import _ from 'lodash'
import mergeby from 'mergeby'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class AppointmentsReducers {
  fetchAppointments = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAppointmentsPending(state, action)
      case SUCCESS:
        return this.fetchAppointmentsSuccess(state, action)
      case FAIL:
        return this.fetchAppointmentsFail(state, action)
      default:
        return state
    }
  }

  updateAppointment = promiseReducer(
    (state, action) => {
      return {
        ...state,
        isLoading: false,
        results: mergeby(state.results, action.result, 'id')
      }
    },
    (state, action) => {
      return {
        ...state,
        isLoading: true
      }
    }
  )

  cancelAppointment = promiseReducer(
    (state, action) => {
      return {
        ...state,
        isLoading: false,
        results: _.filter(state.results, (appointment) => appointment.id !== action.result.id)
      }
    },
    (state, action) => {
      return {
        ...state,
        isLoading: true
      }
    }
  )

  fetchAppointmentsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchAppointmentsSuccess = (state, action) => {
    const { total, items } = action.result
    return {
      ...state,
      total,
      results: items,
      offset: 0,
      limit: 20,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchAppointmentsFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  fetchAppointment = (state, action) => {
    if (action.status !== SUCCESS) {
      return { ...state }
    }
    return {
      ...state,
      results: {
        [action.appointmentId]: action.result
      }
    }
  }

  updateAppointmentsFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }

  createAppointment = (state, action) => {
    return {
      ...state
    }
  }
}

export default new AppointmentsReducers()
