const style = theme => ({
  price: {
    fontSize: 17,
    marginBottom: 10
  },
  discount: {
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: 1.2
  },
  discountInnerLeft: {
    justifyContent: 'left'
  },
  discountInnerCenter: {
    justifyContent: 'center'
  },
  discountInner: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 11
  },
  discountInnerRight: {
    justifyContent: 'right'
  },
  wasPrice: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: 1.2,
    marginRight: 10
  }
})

export default style
