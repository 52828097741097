import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ConfigActions {
  fetchConfig = () => ({
    type: constants.FETCH_CONFIG,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.remoteConfig.fetchRemoteConfig()
    }
  })
}

export default new ConfigActions()

