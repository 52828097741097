import { connect } from 'react-redux'
import { formValueSelector, change, touch } from 'redux-form'
import RoleAndStores from './RoleAndStores'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'

const selector = formValueSelector('user-registration')

const mapStateToProps = state => {
  const loggedInUserRole = authSelectors.getLoggedInUserRole(state)
  const loggedInUserStores = authSelectors.getUserStoresOptions(state)
  const loggedInUserDepartments = authSelectors.getUserDepartmentsOptions(state)
  const selectedRoleId = selector(state, 'roleId')
  const rolesAvailableToUser = authCombinedSelectors.getAvailableRolesForUser(state)
  const rolesAvailableToUserOptions = authCombinedSelectors.getAvailableRolesForUserOptions(state)
  const roleOptionsWithoutSuperAdmin = rolesAvailableToUserOptions && rolesAvailableToUserOptions.filter(option => option.label !== 'Super admin')
  const selectedRole = rolesAvailableToUser && rolesAvailableToUser
    .find((role) => selectedRoleId === role.id)
  const isMultiSelector = selectedRole && selectedRole.multipleStores

  return {
    loggedInUserRole,
    loggedInUserStores,
    selectedRole,
    rolesAvailableToUserOptions: roleOptionsWithoutSuperAdmin,
    isMultiSelector,
    loggedInUserDepartments
  }
}
const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => dispatch(change('user-registration', field, value)),
  touchField: (field) => dispatch(touch('user-registration', field))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAndStores)
