import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class FeedbackActions {
  sendFeedback = fields => ({
    type: constants.SEND_FEEDBACK,
    promise: () => digitalStoreSdk.feedback.sendFeedback(fields),
    payload: fields
  })
}

export default new FeedbackActions()
