import { lighten } from 'polished'

const style = theme => ({
  bar: {
    paddingTop: 'env(safe-area-inset-top)',
    minHeight: theme.headerHeight,
    backgroundColor: lighten(0.28, theme.palette.headerBg.main),
    display: 'flex',
    flexDirection: 'row'
  },
  right: {
    justifyContent: 'flex-end'
  },
  left: {
    justifyContent: 'flex-start'
  },
  closeIcon: {
    width: 26,
    height: 26,
    color: theme.palette.headerBgIconColor.main
  },
  drawerTitle: {
    display: 'flex',
    flex: 1,
    padding: '0 10px'
  },
  drawerTitleText: {
    justifySelf: 'flex-start',
    color: '#FFFFFF'
  }
})

export default style
