import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING, FAIL } from '../../middleware/redux-promise'
import { FETCH_INSPIRATION } from './constants'

const emptyArray = []

export const getInspirationDetails = state => _.get(state, 'inspirationDetails', {})
export const getResult = state => _.get(state.inspirationDetails, 'result', {})
export const getIsLoading = state => _.get(state.inspirationDetails, 'status') === PENDING && _.get(state.inspirationDetails, 'action') === FETCH_INSPIRATION
export const getInspirationFailedToLoad = state => _.get(state.inspirationDetails, 'status') === FAIL
export const getViewMode = state => _.get(state.inspirationDetails, 'viewMode', 'day')

export const getGalleries = createSelector([getResult], inspiration => {
  return _.get(inspiration, 'galleries') || emptyArray
})

export const getSkinToneGalleries = createSelector([getGalleries], galleries => {
  return _.filter(galleries, gallery => gallery.type === 'skinTone')
})

export const getVideoGalleries = createSelector([getGalleries], galleries => {
  return _.filter(galleries, gallery => gallery.type === 'videos')
})

export const getHasMultipleGalleries = createSelector([getGalleries], (galleries = []) => {
  return galleries.length > 1
})

export const getDefaultGalleryIndex = createSelector([getResult], inspiration => {
  return _.findIndex(inspiration.galleries, gallery => gallery.default === true)
})

export const getSelectedGalleryIndex = createSelector(
  [getInspirationDetails, getDefaultGalleryIndex],
  (inspirationDetails, defaultIndex) => {
    return _.get(inspirationDetails, 'selectedGalleryIndex', defaultIndex)
  }
)

export const getActiveGallery = createSelector([
  getGalleries,
  getHasMultipleGalleries,
  getSelectedGalleryIndex
], (galleries = [], hasMultipleGalleries, selectedGalleryIndex) => {
  return _.get(galleries[hasMultipleGalleries ? selectedGalleryIndex : 0], 'media', [])
})

export const getMessageContent = createSelector([ getResult, getDefaultGalleryIndex ], (inspiration, defaultIndex) => {
  const defaultGallery = _.get(inspiration, `galleries[${defaultIndex}].media`, emptyArray)
  const defaultGalleryImages = defaultGallery.map(gallery => {
    return gallery.src
  })
  let inspirationContent = {
    ..._.omit(inspiration, ['galleries']),
    type: 'inspiration',
    images: defaultGalleryImages
  }

  return inspirationContent
})

export const getContentForOrder = createSelector([ getResult, getDefaultGalleryIndex ], (inspiration, defaultIndex) => {
  const defaultGallery = _.get(inspiration, `galleries[${defaultIndex}].media`, emptyArray)
  const defaultGalleryImages = defaultGallery.map(gallery => {
    return gallery.src
  })
  let inspirationContent = {
    id: inspiration.id,
    title: inspiration.title,
    image: defaultGalleryImages[0],
    link: _.get(inspiration, 'details.link'),
    description: _.get(inspiration, 'description')
  }

  return inspirationContent
})

export const getSelectedGalleryDayIndex = createSelector(
  [getActiveGallery],
  (media) => {
    return _.findIndex(media, m => m.day)
  }
)

export const getSelectedGalleryNightIndex = createSelector(
  [getActiveGallery],
  (media) => {
    return _.findIndex(media, m => m.night)
  }
)

export const getSelectedGalleryHasDayNight = createSelector(
  [getSelectedGalleryDayIndex, getSelectedGalleryNightIndex],
  (nightIndex, dayIndex) => {
    return nightIndex > -1 && dayIndex > -1
  }
)
