import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as constants from './constants'
import { apiUpdateSource } from '../../../config'
import * as selectors from './selectors'
import * as currentOrderSelectors from '../currentOrder/selectors'
import * as chatSelectors from '../chat/selectors'
import couchbaseService from '../../../services/couchbaseService'

/**
 * @important please make sure no personal identifiable information is in this object
 * @param {*} currentOrder
 * @returns
 */
const _sanitizeCurrentOrder = (currentOrder) => {
  return {
    products: _.map(currentOrder.products, (product) => {
      const sanitizedProduct = {
        ...product
      }
      return _.pickBy(sanitizedProduct, _.identity)
    }),
    stripePaymentSuccess: currentOrder.stripePaymentSuccess,
    deliveryOption: currentOrder.deliveryOption,
    total: currentOrder.total,
    payment: currentOrder.payment
  }
}

/**
 * @important please make sure no personal identifiable information is in this object
 * @param {*} currentAppointment
 * @returns
 */
const _sanitizeCurrentAppointment = (currentAppointment) => {
  const sanitizedContents = _.map(currentAppointment.contents, (content) => {
    const sanitizedDetails = {
      id: _.get(content, 'details.id'),
      externalProductId: _.get(content, 'details.externalProductId'),
      name: _.get(content, 'details.name'),
      title: _.get(content, 'details.title'),
      images: _.get(content, 'details.images'),
      service: _.get(content, 'details.service'),
      unsold: _.get(content, 'details.unsold'),
      quantity: _.get(content, 'details.quantity'),
      price: _.get(content, 'details.price'),
      priceToDisplay: _.get(content, 'details.priceToDisplay'),
      details: {
        description: _.get(content, 'details.details.description'),
        summary: _.get(content, 'details.details.summary')
      },
      link: _.get(content, 'details.link'),
      latestReview: _.get(content, 'details.latestReview'),
      variant: {
        id: _.get(content, 'details.variant.id'),
        name: _.get(content, 'details.variant.name')
      },
      isLoved: _.get(content, 'details.isLoved'),
      manualDiscount: _.get(content, 'details.manualDiscount'),
      discount: _.get(content, 'details.discount'),
      total: _.get(content, 'details.total'),
      subTotal: _.get(content, 'details.subTotal'),
      discountToDisplay: _.get(content, 'details.discountToDisplay'),
      tax: _.get(content, 'details.tax'),
      groupName: _.get(content, 'details.groupName'),
      categoryId: _.get(content, 'details.categoryId')
    }

    const sanitizedContent = {
      type: content.type,
      isShowing: content.isShowing,
      details: _.pickBy(sanitizedDetails, _.identity)
    }

    return _.pickBy(sanitizedContent, _.identity)
  })

  return {
    id: currentAppointment.id,
    type: currentAppointment.type,
    stage: currentAppointment.stage,
    status: currentAppointment.status,
    customerId: currentAppointment.customerId,
    customer: {
      // firstname only is ok - do not add lastname (see comment above)
      firstName: _.get(currentAppointment, 'customer.firstName', 'Customer')
    },
    orderId: currentAppointment.orderId,
    orderNumber: currentAppointment.orderNumber,
    contents: sanitizedContents,
    user: {
      // firstname only is ok - do not add lastname (see comment above)
      firstName: _.get(currentAppointment, 'user.firstName', 'Consultant')
    },
    ended: currentAppointment.ended || false
  }
}

class CurrentAppointmentActions {
  appointmentAnalyticsEvent = (startEvent) => {
    return {
      type: constants.APPOINTMENT_ANALYTICS_EVENT,
      startEvent
    }
  }

  startAppointment = ({ appointmentId }) => {
    return {
      type: constants.START_APPOINTMENT,
      appointmentId,
      promise: (dispatch, getState) => {
        return digitalStoreSdk.appointments.fetchAppointment({ id: appointmentId })
      }
    }
  }

  clearAppointment = () => {
    return {
      type: constants.CLEAR_APPOINTMENT
    }
  }

  addContent = ({ type, details, ...rest }) => {
    return {
      type: constants.ADD_CONTENT,
      content: {
        type: type,
        details: details,
        ...rest
      }
    }
  }

  removeContent = ({ type, details }) => {
    return {
      type: constants.REMOVE_CONTENT,
      content: {
        type: type,
        details: details
      }
    }
  }

  toggleContentIsShowing = ({ type, details }) => {
    return {
      type: constants.TOGGLE_CONTENT_IS_SHOWING,
      content: {
        type: type,
        details: details
      }
    }
  }

  toggleContentIsLoved = ({ type, details }) => {
    return {
      type: constants.TOGGLE_CONTENT_IS_LOVED,
      content: {
        type: type,
        details: details
      }
    }
  }

  customerToggleContentIsLoved = ({ type, details }) => {
    return {
      type: constants.CUSTOMER_TOGGLE_CONTENT_IS_LOVED,
      content: {
        type: type,
        details: details
      }
    }
  }

  setStage = ({ stage }) => {
    return {
      type: constants.SET_STAGE,
      stage
    }
  }

  setOrderNumber = ({ orderNumber }) => {
    return {
      type: constants.SET_ORDER_NUMBER,
      orderNumber
    }
  }

  setCustomer = ({ customerId }) => {
    return {
      type: constants.SET_CUSTOMER,
      customerId
    }
  }

  setCustomerDetails = ({ customer }) => {
    return {
      type: constants.SET_CUSTOMER_DETAILS,
      customer
    }
  }

  updateCurrentAppointment = (params) => {
    return {
      type: constants.UPDATE_CURRENT_APPOINTMENT,
      promise: (dispatch, getState) => {
        return digitalStoreSdk.appointments.updateAppointment({ ...params, updateSource: apiUpdateSource })
      }
    }
  }

  /**
   * @description used for syncing couchbase to redux
   * @param {object} { currentAppointment }
   * @memberof CurrentAppointmentActions
   */
  setCurrentAppointment = ({ currentAppointment, source }) => {
    return {
      type: constants.SET_CURRENT_APPOINTMENT,
      currentAppointment,
      source
    }
  }

  /**
   * @description used for syncing redux to couchbase
   * @param {object} { appointmentId, source }
   * @memberof CurrentAppointmentActions
   */
  updateAppointmentSharedViewState = ({ appointmentId, source }) => {
    return {
      type: constants.UPDATE_APPOINTMENT_SHARED_VIEW_STATE,
      promise: (dispatch, getState) => {
        const state = getState()
        const currentAppointment = selectors.getCurrentAppointment(state)
        const currentOrder = currentOrderSelectors.getCurrentOrderRaw(state)
        const chatChannelId = chatSelectors.getChatChannelId(state)
        return couchbaseService.updateAppointmentSharedViewState({
          appointmentId,
          currentAppointment: _sanitizeCurrentAppointment(currentAppointment),
          currentOrder: _sanitizeCurrentOrder(currentOrder),
          chatChannelId,
          source
        })
      }
    }
  }

  setConsultationStartTime = () => ({
    type: constants.SET_CONSULTATION_START_TIME
  })

  endAppointment = () => ({
    type: constants.END_APPOINTMENT
  })
  
}

export default new CurrentAppointmentActions()
