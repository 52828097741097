import { useSelector } from 'react-redux'

import {
  getUserOptionsIncludingCurrentUser
} from '../store/modules/combinedSelectors/authCombinedSelectors'

import {
  getCurrentUser,
  getUserSelectedStoreId
} from '../store/modules/auth/selectors'

/**
 * @description returns users options for dopdown
 * @returns
 */
export const useUsersOptions = () => {
  return useSelector(getUserOptionsIncludingCurrentUser)
}

/**
 * @description returns the current user
 * @returns
 */
export const useCurrentUser = () => {
  return useSelector(getCurrentUser)
}

/**
 * @description returns the current store id
 * @returns
 */
export const useCurrentStoreId = () => {
  return useSelector(getUserSelectedStoreId)
}
