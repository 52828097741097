import { promiseReducer } from '../../util'

class DepartmentsReducers {
  fetchDepartments = promiseReducer(
    (state, action) => {
      return {
        ...state,
        results: action.result
      }
    }
  )
}

export default new DepartmentsReducers()
