import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const ImageBox = props => {
  const { src, contain, cover, className, round, classes, caption, onClick, bordered, boxShadow, selectable, selected, ...rest } = props

  const imageBoxClass = classNames(
    classes.root,
    className,
    {
      [classes.contain]: contain,
      [classes.cover]: cover,
      [classes.round]: round,
      [classes.bordered]: bordered,
      [classes.boxShadow]: boxShadow
    }
  )

  const selectBoxClass = classNames(
    {
      [classes.selectIcon]: selectable,
      [classes.selectIconShadow]: boxShadow

    }
  )

  const selectBoxInnerClass = classNames(
    {
      [classes.selectInner]: selectable,
      [classes.selectInnerActive]: selected
    }
  )

  const style = {
    backgroundImage: `url(${src})`,
    margin: '0 auto'
  }

  return (
    <div className={classes.container}>
      <div
        className={imageBoxClass}
        style={style}
        {...rest}
      />
      {caption && <p className={classes.caption}>{caption}</p>}
      {selectable && <div className={selectBoxClass}><div className={classes.selectBoxBorder}><div className={selectBoxInnerClass}></div></div></div>}
    </div>
  )
}

export default withStyles(style)(ImageBox)
