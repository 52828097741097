import { connect } from 'react-redux'
import { compose } from 'recompose'
import _ from 'lodash'
import { getFormValues } from 'redux-form'
import { formNames } from '../../../../../../config'
import NoVariantSelected from './NoVariantSelected'

const mapStateToProps = state => {
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')

  return {
    selectedVariantId
  }
}

export default compose(connect(mapStateToProps))(NoVariantSelected)
