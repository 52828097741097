import React from 'react'
import qs from 'qs'
import { Modules } from '@redant/retailos-ui'
import { customerFunctions } from '../dependencies'
import modalService from '../../services/modalService'
import { getUiConfig } from '../uiConfigStore'

export const CustomerSelfRegistrationScreen = () => {
  const { token: tokenId, location: externalStoreId } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const useCustomerSelfRegistrationScreen = Modules.Customer.Implementation.provideUseCustomerSelfRegistrationScreen({
    customerFunctions,
    tokenId,
    externalStoreId,
    modalService
  })

  return (
    <Modules.Customer.Screens.CustomerSelfRegistrationScreen
      implementation={useCustomerSelfRegistrationScreen}
      uiConfig={getUiConfig('Modules.Customer.Screens.CustomerSelfRegistrationScreen')}
    />
  )
}
