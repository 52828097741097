import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import { selectors as inspirationSelectors, actions as inspirationsActions } from '../../../../store/modules/inspirations'
import TagPicker from './TagPicker'
import { constants as appConstants, selectors as appSelectors } from '../../../../store/modules/app'

const mapStateToProps = state => ({
  tags: inspirationSelectors.getTagsAsOptions(state),
  type: appSelectors.getInspirationsTab(state) === appConstants.BRAND_INSPIRATIONS ? 'brand' : 'user',
  tagId: inspirationSelectors.getTagId(state),
  total: inspirationSelectors.getTotal(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchInspirations: ({ dispatch, type }) => _.debounce(tagId => {
      dispatch(inspirationsActions.searchInspirationsFresh({ tagId, type }))
    }, 300)
  })
)(TagPicker)
