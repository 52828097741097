import _ from 'lodash'

import { distanceUnits } from '../config'

class DistanceFormatter {
  _units = {
    metres: 1,
    kilometres: 1000,
    miles: 1609.34,
    yards: 0.9144
  }

  format = (params) => {
    const {
      distance,
      inputUnits = 'metres',
      outputUnits = distanceUnits,
      decimalPlaces
    } = params || {}

    const multiplier = this._units[inputUnits] / this._units[outputUnits]

    if (_.isNumber(distance) && multiplier) {
      return _.round(
        Number(distance) * multiplier,
        decimalPlaces === undefined ? 2 : decimalPlaces
      )
    } else {
      return '?'
    }
  }
}

export default new DistanceFormatter()
