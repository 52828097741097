import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel'

import Date from '../Date'
import Time from '../Time'
import styles from './style'

const DateTime = ({
  label,
  value,
  onChange,
  onBlur,
  options,
  passedProps,
  meta: { error, touched },
  validate,
  classes
}) => {
  return ([
    <InputLabel
      // FormControlClasses
      classes={{
        focused: classes.floatingLabelFocusStyle
      }}
      htmlFor={label}
      key='label'
      shrink
    >
      {label}
    </InputLabel>,
    <div key='inputs' className={classes.wrapper}>
      <div className={classes.fieldWrapperDate}>
        <Date
          value={value.date}
          onChange={(e) => onChange(e, 'date')}
          onBlur={(e) => onBlur(e, 'date')}
        />
      </div>
      <div className={`${classes.fieldWrapperTime}`}>
        <Time
          value={value.time}
          onChange={(e) => onChange(e, 'time')}
          onBlur={(e) => onBlur(e, 'time')}
        />
      </div>
    </div>,
    <FormHelperText className={classes.errorStyle} key='helper-text'>{error}</FormHelperText>
  ])
}

DateTime.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  })
}

export default withStyles(styles)(DateTime)
