const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  displayNone: {
    display: 'none'
  }
}

export default styles
