import { setUiConfig } from '../uiConfigStore'

setUiConfig('Modules.VirtualConsultation.Screens.CustomerScreen', {
  theme: {},
  paymentProvider: 'STRIPE',
  stripe: {
    // FIXME: replace REACT_APP_ with VITE_ when merging to v3
    stripeAccount: window.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID || process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID
  }
})
