import formStyle from '../../../components/Form/style'

const styles = theme => ({
  generalError: {
    height: 13,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.palette.error.main
  },
  field: {
    maxWidth: '330px',
    width: '100%'
  }
})

export default theme => ({
  ...formStyle,
  ...styles
})
