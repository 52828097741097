export default theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    background: '#fff',
    border: '1px solid #E8E8E8',
    marginTop: 10
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    padding: 5,
    border: `1px solid ${theme.palette.alphabetListGrey.main}`
  },
  circleImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.lightGrey.main,
    borderRadius: 20,
    height: 38,
    width: 38
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  bodyText: {
    flex: 1,
    paddingLeft: 15,
    color: theme.palette.chartLegendsGrey.main,
    fontSize: 13
  },
  dateTime: {
    paddingLeft: 10,
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: theme.palette.chartLegendsGrey.main,
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center'
    }
  }
})
