import _ from 'lodash'
import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import * as constants from './constants'

class PollQuestionsReducers {
  fetchPollQuestions = promiseReducer(
    (state, action) => {
      return {
        ...state,
        pollQuestions: action.result
      }
    }
  )
  saveAnswers = promiseReducer(
    (state, action) => {
      return {
        ...state,
        savingQuestionId: undefined
      }
    },
    (state, action) => {
      return {
        ...state,
        savingQuestionId: action.questionId
      }
    },
    (state, action) => {
      return {
        ...state,
        savingQuestionId: undefined
      }
    }
  )
  removeQuestion = (state, action) => {
    const { questionId } = action
    const nextPollQuestions = _.chain(state.pollQuestions)
      .reject(question => question.id === questionId)
      .value()
    return {
      ...state,
      pollQuestions: nextPollQuestions
    }
  }
  selectAnswer = (state, action) => {
    const { answer, single = true, questionId } = action
    const nextPollQuestions = _.chain(state.pollQuestions)
      .map(question => {
        if (question.id === questionId) {
          const existingAnswer = _.first(question.pollAnswers)
          const existingAnswers = question.pollAnswers || []
          const nextAnswer = {
            id: (new Date()).getTime(),
            answer,
            details: {
              comment: single ? _.get(existingAnswer, 'details.comment') : undefined
            }
          }
          const nextAnswers = [
            ...(single ? [] : existingAnswers),
            nextAnswer
          ]
          return {
            ...question,
            pollAnswers: nextAnswers
          }
        } else {
          return question
        }
      })
      .value()
    return {
      ...state,
      pollQuestions: nextPollQuestions
    }
  }
  updateComment = (state, action) => {
    const { comment, answerId, questionId } = action
    const nextPollQuestions = _.chain(state.pollQuestions)
      .map(question => {
        if (question.id === questionId) {
          const nextAnswers = _.chain(question.pollAnswers)
            .map(pollAnswer => {
              if (pollAnswer.id === answerId) {
                return {
                  ...pollAnswer,
                  details: {
                    ...(pollAnswer.details || {}),
                    comment
                  }
                }
              } else {
                return pollAnswer
              }
            })
            .value()
          return {
            ...question,
            pollAnswers: nextAnswers
          }
        } else {
          return question
        }
      })
      .value()
    return {
      ...state,
      pollQuestions: nextPollQuestions
    }
  }
}

export default new PollQuestionsReducers()
