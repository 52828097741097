import React, { Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import * as validators from '../../../validators'
import style from './style'
import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { Dropdown } from '../../../components/Fields'
import { translations } from '../../../config'
import { withStyles } from '@material-ui/core/styles'

const SelectDepartmentForm = Form('select-department')

const autocompleteDropdownProps = {
  autocomplete: true,
  listWidth: 330,
  maxListHeight: 144
}

const SelectDepartmentFormWrapper = ({
  onSubmit,
  departmentId,
  departments,
  invalid,
  submitting,
  classes
}) => {
  return (
    <Fragment>
      <SelectDepartmentForm onSubmit={onSubmit} submitLabel={translations('Continue')} initialValues={{ departmentId }}>
        <FormBody>
          <div className={classes.formBody}>
            <FormControl className={classes.field}>
              <Dropdown
                options={departments}
                name='departmentId'
                validate={validators.required}
                strict={'true'}
                {...(departments.length > 10 ? autocompleteDropdownProps : {})}
              />
            </FormControl>
          </div>
        </FormBody>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Continue')} buttonClass={classes.fullWidthButton} />
      </SelectDepartmentForm>
    </Fragment>
  )
}

SelectDepartmentFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(SelectDepartmentFormWrapper)
