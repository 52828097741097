import mergeby from 'mergeby'
import { promiseReducer } from '../../util'

class ActivitiesReducers {
  fetchLatestActivities = promiseReducer(
    (state, action) => {
      return {
        ...state,
        results: action.result
      }
    }
  )
  fetchActivities = promiseReducer(
    (state, action) => {
      return {
        ...state,
        results: action.result.items
      }
    }
  )
}

export default new ActivitiesReducers()
