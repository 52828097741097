import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

import Dropdown from '../Dropdown'
import style from './style'

const Birthday = (props) => {
  const {
    classes,
    label,
    dayValue,
    dayOptions,
    onChangeDay,
    monthValue,
    monthOptions,
    onChangeMonth
  } = props

  return <div className={classes.container}>
    <InputLabel
      // FormControlClasses
      classes={{
        focused: classes.floatingLabelFocusStyle
      }}
      shrink
    >
      {label}
    </InputLabel>
    <div className={classes.inputsContainer}>
      {
        [
          {
            key: 'day',
            value: dayValue,
            onChange: onChangeDay,
            options: dayOptions
          },
          {
            key: 'month',
            value: monthValue,
            onChange: onChangeMonth,
            options: monthOptions
          }
        ]
          .map(({ key, ...dropdownProps }) => {
            return <div className={classes.dropdownContainer} key={key}>
              <Dropdown
                className={classes.dropdown}
                noErrorTextLabel
                noNullOption
                {...dropdownProps}
              />
            </div>
          })
      }
    </div>
  </div>
}

export default withStyles(style)(Birthday)