import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  size: 10,
  page: 1,
  results: [],
  eventId: undefined,
  storeId: undefined,
  expand: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_WAITLIST:
      return reducers.fetchWaitlist(state, action)
    case constants.FETCH_WAITLIST_NEXT:
      return reducers.fetchWaitlistNext(state, action)
    case constants.DELETE_FROM_WAITLIST_LOCAL:
      return reducers.deleteFromWaitlistLocal(state, action)
    case constants.CLEAR_WAITLIST_RESULTS:
      return reducers.clearWaitlistResults(state, action)
    default:
      return state
  }
}
