import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import BarGraph from '../../../../components/Charts/BarGraph'
import P from '../../../../components/P'
import style from './style'
import Button from '../../../../components/Button'
import { translations, pollReporting } from '../../../../config'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { getImage } from '../../../../components/Images'

const trashIcon = getImage('trashIcon')
const commentsIconPrimary = getImage('commentsIconPrimary')

const _formatDatePrefix = (result) => {
  const { dateDue, dateStart, status } = result

  switch (status) {
    case 'done':
      return `Ended ${moment(dateDue).format('DD MMM')}`
    case 'inProgress':
      return `Ending ${moment(dateDue).format('DD MMM')}`
    case 'pending':
      return `Starts ${moment(dateStart).format('DD MMM')}`
  }
}

class ResultRow extends Component {

  renderEmptyResult = ({ message }) => {
    const { classes } = this.props

    return (
      <div className={classes.emptyChart}>
        <p className={classes.emptyChartMessage}>{message}</p>
      </div>
    )
  }

  renderChart = () => {
    const { result, classes } = this.props
    const { pollQuestion } = result
    const rowConfig = pollReporting[pollQuestion.type]

    if (result.status === 'inProgress') return this.renderEmptyResult({ message: translations('Question Is In Progress') })
    if (result.status === 'pending') return this.renderEmptyResult({ message: translations('Question Not Yet Released') })
    if (!result.answerCounts.length) return this.renderEmptyResult({ message: translations('No Results') })

    const height = `${rowConfig.chartHeight}px`

    switch (rowConfig.chartType) {
      case 'BAR':
        return (
          <div style={{ height, flexBasis: height }} className={classes.chartWrapper}>
            <BarGraph
              truncateLength={0}
              hideTotal
              chartAspect={1.2}
              data={result.answerCounts}
            />
          </div>
        )
    }
  }

  renderActions = () => {
    const { classes, onCommentsClick, onDeleteClick, result: { commentCount, status } } = this.props

    return (
      <div className={classes.rowActions}>
        {(!!commentCount && status === 'done') && (
          <Button onClick={onCommentsClick}>
            <img className={classes.rowActionIcon} src={commentsIconPrimary} />
            <div className={classes.rowActionText}>{`${translations('Comments')} (${commentCount})`}</div>
          </Button>
        )}
        <Button onClick={onDeleteClick}>
          <img className={classes.rowActionIcon} src={trashIcon} />
          <div className={classes.rowActionText}>{translations('Delete')}</div>
        </Button>
      </div>
    )
  }

  renderSummary = () => {
    const { classes, result } = this.props
    const { pollAnswers, average, status } = result
    return status === 'done' && (
      <div className={classes.rowHeaderRight}>
        {(average) && <P className={classes.rowHeaderValue} value={`AVG ${average}`} />}
        <P className={classes.rowHeaderValue} value={`${pollAnswers.length} Responses`} />
      </div>
    )
  }

  render = () => {
    const { classes, result } = this.props
    const { type, pollQuestion } = result
    const rowConfig = pollReporting[pollQuestion.type]

    if (!rowConfig) return null

    return (
      <Grid item xs={12} sm={6} md={6} key={result.id}>
        <div className={classes.container}>
          <div className={classes.rowHeader}>
            <div className={classes.rowHeaderLeft}>
              <P className={classes.rowHeaderValue} value={translations(type)} />
              <P className={classes.rowHeaderValue} value={_formatDatePrefix(result)} />
            </div>
            {this.renderSummary()}
          </div>
          <P className={classes.rowQuestion} value={pollQuestion.question} />
          {this.renderChart()}
          {this.renderActions()}
        </div>
      </Grid>
    )
  }
}

export default withStyles(style)(ResultRow)
