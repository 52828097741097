import * as constants from './constants'
import * as inspirationsSelectors from './selectors'

import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class InspirationsActions {
  clearResults = () => ({
    type: constants.CLEAR_RESULTS
  })
  fetchInspirationTags = () => {
    return {
      type: constants.FETCH_INSPIRATION_TAGS,
      promise: (dispatch, getState) => {
        const state = getState()
        const regionId = authSelectors.getUserSelectedRegionId(state)
        return digitalStoreSdk.inspirations.fetchAllInspirationTags({ regionId })
      }
    }
  }
  searchInspirationsFresh = (props = {}) => {
    const { query, page = 1, size = 18, tagId, type, userId } = props
    return {
      type: constants.SEARCH_INSPIRATIONS_FRESH,
      promise: (dispatch, getState) => {
        const state = getState()
        const searchQuery = query === undefined ? inspirationsSelectors.getQuery(state) : query
        const regionId = authSelectors.getUserSelectedRegionId(state)
        const searchUserId = userId === undefined ? inspirationsSelectors.getUserId(state) : userId
        const searchTagId = tagId === undefined ? inspirationsSelectors.getTagId(state) : tagId
        const searchType = type === undefined ? inspirationsSelectors.getType(state) : type

        return digitalStoreSdk.inspirations.searchInspirations({
          // can only search for brand inspirations
          query: searchType === 'brand' ? searchQuery : undefined,
          page,
          size,
          regionId,
          tagId: searchType === 'brand' ? searchTagId : undefined,
          type: searchType,
          userId: searchType === 'user' ? searchUserId : undefined
        })
      },
      payload: {
        type,
        query,
        tagId,
        userId
      }
    }
  }
  searchInspirationsNext = () => {
    return {
      type: constants.SEARCH_INSPIRATIONS_NEXT,
      promise: (dispatch, getState) => {
        const state = getState()
        const query = inspirationsSelectors.getQuery(state)
        const page = inspirationsSelectors.getPage(state) + 1
        const size = 5
        const regionId = authSelectors.getUserSelectedRegionId(state)
        const tagId = inspirationsSelectors.getTagId(state)
        const type = inspirationsSelectors.getType(state)
        const userId = inspirationsSelectors.getUserId(state)
        return digitalStoreSdk.inspirations.searchInspirations({
          query: type === 'brand' ? query : undefined,
          page,
          size,
          regionId,
          tagId: type === 'brand' ? tagId : undefined,
          type: type,
          userId: type === 'user' ? userId : undefined
        })
      }
    }
  }
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new InspirationsActions()
