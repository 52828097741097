import { getClientConfig } from '../retailos/clientConfig'

let appsConfig = require('./configFiles/apps')
let kanban = require('./configFiles/kanban/config.default.json')
let countriesConfig = require('./configFiles/countries')
let local = require('./configFiles/environments/local')
let development = require('./configFiles/environments/development')
let qa = require('./configFiles/environments/qa')
let uat = require('./configFiles/environments/uat')
let production = require('./configFiles/environments/production')
let environments = require('./configFiles/environments/environments')
let general = require('./configFiles/general.json')
let pollReporting = require('./configFiles/polls/pollReporting.json')
let customerReportingSchema = require('./configFiles/reporting/customerReportingSchema.json')
let staffReportingSchema = require('./configFiles/reporting/staffReportingSchema.json')
let storeReportingSchema = require('./configFiles/reporting/storeReportingSchema.json')
let colors = require('./configFiles/theme/colors.json')
let colorsv2 = require('./configFiles/theme/colors-v2.json')
let base = require('./configFiles/theme/base.json')
let basev2 = require('./configFiles/theme/base-v2.json')
let text = require('./configFiles/theme/text.json')
let _theme = require('./configFiles/theme/theme.json')
let en = require('./configFiles/translations/en.json')

export { allLanguages, prioritized } from './configFiles/languages.json'

appsConfig = getClientConfig('appsConfig', appsConfig)
kanban = getClientConfig('kanban', kanban)
countriesConfig = getClientConfig('countriesConfig', countriesConfig)
local = getClientConfig('local', local)
development = getClientConfig('development', development)
qa = getClientConfig('qa', qa)
uat = getClientConfig('uat', uat)
production = getClientConfig('production', production)
environments = getClientConfig('environments', environments)
general = getClientConfig('general', general)
pollReporting = getClientConfig('pollReporting', pollReporting)
customerReportingSchema = getClientConfig('customerReportingSchema', customerReportingSchema)
staffReportingSchema = getClientConfig('staffReportingSchema', staffReportingSchema)
storeReportingSchema = getClientConfig('storeReportingSchema', storeReportingSchema)
colors = getClientConfig('colors', colors)
colorsv2 = getClientConfig('colorsv2', colorsv2)
base = getClientConfig('base', base)
basev2 = getClientConfig('basev2', basev2)
text = getClientConfig('text', text)
_theme = getClientConfig('_theme', _theme)
en = getClientConfig('en', en)

export {
    appsConfig,
    kanban,
    countriesConfig,
    local,
    development,
    qa,
    uat,
    production,
    environments,
    general,
    pollReporting,
    customerReportingSchema,
    staffReportingSchema,
    storeReportingSchema,
    colors,
    colorsv2,
    base,
    basev2,
    text,
    _theme,
    en,
}
