import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { FormBody } from '../../../components/Form'
import FormContentBox from '../../../components/FormContentBox'

import style from './style'

const Form = FormContentBox('consultation-customer-details-form')

const ConsultationFormStep = props => {
  const { initialValues, schema, editing, classes, onSubmit, toggleEdit } = props

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      editable
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={_.noop}
      givenContentBoxClass={classes.contentBox}
      givenContentContainerClass={classes.contentBoxContainer}
    >
      <FormBody
        schema={schema}
      />
    </Form>
  )
}

export default withStyles(style)(ConsultationFormStep)
