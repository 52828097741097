// Handle the toggle state between edit and not edit mode

export default ({ input, display }) => {
  return ({ label, displayValue, editing, required, editable = true, ...props }) => {
    return (
      (editable && editing)
        ? input({ label, ...props })
        : display({ label: `${label}${required ? ' *' : ''}`, value: displayValue || '-', ...props })
    )
  }
}
