const styles = {
  container: {
    display: 'flex',
    width: '100%'
  },
  searchInput: {
    flex: 1,
    border: 0,
    outline: 0,
    padding: 10,
    fontSize: 18
  },
  searchButtonContainer: {
    padding: 10
  }
}

export default theme => styles
