const styles = theme => ({
  container: {
    border: `0.5px solid ${theme.palette.contentBoxGrey.main}`,
    width: '100%',
    margin: '0 auto 30px auto'
  },
  header: {
    fontSize: '19px',
    textTransform: 'uppercase',
    borderBottom: `0.5px solid ${theme.palette.contentBoxGrey.main}`,
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      height: '60px'
    }
  },
  content: {
    padding: '30px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 40px'
    },
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
})

export default styles
