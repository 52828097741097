import { compose, withPropsOnChange, branch } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'

import getGroupedItems from './getGroupedItems'
import getChartData from './getChartData'
import getGroupedListData from './getGroupedListData'
import getListData from './getListData'
import getCurrency from './getCurrency'
import getListItem from './getListItem'
import getTarget from './getTarget'

import { selectors as categoriesSelectors } from '../../../store/modules/categories'

const mapStateToProps = state => ({
  categoriesHashmap: categoriesSelectors.getCategoryHashmap(state)
})

const withReportData = compose(
  branch(
    ({ reportSchema }) => _.get(reportSchema.data, 'transform', '').includes('CATEGORIES'),
    connect(mapStateToProps),
    connect()
    ),
  withPropsOnChange(
    ['items', 'reportSchema', 'dispatch'],
    props => {
      const computedProps = {
        groupedItems: getGroupedItems(props),
        chartData: getChartData(props),
        groupedListData: getGroupedListData(props),
        listData: getListData(props),
        currency: getCurrency(props),
        listItem: getListItem(props),
        target: getTarget(props)
      }
      return computedProps
    }
  )
)

export default withReportData
