import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { phoneNumber } from '../../../../validators'
import { getImage } from '../../../Images'

import style from './style'

const phoneIcon = getImage('phoneIcon')

const PhoneIcons = props => {
  const { value, classes, onClickCall } = props

  // note: phoneNumber(value) returns true if NOT a valid phone number
  if (phoneNumber(value)) {
    return null
  }
  return (
    <div className={classes.iconContainer}>
      <IconButton
        onClick={onClickCall}
        aria-label='Start call'
        classes={{ root: classes.buttonContainer }}
      >
        <img src={phoneIcon} className={classes.phoneIconPosition} />
      </IconButton>
    </div>
  )
}

export default withStyles(style)(PhoneIcons)
