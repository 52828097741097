import { useSelector } from 'react-redux'

import {
  getPaymentCardOptions,
  getBasket
} from '../store/modules/currentOrderSalesforce/selectors'

/**
 * @description returns salesforce basket payment card options
 * @returns
 */
export const usePaymentCardOptions = () => {
  return useSelector(getPaymentCardOptions)
}

/**
 * @description returns salesforce basket
 * @returns
 */
export const useBasket = () => {
  return useSelector(getBasket)
}
