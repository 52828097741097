import { connect } from 'react-redux'
import { compose } from 'recompose'
import CustomerSnapshotActivity from './CustomerSnapshotActivity'
import { selectors as customerDetailSelectors } from '../../../../../store/modules/customerDetails'
import { selectors as activitiesSelectors } from '../../../../../store/modules/activities'

const mapStateToProps = (state) => ({
  customer: customerDetailSelectors.getCustomer(state),
  activities: activitiesSelectors.getActivities(state)
})

export default compose(
  connect(mapStateToProps)
)(CustomerSnapshotActivity)
