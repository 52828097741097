import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import P from '../../components/P'
import { withStyles } from '@material-ui/core/styles'
import { noPrices, products as productsConfig, translations } from '../../config'

import style from './style'

class ProductDiscount extends PureComponent {
  _renderDiscountInformation = () => {
    const {
      discountValue,
      classes,
      originalPrice,
      displayDiscount,
      className,
      left,
      center,
      right
    } = this.props
    const discountNumber = parseInt(discountValue, 10)
    if (discountNumber === 0) return false
    const innerContainerClass = classNames(
      classes.discountInner,
      className,
      {
        [classes.discountInnerLeft]: left,
        [classes.discountInnerCenter]: center,
        [classes.discountInnerRight]: right
      }
    )
    return (
      <div className={classes.discountContainer}>
        <div className={innerContainerClass}>
          <P value={_.template(translations('was originalPrice'))({ originalPrice })} className={classes.wasPrice} />
          <P value={_.template(translations('save displayDiscount'))({ displayDiscount })} className={classes.discount} />
        </div>
      </div>
    )
  }
  render () {
    const {
      classes,
      displayPrice,
      originalPrice,
      discountValue,
      isPreviewProduct
    } = this.props
    const discountNumber = parseInt(discountValue, 10)
    return (
      <div className={classes.root}>
        {(noPrices || (isPreviewProduct && productsConfig.noPreviewProductPrices))
          ? null
          : <P value={displayPrice} className={classes.price} />}
        {productsConfig.wasNowPrice
          ? this._renderDiscountInformation()
          : null
        }
      </div>
    )
  }
}

ProductDiscount.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  displayPrice: PropTypes.string.isRequired,
  displayDiscount: PropTypes.string.isRequired,
  discountValue: PropTypes.string.isRequired,
  originalPrice: PropTypes.string.isRequired,
  isPreviewProduct: PropTypes.bool
}

export default withStyles(style)(ProductDiscount)
