import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import P from '../../../components/P'
import NotificationEditorForm from './NotificationEditorForm'
import style from './style'
import { translations } from '../../../config'

const NotificationEditorScreen = ({ classes }) => {
  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={(
          <H2 value={_.toUpper(translations('Notifications'))} />
        )}
      />
      <div className={classes.contentContainer}>
        <P
          value={translations('required fields')}
          className={classes.requiredFieldsText}
        />
        <NotificationEditorForm />
      </div>
    </div>
  )
}

export default withStyles(style)(NotificationEditorScreen)
