import { connect } from 'react-redux'
import { withHandlers, compose } from 'recompose'
import _ from 'lodash'

import TitleBar from './TitleBar'
import SearchFilters from '../../SearchFilters/SearchFilters'
import { actions as productsActions, selectors as productsSelectors } from '../../../../../store/modules/products'
import { actions as currentOrderActions } from '../../../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'

import modalService from '../../../../../services/modalService'

const mapStateToProps = state => {
  const total = productsSelectors.getTotal(state)
  const query = productsSelectors.getResultsQuery(state)
  const sort = productsSelectors.getSort(state)
  const isLoading = productsSelectors.getIsLoading(state)
  const numberOfActiveFilters = productsSelectors.getNumberOfActiveFilters(state)
  const productsSortOptions = productsSelectors.getAvailableSortOptions(state)

  const order = currentOrderCombinedSelectors.currentOrderSelector(state)

  return {
    sort,
    total,
    query,
    isLoading,
    numberOfActiveFilters,
    productsSortOptions,
    ..._.pick(order, [
      'editMode',
      'orderNumber'
    ])
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchProducts: ({ dispatch }) => ({ sort }) => dispatch(productsActions.searchProductsFresh({ sort })),
    stopEditingOrder: ({ dispatch }) => () => dispatch(currentOrderActions.stopEditingOrder()),
    openFilters: ({ dispatch }) => () => {
      modalService.open({
        modalIndex: 2, 
        component: SearchFilters,
        inModal: true, 
        onClose: () => {
          modalService.close({ modalIndex: 2 })
        }
      })
    }
  })
)(TitleBar)
