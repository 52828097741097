import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

import { getImage } from '../../components/Images'
import style from './style'

const buttonTypeProps = {
  primary: {
    color: 'primary'
  },
  secondary: {
    color: 'primary',
    backgroundColor: 'transparent'
  },
  white: {
    variant: 'outlined'
  }
}

/** Basic button component - spreads props to [MUI Button](https://material-ui.com/api/button/) */
export const CustomButton = props => {
  const { children, classes, buttonType, wrapper, fullWidth, big, disabled, className, iconImage, hyperlink, flexGrow, color, ...rest } = props

  const rootClass = classNames(
    classes.root,
    className,
    {
      [classes.white]: buttonType === 'white' && !disabled,
      [classes.fullWidth]: fullWidth,
      [classes.big]: big,
      [classes.hyperlink]: hyperlink,
      [classes.flexGrow]: flexGrow,
      [classes.wrapper]: wrapper
    }
  )

  const labelClass = classNames(
    classes.label,
    {
      [classes.labelBig]: big
    }
  )

  const buttonClasses = {
    root: rootClass,
    label: labelClass
  }
  const hyperlinkIconClass = hyperlink && classes.hyperlinkIcon
  const personalizedButtonProps = buttonTypeProps[buttonType] || {}
  return <Button
    variant={buttonType === 'primary' || color === 'primary' ? 'contained' : 'text'}
    color={color}
    {...rest}
    {...personalizedButtonProps}
    disabled={disabled}
    classes={buttonClasses}
    disableRipple={wrapper}
  >
    {iconImage ? (
      <Icon className={classNames(children ? classes.rightIcon : null, classes.icon)}>
        <img className={hyperlinkIconClass} src={getImage(iconImage)} />
      </Icon>
    ) : (
        null
      )
    }

    {children}
  </Button>
}

CustomButton.defaultProps = {
  fullWidth: false,
  big: false,
  disabled: false,
  hyperlink: false
}

CustomButton.propTypes = {
  /** string indicating which button type to use */
  buttonType: PropTypes.oneOf(['primary', 'white']),
  fullWidth: PropTypes.bool,
  /** render with larger text and button height */
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  iconImage: PropTypes.string,
  hyperlink: PropTypes.bool,
  className: PropTypes.string,
}

export default withStyles(style)(CustomButton)
