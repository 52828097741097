const styles = {
  container: {
    padding: 10,
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer'
  },
  heading: {
    textAlign: 'start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    fontWeight: 'bold'
  },
  headingContainer: {
    padding: '15px 25px'
  }
}

export default theme => styles
