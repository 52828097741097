import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import style from './style'

const DrawerBar = props => {
  const { left, right, closeDrawer = () => {}, classes, className, title } = props

  const barClass = classNames(
    classes.bar,
    className,
    {
      [classes.left]: left,
      [classes.right]: right
    }
  )

  return (
    <div className={barClass}>
      {title && (<div className={classes.drawerTitle}>
        <p className={classes.drawerTitleText}>{title}</p>
      </div>)}
      <IconButton
        onClick={closeDrawer}
        className={classes.closeButton}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  )
}

DrawerBar.propTypes = {
  title: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
  closeDrawer: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

export default withStyles(style)(DrawerBar)
