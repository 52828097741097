import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InfiniteScroll from 'react-infinite-scroller'

import style from './style'
import Event from './Event'
import P from '../../../../components/P'
import { translations } from '../../../../config'

const EventResults = ({ events, classes, hasMore, loadMore, hasSearched, inModal, ...rest }) => {
  if (hasSearched && !events.length) {
    return <P
      className={classes.noResults}
      type='textButton'
      value={translations('No results found')}
    />
  }
  return (
    <InfiniteScroll
      initialLoad={false}
      hasMore={hasMore}
      loadMore={loadMore}
      className={classes.scrollContainer}
      useWindow={!inModal} // dont scroll from window if inside a modal, as the scroll container is within the modal
    >
      <Grid container spacing={0} className={classes.container}>
        {
          events.map(event => {
            return (
              <Grid item xs={12} sm={4} key={event.id} className={classes.gridItem}>
                <Event event={event} {...rest} />
              </Grid>
            )
          })
        }
      </Grid>
    </InfiniteScroll>

  )
}

export default withStyles(style)(EventResults)
