const style = theme => ({
  stockOutter: {
  },
  stockInner: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: `15px 0`,
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  indicators: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%'
    }
  }
})

export default style
