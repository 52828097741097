const boxPadding = '16px 24px'

const style = theme => ({
  customerBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border.main}`,
    padding: boxPadding,
    marginTop: 15,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  container: {
    marginTop: 15
  },
  customerButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& button:not(:last-child)': {
        marginRight: '1rem'
      }
    }
  }
})

export default style
