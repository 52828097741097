import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import style from './style'
import { translations } from '../../config'
import LockForm from './LockForm'
import Button from '../../components/Button'
import MainLogo from '../../components/MainLogo'

const LockScreen = ({ classes, logout }) => {
  return (
    <div className={classes.container}>
      <MainLogo className={classes.logo} />
      <div className={classes.tagline}>
        <LockIcon className={classes.lockIcon} />
        <p className={classes.taglineText}>{translations('Screen Locked')}</p>
      </div>
      <LockForm />
      <Button onClick={logout} className={classes.logout}>{translations('Sign out')}</Button>
    </div>
  )
}

export default withStyles(style)(LockScreen)
