import _ from 'lodash'
import { withPropsOnChange } from 'recompose'
import { stockLevel as stockConstants, translations, distanceUnits, storeLocationsEnabled } from '../../../../../../../config'
import distanceFormatter from '../../../../../../../formatters/distanceFormatter'

import StoreStockItem from './StoreStockItem'

export default withPropsOnChange(
  ['_store', 'stock'],
  ({ _store, stock }) => {
    const { name: storeName, distanceFromCurrentStore } = _store || {}
    const stockInStore = stock

    let stockLevelIndicatorProps = {}
    if (stockInStore >= stockConstants.inStockLevel) {
      stockLevelIndicatorProps = {
        label: translations('In Stock In Other Store Message', { storeName }),
        icon: 'greenCheckIcon'
      }
    } else if (stockInStore === stockConstants.lowStockHighLevel) {
      stockLevelIndicatorProps = {
        label: translations('Low Stock In Other Store Message', { storeName }),
        icon: 'orangeCheckIcon'
      }
    } else if (stockInStore === stockConstants.lowStockLowLevel) {
      stockLevelIndicatorProps = {
        label: translations('Low Stock In Other Store Message', { storeName }),
        icon: 'orangeCheckIcon'
      }
    } else if (stockInStore === stockConstants.outOfStockLevel) {
      stockLevelIndicatorProps = {
        label: translations('Out Of Stock In Other Store Message', { storeName }),
        icon: 'redCrossIcon'
      }
    } else if (stockInStore <= stockConstants.itemNotStocked) {
      stockLevelIndicatorProps = {
        label: translations('Not Stocked In Other Store Store Message', { storeName }),
        icon: 'redCrossIcon'
      }
    }
    if (storeLocationsEnabled) {
      stockLevelIndicatorProps.sublabel = translations('Distance From Current Store', {
        distance: distanceFormatter.format({
          distance: distanceFromCurrentStore,
          inputUnits: 'metres',
          outputUnits: distanceUnits,
          decimalPlaces: 2
        }),
        units: distanceUnits
      })
    }
    return { stockLevelIndicatorProps }
  }
)(StoreStockItem)
