import _ from 'lodash'

import couchbaseService from '../../services/couchbaseService'
import { actions as offlineActions } from '../modules/offline'

const syncOfflineData = ({ dispatch }) => {
  const couchbaseConnections = couchbaseService.getConnections()
  console.info(`CouchbaseService successfuly started with ${_.size(couchbaseConnections)} connections => ${_.join(_.keys(couchbaseConnections), ', ')}`)
}

export default syncOfflineData
