import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import KeyValueCard from '../../../../../../components/KeyValueCard'
import { getColumns, getFormattedOrderRow } from '../helpers'
import { translations } from '../../../../../../config'

import style from './style'

const columns = getColumns()

const GridView = ({ classes, orders, onOrderClick }) => (
  <div className={classes.container}>
    {orders.map(order => {
      const formattedOrder = getFormattedOrderRow(order)
      const data = columns.map(({ key, title, clickHandler }) => {
        return [translations(title), formattedOrder[key], { className: classes.keyValuePair, clickHandler }]
      })

      return <div className={classes.cardContainer}>
        <KeyValueCard
          data={data}
          columns={2}
          onClick={() => onOrderClick(order.orderNumber)}
        />
      </div>
    })}
  </div>
)

export default withStyles(style)(GridView)
