import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { selectors as notificationsSelectors, actions as notificationsActions } from '../../../store/modules/notifications'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as usersActions } from '../../../store/modules/users'
import * as combinedNotificationsSelectors from '../../../store/modules/combinedSelectors/notificationsUserSelector'
import analyticsService from '../../../services/analyticsService'
import NotificationsScreen from './NotificationsScreen'
import * as authCombinedSelectors from '../../../store/modules/combinedSelectors/authCombinedSelectors'
import modalService from '../../../services/modalService'
import ViewAttachmentModal from '../ViewAttachmentModal'

const mapStateToProps = state => {
  const filters = notificationsSelectors.getNotificationsFilters(state)
  const filtersFalse = !filters.toDo && !filters.isFlagged
  const activeUserId = authSelectors.getActiveUserId(state)
  const notificationUserId = notificationsSelectors.getSelectedUserId(state)
  const selectedUserId = notificationUserId || activeUserId
  const currentUserSelected = !notificationUserId || (notificationUserId === activeUserId)
  return {
    notifications: combinedNotificationsSelectors.getNotificationsWithActionedValues(state),
    hasMore: notificationsSelectors.getNotificationsHasMore(state),
    isLoading: notificationsSelectors.getNotificationsIsLoadingInitial(state),
    canViewOtherUsersNotifications: authSelectors.getHasViewOtherUsersNotifications(state),
    filtersFalse,
    filters,
    userOptions: authCombinedSelectors.getUserOptionsIncludingCurrentUser(state),
    selectedUserId,
    currentUserSelected
  }
}

const mapDispatchToProps = dispatch => ({
  searchUsers: params => dispatch(usersActions.searchUsersFresh(params)),
  fetchNotifications: params => dispatch(notificationsActions.fetchUserNotificationsFresh(params)),
  dispatch
})

const handleNotificationClick = ({ meta, type, dispatch }) => {
  const customerId = _.get(meta, 'customerId')
  const orderNumber = _.get(meta, 'orderNumber')
  const messageId = _.get(meta, 'messageId', '')
  const analyticsData = {
    type,
    customerId
  }
  if (orderNumber) analyticsData.orderNumber = orderNumber
  analyticsService.sendCustomEvent({ type: 'notificationClickThrough', analyticsData })
  let path
  if (type === 'birthday') {
    path = `/customers/${customerId}`
  } else if (type === 'pendingQuestions') {
    path = '/polls'
  } else if (type === 'receivedMessage' || type === 'errorMessage') {
    path = `/messages/${messageId}`
  } else if (type === 'orderStatus') {
    path = `/orders/${orderNumber}`
  }
  path && dispatch(push(path))
}

const handleAttachmentClick = ({ attachment, dispatch }) => {
  let path
  switch (attachment.type) {
    case 'customer':
      path = `/customers/${attachment.id}`
      break
    case 'product':
      path = `/product/${attachment.id}`
      break
    case 'inspiration':
      path = `/inspirations/${attachment.id}`
      break
    case 'file':
    case 'image':
      if (window.cordova) {
        window.open(attachment.url, '_blank', 'location=no')
      } else {
        window.open(attachment.url, '_blank')
      }
      break
    default:
      break
  }

  path && dispatch(push(path))
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onFiltersChange: ({ dispatch, filters, selectedUserId }) => status => {
      const newFilters = {
        ...filters,
        ...status
      }
      dispatch(notificationsActions.updateNotificationFilters(newFilters))
      selectedUserId
        ? dispatch(notificationsActions.fetchUserNotificationsFresh({ ...newFilters, id: selectedUserId }))
        : dispatch(notificationsActions.fetchPersonalNotificationsFresh(newFilters))
    },
    onUserInputChange: ({ searchUsers }) => _.debounce((inputValue) => {
      searchUsers({ query: inputValue })
    }, 500),
    onClick: ({ dispatch }) => ({ meta, type }) => {
      handleNotificationClick({ meta, type, dispatch })
    },
    onToggleStatus: ({ dispatch }) => ({ id, status }) => {
      dispatch(notificationsActions.setNotificationUserStatus({ id, status }))
    },
    onViewAttachmentsClick: ({ dispatch }) => ( attachments ) => {
      modalService.open({
        component: ViewAttachmentModal,
        attachments,
        onAttactmentClick: ( attachment ) => {
          handleAttachmentClick({ attachment, dispatch })
          modalService.close()
        }
      })
    },
    fetchFreshNotifications: ({ dispatch, filters }) => e => {
      const userId = _.get(e, 'target')
      ? _.get(e.target, 'value')
      : e
      dispatch(notificationsActions.fetchUserNotificationsFresh(({ ...filters, id: userId })))
    },
    fetchNextNotifications: ({ dispatch, filters, selectedUserId }) => () => {
      dispatch(notificationsActions.fetchUserNotificationsNext({ ...filters, selectedUserId }))
    },
    onToggleActioned: ({ dispatch }) => ({ id, isActioned }) => {
      dispatch(notificationsActions.setNotificationActionedStatus({ id, isActioned }))
    }
  })
)(NotificationsScreen)
