import { connect } from 'react-redux'

import { selectors as customerDetailSelectors, actions as customerDetailActions } from '../../../../store/modules/customerDetails'

import EditNote from './EditNote'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const noteId = ownProps.match.params.noteId
  const customer = customerDetailSelectors.getCustomer(state)
  const customerNotes = customerDetailSelectors.getCustomerNotes(state) || []
  const note = customerNotes.find(note => note.id === noteId)
  const editCustomerNote = (params) => {
    dispatch(customerDetailActions.editCustomerNote(params))
  }
  const onSubmit = ({ note }) => {
    const notes = customerNotes.map(currentNote => {
      if (currentNote.id === noteId) {
        return {
          ...currentNote,
          note,
          // update timestamp to reflect update
          timestamp: new Date().toISOString()
        }
      } else {
        return currentNote
      }
    })
    return editCustomerNote({
      id: customer.id,
      notes
    })
  }
  return {
    ...ownProps,
    onSubmit,
    note
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditNote)
