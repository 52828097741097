import { connect } from 'react-redux'
import { pure } from 'recompose'

import ProductsRouter from './ProductsRouter'

const mapStateToProps = state => {
  const { currentOrder } = state
  return { currentOrder }
}

export default connect(mapStateToProps)(pure(ProductsRouter))
