import * as validators from '../../../validators'
import { storeLocationsEnabled } from "../../../config"
const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const longMax = validators.maxValue(180)
const longMin = validators.minValue(-180)
const latMax = validators.maxValue(90)
const latMin = validators.minValue(-90)

export default ({ regions = [], catalogues = [] } = {}) => {
    const schema = [{
      id: 'name',
      field: 'Input',
      props: {
        label: 'Name',
        name: 'name',
        required: true
      }
    }, {
      id: 'externalStoreId',
      field: 'Input',
      props: {
        label: 'External ID',
        name: 'externalStoreId',
        required: true
      }
    }, {
      id: 'regionId',
      field: 'Dropdown',
      props: {
        label: 'Region',
        name: 'regionId',
        options: [regions],
        validate: [smallTextLengthValidator],
        required: true
      }
    }, {
      id: 'catalogue',
      field: 'AutoComplete',
      props: {
        label: 'Catalogue',
        name: 'catalogue',
        required: true,
        autocomplete: true,
        options: catalogues
      }
    }, {
      id: 'contactEmail',
      field: 'Email',
      props: {
        label: 'Contact Email',
        name: 'contactEmail',
        validate: [validators.email, smallTextLengthValidator],
        required: true,
        showEmailButton: false
      }
    }]
    const layout = [
      ['name']
    ]

  if(storeLocationsEnabled) {
    schema.push(
    {
      id: 'city',
      field: 'Input',
      props: {
        label: 'City',
        name: 'city',
        required: true
      }
    },
    {
      id: 'longitude',
      field: 'Input',
      props: {
        label: 'Longitude',
        name: 'longitude',
        validate: [longMax, longMin],
        required: true
      }
    },
    {
      id: 'latitude',
      field: 'Input',
      props: {
        label: 'Latitude',
        name: 'latitude',
        validate: [latMax, latMin],
        required: true
      }
    })
  }

  return { schema, layout }
}

