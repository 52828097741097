import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { groupMessagingSearchFunctions, followedCustomerFunctions } from '../../dependencies'
import { uiConfig } from '../../CustomerSearch/uiConfig'
import { maxCustomersForGroupMessaging, customerSearchColumns, dateFormat } from '../../../config'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { useSelector } from 'react-redux'
import { formatCustomerSearchColumns } from '../../../helpers'

export const GroupCustomerSearch = ({ onBack, onSelect, selectedCommunicationType }) => {
  const userId = useSelector(authSelectors.getActiveUserId)
  const implementation = Modules.Abstract.Implementation.provideUseResourceSearch({
    resourceSearchFunctions: groupMessagingSearchFunctions,
    columns: formatCustomerSearchColumns(customerSearchColumns, dateFormat)
  })

  const followedCustomerImplementation = Modules.CustomerSearch.Implementation.provideUseFollowedCustomers({
    followedCustomerFunctions,
    userId,
    selectedCommunicationType
  })

  return (
    <Modules.CustomerSearch.Screens.CustomerMultiSelectSearchScreen
      followedCustomerImplementation={followedCustomerImplementation}
      implementation={implementation}
      selectedCommunicationType={selectedCommunicationType}
      uiConfig={uiConfig}
      onBack={onBack}
      onSelect={onSelect}
      maxCustomers={maxCustomersForGroupMessaging}
    />
  )
}
