const itemInner = theme => ({
  margin: '0 23px',
  borderTop: `1px solid ${theme.palette.border.main}`
})

const style = theme => ({
  containerContainer: {
    position: 'relative',
    zIndex: 0
  },
  container: {
    position: 'relative',
    zIndex: -1
  },
  boxContainer: {
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.contentBoxGrey.main}`
    }
  },
  groupTitleContainer: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: 0,
    '&:before': {
      content: '" "',
      display: 'block',
      marginTop: -theme.headerHeight,
      height: theme.headerHeight,
      visibility: 'hidden'
    }
  },
  groupTitle: {
    textAlign: 'center',
    backgroundColor: theme.palette.alphabetListGrey.main,
    textTransform: 'uppercase',
    margin: 0,
    lineHeight: '30px',
    fontSize: '18px'
  },
  itemsContainer: {
    position: 'relative',
    zIndex: 1,
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  itemContainer: {
    marginBottom: '-1px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:first-child > div': {
      borderTop: 0
    },
    '&:hover': {
      backgroundColor: theme.palette.tableHoverGrey.main
    }
  },
  itemInner: {
    ...itemInner
  },
  itemInnerNarrow: {
    ...itemInner,
    margin: '0 6px'
  },
  itemInnerNoMargin: {
    ...itemInner,
    margin: 0
  },
  boxHeader: {
    lineHeight: '55px',
    border: `1px solid ${theme.palette.contentBoxGrey.main}`,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: 0,
    fontSize: 13,
    [theme.breakpoints.up('sm')]: {
      fontSize: 19
    }
  }
})

export default style
