const styles = (theme) => ({
  top: {
    paddingTop: 'env(safe-area-inset-top)'
  },
  bottom: {
    paddingBottom: 'env(safe-area-inset-bottom)'
  },
  left: {
    paddingLeft: 'env(safe-area-inset-left)'
  },
  right: {
    paddingRight: 'env(safe-area-inset-right)'
  }
})

export default styles
