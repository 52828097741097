import _ from 'lodash'
import { setPaymentMethodAndCardType } from '../../../formatters/salesforcePaymentFormatter'
import { salesforceOCAPI } from '../../../config'


export const getPaymentCardOptions = state => _.chain(state)
  .get('currentOrderSalesforce.paymentOptions')
  .filter(paymentMethod => paymentMethod.id !== salesforceOCAPI.ifcPaymentMethodId)
  .reduce((options, current) => {
    if (current.cards && current.cards.length) {
      current.cards.forEach(card => {
        options.push({
          label: `${current.name} - ${card}`,
          value: setPaymentMethodAndCardType(current.id, card)
        })
      })
    } else {
      options.push({
        label: current.name,
        value: current.id
      })
    }
    return options
  }, [])
  .value()

export const getBasket = state => _.get(state, 'currentOrderSalesforce.basket', null)
