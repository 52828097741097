import * as validators from '../../../validators'
import {
  countries,
  countryPhoneCodes
} from '../../../config'

import CustomerEmailField from '../../../containers/Customer/CustomerEditableFields/Email'
import CustomerPhoneNumberField from '../../../containers/Customer/CustomerEditableFields/PhoneNumber'
import { general } from '../../../config/configProvider'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: 'Title', // All labels are translated in the FormBody component at runtime
    name: 'title',
    options: general.titles,
    validate: [smallTextLengthValidator],
    required: false
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: 'First Name',
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: 'Last Name',
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'email',
  component: CustomerEmailField,
  props: {
    label: 'Email',
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: true,
    showEmailButton: general.communicationOptions.channelKeys.includes('emailMarketing')
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: 'Date Of Birth',
    name: 'dob',
    validate: [validators.date, dobAgeValidator],
    placeholder: 'Date Of Birth - input placeholder'
  }
}, {
  id: 'telephone',
  component: CustomerPhoneNumberField,
  props: {
    label: 'Phone Number',
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: general.communicationOptions.channelKeys.includes('smsMarketing'),
    showPhoneButton: true
  }
}, {
  id: 'address1',
  field: 'Input',
  props: {
    label: 'Address Line One',
    name: 'address.address1',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'address2',
  field: 'Input',
  props: {
    label: 'Address Line Two',
    name: 'address.address2',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'city',
  field: 'Input',
  props: {
    label: 'City',
    name: 'address.city',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'county',
  field: 'Input',
  props: {
    label: 'County',
    name: 'address.county',
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: 'Country',
    name: 'address.country',
    validate: [smallTextLengthValidator],
    options: countries,
    autocomplete: true
  }
}, {
  id: 'postCode',
  field: 'Input',
  props: {
    label: 'Customer Postcode',
    name: 'address.postCode',
    validate: [smallTextLengthValidator]
  }
}]
