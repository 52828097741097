import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Input } from '../../../components/Fields'
import * as validators from '../../../validators'
import { translations, passwordRequirements } from '../../../config'
import Column from '../../Form/Column'
import Row from '../../Form/Row'
import List from '../../../components/Form/FormSections/List'

import style from './style'

const PasswordConfirm = ({ classes }) => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.field}>
          <Input
            label={translations('Mandatory Password')}
            name='password'
            type='password'
            validate={[validators.password]}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.field}>
          <Input
            label={translations('Mandatory Confirm Password')}
            name='confirmPassword'
            type='password'
            validate={[validators.passwordConfirmation]}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Row>
      <Column>
        <div className={classes.rowNoMargin}>
          <List listTitle={translations('Password Requirements Title')} items={passwordRequirements} />
        </div>
      </Column>
    </Row>
  </div>
)

export default withStyles(style)(PasswordConfirm)
