import _ from 'lodash'

import getAggregateData from './getAggregateData'
import { getItemValue } from './helpers'

const getListData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { type: chartType, data, fullList } = reportSchema
  const { groupBy, sortBy, aggregateFrom } = data

  let listData = []

  if (chartType === 'LIST' || (fullList && !groupBy)) {
    if (aggregateFrom) {
      const aggregateData = getAggregateData(props)
      if (aggregateData) {
        if (Array.isArray(aggregateData)) {
          listData = aggregateData
        } else {
          listData = (
            Object.keys(aggregateData)
            .map(key => {
              return {
                ...aggregateData[key],
                key
              }
            })
          )
        }
      }
    } else {
      listData = items
    }

    if (sortBy) {
      listData = _.orderBy(listData, [item => getItemValue(item, sortBy, props)], ['desc'])
    }

    return listData.length ? listData : undefined
  }
}

export default getListData
