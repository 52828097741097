import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import { push } from 'connected-react-router'
import ViewInspirationScreen from './ViewInspirationScreen'
import { actions as inspirationDetailsActions, selectors as inspirationDetailsSelectors } from '../../../store/modules/inspirationDetails'
import { actions as inspirationsActions } from '../../../store/modules/inspirations'
import { actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { actions as appActionsModules, selectors as appSelectors } from '../../../store/modules/app'
import withFullScreenLoader from '../../../components/FullScreenLoader'
import HorizontalGallery from '../../../components/HorizontalGallery'
import ViewGalleriesScreen from '../ViewGalleriesScreen'
import BoudoirScreen from '../BoudoirScreen'
import { BRAND_INSPIRATIONS } from '../../../store/modules/app/constants'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'

const mapStateToProps = state => {
  const userRoleCanDelete = authSelectors.getHasDeleteInspiration(state)
  const inspirationBelongsToUser = authCombinedSelectors.getInspirationBelongsToUser(state)
  const inspiration = inspirationDetailsSelectors.getResult(state)
  const selectedGalleryIndex = inspirationDetailsSelectors.getSelectedGalleryIndex(state)
  const hasMultipleGalleries = inspirationDetailsSelectors.getHasMultipleGalleries(state)
  const galleries = inspirationDetailsSelectors.getSkinToneGalleries(state)
  const videoGalleries = _.get(inspirationDetailsSelectors.getVideoGalleries(state), '[0].media', [])
  const activeGallery = inspirationDetailsSelectors.getActiveGallery(state)
  const appointmentInspirations = currentAppointmentSelectors.getAppointmentInspirations(state)
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const isSelectedForAppointment = !!_.find(appointmentInspirations, (item) => {
    return inspiration ? _.get(item, 'details.id') === inspiration.id : false
  })

  return {
    isLoading: inspirationDetailsSelectors.getIsLoading(state),
    inspiration,
    userCanDeleteInspiration: userRoleCanDelete || inspirationBelongsToUser,
    inspirationBelongsToUser: inspirationBelongsToUser,
    canSendMessage: authSelectors.getIsMessagingAllowedForRole(state),
    inspirationFailedToLoad: inspirationDetailsSelectors.getInspirationFailedToLoad(state),
    selectedGalleryIndex,
    hasMultipleGalleries,
    activeGallery,
    isMessagingAllowedForRole,
    messageContent: inspirationDetailsSelectors.getMessageContent(state),
    galleries: galleries,
    videoEmbeds: _.map(videoGalleries, gallery => gallery.src),
    videoThumbs: _.map(videoGalleries, gallery => gallery.thumb),
    contentForOrder: inspirationDetailsSelectors.getContentForOrder(state),
    selected: isSelectedForAppointment
  }
}

const fetchDetails = ({ dispatch, inspirationId }) => {
  return dispatch(inspirationDetailsActions.fetchInspiration({ id: inspirationId }))
}

const boudoirModal = ({ dispatch, replace, inspiration, contentForOrder, dismiss, selectMode }) => modalService.open({
  component: BoudoirScreen,
  zoomModal: true,
  fullScreen: true,
  inModal: true,
  modalIndex: 1,
  onGalleryClick: (index) => {
    dispatch(inspirationDetailsActions.setActiveGalleryIndex(index))
    modalService.close({ modalIndex: 1 })
  },
  onClickNavigateToPrevious: (previousInspiration) => {
    fetchDetails({ dispatch, inspirationId: previousInspiration })
    replace(`/inspirations/${previousInspiration}`)
  },
  onClickNavigateToNext: (nextInspiration) => {
    fetchDetails({ dispatch, inspirationId: nextInspiration })
    replace(`/inspirations/${nextInspiration}`)
  },
  onAddToConsultationClick: () => {
    dispatch(currentAppointmentActions.addContent({ type: 'inspiration', details: contentForOrder }))
    selectMode && modalService.close({ modalIndex: 1 })
    selectMode && dismiss && dismiss()
  }
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['match', 'inspirationId'],
    ({ match, inspirationId }) => {
      return {
        inspirationId: _.get(match, 'params.inspirationId', inspirationId)
      }
    }
  ),
  lifecycle({
    componentDidMount () {
      const { dispatch, inspirationId } = this.props
      fetchDetails({ dispatch, inspirationId }).then((data) => {
        // boudoirModal({
        //   ...this.props,
        //   contentForOrder: {
        //     id: data.id,
        //     title: data.title,
        //     description: data.description,
        //     image: _.get(data, 'galleries[0].media[0].src', null),
        //     link: _.get(data, 'details.link', null)
        //   }
        // })
      })
    }
  }),
  withHandlers({
    onTagClick: ({ dispatch }) => tagId => {
      dispatch(push('/inspirations'))
      dispatch(appActions.changeInspirationsTab({ tab: BRAND_INSPIRATIONS }))
      dispatch(inspirationsActions.searchInspirationsFresh({ tagId, type: 'brand' }))
    },
    onDeleteClick: ({ dispatch, inspiration }) => () => {
      modalService.action({
        title: translations('Delete Inspiration Title'),
        text: translations('Delete Inspiration Text'),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => {
              dispatch(inspirationDetailsActions.deleteInspiration({ id: inspiration.id }))
            },
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    },
    onSendMessageClick: ({ messageContent, dispatch }) => () => {
      dispatch(createMessageActions.addContentToMessage({ content: [messageContent] }))
    },
    onImageClick: ({ inspiration }) => () => {
      return modalService.open({
        component: HorizontalGallery,
        zoomModal: true,
        fullScreen: true,
        images: inspiration.images || [],
        inModal: true
      })
    },
    onGallerySelectClick: ({ dispatch }) => () => {
      return modalService.open({
        component: ViewGalleriesScreen,
        zoomModal: true,
        fullScreen: true,
        inModal: true,
        modalIndex: 1,
        onGalleryClick: (index) => {
          dispatch(inspirationDetailsActions.setActiveGalleryIndex(index))
          modalService.close({ modalIndex: 1 })
        }
      })
    },

    onBoudoirClick: (params) => () => {
      params.dispatch(appActionsModules.customerModeToggle({ isOn: true }))
      return boudoirModal(params)
    },
    onAddToConsultationClick: ({ dispatch, contentForOrder, dismiss, selectMode }) => () => {
      dispatch(currentAppointmentActions.addContent({ type: 'inspiration', details: contentForOrder }))
      selectMode && dismiss && dismiss()
    }
  }),
  withFullScreenLoader
)(ViewInspirationScreen)
