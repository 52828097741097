import { useSelector } from 'react-redux'

import { getIsLoading } from '../store/modules/products/selectors'

/**
 * @description returns if currently loading products
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}
