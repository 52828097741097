let uiConfigStore = {}

export function setUiConfig(componentName, uiConfig) {
  uiConfigStore[componentName] = {
    ...uiConfigStore[componentName],
    ...uiConfig
  }
}

export function getUiConfig(componentName) {
  return uiConfigStore[componentName] || {}
}

window.setUiConfig = setUiConfig
