import React, { Component, Fragment } from 'react'
import { compose } from 'recompose'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import VisuallyHidden from '@reach/visually-hidden'

import style from './style'

import { translations } from '../../../config'
import OrderKanban from '../../../components/OrderKanban'
import OrderKanbanCard from '../../../components/OrderKanbanCard'
import withFullScreenLoader from '../../../components/FullScreenLoader'
import P from '../../../components/P'
import SearchInput from '../../../components/SearchInput'
import Heading from '../../../components/Heading'
import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'

class StoreroomKanbanScreen extends Component {
  _getOrderByForStatus = (status) => (order) => {
    const sh = _.get(order, 'details.statusHistory', [])
    const dateOrderWasSetToThisStatus = _.get(
      _.findLast(sh, shEntry => (_.get(shEntry, 'status') === status)),
      'date'
    )
    return dateOrderWasSetToThisStatus
  }

  renderOrder = (order) => {
    const { goToOrder } = this.props
    return (<OrderKanbanCard key={order.id} {...{ order, handleAction: goToOrder, fields: this.props.config.summaryFields }} />)
  }

  renderEmptyColumn = (column) => {
    const { debouncedQuery, classes } = this.props

    return <li className={classes.emptyColumn}>
      <P
        className={classes.emptyColumnText}
        value={
          debouncedQuery
            ? translations('No Matching Orders')
            : translations('No Orders')
        }
      />
    </li>
  }

  renderSubHeader = () => {
    const { onChangeQuery, query, fetchOrders, classes } = this.props
    const leftContent = <BackBar />
    const centerContent = <div className={classes.searchContainer}>
      <SearchInput
        onChange={onChangeQuery}
        value={query}
        placeholder={translations('Storeroom Kanban Search Placeholder')}
        autoFocus={false}
      />
    </div>
    const rightContent = <IconButton
      className={classes.refreshIcon}
      onClick={fetchOrders}
    >
      <RefreshIcon />
    </IconButton>
    const subHeaderProps = {
      leftContent,
      centerContent,
      rightContent
    }

    return <SubHeader {...subHeaderProps} />
  }

  renderKanban = () => {
    const columns = this.props.config.columns.map(col => {
      col.title = translations(col.title)
      if (col.orderByStatus) {
        col.orderBy = this._getOrderByForStatus(col.orderByStatus)
      }
      return col
    })

    const { ordersByStatus, onChangeColumn, selectedColumn, classes } = this.props
    return <OrderKanban
      ordersByStatus={ordersByStatus}
      columns={columns}
      renderOrder={this.renderOrder}
      renderEmptyColumn={this.renderEmptyColumn}
      onChangeColumn={onChangeColumn}
      selectedColumn={selectedColumn}
      className={classes.kanban}
      padding={20}
    />
  }

  renderMobile = (config) => {
    const { debouncedQuery, ordersList = [], classes } = this.props
    if (debouncedQuery) {
      return <div className={classes.searchResultsMobile}>
        {
          ordersList.length
            ? ordersList.map(this.renderOrder)
            : this.renderEmptyColumn()
        }
      </div>
    } else {
      return this.renderKanban(config)
    }
  }

  renderDesktop = (config) => {
    return this.renderKanban(config)
  } 
  
  render () {
    return <Fragment>
      <VisuallyHidden>
        <Heading component={'h1'}>{translations('Pick From Store')}</Heading>
      </VisuallyHidden>
      {this.renderSubHeader()}
      <Hidden smUp>{this.renderMobile()}</Hidden>
      <Hidden xsDown>{this.renderDesktop()}</Hidden>
    </Fragment>
  }
}

export default compose(
  withFullScreenLoader,
  withStyles(style)
)(StoreroomKanbanScreen)
