import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { customerFunctions } from '../dependencies'

export const CustomerRegistrationCustomerModeScreen = ({ showPasswordModal, dispatch, registrationType }) => {
  const implementation = Modules.Customer.Implementation.provideUseCustomerRegistrationCustomerModeScreen({
    customerFunctions,
    exit: formData => {
      showPasswordModal()
      customerFunctions.handleExitCustomerMode(dispatch, formData)
    },
    registrationType
  })

  return (
    <Modules.Customer.Screens.CustomerRegistrationCustomerModeScreen
      implementation={implementation}
    />
  )
}
