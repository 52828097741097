import BasketRouter from '../BasketRouter'
import modalService from '../../../services/modalService'
import FullScreenButton from '../../../components/FullScreenButton'
import { connect } from 'react-redux'
import { actions as appActions } from '../../../store/modules/app'

export default connect(null, dispatch => {
  return {
    onFullScreen: () => {
      dispatch(appActions.toggleCustomerReviewBasket({ isOpen: true }))
      modalService.open({
        component: BasketRouter,
        initialPath: `/basket`,
        fullScreen: true,
        inModal: true,
        modalIndex: 1,
        noRouting: true,
        onDone: () => {
          dispatch(appActions.toggleCustomerReviewBasket({ isOpen: false }))
          return modalService.close({ modalIndex: 1 })
        }
      })
    }
  }
})(FullScreenButton)
