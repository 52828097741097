import React from 'react'

import GroupList from '../GroupList'

const AlphabetList = props => {
  const {
    items,
    sortKey,
    ...rest
  } = props

  const itemsByLetter = items.reduce((acc, item) => {
    const key = item[sortKey]
    if (!key) return acc
    const letter = key.slice(0, 1).toLowerCase()
    acc[letter] = (acc[letter] || []).concat(item)
    return acc
  }, {})

  return <GroupList data={itemsByLetter} {...rest} />
}

export default AlphabetList
