export const months = [
  { name: 'january', days: 31 },
  { name: 'february', days: 29 },
  { name: 'march', days: 31 },
  { name: 'april', days: 30 },
  { name: 'may', days: 31 },
  { name: 'june', days: 30 },
  { name: 'july', days: 31 },
  { name: 'august', days: 31 },
  { name: 'september', days: 30 },
  { name: 'october', days: 31 },
  { name: 'november', days: 31 },
  { name: 'december', days: 31 }
]
