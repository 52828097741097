import React from 'react'
import { Modules } from '@redant/retailos-ui'
import {
  httpClientService,
  virtualConsultationFunctions,
  useResourceDetails,
  useConsultationLocalState
} from '../dependencies'
import modalService from '../../services/modalService'
import { getAppConfig, environment } from '../../config'
import { ProductRouter } from '../ProductRouter'
import { actions as currentOrderActions } from '../../store/modules/currentOrder'
import { actions as appActions } from '../../store/modules/app'
import { InStoreConsultationCheckout } from '../Checkout'
import { getUiConfig } from '../uiConfigStore'

export const InStoreConsultantScreen = ({ match }) => {
  const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields', [])
  const wishlistConfig = getAppConfig('CONSULTATIONS', 'wishlist', true)
  const featuresConfig = {
    features: {
      participant: {
        info: false,
        video: false
      },
      purchasing: true,
      wishlist: wishlistConfig,
      ...getUiConfig('Modules.VirtualConsultation.Screens.ConsultantScreen').features
    }
  }

  const useEndConsultation = Modules.VirtualConsultation.Implementation.provideUseEndConsultation({
    modalService,
    httpClientService,
    currentOrderActions,
    appActions,
    consultationType: 'store'
  })

  const useFinishConsultation = Modules.VirtualConsultation.Implementation.provideUseFinishConsultation({
    modalService,
    httpClientService,
    currentOrderActions,
    appActions,
    consultationType: 'store'
  })

  const useSelectItems = Modules.VirtualConsultation.Implementation.provideUseSelectItems({
    virtualConsultationFunctions,
    modalService,
    ProductRouter
  })

  const useCheckout = Modules.VirtualConsultation.Implementation.provideUseCheckout({
    modalService,
    CheckoutComponent: InStoreConsultationCheckout,
    checkoutType: 'inStoreConsultation'
  })

  const implementation = Modules.VirtualConsultation.Implementation.provideUseConsultantScreen({
    httpClientService,
    routeParams: match.params,
    modalService,
    virtualConsultationFunctions,
    customerFieldsConfig,
    hooks: {
      useEndConsultation,
      useFinishConsultation,
      useSelectItems,
      useSharedState: useConsultationLocalState,
      useResourceDetails,
      useCheckout
    }
  }, 'user')

  return (
    <Modules.VirtualConsultation.Screens.ConsultantScreen
      implementation={implementation}
      uiConfig={{
        ...getUiConfig('Modules.Consultation.Screens.ConsultantScreen'),
        ...featuresConfig,
        digitalStorePayUrl: environment.DIGITAL_STORE_PAY_URL
      }}
    />
  )
}
