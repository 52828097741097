import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import style from './style'
import _ from 'lodash'
import pluralize from 'pluralize'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import { translations, productsFilters, searchWithinCategory } from '../../../../../config'
import H2 from '../../../../../components/H2'
import P from '../../../../../components/P'
import Button from '../../../../../components/Button'
import Dropdown from '../../../../../components/Fields/Dropdown'
import EditMode from '../../../../../components/EditMode'
import SelectedFilters from '../../SelectedFilters'

const TitleBar = ({ 
  total,
  query,
  sort,
  category,
  isLoading,
  classes,
  editMode,
  orderNumber,
  openFilters,
  numberOfActiveFilters,
  productsSortOptions,
  searchProducts
}) => {

  const [currentSort, setCurrentSort] = useState(sort)

  const updateFilter = (e) => {
    const currentSort = e.target.value
    searchProducts({ sort: currentSort })
    setCurrentSort(currentSort)
  }

  const getTitle = () => {
  
    if (total > 0) {
      if (query) {
        if (category && searchWithinCategory) {
          return _.template(translations('Search results for query in category'))({ query, category: category.name })
        } else {
          return _.template(translations('Search results for query'))({ query })
        }
      } else {
        if (category) {
          return _.template(translations('All products in category'))({ category: category.name })
        } else {
          return translations('All products')
        }
      }
    } else if (!isLoading) {
      if (query) {
        if (category) {
          return _.template(translations('No products found for query in category'))({ query, category: category.name })
        } else {
          return _.template(translations('No products found for query'))({ query })
        }
      } else {
        return translations('No products found')
      }
    }
  }

  const title = getTitle()
  const activeFiltersString = numberOfActiveFilters ? ` (${numberOfActiveFilters})` : ''
  const sortOptions = _.keyBy(productsFilters, 'value')

  return (
    <div>
      {editMode ? <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} /> : null}
      <Grid className={classes.container} container spacing={2}>
        <Grid className={classes.sortContent} item xs={12} md={4}>
          <Button buttonType='primary' onClick={openFilters}>
            {`${translations('Filter')}${activeFiltersString}`}
          </Button>
        </Grid>
        <Grid className={classes.titleContent} item xs={12} md={4}>
          <H2 value={title} className={classes.title} />
        </Grid>
        <Grid className={classes.sortContent} item xs={12} md={4}>
          <P className={classes.productCount} value={total > 0 && `${total} ${pluralize(translations('Product'), total)}`} />
          <div className={classes.filterContainer}>
            <FormControl>
              <Dropdown
                label={translations('Sort') || undefined}
                value={currentSort || ''}
                onChange={updateFilter}
                options={sortOptions}
                nullOptionLabel={translations('Default')}
                noErrorTextLabel
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={2}>
        <Grid className={classes.sortContent} item xs={12} md={12}>
          <SelectedFilters formId={'productsFilters'}/>
        </Grid>
      </Grid>
    </div>
  )
}

TitleBar.propTypes = {
  total: PropTypes.number,
  query: PropTypes.string,
  category: PropTypes.object,
  isLoading: PropTypes.bool
}

export default withStyles(style)(TitleBar)
