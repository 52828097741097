import _ from 'lodash'
import uuid from 'uuid/v4'

import { defaultState, defaultSchema } from './store'

class CurrentAppointmentReducers {
  startAppointment = (state, action) => {
    if (action.status !== 'SUCCESS') {
      return { ...state }
    }
    const appointment = action.result
    return {
      loadingStatus: action.status,
      ...defaultSchema,
      ...state,
      ...appointment,
      contents: state.contents || []
    }
  }

  clearAppointment = (state, action) => {
    return {
      ...defaultState
    }
  }

  addContent = (state, action) => {
    const { content } = action
    const { type, details } = content || {}
    const currentAppointmentContents = _.get(state, 'contents', [])

    if (!type || !details) {
      return {
        ...state
      }
    }

    const newContents = [
      ...currentAppointmentContents,
      {
        ...content,
        details: {
          ...content.details,
          // all content must have an id for matching
          // patch id to the content details when does not exist
          ...!_.get(content, 'details.id', false) && { id: uuid() }
        }
      }
    ]

    const sortedContents = _.sortBy(newContents, (item) => {
      return !_.get(item, 'details.service')
    })

    return {
      ...state,
      contents: sortedContents
    }
  }

  removeContent = (state, action) => {
    const { content } = action
    const { type, details } = content || {}
    const currentAppointmentContents = _.get(state, 'contents', [])

    if (!type || !details) {
      return {
        ...state
      }
    }

    const contentsWithRemoved = _.filter(currentAppointmentContents, (currentAppointmentContent) => {
      const matchesType = _.get(currentAppointmentContent, 'type') === type
      const matchesDetailsId = _.get(currentAppointmentContent, 'details.id') === _.get(details, 'id')
      return !(matchesType && matchesDetailsId)
    })

    return {
      ...state,
      contents: [
        ...contentsWithRemoved
      ]
    }
  }

  /**
   * @description Utility function to toggle a key true/false on a specific content item in the currentAppointment contents
   * @param {object} object
   * @param {string} object.property key to toggle
   * @param {boolean} object.inDetails should put the key outside the content details (default true)
   * @param {boolean} object.unique should remove this property from all other content items (default false)
   * @memberof CurrentAppointmentReducers
   */
  _toggleContentProperty = ({ property, inDetails = true, unique = false }) => (state, action) => {
    const { content } = action
    const { type, details } = content || {}
    const currentAppointmentContents = _.get(state, 'contents', [])

    if (!type || !details) {
      return {
        ...state
      }
    }

    const contentsWithToggleProperties = _.map(currentAppointmentContents, (currentAppointmentContent) => {
      const matchesType = _.get(currentAppointmentContent, 'type') === type
      const matchesDetailsId = _.get(currentAppointmentContent, 'details.id') === _.get(details, 'id')

      if (!(matchesType && matchesDetailsId)) {
        if (!unique) {
          return currentAppointmentContent
        }

        return {
          ..._.omit(currentAppointmentContent, [property]),
          details: {
            ..._.omit(_.get(currentAppointmentContent, 'details', {}), [property])
          }
        }
      }

      return {
        ...currentAppointmentContent,
        details: {
          ..._.get(currentAppointmentContent, 'details', {}),
          ...inDetails && { [property]: !_.get(_.get(currentAppointmentContent, 'details', {}), property, false) }
        },
        ...!inDetails && { [property]: !_.get(currentAppointmentContent, property, false) }
      }
    })

    return {
      ...state,
      contents: [
        ...contentsWithToggleProperties
      ]
    }
  }

  toggleContentIsShowing = this._toggleContentProperty({ property: 'isShowing', inDetails: false, unique: true })
  toggleContentIsLoved = this._toggleContentProperty({ property: 'isLoved' })

  setStage = (state, action) => {
    const { stage } = action

    if (state.stage === 'COMPLETE' || (state.stage === 'PAYMENT_PENDING' && stage !== 'PAYMENT_SUCCESS')) {
      return { ...state }
    }

    return {
      ...state,
      stage
    }
  }

  setOrderNumber = (state, action) => {
    const { orderNumber } = action
    return {
      ...state,
      orderNumber
    }
  }

  setCustomer = (state, action) => {
    const { customerId } = action
    return {
      ...state,
      customerId
    }
  }

  setCustomerDetails = (state, action) => {
    const { customer } = action
    return {
      ...state,
      customer: {
        firstName: _.get(customer, 'firstName')
      }
    }
  }

  updateCurrentAppointment = (state, action) => {
    if (action.status !== 'SUCCESS') {
      return {
        ...state
      }
    }

    return {
      ...state,
      ...action.result
    }
  }

  setCurrentAppointment = (state, action) => {
    return {
      consultationStartTime: _.get(state, 'consultationStartTime'),
      ...action.currentAppointment
    }
  }

  setConsultationStartTime = (state, action) => {
    return {
      ...state,
      consultationStartTime: new Date().toISOString()
    }
  }

  endAppointment = (state, action) => {
    return {
      ...state,
      ended: true
    }
  }

}

export default new CurrentAppointmentReducers()
