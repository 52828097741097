import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'

import * as authSelectors from '../auth/selectors'

class DepartmentsMiddleware {
  fetchDepartmentsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      if (
        authSelectors.getIsLoggedIn(getState()) &&
        (
          matchPath(path, { path: '/select-department', exact: true }) ||
          // also matches users/new
          matchPath(path, { path: '/users/:id', exact: true })
        )
      ) {
        dispatch(actions.fetchDepartments())
      }
    }
  }
}

export default new DepartmentsMiddleware()
