import { connect } from 'react-redux'

import ScanButton from '../../../components/ScanButton'

import {
  actions as currentOrderActions
} from '../../../store/modules/currentOrder'
import { environment, continuousBasketScanEnabled } from '../../../config'

const isContinuousMode = continuousBasketScanEnabled && !!environment.SCANDIT_LICENSE_KEY

export default connect(null, dispatch => ({
  onClick: ({ product }) => {
    dispatch(currentOrderActions.addProduct({ product, quantity: 1, silent: isContinuousMode }))
  },
  continuousMode: isContinuousMode,
  scanScreen: true
}))(ScanButton)
