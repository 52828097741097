import { createSelector } from 'reselect'
import _ from 'lodash'

import { selectors as inspirationsSelectors } from '../inspirations'
import { selectors as inspirationDetailsSelectors } from '../inspirationDetails'

export const getNextInspiration = createSelector([
  inspirationsSelectors.getResults,
  inspirationDetailsSelectors.getResult
], (inspirations = [], inspirationDetails) => {
  const currentInspirationId = inspirationDetails.id
  const currentInspirationIndex = _.findIndex(inspirations, i => i.id === currentInspirationId)

  if (currentInspirationIndex === (inspirations.length - 1)) {
    return _.first(inspirations).id
  }

  return inspirations[currentInspirationIndex + 1].id
})

export const getPreviousInspiration = createSelector([
  inspirationsSelectors.getResults,
  inspirationDetailsSelectors.getResult
], (inspirations = [], inspirationDetails) => {
  const currentInspirationId = inspirationDetails.id
  const currentInspirationIndex = _.findIndex(inspirations, i => i.id === currentInspirationId)

  if (currentInspirationIndex === 0) {
    return _.last(inspirations).id
  }

  return _.get(inspirations, `[${currentInspirationIndex - 1}].id`, null)
})
