import React from 'react'
import style from '../../style'
import { withStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl'
import { Dropdown } from '../../../../../components/EditableFields'

import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'

const Role = ({ roleOptions, editing, formattedRoleName, changeFieldValue, touchField, classes }) => {
  const handleRoleChange = e => {
    // Changing from a single to multiple dropdown causes crashes
    // Due to the fact that the value needs to be an array for multiple
    changeFieldValue('storeIds', [])
    changeFieldValue('roleId', e.target.value)
    // touches both fields so errors display immediately
    touchField('roleId')
    touchField('storeIds')
  }
  return (
    <FormControl className={classes.dropDownStandard}>
      <Dropdown
        label={`${translations('Mandatory Role')} *`}
        name='roleId'
        options={roleOptions}
        editing={editing}
        displayValue={formattedRoleName}
        validate={[validators.required]}
        onChange={(e) => handleRoleChange(e)}
        classes={{root: classes.dropDownStandard}}
      />
    </FormControl>
  )
}

export default withStyles(style)(Role)
