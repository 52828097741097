import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class StoresActions {
  fetchAllStores = (params) => ({
    type: constants.FETCH_ALL_STORES,
    promise: () => digitalStoreSdk.stores.fetchStores(params)
  })
  searchStoresFresh = ({ query }) => ({
    type: constants.SEARCH_STORES_FRESH,
    query,
    promise: (dispatch, getState) => {
      const searchStoresQuery = query === undefined ? selectors.getQuery(getState()) : query
      const size = selectors.getSize(getState())
      return digitalStoreSdk.stores.searchStores({
        query: searchStoresQuery,
        page: 1,
        size
      })
    }
  })
}

export default new StoresActions()
