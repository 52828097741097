import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { selectors as orderDetailsSelectors } from '../../../../store/modules/orderDetails'

import { dateFormat } from '../../../../config'
import { getSchemaConfig } from '../../../../retailos/schemaConfigStore'
import { formatDate, getDeliveryPrice } from '../../../../helpers'
import OrderDetail from './OrderDetail'

class OrderDetailContainer extends PureComponent {
  _generateProps = () => {
    const { total, subTotal, totalDiscount, tax, orderDate, deliveryOption, status, numberOfPurchaseableProducts } = this.props.order
    const formattedItemString = numberOfPurchaseableProducts === '1' ? 'item' : 'items'
    const configDetailsSchema = getSchemaConfig('OrderDetails').renderConfig
    return {
      ...this.props,
      order: {
        ...this.props.order,
        status: status,
        total: total,
        subTotal: subTotal,
        totalDiscount: totalDiscount,
        tax: tax,
        orderDate: formatDate(orderDate, dateFormat),
        numberOfProducts: `${numberOfPurchaseableProducts} ${formattedItemString}`,
        deliveryPrice: getDeliveryPrice(deliveryOption)
      },
      configDetailsSchema
    }
  }

  render () {
    const props = this._generateProps()
    return <OrderDetail {...props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const isLoading = orderDetailsSelectors.getIsLoading(state) && !orderDetailsSelectors.getNoSpinner(state)
  const failedToLoad = orderDetailsSelectors.getFailedToLoad(state)
  const order = orderDetailsSelectors.getOrder(state)
  const isPartialRefundOrder = orderDetailsSelectors.getIsPartialRefundState(state)

  const mappedState = {
    order,
    isLoading,
    failedToLoad,
    isPartialRefundOrder
  }

  return mappedState
}

export default connect(mapStateToProps)(OrderDetailContainer)
