import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import modalService from '../../services/modalService'
import style from './style'

class Modal extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      open: false,
      component: undefined,
      props: undefined,
      fullScreen: false,
      smallModal: false,
      wideModal: false,
      zoomModal: false,
      tryOnModal: false
    }
    this.state = this.initialState
    this.handleClose = this.handleClose.bind(this)
  }

  open = ({ component, props, fullScreen = false, smallModal = false, wideModal = false, zoomModal = false, tryOnModal = false }) => {
    this.setState({ open: true, component, props, fullScreen, smallModal, wideModal, zoomModal, tryOnModal })
  }

  close = () => {
    this.setState(this.initialState)
  }

  componentDidMount () {
    modalService.connect({ open: this.open, close: this.close, modalIndex: this.props.index })
  }

  componentWillUnmount () {
    modalService.disconnect({ modalIndex: this.props.index })
  }

  handleClose(e, reason) {
    const { props = {} } = this.state
    if (reason === 'backdropClick') {
      props.onBackdropClick && props.onBackdropClick() || this.close()
    } else {
      props.dismiss && props.dismiss() || this.close()
    }
  }

  render () {
    const { open, component: Component, props = {}, fullScreen, smallModal, wideModal, zoomModal, tryOnModal } = this.state
    const { classes } = this.props
    const paperClasses = classNames({
      [classes.container]: !fullScreen,
      [classes.fullScreenContainer]: fullScreen,
      [classes.smallModal]: smallModal && !fullScreen,
      [classes.wideModal]: wideModal && !fullScreen,
      [classes.zoomModal]: zoomModal && !fullScreen,
      [classes.noPadding]: props.noPadding,
      [classes.tryOnModal]: tryOnModal
    })
    const dialogClasses = {
      paper: paperClasses,
      root: classes.root
    }
    return (
      Component
      ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          classes={dialogClasses}
          {...props.dialogProps || {}}
        >
          <Component {...props} />
        </Dialog>
      )
      : null
    )
  }
}

export default withStyles(style)(Modal)
