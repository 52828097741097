import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { environment } from '../../../config'

const Deferred = ({ deferredModule, orderNumber, next }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (deferredModule === 'PAY_BY_LINK') {
      // window.open(`${environment.DIGITAL_STORE_PUBLIC_API}/order/${orderNumber}/pay`, '_blank')
      dispatch(currentAppointmentActions.setOrderNumber({ orderNumber }))
      dispatch(currentAppointmentActions.setStage({ stage: 'PAY_BY_LINK' }))
    } else if (deferredModule === 'PAY_BY_EMAIL') {
      dispatch(currentOrderActions.payByEmail({ orderNumber }))
      dispatch(currentAppointmentActions.setStage({ stage: 'PAY_BY_EMAIL' }))
    } else {
      dispatch(currentAppointmentActions.setStage({ stage: 'COMPLETE' }))
    }
    next()
  }, [])

  return null
}

export default Deferred
