const styles = theme => ({
  dateTime: {
    display: 'flex',
    width: '100%'
  },
  date: {
    width: '65%'
  },
  time: {
    width: '35%'
  }
})

export default styles
