const style = theme => ({
  container: {
    display: 'flex',
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.activityBorderGrey.main}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.tableHoverGrey.main
    }
  },
  leftContainer: {
    width: 50,
    display: 'flex',
    justifyContent: 'center'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    padding: 5,
    border: `1px solid ${theme.palette.alphabetListGrey.main}`
  },
  circleImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.lightGrey.main,
    borderRadius: 20,
    height: 38,
    width: 38
  },
  image: {
    height: '100%'
  },
  content: {
    flex: 1,
    paddingLeft: 10,
    overflow: 'hidden'
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 3
  },
  bodyText: {
    color: theme.palette.chartLegendsGrey.main,
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '15px',
    marginBottom: 3
  }
})

export default style
