import * as constants from './constants'
import actions from './actions'
import { constants as authConstants } from '../auth'
import { SUCCESS } from '../../middleware/redux-promise'

class RegionsMiddleware {
  loginSuccessFetchRegionsMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.status === SUCCESS && 
      (action.type === authConstants.LOGIN || action.type === authConstants.GET_SSO_AUTH_CONTEXT) && 
      !action.isCustomerMode
    ) {
      dispatch(actions.fetchAllRegions())
    }

    next(action)
  }
}

export default new RegionsMiddleware()
