const styles = theme => ({
  hide: {
    display: 'none'
  },
  badge: { /* note: do not delete, be overwritten */ },
  badgeRoot: {
    display: 'block'
  }
})

export default styles
