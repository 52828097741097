const style = theme => ({
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonGroupButton: {
    flex: 1,
    borderRadius: 0,
    border: '1px solid gray'
  },
  formContainer: {
    padding: '0px 40px'
  },
  selected: {
    backgroundColor: 'rgba(0,0,0,0.08) !important'
  },
  first: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  last: {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  }
})

export default style
