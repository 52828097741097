import { compose } from 'recompose'
import { connect } from 'react-redux'
import MenuWidget from './MenuWidget'
import { getRegionConfig } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'

const mapStateToProps = state => {
  const configName = getRegionConfig(state)
  const hubTileImages = getTheme(configName).base.hubTileImage
  return {
    hubTileImages
  }
}

export default compose(
  connect(mapStateToProps)
)(MenuWidget)
