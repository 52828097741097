import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import style from './style'
import P from '../../../../components/P'

class CommentBox extends PureComponent {
  render () {
    const { classes, className, value } = this.props
    return (
      <div className={classNames(classes.container, className)}>
        <P
          className={classes.comment}
          value={value}
        />
      </div>
    )
  }
}

export default withStyles(style)(CommentBox)
