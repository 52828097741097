import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  basket: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CREATE_CUSTOMER_BASKET:
      return reducers.createCustomerBasket(state, action)
    case constants.UPDATE_CUSTOMER_BASKET:
      return reducers.updateCustomerBasket(state, action)
    case constants.FETCH_BASKET_PAYMENT_OPTIONS:
      return reducers.fetchBasketPaymentOptions(state, action)
    case constants.CLEAR_BASKET:
      return reducers.clearBasket(state, action)
    default:
      return state
  }
}
