const style = theme => ({
  container: {
    marginTop: 10,
    padding: '0 20px 40px',
    [theme.breakpoints.up('md')]: {
      padding: '0 40px'
    }
  },
  gridItem: {
    display: 'flex',
    padding: '0 20px 40px',
    flexDirection: 'column',
    textAlign: 'center'
  },
  noResults: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: 19
  },
  scrollContainer: {
    display: 'flex'
  }
})

export default style
