import theme from '../../config/theme'

const styles = theme => {
  return {
    relatedProduct: {
      width: '120px', 
      margin: '0 25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        '&:first-child': {
          marginLeft: '25px' 
        },
        '&:last-child': {
          marginRight: '25px'
        }
      }
    },
    image: {
      maxWidth: '120px'
    },
    name: {
      textAlign: 'center'
    },
    price: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: '10px'
    }
  }
}

export default theme => styles(theme)
