import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { goBack } from 'connected-react-router'

import MessageDetailsScreen from './MessageDetailsScreen'
import FullScreenLoader from '../../../components/FullScreenLoader'

import { selectors as messageDetailsSelectors, actions as messageDetailsActions } from '../../../store/modules/messageDetails'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

const mapStateToProps = (state) => ({
  message: messageDetailsSelectors.getResult(state),
  isLoading: messageDetailsSelectors.getIsLoading(state)
})

export default compose(
  FullScreenLoader,
  connect(mapStateToProps),
  withHandlers({
    onFlagClicked: ({ dispatch, message }) => () => {
      modalService.action({
        title: translations('Report Inappropriate Modal Title'),
        text: translations('Report Inappropriate Modal Text'),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => {
              dispatch(messageDetailsActions.flagMessageInappropriate({ messageId: message.id }))
                .then(() => {
                  modalService.close()
                })
            },
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ],
        modalIndex: 1
      })
    },
    onBackClick: ({ dispatch, onGoBackClick }) => () => {
      onGoBackClick
        ? onGoBackClick()
        : dispatch(goBack())
    }
  })
)(MessageDetailsScreen)
