import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import style from './style'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'

const SearchInput = ({
  value = '',
  placeholder,
  onChange,
  classes,
  autoFocus = true
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.searchIconContainer}>
        <SearchIcon className={classes.icon} />
      </div>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={'text'}
        className={classes.searchInput}
        autoCapitalize='none'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        autoFocus={autoFocus}
      />
    </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

export default withStyles(style)(SearchInput)
