import * as authCombinedSelectors from './authCombinedSelectors'
import * as userDetailCombinedSelector from './userDetailCombinedSelector'
import * as variantsCombinedSelectors from './variantsCombinedSelectors'
import * as inspirationDetailsCombinedSelectors from './inspirationDetailsCombinedSelectors'

export {
  authCombinedSelectors,
  userDetailCombinedSelector,
  variantsCombinedSelectors,
  inspirationDetailsCombinedSelectors
}
