import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import activitiesActions from './actions'
import { selectors as authSelectors } from '../auth'
import { FETCH_FOLLOWING } from '../following/constants'
import { SUCCESS } from '../../middleware/redux-promise'
import { FETCH_FULL_CUSTOMER } from '../customerDetails/constants'
import { history } from '../..'

class ActivitiesMiddleware {
  loadClientBookActivities = ({ dispatch, getState }) => next => action => {
    next(action)
    const currentState = getState()
    const hasFetchedFollowingCustomers = action.type === FETCH_FOLLOWING && action.status === SUCCESS
    const isLoggedIn = authSelectors.getIsLoggedIn(currentState)

    if (isLoggedIn && hasFetchedFollowingCustomers) {
      // Client book
      const hasRoutedToClientBook = matchPath(
        currentState.router.location.pathname,
        { path: '/customers', exact: true }
      )

      if (hasRoutedToClientBook) {
        const customerIds = _.chain(action)
          .get('result.items', [])
          .map('customerId')
          .join(',')
          .value()

        if (customerIds && customerIds.length) {
          dispatch(activitiesActions.fetchActivities({
            customerId: { $in: customerIds },
            page: 1,
            limit: 20,
            includes: 'user,customer'
          }))
        }
      }
    }
  }
  loadActivitiesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FETCH_FULL_CUSTOMER && action.status === SUCCESS) {
      const currentPathname = history.location.pathname
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())

      if (!isLoggedIn) {
        return
      }

      //////////////////

      // Full activity list
      const fullRoute = matchPath(currentPathname, { path: '/customers/:id/activity', exact: true })
      if (fullRoute) {
        const customerId = _.get(action, 'result.customer.id')
        dispatch(activitiesActions.fetchActivities({ customerId, page: 1, limit: 20, includes: 'user,customer' }))
        return
      }

      // Latest activity list
      const snapshotRoute = matchPath(currentPathname, { path: '/customers/:id', exact: true })
      if (snapshotRoute && snapshotRoute.params.id !== 'new') {
        const customerId = _.get(action, 'result.customer.id')
        dispatch(activitiesActions.fetchActivities({ customerId, page: 1, limit: 3, includes: 'user,customer' }))

      }
    }
  }
}

export default new ActivitiesMiddleware()
