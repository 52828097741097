const style = theme => ({
  stockContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingRight: 14
  },
  stockIcon: {
    paddingRight: 10
  },
  label: {
    fontWeight: 'bold'
  },
  sublabel: {
    opacity: 0.5
  }
})

export default style
