import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import FormValueProvider from '../../../FormValueProvider'
import RecommendedProducts from './RecommendedProducts'

export default compose(
  FormValueProvider('recommendedProducts'),
  withHandlers({
    onSubmit: ({ onSubmit }) => data => {
      const newData = {
        ...data,
        submitType: 'recommendedProducts'
      }
      return onSubmit(newData)
    }
  }),
  withPropsOnChange(
    ['formValues'],
    ({ formValues, initialFormValues }) => {
      const recommendedProductsValue = _.get(formValues, 'recommendedProducts', [])
      const recommendedProductsInitialValue = _.get(initialFormValues, 'recommendedProducts', [])
      // do not allow a user to save an empty form the first time
      // They can save an empty form if the form had values in it before however (acts as delete)
      const saveDisabled = recommendedProductsValue.length === 0 && recommendedProductsInitialValue.length === 0
      return { saveDisabled }
    }
  )
)(RecommendedProducts)
