import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import { translations } from '../../../config'
import SendFeedbackForm from './SendFeedbackForm'

import style from './style'
import RequiredTextMessage from '../../../components/RequiredTextMessage'

const Feedback = ({ classes, typeOptions, initialValues }) => {
  return (
    <Fragment>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(translations('App Feedback'))} />
        )}
      />
      <div className={classes.contentContainer}>
        <RequiredTextMessage />
        <div className={classes.formContainer}>
          <SendFeedbackForm typeOptions={typeOptions} initialValues={initialValues} />
        </div>
      </div>
    </Fragment>
  )
}

export default withStyles(style)(Feedback)
