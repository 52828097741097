import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import H2 from '../../../../../components/H2'
import Image from '../../../../../components/Image'
import style from './style'
import { translations } from '../../../../../config'
import Button from '../../../../../components/Button'
import Tags from '../../../../../components/Tags'

const Event = ({ classes, onTagClick, onEventClick, defaultGallery = [], event = {}, onEventCTAClick, buttonValue, canSendMessage }) => {
  const { title, tags = [], eventType } = event
  const src = _.get(defaultGallery[0], 'src')
  return (
    <div className={classes.container} onClick={onEventClick}>
      <Image box contain className={classes.image} src={src} />
      <H2 value={title} />
      <Tags
        tags={tags}
        eventType={eventType}
        onTagClick={onTagClick}
      />
      <div className={classes.buttonContainer}>
        { !canSendMessage
          ? null
          : (
            <Button
              onClick={onEventCTAClick}
              className={classes.button}
              buttonType='primary' >
              {buttonValue || translations('Send in message')}
            </Button>
          )
        }
      </div>
    </div>
  )
}

export default withStyles(style)(Event)
