import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const messageIcon = getImage('errorMessageNotificationIcon')

const ReceivedMessageNotification = props => {
  const { customerName, fromCustomer } = props.meta
  const { createdAt } = props
  const text = fromCustomer
    ? _.template(translations('Error Message Notification Incoming Text'))({
      customerName
    })
    : _.template(translations('Error Message Notification Outgoing Text'))({
      customerName
    })
  return (
    <Notification
      {...props}
      iconSource={messageIcon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

ReceivedMessageNotification.propTypes = {
  meta: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    fromCustomer: PropTypes.bool.isRequired
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default ReceivedMessageNotification
