import { connect } from 'react-redux'

import { selectors as eventsSelectors, actions as eventsActions } from '../../../../store/modules/events'
import EventResults from './EventResults'

const mapStateToProps = state => ({
  events: eventsSelectors.getResults(state),
  hasMore: eventsSelectors.getHasMore(state),
  hasSearched: eventsSelectors.getHasSearched(state)
})

const mapDispatchToProps = dispatch => {
  return {
    loadMore: () => dispatch(eventsActions.searchEventsNext())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventResults)
