import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  selectedGalleryIndex: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_INSPIRATION:
      return reducers.fetchInspiration(state, action)
    case constants.DELETE_INSPIRATION:
      return reducers.deleteInspiration(state, action)
    case constants.SET_ACTIVE_GALLERY_INDEX:
      return reducers.setActiveGalleryIndex(state, action)
    default:
      return state
  }
}
