import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import style from './style'

export const CircleButton = ({ onClick, classes, className, color = '#000', Icon, isDisabled = false, label }) => {
  const buttonClasses = cx({
    [classes.container]: true,
    [className]: true,
    [classes.disabled]: isDisabled
  })
  return (
    <button
      aria-label={label}
      onClick={onClick}
      className={buttonClasses}
      style={{ borderColor: color }}
    >
      <Icon
        style={{ fill: color }}
      />
    </button>
  )
}

CircleButton.propTypes = {
  /** `Mui icon to show inside the button */
  Icon: PropTypes.func.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool
}

export default withStyles(style)(CircleButton)
