const styles = theme => ({
  counterWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  value: {
    flex: 1
  },
  button: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid',
    padding: 0,
    width: 36,
    minWidth: 0,
    '&:first-child': {
      marginRight: -1
    }
  }
})

export default theme => styles(theme)
