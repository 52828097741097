import { useSelector } from 'react-redux'

import { getAllCatalogues, getIsLoading } from '../store/modules/productCatalogues/selectors'

/**
 * @description returns catalogues
 * @returns
 */
export const useResults = () => {
  return useSelector(getAllCatalogues)
}

/**
 * @description returns if the store is currently loading catalogues
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}