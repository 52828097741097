const styles = theme => ({
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 24,
    height: 26,
    width: 26,
    padding: 0,
    border: '1px solid #FFFFFF',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    '&:focus': {
      color: theme.palette.primary.main
    }
  }
})

export default styles
