import { connect } from 'react-redux'
import SubCategories from './SubCategories'
import { selectors as categoriesSelectors } from '../../../../store/modules/categories'

const mapStateToProps = (state, props) => ({
  categories: categoriesSelectors.getChildCategoriesById(state, props.catId)
})

export default connect(
  mapStateToProps
)(SubCategories)
