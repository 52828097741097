import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import { selectors as ordersSelectors, actions as ordersActions } from '../../../../../store/modules/orders'
import OrdersTable from './OrdersTable'

const mapStateToProps = state => {
  const orders = ordersSelectors.getOrders(state)
  const isLoading = ordersSelectors.getIsLoading(state)
  const hasMore = ordersSelectors.getHasMore(state)
  const hasSearched = ordersSelectors.getHasSearched(state)
  const noResults = ordersSelectors.getHasNoResults(state)

  return {
    orders,
    isLoading,
    hasMore,
    hasSearched,
    noResults
  }
}

export default compose(
  connect(mapStateToProps),
  // withLoader,
  withHandlers({
    goToOrder: ({ dispatch }) => (orderNumber) => {
      dispatch(push(`/orders/${orderNumber}`))
    },
    searchOrdersNext: ({ dispatch, isLoading, orders }) => () => {
      if (!isLoading) {
        dispatch(ordersActions.searchOrdersNext())
      }
    }
  })
)(OrdersTable)
