import { connect } from 'react-redux'
import React from 'react'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { getPriceValue } from '@redant/digital-store-prices'

import { selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { formatDate } from '../../../../../helpers'
import { getDeliveryText, getCustomerFullName, getFullName } from '../../../../../helpers'
import { translations, noPrices, deliveryEnabled, manualDiscountsEnabled, environment } from '../../../../../config'
import currencyFormatter from '../../../../../formatters/currencyFormatter'

import OrderFooter from './OrderFooter'
import copy from 'copy-to-clipboard'
import toastService from '../../../../../services/toastService/toastService'
import { FileCopyOutlined } from '@material-ui/icons'
import { checkoutFunctions } from '../../../../../retailos/dependencies'

const cf = currencyFormatter.format

const mapStateToProps = (state) => {
  const order = orderDetailsSelectors.getOrder(state)
  return { order }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['order'],
    ({ order }) => {
      const {
        _store,
        customer,
        customerId,
        deliveryDetails,
        deliveryOption,
        deliveryPrice,
        orderType,
        salesChannel,
        subTotal,
        tax,
        total,
        totalDiscount,
        user,
        details,
        status,
        orderNumber
      } = order || {}

      const noCustomer = (!customerId && (_.isEmpty(customer) || !customer))

      const orderInfo = []

      if (deliveryEnabled && orderType !== 'storeroom') orderInfo.push(['deliveryOption', getDeliveryText(noCustomer ? null : deliveryOption)])
      if (deliveryEnabled && _.get(deliveryDetails, 'store')) orderInfo.push(['deliveryStore', _.get(deliveryDetails, 'store.name')])
      if (_store) orderInfo.push(['store', _store.name])
      if (manualDiscountsEnabled && orderType !== 'storeroom') {
        orderInfo.push(['subTotal', cf(subTotal)])
        orderInfo.push(['discount', cf(totalDiscount)])

        const discountBreakdown = checkoutFunctions.mapDigitalStoreOrderDetailsDiscountBreakdown(order)
        for (let breakdown of discountBreakdown) {
          orderInfo.push(breakdown)
        }
      }
      if (getPriceValue(tax)) orderInfo.push(['tax', cf(tax)])
      if (deliveryEnabled && deliveryOption) orderInfo.push(['deliveryCost', cf(deliveryPrice)])
      orderInfo.push(['total', cf(total)])
      if (details.customerPayUrl && status === 'awaiting_payment') {
        orderInfo.push(['Payment', translations('Customer payment link'), {
          clickHandler: true,
          buttonIcon: <FileCopyOutlined style={{ fontSize: '1em', width: '.7rem', marginRight: '3px' }}/>,
          onItemClick: () => {
            const endpoint = `/order/${orderNumber}/pay`
            const copiedUrl = copy(`${environment.DIGITAL_STORE_PAY_URL}${endpoint}`)
            
            if (copiedUrl) {
              toastService.action({
                type: 'success',
                message: translations('Customer payment link successfully copied'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            }
          }
        }])
      }

      return { orderInfo }
    }
  )
)(OrderFooter)
