import { withHandlers, compose } from 'recompose'

import Activity from './Activity'
import analyticsService from '../../../services/analyticsService'

export default compose(
  withHandlers({
    activityClickHandler: ({ onActivityClick }) => () => {
      onActivityClick()
      analyticsService.sendCustomEvent({ type: 'clientActivityCustomerClickThrough' })
    }
  })
)(Activity)
