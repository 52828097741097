import moment from 'moment'

import { dateFormat, customerRegionEnabled } from '../../../config'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { logger } from '../../../helpers'

class OrdersActions {
  _formatDate = dateString => {
    return moment(dateString, dateFormat).toISOString()
  }
  _formatEndDate = dateString => {
    return moment(dateString, dateFormat).endOf('day').toISOString()
  }

  searchOrders = ({ query, page = 1, size = 12, startDate, endDate, userId, storeId, orderType, orderStatus }) => {
    logger.trace('searchOrders')
    logger.debug({ query, page, size, startDate, endDate, userId, storeId, orderType, orderStatus })
    return {
      type: constants.SEARCH_ORDERS,
      promise: (dispatch, getState) => {
        const state = getState()

        const searchQuery = query === undefined ? selectors.getQuery(state) : query
        const searchUserId = userId === undefined ? selectors.getUserId(state) : userId
        const searchStoreId = storeId === undefined ? selectors.getStoreId(state) : storeId
        const searchOrderType = orderType === undefined ? selectors.getOrderType(state) : orderType
        const searchOrderStatus = orderStatus === undefined ? selectors.getOrderStatus(state) : orderStatus

        const searchStartDate = startDate === undefined ? selectors.getStartDate(state) : startDate
        const searchEndDate = endDate === undefined ? selectors.getEndDate(state) : endDate
        const searchStartDateFormatted = searchStartDate ? this._formatDate(searchStartDate) : undefined
        const searchEndDateFormatted = searchEndDate ? this._formatEndDate(searchEndDate) : undefined
        const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined

        return digitalStoreSdk.orders.searchOrders({
          page,
          size,

          query: searchQuery,
          userId: searchUserId,
          storeId: searchStoreId,

          startDate: searchStartDateFormatted,
          endDate: searchEndDateFormatted,
          loggedInStoreId,
          orderType: searchOrderType || undefined,
          status: searchOrderStatus || undefined
        })
      },
      payload: {
        query,
        page,
        size,
        startDate,
        endDate,
        userId,
        storeId,
        orderType,
        orderStatus
      }
    }
  }

  searchOrdersNext = () => {
    return {
      type: constants.SEARCH_ORDERS_NEXT,
      promise: (dispatch, getState) => {
        const state = getState()

        const searchQuery = selectors.getQuery(state)
        const searchUserId = selectors.getUserId(state)
        const searchStoreId = selectors.getStoreId(state)

        const searchStartDate = selectors.getStartDate(state)
        const searchEndDate = selectors.getEndDate(state)
        const searchStartDateFormatted = searchStartDate ? this._formatDate(searchStartDate) : undefined
        const searchEndDateFormatted = searchEndDate ? this._formatEndDate(searchEndDate) : undefined
        const orderType = selectors.getOrderType(state)
        const orderStatus = selectors.getOrderStatus(state)

        const page = selectors.getPage(state)
        const size = selectors.getSize(state)
        const nextPage = page + 1

        const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined

        return digitalStoreSdk.orders.searchOrders({
          page: nextPage,
          size,

          query: searchQuery,
          userId: searchUserId,
          storeId: searchStoreId,

          startDate: searchStartDateFormatted,
          endDate: searchEndDateFormatted,
          loggedInStoreId,
          orderType,
          status: orderStatus
        })
      }
    }
  }

  fetchOrderTypes = () => ({
    type: constants.FETCH_ORDER_TYPES,
    promise: () => {
      return digitalStoreSdk.orders.fetchOrderConfig()
        .then(res => res.orderTypes)
    }
  })
}

export default new OrdersActions()
