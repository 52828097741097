import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 30,
  page: 1,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  sort: undefined,
  category: undefined,
  results: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_PRODUCTS_FRESH:
      return reducers.searchProductsFresh(state, action)
    case constants.SEARCH_PRODUCTS_NEXT:
      return reducers.searchProductsNext(state, action)
    default:
      return state
  }
}
