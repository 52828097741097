const styles = theme => ({
  fullScreenContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  fullScreenButton: {
    backgroundColor: 'transparent'
  },
  closeIcon: {
    width: 26,
    height: 26,
    color: theme.palette.primary.main
  }
})

export default styles
