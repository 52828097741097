import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import OrderHeader from './OrderHeader'

export default compose(
  connect(),
  withHandlers({
    goToCustomer: ({ dispatch, customer = {} }) => () => {
      dispatch(push(`/customers/${customer.id}`))
    }
  })
)(OrderHeader)
