const style = theme => ({
  requiredText: {
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      order: 1,
      alignItems: 'flex-end'
    }
  }
})

export default style
