import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import { Input } from '../../../components/Fields'

import * as validators from '../../../validators'
import style from './style'
import Form, { FormBody, FormError, FormSubmit } from '../../../components/Form'
import { auth, loginTypes, translations } from '../../../config'

const LoginForm = Form('login')
const emailLabel = auth.loginType === loginTypes.USERNAME
  ? 'Username'
  : 'Email'
const emailFieldName = auth.loginType === loginTypes.USERNAME
  ? 'username'
  : 'email'
const emailFieldValidators = auth.loginType === loginTypes.USERNAME
  ? [validators.required]
  : [validators.email, validators.required]

class LoginFormWrapper extends PureComponent {
  componentDidMount() {
    const { changeField, currentUsername } = this.props
    changeField(emailFieldName, currentUsername)
  }

  render() {
    const {
      onSubmit,
      classes,
      isCustomerMode,
      networkConnectivity
    } = this.props
    return (
      <div>
        <LoginForm onSubmit={onSubmit} submitLabel={translations('sign in')}>
          <FormError />
          <FormBody>
            <div className={classes.formBody}>
              <FormControl className={classes.field}>
                <Input
                  label={translations(emailLabel)}
                  name={emailFieldName}
                  validate={emailFieldValidators}
                  autoCapitalize='none'
                  autoComplete='off'
                  autoCorrect='off'
                  spellCheck='off'
                  disabled={!networkConnectivity}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={translations('Password')}
                  name='password'
                  type='password'
                  className={classes.field}
                  validate={validators.required}
                  autoFocus={isCustomerMode}
                />
              </FormControl>
            </div>
          </FormBody>
          <FormSubmit containerClass={classes.centerButtonContainer} label={translations('sign in')} buttonClass={classes.fullWidthButton} />
        </LoginForm>
      </div>
    )
  }
}
export default withStyles(style)(LoginFormWrapper)
