import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ScaleAnswerPicker from '../ScaleAnswerPicker'
import FacesAnswerPicker from '../FacesAnswerPicker'
import ThumbsAnswerPicker from '../ThumbsAnswerPicker'

class AnswerPicker extends PureComponent {
  render () {
    const { classes, question, ...rest } = this.props
    const questionType = _.get(question, 'pollQuestion.type')
    switch (questionType) {
      case 'scale':
        return <ScaleAnswerPicker question={question} {...rest} />
      case 'faces':
        return <FacesAnswerPicker question={question} {...rest} />
      case 'thumbs':
        return <ThumbsAnswerPicker question={question} {...rest} />
      default:
        return null
    }
  }
}

AnswerPicker.propTypes = {
  classes: PropTypes.shape({}),
  question: PropTypes.shape({})
}

export default AnswerPicker
