import _ from 'lodash'

import digitalStoreSdk from '../../../digitalStoreSdk'
// import { apiUpdateSource } from '../../../config'
import { constants } from '.'


class CurrentOrderSalesforceActions {
  createCustomerBasket = params => ({
    type: constants.CREATE_CUSTOMER_BASKET,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.baskets
        .createBasket({
          ...params,
          // updateSource: apiUpdateSource
        })
    }
  })
  updateCustomerBasket = (id, updates) => ({
    type: constants.CREATE_CUSTOMER_BASKET,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.baskets
        .updateBasket({ id, ...updates })
    }
  })
  fetchBasketPaymentOptions = (id) => ({
    type: constants.FETCH_BASKET_PAYMENT_OPTIONS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.baskets
        .fetchBasketPaymentOptions({ id })
    }
  })
  clearBasket = () => ({
    type: constants.CLEAR_BASKET
  })
}

export default new CurrentOrderSalesforceActions()
