import i18next from 'i18next'
import { en } from './configProvider'
import { i18n } from '@redant/retailos-ui'
let languages = []

i18next.init({
  lng: 'en',
  debug: false,
  resources: {
    en: { translation: en }
  }
})

export const translations = (key, options) => i18next.t(key, options)

export const setLanguages = (languagesList) =>  {
  languages = [...languages, ...languagesList]
}

export const setCoreTranslationsWithResources = (translationsList) =>  {
  translationsList.forEach(({ translation, language }) => {
    i18next.addResourceBundle(language, 'translation', translation)
  })
}

export const changeLanguage = (language) => {
  if(language) {
    i18n.changeLanguage(language)
    const hasResourceBundle = i18next.hasResourceBundle(language, 'translation')
    hasResourceBundle ? i18next.changeLanguage(language) : i18next.changeLanguage('en') // If language resource bundle is not available, fallback to english
  }
}

export const getAvailableLanguages = () => languages