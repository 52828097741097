import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import cx from 'classnames'

import style from './style'
import Grid from '@material-ui/core/Grid'

import { getImage } from '../../../Images'
import Image from '../../../Image'

const moreIcon = getImage('moreIcon')

const SingleProductContainer = ({ baseClassNames, classes, product }) => {
  const unsold = _.get(product, 'unsold', false)
  const containerClasses = cx(classes.singleProductContainer, {
    [classes.op3]: unsold
  })
  return (
    <div className={baseClassNames}>
      <div className={containerClasses}>
        <Image className={classes.singleProductImage} src={_.get(product, 'images.0')} />
      </div>
    </div>
  )
}

const MultipleProductContainer = ({ baseClassNames, classes, products }) => {
  return (
    <div className={`${baseClassNames}`}>
      <div className={classes.multipleProductContainer}>
        <Grid container spacing={0} className={classes.gridContainer}>
          {products.map((product, index) => {
            const unsold = _.get(product, 'unsold', false)
            const gridItemClasses = cx(classes.productGridItem, {
              [classes.op3]: unsold
            })
            return (
              <Grid item xs={6} className={gridItemClasses} key={index}><Image className={classes.singleProductImage} src={_.get(product, 'images.0')} /></Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

const MultipleProductContainerWithMoreIcon = ({ baseClassNames, classes, products }) => {
  return (
    <div className={`${baseClassNames}`}>
      <div className={classes.multipleProductContainer}>
        <Grid container spacing={0} className={classes.gridContainer}>
          {products.slice(0, 3).map(product => {
            const unsold = _.get(product, 'unsold', false)
            const gridItemClasses = cx(classes.productGridItem, {
              [classes.op3]: unsold
            })
            return (
              <Grid item xs={6} className={gridItemClasses}><Image className={classes.singleProductImage} src={_.get(product, 'images.0')} /></Grid>
            )
          })}
          <Grid item xs={6} className={classes.productGridItem}>
            <img className={classes.moreIcon} src={moreIcon} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const ProductsContainer = ({ products, classes }) => {
  const baseClassNames = cx({
    [classes.productsContainer]: true
  })
  if (products.length === 1) {
    return (
      <SingleProductContainer
        baseClassNames={baseClassNames}
        classes={classes}
        product={products[0]}
      />
    )
  }

  if (products.length < 5) {
    return (
      <MultipleProductContainer
        baseClassNames={baseClassNames}
        classes={classes}
        products={products}
      />
    )
  }

  return (
    <MultipleProductContainerWithMoreIcon
      baseClassNames={baseClassNames}
      classes={classes}
      products={products}
    />
  )
}

ProductsContainer.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(style)(ProductsContainer)
