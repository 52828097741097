import { connect } from 'react-redux'

import { selectors as customerDetailSelectors } from '../../../../store/modules/customerDetails'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import ViewAllNotes from './ViewAllNotes'

const mapStateToProps = state => ({
  customer: customerDetailSelectors.getCustomer(state),
  currentUserId: authSelectors.getActiveUserId(state)
})

export default connect(mapStateToProps)(ViewAllNotes)
