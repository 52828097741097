const styles = theme => ({
  selectInput: {
    minWidth: 48
  },
  selectInputUnderline: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.accent.main}`
    }
  },
  groupName: {
    backgroundColor: theme.palette.offsetAccent.main
  }
})

export default styles
