
import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import _ from 'lodash'
import fp from 'lodash/fp'
import scanner from '../../services/barcodeScannerService'
import { scanditService } from '../../retailos/dependencies'
import modalService from '../../services/modalService'
import digitalStoreSdk from '../../digitalStoreSdk'
import { translations } from '../../config'
import ScanButton from './ScanButton'
import EnterEANModal from './EnterEANModal'
import analyticsService from '../../services/analyticsService'
import { selectors as authSelectors } from '../../store/modules/auth'
import { actions as appActions } from '../../store/modules/app'

import {
  actions as currentOrderActions,
  selectors as currentOrderSelectors
} from '../../store/modules/currentOrder'
import { push } from 'connected-react-router'


import ScanditFeedbackUI from '../ScanditFeedbackUI'
const searchProduct = (outputFunc, userRegionId, catalogue) => async ({ ean, onError = _.noop, onSuccess = _.noop }) => {
  try {
    const response = await digitalStoreSdk.variants.fetchVariants({
      ean,
      catalogue,
      regionId: userRegionId,
      includes: ['product']
    })
    if (response.total === 0) {
      throw new Error('Product doesnt exist.')
    }
    const variantId = fp.get('items.0.id', response)
    // Reformat the returned variant (with a product nested in it)
    // Convert it into a psuedo product variant with the variant within an array.
    // to make it compatible with `attachVariantToProduct
    const variants = fp.compose(
      fp.castArray,
      fp.omit('products'),
      fp.get('items.0')
    )(response)
    const product = fp.compose(
      fp.assign({ variants }),
      fp.get('items.0.product')
    )(response)
    const productWithVariant = currentOrderSelectors.attachVariantToProduct({ product, ean })
    onSuccess({ ean, product: productWithVariant, variantId })
    if (productWithVariant.id) {
      outputFunc({ product: productWithVariant, ean, variantId })
    }
  } catch (error) {
    onError({ error, ean })
  }
}

// `isLoading` here is about whether the scanner plugin is loading
export default compose(
  connect((state) => ({
    userRegionId: authSelectors.getUserSelectedRegionId(state),
    catalogue: _.get(authSelectors.getCurrentUser(state), 'catalogue', '')
  })),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    onClick: (props) => () => {
      const { 
        setIsLoading, 
        onClick: onClickFromProps, 
        userRegionId, 
        catalogue, 
        continuousMode, 
        dispatch, 
        groupedProducts, 
        scanScreen = false 
      } = props 
      const onConfirm = searchProduct(onClickFromProps, userRegionId, catalogue)
      const validateAndRedirect = (props) => {
        const { openSideBasket = false } = props || {}
        if(scanScreen){
          dispatch(push('/'))
          if(openSideBasket) {
            dispatch(appActions.toggleBasket({ isOpen: true }))
          }
        }
      }
      const showEANModal = (title) => {
        modalService.open({
          component: EnterEANModal,
          title,
          onConfirm,
          onBackdropClick: validateAndRedirect,
          validateAndRedirect,
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Enter EAN Submit')
            },
            {
              text: translations('Cancel'),
              onClick: validateAndRedirect
            }
          ]
        })
      }
      if (window.cordova) {
        setIsLoading(true)
        if (continuousMode) {
          modalService.open({
            component: ScanditFeedbackUI,
            fullScreen: true,
            inModal: true,
            modalIndex: 1,
            noRouting: true,
            onClose: () => {
              setIsLoading(false)
              scanditService.stop()
              modalService.close({
                modalIndex: 1
              })
              dispatch(currentOrderActions.clearScanStatus())
              validateAndRedirect({ openSideBasket: true })
            }
          })

          scanditService.scan({
            onScan: data => {
              onConfirm({
                ean: data,
                onSuccess: ({ product, ean }) => {
                  const productName = _.get(product, 'variant.name') || _.get(product, 'groupName') || _.get(product, 'name')
                  dispatch(currentOrderActions.setScanStatus({
                    statusType: 'success',
                    ean,
                    productName
                  }))
                },
                onError: ({ ean }) => {
                  dispatch(currentOrderActions.setScanStatus({
                    statusType: 'error',
                    ean
                  }))
                }
              })
            }
          })
        } else {
          scanner.scan(
            result => {
              analyticsService.sendCustomEvent({ type: 'eanProductSearch' })
              const { cancelled, text } = result
              if (!cancelled) {
                onConfirm({
                  ean: text,
                  onSuccess: () => {
                    setIsLoading(false)
                    validateAndRedirect({ openSideBasket: true })
                  },
                  onError: () => {
                    setIsLoading(false)
                    showEANModal(translations('Enter EAN No Results Title'))
                  }
                })
              } else {
                setIsLoading(false)
                showEANModal(translations('Enter EAN Title'))
              }
            },
            () => {
              setIsLoading(false)
              showEANModal(translations('Enter EAN Scan Error Title'))
            }
          )
        }
      } else {
        showEANModal(translations('Enter EAN Title'))
      }
    }
  })
)(ScanButton)
