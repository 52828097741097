import _ from 'lodash'
import qs from 'qs'
import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'

import { constants as appConstants } from '../app'
import { constants as authConstants, selectors as authSelectors } from '../auth'
import { constants as productsConstants, selectors as productsSelectors } from '../products'
import { constants as productDetailsConstants } from '../productDetails'
import { constants as customerDetailsConstants } from '../customerDetails'
import { constants as userDetailsConstants } from '../userDetails'
import { constants as usersConstants, selectors as usersSelectors } from '../users'
import { constants as customersConstants, selectors as customersSelectors } from '../customers'
import { constants as currentOrderConstants } from '../currentOrder'
import { constants as notificationsConstants } from '../notifications'
import { constants as createMessageConstants } from '../createMessage'
import { selectors as routingSelectors } from '../routing'
import analyticsService from '../../../services/analyticsService'
import { history } from '../../../store'
import { SUCCESS } from '../../middleware/redux-promise'

class AnalyticsMiddleware {
  login = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === authConstants.LOGIN) {
      const user = authSelectors.getCurrentUser(getState())
      analyticsService.userLogin({ user })
    }
  }
  productSearch = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && (action.type === productsConstants.SEARCH_PRODUCTS_FRESH || action.type === productsConstants.SEARCH_PRODUCTS_NEXT)) {
      const query = productsSelectors.getQuery(getState()) || ''
      const responses = _.chain(productsSelectors.getResults(getState()))
        .map(product => product.id)
        .value()
      const category = productsSelectors.getCategory(getState())
      const page = productsSelectors.getPage(getState()) || ''
      const size = productsSelectors.getSize(getState()) || ''
      const filters = qs.stringify({
        category: category ? category.id : '',
        page,
        size
      })
      const sort = productsSelectors.getSort(getState()) || ''
      analyticsService.productSearch({ query, filters, responses, sort })
    }
  }
  userSearch = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === usersConstants.SEARCH_USER_FRESH) {
      const query = usersSelectors.getQuery(getState()) || ''
      const responses = _.chain(usersSelectors.getResults(getState()))
        .map(user => user.id)
        .value()
      const roleIds = _.chain(usersSelectors.getRoleIds(getState()))
        .join(',')
        .value()
      const storeIds = _.chain(usersSelectors.getStoreIds(getState()))
        .join(',')
        .value()
      const includeDeactivated = usersSelectors.getIncludeDeactivated(getState())
      const page = usersSelectors.getPage(getState()) || ''
      const size = usersSelectors.getSize(getState()) || ''
      const filters = qs.stringify({
        roleIds,
        storeIds,
        includeDeactivated,
        page,
        size
      })
      analyticsService.userSearch({ query, filters, responses, sort: '' })
    }
  }
  customerSearch = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === customersConstants.SEARCH_CUSTOMER_FRESH) {
      const query = customersSelectors.getCustomerListQuery(getState()) || ''
      const responses = _.chain(customersSelectors.getCustomerListResults(getState()))
        .map(customer => customer.id)
        .value()
      const page = customersSelectors.getCustomerListPage(getState()) || ''
      const size = customersSelectors.getCustomerListSize(getState()) || ''
      const filters = qs.stringify({
        page,
        size
      })
      analyticsService.customerSearch({ query, filters, responses, sort: '' })
    }
  }
  viewProductDetails = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === productDetailsConstants.FETCH_PRODUCT) {
      const result = matchPath(history.location.pathname, { path: '/product/:id', exact: true })
      const isRoutingInProductDetailsView = result
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingInProductDetailsView && isLoggedIn) {
        const product = this._mapProductToAnalyticsProduct({ product: action.result })
        analyticsService.sendViewProductEvent({ name: 'productDetails', url: history.location.pathname, product })
      }
    }
  }
  addProductToBasket = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === currentOrderConstants.ADD_PRODUCT_TO_ORDER) {
      const { product, quantity } = action
      analyticsService.sendAddProductToBasketEvent({ quantity, product })
      analyticsService.sendCustomPDPEvent({ name: 'addToBasket', product })
    }
  }
  viewMyAccount = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === userDetailsConstants.FETCH_CURRENT_USER_DETAILS) {
      const result = matchPath(history.location.pathname, { path: '/users/:id', exact: true })
      const isRoutingInUserDetailsView = result
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingInUserDetailsView && isLoggedIn) {
        analyticsService.sendViewEvent({ name: 'myAccount', url: history.location.pathname })
      }
    }
  }
  viewNotifications = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === notificationsConstants.FETCH_PERSONAL_NOTIFICATIONS_FRESH) {
      const result = matchPath(history.location.pathname, { path: '/notifications', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (result && isLoggedIn) {
        analyticsService.sendViewEvent({ name: 'notifications', url: history.location.pathname })
      }
    }
  }
  viewBasket = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === appConstants.TOGGLE_BASKET && action.isOpen) {
      analyticsService.sendViewEvent({ name: 'basket', url: history.location.pathname })
    }
  }
  registerCustomer = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && (action.type === customerDetailsConstants.CREATE_CUSTOMER || action.type === currentOrderConstants.CREATE_CUSTOMER_FOR_ORDER)) {
      const customer = this._mapCustomerToAnalyticsCustomer({ customer: action.result })
      analyticsService.sendCustomEvent({ type: 'registerCustomer', customer })
    }
  }
  resetPassword = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.RESET_PASSWORD) {
      analyticsService.sendCustomEvent({ type: 'resetPassword' })
    }
  }
  addToWishlist = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === customerDetailsConstants.UPDATE_CUSTOMER_WISHLIST) {
      // first product in wishlist
      const product = action.result.wishlist[0]
      analyticsService.sendAddProductToWishlist({ product })
      analyticsService.sendCustomPDPEvent({ name: 'addToWishlist', product, wishlistLength: action.result.wishlist.length, customerId: action.result.id })
    }
  }
  addProductToMessage = ({ dispatch, getState }) => next => (action) => {
    if (action.type === createMessageConstants.ADD_PRODUCT_TO_MESSAGE) {
      const responses = action.message.products
      analyticsService.sendProductRecommendationsEvent({ responses })
      analyticsService.sendCustomPDPEvent({ name: 'addToMessage', responses })
    }
    next(action)
  }
  productDetailsTabChange = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === productDetailsConstants.CHANGE_PRODUCT_TAB) {
      const { productTab: productTabConstant } = action
      const eventName = productTabConstant === productDetailsConstants.PRODUCT_DETAILS_EXPANDED
        ? 'productDetailsTabClick'
        : 'productReviewsTabClick'
      analyticsService.sendCustomPDPEvent({ name: eventName })
    }
  }
  changeProductQuantityInOrder = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrderConstants.CHANGE_PRODUCT_QUANTITY_IN_ORDER && action.status === SUCCESS) {
      analyticsService.sendCustomEvent({ type: 'changeProductQuantityInOrder' })
    }
    next(action)
  }
  pdpSelectionAfterSearch = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE && matchPath(action.payload.location.pathname, { path: '/product/:id' })) {
      const newPath = matchPath(action.payload.location.pathname, { path: '/product/:id' })
      const state = getState()
      const previous = routingSelectors.getLocationPathname(state)
      const matching = matchPath(previous, { path: '/products/cat/:id', exact: true }) || matchPath(previous, { path: '/products', exact: true })
      if (matching) {
        const responses = productsSelectors.getResults(state)
        const query = productsSelectors.getQuery(state)
        const itemId = _.get(newPath, 'params.id')
        analyticsService.sendSearchSelectedEvent({ searchType: 'products', query, responses, itemId })
      }
    }
  }
  _mapProductToAnalyticsProduct = ({ product, variantId }) => {
    const variant = _.chain(product)
      .get('variants')
      .find(variant => variant.id === variantId)
      .get('name', '')
      .value()
    const analyticsProduct = _.chain(product)
      .at(['id', 'name', 'description[0]', 'price'])
      .thru(([id, title, description = '', price]) => ({
        id,
        title,
        description,
        price,
        variant
      }))
      .value()
    return analyticsProduct
  }
  _mapCustomerToAnalyticsCustomer = ({ customer }) => {
    const analyticsProduct = _.chain(customer)
      .at(['id', 'firstName', 'lastName', 'email'])
      .thru(([id, firstName, lastName, email]) => ({
        id,
        firstName,
        lastName,
        email
      }))
      .value()
    return analyticsProduct
  }
}

export default new AnalyticsMiddleware()
