import { promiseReducer } from '../../util'

class MessageDetailsReducers {
  getIsFlagged = result => !!result.flagId

  fetchMessageDetails = promiseReducer(
    (state, action) => {
      return {
        ...state,
        result: {
          ...action.result,
          isFlagged: this.getIsFlagged(action.result)
        }
      }
    }
  )

  flagMessageInappropriate = promiseReducer(
    (state, action) => ({
      ...state,
      result: {
        ...state.result,
        isFlagged: true
      }
    })
  )
}

export default new MessageDetailsReducers()
