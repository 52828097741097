import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class CategoriesReducers {
  fetchAllCategories = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllCategoriesPending(state, action)
      case SUCCESS:
        return this.fetchAllCategoriesSuccess(state, action)
      case FAIL:
        return this.fetchAllCategoriesFail(state, action)
      default:
        return state
    }
  }

  fetchAllCategoriesPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchAllCategoriesSuccess = (state, action) => {
    return {
      ...state,
      categories: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchAllCategoriesFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

}

export default new CategoriesReducers()
