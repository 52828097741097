import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import StockInOtherStores from './StockInOtherStores'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { formNames } from '../../../../../config'
import { getFormValues } from 'redux-form'
import _ from 'lodash'
import modalService from '../../../../../services/modalService'
import NoVariantSelected from './NoVariantSelected'
import StoreList from './StoreList'

const mapStateToProps = state => {
    const formValues = getFormValues(formNames.productDetails)(state)
    const currentVariantId = _.get(formValues, 'variantId')
    const currentStoreId = authSelectors.getUserSelectedStoreId(state)

    return {
        currentStoreId,
        currentVariantId
    }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
      openStoresList: ()  => () => {
        modalService.open({
            component: StoreList,
            hideBottomBar: true,
            onBackClick: () => modalService.close()
        })
      },
      openNoVariantSelected: () => () => {
          modalService.open({
              component: NoVariantSelected,
              hideBottomBar: true,
              onBackClick: () => modalService.close()
          })
      }
  })
)(StockInOtherStores)
