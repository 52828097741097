import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'

import style from './style'
import P from '../../../../components/P'

class TagPicker extends Component {
  state = {
    tagId: this.props.tagId
  }
  updateTag = e => {
    const { searchEvents } = this.props
    const tagId = e.target.value
    searchEvents(tagId)
  }
  render = () => {
    const { classes, tags, type, tagId, total } = this.props
    return (
      <div className={classes.container}>
        <FormControl className={classes.formControlContainer}>
          <Dropdown
            label={translations('Tags')}
            value={tagId === null ? '' : tagId}
            options={tags}
            onChange={this.updateTag}
          />
        </FormControl>
        {total ? <P className={classes.totalText} value={translations('Inspirations Items', { count: total })} /> : null}
      </div>
    )
  }
}

export default withStyles(style)(TagPicker)
