import _ from 'lodash'
import pluralize from 'pluralize'

import * as currentOrderSelectors from '../currentOrder/selectors'
import * as currentAppointmentSelectors from '../currentAppointment/selectors'
import { productMatch } from '../currentOrder/reducers'

/**
 * @description Due to total currentOrder overhaul (added currentAppointment) we must
 * now recompose the legacy currentOrder basket object to sent to the API at checkout.
 * @param {object} state
 * @returns {object} recomposedConsultationOrderForCheckout
 */
export const getRecomposedConsultationOrderForCheckout = (state) => {
  const currentOrder = currentOrderSelectors.getCurrentOrderRaw(state)
  const currentOrderProducts = currentOrderSelectors.getCurrentOrderProducts(state)
  const currentOrderDetails = currentOrderSelectors.getCurrentOrderDetails(state)
  const consultationMode = currentAppointmentSelectors.getIsAppointmentActive(state)
  const currentAppointmentContents = currentAppointmentSelectors.getAppointmentContents(state)
  const currentAppointmentProducts = currentAppointmentSelectors.getAppointmentProducts(state)

  const recomposedProducts = [
    ..._.reduce(currentOrderProducts, (acc, currentOrderProduct) => {
      const productInAppointment = _.find(currentAppointmentProducts, (currentAppointmentProduct) => {
        return productMatch(currentOrderProduct, currentAppointmentProduct)
      })

      if (!productInAppointment) {
        return [
          ...acc,
          currentOrderProduct
        ]
      }

      return [
        ...acc,
        {
          ...currentOrderProduct,
          ...productInAppointment
        }
      ]
    }, []),
    ..._.reduce(currentAppointmentProducts, (acc, currentAppointmentProduct) => {
      const isProductInCurrentOrder = _.some(currentOrderProducts, (currentOrderProduct) => {
        return productMatch(currentAppointmentProduct, currentOrderProduct)
      })

      if (isProductInCurrentOrder) {
        return [
          ...acc
        ]
      }

      return [
        ...acc,
        {
          ...currentAppointmentProduct,
          unsold: true
        }
      ]
    }, [])
  ]

  const recomposedBasketProducts = _(currentOrderProducts)
    .groupBy('externalProductId')
    .map((products) => ({ ...products[0], quantity: products.length }))
    .value()

  const recomposedDetails = {
    ...currentOrderDetails,
    ..._.reduce(currentAppointmentContents, (acc, content) => {
      const { type, details } = content || {}

      if (!type || !details || type === 'product') {
        return {
          ...acc
        }
      }

      const pluralizedType = pluralize(type)
      return {
        ...acc,
        [pluralizedType]: [
          ..._.get(acc, pluralizedType, []),
          {
            ...details
          }
        ]
      }
    }, {})
  }

  return {
    ...currentOrder,
    consultationMode,
    basketGroupedProducts: recomposedBasketProducts,
    products: recomposedProducts,
    details: recomposedDetails
  }
}
