import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { translations, viewEvent as viewEventConfig } from '../../../config'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H1 from '../../../components/H1'
import H2 from '../../../components/H2'
import HorizontalGallery from '../../../components/HorizontalGallery'
import ImageBox from '../../../components/ImageBox'
import P from '../../../components/P'
import Tags from '../../../components/Tags'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import { getImage } from '../../../components/Images'
import { getFullName } from '../../../helpers'
import style from './style'

const addToWaitlistIcon = getImage('addToWaitlistIcon')
const viewWaitlistIcon = getImage('viewWaitlistIcon')

class ViewEventScreen extends Component {
  renderImage = () => {
    const { galleries } = this.props.event
    const { classes, onImageClick, selectedGalleryIndex} = this.props

    return (
      <Grid item xs={12} sm={6} className={classes.imageItemContainer}>
        <HorizontalGallery
          images={_.get(galleries[selectedGalleryIndex], 'media', [])}
          embeddedVideos={[]}
          videoThumbnails={[]}
        />
      </Grid>
    )
  }
  renderGallerySelect = () => {
    const { classes, gallerySelectImage, onGallerySelectClick } = this.props
    return (
      <div className={classes.gallerySelectContainer}>
        <Button wrapper onClick={onGallerySelectClick}>
          <ImageBox
            src={gallerySelectImage}
            className={classes.gallerySelectImage}
            cover
            round
            caption={translations('View Galleries')}
            boxShadow
            bordered
          />
        </Button>
      </div>
    )
  }
  renderContent = () => {
    const { classes, event, hasMultipleGalleries } = this.props
    const { title, description } = event

    return (
      <Grid item xs={12} sm={6} className={classes.textItemContainer}>
        <div className={classes.eventContentContainer}>
          <H1 className={classes.title} value={title} />
          {this.renderTags()}
          <P value={description} />
          {this.renderCreatedBy()}
        </div>
        <div className={classes.eventActions}>
          {hasMultipleGalleries && this.renderGallerySelect()}
          {this.renderSendMessageButton()}
        </div>
      </Grid>
    )
  }
  renderTags = () => {
    const { eventType } = this.props.event
    const { classes, onTagClick } = this.props
    const tags = _.get(this.props.event, 'tags', [])
    if (!tags.length) {
      return null
    }
    return (
      <div className={classes.tagsContainer} >
        <Tags
          tags={tags}
          eventType={eventType}
          onTagClick={onTagClick}
          showAll
          inline
        />
      </div>
    )
  }
  renderSendMessageButton = () => {
    const { classes, onSendMessageClick, canSendMessage } = this.props
    if (!canSendMessage) {
      return null
    }
    return <Button onClick={onSendMessageClick} className={classes.button} buttonType='primary' >{translations('Send in message')}</Button>
  }
  renderBottomBar = () => {
    const { onDeleteClick, userCanDeleteEvent } = this.props
    if (!userCanDeleteEvent) {
      return null
    }
    return (
      <BottomBar>
        <ButtonsContainer align='center'>
          <Button
            buttonType='primary'
            onClick={onDeleteClick}
            big
          >
            {translations('Delete')}
          </Button>
        </ButtonsContainer>
      </BottomBar>
    )
  }
  renderHeader = () => {
    let title = translations('Event')
    return (
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(title)} />
        )}
      />
    )
  }
  renderCreatedBy = () => {
    const { event, classes } = this.props
    if (!event.createdByUser) {
      return null
    }
    return (
      <P
        className={classes.createdByText}
        value={translations('Event Created By', { username: getFullName(event.createdByUser) })}
      />
    )
  }

  renderActions = () => {
    const { classes, onClickShowWaitlist, onClickAddToWaitlist } = this.props
    return (
      <div className={classes.iconsContainer}>
        {
          viewEventConfig.waitlist
            ? <img src={viewWaitlistIcon} className={classes.iconImage} onClick={onClickShowWaitlist} />
            : null
        }
        {
          viewEventConfig.waitlist
            ? <img src={addToWaitlistIcon} className={classes.iconImage} onClick={onClickAddToWaitlist} />
            : null
        }
      </div>
    )
  }

  render () {
    const { classes, eventFailedToLoad } = this.props
    return (
      <div className={classes.container}>
        {this.renderHeader()}
        {
          eventFailedToLoad
            ? (
              <div className={classes.errorContainer}>
                <P className={classes.errorText} type='textButton' value={translations('Failed Event Text')} />
              </div>
            )
            : (
              <Fragment>
                {this.renderActions()}
                <Grid container spacing={0}>
                  {this.renderImage()}
                  {this.renderContent()}
                </Grid>
                {this.renderBottomBar()}
              </Fragment>
            )
        }
      </div>
    )
  }
}

export default withStyles(style)(ViewEventScreen)
