import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export const H1 = ({ value, className, classes }) => (
  <div className={classNames(classes.h1, className)}>
    {value}
  </div>
)

H1.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    h1: PropTypes.string
  }),
  value: PropTypes.any
}

export default withStyles(style)(H1)
