import { connect } from 'react-redux'

import { actions as productsActions, selectors as productsSelectors } from '../../../store/modules/products'
import RootScreen from './RootScreen'

const mapStateToProps = state => {
  return {
    resultsCategory: productsSelectors.getCategory(state),
    query: productsSelectors.getQuery(state),
    resultsQuery: productsSelectors.getResultsQuery(state)
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: ({ category, query, sort }) => {
    dispatch(productsActions.searchProductsFresh({ category, query, sort }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen)
