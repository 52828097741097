import { connect } from 'react-redux'
import UsersSearchFilters from './UsersSearchFilters'
import { actions as usersActions, selectors as usersSelectors } from '../../../../store/modules/users'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as rolesSelectors } from '../../../../store/modules/roles'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const roleIds = usersSelectors.getRoleIds(state)
  const storeIds = usersSelectors.getStoreIds(state)
  const includeDeactivated = usersSelectors.getIncludeDeactivated(state)
  const searchUsers = ({ roleIds, storeIds, includeDeactivated }) => dispatch(usersActions.searchUsersFresh({ roleIds, storeIds, includeDeactivated }))
  const loggedInUserStoresOptions = authSelectors.getUserStoresOptions(state)
  const allRolesOptions = rolesSelectors.getAllRolesOptions(state)
  return {
    ...ownProps,
    searchUsers,
    includeDeactivated,
    roleIds,
    storeIds,
    loggedInUserStoresOptions,
    allRolesOptions
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UsersSearchFilters)
