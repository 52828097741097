import React, { useEffect, useState } from 'react'
import { selectors as appSelectors } from '../../store/modules/app'
import { changeLanguage } from '../../config'
import { useSelector } from 'react-redux'


export default () => (WrappedComponent) => {

  const WithTranslationsHOC = (props) => {
    const appLanguage = useSelector(appSelectors.getAppLanguage)
    const [currentValue, setCurrentValue] = useState('')

    useEffect(() => {
      setCurrentValue(appLanguage) // forces a re-render when language changes
      changeLanguage(appLanguage)
    }, [appLanguage])

    return (<WrappedComponent {...props} />)
  }

  return WithTranslationsHOC
}
