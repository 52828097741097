import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class CustomerReducers {
  searchPostcode = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchPostcodePending(state, action)
      case SUCCESS:
        return this.searchPostcodeSuccess(state, action)
      case FAIL:
        return this.searchPostcodeFailure(state, action)
      default:
        return state
    }
  }
  searchPostcodePending = (state, action) => ({
    ...state,
    searchPostcodeStatus: PENDING,
    action: action.type,
    // remove address list when searched again
    addressList: undefined
  })
  searchPostcodeSuccess = (state, action) => ({
    ...state,
    searchPostcodeStatus: SUCCESS,
    action: undefined,
    addressList: action.result,
    searchPostcodeError: {}
  })
  searchPostcodeFailure = (state, action) => ({
    ...state,
    searchPostcodeStatus: FAIL,
    searchPostcodeError: action.error
  })
  searchFullAddress = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchFullAddressPending(state, action)
      case SUCCESS:
        return this.searchFullAddressSuccess(state, action)
      case FAIL:
        return this.searchFullAddressFailure(state, action)
      default:
        return state
    }
  }
  searchFullAddressPending = (state, action) => ({
    ...state,
    searchFullAddressStatus: PENDING,
    action: action.type
  })
  searchFullAddressSuccess = (state, action) => ({
    ...state,
    searchFullAddressStatus: SUCCESS,
    action: undefined,
    searchedFullAddress: action.result,
    searchFullAddressError: {}
  })
  searchFullAddressFailure = (state, action) => ({
    ...state,
    searchFullAddressStatus: FAIL,
    searchFullAddressError: action.error
  })
  searchCustomerFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchCustomerFreshPending(state, action)
      case SUCCESS:
        return this.searchCustomerFreshSuccess(state, action)
      case FAIL:
        return this.searchCustomerFreshFail(state, action)
      default:
        return state
    }
  }
  searchCustomerFreshPending = (state, action) => {
    const { query } = action
    return {
      ...state,
      customerList: {
        ...state.customerList,
        query,
        status: PENDING,
        action: action.type
      }
    }
  }
  searchCustomerFreshSuccess = (state, action) => {
    const { total, results } = action.result
    return {
      ...state,
      customerList: {
        ...state.customerList,
        total,
        page: 1,
        results,
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    }
  }
  searchCustomerFreshFail = (state, action) => ({
    ...state,
    customerList: {
      ...state.customerList,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  })
  searchCustomerNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchCustomerNextPending(state, action)
      case SUCCESS:
        return this.searchCustomerNextSuccess(state, action)
      case FAIL:
        return this.searchCustomerNextFail(state, action)
      default:
        return state
    }
  }
  searchCustomerNextPending = (state, action) => {
    return {
      ...state,
      customerList: {
        ...state.customerList,
        status: PENDING,
        action: action.type
      }
    }
  }
  searchCustomerNextSuccess = (state, action) => {
    const { total, results, query } = action.result
    const { page, results: currentResults, query: currentQuery } = state.customerList
    if (query === currentQuery) {
      return {
        ...state,
        customerList: {
          ...state.customerList,
          total,
          page: page + 1,
          results: [...currentResults, ...results],
          status: SUCCESS,
          action: undefined,
          error: undefined
        }
      }
    } else {
      return state
    }
  }
  searchCustomerNextFail = (state, action) => {
    return {
      ...state,
      customerList: {
        ...state.customerList,
        status: FAIL,
        action: undefined,
        error: action.error.message
      }
    }
  }
  updateCustomerInList = (state, action) => {
    return {
      ...state,
      customerList: {
        ...state.customerList,
        results: state.customerList.results
          ? state.customerList.results
            .map(result => result.id === action.customer.id ? action.customer : result)
          : state.customerList.results
      }
    }
  }
}

export default new CustomerReducers()
