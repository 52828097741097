import { promiseReducer } from '../../util'

class FeedbackReducers {
  sendFeedback = promiseReducer(
    (state, action) => {
      return {
        ...state,
        result: action.result
      }
    }
  )
}

export default new FeedbackReducers()
