import { useSelector } from 'react-redux'

import {
  getStore,
  getError,
  getIsFetchStoreLoading,
  getStoreCalendars,
  getStoreCalendarOptions,
  getStoreROSCalendarOptions
} from '../store/modules/storeDetails/selectors'

/**
 * @description returns store details
 * @returns
 */
export const useResults = () => {
  return useSelector(getStore)
}

/**
 * @description returns regions error
 * @returns
 */
export const useError = () => {
  return useSelector(getError)
}

/**
 * @description returns if the store is currently loading
 * @returns
 */
export const useIsFetchStoreLoading = () => {
  return useSelector(getIsFetchStoreLoading)
}


/**
 * @description returns store calendars
 * @returns
 */
export const useStoreCalendars = () => {
  return useSelector(getStoreCalendars)
}

/**
 * @description returns all store calendars as options
 * @returns
 */
export const useStoreCalendarOptions = () => {
  return useSelector(getStoreCalendarOptions)
}

/**
 * @description returns store RetailOS calendars as options
 * @returns
 */
export const useStoreROSCalendarOptions = () => {
  return useSelector(getStoreROSCalendarOptions)
}
