const styles = {
  container: {
    width: '100%'
  },
  scroller: {
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch'
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: 0,
    padding: 0,
    paddingBottom: 10,
    listStyle: 'none'
  },
  item: {
    display: 'flex'
  }
}

export default styles
