import { useSelector } from 'react-redux'

import {
  getCurrentOrderProducts,
  getCurrentOrderNumberOfProducts,
  getCurrentOrderGroupedProducts,
  currentOrderSelector,
  getCurrentOrderPurchasedGroupedProducts,
  getCurrentOrderNumberOfPurchasedProducts
} from '../store/modules/combinedSelectors/currentOrderCombinedSelectors'

/**
 * @description returns current order
 * @returns
 */
export const useCurrentOrder = () => {
  return useSelector(currentOrderSelector)
}

/**
 * @description returns products
 * @returns
 */
export const useProducts = () => {
  return useSelector(getCurrentOrderProducts)
}

/**
 * @description returns products grouped by product
 * @returns
 */
export const useGroupedProducts = () => {
  return useSelector(getCurrentOrderGroupedProducts)
}

/**
 * @description returns purchased products grouped by product
 * @returns
 */
export const usePurchasedGroupedProducts = () => {
  return useSelector(getCurrentOrderPurchasedGroupedProducts)
}

/**
 * @description returns total number of products
 * @returns
 */
export const useProductsCount = () => {
  return useSelector(getCurrentOrderNumberOfProducts)
}
