import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import HybridRouter from './HybridRouter'

export default compose(
  withRouter,
  connect()
)(HybridRouter)
