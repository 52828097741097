import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class CreateMessageReducers {
  updateRecipient = (state, action) => {
    if (action.customerObject) {
      return {
        ...state,
        recipients: [action.customerObject]
      }
    } else {
      return state
    }
  }

  updateMessage = (state, action) => {
    const { message } = action
    return {
      ...state,
      message: {
        ...state.message,
        ...message
      }
    }
  }
  addContentToMessage = (state, action) => {
    const { message } = action
    return {
      ...state,
      message: {
        ...state.message,
        ...message
      }
    }
  }

  sendMessage = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  setPreviousPath = (state, action) => {
    const { path } = action
    return {
      ...state,
      previousPath: path
    }
  }

  fetchMessageTemplates = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchMessageTemplatesPending(state, action)
      case SUCCESS:
        return this.fetchMessageTemplatesSuccess(state, action)
      case FAIL:
        return this.fetchMessageTemplatesFailure(state, action)
      default:
        return state
    }
  }
  fetchMessageTemplatesPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })
  fetchMessageTemplatesSuccess = (state, action) => ({
    ...state,
    status: SUCCESS,
    action: undefined,
    templates: action.result,
    error: {}
  })
  fetchMessageTemplatesFailure = (state, action) => ({
    ...state,
    status: FAIL,
    error: action.error
  })
}

export default new CreateMessageReducers()
