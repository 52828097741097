import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import StoresSearchScreen from './StoresSearchScreen'
import StoreDetailsScreen from './StoreDetailsScreen'
// import ViewUserScreen from './ViewUserScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/stores' path='/stores' exact component={TimedLogoutComponent(StoresSearchScreen)} />,
  <PrivateRoute key='/stores/new' path='/stores/new' exact component={TimedLogoutComponent(StoreDetailsScreen)} />,
  <PrivateRoute key='/stores/:id' path='/stores/:id' exact component={TimedLogoutComponent(StoreDetailsScreen)} />
]
