import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import { a11y, assets, getTheme } from '../../config'

const MainLogo = ({ classes, className }) => {
  const theme = getTheme('default')
  return (
    <div className={cx(classes.logoContainer, className)}>
      <img className={classes.logo} src={theme.base.loginLogo || assets.loginLogo} alt={a11y.mainLogoAltText} />
    </div>
  )
}

MainLogo.propTypes = {
  value: PropTypes.any
}

export default withStyles(style)(MainLogo)
