import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import { actions as productsActions, selectors as productsSelectors } from '../../../../../store/modules/products'
import { groupedProductsEnabled } from '../../../../../config'
import ProductsGrid from './ProductsGrid'

const mapStateToProps = (state, props) => {
  const products = groupedProductsEnabled ? productsSelectors.getGroupedResults(state) : productsSelectors.getResults(state)
  const hasMore = productsSelectors.getHasMore(state)
  const isLoading = productsSelectors.getIsLoading(state)
  const resultsCategory = productsSelectors.getResultsCategory(state)
  // discrepancy boolean is true if the category we're supposed to be displaying
  // does not match the category of the products in the redux store
  // so that we can hide product results from previously visited categoryies
  // flashing on initial load
  const categoryDiscrepancy = !(_.get(resultsCategory, 'id') === _.get(props, 'category.id') || resultsCategory === _.get(props, 'category.id'))
  const parsedProducts = categoryDiscrepancy ? [] : products

  return {
    hasMore,
    isLoading,
    products: parsedProducts
  }
}

const mapDispatchToProps = dispatch => ({
  loadMoreProducts: () => dispatch(productsActions.searchProductsNext())
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleLoadMore: ({ isLoading, loadMoreProducts }) => () => {
      if (!isLoading) {
        loadMoreProducts()
      }
    }
  })
)(ProductsGrid)
