import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ResourcesScreen from './ResourcesScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
import { DocumentScreen } from '../../retailos/DocumentScreen/DocumentScreen'

export default (
  <PrivateRoute key='/resources' path='/resources' exact component={TimedLogoutComponent(DocumentScreen)} />
)
