import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ProductCataloguesActions {
  fetchProductCatalogues = (params) => ({
    type: constants.FETCH_PRODUCT_CATALOGUES,
    promise: () => digitalStoreSdk.products.fetchProductCatalogues(params)
  })
}

export default new ProductCataloguesActions()
