import React from 'react'
import _ from 'lodash'

import P from '../P'
import { getFullName } from '../../helpers'

export const AddressText = props => {
  const { address, title, lastName } = props
  const {
    address1,
    address2,
    city,
    county,
    country,
    postCode
  } = address || {}

  const lines = [
    ...(
      lastName
      ? [`${title} ${getFullName(props)}`]
      : []
    ),
    address1,
    address2,
    city || county ? `${city || ''}${city && county ? ', ' : ''}${county || ''}` : '',
    country,
    postCode
  ]

  return _.compact(lines).map(line => <P value={line} key={line} />)
}
