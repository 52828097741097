import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../config'
import SearchInput from '../SearchInput'
import SubHeader from '../SubHeader'
import BackBar from '../BackBar'
import style from './style'

class SearchBar extends Component {
  state = {
    query: this.props.query
  }

  search = _.debounce(({ query }) => {
    const { search } = this.props
    search({ query })
  }, 300)

  updateQuery = query => {
    this.setState({ query })
    this.search({ query })
  }

  // doesn't use this.search so debounce is avoided
  // makes it snappier
  clearQuery = () => {
    const { search } = this.props
    this.setState({ query: '' })
    search({ query: null })
  }

  onChange = e => {
    this.updateQuery(e.target.value)
  }

  render () {
    const { placeholder, goBack, isLoading, searchBarPosition, classes, doNotClearQuery = false } = this.props

    return (
      <SubHeader
        absolute={searchBarPosition === 'absolute'}
        fixed={searchBarPosition === 'fixed'}
        isLoading={isLoading}
        leftContent={
          <BackBar onClick={this.state.query && !doNotClearQuery ? this.clearQuery : goBack} />
        }
        centerContent={
          <div className={classes.mainContainer}>
            <SearchInput
              value={this.state.query}
              placeholder={placeholder}
              onChange={this.onChange}
              autoFocus={false}
            />
          </div>
        }
      />
    )
  }
}

SearchBar.defaultProps = {
  search: () => { console.warn('No search function provided.') },
  placeholder: translations('Search Placeholder Default')
}

export default withStyles(style)(SearchBar)
