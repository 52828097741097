import React from 'react'
import PropTypes from 'prop-types'
import Editable from '../Editable'
import { AutoComplete } from '../../../components/Fields'
import P from '../../P'

Editable.propTypes = {
  editing: PropTypes.bool
}

export default Editable({
  input: (props) => {
    return (
      <AutoComplete {...props} />
    )
  },
  display: ({ label, value, format }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={format ? format(value) : value} />
    </div>
  )
})
