import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../../config'
import OrderIcon from './OrderIcon'

const PurchaseActivityContainer = (Activity) => ({ activity, onActivityClick }) => {
  const { customer } = activity
  const { numberOfProducts, refund, consultation, orderType, consultationType } = activity.meta || {}
  const intNumberOfProducts = parseInt(numberOfProducts, 10)

  const verbKey = (
    orderType === 'referral'
    ? 'Purchase Activity Verb Referral'
    : (
      refund
      ? 'Purchase Activity Verb Refund'
      : 'Purchase Activity Verb Default'
    )
  )

  const textParts = []
  textParts.push(translations(verbKey))
  textParts.push(translations('Purchase Activity Items', { count: intNumberOfProducts }))
  if (consultation) {
    if (consultationType === 'virtual')
      textParts.push(translations('Purchase Activity Virtual Consultation Suffix'))
    else
      textParts.push(translations('Purchase Activity Consultation Suffix'))
  }

  const text = textParts.join(' ')

  return (
    <Activity
      text={text}
      icon={OrderIcon}
      customer={customer}
      date={activity.meta.timestamp}
      onActivityClick={onActivityClick}
      activityType={activity.activityType}
    />
  )
}

PurchaseActivityContainer.propTypes = {
  activity: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
    customer: PropTypes.shape({}).isRequired,
    createdAt: PropTypes.string.isRequired
  }).isRequired,
  onActivityClick: PropTypes.func.isRequired
}

export default PurchaseActivityContainer
