import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import P from '../P'
import H2 from '../H2'
import H3 from '../H3'
import RoundedButton from '../RoundedButton'
import Input from '../Fields/Input'
import SubHeader from '../SubHeader'
import Button from '../Button'
import { getImage } from '../Images'
import style from './style'
import { translations } from '../../config'

const crossIcon = getImage('closeIconBlack')

class QuestionCommentModal extends PureComponent {
  state = {
    comment: this.props.comment,
    initialisedWithComment: !!this.props.comment
  }
  handleInputChange = value => {
    this.setState && this.setState({
      comment: value
    })
  }
  handleSubmit = () => {
    const { updateComment, dismiss } = this.props
    updateComment({ comment: this.state.comment })
    dismiss()
  }
  handleDeleteComment = () => {
    const { updateComment, dismiss } = this.props
    updateComment({ comment: undefined })
    dismiss()
  }
  render () {
    const {
      title,
      dismiss,
      question,
      classes
    } = this.props
    return (
      <div className={classes.content}>
        <SubHeader
          notFixed
          className={classes.header}
          centerContent={
            <H2 value={title} />
          }
          rightContent={
            <img onClick={dismiss} src={crossIcon} className={classes.crossIcon} />
          }
        />
        <div className={classes.scrollContainer}>
          <div className={classes.innerContainer}>
            <P className={classes.questionText} value={question} />
          </div>
          <div className={classes.commentsContainer}>
            <H3 value={translations('Comments')} />
            <Input
              onChange={this.handleInputChange}
              value={this.state.comment}
              passedProps={{
                placeholder: translations('Please provide your comments'),
                multiline: true,
                rows: 5,
                rowsMax: 14,
                fullWidth: true
              }}
            />
            {this.state.initialisedWithComment
              ? <RoundedButton
                className={classes.roundedButton}
                textClassName={classes.roundedButtonText}
                title={translations('DELETE COMMENT')}
                onClick={this.handleDeleteComment} />
              : null
            }
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            className={classes.button}
            onClick={this.handleSubmit}
          >
            {translations('SUBMIT')}
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(QuestionCommentModal)
