import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import pollResultsActions from './actions'
import * as authSelectors from '../auth/selectors'
import * as pollResultsSelectors from './selectors'
import { DELETE_POLL_QUESTION_INSTANCE } from './constants'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

class PollResultsMiddleware {
  loadPollResultsMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    // this is all in the if (...) so that the latter perf expensive predicates
    // are only evaluated if the earlier predicates actually return true
    if (
      // is logged in
      authSelectors.getIsLoggedIn(getState()) &&
      authSelectors.getHasViewPollResults(getState()) &&
      // poll questions aren't already loaded
      pollResultsSelectors.getIsInitial(getState()) &&
      // is routing to a page that needs poll questions
      action.type === LOCATION_CHANGE &&
      (
        matchPath(action.payload.location.pathname, { path: '/polls' })
      )
    ) {
      const filters = pollResultsSelectors.getFilters(getState())
      dispatch(pollResultsActions.fetchPollResultsFresh({ filters }))
    }
  }

  loadPollResultsOnPollQuestionInstanceDeleteMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (
      authSelectors.getIsLoggedIn(getState()) &&
      action.type === DELETE_POLL_QUESTION_INSTANCE &&
      action.status === 'SUCCESS'
    ) {
      const filters = pollResultsSelectors.getFilters(getState())
      dispatch(pollResultsActions.fetchPollResultsFresh({ filters }))
      modalService.continue({
        title: translations('Success'),
        text: translations('Question Deleted')
      })
    }
    next(action)
  }
}

export default new PollResultsMiddleware()
