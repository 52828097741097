import toastService from '../../services/toastService/toastService'
import { actions as networkActions } from '../modules/network'
import { translations } from '../../config'

const monitorNetworkConnection = ({ dispatch }) => {
  const onOnline = () => {
    dispatch(networkActions.networkConnectionOn())
    toastService.action({
      type: 'success',
      message: translations('Re-established network connectivity'),
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  }
  const onOffline = () => {
    dispatch(networkActions.networkConnectionOff())
    toastService.action({
      type: 'error',
      message: translations('Lost network connectivity'),
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  }
  if (window.cordova) {
    document.addEventListener('online', onOnline, false)
    document.addEventListener('offline', onOffline, false)
    return () => {
      document.removeEventListener('online', onOnline)
      document.removeEventListener('offline', onOffline)
    }
  }
  window.addEventListener('online', onOnline)
  window.addEventListener('offline', onOffline)
  return () => {
    window.removeEventListener('online', onOnline)
    window.removeEventListener('offline', onOffline)
  }
}

export default monitorNetworkConnection
