import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ProductCataloguesReducers {
  fetchProductCatalogues = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchProductCataloguesPending(state, action)
      case SUCCESS:
        return this.fetchProductCataloguesSuccess(state, action)
      case FAIL:
        return this.fetchProductCataloguesFail(state, action)
      default:
        return state
    }
  }
  fetchProductCataloguesPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchProductCataloguesSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchProductCataloguesFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
}

export default new ProductCataloguesReducers()
