import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import * as authSelectors from '../auth/selectors'
import { selectors as appSelectors, constants as appConstants } from '../app'

import * as selectors from './selectors'
import actions from './actions'

class EventsMiddleware {
  searchEventsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const state = getState()
      if (
        authSelectors.getIsLoggedIn(state) &&
        (
          matchPath(path, { path: '/events', exact: true })
        )
      ) {
        dispatch(actions.searchEventsFresh())
      }
    }
  }
  searchEventTagsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const state = getState()
      if (
        authSelectors.getIsLoggedIn(state) &&
        (
          matchPath(path, { path: '/events', exact: true }) ||
          matchPath(path, { path: '/messages/new', exact: true })
        )
      ) {
        dispatch(actions.fetchEventTags())
      }
    }
  }
}

export default new EventsMiddleware()
