import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import RemoveButton from '../RemoveButton'

const CustomerComponent = ({ id, firstName, lastName, classes, width, editing, onDelete }) => {
  return (
    <div className={cx(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })}>
      {editing && (
        <RemoveButton onClick={event => onDelete({ id, event })} />
      )}
      <div className={classes.customer}>
        {firstName} {lastName}
      </div>
    </div>
  )
}

export default withStyles(style)(CustomerComponent)
