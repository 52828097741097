import { promiseReducer } from '../../util'

class ConfigReducers {
  fetchConfig = promiseReducer(
    (state, action) => {
      return {
        ...state,
        config: action.result
      }
    }
  )
}

export default new ConfigReducers()