import bcrypt from 'bcryptjs'

const SALT_ROUNDS = 10

/**
 * @description generate secure hash of password
 * @param {object} object
 * @param {string} object.password
 */
export const hash = ({ password }) => {
  return bcrypt.hashSync(password, SALT_ROUNDS)
}

/**
 * @description compare entered password with hashed password
 * @param {object} object
 * @param {string} object.password
 * @param {string} object.offlinePasswordHash
 */
export const compare = ({ password, offlinePasswordHash }) => {
  return bcrypt.compareSync(password, offlinePasswordHash)
}
