import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'

class EventWaitlistActions {
  fetchEventWaitlist = (params) => ({
    type: constants.FETCH_WAITLIST,
    promise: () => {
      const { eventId, storeId, customerId, expand, page = 1, size = 10 } = params
      return digitalStoreSdk.eventWaitlist.fetchEventWaitlist(params)
      .then(result => ({
        results: result,
        eventId,
        storeId,
        customerId,
        expand,
        page,
        size
      }))
    }
  })

  fetchEventWaitlistNext = () => ({
    type: constants.FETCH_WAITLIST_NEXT,
    promise: (dispatch, getState) => {
      const { eventWaitlist: { page, eventId, storeId, expand, size } } = getState()
      const nextPage = page + 1
      digitalStoreSdk.eventWaitlist.fetchEventWaitlist({
        eventId,
        storeId,
        expand,
        page: nextPage,
        size
      })
      .then(result => ({
        results: result,
        page: nextPage
      }))
    }
  })

  addToEventWaitlist = params => ({
    type: constants.ADD_TO_WAITLIST,
    promise: (dispatch, getState) => {
      const currentStore = authSelectors.getSelectedStore(getState())

      return digitalStoreSdk.eventWaitlist.addToEventWaitlist({
        ...params,
        storeId: currentStore.id
      })
    }
  })

  deleteEntryFromWaitlist = eventId => ({
    type: constants.DELETE_FROM_WAITLIST,
    promise: () => {
      return digitalStoreSdk.eventWaitlist.deleteFromEventWaitlist(eventId)
    }
  })

  deleteEntryFromWaitlistLocal = entryId => ({
    type: constants.DELETE_FROM_WAITLIST_LOCAL,
    entryId
  })

  clearWaitlist = () => ({
    type: constants.CLEAR_WAITLIST_RESULTS
  })

  addToCustomerEventWaitlist = ({ eventIds, storeId, customerId, replace }) => ({
    type: constants.ADD_TO_CUSTOMER_WAITLIST,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.eventWaitlist.addToCustomerEventWaitlist({
        replace,
        storeId,
        customerId,
        eventIds
      })
    }
  })
}

export default new EventWaitlistActions()
