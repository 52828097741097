const style = theme => ({
  container: {
    padding: '15px 20px 15px',
    background: theme.palette.lightGrey.main,
    [theme.breakpoints.up('sm')]: {
      padding: '15px 25% 15px'
    }
  }
})

export default style
