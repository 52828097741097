const styles = {
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 100,
    margin: 0,
    marginBottom: '14px'
  },
  logo: {
    width: '100%',
    height: '50px'
  }
}
export default theme => styles
