
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import * as validators from '../../../validators'
import style from './style'
import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { Dropdown } from '../../../components/Fields'
import { translations } from '../../../config'
import { withStyles } from '@material-ui/core/styles'

const SelectStoreForm = Form('select-store')

const autocompleteDropdownProps = {
  autocomplete: true,
  listWidth: 330,
  maxListHeight: 144
}

const SelectStoreFormWrapper = ({
  onSubmit,
  storeId,
  stores,
  invalid,
  submitting,
  classes
}) => {
  return (
    <div className={classes.formContainer}>
      <SelectStoreForm onSubmit={onSubmit} submitLabel={translations('Continue')} initialValues={{ storeId }}>
        <FormBody>
          <div>
            <FormControl className={classes.field}>
              <Dropdown
                options={stores}
                name='storeRegionId'
                validate={validators.required}
                autocomplete
                strict={'true'}
                {...(stores.length > 10 ? autocompleteDropdownProps : {})}
              />
            </FormControl>
          </div>
        </FormBody>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Continue')} buttonClass={classes.fullWidthButton} />
      </SelectStoreForm>
    </div>
  )
}

SelectStoreFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(SelectStoreFormWrapper)
