import React from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const TableRow = ({ children, deactivated, classes, className, ...rest }) => {
  const rowClass = classNames({
    [classes.tableRow]: true,
    [classes.deactivatedTableRow]: deactivated
  })

  return (
    <Grid role="row" container spacing={0} className={`${rowClass} ${className}`} {...rest} >
      {children}
    </Grid>
  )
}

export default withStyles(styles)(TableRow)
