import React from 'react'
import Editable from '../Editable'
import { Dropdown } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Dropdown {...props} /> 
  ),
  display: ({ label, value }) => {
    const modifiedValue = value instanceof Array ? value.join(', ') : value
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={modifiedValue} />
      </div>
    )
  }
})
