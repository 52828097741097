import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const ErrorMessage = styled.p`

`

const LoaderContainer = (props) => {
  const { children, isLoading, error } = props

  if (isLoading) {
    return <Container><CircularProgress size={50} /></Container>
  } else if (error) {
    return <Container><ErrorMessage>{error}</ErrorMessage></Container>
  } else {
  return (<>{children}</>)
  }
}

export default LoaderContainer