import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { FETCH_ALL_STORES } from './constants'

export const getAllStores = state => _.get(state, 'stores.result.items') || []
export const getResults = state => _.get(state.stores, 'results', getAllStores(state))
export const getError = state => state.stores.error
export const getIsLoading = state => state.stores.status === PENDING
export const getQuery = state => state.stores.query
export const getSize = state => state.stores.size
export const getAllStoresAsOptions = createSelector([
  getAllStores
], (stores) => {
  return stores.map(store => ({ value: store.id, label: store.name }))
})

export const getStoreOptions = createSelector([
  getResults
], (stores) => {
  return stores.map(store => ({ value: store.id, label: store.name }))
})

export const getStoreById = id => createSelector([
  getAllStores
], stores => {
  return stores.find(s => s.id === id)
})

export const getStoresIsLoading = state => state.stores.status === PENDING && state.stores.action === FETCH_ALL_STORES

export const getStoreNamesTruncatedDisplay = _.memoize((storeIds) =>
  createSelector([
    getAllStores
  ], (stores) => {
    if (storeIds.length > 2) {
      const firstStore = stores.find(s => s.id === storeIds[0])
      const secondStore = stores.find(s => s.id === storeIds[1])
      if (firstStore && secondStore) {
        return `${firstStore.name}, ${secondStore.name} (+${storeIds.length - 2} more)`
      } else {
        return 'loading...'
      }
    } else {
      return _.chain(stores)
        .filter(store => storeIds.includes(store.id))
        .map(store => store.name)
        .join(', ')
        .defaultTo('loading...')
        .value()
    }
  })
)

export const getStoresHashmap = createSelector(
  [getAllStores],
  stores => {
    return (stores || []).reduce(
      (hashmap, _store) => {
        return {
          ...hashmap,
          [_store.id]: _store
        }
      }, {})
  }
)
