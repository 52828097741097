const style = theme => ({
  button: {
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      width: '120px'
    }
  },
  viewButtonGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      paddingBottom: 0
    }
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  },
  title: {
    marginBottom: 10
  }
})

export default theme => style(theme)
