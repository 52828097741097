import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import P from '../P'
import cx from 'classnames'
import style from './style'

class RoundedButton extends PureComponent {
  render () {
    const { classes, className, textClassName, title, ...rest } = this.props
    return (
      <ButtonBase
        focusRipple
        className={cx(classes.container, className)}
        {...rest}
      >
        <P
          className={cx(classes.text, textClassName)}
          type={'textButton'}
          value={title}
        />
      </ButtonBase>
    )
  }
}

RoundedButton.propTypes = {
  title: PropTypes.string.isRequired
}

export default withStyles(style)(RoundedButton)
