import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import ordersActions from './actions'
import usersActions from '../users/actions'
import followingActions from '../following/actions'
import * as authSelectors from '../auth/selectors'
import * as ordersSelectors from './selectors'
import { canReassignCustomer } from '../orderDetails/selectors'
import { logger } from '../../../helpers'

const { getHasReassignOrder } = authSelectors

class OrdersMiddleware {
  loadOrdersMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToOrders = action.type === LOCATION_CHANGE && matchPath(action.payload.location.pathname, {
      path: '/orders',
      exact: true
    })
    const isRoutingToOrderDetail = action.type === LOCATION_CHANGE && matchPath(action.payload.location.pathname, {
      path: '/orders/:id',
      exact: true
    })

    if (isRoutingToOrders) {
      const state = getState()

      const userId = ordersSelectors.getUserId(state) === null
        ? null
        : ordersSelectors.getUserId(state) || authSelectors.getActiveUserId(state)

      const storeId = ordersSelectors.getStoreId(state) === null
        ? null
        : ordersSelectors.getStoreId(state) || authSelectors.getUserSelectedStoreId(state)

      const startDate = ordersSelectors.getStartDate(state)
      const endDate = ordersSelectors.getEndDate(state)
      const orderType = ordersSelectors.getOrderType(state)
      const orderStatus = ordersSelectors.getOrderStatus(state)

      // Check filters
      logger.trace(`isRoutingToOrders.searchOrders`)
      const params = { page: 1, size: 12, query: undefined, userId, storeId, startDate, endDate, orderType, orderStatus }
      dispatch(ordersActions.searchOrders(params))
      dispatch(ordersActions.fetchOrderTypes())
    }
    if (isRoutingToOrderDetail) {
      if (getHasReassignOrder(getState())) {
        dispatch(usersActions.searchUsersFresh({ includeDeactivated: false }))
      }
      if (canReassignCustomer(getState())) {
        dispatch(followingActions.fetchFollowing())
      }
    }
  }
}

export default new OrdersMiddleware()
