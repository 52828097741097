const styles = theme => ({
  container: {
    borderRadius: 20,
    border: `1px solid ${theme.palette.whiteText.main}`,
    padding: 10
  },
  text: {
    color: theme.palette.whiteText.main
  }
})

export default styles
