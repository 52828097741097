import React from 'react'
import { Modules } from '@redant/retailos-ui'

import modalService from '../../../services/modalService'
import { composeMessageFunctions, filePickerFunctions, messageDetailFunctions } from '../../dependencies'
import { ProductRouter } from '../../ProductRouter'
import InspirationsSearchScreen from '../../../containers/Inspirations/InspirationsSearchScreen'
import { getLanguage, messageContentOptions } from '../../../config'


export const MessageDetailScreen = ({ match, goToCustomer }) => {

  const useFilePicker = Modules.File.Implementation.provideUseFilePicker({
    filePickerFunctions,
    acceptedFileTypes: ['image/*']
  })

  const useAttachContent = Modules.Messaging.Implementation.provideUseAttachContent({
    composeMessageFunctions,
    modalService,
    ProductModalComponent: ProductRouter,
    InspirationModalComponent: InspirationsSearchScreen, // FIXME: temporary, replace with ui library screen eventually
    contentOptions: messageContentOptions,
    hooks: {
      useFilePicker
    }
  })

  const implementation = Modules.Messaging.Implementation.provideUseMessageDetailScreen({
    messageDetailFunctions,
    composeMessageFunctions,
    routeParams: match.params,
    modalService,
    getLanguage,
    hooks: {
      useAttachContent
    }
  })

  return (
    <Modules.Messaging.Screens.MessageDetailScreen
      implementation={implementation}
      onSelect={goToCustomer}
    />
  )
}