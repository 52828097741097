import React, { Fragment, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { translations, scanProductEnabled } from '../../config'
import Button from '../Button'
import FullScreenLoader from '../../components/FullScreenLoader/FullScreenLoader'

import style from './style'

const ScanButton = props => {
  const { onClick, classes, isLoading, scanScreen = false  } = props
  useEffect(() => {
   if(scanScreen) {
     onClick()
   }
  }, [scanScreen])

  return (
    scanProductEnabled
      ? (
        <Fragment>
          {
            isLoading
              ? <FullScreenLoader />
              : null
          }
          {
            !scanScreen && (
              <Button
                onClick={onClick}
                buttonType='primary'
                className={classes.bigButton}
                iconImage={'scanIcon'}
                children={useMediaQuery(theme => theme.breakpoints.down('xs')) ? null : translations('Scan')}
              />
            )
          }
          
        </Fragment>
      )
      : null
  )
}

export default withStyles(style)(ScanButton)
