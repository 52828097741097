import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import classNames from 'classnames'

import Button from '../../components/Button'
import ConsultationCustomerDetailsForm from './ConsultationCustomerDetailsForm'

import ImagePickerWrapper from '../../components/ImagePickerWrapper'

import style from './style'

const _renderWizardStep = ({ details = {}, props = {}, onClick, isReviewStep, condensed, configOverrides }) => {
  const { classes } = props
  const { disabled } = details

  return (
    <Button wrapper disabled={disabled} className={classes.wizardStepWrap} onClick={onClick}>
      {_renderStep({ details, props, isReviewStep, condensed, configOverrides })}
    </Button>
  )
}

const _renderFormToggle = ({ props = {}, isOpen, toggleOpen }) => {
  const { classes } = props
  return (
    <div className={classes.wizardStepFormToggle}>
      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </div>
  )
}

const _renderWizardFormStep = ({
  details = {},
  props = {},
  schema = {},
  initialValues = {},
  toggleOpen,
  isReviewStep,
  condensed,
  configOverrides,
  isOpen = false,
  toggleEdit,
  editing = false,
  onSubmit = _.noop
}) => {
  const { classes } = props
  const { disabled } = details

  return (
    <div>
      <div className={classes.wizardForm}>
        <Button wrapper disabled={disabled} className={classes.wizardStepWrap} onClick={() => toggleOpen(!isOpen)}>
          {_renderStep({ details, props, isReviewStep, condensed, configOverrides })}
          {_renderFormToggle({ props, details, isOpen, toggleOpen })}
        </Button>
      </div>
      {isOpen ? <div className={classes.wizardFormWrap}>
        <ConsultationCustomerDetailsForm
          toggleEdit={() => toggleEdit(!editing)}
          onSubmit={onSubmit}
          schema={schema}
          initialValues={initialValues}
          editing={editing}
        />
      </div> : null}
    </div>
  )
}

const _renderWizardImageStep = ({ details = {}, props = {}, onPhotoAdded, beforeOpen, condensed, configOverrides }) => {
  const { classes } = props
  const { disabled } = details
  return (
    <ImagePickerWrapper chooseFromLibrary={false} disabled={details.disabled} className={classes.wizardStepWrap} onPhotoAdded={onPhotoAdded} beforeOpen={beforeOpen}>
      {_renderStep({ details, props, disabled, condensed, configOverrides })}
    </ImagePickerWrapper>
  )
}

const _renderStep = ({ details = {}, props = {}, isReviewStep, condensed, configOverrides }) => {
  const { classes } = props
  const { selected, detail, templateOptions, disabled, disabledMessage } = details
  const selectedLabel = _.template(configOverrides.selectedLabel || details.selectedLabel)(templateOptions)
  const defaultLabel = _.template(configOverrides.defaultLabel || details.defaultLabel)(templateOptions)
  const label = selected ? selectedLabel : defaultLabel
  const checkedClass = classNames(
    {
      [classes.wizardStepCheckActive]: selected,
      [classes.wizardStepCheckReview]: !selected && isReviewStep,
      [classes.wizardStepCheck]: !selected && !isReviewStep
    }
  )

  const wizardStepLabelStyles = classNames(
    {
      [classes.wizardStepLabelBasket]: condensed,
      [classes.wizardStepLabel]: !condensed
    }
  )
  const wizardStepDetailStyles = classNames(
    {
      [classes.wizardStepDetailBasket]: condensed,
      [classes.wizardStepDetail]: !condensed
    }
  )
  const wizardStepInnerStyles = classNames(
    {
      [classes.wizardStepInnerBasket]: condensed,
      [classes.wizardStepInner]: !condensed
    }
  )

  const stepDetail = disabled ? (configOverrides.disabledMessage || disabledMessage) : detail
  return (
    <div className={classes.wizardStep}>
      <div className={checkedClass} />
      <div className={wizardStepInnerStyles}>
        <p className={wizardStepLabelStyles}>{label}</p>
        {stepDetail && <p className={wizardStepDetailStyles}>{stepDetail}</p>}
      </div>
    </div>
  )
}

const ConsultationWizard = props => {
  const {
    classes,
    customer,
    customerNotes,
    beforePhoto,
    afterPhoto,
    inspiration,
    products,
    customerBasketReview,
    addInspirationToAppointment,
    addCustomerToOrder,
    addBeforePhotoToOrder,
    addAfterPhotoToOrder,
    addProductsToOrder,
    photoPermissionCheck,
    viewCustomerReviewBasket,
    condensed,
    customerDetailsSchema,
    customerNotesInitialValues,
    hasCustomerNotesSaved,
    consultationSteps = [],
    toggleCustomerDetails,
    customerDetailsOpen,
    toggleCustomerDetailsEdit,
    isCustomerDetailsEditing,
    updateCustomerNotes
  } = props

  return (
    <div className={classes.container}>
      {consultationSteps.map(step => {
        switch (step.type) {
          case 'customerSelect':
            return _renderWizardStep({ props, details: customer, onClick: addCustomerToOrder, condensed, configOverrides: step })
          case 'customerNotes':
            return _renderWizardFormStep({
              props,
              details: { ...customerNotes, selected: hasCustomerNotesSaved },
              schema: customerDetailsSchema,
              initialValues: customerNotesInitialValues,
              toggleOpen: toggleCustomerDetails,
              toggleEdit: toggleCustomerDetailsEdit,
              editing: isCustomerDetailsEditing,
              condensed,
              isOpen: customerDetailsOpen,
              configOverrides: step,
              onSubmit: updateCustomerNotes
            })
          case 'inspirationSelect':
            return _renderWizardStep({ props, details: inspiration, onClick: addInspirationToAppointment, condensed, configOverrides: step })
          case 'beforePhotoSelect':
            return _renderWizardImageStep({
              props,
              details: beforePhoto,
              onPhotoAdded: addBeforePhotoToOrder,
              beforeOpen: photoPermissionCheck,
              condensed,
              configOverrides: step
            })
          case 'afterPhotoSelect':
            return _renderWizardImageStep({
              props,
              details: afterPhoto,
              onPhotoAdded: addAfterPhotoToOrder,
              beforeOpen: photoPermissionCheck,
              condensed,
              configOverrides: step
            })
          case 'productSelect':
            return _renderWizardStep({ props, details: products, onClick: addProductsToOrder, condensed, configOverrides: step })
          case 'review':
            return _renderWizardStep({ props, details: customerBasketReview, onClick: viewCustomerReviewBasket, isReviewStep: true, condensed, configOverrides: step })
          default:
            return null
        }
      })}
    </div>
  )
}

export default withStyles(style)(ConsultationWizard)
