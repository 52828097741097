import React from 'react'
import { Modules } from '@redant/retailos-ui'
import {
  httpClientService,
  adminInterfaceFunctions
} from '../dependencies'

export const RootScreen = () => {
  const implementation = Modules.AdminInterface.Implementation.provideUseAdminInterfaceRootScreen({
    httpClientService,
    adminInterfaceFunctions
  })

  return (
    <Modules.AdminInterface.Screens.RootScreen
      implementation={implementation}
    />
  )
}
