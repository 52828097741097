import _ from 'lodash'

export const getIsLoading = (state) =>
  state.checkoutFlow.isLoading || state.checkoutFlow.status === 'PENDING'
export const getAll = (state) => ({
  ...state.checkoutFlow,
  isLoading: getIsLoading(state)
})
export const getIsInOrderSummary = state => _.get(state, 'checkoutFlow.current') === 'ORDER_SUMMARY'
export const getOrderNumber = state => _.get(state, 'checkoutFlow.orderNumber')
export const getOrderId = state => _.get(state, 'checkoutFlow.orderId')
export const getIsCheckoutOpen = state => _.get(state, 'checkoutFlow.checkoutOpen') || false
