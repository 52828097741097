import { general } from '../../config/configProvider'
import { omitStoreSaleDeliveryDetails } from '../../config'
import { setUiConfig } from '../uiConfigStore'

setUiConfig('Modules.Checkout.Screens.RootScreen', {
    paymentMethodOptions: [
        { id: 'CARD', label: 'Credit / debit card', checkoutType: ['virtualConsultation'] },
        { id: 'SKIP_PAYMENT', label: 'Skip Payment', checkoutType: ['inStoreConsultation', 'basketCheckout'] },
        { id: 'PAY_BY_LINK', label: 'Pay by link', checkoutType: ['virtualConsultation', 'inStoreConsultation', 'basketCheckout'] },
        { id: 'KLARNA', label: 'Klarna', checkoutType: ['virtualConsultation'] }
    ],
    offsetTop: undefined,
    orderNotesMaxChars: 1000,
    saveTransaction: false,
    features: {
        discounting: true,
        editUnitPrice: true,
        backdating: true,
        promoCodes: true,
        orderNotes: true
    },
    checkoutBackdateLimit: general.checkoutBackdateLimit,
    omitStoreSaleDeliveryDetails: omitStoreSaleDeliveryDetails
})