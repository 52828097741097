const styles = {
  button: {
    marginRight: 10
  },
  title: {
    fontSize: 19,
    margin: '0 0 10px 0',
    textTransform: 'uppercase'
  }
}

export default theme => styles
