import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import qs from 'qs'
import ScanButton from '../../../components/ScanButton'

export default connect(null, dispatch => ({
  onClick: ({ product, variantId }) => {
    const link = `/product/${product.id}${qs.stringify({ variantId }, { addQueryPrefix: true })}`
    dispatch(push(link))
  }
}))(ScanButton)
