import textContentStyle from '../../../../components/Form/TextContent/style'

const tableContainerHeight = 257

const styles = theme => ({
  table: {
    width: '100%'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: `calc(100vh - ${tableContainerHeight}px)`,
    flexDirection: 'column'
  },
  tableContainer: {
    height: `calc(100vh - ${tableContainerHeight}px)`,
    overflow: 'auto',
    marginTop: '20px'
  },
  tableHead: {
    backgroundColor: theme.palette.lightGrey.main,
    textTransform: 'uppercase',
    color: theme.palette.accent.main
  },
  bold: {
    fontWeight: 800
  },
  tableRow: {
    cursor: 'pointer',
    height: '60px'
  },
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  gridContainer: {
    height: `calc(100vh - ${tableContainerHeight}px)`,
    overflow: 'auto',
    margin: '20px auto 0 auto',
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  loadingBarContainer: {
    minHeight: 2,
    height: 2,
    width: '100%',
    position: 'absolute',
    top: 56,
    left: 0,
    [theme.breakpoints.up('xs')]: {
      top: 64
    }
  },
  hideExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      backgroundColor: 'green'
    }
  }
})

export default theme => ({
  ...styles(theme),
  ...textContentStyle
})
