export default theme => ({
  notificationText: {
    paddingTop: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  extraPaddingBottom: {
    paddingBottom: 10
  },
  italic: {
    fontStyle: 'italic'
  },
  marginLeft: {
    marginLeft: 10
  }
})
