import * as constants from './constants'
import { promiseReducer } from '../../util'

class RegionsReducers {
  fetchAllRegions = promiseReducer(
    (state, action) => {
      const { result } = action
      return {
        ...state,
        result
      }
    }
  )
}

export default new RegionsReducers()
