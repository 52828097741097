import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class MessageDetailsActions {
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  fetchMessageDetails = ({ id }) => ({
    type: constants.FETCH_MESSAGE_DETAILS,
    promise: () => digitalStoreSdk
      .messages.fetchMessageById(id, { includes: ['customer', 'store', 'user'] })
  })
  flagMessageInappropriate = ({ messageId }) => ({
    type: constants.FLAG_MESSAGE_INAPPROPRIATE,
    payload: { messageId },
    promise: (displatch, getState) => {
      return digitalStoreSdk.messages
        .flagMessage({
          messageId
        })
    }
  })
}

export default new MessageDetailsActions()
