import digitalStoreSdk from '../../../digitalStoreSdk'
import * as constants from './constants'
import * as selectors from './selectors'

class MessagesActions {
  fetchMessagesForCustomerFresh = ({ id }) => ({
    type: constants.FETCH_MESSAGES_FOR_CUSTOMER_FRESH,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      return digitalStoreSdk.messages
        .fetchMessagesByCustomerIdPaged({
          id,
          page: 1,
          size
        })
    }
  })

  fetchMessagesForCustomerNext = ({ id }) => ({
    type: constants.FETCH_MESSAGES_FOR_CUSTOMER_NEXT,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      return digitalStoreSdk.messages
        .fetchMessagesByCustomerIdPaged({ id, page: page + 1, size })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  flagMessageInappropriate = ({ messageId, userId, customerId }) => ({
    type: constants.FLAG_MESSAGE_INAPPROPRIATE,
    payload: { messageId },
    promise: (displatch, getState) => {
      return digitalStoreSdk.messages
        .flagMessage({
          messageId,
          userId,
          customerId
        })
    }
  })
}

export default new MessagesActions()
