import React from 'react'
import moment from 'moment'

import Form from '../../../components/Form'
import CheckoutWrapper from '../common/CheckoutWrapper'

export const formId = 'edit-basket'

const BasketForm = Form(formId)

const EditBasket = (props) => {
  const { orderDate = moment().format('DD/MM/YYYY'), onChange } = props
  return <BasketForm initialValues={{ orderDate }} onChange={onChange}>
    <CheckoutWrapper editable handleNext formId={formId} />
  </BasketForm>
}

export default EditBasket
