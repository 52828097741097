const styles = theme => ({
  container: {
    margin: '0 auto 15px auto',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
    backgroundColor: theme.palette.accent.main
  },
  rowHeaderLeft: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  rowHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1
  },
  rowHeaderValue: {
    fontWeight: 'bold',
    fontSize: 9,
    lineHeight: '26px',
    marginRight: 15,
    textTransform: 'uppercase',
    color: theme.palette.offsetAccent.main
  },
  rowQuestion: {
    padding: 15
  },
  chartWrapper: {
    borderTop: '2px solid #EAEAEA',
    borderBottom: '2px solid #EAEAEA',
    position: 'relative',
    flex: 1
  },
  rowActions: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  rowActionText: {
    fontSize: 12
  },
  rowActionIcon: {
    display: 'block',
    height: 14,
    marginRight: 8
  },
  emptyChart: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,
    borderTop: '2px solid #EAEAEA',
    borderBottom: '2px solid #EAEAEA'
  },
  emptyChartMessage: {
    color: theme.palette.mediumGrey.main,
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
})

export default styles
