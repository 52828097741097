import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import ListItem from './ListItem'

class List extends PureComponent {
  render () {
    const { items, onClickItem, itemProps, classes, ...rest } = this.props

    return (
      <ul className={classes.listContainer}>
        {
          (items || []).map((item, index) => {
            return (
              <ListItem
                {...rest}
                item={item}
                itemProps={itemProps}
                onClick={onClickItem}
                itemIndex={index}
                key={index}
              />
            )
          })
        }
      </ul>
    )
  }
}

export default withStyles(style)(List)
