import React from 'react'
import AddressListError from './AddressListError'
import enhanceWithClickOutside from 'react-click-outside'

// class AddressListErrorContainer extends React.Component {
//   handleClickOutside = () => {
//     this.props.onClickOutsideError()
//   }
//   render () {
//     return (
//       <AddressListError
//         {...this.props}
//         handleClickOutside={this.handleClickOutside}
//       />
//     )
//   }
// }

// const WrappedAddressListErrorContainer = enhanceWithClickOutside(AddressListErrorContainer)

// export default WrappedAddressListErrorContainer

export default AddressListError
