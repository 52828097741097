import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

import { theme } from '../../config/theme'

const AlertBadge = ({
  onClick,
  getBadgeCount,
  children,
  classes
}) => {
  const badgeCount = getBadgeCount()

  if (!badgeCount) {
    return (
      <div onClick={onClick}>
        {children}
      </div>
    )
  }

  return (
    <Badge
      onClick={onClick}
      badgeContent={badgeCount}
      color={theme.palette.alertBadgeColor.main}
      classes={{ badge: classes.badge, root: classes.badgeRoot }}
    >
      {children}
    </Badge>
  )
}

AlertBadge.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  getBadgeCount: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['notifications', 'basket'])
}

export default withStyles(style)(AlertBadge)
