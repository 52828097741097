const communicationOptionMappings = {
  "Email": "email",
  "SMS": "sms",
  "WhatsApp": "whatsapp"
}

const mapCommunicationOptions = (channelOptionKeys) => {
  return channelOptionKeys.map(option => ({
    value: communicationOptionMappings[option.label] || option.value,
    label: option.label
  }))
}

export default mapCommunicationOptions