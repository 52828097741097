import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import SelectStoreForm from './SelectStoreForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'

const mapStateToProps = state => ({
  stores: authSelectors.getUserStoreRegionOptions(state),
  storeId: authSelectors.getUserSelectedStoreId(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch }) => ({ storeRegionId }) => {
      const [storeId, regionId] = _.split(storeRegionId, '_')
      return dispatch(authActions.selectStore({ storeId, regionId }))
    }
  })
)(SelectStoreForm)
