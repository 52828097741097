const styles = theme => ({
  addNewButton: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    minHeight: 180,
    borderRadius: 20,
    border: '2px dashed #D1D1D1',
    position: 'relative',
    outline: 0,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: 30,
      height: 2,
      background: '#D1D1D1',
      left: 'calc(50% - 15px)'
    },
    '&:after': {
      transform: 'rotateZ(90deg)'
    },
    [theme.breakpoints.up('xs')]: {
      minWidth: 140,
      marginRight: 30
    }
  },
  productGridItem: {
    padding: '0px 20px',
    marginBottom: 20,
    // overriding a troublesome material ui style
    flexBasis: 'auto'
  }
})

export default styles
