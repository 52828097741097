import React from 'react'
import PropTypes from 'prop-types'
import H1 from '../../../components/H1'
import P from '../../../components/P'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'
import style from './style'

const NotFoundScreen = ({
  classes
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <H1 className={classes.heading} value={translations('404 Heading')} />
        <P value={translations('404 Intro')} type={'large'} />
      </div>
    </div>
  )
}

NotFoundScreen.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(NotFoundScreen)
