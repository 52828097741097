import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import fp from 'lodash/fp'

import handleFollow from './handleFollow'
import handleAnonymiseCustomer from './handleAnonymiseCustomer'
import ViewCustomerScreen from './ViewCustomerScreen'
import { selectors as customerDetailsSelectors, actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { selectors as storesSelectors } from '../../../store/modules/stores'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as productWaitlistSelectors, actions as productWaitlistActions } from '../../../store/modules/productWaitlist'
import { selectors as appointmentsSelectors } from '../../../store/modules/appointments'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'

import { communicationOptions, translations, apps, toggleCustomerFollow } from '../../../config'
import analyticsService from '../../../services/analyticsService'
import imageUploadService from '../../../services/imageUploadService'
import handleNotifyMe from './handleNotifyMe'
import modalService from '../../../services/modalService'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { groupByDate } from '../../../helpers'
import {
  preferenceKeys,
  fieldsForCustomerDetails,
  storeRelatedMarketingFields
} from '../../../store/util/marketingPreferences'
const reduceArrayToObject = (array, value) => array.reduce((o, key) => ({ ...o, [key]: value }), {})

const onCommunicationSubmit = ({ params, onSubmit }) => {
  const { customerMarketingChannels, customerMarketingPreferences, marketingStoreIds, preferredLanguage, details, id } = params
  const { channelKeys } = communicationOptions
  // all values as an object
  const allPreferences = reduceArrayToObject(preferenceKeys, false)
  const allChannels = reduceArrayToObject(channelKeys, false)

  // truthy values as an object
  const newTruthyPreferences = reduceArrayToObject(customerMarketingPreferences, true)
  const newTruthyChanels = reduceArrayToObject(customerMarketingChannels, true)

  // new values
  let newParams = {
    ...allPreferences,
    ...allChannels,
    ...newTruthyPreferences,
    ...newTruthyChanels,
    id
  }
  if (preferredLanguage) {
    newParams.details = {
      ...details,
      preferredLanguage
    }
  }
  newParams.details = fp.assign(
    fp.pick(fieldsForCustomerDetails, newParams),
    newParams.details
  )

  newParams = _.omit(newParams, fieldsForCustomerDetails)

  const isStoreMarketingSelected = _.some(customerMarketingPreferences, item => _.includes(storeRelatedMarketingFields, item)) // only add storeIds if storeMarketing is selected
  if (fp.size(marketingStoreIds) && isStoreMarketingSelected) {
    newParams.marketingStoreIds = marketingStoreIds
  }
  return onSubmit(newParams)
}

const onCustomerDetailsSubmit = ({ params, onSubmit }) => {
  const customerId = _.get(params, ['id'])
  const details = _.omit(params, ['id'])
  return onSubmit({
    id: customerId,
    details
  })
}

const onCustomerLoyaltySubmit = ({ params, onSubmit }) => {
  const customerId = _.get(params, 'id')
  const details = { loyaltyType: _.get(params, 'loyaltyType') }
  return onSubmit({
    id: customerId,
    details
  })
}

const onCustomerListSubmit = ({ params, onSubmit, submitType }) => {
  const customerId = _.get(params, 'id')
  const list = _.get(params, submitType)

  if (submitType === 'wishlist') {
    analyticsService.sendCustomPDPEvent({ name: 'addToWishlist', wishlistLength: list.length, customerId })
  }

  const newListPromises = list.map(item => {
    if (item.type === 'image' && item.local) {
      return imageUploadService.upload(item.url)
        .then(remoteFileUrl => {
          return {
            ...item,
            local: false,
            url: remoteFileUrl
          }
        })
    }
    return item
  })

  return Promise.all(newListPromises)
    .then(newList => {
      return onSubmit({
        id: customerId,
        details: {
          [submitType]: newList
        }
      })
    })
}

const handleSubmitWaitlist = ({ storeId, products, customerId, dispatch }) => {
  return dispatch(productWaitlistActions.addToCustomerProductWaitlist({
    replace: true,
    storeId,
    customerId,
    productIds: _.map(products, p => {
      return p.id
    })
  }))
}

const mapStateToProps = state => {
  const customerDetails = customerDetailsSelectors.getCustomer(state)

  const isCustomerAnonymised = customerDetailsSelectors.getIsCustomerAnonymised(state)
  const isUserAdmin = authSelectors.getIsUserAdmin(state)
  const customerWaitlist = productWaitlistSelectors.getProductWaitlistForCustomer(state)
  const isFollowing = customerDetailsSelectors.getIsCustomerFollowed(state)
  const shouldShowAnonymiseButton = isUserAdmin && !isCustomerAnonymised
  const shouldShowFollowButton = toggleCustomerFollow && ((!isCustomerAnonymised) || (isCustomerAnonymised && isFollowing))
  const isCustomerEditable = !_.get(customerDetails, 'anonymised')
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const shouldShowMessageButton = isMessagingAllowedForRole && !isCustomerAnonymised && apps.MESSAGING
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const customerCurrentlyInBasket = currentOrderCustomer && customerDetails && currentOrderCustomer.id === customerDetails.id
  const isConsultationMode = !!currentAppointmentSelectors.getAppointmentCustomerId(state)
  const storeOptions = storesSelectors.getAllStoresAsOptions(state)
  const selectedStore = storeOptions.find(({ value }) => customerDetails.registeredAtId === value)

  const groupedAppointments = groupByDate(
    appointmentsSelectors.getAppointments(state),
    'startDateTime',
    { today: 'Today\'s Appointments', tomorrow: 'Tomorrow\'s Appointments', upcoming: 'Upcoming Appointments', expired: 'Previous Appointments' }
  )

  return {
    customer: customerDetails,
    isCustomerDetailLoading: customerDetailsSelectors.getInitialCustomerDetailIsLoading(state),
    isStoresLoading: storesSelectors.getStoresIsLoading(state),
    isWaitlistLoading: productWaitlistSelectors.getIsLoading(state),
    initialValues: customerDetails,
    waitlistInitialValues: customerWaitlist,
    shouldShowAnonymiseButton,
    shouldShowFollowButton,
    isCustomerEditable,
    userSelectedStoreId: authSelectors.getUserSelectedStoreId(state),
    customerFirstName: _.get(customerDetails, 'firstName'),
    shouldShowMessageButton,
    currentOrderCustomer,
    customerCurrentlyInBasket,
    appointments: groupedAppointments,
    isConsultationMode,
    registeredStoreSchema: {
      id: 'registeredAtId',
      field: 'Dropdown',
      props: {
        name: 'registeredAtId',
        label: translations('Customer Registered Store'),
        options: storeOptions,
        displayValue: selectedStore && selectedStore.label
      }
    }
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: params => {
    const newParams = _.omit(params, ['latestMessage', 'submitType'])
    return Promise.resolve(newParams)
      .then(finalParams => {
        return dispatch(customerDetailsActions.updateCustomer(finalParams))
      })
  },
  handleAddCustomerToOrder: ({ customer, currentOrderCustomer }) => {
    if (currentOrderCustomer) {
      modalService.action({
        title: translations('Add Customer To Basket Confirm Customer Overwrite Title'),
        text: translations('Add Customer To Basket Confirm Customer Overwrite Text', {
          customer: _.compact([currentOrderCustomer.firstName, currentOrderCustomer.lastName]).join(' ')
        }),
        actions: [
          {
            text: translations('Cancel')
          },
          {
            text: translations('Replace'),
            primary: true,
            onClick: () => dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
          }
        ]
      })
    } else {
      dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
    }
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onCommunicationSubmit: ({ dispatch, onSubmit }) => params => {
      return onCommunicationSubmit({ params, onSubmit })
    },
    onCustomerPreferencesSubmit: ({ dispatch, onSubmit }) => params => {
      return onCustomerDetailsSubmit({ params, onSubmit })
    },
    onCustomerLoyaltySubmit: ({ dispatch, onSubmit }) => params => {
      return onCustomerLoyaltySubmit({ params, onSubmit })
    },
    onSubmitWishlist: ({ dispatch, onSubmit }) => params => {
      return onCustomerListSubmit({ params, onSubmit, submitType: 'wishlist' })
    },
    onSubmitRecommendedProducts: ({ dispatch, onSubmit }) => params => {
      return onCustomerListSubmit({ params, onSubmit, submitType: 'recommendedProducts' })
    },
    onSubmitWaitlist: ({ dispatch, initialValues: customer, userSelectedStoreId }) => ({ waitlist }) => {
      return handleSubmitWaitlist({
        products: waitlist,
        customerId: customer.id,
        storeId: userSelectedStoreId,
        dispatch
      })
    },
    onSubmitAppointments:({ dispatch, onSubmit }) => params => {
      return onCustomerDetailsSubmit({ params, onSubmit })
    },
    onActionsClick: ({ dispatch, shouldShowFollowButton, customer, shouldShowMessageButton, handleAddCustomerToOrder, customerCurrentlyInBasket, currentOrderCustomer, shouldShowAnonymiseButton, initialValues }) => () => {
      modalService.action({
        title: 'Customer Actions',
        buttonDirection: 'column',
        actions: [
          ...(shouldShowFollowButton ? [{
            text: translations(customer.following ? 'Unfollow' : 'Follow'),
            onClick: () => handleFollow({ ...customer, dispatch }),
            primary: true,
            fullWidth: true
          }]: []),
          ...(shouldShowMessageButton ? [{
            text: translations('Message'),
            onClick: () => dispatch(createMessageActions.updateReceipient(customer)),
            primary: true,
            fullWidth: true
          }]: []),
          ...(shouldShowFollowButton ? [{
            text: translations('Follow Up'),
            onClick: () => handleNotifyMe({ dispatch, customer }),
            primary: true,
            fullWidth: true
          }]: []),
          ...(shouldShowAnonymiseButton ? [{
            text: translations('Forget Me'),
            onClick: () => handleAnonymiseCustomer({ ...initialValues, dispatch }),
            primary: true,
            fullWidth: true
          }]: []),
          {
            text: translations('Add Customer To Basket'),
            onClick: () => handleAddCustomerToOrder({ dispatch, customer, currentOrderCustomer }),
            primary: true,
            fullWidth: true,
            disabled: customerCurrentlyInBasket
          }
        ]
      })
    }
  }),
  withPropsOnChange(
    ['isCustomerDetailLoading', 'isStoresLoading'],
    ({ isCustomerDetailLoading, isStoresLoading }) => ({
      isLoading: isCustomerDetailLoading || isStoresLoading
    })
  )
)(ViewCustomerScreen)
