import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { getImage } from '../Images'
import { translations } from '../../config'
import Button from '../Button'

import style from './style'
import fileUploadService from '../../services/fileUploadService/fileUploadService'

const acceptedFileTypes = ['application/pdf', 'image/*']

class UploadFile extends Component {
  state = {
    file: this.props.file,
    validationErrors: []
  }

  fileSelectedWeb = (e) => {
    const { onPhotoAdded, onFileAdded } = this.props
    const file = e.target.files[0]
    const { type, name, size } = file || {}

    let validationErrors = [];
    const isFileSizeValid = size <= 5242880;
    const isFileTypeValid = acceptedFileTypes.some((pattern) => new RegExp(pattern).test(type));

    if (!isFileSizeValid) {
      validationErrors.push("filesize");
    }
    if (!isFileTypeValid) {
      validationErrors.push("filetype");
    }
    this.setState && this.setState({ validationErrors });

    if (isFileTypeValid && isFileSizeValid) {
      const blobUrl = window.URL.createObjectURL(file)
      this.setState && this.setState({ file: blobUrl })
      if (type.includes('image')) {
        onPhotoAdded({ blobUrl, fileName: name })
      } else {
        onFileAdded({ blobUrl, fileName: name })
      }
    }

  }

  fileSelectedCordova = (file) => {
    const { onPhotoAdded } = this.props
    const fileName = file.substring(file.lastIndexOf('/') + 1)
    const isImage = (url) => /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
    this.setState && this.setState({ file })
    if (isImage(file)) {
      onPhotoAdded({ blobUrl: file, fileName })
    }
  }

  openFilePickerCordova = () => {
    const { onFileAdded } = this.props
    DocumentPicker.getFile(['pdf'], (url) => {
      const fileName = url.substring(url.lastIndexOf('/') + 1)
      onFileAdded({ blobUrl: url, fileName })
    })
  }

  openFilePickerWeb = () => {
    const { filepicker } = this.refs
    filepicker.click()
  }

  _onDeleteClick = () => {
    const { onPhotoRemoved } = this.props
    const { filepicker } = this.refs
    this.setState && this.setState({ file: '' })
    onPhotoRemoved()
    filepicker.value = null
  }

  _renderDeleteFileButton = () => {
    return (
      <Button
        buttonType='primary'
        onClick={this._onDeleteClick}
      >
        {translations('Delete File')}
      </Button>
    )
  }

  _renderButtonCordova = () => {
    const { classes, buttonSpacing } = this.props
    if (this.state.file) {
      this._renderDeleteFileButton()
    }
    const buttonClasses = cx(classes.submitButtonHalf, {
      [classes.buttonSpace]: buttonSpacing
    })
    return (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.submitButtonHalf}
          buttonType='primary'
          onClick={this._openImagePickerCamera}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('camera')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Take Photo')}
            </div>
          </div>
        </Button>
        <Button
          className={buttonClasses}
          buttonType='primary'
          onClick={this._openImagePickerLibrary}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('addFromGallery')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Add from Gallery')}
            </div>
          </div>
        </Button>
        <Button
          className={classes.submitButtonHalf}
          buttonType='primary'
          onClick={this.openFilePickerCordova}
        >
          <div className={classes.submitButtonLabelContainer}>
            <img className={classes.submitButtonLabelIcon} src={getImage('pdfIcon')} />
            <div className={classes.submitButtonLabelText}>
              {translations('Add Generic File')}
            </div>
          </div>
        </Button>
      </div>
    )
  }

  _openImagePickerLibrary = () => fileUploadService.openImagePickerLibrary(this.fileSelectedCordova)
  _openImagePickerCamera = () => fileUploadService.openImagePickerCamera(this.fileSelectedCordova)



  _renderButtonWeb = () => {
    if (this.state.file) {
      return this._renderDeleteFileButton()
    } else {
      return (
        <Button
          buttonType='primary'
          onClick={this.openFilePickerWeb}
        >
          {translations('Add Image Web')}
        </Button>
      )
    }
  }

  renderButton = () => {
    if (window.cordova) {
      return this._renderButtonCordova()
    } else {
      return this._renderButtonWeb()
    }
  }

  renderValidationText = (classes) => {
    let msg = translations("Maximum upload file size");
    if (this.state.validationErrors.includes("filetype")) {
      msg = translations("Invalid file type")
    }
    if (this.state.validationErrors.includes("filesize")) {
      msg = translations("Maximum upload file size")
    }
    return <div className={this.state.validationErrors.length > 0 ? classes.validationError : null}><i>{msg}</i></div>
  }

  render() {
    const {
      classes,
      className
    } = this.props

    return (
      <div className={className}>
        {this.renderButton()}
        <input
          type='file'
          ref={'filepicker'}
          accept={acceptedFileTypes.join(',')}
          className={classes.filePicker}
          onChange={this.fileSelectedWeb}
        />
        {this.renderValidationText(classes)}
      </div>
    )
  }
}

export default withStyles(style)(UploadFile)
