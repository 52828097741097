import * as constants from './constants'

import digitalStoreSdk from '../../../digitalStoreSdk'

class InspirationDetailsActions {
  fetchInspiration = ({ id }) => ({
    type: constants.FETCH_INSPIRATION,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.inspirations.fetchInspirationById({ id })
    }
  })
  deleteInspiration = ({ id }) => ({
    type: constants.DELETE_INSPIRATION,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.inspirations.deleteInspirationById({ id })
    },
    payload: { id }
  })
  setActiveGalleryIndex = (index) => ({
    type: constants.SET_ACTIVE_GALLERY_INDEX,
    payload: index
  })
}

export default new InspirationDetailsActions()
