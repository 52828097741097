import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

const Carousel = ({
  items,
  renderItem,
  classes,
  containerClassName
}) => (
  <div className={`${classes.container} ${containerClassName}`}>
    <div className={classes.scroller}>
      <ul className={classes.itemWrapper}>
        {items.map((item, index) => (
          <li className={classes.item} key={index}>{renderItem(item)}</li>
        ))}
      </ul>
    </div>
  </div>
)

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })).isRequired,
  renderItem: PropTypes.func.isRequired
}

export default withStyles(style)(Carousel)
