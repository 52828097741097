import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import FormValueProvider from '../../../FormValueProvider'

import CustomerWaitlist from './CustomerWaitlist'

export default compose(
  FormValueProvider('waitlist'),
  withHandlers({
    onSubmit: ({ onSubmit }) => data => {
      const newData = {
        ...data,
        submitType: 'waitlist'
      }
      return onSubmit(newData)
    }
  }),
  withPropsOnChange(
    ['formValues'],
    ({ formValues, initialFormValues }) => {
      const waitlistValue = _.get(formValues, 'waitlist', [])
      const waitlistInitialValue = _.get(initialFormValues, 'waitlist', [])
      // do not allow a user to save an empty form the first time
      // They can save an empty form if the form had values in it before however (acts as delete)
      const saveDisabled = waitlistValue.length === 0 && waitlistInitialValue.length === 0
      return {
        saveDisabled
      }
    }
  )
)(CustomerWaitlist)
