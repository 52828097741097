import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import { AddressText } from '../../../../components/Address/util'

import style from './style'

let DeliverySection = props => {
  const { classes, deliveryAddress, deliveryDetails = {}, deliveryText } = props

  return <div className={classes.container}>
    <div className={classes.inner}>
      <H2
        value={translations('Delivery Details')}
        className={classes.title}
      />
      <P value={deliveryText} />
      {deliveryAddress || deliveryDetails.store ? <div className={classes.br} /> : null}
      {
        deliveryAddress
        ? <AddressText
          address={deliveryAddress}
          {...deliveryDetails}
        />
        : (
          deliveryDetails.store && deliveryDetails.store.name
          ? <P value={deliveryDetails.store.name} />
          : null
        )
      }
    </div>
  </div>
}

DeliverySection = withStyles(style)(DeliverySection)

export { DeliverySection }
export default DeliverySection
