import * as validators from '../../../validators'
import { storePinMinLength } from '../../../config'

const minLengthValidator = validators.minLength(storePinMinLength)

export default ({ initialValues } = {}) => {
  return {
    schema: [
      {
        id: 'storeCode',
        field: 'Input',
        props: {
          label: 'New Store PIN',
          name: 'storeCode',
          required: !initialValues.currentStorePin,
          validate: [minLengthValidator, validators.numeric]
        }
      },
      {
        id: 'confirmStoreCode',
        field: 'Input',
        props: {
          label: 'Confirm Store PIN',
          name: 'confirmStoreCode',
          required: !initialValues.currentStorePin,
          validate: [validators.storeCodeConfirmation]
        }
      },
      ...initialValues.currentStorePin ? [{
        id: 'currentPin',
        field: 'Input',
        props: {
          label: 'Current PIN',
          name: 'currentPin',
          validate: [validators.currentStoreCodeConfirmation, validators.currentPinConfirmation]
        }
      }] : [],
    ],
    layout: [
      ...initialValues.currentStorePin ? [['currentPin']] : [],
      ['storeCode', 'confirmStoreCode']
    ]
  }
}
