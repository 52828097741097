import _ from 'lodash'
import { withPropsOnChange } from 'recompose'

const formatOptions = (options, grouped) => {
  if (grouped) {
    return options
  } else {
    return _
      .map(options, option => {
        if (typeof option === 'object') return option
        return { label: option, value: option }
      })
  }
}

const OptionsFormatter = withPropsOnChange(
  ['options', 'grouped'],
  ({ options, grouped }) => ({
    options: formatOptions(options, grouped)
  })
)

export default OptionsFormatter
