// import { loadClientConfig } from './retailos/clientConfig'

async function main() {
  try {
    console.log('main()')
    
    // use this to remotely load the client config. This is not used yet but could be useful in the future.
    // console.log('Fetching client config...')
    // await loadClientConfig()
    
    console.log('Initializing dependencies...')

    await require('./retailos/dependencies').initializeDependencies()
    console.log('Starting app...')

    require('./app')
  } catch (error) {
    console.error(error)
  }
}

main()
