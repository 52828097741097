import { connect } from 'react-redux'
import { withHandlers, compose } from 'recompose'

import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'

import EventsSearchScreen from './EventsSearchScreen'

const mapStateToProps = state => ({
  
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    handleTabChange: ({ dispatch }) => (event, value) => {
      // one is a native select so have to use event
      dispatch(appActions.changeEventsTab({ tab: value || event.target.value }))
    }
  })
)(EventsSearchScreen)
