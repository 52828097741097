import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'
import { selectors as authSelectors } from '../auth'
import messagesActions from './actions'

class MessagesMiddleware {
  loadCustomerMessagesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToMessagesResult = matchPath(action.payload.location.pathname, { path: '/customers/:id/messages', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToMessagesResult && isLoggedIn) {
        const { id } = isRoutingToMessagesResult.params
        dispatch(messagesActions.fetchMessagesForCustomerFresh({ id }))
      }
    }
  }
}

export default new MessagesMiddleware()
