
import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as eventWaitlistActions, constants as eventWaitlistConstants } from '../eventWaitlist'
import { selectors as authSelectors } from '../auth'
import { FETCH_FULL_CUSTOMER } from '../customerDetails/constants'
import { SUCCESS } from '../../middleware/redux-promise'
import { history } from '../..'

class EventWaitlistMiddleware {
  fetchEventWaitlist = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/events/:id/waitlist', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      if (result && isLoggedIn) {
        const eventId = result.params.id
        dispatch(eventWaitlistActions.fetchEventWaitlist({
          eventId,
          storeId,
          expand: 'customer,store'
        }))
      }
    }
  }

  fetchEventWaitlistForWaitlistScreen = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/waitlist', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      if (result && isLoggedIn) {
        dispatch(eventWaitlistActions.fetchEventWaitlist({
          storeId,
          expand: 'event',
          group: 'event'
        }))
      }
    }
  }

  fetchEventWaitlistForCustomerScreen = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === FETCH_FULL_CUSTOMER && action.status === SUCCESS) {
      const currentPathname = history.location.pathname
      const match = matchPath(currentPathname, { path: '/customers/:id/full' })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const storeId = authSelectors.getUserSelectedStoreId(getState())

      if (match && isLoggedIn) {
        const customerId = _.get(action, 'result.customer.id')
        dispatch(eventWaitlistActions.fetchEventWaitlist({
          customerId,
          storeId,
          expand: 'event'
        }))
      }
    }
  }

  clearWaitlistOnFreshFetch = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.type === eventWaitlistConstants.FETCH_WAITLIST &&
      action.status === 'PENDING'
    ) {
      dispatch(eventWaitlistActions.clearWaitlist())
    }

    next(action)
  }

  updateListOnDelete = ({ dispatch, getState }) => (next) => (action) => {
    if (
      action.type === eventWaitlistConstants.DELETE_FROM_WAITLIST &&
      action.status === 'SUCCESS' &&
      action.result && action.result.deleted
    ) {
      dispatch(eventWaitlistActions.deleteEntryFromWaitlistLocal(action.result.deleted))
    }

    next(action)
  }
}

export default new EventWaitlistMiddleware()
