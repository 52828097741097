const styles = theme => ({
  container: {
    padding: '10px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '100%',
      marginBottom: 10
    }
  },
  fieldWrapper: {
    flex: 1,
    width: '100%'
  },
  field: {
    flex: 1
  }
})

export default theme => styles(theme)
