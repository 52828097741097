const style = theme => ({
  formControlText: {
    width: '100%',
    paddingBottom: 12
  },
  formControlDropdown: {
    width: '100%',
    paddingBottom: 12,
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    }
  },
  imageButtonContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  fileName: {
    marginRight: 20
  },
  feedbackTypeText: {
    fontSize: 16,
    margin: '5px 0'
  }
})

export default style
