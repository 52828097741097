import _ from 'lodash'

export const HIDE_CHILD_CATEGORIES = 'HIDE_CHILD_CATEGORIES'
export const HIDE_PARENT_CATEGORIES = 'HIDE_PARENT_CATEGORIES'

const filterOutParentCategories = ({ aggregateData, categoriesHashmap }) => {
  return _.pickBy(aggregateData, obj => {
    const id = _.get(obj, 'category.id')
    const foundChildCatWithMatchingParent = _.pickBy(categoriesHashmap, category => category.parentId === id)
    return !_.size(foundChildCatWithMatchingParent)
  })
}

const filterOutChildCategories = ({ aggregateData, categoriesHashmap }) => {
  return _.pickBy(aggregateData, obj => {
    const id = _.get(obj, 'category.id')
    const foundCategory = categoriesHashmap[id]
    return !_.get(foundCategory, 'parentId')
  })
}

export const getTransformedData = ({ transform, props, aggregateData }) => {
  if (transform === HIDE_PARENT_CATEGORIES) {
    const categoriesHashmap = _.get(props, 'categoriesHashmap')
    return filterOutParentCategories({ aggregateData, categoriesHashmap })
  }
  if (transform === HIDE_CHILD_CATEGORIES) {
    const categoriesHashmap = _.get(props, 'categoriesHashmap')
    return filterOutChildCategories({ aggregateData, categoriesHashmap })
  }
}
