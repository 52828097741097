import _ from 'lodash'
import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { productCatalogueFunctions } from '../dependencies'
import { getUiConfig } from '../uiConfigStore'
import './uiConfig'
import modalService from '../../services/modalService'
import { productsFilters, translations } from '../../config'
import HybridRouter from '../../components/HybridRouter'
import { goBack } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'

const getFeatureType = (featureType = 'basket') => {
  const pathname = useSelector(state => _.get(state, 'router.location.pathname', ''))
  if (pathname.includes('consultation')) {
    if (pathname.includes('virtual')) {
      return 'virtualConsultation'
    }
    return 'consultation'
  }

  return featureType
}

const ImplProductRouter = ({
  initialItems,
  onSelected,
  featureType: featureTypeFromProps
}) => {
  const featureType = getFeatureType(featureTypeFromProps)
  const useRouter = featureType === 'basket'
  const dispatch = useDispatch()

  const RouterComponent = (routerProps) => {
    const { push, replace, goBack, categoryId } = routerProps

    const translatedProductFilters = productsFilters.map(item => ({
      label: translations(item.label),
      value: item.value,
    }));

    const useProductListScreen = Modules.Product.Implementation.provideUseProductListScreen({
      productCatalogueFunctions,
      modalService,
      featureType,
      sortOptions: translatedProductFilters, // productsFilters is misleadingly named,
      initialItems,
      push
    })
    const implementation = Modules.Product.Implementation.provideUseRootScreen({
      modalService,
      productCatalogueFunctions,
      push,
      replace,
      goBack,
      sortOptions: translatedProductFilters, // productsFilters is misleadingly named,
      match: { params: { categoryId } },
      onSelected: (fullProducts) => {
        onSelected(fullProducts)
      },
      featureType,
      hooks: {
        useProductListScreen
      },
      initialItems,
      showVariationsInDropdown: true
    })

    return (
      <Modules.Product.Screens.RootScreen
        implementation={implementation}
        uiConfig={{
          ...getUiConfig('Modules.Product.Screens.RootScreen'),
          sortOnly: featureType === 'consultation'
        }} />
    )
  }

  const productsRoutes = [
    { path: '/products/cat/:categoryId', component: RouterComponent, exact: false },
    { path: '/products/', component: RouterComponent, exact: false },
    // { path: '/product/:id', component: ProductDetailScreen, exact: true },
  ]

  return (
    <HybridRouter
      routes={productsRoutes}
      noRouting={!useRouter}
      exit={() => {
        if (featureType === 'basket') {
          return dispatch(goBack())
        }
        modalService.close()
      }}
      passedProps={{}}
      initialPath={'/products'}
    />
  )
}

/* FIXME: this is using memo() because of a bug
 * where fetching unread notification count causes modals to re-render
 */
export const ProductRouter = React.memo(ImplProductRouter)

export const ProductCatalogueApp = () => {
  return (
    <ProductRouter useRouter />
  )
}
