import { defaultState } from './store'
import { promiseReducer } from '../../util'

class StoreDetailsReducers {
  fetchStore = promiseReducer(
    (state, action) => {
      const { result } = action

      return {
        ...state,
        result,
        // not sure what functionality we want with regards to this
        view: { type: 'store' }
      }
    }
  )
  setView = promiseReducer(
    (state, action) => ({
      ...state,
      view: action.result
    })
  )
  setTimePeriod = (state, action) => {
    const { timePeriod } = action
    return {
      ...state,
      timePeriod
    }
  }
  createStore = promiseReducer(
    (state, action) => {
      console.log(action)
      return {
        ...state
      }
    }
  )
  updateStore = promiseReducer(
    (state, action) => {
      console.log(action)
      return {
        ...state
      }
    }
  )
  clear = (state, action) => {
    return defaultState
  }
}

export default new StoreDetailsReducers()
