import _ from 'lodash'

import getGroupedItems from './getGroupedItems'
import { makeGroupDecider } from './helpers'

const _getGroupNames = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { groupBy } = data

  const decideGroup = makeGroupDecider(groupBy)
  const groupedItems = getGroupedItems(props)
  // make an array: [ { name, value, color } ]
  const groupNames = (
    decideGroup.groupNames.length
    ? decideGroup.groupNames
    : Object.keys(groupedItems)
      .sort((a, b) => {
        if (a < b) return -1
        if (a > b) return 1
        return 0
      })
  )
  return groupNames
}
const getGroupNames = _.memoize(_getGroupNames)

export default getGroupNames
