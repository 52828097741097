import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { deliveryEnabled } from '../../../config'
import CheckoutWrapper from '../common/CheckoutWrapper'
import DeliverySection from './sections/DeliverySection'
import style from './style'

const checkoutHasDelivery = deliveryEnabled

const OrderSummary = (props) => {
  const {
    classes,
    customer,
    ...rest
  } = props

  return (
    <CheckoutWrapper handleNext>
      {(props.customer && checkoutHasDelivery) ? <DeliverySection {...rest} /> : null}
    </CheckoutWrapper>
  )
}

export default withStyles(style)(OrderSummary)
