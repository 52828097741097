const style = {
  image: {
    width: '100%',
    paddingTop: '100%'
  },
  title: {
    marginTop: 10
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  title: {
    fontWeight: 'bold'
  }
}

export default style
