import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import SearchBar from '../common/SearchBar'
import Results from '../common/Results'
import CategoriesGrid from './CategoriesGrid'
import MultiSelectBar from '../common/MultiSelectBar'

class ProductsRootScreen extends Component {
  render () {
    const { query, resultsQuery, multiSelect } = this.props
    return <Fragment>
      <SearchBar {...this.props} query={null} />
      {
        query && resultsQuery
          ? <Results {...this.props} />
          : <CategoriesGrid {...this.props} />
      }
      {multiSelect && <MultiSelectBar {...this.props} />}
    </Fragment>
  }
}

ProductsRootScreen.propTypes = {
  query: PropTypes.string,
  resultsQuery: PropTypes.array,
  multiSelect: PropTypes.bool
}

export default ProductsRootScreen
