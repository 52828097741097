import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import Tag from './Tag'

export default compose(
  connect(),
  withHandlers({
    onClick: ({ dispatch, onTagClick }) => (event, id) => {
      event.stopPropagation()
      onTagClick(id)
    }
  })
)(Tag)
