import React, { Fragment } from 'react'

import ProductsGrid from './ProductsGrid'
import TitleBar from './TitleBar'

const Results = props => {
  return <Fragment>
    <TitleBar {...props} />
    <ProductsGrid {...props} />
  </Fragment>
}

export default Results
