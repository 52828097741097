const style = {
  listItemsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 15
  },
  chartContainer: {
    height: 250,
    minWidth: 300,
    position: 'relative'
  }
}

export default style
