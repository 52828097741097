import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../../containers/AlertBadge'
import { translations } from '../../config'
import DrawerBar from '../DrawerBar'

import style from './style'

const SideMenu = ({
  items,
  isOpen,
  onClose,
  onMenuItemClick,
  footerElement,
  classes,
  user,
  appsAllowedForRole,
  networkConnectivity
}) => (
  <Drawer
    open={isOpen}
    onClose={onClose}
    anchor='left'
    classes={{ paper: classes.rootMenu }}
  >
    <DrawerBar closeDrawer={onClose} left />
    <nav
      aria-label={translations('Side Menu Title')}
      className={classes.container}>
      <ul className={classes.list}>
        {items.map(({ id, title, badgeType, allowOffline }) => {
          // filter out 'USER_MANAGEMENT' item, as now shows as a button in the footer of the side menu
          if ((!appsAllowedForRole[id] && id !== 'DASHBOARD') || id === 'USER_MANAGEMENT' || (!networkConnectivity && !allowOffline)) {
            return null
          }
          return (
            <li key={id}>
              <AlertBadge type={badgeType} classes={{ badge: classes.alertBadge }}>
                <ButtonBase focusRipple onClick={() => onMenuItemClick(id)} classes={{ root: classes.menuItem }}>{translations(title)}</ButtonBase>
              </AlertBadge>
            </li>
          )
        })}
      </ul>
    </nav>

    <div className={classes.footer}>
      {footerElement}
    </div>
  </Drawer>
)

SideMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    title: PropTypes.string
  })),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func
}

export default withStyles(style)(SideMenu)
