import React from 'react'
import moment from 'moment'

import { dateFormat, timeFormat } from '../../../config'
import DateTimePicker from './DateTimePicker'

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

class DateTimePickerContainer extends React.Component {
  constructor (props) {
    super()
    const formattedValue = moment(props.value, dateAndTimeFormat).format('YYYY-MM-DDTHH:mm')
    if (moment(formattedValue, 'YYYY-MM-DDTHH:mm', true).isValid()) {
      this.value = formattedValue
    } else {
      this.value = props.value
    }
  }

  onChange = (e) => {
    const { value } = e.target
    this.props.onChange(this.handleChange(value))
  }

  onBlur = (e) => {
    const { value } = e.target
    this.props.onBlur(this.handleChange(value))
  }

  handleChange = value => {
    this.value = value
    const date = new Date(value)
    const formattedValue = moment(date).format(dateAndTimeFormat)
    if (moment(formattedValue, dateAndTimeFormat, true).isValid()) {
      return formattedValue
    } else {
      return ''
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.value,
    onChange: this.onChange,
    onBlur: this.onBlur
  })

  render () {
    const props = this._generateProps()
    return (
      <DateTimePicker {...props} />
    )
  }
}

export default DateTimePickerContainer
