import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../P'
import { translations, noPrices } from '../../../../config'

import style from './style'

const OrderContentContainer = ({ orderDate, orderNumber, numberOfProducts, total, status, hideStatus, orderType, classes }) => (
  <dl className={classes.listContainer}>
    <dt className={classes.title}>{translations('Order created')}</dt>
    <dd className={classes.data}><P value={orderDate} /></dd>
    <dt className={classes.title}>{translations('Order number')}</dt>
    <dd className={classes.data}><P value={`#${orderNumber}`} /></dd>
    <dt className={classes.title}>{translations('Order type')}</dt>
    <dd className={classes.data}><P value={orderType} /></dd>
    <dt className={classes.title}>{translations('Number of products')}</dt>
    <dd className={classes.data}><P value={numberOfProducts} /></dd>
    {noPrices ? null : <dt className={classes.totalHeader}><P type='inline' value={translations('Product Total')} /></dt>}
    {noPrices ? null : <dd className={`${classes.totalContent} ${classes.data}`}><P className={classes.bold} type='inline' value={total} /></dd>}
    {
      !hideStatus
        ? (
          <Fragment>
            <dt className={classes.title}>{translations('Order status')}</dt>
            <dd className={classes.data}>
              <div className={classes.statusBubble}>
                <P className={classes.bold} value={_.startCase(status)} />
              </div>
            </dd>
          </Fragment>
        )
        : null
    }
  </dl>
)

export default withStyles(style)(OrderContentContainer)
