const styles = theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.main
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 100,
    margin: 0
  },
  logo: {
    width: '250px'
  },
  forgotLinkWrapper: {
    textAlign: 'center',
    marginTop: 18
  },
  forgotLink: {
    fontSize: 14,
    color: '#000'
  },
  innerContainer: {
    padding: '40px',
    width: '100%',
    maxWidth: 338,
    [theme.breakpoints.up('sm')]: {
      padding: 0
    }
  }
})

export default styles
