import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations, noPrices as noPricesConfig } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'

import { InChart as Generic } from '../Generic'
import style from './style'

const getBubbleText = (noPrices) => (
  noPrices || noPricesConfig
  ? ({ units }) => `${units || 0} ${translations('units')}`
  : ({ total, units }) => _.template(translations('Top Products Info'))({
    total: currencyFormatter.format(total),
    units: units || 0
  })
)

let InChart = props => {
  const { product = {}, total, units, noPrices, classes } = props
  const { name, images = [] } = product

  const mainText = name

  const bubbleText = getBubbleText(noPrices)({ total, units })

  const rightContent = (
    <div
      className={classes.image}
      style={{
        backgroundImage: `url(${images[0]})`
      }}
    />
  )

  return <Generic
    mainText={mainText}
    bubbleText={bubbleText}
    rightContent={rightContent}
  />
}

InChart = withStyles(style)(InChart)

export { InChart }
