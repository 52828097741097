import { connect } from 'react-redux'
import { getFormValues, getFormInitialValues } from 'redux-form'

export default (formId) => (Form) => {
  const mapStateToProps = state => ({
    formValues: getFormValues(formId)(state),
    initialFormValues: getFormInitialValues(formId)(state)
  })

  return connect(mapStateToProps)(Form)
}
