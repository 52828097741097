import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import * as authSelectors from '../auth/selectors'
import { selectors as appSelectors, constants as appConstants } from '../app'

import * as selectors from './selectors'
import actions from './actions'
import { BRAND_INSPIRATIONS } from '../app/constants'

class InspirationsMiddleware {
  searchInspirationsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const state = getState()
      const hasResults = selectors.getHasResults(state)
      if (
        authSelectors.getIsLoggedIn(state) &&
        (
          matchPath(path, { path: '/inspirations', exact: true }) ||
          (matchPath(path, { path: '/inspirations/:id', exact: true }) && !hasResults)
        )
      ) {
        const type = appSelectors.getInspirationsTab(state) === BRAND_INSPIRATIONS ? 'brand' : 'user'
        const userId = type === 'brand' ? undefined : authSelectors.getActiveUserId(state)
        dispatch(actions.searchInspirationsFresh({ type, userId }))
      }
    }
  }
  searchInspirationTagsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const state = getState()
      if (
        authSelectors.getIsLoggedIn(state) &&
        (
          matchPath(path, { path: '/inspirations', exact: true }) ||
          matchPath(path, { path: '/messages/new', exact: true })
        )
      ) {
        dispatch(actions.fetchInspirationTags())
      }
    }
  }
  fetchInspirationsOnTabChangeMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === appConstants.CHANGE_INSPIRATIONS_TAB) {
      const state = getState()
      // only fetch if the tab has changed
      if (action.tab !== appSelectors.getInspirationsTab(state)) {
        const type = action.tab === BRAND_INSPIRATIONS ? 'brand' : 'user'
        const userId = type === 'brand' ? undefined : authSelectors.getActiveUserId(state)
        dispatch(actions.clearResults())
        dispatch(actions.searchInspirationsFresh({ type, userId }))
      }
    }
    next(action)
  }
}

export default new InspirationsMiddleware()
