import { connect } from 'react-redux'
import { withHandlers, compose, withState, lifecycle } from 'recompose'
import _ from 'lodash'
import {
  getFormValues
} from 'redux-form'

import { translations, getAppConfig } from '../../config'
import * as validators from '../../validators'
import digitalStoreSdk from '../../digitalStoreSdk'

import ConsultationWizard from './ConsultationWizard'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import { actions as appActions } from '../../store/modules/app'
import { actions as inspirationsActions } from '../../store/modules/inspirations'
import { actions as categoriesActions, selectors as categoriesSelectors } from '../../store/modules/categories'
import { actions as productsActions } from '../../store/modules/products'
import { actions as followingActions } from '../../store/modules/following'
import { actions as customerDetailsActions } from '../../store/modules/customerDetails'
import modalService from '../../services/modalService'
import ConsultationCustomerSearch from './ConsultationCustomerSearch'
import InspirationsRouter from '../Inspirations/InspirationsRouter'
import ProductsRouter from '../Products/ProductsRouter'
import BasketRouter from '../Basket/BasketRouter'

const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields', [])
const customerDetailsFields = customerFieldsConfig.map(field => field.id)

const customerDetailsSchema = (customerFieldsConfig) && customerFieldsConfig.map(item => {
  const { id, field, validate, ...rest } = item
  return {
    id,
    field,
    props: {
      name: id,
      validate: validators.generateSchemaValidationFromConfig(validate),
      ...rest
    }
  }
})

const mapStateToProps = state => {
  const categories = categoriesSelectors.getCategories(state)
  const customerDetails = currentOrderSelectors.getCurrentOrderCustomer(state)
  const drinksCategory = _.find(categories, cat => cat.externalCategoryId === 'drinks')
  const customerNotesInitialValues = _.chain(customerDetails)
    .pickBy((value, key) => {
      if (_.find(customerDetailsFields, (field) => key === field)) {
        return value
      }

      return undefined
    })
    .value()
  const customerNotesValues = getFormValues('consultation-customer-details-form')(state)

  return {
    consultationSteps: getAppConfig('CONSULTATIONS', 'steps'),
    customer: currentOrderSelectors.getConsultationCustomer(state),
    customerNotes: currentOrderSelectors.getConsultationCustomerNotes(state),
    inspiration: currentAppointmentSelectors.getConsultationInspiration(state),
    drinks: currentAppointmentSelectors.getConsultationRequestItems(state),
    beforePhoto: currentAppointmentSelectors.getConsultationBeforePhoto(state),
    afterPhoto: currentAppointmentSelectors.getConsultationAfterPhoto(state),
    products: currentAppointmentSelectors.getConsultationProducts(state),
    selectedProducts: currentAppointmentSelectors.getAppointmentProducts(state),
    drinksCategory,
    customerDetailsSchema,
    customerNotesInitialValues,
    customerNotesValues,
    customerBasketReview: currentOrderSelectors.getConsulationCustomerBasketReview(state),
    hasBasketBeenReviewed: currentOrderSelectors.getCurrentOrderBasketReviewStatus(state)
  }
}

export default compose(
  connect(
    mapStateToProps
  ),
  withState('tabValue', 'changeTabValue', 'basket'),
  withState('customerDetailsOpen', 'toggleCustomerDetails', false),
  withState('isCustomerDetailsEditing', 'toggleCustomerDetailsEdit', false),
  withState('hasCustomerNotesSaved', 'setCustomerNotesSaved', false),
  lifecycle({
    componentDidMount () {
      this.props.dispatch(categoriesActions.fetchAllCategories())
    }
  }),
  withHandlers({
    addCustomerToOrder: ({ dispatch }) => () => {
      dispatch(followingActions.fetchFollowing())
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addCustomer'))
      modalService.open({
        component: ConsultationCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          modalService.close()

          return digitalStoreSdk.customers.fetchCustomerDetails({ id: customer.id })
            .then(customer => {
              dispatch(currentOrderActions.updateOrder({
                customer,
                startCheckoutWithCustomer: true
              }))
            })
        },
        onRegisterClick: () => dispatch(appActions.toggleBasket({ isOpen: false })),
        onBackClick: () => {
          modalService.close()
        }
      })
    },
    addInspirationToAppointment: ({ dispatch }) => () => {
      dispatch(inspirationsActions.fetchInspirationTags())
      dispatch(inspirationsActions.searchInspirationsFresh({ type: 'brand' }))
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addLook'))
      modalService.open({
        component: InspirationsRouter,
        hideBottomBar: true,
        selectMode: true,
        fullScreen: true,
        exit: () => modalService.close(),
        noRouting: true,
        tabbed: false
      })
    },
    photoPermissionCheck: ({ customer, dispatch }) => callback => {
      if (!customer.photoOptIn) {
        modalService.action({
          title: translations('Before & After Photo'),
          text: translations('I am happy for my before and/or after photo(s) to be taken during my consultation and emailed to me afterwards.', {
            customer: customer.detail
          }),
          actions: [
            {
              text: translations('No')
            },
            {
              text: translations('Yes'),
              primary: true,
              onClick: () => {
                dispatch(customerDetailsActions.updateCustomer({ id: customer.id, photoOptIn: true }, true))
                  .then(() => {
                    callback()
                  })
                  .catch((error) => {
                    console.error(error)
                    console.log('Could not update customer')
                  })
              }
            }
          ]
        })
      } else {
        callback()
      }
    },
    addAfterPhotoToOrder: ({ dispatch }) => file => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('afterPhoto'))
      dispatch(currentAppointmentActions.addContent({
        type: 'photo',
        details: {
          type: 'afterPhoto',
          file: file
        }
      }))
    },
    addBeforePhotoToOrder: ({ dispatch }) => file => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('beforePhoto'))
      dispatch(currentAppointmentActions.addContent({
        type: 'photo',
        details: {
          type: 'beforePhoto',
          file: file
        }
      }))
    },
    addRequestItemsToOrder: ({ dispatch, drinksCategory }) => () => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('requestItem'))
      dispatch(productsActions.resetStore())
      // dispatch(currentOrderActions.clearSelectedProducts())
      modalService.open({
        component: ProductsRouter,
        initialPath: `/products/cat/${drinksCategory.id}`,
        fullScreen: true,
        hideSearchBar: true,
        exit: () => modalService.close(),
        buttonValue: translations('Select Product'),
        noRouting: true,
        // passed props
        disablePrice: true
      })
    },
    addProductsToOrder: ({ dispatch }) => () => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('addProductOrService'))
      dispatch(productsActions.resetStore())
      // dispatch(currentOrderActions.clearSelectedProducts())
      modalService.open({
        component: ProductsRouter,
        fullScreen: true,
        overflowHidden: true,
        exit: (currentOrderData) => {
          modalService.close()
        },
        buttonValue: translations('Select Product'),
        multiSelect: true,
        noRouting: true
      })
    },
    viewCustomerReviewBasket: ({ dispatch, hasBasketBeenReviewed }) => () => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent('customerReviewBasket'))
      dispatch(appActions.toggleCustomerReviewBasket({ isOpen: true }))
      modalService.open({
        component: BasketRouter,
        initialPath: `/basket`,
        fullScreen: true,
        modalIndex: 1,
        noRouting: true,
        silent: true,
        onDone: () => {
          dispatch(appActions.toggleCustomerReviewBasket({ isOpen: false }))
          if (!hasBasketBeenReviewed) {
            dispatch(currentOrderActions.manageCustomerReviewBasketStatus({ seen: true }))
          }
          return modalService.close({ modalIndex: 1 })
        }
      })
    },
    updateCustomerNotes: ({ dispatch, customer, toggleCustomerDetailsEdit, customerNotesValues, setCustomerNotesSaved }) => () => {
      const formValues = customerNotesValues

      dispatch(customerDetailsActions.updateCustomer({ id: customer.id, ...formValues }, true))
        .then(() => {
          toggleCustomerDetailsEdit(false)
          setCustomerNotesSaved(true)
        })
        .catch(err => {
          console.log('Could not update customer')
        })
    }
  })
)(ConsultationWizard)
