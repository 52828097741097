import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import ViewEventScreen from './ViewEventScreen'
import { actions as eventDetailsActions, selectors as eventDetailsSelectors } from '../../../store/modules/eventDetails'
import { actions as eventsActions } from '../../../store/modules/events'
import { actions as appActions } from '../../../store/modules/app'
import { actions as eventWaitlistActions } from '../../../store/modules/eventWaitlist'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import withFullScreenLoader from '../../../components/FullScreenLoader'
import HorizontalGallery from '../../../components/HorizontalGallery'
import ViewGalleriesScreen from '../ViewGalleriesScreen'
import { BRAND_INSPIRATIONS } from '../../../store/modules/app/constants'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import ViewEventCustomerSearch from './ViewEventCustomerSearch'

const mapStateToProps = state => {
  const userRoleCanDelete = authSelectors.getHasDeleteEvent(state)
  const eventBelongsToUser = authCombinedSelectors.getEventBelongsToUser(state)
  const event = eventDetailsSelectors.getResult(state)
  const selectedGalleryIndex = eventDetailsSelectors.getSelectedGalleryIndex(state)
  const gallerySelectImage = _.get(event, `galleries[${selectedGalleryIndex}].media[0].src`)
  const hasMultipleGalleries = eventDetailsSelectors.getHasMultipleGalleries(state)

  return {
    isLoading: eventDetailsSelectors.getIsLoading(state),
    event,
    userCanDeleteEvent: userRoleCanDelete || eventBelongsToUser,
    eventBelongsToUser: eventBelongsToUser,
    canSendMessage: authSelectors.getIsMessagingAllowedForRole(state),
    eventFailedToLoad: eventDetailsSelectors.getEventFailedToLoad(state),
    selectedGalleryIndex,
    gallerySelectImage,
    hasMultipleGalleries,
    messageContent: eventDetailsSelectors.getMessageContent(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onTagClick: ({ dispatch }) => tagId => {
      dispatch(push('/events'))
      dispatch(appActions.changeEventsTab({ tab: BRAND_INSPIRATIONS }))
      dispatch(eventsActions.searchEventsFresh({ tagId, type: 'brand' }))
    },
    onDeleteClick: ({ dispatch, event }) => () => {
      modalService.action({
        title: translations('Delete Event Title'),
        text: translations('Delete Event Text'),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => {
              dispatch(eventDetailsActions.deleteEvent({ id: event.id }))
            },
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    },
    onSendMessageClick: ({ messageContent, dispatch }) => () => {
      dispatch(createMessageActions.addContentToMessage({ content: [messageContent] }))
    },
    onImageClick: ({ event }) => () => {
      return modalService.open({
        component: HorizontalGallery,
        zoomModal: true,
        fullScreen: true,
        images: event.images || [],
        inModal: true
      })
    },
    onGallerySelectClick: ({ dispatch }) => () => {
      return modalService.open({
        component: ViewGalleriesScreen,
        zoomModal: true,
        fullScreen: true,
        inModal: true,
        onGalleryClick: (index) => {
          dispatch(eventDetailsActions.setActiveGalleryIndex(index))
          modalService.close()
        }
      })
    },
    onClickShowWaitlist: ({ dispatch, event }) => () => {
      dispatch(push(`/events/${event.id}/waitlist`))
    },
    onClickAddToWaitlist: ({ dispatch, event }) => () => {
      modalService.open({
        component: ViewEventCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          dispatch(eventWaitlistActions.addToEventWaitlist({
            eventId: event.id,
            customerId: customer.id
          }))
          .then(() => {
            modalService.close()
            modalService.continue({
              title: translations('Added to waitlist title'),
              text: translations('Added to waitlist text', {
                customer: `${customer.firstName} ${customer.lastName}`
              })
            })
          })
          .catch(err => {
            modalService.close()
            modalService.continue({
              title: translations('Adding to waitlist failed title'),
              text: translations(err.code)
            })
          })
        },
        onBackClick: () => modalService.close()
      })
    }
  }),
  withFullScreenLoader
)(ViewEventScreen)
