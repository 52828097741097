import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import H2 from '../../components/H2'
import Button from '../../components/Button'
import Container from '../../components/Container'
import { translations } from '../../config'

const ErrorScreen = ({
  onClick,
  title,
  buttonText,
  classes
}) => {
  return (
    <Container withMarginTop className={classes.offlineScreenContainer}>
      <H2 className={classes.offlineTitleStyle} value={title} />
      {
        onClick
        ? <Button className={classes.offlineButton} onClick={onClick} buttonType='primary'>
          {buttonText}
        </Button>
        : null
      }
    </Container>
  )
}

ErrorScreen.defaultProps = {
  buttonLabel: translations('Click Here')
}

ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func
}

export default withStyles(style)(ErrorScreen)
