// copied from containers/ProductDetail/ProductDetailScreen/StockIndicator/stockLevelConstants
// because stock logic needs to be used outside of the PDP (in the PLP).
// TODO: make this better.

export const inStockLevel = 3
export const lowStockHighLevel = 2
export const lowStockLowLevel = 1
export const outOfStockLevel = 0
export const itemNotStocked = -1

export const ONLINE_AVAILABLE_STATUS = 'available'
export const ONLINE_UNAVAILABLE_STATUS = 'unavailable'
export const ONLINE_LOW_STOCK_STATUS = 'lowStock'

export const stockStatusWeight = {
  [ONLINE_AVAILABLE_STATUS]: 2,
  [ONLINE_UNAVAILABLE_STATUS]: 0,
  [ONLINE_LOW_STOCK_STATUS]: 1
}
