import React from 'react'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import _ from 'lodash'
import cx from 'classnames'

import P from '../../../../../../components/P'
import { Dropdown } from '../../../../../../components/Fields'
import style from './style'
import { getImage } from '../../../../../../components/Images'
import { translations } from '../../../../../../config'
import { logger } from '../../../../../../helpers'

const noImagePlaceholder = getImage('imageNotAvailableImg')

const RefundProduct = ({ product, classes, onProductClick }) => {
  // Strip zero quantity
  const options = _.reduce(_.range(_.get(product, 'quantity') + 1), (memo, quantityAmount, index) => {
    if (quantityAmount !== 0) {
      memo.push({
        label: (quantityAmount).toString(),
        value: (quantityAmount).toString()
      })
    }
    return memo
  }, [])
  const imageSrc = _.get(product, 'images.0') || noImagePlaceholder
  // default to false
  const unsold = _.get(product, 'unsold', false)

  const containerClasses = cx(classes.fullProductContainer, {
    [classes.op3]: unsold
  })
  return (
    <div className={containerClasses}>
      <div onClick={onProductClick}>
        <div className={classes.imageContainer}>
          <div className={classes.imageInnerContainer}>
            <img className={classes.singleProductImage} src={imageSrc} />
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <P className={classes.productName} type='bold' value={product.name} />
        </div>
      </div>
      {
        !unsold
          ? (
            <div className={classes.dropdownContainer}>
              <FormControl className={classes.formControl}>
                <Dropdown name={`${product.id}_quantity`} label={translations('Quantity to refund')} options={options} />
              </FormControl>
            </div>
          )
          : null
      }
    </div>
  )
}

RefundProduct.propTypes = {
  product: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onProductClick: PropTypes.func.isRequired
}

export default withStyles(style)(RefundProduct)
