import { useSelector } from 'react-redux'

import {
  getIsLoading,
  getIsLoadingFresh,
  getAppointments,
  getAppointmentsFilters,
  getAppointmentsQuery,
  getHasMore
} from '../store/modules/appointments/selectors'

/**
 * @description returns boolean
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns boolean
 * @returns
 */
 export const useIsLoadingFresh = () => {
  return useSelector(getIsLoadingFresh)
}

/**
 * @description returns appointments
 * @returns
 */
export const useAppointments = () => {
  return useSelector(getAppointments)
}

/**
 * @description returns filters for appointments
 * @returns
 */
export const useAppointmentsFilters = () => {
  return useSelector(getAppointmentsFilters)
}

/**
 * @description returns filters as a query for appointments
 * @returns
 */
export const useAppointmentsQuery = () => {
  return useSelector(getAppointmentsQuery)
}

/**
 * @description returns if there are more appointments to load
 * @returns
 */
 export const useHasMore = () => {
  return useSelector(getHasMore)
}

