let schemaConfig = {}

export const setSchemaConfig = (name, config) => {
  schemaConfig[name] = {
    ...schemaConfig[name],
    ...config
  }
}

export const getSchemaConfig = (name) => {
  return schemaConfig[name] || {}
}