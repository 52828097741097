import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import { selectors as authSelectors } from '../auth'
import actions from './actions'

class MessageDetailsMiddleware {
  loadMessageDetailsMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/messages/:id', exact: true })
      const isRoutingToMessageDetails = result && result.params.id !== 'new'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToMessageDetails && isLoggedIn) {
        dispatch(actions.fetchMessageDetails({ id: result.params.id }))
      }
    }
  }
}

export default new MessageDetailsMiddleware()
