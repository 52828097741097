import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import OrderInfo from '../OrderInfo'
import style from './style'

class OrderFooter extends Component {
  render () {
    const { orderInfo, classes } = this.props

    return <div className={classes.container}>
      <OrderInfo info={orderInfo} emphasisKey='total' />
    </div>
  }
}

export default withStyles(style)(OrderFooter)
