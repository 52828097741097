import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import List from '../../../components/List'

import BasketItem from '../BasketItem'
import style from './style'

const BasketList = props => {
  const {
    editableQuantities,
    editablePrices,
    clickable,
    textType,
    gap,

    products,
    goToProduct,
    classes,

    discountMode,
    addSelection,
    removeSelection,
    selectedLines,
    addDiscountItems,

    hideUnpurchasedItems,
    hideUndo,
    hideRefund
  } = props

  const itemProps = {
    editableQuantities,
    editablePrices,
    textType,
    gap,
    products,

    discountMode,
    addSelection,
    removeSelection,
    selectedLines,
    addDiscountItems,

    hideUnpurchasedItems,
    hideUndo,
    hideRefund
  }

  if (products && products.length) {
    return <List
      items={products}
      itemProps={itemProps}
      ItemComponent={BasketItem}
      noMargin
      onClickItem={clickable ? goToProduct : undefined}
    />
  } else {
    return <div className={classes.emptyBasketMessage}>
      {translations('Empty Basket')}
    </div>
  }
}

export default withStyles(style)(BasketList)
