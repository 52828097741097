import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { getImage } from '../../../Images'

import style from './style'

const smsIcon = getImage('smsIcon')

const SMSIcon = ({ classes, messageType }) => {
  return (
    <img src={smsIcon} className={classes.image} />
  )
}

SMSIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  messageType: PropTypes.string.isRequired
}

export default withStyles(style)(SMSIcon)
