import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Dropdown from '../../../../components/Fields/Dropdown'
import Checkbox from '../../../../components/Fields/Checkbox'
import { translations } from '../../../../config'
import styles from './style'

class UsersSearchFilters extends Component {
  state = {
    roleIds: this.props.roleIds,
    storeIds: this.props.storeIds,
    includeDeactivated: this.props.includeDeactivated
  }

  searchUsers = _.debounce(({ roleIds, storeIds, includeDeactivated }) => {
    const { searchUsers } = this.props
    searchUsers({ roleIds, storeIds, includeDeactivated })
  }, 300)

  updateIncludeDeactivated = (e) => {
    const includeDeactivated = e.target.checked
    this.searchUsers({ includeDeactivated })
    this.setState({ includeDeactivated })
  }

  updateRoles = (e) => {
    const roleIds = e.target.value
    this.searchUsers({ roleIds })
    this.setState({ roleIds })
  }

  updateStores = (e) => {
    const storeIds = e.target.value
    this.searchUsers({ storeIds })
    this.setState({ storeIds })
  }

  render() {
    const { classes, loggedInUserStoresOptions, allRolesOptions } = this.props
    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Stores')}
                value={this.state.storeIds}
                options={loggedInUserStoresOptions}
                onChange={this.updateStores}
                passedProps={{ multiple: true }}
                multiple
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.middleContent}>
            <FormControl className={classes.roleFilter}>
              <Dropdown
                label={translations('Roles')}
                value={this.state.roleIds}
                options={allRolesOptions}
                onChange={this.updateRoles}
                passedProps={{ multiple: true }}
                multiple
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.rightContent}>
            <FormControl className={classes.checkboxContainer}>
              <Checkbox
                label={translations('Include Deactivated')}
                value={this.state.includeDeactivated}
                checked={this.state.includeDeactivated}
                onChange={this.updateIncludeDeactivated}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(UsersSearchFilters)
