import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import BasicModalOverlay from '../../BasicModalOverlay'
import { Input } from '../../Fields'
import Form from '../../Form'
import { ean } from '../../../validators'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import style from './style'

export const formId = 'fetch-by-ean'
const EANForm = Form(formId)

class EnterEANModal extends Component {
  state = {
    submitting: false
  }

  onSubmit = formData => {
    const { onConfirm, validateAndRedirect  } = this.props
    this.setState({ submitting: true })
    onConfirm({
      ean: formData.ean,
      onError: () => modalService.continue({
        title: translations('EAN No Results Title', { ean: formData.ean }),
        confirmButtonText: translations('Close'),
        success:() => validateAndRedirect({ openSideBasket: true })
      }),
      onSuccess:() => {
        modalService.close()
        validateAndRedirect({ openSideBasket: true })
      }
    })
  }

  render() {
    const { classes, ...rest } = this.props
    return (
      <EANForm
        onSubmit={this.onSubmit}
      >
        <BasicModalOverlay
          {...rest}
          submitting={this.state.submitting}
        >
          <FormControl className={classes.formControl}>
            <Input
              name='ean'
              label={translations('EAN')}
              validate={ean}
            />
          </FormControl>
        </BasicModalOverlay>
      </EANForm>
    )
  }
}

export default withStyles(style)(EnterEANModal)
