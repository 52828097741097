import _ from 'lodash'

import { translations } from '../../config'

import modalService from '../modalService'

// example scan result
// {
//   cancelled: 0,
//   text: '8221140348169',
//   format: 'EAN_13'
// }

class BarcodeScannerService {
  scan = (onSuccess, onError) => {
    const _scan = _.get(window, 'cordova.plugins.barcodeScanner.scan')
    if (_scan) {
      this.getPermissions()
        .then(() => _scan(onSuccess, onError, { showFlipCameraButton: true, showTorchButton: true }))
        .catch(error => onError(error))
    } else {
      onError('barcode scanner plugin not found')
    }
  }

  getPermissions = () => {
    return new Promise((resolve, reject) => {
      const _diagnostic = _.get(window, 'cordova.plugins.diagnostic')
      if (_diagnostic) {
        const statuses = _diagnostic.permissionStatus
        _diagnostic.getCameraAuthorizationStatus(
          status => {
            if (status === statuses.GRANTED) {
              resolve()
            } else {
              _diagnostic.requestCameraAuthorization(
                status => {
                  if (status === statuses.GRANTED) {
                    resolve()
                  } else {
                    modalService.action({
                      title: translations('Camera Permission Title'),
                      text: translations('Camera Permission Text'),
                      actions: [
                        {
                          success: true,
                          text: translations('yes'),
                          onClick: () => _diagnostic.switchToSettings(resolve, reject),
                          primary: true
                        },
                        {
                          text: translations('no'),
                          onClick: () => reject(new Error('camera permission denied'))
                        }
                      ]
                    })
                  }
                },
                error => {
                  reject(error)
                }
              )
            }
          },
          error => {
            reject(error)
          }
        )
      } else {
        reject(new Error('diagnostic plugin not found'))
      }
    })
  }
}

export default new BarcodeScannerService()
