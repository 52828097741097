import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import { componentConfigs, productDetailsTypes } from '../../../../../../config'

import FullProduct from './FullProduct'

export default compose(
  connect(),
  withHandlers({
    onProductClick: ({ dispatch, product }) => () => {
      const { productId, link } = product
      const { productDetailsType } = componentConfigs.selectProduct
      if (productDetailsType === productDetailsTypes.PDP) {
        dispatch(push(`/product/${productId}`))
      } else if (productDetailsType === productDetailsTypes.LINK && link) {
        window.open(link, '_blank')
      }
    }
  })
)(FullProduct)
