import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import { Dropdown, Checkbox } from '../../../../../components/Fields'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import P from '../../../../../components/P'
import H2 from '../../../../../components/H2'
import { translations } from '../../../../../config'
import { communicationOptions } from '../../../../../config/general'
import * as validators from '../../../../../validators'
import style from './style'
import { preferenceOptionKeys } from '../../../../../store/util/marketingPreferences'
const { channelOptionKeys } = communicationOptions

const CommunicationOptions = ({
  classes,
  isStoreMarketingSelected,
  storesOptions,
  languageOptions,
  shortVariant
}) => {
  return (
    <ContentBox givenContentClass={classes.communicationContentContainer}>
      <ContentBoxHeader>
        <TextContent><H2 className={classes.contentBoxHeader} value={translations('Communication Options')} /></TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        { !shortVariant && <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className={classes.communicationWrapper}>
              <FormControl row>
                <Dropdown
                  name='preferredLanguage'
                  label={translations('Preferred Language*')}
                  options={languageOptions}
                  validate={validators.required}
                  required
                  autocomplete
                  hideClear
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={shortVariant ? 12 : 6}>
            <P value={translations('sign up - type')} className={classes.formText} />
            <div className={classes.communicationWrapper} >
              {
                shortVariant
                  ? <FormGroup row className={classes.fullWidth}>
                    <div>
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxMargin
                        }}
                        control={
                          <Checkbox
                            name='generalMarketing'
                          />
                        }
                        label={translations('General marketing')}
                        className={classes.generalMarketing}
                      />
                    </div>
                  </FormGroup>
                  : <FormGroup row className={classes.fullWidth}>
                    {_.map(preferenceOptionKeys, (option) => {
                      return (<FormControlLabel
                        classes={{
                          label: classes.checkboxMargin
                        }}
                        control={
                          <Checkbox
                            name={option.value}
                          />
                        }
                        label={translations(option.label)}
                        className={classes.noMarginLeft}
                      />)
                    }
                    )}
                  </FormGroup>
              }
            </div>
            {isStoreMarketingSelected && (
              <div className={classes.storesContainer}>
                <FormControl className={classes.fullWidth}>
                  <Dropdown
                    strict={'true'}
                    options={storesOptions}
                    label={translations('Stores for marketing')}
                    name='marketingStoreIds'
                    multiple
                  />
                </FormControl>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <P className={classes.formText} value={translations('sign up - contact')} />
            <div className={classes.communicationWrapper} >
              <FormGroup row>
                {_.map(channelOptionKeys, (option) => {
                  return (<FormControlLabel
                    classes={{
                      label: classes.checkboxMargin
                    }}
                    control={
                      <Checkbox
                        name={option.value}
                      />
                    }
                    label={translations(option.label)}
                    className={classes.noMarginLeft}
                  />)
                }
                )}
              </FormGroup>
            </div>
          </Grid>
        </Grid>
      </ContentBoxBody>
    </ContentBox>
  )
}

CommunicationOptions.propTypes = {
  classes: PropTypes.shape({}),
  isStoreMarketingSelected: PropTypes.bool.isRequired,
  storesOptions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(style)(CommunicationOptions)
