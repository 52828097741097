const style = theme => ({
  registrationButton: {
    width: '90%',
    [theme.breakpoints.up('xs')]: {
      width: '375px'
    },
    height: '50px',
    lineHeight: '50px',
    display: 'block',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    margin: '0 auto',
    fontWeight: 600
  }
})

export default style
