export default (theme) => ({
  container: {
    display: 'block',
    userSelect: 'none',
    cursor: 'pointer',
    position: 'relative'
  },
  icon: {
    width: 25,
    height: 25,
    position: 'relative'
  },
  circle: {
    backgroundColor: 'black',
    padding: 7,
    borderRadius: '50%',
    opacity: 0.3,
    'input:checked ~ &': {
      opacity: 1
    }
  },
  input: {
    position: 'absolute',
    opacity: 0,
    visibility: 'hidden',
    cursor: 'pointer'
  }
})
