const style = {
  orderHeader: {
    marginBottom: 20
  },
  carouselContainer: {
    marginBottom: 30
  }
}

export default style
