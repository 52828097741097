import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange, withProps } from 'recompose'
import { push } from 'connected-react-router'
import { destroy as destroyForm } from 'redux-form'

import { selectors as productDetailSelectors, actions as productDetailsActions } from '../../../store/modules/productDetails'
import ProductDetailScreen from './ProductDetailScreen'
import modalService from '../../../services/modalService'
import analyticsService from '../../../services/analyticsService'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { translations, productDetails as productDetailsConfig, apps, formNames } from '../../../config'
import { selectors as currentOrderSelectors, actions as currentOrderActions } from '../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import * as productDetailsCombinedSelectors from '../../../store/modules/combinedSelectors/productDetailsCombinedSelectors'
import { actions as productWaitlistActions, selectors as productWaitlistSelectors } from '../../../store/modules/productWaitlist'
import { selectors as networkConnectors } from '../../../store/modules/network'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'

import MarkdownScreen from '../../../components/MarkdownScreen'
import HorizontalGallery from './../../../components/HorizontalGallery'
import ProductDetailCustomerSearch from './ProductDetailCustomerSearch'

const mapStateToProps = state => {
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const selectedProducts = currentAppointmentSelectors.getAppointmentProducts(state)

  const isMessagingEnabled = isMessagingAllowedForRole && productDetailsConfig.messaging && apps.MESSAGING
  const productDetails = productDetailsCombinedSelectors.getCurrentProductDetails(state)
  const storeId = authSelectors.getUserSelectedStoreId(state)
  return {
    ...productDetails,
    selectedProducts,
    isMessagingEnabled,
    isLoading: productDetailSelectors.getIsLoading(state),
    productError: productDetailSelectors.getProductError(state),
    tabValue: productDetailSelectors.getBottomTab(state),
    isConnected: networkConnectors.isConnected(state),
    storeId,
    ..._.pick(currentOrderCombinedSelectors.currentOrderSelector(state), [
      'editMode',
      'orderNumber'
    ])
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['selectedProducts'], ({ selectedProducts, id }) => {
    return {
      isSelected: !!_.find(selectedProducts, (selected) => selected.id === id)
    }
  }),
  withProps({
    hideInStoreStock: !productDetailsConfig.inStoreStock
  }),
  withHandlers({
    fetchProductDetails: ({ dispatch, storeId, match, id }) => () => {
      const productId = id || match.params.id
      dispatch(productDetailsActions.fetchProduct({ id: productId, storeId }))
    },
    onSearchClick: ({ dispatch, product }) => () => {
      const updateCustomersWishlist = (params) => {
        dispatch(customerDetailsActions.updateCustomerWishlist(params))
        modalService.close()
      }
      modalService.open({
        component: ProductDetailCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          const currentWishlist = _.get(customer, 'wishlist', [])
          const customerFullName = `${customer.firstName} ${customer.lastName}`
          const productExists = currentWishlist.find(obj => obj.id === product.id)
          if (!productExists) {
            const wishlistObject = {
              id: product.id,
              brand: product.brand,
              images: product.images,
              link: product.link || "",
              name: product.name,
              price: product.price,
              type: 'product',
              externalProductId: product.externalProductId
            }
            const wishlistUpdated = [wishlistObject].concat(currentWishlist)
            return updateCustomersWishlist({
              id: customer.id,
              details: { wishlist: wishlistUpdated }
            })
          } else {
            modalService.continue({
              title: translations('Wishlist item already exists title'),
              text: translations('this item already exists text', { customer: customerFullName })
            })
          }
        },
        onBackClick: () => modalService.close()
      })
    },
    onClickAddMessage: ({ dispatch, product }) => () => {
      dispatch(createMessageActions.addContentToMessage({ content: [{ type: 'product', ...product }] }))
    },
    handleTabChange: ({ dispatch }) => (event, value) => {
      dispatch(productDetailsActions.changeProductBottomTab({ productTab: value }))
    },
    openHTMLModal: ({ dispatch, product, careInstructions }) => () => {
      analyticsService.sendCustomPDPEvent({ name: 'careInstructionsClick' })
      modalService.open({
        component: MarkdownScreen,
        markdownData: careInstructions,
        inModal: true,
        success: () => modalService.close()
      })
    },
    openSizeGuideModal: ({ dispatch, product, sizeGuide }) => () => {
      analyticsService.sendCustomPDPEvent({ name: 'sizeGuideClick' })
      modalService.open({
        component: MarkdownScreen,
        markdownData: sizeGuide,
        inModal: true,
        success: () => modalService.close()
      })
    },
    openGalleryModal: ({ product }) => () => {
      return modalService.open({
        component: HorizontalGallery,
        zoomModal: true,
        fullScreen: true,
        images: product.images || [],
        embeddedVideos: product.embeddedVideos || [],
        videoThumbnails: product.videoThumbnails || [],
        inModal: true
      })
    },
    stopEditingOrder: ({ dispatch }) => () => {
      dispatch(currentOrderActions.stopEditingOrder())
    },
    onClickShowWaitlist: ({ dispatch, product }) => () => {
      dispatch(push(`/product/${product.id}/waitlist`))
    },
    onClickAddToWaitlist: ({ dispatch, product }) => () => {
      modalService.open({
        component: ProductDetailCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          dispatch(productWaitlistActions.addToProductWaitlist({
            productId: product.id,
            customerId: customer.id
          }))
            .then(() => {
              modalService.close()
              modalService.continue({
                title: translations('Added to waitlist title'),
                text: translations('Added to waitlist text', {
                  customer: `${customer.firstName} ${customer.lastName}`
                })
              })
            })
            .catch(err => {
              modalService.close()
              modalService.continue({
                title: translations('Adding to waitlist failed title'),
                text: translations(err.code)
              })
            })
        },
        onBackClick: () => modalService.close()
      })
    },
    cleanUpForm: ({ dispatch }) => () => {
      dispatch(destroyForm(formNames.productDetails))
    }
  })
)(ProductDetailScreen)
