const style = theme => ({
  fullProductContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imageContainer: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    },
    marginBottom: 15,
    marginLeft: -1,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      marginLeft: 'auto'
    }
  },
  imageInnerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  singleProductImage: {
    height: '100%',
    maxWidth: '100%'
  },
  productName: {
    marginBottom: 10
  },
  listContainer: {
    margin: 0,
    display: 'flex',
    marginTop: 'auto'
  },
  descriptionContent: {
    margin: 0
  },
  contentWrapper: {
    paddingRight: 10,
    [theme.breakpoints.up('xs')]: {
      paddingRight: 0
    },
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  op3: {
    opacity: '0.3'
  }
})

export default style
