import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { selectors as customerDetailSelectors } from '../../../../store/modules/customerDetails'
import LatestNote from './LatestNote'
import analyticsService from '../../../../services/analyticsService'

const mapStateToProps = state => ({
  note: customerDetailSelectors.getCustomerNotes(state)[0]
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    viewNotesHandler: () => () => {
      analyticsService.sendCustomEvent({ type: 'fullNotesListClickThrough' })
    }
  })
)(LatestNote)
