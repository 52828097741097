import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import { translations } from '../../../../config'
import P from '../../../../components/P'
import Li from '../../../../components/Li'
import style from './style'

const List = ({ classes, listTitle, items }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <FormControl className={classes.field}>
        <P value={listTitle} />
        {items && items.map((item, index) => {
          return <Li value={translations(item)} key={index} />
        })}
      </FormControl>
    </Grid>
  </Grid>
)

List.propTypes = {
  listTitle: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
}

export default withStyles(style)(List)
