import React, { Component, Fragment } from 'react'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'

import { translations, clientReporting, customerSelfRegistrationEnabled } from '../../../config'
import { constants as appConstants } from '../../../store/modules/app'
import TabbedContent from '../../../components/TabbedContent'
import AlphabetList from '../../../components/AlphabetList'
import FullScreenProgress from '../../../components/FullScreenLoader'
import H2 from '../../../components/H2'
import MobileSelect from '../../../components/MobileSelect'
import RegistrationButton from '../../../components/RegistrationButton'
import Activity from '../../../components/ActivityList/Activity'
import ActivityList from '../../../components/ActivityList'
import ChartTile from '../../../components/Charts/ChartTile'
import CustomerReportingCharts from '../CustomerReporting/CustomerReportingCharts'
import CustomerSearch from '../CustomerSearch'
import FollowingListItem from '../FollowingListItem'
import { reportingStyle, noReportingStyle } from './style'
import analyticsService from '../../../services/analyticsService'
import { ScanToRegisterModal } from '../../../retailos/ScanToRegisterModal'
import modalService from '../../../services/modalService'

class ClientBookScreen extends Component {
  selectOptions = [
    {
      value: 0,
      text: translations('My Clients'),
      render: this.renderFollowing
    },
    {
      value: 1,
      text: translations('Recent Activity'),
      render: this.renderActivities
    },
    ...(
      clientReporting
        ? [{
          value: 2,
          text: translations('Reporting'),
          render: this.renderReporting
        }]
        : []
    )
  ]

  state = {
    view: this.selectOptions[0].value,
    qrModalOpen: false
  }

  onCloseQrModal = () => {
    this.setState({ qrModalOpen: false })
  }

  onRegisterCustomer = () => {
    const { registerCustomer } = this.props
    if (customerSelfRegistrationEnabled) {
      modalService.action({
        title: translations('Register Customer'),
        text: translations('Register Customer - select option text'),
        actions: [
          {
            text: translations('Register Customer - self register'),
            onClick: () => this.setState({ qrModalOpen: true }),
            primary: true,
            iconImage: 'scanIcon'
          },
          {
            text: translations('Register Customer - continue to registration'),
            onClick: registerCustomer,
            primary: false,
            iconImage: 'customerIconDark'
          }
        ]
      })
    } else {
      registerCustomer()
    }
  }

  onSelect = e => {
    const view = e.target.value
    this.setState({ view })
  }

  onClickClientItem (item) {
    const { goToCustomer } = this.props
    return () => {
      goToCustomer(item.id)
      analyticsService.sendCustomEvent({ type: 'clientBookCustomerClickThrough' })
    }
  }

  renderFollowing (opts) {
    const { following } = this.props
    const mobile = !!(opts && opts.mobile)
    if (following) {
      return (
        <AlphabetList
          items={this.props.following}
          onClickItem={this.onClickClientItem.bind(this)}
          ItemComponent={FollowingListItem}
          sortKey='firstName'
          narrow={clientReporting || mobile}
        />
      )
    } else {
      return 'Following: undefined'
    }
  }

  renderActivities () {
    const { activities } = this.props
    return <ActivityList activities={activities} render={({ activity, ActivityContainer }) => {
      const ActivityComponent = ActivityContainer(Activity)
      return <ActivityComponent activity={activity} />
    }} />
  }

  renderReporting () {
    const { classes, following } = this.props
    return (
      <div className={classes.reportsContainer}>
        <CustomerReportingCharts
          customers={following}
          Tile={ChartTile}
        />
      </div>
    )
  }

  renderMobile () {
    const { classes } = this.props
    return (
      <div className={classes.mobileContainer}>
        <div className={classes.mobileInner}>
          <MobileSelect
            options={this.selectOptions}
            onChange={this.onSelect}
            value={this.state.view}
          />
        </div>
        <div className={classes.mobileInner}>
          {
            this.selectOptions[this.state.view] &&
            this.selectOptions[this.state.view].render.bind(this)({ mobile: true })
          }
        </div>
      </div>
    )
  }

  renderNotMobile () {
    const { classes, tabValue, handleTabChange } = this.props
    const tabs = [
      {
        label: translations('My Clients'),
        content: this.renderFollowing(),
        value: appConstants.CLIENT_BOOK_CONTACT_DETAILS
      },
      {
        label: translations('Recent Activity'),
        content: this.renderActivities(),
        value: appConstants.CLIENT_BOOK_ACTIVITIES_LIST
      }
    ]

    return (
      <div className={classes.container}>
        <div className={classes.tabsAndReportsContainer}>
          <div className={classes.tabsContainer}>
            <TabbedContent
              tabs={tabs}
              tabValue={tabValue}
              handleTabChange={handleTabChange}
            />
          </div>
          {
            clientReporting
              ? this.renderReporting()
              : null
          }
        </div>
      </div>
    )
  }

  renderClientBook () {
    const { following, classes } = this.props

    return (
      following && following.length
        ? (
          <Fragment>
            <Hidden smUp>
              {this.renderMobile()}
            </Hidden>
            <Hidden xsDown>
              {this.renderNotMobile()}
            </Hidden>
          </Fragment>
        )
        : (
          <div className={classes.noFollowingTextContainer}>
            <H2 value={translations('Not Following Anyone 1')} />
            <H2 value=' ' />
            <H2 value={translations('Not Following Anyone 2')} />
          </div>
        )
    )
  }

  render () {
    const { onBackClick, onSelectCustomerInModal, onCustomerClick, classes } = this.props

    return (
      <CustomerSearch
        key='client-book-search'
        onCustomerClick={onSelectCustomerInModal || onCustomerClick}
        onBackClick={onBackClick}
      >
        <div className={classes.noOverflow}>
          {this.renderClientBook()}
        </div>
        {!onSelectCustomerInModal && (
          <RegistrationButton onClick={this.onRegisterCustomer}>
            {translations('Register Customer')}
          </RegistrationButton>
        )}
        <ScanToRegisterModal open={this.state.qrModalOpen} onClose={this.onCloseQrModal}/>
      </CustomerSearch>
    )
  }
}

const ClientBookScreenWithReporting = withStyles(reportingStyle)(ClientBookScreen)
const ClientBookScreenWithoutReporting = withStyles(noReportingStyle)(ClientBookScreen)

const ClientBookScreenWithStyles = (
  clientReporting
  ? ClientBookScreenWithReporting
  : ClientBookScreenWithoutReporting
)

export default FullScreenProgress(ClientBookScreenWithStyles)
