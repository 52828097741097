import React from 'react'
import { Modules } from '@redant/retailos-ui'

import { httpClientService } from '../../dependencies'
import { getUiConfig } from '../../uiConfigStore'
import './uiConfig'

export const MessageListScreen = ({ history }) => {

  const messageListFunctions = new Modules.Messaging.Implementation.MessageListFunctions(httpClientService, history)

  const implementation = Modules.Messaging.Implementation.provideUseMessageListScreen({
    messageListFunctions
  })

  return (
    <Modules.Messaging.Screens.MessageListScreen
      implementation={implementation}
      uiConfig={{ ...getUiConfig('Modules.Messaging.Screens.MessageListScreen') }}
    />
  )
}
