let loadedConfig = {
}

let configOverride = false

export async function loadClientConfig() {
  if (configOverride) {
    return
  }

  // TODO: implement a client agnostic endpoint to fetch client configs
  const theUrl = 'http://localhost:3000/v2/client-config'
  const fetchResponse = await fetch(theUrl, {
    headers: {
      'x-ra-href': window.location.href,
    }
  })

  if (!fetchResponse.ok) {
    console.warn('Failed fetching remote client config. Falling back to local.')
    return
  }

  const response = await fetchResponse.json()

  // maybe store in local storage for offline usage when network connection drops
  loadedConfig = response.result
}

export function setClientConfig(config) {
  loadedConfig = config
  configOverride = true
}

export function getClientConfig(configFile, fallbackValue) {
  const configData =  loadedConfig[configFile]
  if (typeof configData === 'object' && !Array.isArray(configData)) {
    return {
      ...fallbackValue, 
      ...configData
    }
  }
  return loadedConfig[configFile] || fallbackValue

}
