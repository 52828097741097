import { push } from 'connected-react-router'
import { constants as authConstants, actions as authActions, selectors as authSelectors } from '../auth'
import { apps } from '../../../config'

class RoutingMiddleware {
  loginSuccessRouteRedirectMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const { isCustomerMode, isUsingRecentUser = false } = action
    if (action.status === 'SUCCESS' && action.type === authConstants.LOGIN && !isCustomerMode) {
      if (action.result.stores.length === 1) {
        const store = action.result.stores[0]
        dispatch(authActions.selectStore({ storeId: store.id, regionId: store.regionId }))
      } else {
        if (!isUsingRecentUser) {
          dispatch(push(`/select-store`))
        }
      }
    }
  }
  getSSOAuthContextSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === authConstants.GET_SSO_AUTH_CONTEXT) {
      if (action.result.stores.length === 1) {
        const store = action.result.stores[0]
        dispatch(authActions.selectStore({ storeId: store.id, regionId: store.regionId }))
      } else {
        dispatch(push(`/select-store`))
      }
    }
  }
  selectStoreRouteRedirectMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.SELECT_STORE && !action.silent) {
      if (apps.DEPARTMENTS) {
        const departments = authSelectors.getUserDepartments(getState())
        if (departments.length === 1) {
          dispatch(authActions.selectDepartment({ departmentId: departments[0].id }))
        } else {
          dispatch(push('/select-department'))
        }
      } else {
        dispatch(push('/'))
      }
    }
  }
  selectDepartmentRouteRedirectMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.SELECT_DEPARTMENT && !action.silent) {
      dispatch(push('/'))
    }
  }

  // causing a bug on login
  // showLoginWithNoSelectedStore = ({ dispatch, getState }) => next => (action) => {
  //   next(action)
  //   if (action.type === LOCATION_CHANGE && action.payload.location.pathname === '/select-store') {
  //     if (!authSelectors.getSelectedStore(getState())) {
  //       dispatch(replace('/login'))
  //     }
  //   }
  // }
}

export default new RoutingMiddleware()
