import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import H3 from '../../../../components/H3'
import { Dropdown, Checkbox, Input } from '../../../../components/Fields'
import { translations } from '../../../../config'
import { theme } from '../../../../config/theme'
import * as validators from '../../../../validators'
import { useCurrentOrder } from '../../../../hooks/currentOrderHooks'
import { usePaymentCardOptions } from '../../../../hooks/currentOrderSalesforceHooks'
import { formatExpirationDate } from '../../../../helpers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-bottom: 0.5px solid ${theme.palette.border.main};
  padding: 25px 30px 25px;
`

const Inner = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

const Title = styled(H3)`
  margin-bottom: 16px;
  text-align: center;
`

const CustomFormControl = styled(FormControl)`
  flex: 1;
  width: 100%;
`

const FullPayment = ({
  formValues,
  isPayingFullAmount,
  handlePaymentFullAmountClick
}) => {
  const [requiresFuturePayment, setRequiresFuturePayment] = useState(false)
  const depositAmount = _.get(formValues, 'depositAmount')
  const order = useCurrentOrder()
  const total = _.get(order, 'total.value')
  const dropdownPaymentOptions = usePaymentCardOptions()

  useEffect(() => {
    const paymentIsRequiredInTheFuture = !isPayingFullAmount && depositAmount && (_.toNumber(depositAmount) < total)
    if (paymentIsRequiredInTheFuture !== requiresFuturePayment) {
      setRequiresFuturePayment(paymentIsRequiredInTheFuture)
    }
  }, [depositAmount])

  return <Container>
    <Title value={translations('Full or Part Payment')} />
    <Inner>
      <CustomFormControl>
        <Checkbox
          name='payInFull'
          label={translations('Pay Full Amount')}
          onChange={handlePaymentFullAmountClick}
        />
      </CustomFormControl>
      <CustomFormControl required>
        <Input
          name='depositAmount'
          label={translations('Deposit Amount')}
          type='number'
          validate={validators.required}
          readOnly={isPayingFullAmount}
        />
      </CustomFormControl>
      <CustomFormControl required={!isPayingFullAmount}>
        <Dropdown
          name='paymentMethod'
          label={translations('Payment Method')}
          options={dropdownPaymentOptions}
          validate={requiresFuturePayment ? validators.required : null}
        />
      </CustomFormControl>
      <Fragment>
        <CustomFormControl required={!isPayingFullAmount}>
          <Input
            name='cardLastFourDigits'
            label={translations('Last 4 Digits of Card Number')}
            validate={requiresFuturePayment ? validators.required : null}
            type='number'
            inputProps={{ maxlength: '4' }}
          />
        </CustomFormControl>
        <CustomFormControl required={!isPayingFullAmount}>
          <Input
            name='cardExpiryDate'
            label={translations('Card Expiry Date')}
            placeholder='MM/YY'
            validate={requiresFuturePayment ? validators.required : null}
            inputProps={{ maxlength: '5' }}
            format={formatExpirationDate}
          />
        </CustomFormControl>
      </Fragment>
      <CustomFormControl required={!isPayingFullAmount}>
        <Dropdown
          name='futurePaymentMethod'
          label={translations('Future Payment Method')}
          validate={requiresFuturePayment ? validators.required : null}
          options={dropdownPaymentOptions}
          disabled={!!isPayingFullAmount}
        />
      </CustomFormControl>
    </Inner>
  </Container>
}

export default FullPayment
