import * as constants from './constants'
import { constants as detailConstants } from '../inspirationDetails'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_INSPIRATIONS_FRESH:
      return reducers.searchInspirationsFresh(state, action)
    case constants.SEARCH_INSPIRATIONS_NEXT:
      return reducers.searchInspirationsNext(state, action)
    case constants.CLEAR_RESULTS:
      return reducers.clearResults(state, action)
    case constants.FETCH_INSPIRATION_TAGS:
      return reducers.fetchInspirationTags(state, action)
    case detailConstants.DELETE_INSPIRATION:
      return reducers.removeListItem(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
