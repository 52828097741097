import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { customerFunctions } from '../dependencies'
import { environment } from '../../config'

export const ScanToRegisterModal = ({ open, onClose }) => {
  const customerLinkPath = '/register'
  const registrationUrl = `${environment.CUSTOMER_FACING_URL || window.location.origin}${customerLinkPath}`
  const implementation = Modules.Customer.Implementation.provideUseCustomerRegistrationToken({
    customerFunctions,
    registrationUrl
  })

  return (
    <Modules.Customer.Components.ScanToRegisterModal
      implementation={implementation}
      open={open}
      onClose={onClose}
    />
  )
}
