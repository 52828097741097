import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import _ from 'lodash'

import style from './style'
import { formatDate } from '../../../../../helpers'
import { dateFormat, timeFormat } from '../../../../../config'
import formatActivityText from '../../formatActivityText'

const dateAndTimeFormat = `${dateFormat} @ ${timeFormat}`

const renderIconOrImage = ({ icon: Icon, imageUrl, classes }) => {
  if (imageUrl) {
    return <img src={imageUrl} className={classes.image} />
  } else if (Icon) {
    return <Icon className={classes.image} />
  } else {
    return null
  }
}

const CustomerSnapshotActivity = (props) => {
  const {
    text,
    icon,
    customer,
    date,
    onActivityClick,
    classes,
    imageUrl,
    type,
    activityType
  } = props
  const imageContainerClasses = cx({
    [classes.circleImageContainer]: icon,
    [classes.imageContainer]: imageUrl
  })
  const firstName = _.get(customer, 'firstName')
  return <div className={classes.container} onClick={onActivityClick}>
    <div className={imageContainerClasses}>
      {renderIconOrImage({ icon, imageUrl, classes, type })}
    </div>
    <div className={classes.bodyText}>{formatActivityText({ firstName, text, activityType })}</div>
    <div className={classes.dateTime}>{formatDate(date, dateAndTimeFormat)} </div>
  </div>
}

export default withStyles(style)(CustomerSnapshotActivity)
