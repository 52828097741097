import { selectors as networkSelectors } from '../modules/network'
import { translations } from '../../config'
import { wrapFetchWithSlowNotification } from './slowRequestNotifier'

export default ({ getState }) => {
  const originalFetch = window.fetch
  window.fetch = (...args) => {
    const isConnectedPreRequest = networkSelectors.isConnected(getState())
    if (isConnectedPreRequest) {
      return wrapFetchWithSlowNotification(originalFetch, args)
        .catch(error => {
          const isConnectedPostRequest = networkSelectors.isConnected(getState())
          if (!isConnectedPostRequest) {
            throw new Error(translations('network connection down'))
          } else {
            throw error
          }
        })
    } else {
      return Promise.reject(new Error(translations('network connection down')))
    }
  }
}
