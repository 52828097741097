const styles = theme => ({
  field: {
    minWidth: '330px',
    marginBottom: '20px'
  },
  columnStyle: {
    padding: '0 20px',
    alignSelf: 'flex-start'
  },
  rowStyle: {
    marginBottom: '20px'
  },
  formContainer: {
    width: '100%'
  },
  fieldWrapper: {
    margin: '10px'
  },
  contentContainer: {
    margin: '20px 7.5%',
    display: 'flex',
    flexDirection: 'column'
  },
  submitError: {
    textAlign: 'left'
  },
  requiredFollowButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  requiredText: {
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      order: 1,
      alignItems: 'flex-end'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginBottom: 0
    }
  }
})

export default styles
