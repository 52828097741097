const styles = theme => ({
  chartWrapper: {
    fontWeight: 'bold'
  },
  total: {
    fontWeight: 'bold',
    color: theme.palette.chartLegendsGrey.main,
    fontSize: 12,
    position: 'absolute',
    bottom: 12,
    right: 0,
    textTransform: 'uppercase'

  }
})

export default styles
