export const notificationObject = ({ formData = {}, users = {}, storeIds = {}, departmentIds = {}, scheduledDate }) => {
  // the contents of formData should be set as attachments on notification object.
  // afterwards they will be stored in `details` property

  let attachments = formData.content.length ? formData.content.map(item => ({
    'id': item.id,
    'type': item.type,
    'name': item.name || item.title || item.fileName || 'View',
    'url': item.url
  })) : []
  return {
      message: formData.text,
      targetUser: {
        id: users.id,
        name: users.fullName || users.firstName
      },
      storeIds,
      departmentIds,
      scheduledDate: scheduledDate.toDate(),
      isActionable: formData.type === 'task',
      attachments
  };
};