import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import SubPrivateRoute from './SubPrivateRoute'
import { selectors as authSelectors } from '../../store/modules/auth'
import { selectors as networkSelectors } from '../../store/modules/network'
import { selectors as appSelectors, actions as appActions } from '../../store/modules/app'

const mapStateToProps = state => ({
  isLoggedIn: !!authSelectors.getIsLoggedInBase(state),
  isConnected: networkSelectors.isConnected(state),
  isApiFail: appSelectors.getApiFailStatus(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleErrorOnClick: () => {
    dispatch(replace(ownProps.location.pathname))
    dispatch(appActions.onApiDismiss())
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(SubPrivateRoute)
