import textContentStyle from '../../../components/Form/TextContent/style'

const tableContainerHeight = 257

const styles = theme => ({
  grid: {
    width: '100%'
  },
  emptyMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  emptyMessageText: {
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
})

export default theme => ({
  ...styles(theme),
  ...textContentStyle
})
