import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import RemoveButton from '../RemoveButton'
import DescriptionIcon from '@material-ui/icons/Description'
import { SvgIcon } from '@material-ui/core'

const FileComponent = ({ id, classes, width, editing, onDelete, fileName }) => {
  return (
    <div className={cx(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })}>
      {editing && (
        <RemoveButton onClick={event => onDelete({ id, event })} />
      )}
      <SvgIcon className={classes.icon}>
        <DescriptionIcon />
      </SvgIcon>
      <div className={classes.fileNameContainer}>{fileName}</div>
    </div>
  )
}

FileComponent.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    containerMargin: PropTypes.string,
    image: PropTypes.string,
    imageContainer: PropTypes.string
  }),
  fileName: PropTypes.string,
  width: PropTypes.string,
  editing: PropTypes.bool,
  onDelete: PropTypes.func
}

export default withStyles(style)(FileComponent)
