import { connect } from 'react-redux'
import WebviewScreen from './WebviewScreen'
import selectors from './selectors'
import { env, environments } from '../../../config'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const editable = env !== environments.PRODUCTION
  const src = selectors.getWidgetSource(state, ownProps)
  return {
    editable,
    ...ownProps,
    src
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WebviewScreen)
