import { connect } from 'react-redux'
import { change } from 'redux-form'
import LoginForm from './LoginForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as appSelectors } from '../../../store/modules/app'
import { selectors as networkSelectors } from '../../../store/modules/network'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { success } = ownProps
  const isUsingRecentUser = authSelectors.getIsUsingRecentUser(state)
  const isCustomerMode = appSelectors.getCustomerModeStatus(state)
  const currentUsername = authSelectors.getActiveUsername(state)
  const networkConnectivity = networkSelectors.isConnected(state)

  const changeField = (field, value) => {
    return dispatch(change('login', field, value))
  }

  const onSubmit = ({ email, username, password }) => {
    return dispatch(authActions.login({
      email,
      username,
      password,
      isCustomerMode,
      isUsingRecentUser
    }))
      .then(() => {
        // only has a value in the modal
        if (success) {
          success()
        }
      })
  }

  return {
    ...ownProps,
    onSubmit,
    isCustomerMode,
    currentUsername,
    changeField,
    networkConnectivity
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LoginForm)
