import { SEARCH_PRODUCTSLISTS } from './constants'
import { searchProductLists } from './reducers'

const defaultState = {
  results: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCTSLISTS:
      return searchProductLists(state, action)
    default:
      return state
  }
}
