import { promiseReducer } from '../../util'
import { compactObject } from '../../../helpers'

export const searchProductLists = promiseReducer(
  // SUCCESS
  (state, action) => {
    const { result = {} } = action
    const { results, total } = result
    return {
      ...state,
      total,
      results
    }
  },
  // PENDING
  (state, action) => {
    const { payload = {} } = action
    return {
      ...state,
      ...compactObject(payload)
    }
  }
)

export const searchProductListsNext = promiseReducer(
  (state, action) => {
    const { page, results: currentResults } = state
    const { result = {} } = action
    const { results, total } = result
    return {
      ...state,
      page: ((page || 0) + 1),
      total,
      results: [...currentResults, ...results]
    }
  }
)
