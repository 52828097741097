import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Button from '../../../components/Button'
import H2 from '../../../components/H2'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import style from './style'
import { customerSelfRegistrationEnabled, translations } from '../../../config'
import ActionBar from '../../../components/ActionBar'
import modalService from '../../../services/modalService'
import { CustomerRegistrationCustomerModeScreen } from '../../../retailos/Customer'
import { ScanToRegisterModal } from '../../../retailos/ScanToRegisterModal'

const CustomerRegistrationScreen = (props) => {
  const {
    customerModeUpdate,
    customerModeOn,
    customerModeOff,
    showPasswordModal,
    submitAction,
    afterSubmit,
    disallowCustomerMode,
    onBackClick,
    classes,
    dispatch,
    registrationType
  } = props
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [mobileActionsAnchorEl, setMobileActionsAnchorEl] = useState(null)

  const handleCloseQrModal = () => {
    setQrModalOpen(false)
  }
  const handleOpenQrModal = () => {
    handleCloseMenu()
    setQrModalOpen(true)
  }

  const handleOpenMenu = (event) => {
    setMobileActionsAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMobileActionsAnchorEl(null)
  }

  const handleCustomerMode = () => {
    handleCloseMenu()
    customerModeOn()
  }

  if (customerModeUpdate) {
    modalService.open({
      modalIndex: 4,
      component: () => (
        <CustomerRegistrationCustomerModeScreen
          dispatch={dispatch}
          showPasswordModal={showPasswordModal}
          registrationType={registrationType}
        />
      ),
      fullScreen: true,
      onBackdropClick: () => null,
      exit: () => modalService.close({ modalIndex: 4 }),
      dialogProps: { disableEscapeKeyDown: true, disableBackdropClick: true },
      noRouting: true
    })
  }
  const shortVariant = registrationType === 'basket'

  return (
    <div>
      <SubHeader
        leftContent={!customerModeUpdate && (
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <H2 value={_.toUpper(translations('Add a new customer'))} />
        )}
      />
      {customerModeUpdate &&
      <ActionBar
        title={translations('Customer Mode')}
        buttonTitle={translations('Exit Customer Mode')}
        onClick={showPasswordModal}
      />}
      <div className={classes.contentContainer}>
        <div className={classes.subHeaderContainer}>
          <p className={classes.requiredFieldsText}>{translations('required fields')}</p>
          {/** Mobile menu */}
          <IconButton
            aria-label={translations('Actions')}
            aria-controls="customer-registration-menu"
            aria-haspopup="true"
            className={classes.mobileMenu}
            onClick={handleOpenMenu}>
            <MoreVert />
          </IconButton>
          <Menu
            id="customer-registration-menu"
            className={classes.mobileMenu}
            anchorEl={mobileActionsAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top'
            }}
            keepMounted
            open={Boolean(mobileActionsAnchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleOpenQrModal}>{translations('Register Customer - self register')}</MenuItem>
            <MenuItem onClick={handleCustomerMode}>{translations('Customer Mode')}</MenuItem>
          </Menu>
          { /* Desktop view */ }
          <div className={classes.customerModeButtonContainer}>
            { customerSelfRegistrationEnabled &&
              <Button
                color='primary'
                onClick={handleOpenQrModal}
              >
                {translations('Register Customer - self register')}
              </Button>
            }
            {!customerModeUpdate && !disallowCustomerMode &&
              <Button onClick={customerModeOn}
                className={classes.customerModeButton}
                color='primary'>
                {translations('Customer Mode')}
              </Button>
            }
          </div>
        </div>

        <CustomerRegistrationForm submitAction={submitAction} afterSubmit={afterSubmit} shortVariant={shortVariant}/>
        <ScanToRegisterModal open={qrModalOpen} onClose={handleCloseQrModal}/>
      </div>
    </div>
  )
}

export default withStyles(style)(CustomerRegistrationScreen)
