import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { FormBody } from '../../../components/Form'
import requiredSchema from '../../../components/Form/commonSchemas/address.required'
import notRequiredSchema from '../../../components/Form/commonSchemas/address.notRequired'

import style from './style'

const AddressFull = ({ classes, fullWidth, required, visible }) => {
  const containerClass = classNames({
    [classes.hidden]: !visible
  })

  const schema = required ? requiredSchema : notRequiredSchema

  return (
    <FormBody
      className={containerClass}
      schema={schema}
      editing
      fullWidthFields={fullWidth}
    />
  )
}

export default withStyles(style)(AddressFull)
