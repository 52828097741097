const styles = {
  chartContainer: {
    position: 'relative',
    width: 325
  },
  chartContainerOuter: {
    position: 'relative'
  },
  chartContainerInner: {
    position: 'absolute',
    width: '320px',
    height: '260px'
  }
}

export default theme => styles
