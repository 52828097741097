import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import UsersSearchInput from '../UsersSearchInput'
import UsersSearchTable from '../UsersSearchTable'
import UsersSearchFilters from '../UsersSearchFilters'
import UsersLoadingBar from '../UsersLoadingBar'

import RegistrationButton from '../../../../components/RegistrationButton'
import { translations } from '../../../../config'

import styles from './style'

const UsersSearchScreen = ({ isLoading, onUserClick, onBackClick, classes, hideBottomBar, disabledUserId, inModal }) => {
  const scrollContainerClass = (inModal) ? classes.scrollContainerInModal : classes.scrollContainer
  return (
    <div className={classes.container}>
      <UsersLoadingBar />
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <UsersSearchInput />
        )}
      />
      <div className={scrollContainerClass}>
        <UsersSearchFilters />
        <UsersSearchTable onUserClick={onUserClick} disabledUserId={disabledUserId} />
      </div>
      {!hideBottomBar && (
        <RegistrationButton to='/users/new'>
          {translations('Register User')}
        </RegistrationButton>
      )}
    </div>
  )
}

UsersSearchScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired
}

export default withStyles(styles)(UsersSearchScreen)
