import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ResultRow from './ResultRow'
import modalService from '../../../../services/modalService'
import { actions } from '../../../../store/modules/pollResults'
import ViewCommentsModal from '../../ViewCommentsModal'
import { translations } from '../../../../config'

export default compose(
  connect(),
  withHandlers({
    onDeleteClick: ({ dispatch, result }) => () => {
      const { id } = result
      modalService.action({
        title: translations('Confirm Deletion'),
        text: translations('Delete Poll Question Copy'),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => dispatch(actions.deletePollQuestionInstanceById({ id })),
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    },
    onCommentsClick: ({ dispatch, result }) => () => {
      modalService.open({
        component: ViewCommentsModal,
        fullScreen: true,
        onCloseClick: () => modalService.close(),
        result
      })
    }
  })
)(ResultRow)
