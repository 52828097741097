import { connect } from 'react-redux'
import { submit, isValid, getFormValues } from 'redux-form'
import uuidv4 from 'uuid/v4'
import _ from 'lodash'

import { selectors as customerDetailSelectors, actions as customerDetailActions } from '../../../../store/modules/customerDetails'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import CreateNote from './CreateNote'

const mapStateToProps = state => ({
  state
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const customer = customerDetailSelectors.getCustomer(state)
  const customerNotes = customerDetailSelectors.getCustomerNotes(state) || []
  const currentUserStoreName = authSelectors.getSelectedStore(state).name
  const currentUserRoleName = authSelectors.getLoggedInUserRole(state).name
  const currentUserId = authSelectors.getActiveUserId(state)
  const currentUserFullName = authSelectors.getActiveUserFullName(state)
  const updateCustomerNotes = (params) => {
    dispatch(customerDetailActions.updateCustomerNotes(params))
  }
  const isFormValid = isValid('createNoteForm')(state)
  const formValues = getFormValues('createNoteForm')(state)
  const noteValue = _.get(formValues, 'note', '').trim()
  const onSubmit = ({ note }) => {
    const noteObject = {
      id: uuidv4(),
      type: 'note',
      note,
      user: {
        id: currentUserId,
        name: currentUserFullName,
        roleName: currentUserRoleName,
        storeName: currentUserStoreName
      },
      timestamp: new Date().toISOString()
    }
    // add new note to end of array
    // when viewing the notes will be from oldest to newest
    const notes = [noteObject].concat(customerNotes)
    return updateCustomerNotes({
      id: customer.id,
      notes
    })
  }
  const submitForm = formId => dispatch(submit(formId))
  return {
    ...ownProps,
    customer,
    onSubmit: ownProps.onSubmit || onSubmit,
    submitForm,
    isFormValid,
    noteValue
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateNote)
