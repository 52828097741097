import React, { Component } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H1 from '../../../components/H1'
import H2 from '../../../components/H2'
import { translations } from '../../../config'
import { getImage } from '../../../components/Images'
import CircularProgress from '@material-ui/core/CircularProgress'
import HorizontalGallery from '../../../components/HorizontalGallery'
import ImageBox from '../../../components/ImageBox'


import style from './style'
import P from '../../../components/P'
import Tags from '../../../components/Tags'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import { getFullName } from '../../../helpers'

import { Helmet } from 'react-helmet'
import { matchPath } from 'react-router'
import { history } from '../../../store'

const playIcon = getImage('playIcon')
const chevronIcon = getImage('chevronIcon')

class BoudoirScreen extends Component {
  componentDidMount() {
    if (window.cordova) {
      const { handleResize } = this.props
      window.addEventListener('resize', handleResize)
      handleResize()
    }
  }

  componentWillUnmount() {
    if (window.cordova) {
      const { handleResize } = this.props
      window.removeEventListener('resize', handleResize)
    }
  }
  renderHeader() {
    const {
      classes,
      closeModal,
      onGallerySelectClick,
      selectedGalleryHasDayNight,
      onNightSelectClick,
      onDaySelectClick,
      dayImageIndex,
      nightImageIndex,
      galleryIndex,
      hasMultipleGalleries,
      showPasswordModal
    } = this.props

    const nightActive = nightImageIndex === galleryIndex
    const dayActive = dayImageIndex === galleryIndex

    return (
      <div className={classes.headerContainer}>
        <div className={classes.headerContainerLeft}>
          <Button className={classes.headerButton} buttonType={'primary'} onClick={showPasswordModal}>{translations('Colleague Mode')}</Button>
        </div>
        {selectedGalleryHasDayNight && <div className={classes.headerContainerCenter}>
          <Button className={[classes.headerButton, classes.headerButtonSwitch, dayActive && classes.headerButtonActive]} buttonType={'primary'} onClick={onDaySelectClick}>{'Day'}</Button>
          <Button className={[classes.headerButton, classes.headerButtonSwitch, nightActive && classes.headerButtonActive]} buttonType={'primary'} onClick={onNightSelectClick}>{'Night'}</Button>
        </div>}
        <div className={classes.headerContainerRight}>
          {hasMultipleGalleries && <Button className={classes.headerButton} buttonType={'primary'} onClick={onGallerySelectClick}>{translations('Skin Tone')}</Button>}
        </div>
      </div>
    )
  }

  renderNavigation() {
    const { classes, onClickNavigateToPrevious, onClickNavigateToNext, isLoading, mainImageSrc } = this.props
    return (
      <div className={classes.lookNavigation}>
        <Button wrapper disabled={isLoading} onClick={onClickNavigateToPrevious} className={classes.lookNavigationButton}>
          <span className={classes.lookNavigationButtonLabel}>{translations('Previous')}</span>
          <div className={classes.lookNavigationButtonInnerLeft} />
        </Button>
        {mainImageSrc && isLoading && <div className={classes.lookNavigationLoader}>
          <CircularProgress size={50} />
        </div>}
        {!isLoading && !mainImageSrc && <p className={classes.emptyImageMessage}>{translations('No image for this skin tone')}</p>}
        <Button wrapper disabled={isLoading} onClick={onClickNavigateToNext} className={classes.lookNavigationButton}>
          <span className={classes.lookNavigationButtonLabel}>{translations('Next')}</span>
          <div className={classes.lookNavigationButtonInnerRight} />
        </Button>
      </div>
    )
  }

  renderImage() {
    const { classes, galleryIndex, mainImageSrc } = this.props

    return (
      <div className={classes.imageContainer} style={{ backgroundImage: `url(${mainImageSrc})` }}>
        {this.renderNavigation()}
      </div>
    )
  }

  renderTitle() {
    const { classes, inspiration } = this.props
    const { title } = inspiration
    return (
      <div className={classes.containerTitlewrap}>
        <H1 value={title} className={classes.containerTitle} />
      </div>
    )
  }

  renderAddToButton() {
      const { classes, onAddToConsultationClick } = this.props
      return (
        <div className={classes.addToButtonContainer}>
          <Button big buttonType={'primary'} onClick={onAddToConsultationClick}>{translations('Choose This Look')}</Button>
        </div>
      )
    }

  renderProducts() {
    const { classes, inspiration, closeModal, videoGallery, onOpenVideoClick, onAddToConsultationClick } = this.props
    const products = _.get(inspiration, 'details.products', [])
    const renderImage = ({ image, title, video }) => {
      return (
        <div className={classes.productContainer}>
          <div className={classes.productImageWrap}>
            <div className={classes.productImage} style={{ backgroundImage: `url(${image})` }}>
              {video && <div className={classes.playIconWrap}><img className={classes.playIcon} src={playIcon} alt={'Play'} /></div>}
            </div>
          </div>
          <H2 className={classes.productTitle} value={title} />
        </div>
      )
    }

    const renderProductList = () => products.map(product => {
      return renderImage({ image: product.image, title: product.title })
    })

    const renderAddToButton = () => {
      return (
        <div>
          <Button className={classes.productAddToButton} buttonType={'primary'} onClick={onAddToConsultationClick}>{translations('Add Look To Consultation')}</Button>
        </div>
      )
    }

    const renderVideoGallery = () => videoGallery.map(video => {
      return (
        <div className={classes.productContainer}>
          <Button wrapper onClick={() => onOpenVideoClick(video.src)}>
            {renderImage({ image: video.thumb, title: video.title, video: true })}
          </Button>
        </div>
      )
    })

    return (
      <div className={classes.productsContainer}>
        {[
          ...[renderProductList()]
          // ...[renderVideoGallery()]
        ]}
      </div>
    )
  }

  renderOrientationHelper() {
    const { classes, showOrientationHelper } = this.props

    return showOrientationHelper && (
      <div className={classes.orientationHelper} />
    )
  }

  render() {
    const { classes, inspirationFailedToLoad } = this.props
    const path = history.location.pathname
    const isActiveConsultation = matchPath(path,{path:'/consultations/virtual/:id'}) || matchPath(path, { path: '/consultations/store/:id' })
    return (
      <div className={classes.container}>
        <Helmet><title>{'Boudoir'}</title></Helmet>
        {this.renderOrientationHelper()}
        {this.renderHeader()}
        {this.renderImage()}
        <div className={classes.contentContainer}>
          {this.renderTitle()}
          <div className={classes.productCarousel}>
            {this.renderProducts()}
            {
                  isActiveConsultation !== null?
                  this.renderAddToButton():
                  null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(BoudoirScreen)
