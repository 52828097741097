import { createSelector } from 'reselect'
import _ from 'lodash'
import { FETCH_POLL_QUESTIONS, SAVE_ANSWERS } from './constants'
import { PENDING } from '../../middleware/redux-promise'

export const getStatus = state => state.pollQuestions.status
export const getAction = state => state.pollQuestions.action
export const getSavingQuestionId = state => state.pollQuestions.savingQuestionId
export const getIsInitial = state => !state.pollQuestions.status
export const getIsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_POLL_QUESTIONS)
))
export const getIsSavingAnswers = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === SAVE_ANSWERS)
))

export const getIsSavingAnswersForQuestion = _.memoize((questionId) =>
  createSelector([
    getIsSavingAnswers,
    getSavingQuestionId
  ], (isSavingAnswers, savingQuestionId) => {
    if (isSavingAnswers) {
      return savingQuestionId === questionId
    } else {
      return false
    }
  })
)

export const getPollQuestionAnswers = _.memoize((questionId) =>
  createSelector([
    getPollQuestions
  ], (pollQuestions) => {
    return _.chain(pollQuestions)
      .find(pollQuestion => pollQuestion.id === questionId)
      .get('pollAnswers')
      .value()
  })
)

export const getPollQuestions = state => _.get(state, 'pollQuestions.pollQuestions')
export const getGroupedPollQuestions = createSelector([
  getPollQuestions
], (pollQuestions) => {
  return _.chain(pollQuestions)
    .groupBy('type')
    .value()
})
export const getDailyQuestions = createSelector([
  getGroupedPollQuestions
], (groupedPollQuestions) => {
  return _.get(groupedPollQuestions, 'daily', [])
})
export const getWeeklyQuestions = createSelector([
  getGroupedPollQuestions
], (groupedPollQuestions) => {
  return _.get(groupedPollQuestions, 'weekly', [])
})
export const getMonthlyQuestions = createSelector([
  getGroupedPollQuestions
], (groupedPollQuestions) => {
  return _.get(groupedPollQuestions, 'monthly', [])
})
