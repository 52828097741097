import { createSelector } from 'reselect'
import _ from 'lodash'
import * as constants from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { getLanguage } from '../../../config/languages'

export const getCurrentRecipients = state => _.get(state.createMessage, 'recipients', [])
export const getCurrentRecipientLanguage = createSelector([
  getCurrentRecipients
], recipients => {
  // NOTE: There seems to be a different behaviour on the recipient details.
  // Searching for a customer and 'creating message' compaired to clicking message
  // from the customer profile (this time the details object is spread).
  // Leaving a note to look into it.
  return recipients.length
    ? _.get(recipients, '0.preferredLanguage')
      ? _.get(recipients, '0.preferredLanguage')
      : _.get(recipients, '0.details.preferredLanguage')
    : null
})
export const getMessageDetails = state => state.createMessage.message || {}
export const getMessageTemplates = state => state.createMessage.templates.items || []
export const getMessageTemplateDropdownOptions = createSelector([
  (state) => _.get(state, 'createMessage.templates.items', []),
  (state, { groupByLanguage }) => groupByLanguage,
  getCurrentRecipientLanguage
], (messageTemplates, groupByLanguage, currentRecipientLanguage) => {
  const options = _.map(messageTemplates, messageTemplate => ({
    label: messageTemplate.name,
    value: messageTemplate.id,
    key: getLanguage(messageTemplate.language) || messageTemplate.language
  }))

  // if (groupByLanguage && currentRecipientLanguage) {
  //   options.unshift(_.find(options, { key: currentRecipientLanguage }))
  // }

  // console.log({ options })

  return groupByLanguage
    ? _.groupBy(options, 'key')
    : options
})

export const getPreviousPath = state => state.createMessage.previousPath
export const getMessageTemplatesAreLoading = state => {
  return state.createMessage.status === 'PENDING'
}
export const getIsMessageSending = state => {
  const messageDetailsAction = _.get(state, 'createMessage.action')
  const messageDetailsStatus = _.get(state, 'createMessage.status')
  return messageDetailsAction === constants.SEND_MESSAGE && messageDetailsStatus === PENDING
}

export const getSingleRecipientMarketingChannels = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.customerMarketingChannels')
      ? _.get(recipients, '0.customerMarketingChannels', [])
      : _.get(recipients, '0.details.customerMarketingChannels', []);
  }
  return []
})

export const getSingleRecipientMarketingPreferences = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.customerMarketingPreferences')
      ? _.get(recipients, '0.customerMarketingPreferences', [])
      : _.get(recipients, '0.details.customerMarketingPreferences', []);
  }
  return []
})

export const getMessageCommunicationType = createSelector([
  getMessageDetails
], details => {
  return _.get(details, 'communicationType')
})

export const getMessageContent = createSelector([
  getMessageDetails
], message => {
  return _.get(message, 'content', [])
})
