import React, { PureComponent } from 'react'
import _ from 'lodash'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import { getImage } from '../../../../../components/Images'
import P from '../../../../../components/P'
import style from './style'

class FacesAnswerPicker extends PureComponent {
  getButtonClassForAnswer = (answer) => {
    const { classes, question } = this.props
    const questionAnswer = _.get(question, 'pollAnswers[0].answer')
    if (questionAnswer != null) {
      if (answer === questionAnswer) {
        return classes.scaleEmphasised
      } else {
        return classes.scaleFaded
      }
    } else {
      return classes.scale
    }
  }
  getImageClassForAnswer = (answer) => {
    const { classes, question } = this.props
    const questionAnswer = _.get(question, 'pollAnswers[0].answer')
    if (questionAnswer != null) {
      if (answer === questionAnswer) {
        return classes.scaleImageEmphasised
      } else {
        return classes.scaleImageFaded
      }
    } else {
      return classes.scaleImage
    }
  }
  handleSelectAnswer = _.memoize((answer) => () => {
    const { selectAnswer } = this.props
    selectAnswer({ answer, single: true })
  })
  render () {
    const { classes, disabled, dailyQuestion } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <ButtonBase disabled={disabled} focusRipple={!disabled} className={this.getButtonClassForAnswer(1)} onClick={this.handleSelectAnswer(1)}>
            <img src={getImage('scale1')} className={this.getImageClassForAnswer(1)} />
          </ButtonBase>
          <ButtonBase disabled={disabled} focusRipple={!disabled} className={this.getButtonClassForAnswer(2)} onClick={this.handleSelectAnswer(2)}>
            <img src={getImage('scale2')} className={this.getImageClassForAnswer(2)} />
          </ButtonBase>
          <ButtonBase disabled={disabled} focusRipple={!disabled} className={this.getButtonClassForAnswer(3)} onClick={this.handleSelectAnswer(3)}>
            <img src={getImage('scale3')} className={this.getImageClassForAnswer(3)} />
          </ButtonBase>
          <ButtonBase disabled={disabled} focusRipple={!disabled} className={this.getButtonClassForAnswer(4)} onClick={this.handleSelectAnswer(4)}>
            <img src={getImage('scale4')} className={this.getImageClassForAnswer(4)} />
          </ButtonBase>
          <ButtonBase disabled={disabled} focusRipple={!disabled} className={this.getButtonClassForAnswer(5)} onClick={this.handleSelectAnswer(5)}>
            <img src={getImage('scale5')} className={this.getImageClassForAnswer(5)} />
          </ButtonBase>
        </div>
        <div className={classes.legendContainer}>
          <div>
            <P className={dailyQuestion ? classes.legendTextDaily : classes.legendText} value={'STRONGLY DISAGREE'} />
          </div>
          <div>
            <P className={dailyQuestion ? classes.legendTextDaily : classes.legendText} value={'STRONGLY AGREE'} />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(FacesAnswerPicker)
