import { connect } from 'react-redux'
import { compose } from 'recompose'
import SearchBar from './SearchBar'
import { actions as ordersActions, selectors as ordersSelectors } from '../../../../store/modules/orders'

const mapStateToProps = state => {
  const query = ordersSelectors.getQuery(state)
  const isLoading = ordersSelectors.getIsLoading(state)

  return {
    query,
    isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  searchOrders: ({ query }) => {
    dispatch(ordersActions.searchOrders({ query }))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SearchBar)
