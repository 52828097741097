import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import { reduxForm, change } from 'redux-form'
import { compose, withHandlers, withProps, withPropsOnChange, withState } from 'recompose'
import moment from 'moment'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import NotificationEditorForm from './NotificationEditorForm'
import formValueProvider from '../../../../containers/FormValueProvider'
import { actions as notificationActions } from '../../../../store/modules/notifications'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as notificationSelectors } from '../../../../store/modules/notifications'
import modalService from '../../../../services/modalService'
import { notificationObject } from './helpers'
import { translations, dateFormat, timeFormat } from '../../../../config'

const showSuccessModal = ({ title, dispatch }) => {
  modalService.continue({
    title,
    success: () => {
      modalService.close()
      dispatch(goBack())
    }
  })
}

const showFailModal = () => {
  modalService.continue({
    title: translations('Sorry, there was an error sending the notification'),
    success: () => {
      modalService.close()
    }
  })
}

const formId = 'create-notification'
const mapStateToProps = state => {
  return {
    me: authSelectors.getCurrentUser(state),
    group: notificationSelectors.getBroadCastGroup(state),
  }
}

export default compose(
  connect(mapStateToProps),
  withState('isLoading', 'setIsformLoading', false),
  FullScreenLoader,
  reduxForm({ form: formId, enableReinitialize: true }),
  withState('selectedUser', 'onUserSelect', (props) => {
    // Get the initial state based on query string
    const preloadMe = window.location.hash.includes('me')
    return preloadMe ? props.me : undefined
  }),
  withProps({
    initialValues: { type: 'standard', time: '00:00' },
    sendButtonText: 'cta-sendNotification',
    formId
  }),
  formValueProvider(formId),
  withPropsOnChange(['formValues', 'invalid'], ({ formValues = {}, invalid }) => {
    return {
      submitDisabled: (
        (!formValues.sendNow && (!formValues.date || !formValues.time)) || !formValues.text || !formValues.type || invalid
      )
    }
  }),
  withHandlers({
    onSubmit: ({ dispatch, selectedUser, setIsformLoading }) => (formData) => {
      setIsformLoading(true)
      const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`
      const dateTime = formData.date + ', ' + formData.time
      const scheduledDate = formData.sendNow ? moment(new Date()) : moment(dateTime, dateAndTimeFormat)
      const storeIds = selectedUser.storeIds && selectedUser.storeIds
      const departmentIds = selectedUser.departmentIds && selectedUser.departmentIds

      // Check if scheduledDate has become in the past since being set
      // This will happen if you fill it in, then wait for a minute
      if (moment(scheduledDate, dateAndTimeFormat).isBefore(new Date()) && !formData.sendNow) {
        // Force re-validate by updating the form
        dispatch(change(formId, '_validateHack', Math.random()))
        return
      }

      if (selectedUser.groupName) {
        switch (selectedUser.groupName) {
          case 'All':
            dispatch(notificationActions.sendUserToAllNotification(
              notificationObject({ formData, scheduledDate })
            ))
              .then(() => showSuccessModal({ title: translations('Notification broadcast sent'), dispatch }))
              .catch(() => showFailModal())
              .finally(() => setIsformLoading(false))
            break
          default:
            dispatch(notificationActions.sendUserToManyNotification(
              notificationObject({ formData, storeIds, departmentIds, scheduledDate })
            ))
              .then(() => showSuccessModal({ title: translations('Notification broadcast sent'), dispatch }))
              .catch(() => showFailModal())
              .finally(() => setIsformLoading(false))
        }
      } else {
        dispatch(notificationActions.sendUserToUserNotification(
          notificationObject({ formData, users: selectedUser, scheduledDate })
        ))
          .then(() => showSuccessModal({ title: translations('User notification sent'), dispatch }))
          .catch(() => showFailModal())
          .finally(() => setIsformLoading(false))
      }
    }
  })
)(NotificationEditorForm)
