import { compose, withProps } from 'recompose'
import { MessengerEditorText } from '../../../../../components/Messenger'
import { maxLength } from '../../../../../validators'

const textMaxLength = maxLength(280, 'text')

export default compose(
  withProps((props) => ({
    hasSingleRecipient: props.hasRecipient,
    hasRecipients: props.hasRecipient,
    validate: [textMaxLength]
  }))
)(MessengerEditorText)
