import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './style'

/** Display a group of buttons */
export const ButtonsContainer = props => {
  const { children, classes, align, className, innerClassName, isModal, foldOnMobile, buttonDirection } = props
  const containerClass = classNames(
    className,
    classes.container
  )
  const innerClass = classNames(
    classes.inner,
    innerClassName,
    {
      [classes.alignLeft]: align === 'left',
      [classes.alignCenter]: align === 'center',
      [classes.alignRight]: align === 'right',
      [classes.modalColumn]: isModal,
      [classes.foldMobile]: foldOnMobile,
      [classes.buttonDirectionColumn]: buttonDirection === 'column'
    }
  )
  // container inner layout is needed to make width 100% and
  // negative margins work nicely
  return <div className={containerClass}>
    <div className={innerClass}>
      {children}
    </div>
  </div>
}

ButtonsContainer.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  /** shows buttons in a column */
  isModal: PropTypes.bool,
  /** shows buttons in a column for small screen sizes */
  foldOnMobile: PropTypes.bool,
  buttonDirection: PropTypes.oneOf(['column'])
}

export default withStyles(style)(ButtonsContainer)
