import React from 'react'
import Button from '../Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const SubmittingButton = ({ isSubmitting, children, disabled, ...props }) => {
  const buttonContent = isSubmitting
    ? <CircularProgress size={23} thickness={2} />
    : children

  return (
    <Button
      {...props}
      disabled={isSubmitting || disabled}
    >
      {buttonContent}
    </Button>
  )
}

export default SubmittingButton
