import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import ProductsRouter from '../../../../../containers/Products/ProductsRouter'
import ProductCarousel from '../../../../../components/Fields/ProductCarousel'
import TabbedContent from '../../../../../components/TabbedContent'
import OrderCarousel from '../../../../../components/OrderCarousel'
import style from './style'
import { translations, getAppConfig } from '../../../../../config'
import { SNAPSHOT_PURCHASES, SNAPSHOT_RECOMMENDATIONS, SNAPSHOT_WISHLIST } from '../../../../../store/modules/app/constants'
import MobileSelect from '../../../../../components/MobileSelect'
import InspirationsSearchScreen from '../../../../Inspirations/InspirationsSearchScreen'

class CustomerSnapshotActionCarousel extends Component {
  renderProductCarousel = (productType) => {
    const { classes } = this.props
    const products = _.get(this.props, productType, [])
    return (
      <div className={classes.individualContainer}>
        <ProductCarousel
          value={products}
          editing={false}
          hidePrice={true}
          productsModalComponent={ProductsRouter}
          inspirationsModalComponent={InspirationsSearchScreen}
        />
      </div>
    )
  }
  renderPurchases = () => {
    const { classes } = this.props
    return (
      <div className={classes.individualContainer}>
        <OrderCarousel
          hideTitle
          inCarousel
        />
      </div>
    )
  }
  renderContent = (type, variant) => {
    if (type === 'product') {
      return this.renderProductCarousel(variant)
    } else if (type === 'purchase') {
      return this.renderPurchases()
    }
  }
  renderDesktop = () => {
    const { tabValue, handleTabChange, classes, customerFirstName } = this.props

    const tabsFromConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'customerSnapshotTabs').map(item => {
      if (!item.enabled) return undefined

      switch (item.type) {
        case 'SNAPSHOT_PURCHASES':
          return {
            label: translations('Latest Purchases'),
            content: this.renderContent('purchase'),
            value: SNAPSHOT_PURCHASES
          }
        case 'SNAPSHOT_RECOMMENDATIONS':
          return {
            label: translations(item.label, { customerFirstName }) || translations('Recommendations'),
            content: this.renderContent('product', 'recommendedProducts'),
            value: SNAPSHOT_RECOMMENDATIONS
          }
        case 'SNAPSHOT_WISHLIST':
          return {
            label: translations('Wishlist'),
            content: this.renderContent('product', 'wishlist'),
            value: SNAPSHOT_WISHLIST
          }
      }
    })

    const tabs = _.compact(tabsFromConfig)
    return (
      <TabbedContent
        tabs={tabs}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        containerClass={classes.containerClass}
        noDivider
        mediumTabs
      />
    )
  }

  renderMobile = () => {
    const { handleTabChange, tabValue, customerFirstName, classes } = this.props
    const optionsFromConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'customerSnapshotTabs').map(item => {
      if (!item.enabled) return undefined

      switch (item.type) {
        case 'SNAPSHOT_PURCHASES':
          return {
            text: translations('Latest Purchases'),
            value: SNAPSHOT_PURCHASES,
            type: 'purchase'
          }
        case 'SNAPSHOT_RECOMMENDATIONS':
          return {
            text: translations(item.label, { customerFirstName }) || translations('Recommendations'),
            value: SNAPSHOT_RECOMMENDATIONS,
            type: 'product',
            variant: 'recommendedProducts'
          }
        case 'SNAPSHOT_WISHLIST':
          return {
            text: translations('Wishlist'),
            value: SNAPSHOT_WISHLIST,
            type: 'product',
            variant: 'wishlist'
          }
      }
    })
    const options = _.compact(optionsFromConfig)
    const option = options.find(o => o.value === tabValue)
    return (
      <Fragment>
        <MobileSelect
          onChange={handleTabChange}
          value={tabValue}
          options={options}
        />
        <div className={classes.individualContainer}>
          {this.renderContent(_.get(option, 'type'), _.get(option, 'variant'))}
        </div>
      </Fragment>
    )
  }

  render = () => {
    const { classes } = this.props
    return (
      <section className={classes.container}>
        <Hidden smUp>
          {this.renderMobile()}
        </Hidden>
        <Hidden xsDown>
          {this.renderDesktop()}
        </Hidden>
        
      </section>
    )
  }
}

export default withStyles(style)(CustomerSnapshotActionCarousel)
