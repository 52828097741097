const styles = theme => ({
  container: {
    padding: '0 15px',
    flex: 1,
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    paddingBottom: 50
  },
  listContainer: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    margin: '10px auto',
    maxWidth: 1200
  },
  filterText: {
    fontWeight: 'bold'
  },
  filterIcon: {
    marginRight: 5
  },
  filterResultsText: {
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '15px 0'
  }
})

export default styles
