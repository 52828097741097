import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import PollsQuestionsSection from '../PollsQuestionsSection'
import H2 from '../../../components/H2'
import Container from '../../../components/Container'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { translations } from '../../../config'
import style from './style'

class PollsQuestionsList extends PureComponent {
  render () {
    const { classes, dailyQuestions, weeklyQuestions, monthlyQuestions } = this.props
    return (
      <div className={classes.container}>
        {dailyQuestions.length ? <PollsQuestionsSection title={translations('Daily questions')} questions={dailyQuestions} /> : null}
        {weeklyQuestions.length ? <PollsQuestionsSection title={translations('Weekly questions')} questions={weeklyQuestions} /> : null}
        {monthlyQuestions.length ? <PollsQuestionsSection title={translations('Monthly questions')} questions={monthlyQuestions} /> : null}
        {!dailyQuestions.length && !weeklyQuestions.length && !monthlyQuestions.length && (
          <Container
            withMarginTop
            className={classes.noResultsMessage}
          >
            <H2 value={translations('There are no questions for you to answer.')} />
          </Container>
        )}
      </div>
    )
  }
}

export default compose(
  withStyles(style),
  FullScreenLoader
)(PollsQuestionsList)
