import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import withCheckoutFlow from '../withCheckoutFlow'
import Deferred from './Deferred'

export default compose(
  withCheckoutFlow,
  connect(state => ({
    deferredModule: _.get(state, 'checkoutFlow.deferred'),
    orderNumber: _.get(state, 'checkoutFlow.orderNumber')
  }))
)(Deferred)
