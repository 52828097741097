export default theme => ({
  formControl: {
    width: '100%'
  },
  buttonGroup: {
    marginBottom: '30px'
  },
  formContainer: {
    margin: '0px 35px'
  },
  discountDetailText: {
    fontSize: '12px'
  }
})
