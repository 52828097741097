import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import Dropdown from '../../../../components/Fields/Dropdown'

const UserDropdown = props => {
  const { classes, userOptions, onUserInputChange, selectedUserId, fetchFreshNotifications } = props
  return (
    <div className={classes.container}>
      <Dropdown
        autocomplete
        options={userOptions}
        onInputChange={onUserInputChange}
        value={selectedUserId}
        onChange={fetchFreshNotifications}
        onBlur={fetchFreshNotifications}
        hideClear
        label={'User'}
        hideLabel
      />
    </div>
  )
}

export default withStyles(style)(UserDropdown)
