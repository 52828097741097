import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'
import { actions as productWaitlistActions, selectors as productWaitlistSelectors } from '../../../store/modules/productWaitlist'

import WaitlistScreen from './WaitlistScreen'

const mapStateToProps = state => {
  return {
    waitlist: productWaitlistSelectors.getProductWaitlist(state),
    isLoading: productWaitlistSelectors.getIsLoading(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onClickViewWaitlist: ({ dispatch }) => product => {
      const { id } = product
      dispatch(push(`/product/${id}/waitlist`))
    },
    loadMore: ({ dispatch }) => () => {
      dispatch(productWaitlistActions.fetchProductWaitlistNext())
    }
  })
)(WaitlistScreen)
