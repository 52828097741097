import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const birthdayIcon = getImage('customerIcon')

const BirthdayNotification = props => {
  const { customerName, dayAmount, date } = props.meta
  const { createdAt } = props
  const text = _.template(translations('Birthday Notification Text'))({
    customerName,
    dayAmount,
    date
  })
  return (
    <Notification
      {...props}
      iconSource={birthdayIcon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

BirthdayNotification.propTypes = {
  meta: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    dayAmount: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default BirthdayNotification
