import CustomerReportingFullList from './CustomerReportingFullList'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { selectors as followingSelectors } from '../../../../../store/modules/following'

const mapStateToProps = state => {
  const customers = followingSelectors.getFollowing(state)
  return {
    customers
  }
}

export default connect(mapStateToProps)(CustomerReportingFullList)
