import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Lock from '@material-ui/icons/Lock'
import Block from '@material-ui/icons/Block'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const UsersRowIcon = ({ accountDeactivated, accountLocked, classes }) => {
  if (accountDeactivated) {
    return (
      <div className={classes.iconContainer}>
        <Block />
      </div>
    )
  } else if (accountLocked) {
    return (
      <div className={classes.iconContainer}>
        <Lock />
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(style)(UsersRowIcon)
