import * as constants from './constants'

import digitalStoreSdk from '../../../digitalStoreSdk'

class EventDetailsActions {
  fetchEvent = ({ id }) => ({
    type: constants.FETCH_EVENT,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.events.fetchEventById({ id })
    }
  })
  deleteEvent = ({ id }) => ({
    type: constants.DELETE_EVENT,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.events.deleteEventById({ id })
    },
    payload: { id }
  })
  setActiveGalleryIndex = (index) => ({
    type: constants.SET_ACTIVE_GALLERY_INDEX,
    payload: index
  })
}

export default new EventDetailsActions()
