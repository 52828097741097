import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Image from '../../../Image'
import style from './style'
import RemoveButton from '../RemoveButton'

const ImageComponent = ({ id, url, classes, width, editing, onDelete, fileName }) => {
  return (
    <div className={cx(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })}>
      {editing && (
        <RemoveButton onClick={event => onDelete({ id, event })} />
      )}
      <Image src={url} className={classes.image} containerClassName={classes.imageContainer} />
      <div className={classes.fileNameContainer}>{fileName}</div>
    </div>
  )
}

ImageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    containerMargin: PropTypes.string,
    image: PropTypes.string,
    imageContainer: PropTypes.string
  }),
  width: PropTypes.string,
  editing: PropTypes.bool,
  onDelete: PropTypes.func
}

export default withStyles(style)(ImageComponent)
