import { connect } from 'react-redux'

import { selectors as categoriesSelectors } from '../../../../store/modules/categories'

import CategoriesGrid from './CategoriesGrid'

const mapStateToProps = state => ({
  categories: categoriesSelectors.getRootCategories(state)
})

export default connect(
  mapStateToProps
)(CategoriesGrid)
