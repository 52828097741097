import React from 'react'
import CustomerSnapshotHeader from './CustomerSnapshotHeader'
import CustomerSnapshotLatest from './CustomerSnapshotLatest'
import CustomerSnapshotSpendMetrics from './CustomerSnapshotSpendMetrics'
import CustomerSnapshotActionCarousel from './CustomerSnapshotActionCarousel'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import style from './style'

import FullScreenProgress from '../../../../components/FullScreenLoader'

const ViewCustomerSnapshotScreen = ({ classes }) => (
  <React.Fragment>
    <CustomerSnapshotHeader />
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.leftContent}>
          <CustomerSnapshotSpendMetrics />
        </div>
        <div className={classes.rightContent}>
          <CustomerSnapshotLatest />
        </div>
      </div>
    </div>
    <div className={classes.carouselContainer}>
      <CustomerSnapshotActionCarousel />
    </div>
  </React.Fragment>
)

export default compose(
  withStyles(style),
  FullScreenProgress
)(ViewCustomerSnapshotScreen)
