import _ from 'lodash'
import { promiseReducer } from '../../util'

export const fetchProductList = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)

export const fetchProductLists = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)

export const createProductList = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)

export const updateProductList = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)

export const putProductList = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)

export const deleteProductList = promiseReducer(
  (state, action) => ({
    ...state,
    list: action.result
  })
)
