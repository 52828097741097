import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import cx from 'classnames'
import style from './style'
import Button from '../../../../../components/Button'
import BackBar from '../../../../../components/BackBar'
import H1 from '../../../../../components/H1'
import { getCustomerFullName } from '../../../../../helpers'
import { translations } from '../../../../../config'

const CustomerSnapshotHeader = (props) => {
  const { classes, customer, tier, initials, goToFullProfile, isDatesVisible, onActionsClick, heightOffsetRef } = props

  return <section className={classes.container} ref={heightOffsetRef}>
    <div className={classes.leftContent}>
      <BackBar classes={{ container: classes.backArrowContainer, buttonText: classes.backArrowButtonText, arrow: classes.backArrow }} />
      <div className={classes.customer}>
        <Avatar className={classes.avatar}>{initials}</Avatar>
        <div className={cx(classes.customerDetailsWrapper, { [classes.centerDetailsWrapper]: !isDatesVisible })}>
          <div className={classes.customerNameWrapper}>
            <H1 className={classes.title} value={getCustomerFullName(customer)} />
            {tier && <div className={classes.pill}>{tier}</div>}
          </div>
          {isDatesVisible && <div className={classes.dateOverviewWrapper}>
            <span className={classes.dateOverview}>Last in store: 28/03/18</span>
            <span className={classes.dateOverview}>Last online: 28/03/18</span>
          </div>}
        </div>
      </div>
    </div>

    <div className={classes.buttonWrapper}>
      <Button classes={{ root: classes.buttonRoot }} color='primary' onClick={onActionsClick}>
        {translations('Actions')}
      </Button>
    </div>
    <Button classes={{ root: classes.profileButton }} color='primary' onClick={goToFullProfile}>{translations('Full Profile')}</Button>
  </section>
}

export default compose(
  withStyles(style)
)(CustomerSnapshotHeader)
