import React, { Component, Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Table, TableHeader, TableRow, TableCell } from '../../../../components/Table'
import ButtonLink from '../../../../components/ButtonLink'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import _ from 'lodash'
import { translations } from '../../../../config'

import styles from './style'

class CustomerScreen extends Component {
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>{translations('Sorry, an error has occurred. Please try again.')}</div>
  }

  renderTableRows = () => {
    const { results, classes } = this.props
    return _.chain(results)
      .map(customer => {
        const { firstName, lastName, telephone, address, email, dob, id } = customer
        const { onCustomerClick } = this.props
        // address/dob/telephone is not required
        return (
          <TableRow key={id} onClick={() => onCustomerClick(customer)}>
            <TableCell xs={4} sm={3} md={3}>
              <ButtonLink >
                {`${firstName} ${lastName}`}
              </ButtonLink>
            </TableCell>
            <TableCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{telephone || '-'}</TableCell>
            <TableCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{address ? address.postCode : '-'}</TableCell>
            <TableCell xs={8} sm={3} md={3} truncate>{email}</TableCell>
            <TableCell className={classes.hideExtraSmall} xs={0} sm={0} md={2}>{dob || '-'}</TableCell>
          </TableRow>
        )
      })
      .value()
  }

  render() {
    const { error, noResults, hasSearched, searchCustomers, hasMore, results, isLoading, classes, isHiddenForModal } = this.props
    if (error) {
      return this.renderError()
    }
    // only display table if there are results
    return (
      <Fragment>
        <div className={classes.loadingBarContainer}>
          {isLoading && <LinearProgress mode='indeterminate' style={{ height: 2 }} />}
        </div>
        <div className={classes.gridContainer}>
          <InfiniteScroll
            className={classes.container}
            loadMore={searchCustomers}
            hasMore={hasMore}
            initialLoad={false}
          >
            {
              results &&
              <Table
                emptyMessage={translations('No results found')}
                header={(
                  <TableHeader>
                    <TableCell headerCell xs={4} sm={3} md={3}>{translations('Customer Name')}</TableCell>
                    <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{translations('Customer Telephone')}</TableCell>
                    <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={3} md={2}>{translations('Customer Postcode')}</TableCell>
                    <TableCell headerCell xs={8} sm={3} md={3}>{translations('Customer Email')}</TableCell>
                    <TableCell headerCell className={classes.hideExtraSmall} xs={0} sm={0} md={2}>{translations('DOB')}</TableCell>
                  </TableHeader>
                )}
                rows={this.renderTableRows()}
              />
            }
          </InfiniteScroll>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(CustomerScreen)
