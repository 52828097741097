import { push } from 'connected-react-router'
import { SUCCESS } from '../../middleware/redux-promise'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import { constants as feedbackConstants } from '../feedback'

class FeedbackMiddleware {
  sendFeedbackSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.status === SUCCESS && action.type === feedbackConstants.SEND_FEEDBACK) {
      dispatch(push(`/`))
      modalService.continue({title: translations('Feedback Success Title'), text: translations('Feedback Success Text')})
      next(action)
    } else {
      next(action)
    }
  }
}

export default new FeedbackMiddleware()
