import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import ImageBox from '../../../components/ImageBox'
import Button from '../../../components/Button'
import { getImage } from '../../../components/Images'

import { Helmet } from 'react-helmet'

const closeIconBlack = getImage('closeIconBlack')
const noSkinToneImg = getImage('noSkinTone')

class ViewGalleriesScreen extends Component {
  renderImages = () => {
    const { galleries, classes, onGalleryClick, selectedGalleryIndex } = this.props

    return galleries.map((gallery, index) => {
      const media = _.get(gallery, 'media', [])
      const firstImage = media[0] || {}
      const galleryPickerImage = _.find(media, m => m.galleryPickerImage) || { src: noSkinToneImg }

      return (
        <div className={classes.galleryItem}>
          <Button wrapper onClick={() => onGalleryClick(index)}>
            <ImageBox
              className={classes.gallerySelectImage}
              src={galleryPickerImage.src}
              round
              bordered
              boxShadow
              cover
              selectable
              selected={selectedGalleryIndex === index}
            />
          </Button>
        </div>
      )
    })
  }

  renderClose() {
    const { onCloseClick, classes } = this.props
    return (
      <div className={classes.closeContainer}>
        <IconButton onClick={onCloseClick} classes={{ root: classes.iconButton }}>
          <img src={closeIconBlack} className={classes.closeImage} />
        </IconButton>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <h1 className='visuallyhidden'>{'View Galleries'}</h1>
        <Helmet><title>{'View Galleries'}</title></Helmet>
        {this.renderClose()}
        <div className={classes.galleryContainer}>
          {this.renderImages()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ViewGalleriesScreen)
