import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class UserDetailsReducers {
  createUser = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createUserPending(state, action)
      case SUCCESS:
        return this.createUserSuccess(state, action)
      case FAIL:
        return this.createUserFailure(state, action)
      default:
        return state
    }
  }

  createUserPending = (state, action) => ({
    ...state,
    createUserStatus: PENDING,
    action: action.type
  })

  createUserSuccess = (state, action) => ({
    ...state,
    createUserStatus: SUCCESS,
    action: undefined,
    user: action.result,
    error: {}
  })

  createUserFailure = (state, action) => ({
    ...state,
    createUserStatus: FAIL,
    error: action.error
  })

  fetchUserDetails = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchUserDetailsPending(state, action)
      case SUCCESS:
        return this.fetchUserDetailsSuccess(state, action)
      case FAIL:
        return this.fetchUserDetailsFailure(state, action)
      default:
        return state
    }
  }

  fetchUserDetailsPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  fetchUserDetailsSuccess = (state, action) => ({
    ...state,
    status: SUCCESS,
    action: undefined,
    user: action.result,
    error: {}
  })

  fetchUserDetailsFailure = (state, action) => ({
    ...state,
    status: FAIL,
    error: action.error
  })

  updateUser = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateUserPending(state, action)
      case SUCCESS:
        return this.updateUserSuccess(state, action)
      case FAIL:
        return this.updateUserFailure(state, action)
      default:
        return state
    }
  }

  updateUserPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  updateUserSuccess = (state, action) => ({
    ...state,
    status: SUCCESS,
    action: undefined,
    user: action.result,
    error: {}
  })

  updateUserFailure = (state, action) => ({
    ...state,
    status: FAIL,
    error: action.error
  })
}

export default new UserDetailsReducers()
