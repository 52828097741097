import _ from 'lodash'

import { getItemValue, getItemAggregateValuesFactory } from './helpers'

const getCurrency = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data, type } = reportSchema
  const { sumBy, aggregateFrom, vsTarget } = data

  if (sumBy) {
    const someItemSumValue = _.get(items.find(item => _.get(item, sumBy)), sumBy)
    return _.get(someItemSumValue, 'code')
  }
  if (aggregateFrom) {
    // const someAggregateData = _.get(items.find(item => _.get(item, aggregateFrom)) || items[0], aggregateFrom)
    const someAggregateData = getItemAggregateValuesFactory(props, true)(items[0])
    if (someAggregateData) {
      if (type === 'COUNT') {
        return _.mapValues(
          someAggregateData,
          someValue => _.get(someValue, 'code')
        )
      } else {
        return _.get(Object.values(someAggregateData)[0], 'code')
      }
    }
  }
  if (vsTarget) {
    const target = getItemValue(items[0], _.get(vsTarget, 'target.path'), props, true) || {}
    return target.code
  }
}

export default getCurrency
