import React from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

import style from './style'

const Label = props => {
  const { classes, label, noFloatingLabel, shrink, visuallyHidden = false } = props
  const labelEl = <InputLabel
    // FormControlClasses
    classes={{
      focused: classes.floatingLabelFocusStyle
    }}
    htmlFor={label}
    shrink={!!shrink}
  >
    {!noFloatingLabel && label}
  </InputLabel>

  return visuallyHidden ?
    <VisuallyHidden>
      {labelEl}
    </VisuallyHidden> :
    labelEl
}

export default withStyles(style)(Label)
