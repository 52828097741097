const style = theme => ({
  container: {
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: theme.palette.lightGrey.main
  },
  gallerySelectImage: {
    height: 150,
    width: 150
  },
  galleryItem: {
    padding: 10
  },
  galleryContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  closeContainer: {
    position: 'fixed',
    top: 20,
    right: 20
  }
})

export default style
