import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { selectors as pollResultsSelectors, actions as pollResultsActions } from '../../../store/modules/pollResults'
import { selectors as authSelectors } from '../../../store/modules/auth'

import FilterModal from './FilterModal'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  const filters = pollResultsSelectors.getFilters(state)
  const storeList = authSelectors.getUserStoresOptions(state)
  return {
    filters,
    storeList,
    initialValues: filters
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, filters }) => formValues => {
      let newFilters = {
        ...filters,
        ...formValues
      }

      dispatch(pollResultsActions.changeFilters({ filters: newFilters }))
      dispatch(pollResultsActions.fetchPollResultsFresh({ filters: newFilters }))
      modalService.close()
    }
  })
)(FilterModal)
