import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'
import styled from 'styled-components'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { actions as checkoutFlowActions } from '../../../store/modules/checkoutFlow'
import { useGetFormValues } from '../../../hooks/formHooks'
import FormModal from '../../../components/FormModal'
import Form from '../../../components/Form'
import FullPayment from './FullPayment'
import CreditPayment from './CreditPayment'
import CheckoutWrapper from '../common/CheckoutWrapper'
import modalService from '../../../services/modalService'
import { translations, salesforceOCAPI } from '../../../config'
import { useCurrentOrder } from '../../../hooks/currentOrderHooks'
import { useBasket } from '../../../hooks/currentOrderSalesforceHooks'
import { getFullName } from '../../../helpers'
import { getPaymentCardType, getPaymentMethod } from '../../../formatters/salesforcePaymentFormatter'

const PaymentFormWrapper = styled.div``

export const formId = 'payment-saleforce'

const PaymentForm = Form(formId)

const PaymentSalesforce = () => {
  const dispatch = useDispatch()
  const formValues = useGetFormValues(formId)
  const order = useCurrentOrder()
  const total = _.get(order, 'total.value')
  const basket = useBasket(formId)
  const customerFullName = getFullName(useCurrentOrder().customer || {})
  const [isPayingFullAmount, setIsPayingFullAmount] = useState(false)

  const handlePaymentFullAmountClick = useCallback(() => {
    if (!isPayingFullAmount) {
      dispatch(change(formId, 'depositAmount', total))  
    }
    setIsPayingFullAmount(!isPayingFullAmount)
  })

  const onSubmit = useCallback((formData) => {
    modalService.open({
      component: FormModal,
      formId: 'payment-authorisation-code',
      title: translations('Please Enter your Authorisation Code'),
      onSubmit: ({ authorisationCode }) => {
        if (!basket) {
          throw new Error('No Salesforce basket found in store.')
        }

        const payments = []

        if (formData.cardLastFourDigits) {
          payments.push({
            method: getPaymentMethod(formData.paymentMethod),
            amount: parseFloat(formData.depositAmount).toFixed(2),
            info: {
              cardLastFourDigits: formData.cardLastFourDigits,
              cardExpiryDate: formData.cardExpiryDate,
              cardholderName: customerFullName,
              cardType: getPaymentCardType(formData.paymentMethod),
              cardAuthorizationCode: authorisationCode
            }
          })
        }

        if (formData.ifcAgreementNumber) {
          payments.push({
            method: salesforceOCAPI.ifcPaymentMethodId,
            amount: parseFloat(formData.depositAmount).toFixed(2),
            info: {
              ifcPaymentMonths: formData.ifcPaymentMonths,
              ifcAgreementNumber: formData.ifcAgreementNumber
            }
          })
        }

        dispatch(currentOrderActions.updateOrder({
          details: {
            salesforce: {
              basketId: basket.basketId,
              payInFull: formData.payInFull || false,
              futurePaymentMethod: formData.futurePaymentMethod || null,
              payments: payments
            }
          }
        }))
        modalService.close()
        dispatch(checkoutFlowActions.next())
      },
      schema: [{
        id: 'authorisationCode',
        field: 'Input',
        props: {
          label: translations('Authorisation Code'),
          name: 'authorisationCode',
          multiline: false,
          required: true
        }
      }]
    })
  }, [])

  return <PaymentForm
    onSubmit={onSubmit}
    initialValues={{}}>
    <CheckoutWrapper
      formId={formId}
      editable
    >
      <PaymentFormWrapper>
        <FullPayment
          formValues={formValues}
          isPayingFullAmount={isPayingFullAmount}
          handlePaymentFullAmountClick={handlePaymentFullAmountClick}
        />
        <CreditPayment
          formValues={formValues}
          isPayingFullAmount={isPayingFullAmount}
        />
      </PaymentFormWrapper>
    </CheckoutWrapper>
  </PaymentForm>
}

export default PaymentSalesforce
