import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { constants as currentOrderConstants } from '../../store/modules/currentOrder'

import P from '../P'
import { translations } from '../../config'
import style from './style'

const EditMode = ({
  // hoc
  classes,
  editType,

  // passed
  orderNumber,
  stopEditingOrder,
  fill
}) => {
  const isExchange = editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
  const title = isExchange
    ? translations('EXCHANGE MODE')
    : translations('EDIT MODE')

  const tagline = isExchange
    ? _.template(translations('You are exchanging order number'))({ orderNumber })
    : _.template(translations('You are editing order number'))({ orderNumber })

  const exitText = isExchange
    ? translations('Exit exchange mode')
    : translations('Exit edit mode')

  const editModeSection = (
    <div className={fill ? classes.containerFill : classes.container}>
      <P className={classes.header} type='large' value={title} />
      <P className={classes.orderNumberText} value={tagline} />
      <P className={classes.exitText} onClick={stopEditingOrder} value={exitText} />
    </div>
  )
  return editModeSection
}

export default withStyles(style)(EditMode)
