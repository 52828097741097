
const style = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    height: 48,
    borderRadius: 2,
    backgroundColor: theme.searchInput.backgroundColor,
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    width: '100%'
  },
  searchInput: {
    flex: 1,
    border: 0,
    outline: 0,
    padding: 10,
    fontSize: theme.searchInput.fontSize,
    color: theme.searchInput.textColor,
    fontFamily: theme.base.fontFamily,
    lineHeight: '20px',
    width: '100%'
  },
  searchIconContainer: {
    paddingLeft: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  icon: {
    color: theme.searchInput.iconColor,
    transform: 'scale(0.9)'
  }
})

export default style
