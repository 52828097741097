import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import * as constants from './constants'
import * as authSelectors from '../auth/selectors'
import { SUCCESS } from '../../middleware/redux-promise'

class ResourcesMiddleware {
  loadResourcesMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToResources = matchPath(action.payload.location.pathname, { path: '/resources', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isLoggedIn && isRoutingToResources) {
        dispatch(actions.fetchResources())
      }
    }
  }
  resourcesLinkLoadMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.FETCH_RESOURCE && action.status === SUCCESS) {
      const { link } = action.result
      if (window.cordova) {
        window.open(link, '_blank', 'location=no')
      } else {
        window.open(link, '_blank')
      }
    }
    next(action)
  }
}

export default new ResourcesMiddleware()
