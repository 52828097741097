const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 64px)'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    textAlign: 'center',
    maxWidth: '600px'
  },
  heading: {
    marginBottom: '16px'
  }
}

export default theme => styles
