import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../AlertBadge'
import Button from '../../components/Button'
import style from './style'
import {  a11y, translations } from '../../config'
import { matchPath } from 'react-router'

const Header = ({
  children,
  rightElement: RightElement,
  openMenu,
  classes,
  customerModeUpdate,
  networkNotification,
  isConsultation,
  theme
}) => {
  const hideMenuItems = customerModeUpdate || isConsultation
  const showBasketIcon = !hideMenuItems && RightElement

  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      {networkNotification}
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoContainer}>
          <img className={classes.whiteLetters} src={theme.base.headerLogo} alt={a11y.mainLogoAltText} />
        </div>
        { !hideMenuItems ? (
          <IconButton color='inherit' aria-label='Menu' onClick={openMenu} className={classes.burger}>
            <AlertBadge type='notifications' classes={{ badge: classes.burgerBadge }}>
              <MenuIcon />
            </AlertBadge>
          </IconButton>
        ) : <div />
        }
        { showBasketIcon &&
          <Button aria-label={translations('Basket')} className={classes.basketIcon}><RightElement /></Button>
        }
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  RightElement: PropTypes.element
}

export default withStyles(style)(Header)
