import React from 'react'
import PropTypes from 'prop-types'
import { FormHelperText } from '@material-ui/core'
import Editable from '../Editable'
import { Input } from '../../../components/Fields'
import P from '../../P'

Editable.propTypes = {
  editing: PropTypes.bool
}

export default Editable({
  input: (props) => {
    return (
      <Input {...props} />
    )
  },
  display: ({ label, value, format, description }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={format ? format(value) : value} />
      { description && <FormHelperText>{description}</FormHelperText> }
    </div>
  )
})
