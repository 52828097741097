import React from 'react'

import { MessageListScreen, MessageDetailScreen, ComposeMessageScreen, ComposeGroupMessageScreen } from '../../retailos/Messaging'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/messages' path='/messages' exact component={TimedLogoutComponent(MessageListScreen)} />,
  <PrivateRoute key='/messages/new' path='/messages/new' exact component={TimedLogoutComponent(ComposeMessageScreen)} />,
  <PrivateRoute key='/messages/new' path='/messages/new/broadcast' exact component={TimedLogoutComponent(ComposeGroupMessageScreen)} />,
  <PrivateRoute key='/messages/new' path='/messages/new/:id' exact component={TimedLogoutComponent(ComposeMessageScreen)} />,
  <PrivateRoute key='/messages/:id' path='/messages/:id' exact component={TimedLogoutComponent(MessageDetailScreen)} />
]
