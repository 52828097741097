const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  formControl: {
    width: '100%',
    marginBottom: 10
  },
  buttonClass: {
    color: 'white',
    width: '100%'
  }
}

export default style
