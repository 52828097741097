import _ from 'lodash'
import { matchPath } from 'react-router'

import { constants, selectors, actions } from './'
import * as currentOrderCombinedSelectors from '../combinedSelectors/currentOrderCombinedSelectors'
import { actions as checkoutActions, constants as checkoutConstants } from '../checkoutFlow'
import { actions as appActions } from '../app'
import { constants as currentOrderSalesforceConstants } from '../currentOrderSalesforce'
import { constants as authConstants } from '../auth'
import { constants as customerDetailConstants } from '../customerDetails'
import modalService from '../../../services/modalService'
import { translations, emailBasketEnabled, storeroomRequestEnabled, addedToBasketModalEnabled } from '../../../config'
import { history } from '../../../store'
import { SUCCESS } from '../../middleware/redux-promise'
import toastService from '../../../services/toastService/toastService'
import { selectors as currentAppointmentSelectors } from '../currentAppointment'

class CurrentOrderMiddleware {
  _getModalActions = ({ dispatch, getState }) => {
    const inEditMode = selectors.getCurrentOrderEditMode(getState())
    const inConsultationMode = currentAppointmentSelectors.getIsAppointmentActive(getState())

    const actions = [
      emailBasketEnabled && !inEditMode && {
        success: true,
        disabled: inConsultationMode,
        text: translations('Email basket'),
        onClick: () => dispatch(checkoutActions.start({ checkoutType: 'referral' })),
        primary: false
      },
      storeroomRequestEnabled && {
        success: true,
        text: translations('Submit Request'),
        onClick: () => dispatch(checkoutActions.start({ checkoutType: 'storeroom' })),
        primary: false
      },
      !inConsultationMode && {
        success: true,
        text: translations('Go To Checkout'),
        onClick: () => dispatch(checkoutActions.start({ checkoutType: 'checkout' })),
        primary: true
      },
      {
        text: translations('Continue'),
        primary: false
      }
    ].filter(e => e)
    return actions
  }

  addProductMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.ADD_PRODUCT_TO_ORDER && addedToBasketModalEnabled && action.product) {
      const { product, silent, quantity = 1 } = action
      if (!silent) {
        const productName = _.get(product, 'variant.name') || _.get(product, 'groupName') || _.get(product, 'name')
        toastService.action({
          type: 'basket',
          message: `(${quantity}) ${productName}`,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      }
    }
    next(action)
  }

  /**
   * @deprecated
   * @description This middleware triggers a modal when product(s) are removed from the basket
   * However, it's no longer used since RET-4871. I have left it here as we may want to add this back for other clients in the future.
   * @memberof CurrentOrderMiddleware
   */
  removeProductMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.REMOVE_PRODUCT_FROM_ORDER && addedToBasketModalEnabled) {
      const { silent } = action
      const path = history.location.pathname
      const isCheckout = matchPath(path, { path: '/checkout' })
      const isReferral = matchPath(path, { path: '/referral' })
      if (!isCheckout && !isReferral && !silent) {
        const products = currentOrderCombinedSelectors.getCurrentOrderGroupedProducts(getState())
        const { product } = action
        const title = translations('Removed From Basket')
        const text = _.template(translations('Removed From Basket Details'))({
          name: product.name,
          secondaryName: product.secondaryName
        })
        if (products.length > 1) {
          modalService.action({
            title,
            text,
            actions: this._getModalActions({ dispatch, getState })
          })
        } else {
          modalService.continue({
            title,
            text,
            confirmButtonText: translations('Continue')
          })
        }
      }
    }
    next(action)
  }

  restoreMiddleware = ({ dispatch, getState }) => next => action => {
    const { isCustomerMode, storeId } = action
    const validSelectStore = action.type === authConstants.SELECT_STORE && !action.silent
    if (validSelectStore && !isCustomerMode) {
      dispatch(actions.restoreOrder({ storeId }))
        .catch(err => {
          console.log(err)
        })
    }
    next(action)
  }

  setDeliveryOptionMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.status === 'SUCCESS' && action.type === checkoutConstants.FETCH_DELIVERY_OPTIONS) {
      if (!getState().currentOrder.deliveryOption && getState().currentOrder.customer) {
        dispatch(actions.updateOrder({
          deliveryOption: action.result.find(({ deliveryType }) => deliveryType === 'home'),
          deliveryType: 'home'
        }))
      }
    }

    next(action)
  }

  updateOrderCustomerOnCustomerUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === customerDetailConstants.UPDATE_CUSTOMER && action.status === SUCCESS) {
      const customer = action.result
      const currentCustomer = selectors.getCurrentOrderCustomer(getState())
      if (currentCustomer && currentCustomer.id === customer.id) {
        dispatch(actions.updateOrder({ customer }))
      }
    }
    next(action)
  }

  recordRequestOnAddRequestSuccess = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.ADD_REQUEST_ITEM && action.status === SUCCESS) {
      const order = action.result
      dispatch(actions.addRequestItemsToOrder(order.products))
    }
    next(action)
  }

  startConsultationModeMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === constants.START_CONSULTATION_MODE) {
      dispatch(actions.fetchBasketConfiguration({ force: false }))
    }
    if (action.type === constants.ADD_INSPIRATION_TO_ORDER) {
      dispatch(actions.fetchBasketConfiguration({ force: false }))
    }
  }

  clearOrderMiddleware = ({ dispatch }) => next => (action) => {
    next(action)
    if (action.type === constants.CLEAR_ORDER && action.status === SUCCESS && action.showToast) {
      dispatch(appActions.toggleBasket({ isOpen: false }))
      toastService.action({
        type: 'basket',
        message: translations('Basket - Empty message'),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
  }

  customerAssignedToOrderMiddleware = () => next => (action) => {
    next(action)
    if (action.type === constants.UPDATE_ORDER && action.customer && action.showToast) {
      const { firstName, lastName } = action.customer
      const fullName = _.compact([firstName, lastName]).join(' ')
      toastService.action({
        type: 'basket',
        message: translations('Basket - Assigned to customer message', { fullName }),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
  }

  salesforceSyncOrderMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const isBasketCreateAction = action.type === currentOrderSalesforceConstants.CREATE_CUSTOMER_BASKET
    const isSuccess = action.status === SUCCESS

    // if (isBasketCreateAction && isSuccess) {
    //   const items = _.get(action, 'result.items', [])

    //   const state = getState()
    //   const currentOrderProducts = _.get(state, 'currentOrder.products')

    //   // fixme: this is a poor attempt to match app basket with SF basket.
    //   const productsWithMeta = _.map(currentOrderProducts, product => {
    //     const meta = items.find(item => item.externalProductId === product.externalProductId)
    //     return {
    //       ...product,
    //       details: {
    //         ...product.details,
    //         salesforce: meta
    //       }
    //     }
    //   })

    //   dispatch(actions.updateOrder({ products: productsWithMeta }))
    // }
  }
}

export default new CurrentOrderMiddleware()
