import _ from 'lodash'
import { SUCCESS } from '../../middleware/redux-promise'

class ChatReducers {
  channelJoined = (state, action) => {
    return {
      ...state,
      channelId: action.channelId
    }
  }
  setChatChannelId = (state, action) => {
    return {
      ...state,
      channelId: action.channelId
    }
  }
  deleteChatChannel = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        channelId: ''
      }
    }
    return {...state}
  }
}

export default new ChatReducers()
