import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../config'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import H2 from '../../../components/H2'

import FullScreenProgress from '../../../components/FullScreenLoader'
import Waitlist from '../../../components/Waitlist'

import style from './style'

class EventWaitlistScreen extends PureComponent {
  render () {
    const { classes, loadMore, hasMore, waitlist = [], onClickDelete, onClickSendMessage } = this.props
    return (
      <div className={classes.root}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <H2 value={_.toUpper(translations('Waitlist'))} />
          )}
        />
        <Waitlist
          loadMore={loadMore}
          hasMore={hasMore}
          waitlist={waitlist}
          onClickDelete={onClickDelete}
          onClickSendMessage={onClickSendMessage}
        />
      </div>
    )
  }
}

EventWaitlistScreen.propTypes = {
  getEventWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(EventWaitlistScreen)
)
