const styles = theme => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  rightButtonContainer: {
    paddingLeft: 12,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8
    }
  }
})

export default styles
