import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING, FAIL } from '../../middleware/redux-promise'

import { FETCH_USER_DETAILS, FETCH_CURRENT_USER_DETAILS } from './constants'

export const getSelectedUser = state => (state.userDetails || {}).user

export const getInitialUserDetailIsLoading = state => state.userDetails.status === PENDING && (state.userDetails.action === FETCH_USER_DETAILS || state.userDetails.action === FETCH_CURRENT_USER_DETAILS)
export const getFailedFetchCurrentUserDetailsStatus = state => state.userDetails.status === FAIL && state.userDetails.action === FETCH_CURRENT_USER_DETAILS

export const getUserWithModifiedRoleAndStores = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    const newUser = _.omit(user, ['role', 'stores', 'departments'])
    newUser.roleId = user.role.id
    newUser.departmentIds = (user.departments || []).map(d => d.id)

    // material UI needs an array for multi and a string for single
    if (!user.role.multipleStores) {
      newUser.storeIds = user.stores.length ? user.stores[0].id : ''
    } else {
      newUser.storeIds = user.stores.map(store => store.id)
    }
    return newUser
  }
})

export const getSelectedUserValue = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user.role.value
  }
})

export const getSelectedUserIsLocked = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user && user.accountLocked
  }
})

export const getSelectedUserIsDeactivated = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return user && user.accountDeactivated
  }
})

export const getSelectedUserForwardingUser = createSelector([
  getSelectedUser
], (user) => {
  if (user) {
    return _.get(user, 'messageForwardUser')
  }
})
