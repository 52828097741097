import { tileRatio } from '../../config'

const styles = theme => {
  return {
    container: {
      opacity: 0,
      display: 'flex',
      flex: 1,
      transition: 'opacity 0.3s',
      background: theme.palette.lightGrey.main
    },
    containerLoaded: {
      opacity: 1
    },
    button: {
      position: 'relative',
      flex: '1',
      border: 0,
      padding: `0 0 ${tileRatio}% 0`,
    },
    buttonInner: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%'
    },
    ratioContainer: {
      width: '100%'
    },
    shadowOverlay: {
      position: 'absolute',
      width: '100%',
      height: '98%',
      top: 0,
      left: 0,
      background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)'
    },
    title: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center'
    },
    titleInner: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        lineHeight: '16px'
      }
    },
    image: {
      width: '100%',
      height: '100%'
    },
    link: {
      position: 'absolute',
      width: '97%',
      height: '95%',
      top: 0,
      left: '1.5%'
    },
    parentCorner: {
      position: 'absolute',
      right: '12px',
      top: '13px',
      maxWidth: '25%',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        right: '14px',
        maxWidth: '22%'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '15%',
        right: '24px'
      }
    },
    smallIconImg: {
      maxWidth: '40%',
      float: 'right'
    },
    rippleVisible: {
      color: '#FFFFFF'
    }
  }
}

export default theme => styles(theme)
