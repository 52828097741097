import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  order: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ORDER:
      return reducers.fetchOrder(state, action)
    case constants.REASSIGN_USER_FOR_ORDER:
      return reducers.reassignUser(state, action)
    case constants.REASSIGN_CUSTOMER_FOR_ORDER:
      return reducers.reassignCustomer(state, action)
    case constants.BEGIN_PARTIAL_REFUND_ORDER:
      return reducers.beginRefundPartialOrder(state, action)
    case constants.CANCEL_PARTIAL_REFUND_ORDER:
      return reducers.cancelRefundPartialOrder(state, action)
    case constants.REFUND_ORDER:
      return reducers.performRefund(state, action)
    case constants.CANCEL_ORDER:
      return reducers.cancelOrder(state, action)
    case constants.UPDATE_ORDER:
      return reducers.updateOrder(state, action)
    case constants.BEGIN_EXCHANGE_ORDER:
      return reducers.beginExchangeOrder(state, action)
    case constants.CANCEL_EXCHANGE_ORDER:
      return reducers.cancelExchangeOrder(state, action)
    default:
      return state
  }
}
