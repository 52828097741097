import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  templates: [],
  recipients: [],
  message: {
    subject: '',
    text: '',
    communicationType: '',
    content: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      // so that previous path isn't immediately reset when navigating
      // to compose message screen
      const { previousPath } = state
      return {
        ...defaultState,
        previousPath
      }
    case constants.UPDATE_RECIPIENT:
      return reducers.updateRecipient(state, action)
    case constants.UPDATE_MESSAGE:
      return reducers.updateMessage(state, action)
    case constants.ADD_CONTENT_TO_MESSAGE:
      return reducers.addContentToMessage(state, action)
    case constants.SEND_MESSAGE:
      return reducers.sendMessage(state, action)
    case constants.SET_PREVIOUS_PATH:
      return reducers.setPreviousPath(state, action)
    case constants.FETCH_MESSAGE_TEMPLATES:
      return reducers.fetchMessageTemplates(state, action)
    default:
      return state
  }
}
