import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { push } from 'connected-react-router'

import LoginScreen from './LoginScreen'
import { actions as appActions } from '../../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { actions as networkActions, selectors as networkSelectors } from '../../../store/modules/network'
import modalService from '../../../services/modalService'
import { translations, environment } from '../../../config'

const loginMethods = environment.LOGIN_METHODS || []

const mapStateToProps = state => ({
  state,
  accountIsLocked: authSelectors.getAccountIsLocked(state),
  isUsingRecentUser: authSelectors.getIsUsingRecentUser(state),
  networkConnectivity: networkSelectors.isConnected(state)
})
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state, ...mappedStateProps }, { dispatch }, ownProps) => {

  const showForgotPasswordModal = () => {
    const accountIsLocked = authSelectors.getAccountIsLocked(state)
    const title = accountIsLocked
      ? translations('Account Locked')
      : translations('Sign out')
    const text = accountIsLocked
      ? translations('Account Locked Message')
      : translations('Sign out message')
    modalService.action({
      title: title,
      text: text,
      actions: [
        {
          success: true,
          text: translations('Reset Password'),
          onClick: () => pushToForgotPassword({ currentUser: authSelectors.getCurrentUser(state) }),
          primary: true
        },
        {
          text: translations('Cancel')
        }
      ]
    })
  }

  const pushToForgotPassword = ({ currentUser }) => {
    dispatch(appActions.customerModeToggle({ isOn: false }))
    dispatch(push('/forgot-password'))
    if (currentUser) {
      dispatch(authActions.logout())
    }
  }

  const openSSO = () => {
    const shouldClearLoginCache = environment.SSO_CLEAR_LOGIN_CACHE || false
    const src = `${environment.API_URL}/v1/saml/login`
    const successRoute = '/sso/success/'
    if (window.cordova) {
      const browserOpts = `${shouldClearLoginCache && 'clearcache=yes,'}location=no,footer=no,enableViewportScale=yes,hidenavigationbuttons=yes`
      const inAppBrowser = window.cordova.InAppBrowser.open(src, '_blank', browserOpts)
      inAppBrowser.addEventListener('loadstart', (params) => {
        if (params.url.includes(successRoute)) {
          const token = params.url.split(successRoute)[1]
          inAppBrowser.close()
          dispatch(push(`${successRoute}${token}`))
        }
      })
    } else {
      window.location = src
    }
  }

  const enabledSSO = loginMethods.indexOf('SSO') > -1

  const setIsUsingRecentUser = (isUsingRecentUser) => {
    dispatch(authActions.setIsUsingRecentUser(isUsingRecentUser))
  }

  return {
    ...ownProps,
    ...mappedStateProps,
    showForgotPasswordModal,
    pushToForgotPassword,
    openSSO,
    enabledSSO,
    setIsUsingRecentUser,
  }
}

export default compose(
  withState('loginMethod', 'setLoginMethod', loginMethods[0] || 'DIGITAL_STORE'),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(LoginScreen)
