import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { goBack } from 'connected-react-router'
import _ from 'lodash'

import { getCheckoutModuleOptions } from '../../../helpers'
import withCheckoutFlow from '../withCheckoutFlow'
import PayPalHere from './PayPalHere'

export default compose(
  withCheckoutFlow,
  withPropsOnChange(
    ['checkoutType'],
    ({ checkoutType }) => {
      return _.pick(getCheckoutModuleOptions('PAYPAL_HERE', checkoutType || 'checkout'), 'paymentRequired')
    }
  ),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    }
  })
)(PayPalHere)
