const styles = theme => ({
  container: {
    minWidth: 200,
    position: 'relative',
    marginRight: 30,
    marginBottom: 10
  },
  fullWidth: {
    marginRight: 0
  },
  contentWrapper: {
    paddingTop: '100%'
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  button: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    minHeight: 180,
    borderRadius: 20,
    border: '2px dashed #D1D1D1',
    position: 'relative',
    outline: 0,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: 30,
      height: 2,
      background: '#D1D1D1',
      left: 'calc(50% - 15px)'
    },
    '&:after': {
      transform: 'rotateZ(90deg)'
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      '&:before, &:after': {
        background: theme.palette.primary.main,
      },
    }
  }
})

export default styles
