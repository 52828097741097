const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200
    }
  },
  containerMargin: {
    marginRight: 30
  },
  closeImage: {
    height: 6
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 24,
    height: 24,
    width: 24,
    '&:focus': {
      color: theme.palette.primary.main
    }
  },
  image: {
    objectFit: 'contain',
    maxHeight: 180,
    maxWidth: '100%'
  },
  imageContainer: {
    height: 'auto'
  },
  productText: {
    marginTop: 10,
    fontSize: 13,
    textAlign: 'center',
    width: '100%'
  },
  truncatedProductName: {
    overflow: 'hidden',
    lineHeight: '1.2em',
    maxHeight: '2.4em'
  }
})

export default styles
