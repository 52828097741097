import { connect } from 'react-redux'
import BroadcastScreen from './BroadcastScreen'
import { compose, withHandlers } from 'recompose'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { actions as departmentActions } from '../../../../../store/modules/departments'
import { actions as notificationActions, selectors as notificationSelectors } from '../../../../../store/modules/notifications'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const loggedInUserStoresOptions = authSelectors.getUserStoresOptions(state)
  const loggedInUserDepartmentOptions = authSelectors.getUserDepartmentsOptions(state)
  const setGroup = ({ groupName }) => dispatch(notificationActions.setBroadcastGroup({ groupName }))
  const getGroup = notificationSelectors.getBroadCastGroup(state)
  const fetchDepartments = () => dispatch(departmentActions.fetchDepartments())
  const broadcastPermissions = authSelectors.getBroadcastPermissions(state)

  return {
    ...ownProps,
    loggedInUserStoresOptions,
    loggedInUserDepartmentOptions,
    setGroup,
    getGroup,
    fetchDepartments,
    broadcastPermissions,
    initGroup: loggedInUserStoresOptions.length === 1 ? {stores: [loggedInUserStoresOptions[0].label], departments: []} : {stores: [], departments: []},
    initStore: !broadcastPermissions.allStores && loggedInUserStoresOptions.length === 1 ? [loggedInUserStoresOptions[0].value] : [],
    initDepartment: !broadcastPermissions.allDepartments && loggedInUserDepartmentOptions.length === 1 ? [loggedInUserDepartmentOptions[0].value] : [],
  }
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps),
  withHandlers({
    onSubmit: ({ onSubmit }) => (options) => {
      onSubmit(options)
    }
  })
)(BroadcastScreen)