import React, { Fragment } from 'react'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import ScrollContainer from '../../../components/ScrollContainer'
import Heading from '../../../components/Heading'
import { translations } from '../../../config'
import InspirationTabs from './InspirationTabs'
import InspirationSearchInput from './InspirationSearchInput'
import TagPicker from './TagPicker'
import InspirationResults from './InspirationResults'

import { Helmet } from 'react-helmet'

const InspirationsSearchScreen = ({ tabbed = true, tabValue, handleTabChange, goBack, ...rest }) => {
  // either a function passed in or undefined (will take default inside BackBar)
  const onClick = goBack
  return (
    <Fragment>
      <h1 className='visuallyhidden'>{translations('Inspirations')}</h1>
      <Helmet><title>{translations('Inspirations')}</title></Helmet>
      <SubHeader
        leftContent={(
          <BackBar onClick={onClick} />
        )}
        centerContent={(
          <Heading component={'h1'} uppercase>{translations('Inspirations')}</Heading>
        )}
      />
      {tabbed ? <InspirationTabs tabValue={tabValue} handleTabChange={handleTabChange} /> : null}
      <InspirationSearchInput />
      <ScrollContainer>
        <TagPicker />
        <InspirationResults {...rest} />
      </ScrollContainer>
    </Fragment>
  )
}

export default InspirationsSearchScreen
