import { ellipsis } from 'polished'

const styles = theme => ({
  container: {
    margin: '10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FCFFC8',
    border: '1px solid #FFD245'
  },
  containerFill: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FCFFC8',
    border: '1px solid #FFD245'
  },
  header: {
    fontWeight: 'bold'
  },
  orderNumberText: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '25px',
    marginRight: '25px',
    marginBottom: '5px',
    ...ellipsis('230px')
  },
  exitText: {
    '&:hover': {
      cursor: 'pointer'
    },
    fontSize: '13px',
    marginBottom: '5px',
    textDecoration: 'underline'
  }
})

export default styles
