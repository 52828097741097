import React, { PureComponent } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import AnswerPicker from '../AnswerPicker'
import Grid from '@material-ui/core/Grid'
import CommentBox from '../CommentBox'
import P from '../../../../components/P'
import Button from '../../../../components/Button'
import SubmittedOverlay from '../SubmittedOverlay'
import { translations } from '../../../../config'

class NonDailyQuestionRow extends PureComponent {
  render() {
    const { classes, question, type, selectAnswer, saveAnswers, openCommentModal, submitting, showSubmittedOverlay } = this.props
    const answered = _.get(question, 'pollAnswers.length', 0) > 0
    const comment = _.get(question, 'pollAnswers[0].details.comment')
    return (
      <div className={classNames(classes.content, {
        [classes.containerWeekly]: type === 'weekly' && !showSubmittedOverlay,
        [classes.containerMonthly]: type === 'monthly' && !showSubmittedOverlay,
        [classes.boxShadow]: !showSubmittedOverlay,
        [classes.maxheight]: showSubmittedOverlay
      })}>
        <div className={classes.headerContainer}>
          <P className={classes.header} value={question.pollQuestion.question} />
        </div>
        <div className={classes.answerContainer}>
          <AnswerPicker
            disabled={submitting}
            question={question}
            selectAnswer={selectAnswer}
          />
        </div>
        {answered && comment &&
          <div className={classes.commentContainer}>
            <CommentBox
              value={comment}
            />
          </div>
        }
        {answered &&
          <div className={classes.buttonContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.buttonCell}>
                <Button
                  buttonType={'white'}
                  disabled={submitting}
                  fullWidth
                  onClick={openCommentModal}
                >
                  {comment ? translations('edit comment') : translations('comment')}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.buttonCell}>
                <Button
                  buttonType={'primary'}
                  disabled={submitting}
                  fullWidth
                  onClick={saveAnswers}
                >
                  {translations('confirm')}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        {submitting && <div className={classes.submittingOverlay} />}
        {showSubmittedOverlay && (
          <div className={classes.submittedOverlay}>
            <SubmittedOverlay />
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(style)(NonDailyQuestionRow)
