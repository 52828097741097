import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import ImageBox from '../../../components/ImageBox'
import Button from '../../../components/Button'
import { getImage } from '../../../components/Images'

const closeIconBlack = getImage('closeIconBlack')

class ViewGalleriesScreen extends Component {
  renderImages = () => {
    const { galleries, classes, onGalleryClick, selectedGalleryIndex } = this.props

    return galleries.map((gallery, index) => {
      return (
        <div className={classes.galleryItem}>
          <Button wrapper onClick={() => onGalleryClick(index)}>
            <ImageBox
              className={classes.gallerySelectImage}
              src={gallery.media[0].src}
              round
              bordered
              boxShadow
              cover
              selectable
              selected={selectedGalleryIndex === index}
            />
          </Button>
        </div>
      )
    })
  }

  renderClose () {
    const { onCloseClick, classes } = this.props
    return (
      <div className={classes.closeContainer}>
        <IconButton onClick={onCloseClick} classes={{ root: classes.iconButton }}>
          <img src={closeIconBlack} className={classes.closeImage} />
        </IconButton>
      </div>
    )
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderClose()}
        <div className={classes.galleryContainer}>
          {this.renderImages()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ViewGalleriesScreen)
