import theme from '../../config/theme'

const styles = {
  h3: {
    color: theme.palette.text.main,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h3.fontSize,
    fontWeight: theme.h3.fontWeight
  }
}

export default theme => styles
