import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import CustomerSnapshotLatest from './CustomerSnapshotLatest'
import { selectors as customerDetailSelectors } from '../../../../../store/modules/customerDetails'
import { selectors as activitiesSelectors } from '../../../../../store/modules/activities'

const mapStateToProps = (state) => ({
  customer: customerDetailSelectors.getCustomer(state),
  activities: activitiesSelectors.getActivitiesByCustomer(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    seeAllActivities: ({ dispatch, customer }) => () => {
      dispatch(push(`/customers/${customer.id}/activity`))
    }
  })
)(CustomerSnapshotLatest)
