import { createSelector } from 'reselect'
import _ from 'lodash'

import { selectors as notificationsSelectors } from '../notifications'
import { selectors as authSelectors } from '../auth'

export const getNotificationsWithActionedValues = createSelector([
  authSelectors.getActiveUserId,
  notificationsSelectors.getNotifications
], (userId, notifications) => {
  return notifications
    .map(notification => {
      if (notification.isActioned) {
        return {
          ...notification,
          isActionedByCurrentUser: _.get(notification.meta, 'userId') === userId
        }
      }
      return notification
    })
})
