import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import Inspiration from './Inspiration'

export default compose(
  connect(),
  withHandlers({
    onInspirationContainerClick: ({ editing, id, dispatch }) => () => {
      if (!editing) {
        dispatch(push(`/inspirations/${id}`))
      }
    }
  })
)(Inspiration)
