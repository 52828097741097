const style = theme => ({
  productsContainer: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'flex-start',
    flex: 1
  },
  productContainer: {
    width: 120,
    margin: '0 7px'
  },
  productAddToButton: {
    width: 120,
    height: 120,
    borderRadius: '100%',
    whiteSpace: 'normal'
  },
  productImage: {
    width: 108,
    height: 108,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative',
    overflow: 'hidden'
  },
  productTitle: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
    whiteSpace: 'normal'
  }
})

export default style
