import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class DepartmentsActions {
  fetchDepartments = () => ({
    type: constants.FETCH_DEPARTMENTS,
    promise: () => digitalStoreSdk.departments.fetchDepartments()
  })
}

export default new DepartmentsActions()
