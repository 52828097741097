import React from 'react'
import moment from 'moment'

import Editable from '../Editable'
import { dateFormat } from '../../../config'
import { Birthday } from '../../../components/Fields'
import P from '../../P'

let birthdayFormat;
['/', '-'].forEach(delimiter => {
  if (dateFormat.includes(delimiter)) {
    // remove years part of format
    // eg, change DD/MM/YYYY to DD/MM
    birthdayFormat = dateFormat.split(delimiter).filter(s => !s.includes('Y')).join(delimiter)
  }
})

export default Editable({
  input: (props) => {
    return (
      <Birthday {...props} />
    )
  },
  display: ({ label, value }) => {
    const formattedValue = moment.utc(value, dateFormat).format(birthdayFormat)
    return <div>
      <P value={label} type='textButton' />
      <P value={formattedValue} />
    </div>
  }
})
