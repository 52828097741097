import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const BottomBar = props => {
  const { absolute, classes } = props
  const className = classNames(classes.buttonContainer, {
    [classes.absolute]: absolute
  })

  return (
    <Fragment>
      <div className={classes.spacing} />
      <div className={className}>
        {props.children}
      </div>
    </Fragment>
  )
}

export default withStyles(style)(BottomBar)
