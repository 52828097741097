import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Input, InputLabel } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import style from './style'

const DateTimePicker = ({
  label,
  classes,
  onChange,
  onBlur,
  meta: { touched, error },
  id,
  ...rest
}) => {
  return (
    <Fragment>
      <InputLabel shrink>{label}</InputLabel>
      <Input
        id={id}
        type='datetime-local'
        label={label}
        {...rest}
        onChange={onChange}
        onBlur={onBlur}
      />
      <FormHelperText error>{touched && error}</FormHelperText>
    </Fragment>
  )
}

DateTimePicker.propTypes = {
  classes: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
}

export default withStyles(style)(DateTimePicker)
