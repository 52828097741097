import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import Form, { FormBody, FormError, FormSubmit } from '../../../components/Form'
import { auth, loginTypes, translations } from '../../../config'

const nameField = auth.loginType === loginTypes.USERNAME
  ? { label: translations('Username'), name: 'username' }
  : { label: translations('Email'), name: 'email' }
const schema = [
  {
    id: nameField.name,
    field: 'Input',
    props: {
      name: nameField.name,
      label: nameField.label,
      editable: false
    }
  }, {
    id: 'password',
    field: 'Input',
    props: {
      name: 'password',
      label: translations('Password'),
      type: 'password',
      required: true,
      autoFocus: true
    }
  }
]

const layout = [
  `${nameField.name}:12`,
  'password:12'
]

const LockForm = Form('lock-form')

const LockFormComponent = ({ classes, unlockScreen, initialValues }) => {
  return (
    <div className={classes.container}>
      <LockForm onSubmit={unlockScreen} initialValues={initialValues} editing>
        <FormError />
        <FormBody schema={schema} layout={layout} />
        <FormSubmit noContainer buttonClass={classes.submit}>{translations('Unlock')}</FormSubmit>
      </LockForm>
    </div>
  )
}

export default withStyles(style)(LockFormComponent)
