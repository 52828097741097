import { useSelector } from 'react-redux'

import {
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormError,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed
} from 'redux-form'

/**
 * @description returns form values
 * @returns
 */
export const useGetFormValues = (formId) => {
  return useSelector(getFormValues(formId))
}

/**
 * @description returns form is valid
 * @returns
 */
export const useIsValid = (formId) => {
  return useSelector(isValid(formId))
}

/**
 * @description returns form is pristine
 * @returns
 */
export const useIsPristine = (formId) => {
  return useSelector(isPristine(formId))
}
