import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Form from '../../../../../components/Form'
import FormBody from '../../../../../components/Form/FormBody'
import ButtonsContainer from '../../../../../components/ButtonsContainer'
import { SelectUser, Input } from '../../../../../components/Fields'
import IconButton from '@material-ui/core/IconButton'
import modalService from '../../../../../services/modalService'
import H2 from '../../../../../components/H2'
import H3 from '../../../../../components/H3'
import P from '../../../../../components/P'
import Button from '../../../../../components/Button'
import ButtonLink from '../../../../../components/ButtonLink'
import { getImage } from '../../../../../components/Images'
import AppointmentProductsList from './AppointmentProductsList'
import { Modules } from '@redant/retailos-ui'
import { translations, environment } from '../../../../../config'
import media from '../../../../../config/media'
import { required } from '../../../../../validators'
import { virtualConsultationFunctions } from '../../../../../retailos/dependencies'
import { selectors as appointmentsSelectors } from '../../../../../store/modules/appointments'
import { selectors as authSelectors } from '../../../../../store/modules/auth'

import { apps } from '../../../../../config'
import { ProductRouter } from '../../../../../retailos/ProductRouter'
import { useSelector } from 'react-redux'

const messageIcon = getImage('messageDarkIcon')

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const ContentWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: 100px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #CECECE;
  background-color: #F5F5F5;
`

const DetailsContent = styled.div`
  padding: 10px;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  ${media.greaterThan('md')`
    padding: 5px 24px 14px 24px;
  `}
`
const FormContent = styled.div`
  
`

const ServiceLabel = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 13px;
  background-color: #000000;
  color: #ffffff;
  ${media.greaterThan('md')`
    padding: 8px 24px;
  `}
`

const CustomLink = styled(ButtonLink)`
  font-size: 13px;
  color: rgba(0,0,0,0.87);
`

const ViewProfileLink = styled(CustomLink)`
  text-decoration: underline;
  padding-left:8px;
`

const CopyConsulationLink = styled(CustomLink)`
  margin-top: 33px;
`

const BookingInfo = styled.p`
  white-space: pre-wrap;
`

const EditAppointmentForm = Form('edit-appointment')

const EditAppointmentFormStyled = styled(EditAppointmentForm)`
  display: flex;
  flex-direction: column;
`
const CustomFormBody = styled(FormBody)`
  display: flex;
  flex-direction: column;
`

const CustomFormControl = styled(FormControl)`
  width: 100%;
`
const ThematicBreakLine = styled.hr`
  border-top: 1px solid #D3D3D3;
`

const ButtonsContainerStyled = styled(ButtonsContainer)`
  margin-top: 18px;
`
const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 12px;
`

const DetailsInfo = styled(P)`
  margin-right: 10px;
  margin-bottom: 10px;
`

const SmallHeading = styled(P)`
  margin-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
`

const AppointmentEditScreen = ({
  id,
  externalAppointmentId,
  type,
  userFullName,
  customerFullName,
  when,
  customer,
  details,
  user,
  notes,
  service,
  bookingNotes,
  onCustomerMessageClick,
  onCopyLinkClick,
  onCustomerProfileClick,
  onSaveAppointmentClick,
  onCancelAppointmentClick,
  bookingTelephone,
  saveConsultationItems,
  calendarName
}) => {
  const title = type === 'VIRTUAL_CONSULTATION' ? 'Virtual consultation' : 'Consultation'
  const customerLinkPath = `/consultations/virtual/${externalAppointmentId || id}/customer`
  const customerLinkUrl = environment.CUSTOMER_FACING_URL ? `${environment.CUSTOMER_FACING_URL}${customerLinkPath}` : `${window.location.origin}${customerLinkPath}`
  const appointment = useSelector(appointmentsSelectors.getAppointmentById(id))
  const getIsMessagingAllowedForRole = useSelector(authSelectors.getIsMessagingAllowedForRole)
  const contents = _.get(appointment, 'details.items', [])
  const initialItems = Modules.VirtualConsultation.Implementation.VirtualConsultationFunctions.mapConsultationItemsToSelectectedItems(_.get(details, 'items', []))
  const openProductModal = () => {
    return modalService.open({
      component: ProductRouter,
      initialItems,
      fullScreen: true,
      overflowHidden: true,
      multiSelect: true,
      noRouting: true,
      modalIndex: 1,
      featureType: 'consultation',
      onSelected: (fullProducts) => {
        const consultationItems = fullProducts.map(
          virtualConsultationFunctions.mapFullProductToConsultationItem
        )
        saveConsultationItems({ id, consultationItems })
        modalService.close({ modalIndex: 1 })
      }
    })
  }

  return (
    <Container>
      <H2 value={_.toUpper(translations(title))} />
      <ContentWrapper>
        <EditAppointmentFormStyled onSubmit={(data) => onSaveAppointmentClick({ id, ...data })} initialValues={{ user, notes }}>
          <CustomFormBody>
            <DetailsContainer>
              {service && (
                <ServiceLabel>
                  {_.toUpper(service)}
                </ServiceLabel>
              )}
              <DetailsContent>
                <Grid container>
                  <Grid item>
                    <DetailsInfoContainer>
                      <H3 value={_.toUpper(customerFullName)} />
                      {getIsMessagingAllowedForRole ? <IconButton
                        onClick={() => onCustomerMessageClick(customer)}>
                        <img alt='message-icon' src={messageIcon} />
                      </IconButton> : null}
                      {apps.CUSTOMER_MANAGEMENT ? <ViewProfileLink onClick={() => onCustomerProfileClick(customer)}>{translations('View profile')}</ViewProfileLink>
                        : null}
                    </DetailsInfoContainer>
                    <DetailsInfoContainer>
                      <DetailsInfo value={_.capitalize(when)} />
                      <DetailsInfo value={`${translations('Contact Number')}: ${bookingTelephone}`} />
                      <DetailsInfo value={`${translations('Calendar')}: ${calendarName}`} />
                    </DetailsInfoContainer>
                  </Grid>
                </Grid>
              </DetailsContent>
            </DetailsContainer>
            {
              bookingNotes !== null && bookingNotes !== '' ?
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <H3 value={translations('Booking Info')} />
                    <BookingInfo>{_.trimStart(bookingNotes)}</BookingInfo>
                    <ThematicBreakLine />
                  </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <H3 value={translations('Booking Info')} />
                    <BookingInfo>{translations('No Booking Info')}</BookingInfo>
                    <ThematicBreakLine />
                  </Grid>
                </Grid>
            }
            <FormContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <CustomFormControl>
                    <SelectUser
                      name='user'
                      validate={required}
                      label={`${translations('Appointment with')}:`}
                      buttonLabel={`${translations('Search user')}`} />
                    {type === 'VIRTUAL_CONSULTATION' && (
                      <CopyConsulationLink onClick={() => onCopyLinkClick(customerLinkUrl)}>{translations('Copy consultation link')}</CopyConsulationLink>
                    )}
                  </CustomFormControl>
                </Grid>
              </Grid>
              <ThematicBreakLine />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <CustomFormControl>
                    <Input
                      label={translations('Appointment notes')}
                      name='notes'
                      multiline
                      rows={5}
                      rowsMax={10} />
                  </CustomFormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SmallHeading value={`${translations('Consultation products')}:`} />
                  <AppointmentProductsList
                    appointmentId={id}
                    disabled={appointment.status !== 'UNKNOWN'}
                    addItems={() => openProductModal()}
                    items={contents}
                  />
                </Grid>
              </Grid>
            </FormContent>
          </CustomFormBody>
          <ButtonsContainerStyled foldOnMobile align='right' buttonDirection='column'>
            <Button
              type={'submit'}
              color='primary'
              buttonType='primary'>
              {`${translations('Save Appointment')}`}
            </Button>
            <Button
              buttonType='primary'
              onClick={onCancelAppointmentClick}>
              {`${translations('Cancel Appointment')}`}
            </Button>
          </ButtonsContainerStyled>
        </EditAppointmentFormStyled>
      </ContentWrapper>
    </Container>
  )
}

AppointmentEditScreen.propTypes = {
  id: PropTypes.string.isRequired,
  externalAppointmentId: PropTypes.string,
  type: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  customerFullName: PropTypes.string.isRequired,
  when: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notes: PropTypes.string,
  service: PropTypes.string,
  onCustomerMessageClick: PropTypes.func,
  onCopyLinkClick: PropTypes.func,
  onCustomerProfileClick: PropTypes.func,
  onSaveAppointmentClick: PropTypes.func,
  onCancelAppointmentClick: PropTypes.func
}

export default AppointmentEditScreen
