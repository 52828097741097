import React from 'react'
import PropTypes from 'prop-types'
import StockLevelIndicator from '../../../../../components/StockLevelIndicator'

const InStoreStock = ({
  stockProps
}) => {
  const { statusIcon, stockStatus } = stockProps
  return (
    <StockLevelIndicator label={stockStatus} icon={statusIcon} />
  )
}

InStoreStock.propTypes = {
  stockProps: PropTypes.shape({
    stockIcon: PropTypes.node,
    stockStatus: PropTypes.string
  }).isRequired
}

export default (InStoreStock)
