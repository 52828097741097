const style = theme => ({
  orderProductContentContainer: {
    display: 'grid',
    marginTop: 30,
    gridTemplateColumns: 'repeat(3,1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: 'repeat(1,1fr)'
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2,1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3,1fr)'
    },
    gridGap: '40px'
    // xs sm md
  }
})

export default style
