import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import ButtonLink from '../../../components/ButtonLink'
import { getImage } from '../../../components/Images'
import style from './style'
import { getCustomerFullName } from '../../../helpers'

const FollowingListItem = props => {
  const { firstName, lastName, classes, anonymised, sendMessage, isMessagingEnabled, onClick } = props
  const fullName = getCustomerFullName({ firstName, lastName, anonymised })

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <ButtonLink className={classes.name} onClick={onClick}>{fullName}</ButtonLink>
      </div>
      <div className={classes.right}>
        {
          isMessagingEnabled
            ? (
              <IconButton
                aria-label='Send message'
                onClick={sendMessage}
                className={classes.messageIconButton}
              >
                <img src={getImage('messageDarkIcon')} className={classes.messageIcon} />
              </IconButton>
            )
            : null
        }
      </div>
    </div>
  )
}

export default withStyles(style)(FollowingListItem)
