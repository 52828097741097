const style = theme => ({
  tabRoot: {
    fontWeight: 'bold',
    opacity: '1 !important',
    marginRight: 10,
    marginLeft: 10,
    maxWidth: 'none'
  },
  tabLabel: {
    ...theme.h2,
    textTransform: 'uppercase'
  },
  container: {
    marginTop: 20,
    padding: '0 20px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 28% 10px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 35% 10px'
    }
  }
})

export default style
