import { compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { withRouter } from 'react-router'

import { feedbackTypes, translations } from '../../../config'

import FeedbackScreen from './FeedbackScreen'

const pathnameToConfigNameMap = {
  '/feedback': 'general'
}

export default compose(
  withRouter,
  withPropsOnChange(
    ['location'],
    ({ location }) => {
      const configName = pathnameToConfigNameMap[location.pathname]
      const typeOptions = feedbackTypes[configName].map(type => {
        return {
          value: type,
          label: translations(_.startCase(type))
        }
      })
      const initialValues = typeOptions.length === 1
        ? { feedbackType: typeOptions[0].value }
        : undefined
      return {
        typeOptions,
        initialValues
      }
    })
)(FeedbackScreen)
