import React from 'react'

import CustomerSearch from '../../../Customer/CustomerSearch'
import FollowingListItem from '../../../Customer/FollowingListItem'
import AlphabetList from '../../../../components/AlphabetList'
import Container from '../../../../components/Container'

const listItemProps = { noMessageButton: true }

const ProductDetailCustomerSearch = props => {
  const { following, ...rest } = props
  return (
    <CustomerSearch {...rest}>
      <Container followingList>
        <AlphabetList
          items={following}
          onClickItem={item => () => props.onCustomerClick(item)}
          ItemComponent={FollowingListItem}
          sortKey='firstName'
          itemProps={listItemProps}
        />
      </Container>
    </CustomerSearch>
  )
}

export default ProductDetailCustomerSearch
