import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import style from '../style'

const AddressList = ({
  classes,
  addressList,
  handleItemClick
}) => {
  return (
    <Paper className={classes.paperContainer}>
      {addressList.map(({ text, description, id }) => {
        return <MenuItem key={id} onClick={() => handleItemClick(id)}>
          {text}, {description}
        </MenuItem>
      })}
    </Paper>
  )
}

AddressList.propTypes = {
  toggleList: PropTypes.func.isRequired,
  addressList: PropTypes.arrayOf(PropTypes.object),
  searchFullAddress: PropTypes.func.isRequired,
  changeFormField: PropTypes.func.isRequired
}

export default withStyles(style)(AddressList)
