import React, { Component } from 'react'
import currencies from 'currency-formatter/currencies.json'
import _ from 'lodash'

import Input from './Input'
import { currencyCode as defaultCurrencyCode } from '../../../config'

class InputContainer extends Component {
  // Fixes a bug which caused fields "labels" to not float when the value is changed programmatically.
  // By focusing and then immediately bluring it.
  componentWillReceiveProps (nextProps) {
    if (nextProps.value && !this.props.value) {
      let activeElement = document.activeElement
      if (activeElement !== this.ref) {
        setTimeout(() => {
          this.ref.focus()
          this.ref.blur()
          activeElement.focus()
        }, 0)
      }
    }
  }

  handleChange = (e) => {
    const { type, negative } = this.props
    const value = e.target ? e.target.value : e
    let processedValue = value
    if (type === 'number' && value) {
      processedValue = value.replace(/\D/g, '')
    }
    if (type === 'price' && value) {
      processedValue = (negative ? '-' : '') + value.replace(/[^\d\.]/g, '')
    }
    return processedValue
  }

  getValue = () => {
    const { value, type, currencyCode = defaultCurrencyCode } = this.props
    if (type === 'price') {
      const currencySymbol = _.get(currencies, [currencyCode, 'symbol'])
      return currencySymbol + ' ' + value
    } else {
      return value
    }
  }

  getRef = (ref) => {
    if (ref) {
      this.ref = ref
    }
  }

  onChange = (e) => {
    this.props.onChange(this.handleChange(e))
  }

  onBlur = (e) => {
    this.props.onBlur(this.handleChange(e))
  }

  _generateProps = () => ({
    ...this.props,
    onBlur: this.onBlur,
    onChange: this.onChange,
    getRef: this.getRef,
    value: this.getValue()
  })

  render () {
    const props = this._generateProps()
    return <Input {...props} />
  }
}

InputContainer.defaultProps = {
  onBlur: () => {}
}

export default InputContainer
