import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import FullScreenLoader from '../../../components/FullScreenLoader'
import ResourcesScreen from './ResourcesScreen'
import { selectors as resourcesSelectors, actions as resourcesActions } from '../../../store/modules/resources'

const mapStateToProps = state => {
  const resourcesLoading = resourcesSelectors.getIsResourcesLoading(state)
  const linkLoading = resourcesSelectors.getIsLinkLoading(state)
  return {
    isLoading: resourcesLoading || linkLoading,
    resources: resourcesSelectors.getResources(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    viewResource: ({ dispatch }) => ({ key }) => {
      dispatch(resourcesActions.fetchResource({ key }))
    }
  }),
  FullScreenLoader
)(ResourcesScreen)
