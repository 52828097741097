const style = theme => ({
  container: {
    padding: '10px 20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  formControlContainer: {
    width: '100%',
    maxWidth: 450,
    [theme.breakpoints.up('md')]: {
      maxWidth: 550
    }
  },
  totalText: {
    flexShrink: 0,
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: 20
    }
  }
})

export default style
