import theme from '../../config/theme'

const styles = {
  root: {
    margin: 0,
    padding: 0
  },
  content: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.offsetAccent.main,
    color: theme.palette.primary.main
  },
  error: {
    color: theme.palette.error.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  default: {
    color: theme.palette.darkGrey.main
  },
  close: {
    backgroundColor: 'transparent'
  },
  SnackbarContent: {
    backgroundColor: 'transparent'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 10
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    paddingLeft: 10
  }
}

export default theme => styles
