const styles = (theme) => ({
  container: {
    padding: 15,
    maxWidth: '50%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%'
    }
  },
  ratioContainer: {
    width: '100%'
  },
  heading: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
    margin: '15px 0',
    width: '100%',
    fontWeight: 'bold'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
})

export default styles
