import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import FullMessageList from './FullMessageList'
import { selectors as customerDetailsSelectors } from '../../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../../store/modules/createMessage'
import { selectors as messagesSelectors, actions as messagesActions } from '../../../../store/modules/customerMessages'
import { actions as messageDetailsActions } from '../../../../store/modules/messageDetails'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import analyticsService from '../../../../services/analyticsService'
import { getCustomerFullName } from '../../../../helpers/getFullName'
import modalService from '../../../../services/modalService'

import MessageDetailsScreen from '../../../Message/MessageDetailsScreen'

const mapStateToProps = state => {
  const customerDetailIsLoading = customerDetailsSelectors.getInitialCustomerDetailIsLoading(state)
  const messagesIsLoading = messagesSelectors.getIsLoadingInitial(state)
  const customer = customerDetailsSelectors.getCustomer(state)
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)

  return {
    customer,
    customerFullName: getCustomerFullName(customer),
    messages: messagesSelectors.getResults(state),
    isLoading: messagesIsLoading || customerDetailIsLoading,
    hasMore: messagesSelectors.getHasMore(state),
    customerId: customerDetailsSelectors.getCustomerId(state),
    isMessagingAllowedForRole
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    fetchMessages: ({ dispatch, isLoading, customerId }) => () => {
      if (!isLoading) {
        dispatch(messagesActions.fetchMessagesForCustomerNext({ id: customerId }))
      }
    },
    pushToMessageScreen: ({ dispatch, customer }) => () => {
      analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'fullMessageList' })
      dispatch(createMessageActions.updateReceipient(customer))
    },
    goToMessage: ({ dispatch }) => ({ id }) => {
      dispatch(messageDetailsActions.resetStore())
      dispatch(messageDetailsActions.fetchMessageDetails({ id }))
      modalService.open({
        component: MessageDetailsScreen,
        fullScreen: true,
        onGoBackClick: () => modalService.close()
      })
    }
  })
)(FullMessageList)
