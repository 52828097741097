import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 30,
  page: 1,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  includeDeactivated: false,
  roleIds: [],
  storeIds: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_USER_FRESH:
      return reducers.searchUserFresh(state, action)
    case constants.SEARCH_USER_NEXT:
      return reducers.searchUserNext(state, action)
    case constants.UPDATE_USER_IN_LIST:
      return reducers.updateUserInList(state, action)
    default:
      return state
  }
}
