import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import NoteIcon from '@material-ui/icons/Note'

import { getFullName } from '../../../helpers'
import { translations } from '../../../config'

const NoteActivityContainer = (Activity) => ({ activity, onActivityClick }) => {
  const { firstName: userFirstName, lastName: userLastName } = activity.user
  const { customer } = activity
  const text = _.template(translations('Had a note added by name'))({ name: getFullName(userFirstName, userLastName) })

  return (
    <Activity
      text={text}
      icon={NoteIcon}
      customer={customer}
      date={activity.meta.timestamp}
      onActivityClick={onActivityClick}
      activityType={activity.activityType}
    />
  )
}

NoteActivityContainer.propTypes = {
  activity: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired,
    meta: PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date)
    }),
    activityType: PropTypes.string.isRequired,
    customer: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onActivityClick: PropTypes.func.isRequired
}

export default NoteActivityContainer
