import { useSelector } from 'react-redux'

import { getRegions, getError, getIsLoading } from '../store/modules/regions/selectors'

/**
 * @description returns regions
 * @returns
 */
export const useResults = () => {
  return useSelector(getRegions)
}

/**
 * @description returns regions error
 * @returns
 */
export const useError = () => {
  return useSelector(getError)
}

/**
 * @description returns if the store is currently loading more reports
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}
