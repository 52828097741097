const styles = (theme) => ({
  feedback: {
    background: theme.palette.background.main,
    height: '10%',
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  top: {
    top: 0
  },
  bottom: {
    bottom: 0
  },
  icon: {
    marginRight: '.2rem'
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.actionedGreen.main
  },
  statusMessage: {
    color: theme.palette.text.main
  }
})

export default styles
