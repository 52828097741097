import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { getImage } from '../../../Images'

import style from './style'

const basketImage = getImage('basketIcon')

const OrderIcon = ({ classes }) => {
  return (
    <img src={basketImage} className={classes.image} />
  )
}

OrderIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired
}

export default withStyles(style)(OrderIcon)
