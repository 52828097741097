import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../../config'
import { getFullName } from '../../../helpers'

const ProductCarouselActivityContainer = (Activity) => ({ activity, onActivityClick }) => {
  const { customer = {}, user = {}, meta = {} } = activity
  const { firstName: userFirstName, lastName: userLastName } = user
  const { fieldName, imageUrl } = meta
  const text = _.template(translations('fieldName was updated by name'))({ fieldName, name: getFullName(userFirstName, userLastName) })

  return (
    <Activity
      text={text}
      imageUrl={imageUrl}
      customer={customer}
      date={activity.meta.timestamp}
      onActivityClick={onActivityClick}
      type={activity.activityType}
      activityType={activity.activityType}
    />
  )
}

ProductCarouselActivityContainer.propTypes = {
  activity: PropTypes.shape({}).isRequired,
  onActivityClick: PropTypes.func.isRequired
}

export default ProductCarouselActivityContainer
