const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '10px'
  }
})

export default styles
