import React from 'react'

import Editable from '../../../../components/EditableFields/Editable'
import { Email } from '../../../../components/Fields'

import EmailDisplay from './EmailDisplay'

const EditableEmail = Editable({
  input: ({ classes, ...props }) => {
    return (
      <Email {...props} />
    )
  },
  display: props => <EmailDisplay {...props} />
})

export default EditableEmail
