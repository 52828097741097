import formStyle from '../../../components/Form/style'

const styles = {
  field: {
    // maxWidth: '330px',
    width: '100%'
  },
  centerButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 7,
    fontWeight: 600
  },
  fullWidthButton: {
    width: '100%'
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column'
  }
}

export default theme => ({
  ...formStyle(theme),
  ...styles
})
