import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { isInvalid } from 'redux-form'

import withCheckoutFlow from '../withCheckoutFlow'
import CouponCode, { formId } from './CouponCode'

export default compose(
  withCheckoutFlow,
  connect(state => ({
    formInvalid: isInvalid(formId)(state)
  })),
  withHandlers({
    onSubmit: ({ next, updateOrder }) => formData => {
      const { couponCode } = formData
      const updateOrderParams = {
        details: { couponCode }
      }
      updateOrder(updateOrderParams)
      next()
    }
  })
)(CouponCode)
