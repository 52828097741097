import _ from 'lodash'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import Stores from './Stores'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as storeSelectors } from '../../../../../store/modules/stores'
import { selectors as rolesSelectors } from '../../../../../store/modules/roles'

const selector = formValueSelector('roles-and-stores')

const mapStateToProps = state => {
  const selectedRoleId = selector(state, 'roleId') || ''
  const selectedRole = rolesSelectors.getRoleById(selectedRoleId)(state)
  // can be an array or a single id
  const selectedStoreIds = selector(state, 'storeIds') || []
  const formattedSelectedStoreNames = _.isArray(selectedStoreIds)
    ? storeSelectors.getStoreNamesTruncatedDisplay(selectedStoreIds)(state)
    : authSelectors.getUserStoreFromId(state, selectedStoreIds).name
  return {
    selectedRole,
    storeOptions: authSelectors.getUserStoresOptions(state),
    formattedSelectedStoreNames,
    isMultiSelector: selectedRole ? selectedRole.multipleStores : undefined
  }
}

export default connect(
  mapStateToProps
)(Stores)
