const style = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.lightGrey.main
  },
  gallerySelectImage: {
    height: 150,
    width: 150
  },
  galleryItem: {
    padding: 10
  },
  galleryContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '68px 30px',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    height: '100%'
  },
  closeContainer: {
    position: 'fixed',
    top: 20,
    right: 20,
    zIndex: 3
  }
})

export default style
