import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined,
  following: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_FOLLOWING:
      return reducers.fetchFollowing(state, action)
    case constants.ADD_TO_FOLLOWING:
      return reducers.addToFollowing(state, action)
    case constants.REMOVE_FROM_FOLLOWING:
      return reducers.removeFromFollowing(state, action)
    default:
      return state
  }
}
