import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import LockIcon from '@material-ui/icons/Lock'

import { Input } from '../../../components/Fields'
import { selectors as authSelectors } from '../../../store/modules/auth'
import * as offlinePasswordHandler from '../../../helpers/offlinePasswordHandler'
import Container from '../../../components/Container'
import Form, { FormBody, FormError, FormSubmit } from '../../../components/Form'
import { translations } from '../../../config'
import * as validators from '../../../validators'

const TitleContainer = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const TitleIcon = styled(LockIcon)`
  margin: 0 5px 0 0;
`

const Title = styled.p`
  margin: 0;
`

const SubmitWrap = styled.div`
  margin-top: 15px;
`

const LoginForm = Form('store-pin-entry')

const StorePinEntryScreen = ({ success = _.noop }) => {
  const currentStorePin = useSelector(authSelectors.getUserSelectedStorePin)

  const onSubmit = async ({ storePin }) => {
    const matched = offlinePasswordHandler.compare({
      password: storePin,
      offlinePasswordHash: currentStorePin
    })

    if (matched) {
      return success()
    } else {
      throw new Error('Incorrect PIN')
    }
  }

  return (
    <Container>
      <TitleContainer>
        <TitleIcon />
        <Title>{translations('Screen Locked')}</Title>
      </TitleContainer>
      <LoginForm onSubmit={onSubmit}>
        <FormError error={'ERROR'} />
        <FormBody>
          <FormControl>
            <Input
              type={'password'}
              label={'Store PIN'}
              name={'storePin'}
              autoCapitalize='none'
              autoComplete='off'
              autoCorrect='off'
              spellCheck='off'
              validate={validators.required}
            />
          </FormControl>
        </FormBody>
        <SubmitWrap>
          <FormSubmit fullWidth label={translations('Unlock')} />
        </SubmitWrap>
      </LoginForm>
    </Container>
  )
}

export default StorePinEntryScreen
