import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { isInvalid } from 'redux-form'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import withCheckoutFlow from '../../withCheckoutFlow'
import PaymentDummy, { formId } from './PaymentDummy'

export default compose(
  withCheckoutFlow,
  // storeId and userId are needed for uploadOrder
  connect(state => ({
    storeId: authSelectors.getUserSelectedStoreId(state),
    userId: authSelectors.getActiveUserId(state),
    formInvalid: isInvalid(formId)(state)
  })),
  withHandlers({
    onSubmit: ({ next, updateOrder }) => formData => {
      const { paymentToken } = formData
      const updateOrderParams = {
        paymentToken
      }
      updateOrder(updateOrderParams)
      next()
    },
    skipPayment: ({ next, updateOrder }) => formData => {
      updateOrder()
      next()
    }
  })
)(PaymentDummy)
