import { connect } from 'react-redux'

import { selectors as followingSelectors } from '../../../../store/modules/following'

import ProductDetailCustomerSearch from './ProductDetailCustomerSearch'

const mapStateToProps = state => {
  return {
    following: followingSelectors.getFollowing(state)
  }
}

export default connect(mapStateToProps)(ProductDetailCustomerSearch)
