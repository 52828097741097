import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'

import { actions as inspirationsActions } from '../../../../../store/modules/inspirations'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import Inspiration from './Inspiration'

const mapStateToProps = state => ({
  canSendMessage: authSelectors.getIsMessagingAllowedForRole(state)
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['inspiration'], ({ inspiration }) => {
    const { galleries = [] } = inspiration
    const defaultGallery = _.find(galleries, gallery => gallery.default === true) || {}
    const firstGallery = galleries[0] || {}
    const defaultGalleryMedia = defaultGallery.media || firstGallery.media || []
    return {
      defaultGallery: defaultGalleryMedia || [],
      messageContent: {
        ..._.omit(inspiration, ['galleries']),
        type: 'inspiration',
        images: defaultGalleryMedia.map((image = {}) => image.src)
      }
    }
  }),
  withHandlers({
    onTagClick: ({ dispatch }) => tagId => {
      dispatch(inspirationsActions.searchInspirationsFresh({ tagId }))
    },
    onInspirationClick: ({ dispatch, inspiration, onInspirationClick, messageContent, push }) => () => {
      if (onInspirationClick) {
        onInspirationClick(messageContent)
      } else {
        push(`/inspirations/${inspiration.id}`)
      }
    },
    onInspirationCTAClick: ({ dispatch, onInspirationClick, inspiration, messageContent, defaultGallery }) => event => {
      event.stopPropagation()
      if (onInspirationClick) {
        onInspirationClick(messageContent)
      } else {
        dispatch(createMessageActions.addContentToMessage({ content: [{ type: 'inspiration', ...inspiration }] }))
      }
    }
  })
)(Inspiration)
