import _ from 'lodash'

import { translations } from '../config'

export const getDeliveryText = (deliveryOption) => {
  const { name, deliveryType } = deliveryOption || {}
  if (!deliveryType && !name) return '-'
  const deliveryTypeText = _.template(translations('deliveryType Delivery'))({ deliveryType: _.startCase(deliveryType) })
  const deliveryOptionText = (
    name
    ? `(${translations(name)})`
    : ''
  )
  return `${deliveryTypeText} ${deliveryOptionText}`
}

export const getDeliveryPrice = deliveryOption => _.get(deliveryOption, 'price')
