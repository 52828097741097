import { connect } from 'react-redux'
import _ from 'lodash'
import { withHandlers, compose } from 'recompose'

import EmailDisplay from './EmailDisplay'
import { selectors as customerDetailsSelectors } from '../../../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import analyticsService from '../../../../../services/analyticsService'
import { apps } from '../../../../../config'

const mapStateToProps = (state) => {
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const currentCustomerDetails = customerDetailsSelectors.getCustomer(state)

  const isMessagingEnabled = isMessagingAllowedForRole && !currentCustomerDetails.anonymised && apps.MESSAGING
  return {
    currentCustomerDetails,
    isMessagingEnabled
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onActivityClick: ({ dispatch, currentCustomerDetails }) => () => {
      analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'customerProfile' })
      dispatch(createMessageActions.updateReceipient(currentCustomerDetails))
      dispatch(createMessageActions.updateMessage({ communicationType: 'email' }))
    }
  })
)(EmailDisplay)
