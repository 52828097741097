import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../../../components/Button'
import { translations, findInOtherLocationsEnabled } from '../../../../../config'
import style from './style'
import { Modules } from '@redant/retailos-ui'
import { productCatalogueFunctions } from "../../../../../retailos/dependencies";


const StockInOtherStores = ({ classes, currentStoreId, currentVariantId, openNoVariantSelected, openStoresList }) => {
  return (
    findInOtherLocationsEnabled ? 
    renderFindInOtherLocations({classes, currentStoreId, currentVariantId, openNoVariantSelected}) : 
      <div className={classes.locationContainer}>
        <Button
          onClick={openStoresList}
          className={classes.storeLink}
          iconImage={'locationIcon'}
          hyperlink
          children={translations('Find in other stores')}
        />
      </div>
  )
}

const renderFindInOtherLocations = ({classes, currentStoreId, currentVariantId, openNoVariantSelected}) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const closeDrawer  = () => {
    setDrawerOpen(false)
  }
  const implementation = Modules.Product.Implementation.provideUseVariantStoreStock({
    productFunctions: productCatalogueFunctions,
    currentVariantId,
    currentStoreId
  })

  const onClick = currentVariantId ? () => setDrawerOpen(true) : openNoVariantSelected;

  return (
    <>
      <div className={classes.locationContainer}>
        <Button
          onClick={onClick}
          className={classes.storeLink}
          iconImage={'locationIcon'}
          hyperlink
          children={translations('Find in other stores')}
        />
      </div>
      <Modules.Product.Components.FindInOtherLocationsSidebar 
        implementation={implementation} 
        drawerOpen={drawerOpen} 
        closeDrawer={closeDrawer}
      />
    </>
  )
}

export default withStyles(style)(StockInOtherStores)
