import { connect } from 'react-redux'
import { change } from 'redux-form'
import RecentUsers from './RecentUsers'
import { selectors as authSelectors, actions as authActions } from '../../../store/modules/auth'

const mapStateToProps = state => ({
  users: authSelectors.getRecentUsers(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: username => {
    // Focus the password field
    document.querySelector('input[type=password]').focus()

    // Update the login form
    dispatch(change('login', 'username', username))
    dispatch(change('login', 'password', ''))
    ownProps.onClick && ownProps.onClick(username)
  },
  onRemove: id => {
    dispatch(authActions.removeRecentUser({ id }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentUsers)
