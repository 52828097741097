import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import cx from 'classnames'
import style from './style'

/** Spreads props to [MUI input](https://material-ui.com/api/input/) */
export const DatePicker = ({
  label,
  classes,
  onChange,
  onBlur,
  clearInput,
  meta: { touched, error } = {},
  ...rest
}) => {
  const inputClasses = cx(classes.textField, {
    [classes.textFieldPadding]: window.cordova && rest.value
  })

  const ClearButton = ({ onClick }) => (
    <div className={classes.clearButtonContainer}>
      <div
        className={classes.clearButton}
        onClick={onClick}
      />
    </div>
  )

  return (
    <div className={classes.inputContainer}>
      <InputLabel shrink>{label}</InputLabel>
      <Input
        type='date'
        onChange={onChange}
        onBlur={onBlur}
        className={inputClasses}
        {...rest}
      />
      {touched && error ? <FormHelperText error>{error}</FormHelperText> : null}
      {rest.value && <ClearButton onClick={clearInput} />}
    </div>
  )
}

DatePicker.propTypes = {
  classes: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  clearInput: PropTypes.func
}

export default withStyles(style)(DatePicker)
