import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import PollsQuestionsList from './PollsQuestionsList'
import { selectors as pollQuestionsSelectors } from '../../../store/modules/pollQuestions'

const mapStateToProps = state => {
  const dailyQuestions = pollQuestionsSelectors.getDailyQuestions(state)
  const weeklyQuestions = pollQuestionsSelectors.getWeeklyQuestions(state)
  const monthlyQuestions = pollQuestionsSelectors.getMonthlyQuestions(state)
  const isLoading = pollQuestionsSelectors.getIsLoading(state)
  return {
    dailyQuestions,
    weeklyQuestions,
    monthlyQuestions,
    isLoading
  }
}
export default compose(
  connect(mapStateToProps),
  withHandlers({
  })
)(PollsQuestionsList)
