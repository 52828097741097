const sharedStyles = {
  productInnerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const style = theme => ({
  productsContainer: {
    position: 'relative',
    border: `1px solid ${theme.palette.contentBoxGrey.main}`,
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    }
  },
  singleProductContainer: {
    ...sharedStyles.productInnerContainer
  },
  multipleProductContainer: {
    ...sharedStyles.productInnerContainer,
    alignItems: 'flex-start'
  },
  singleProductImage: {
    height: '100%',
    maxWidth: '80%',
    objectFit: 'cover'
  },
  gridContainer: {
    height: '100%'
  },
  productGridItem: {
    height: 283,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      height: '50%'
    }
  },
  moreIcon: {
    width: '50%'
  },
  op3: {
    opacity: 0.3
  }
})

export default style
