import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { compose } from 'recompose'

import SubHeader from '../../../components/SubHeader'
import FormContentBox from '../../../components/FormContentBox'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'

import WrappedFormContentBox from './WrappedFormContentBox'
import LatestNote from './LatestNote'
import LatestMessage from './LatestMessage'
import RecommendedProducts from './RecommendedProducts'
import Loyalty from './Loyalty/OrderList'
import CommunicationOptions from './CommunicationOptions'
import Wishlist from './Wishlist'
import CustomerWaitlist from './CustomerWaitlist'

import AppointmentsList from '../../ConsultationsV2/AppointmentsDashboardScreen/AppointmentsListing/AppointmentsList'

import { translations, getAppConfig } from '../../../config'
import TopRightButton from '../../../components/TopRightButton'
import { getCustomerFullName } from '../../../helpers'
import RequiredTextMessage from '../../../components/RequiredTextMessage'

const PersonalDetailsForm = FormContentBox('personal-details')
const CustomerDetailsForm = FormContentBox('customer-details')

const ViewCustomerFormWrapper = ({
  classes,
  initialValues = {},
  isLoadingInitialData,
  onSubmit,
  onSubmitWishlist,
  onSubmitRecommendedProducts,
  onCustomerLoyaltySubmit,
  onCommunicationSubmit,
  onSubmitAppointments,
  isCustomerEditable,
  onCustomerPreferencesSubmit,
  waitlistInitialValues,
  onSubmitWaitlist,
  customerFirstName,
  onActionsClick,
  exit,
  appointments,
  isConsultationMode,
  registeredStoreSchema
}) => {
  const { firstName, lastName, anonymised } = initialValues || {}
  const fullName = getCustomerFullName({ firstName, lastName, anonymised })
  let layout = getAppConfig('CUSTOMER_MANAGEMENT', 'viewCustomerScreenLayout')

  let customerSchema = schema.customer.schema
  let personalSchema = [
    ...schema.personal.schema,
    registeredStoreSchema
  ]

  if (isConsultationMode) {
    customerSchema = customerSchema.filter((record) => { return record.id !== "preferredSalesAssistant"});
    personalSchema = personalSchema.map((record) => { 
      if (record.id == "email") {
        record.props.showEmailButton = false;
        return record;
      } else if (record.id == "telephone") {
        record.props.showSmsButton = false;
        record.props.showPhoneButton = false;
        return record;
      } else {
        return record;
      }
    } )
    layout = layout.map((record) => {
      if (record.type == "LOYALTY" || record.type == "RECOMMENDED_PRODUCTS" || record.type == "WISHLIST" || record.type == "WAITLIST" || record.type == "MESSAGES" || record.type == "APPOINTMENTS_LISTING" ) {
        record.editable = "false";
      }

      return record;
    })
  }
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar onClick={exit} />
        )}
        centerContent={(
          <H2 value={_.toUpper(fullName)} />
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredFollowButtonContainer}>
          <div className={classes.buttonsContainer}>
            {!isConsultationMode?<TopRightButton onClick={onActionsClick} id='customer-profile-actions'>{translations('Actions')}</TopRightButton>:<div/>}
          </div>
          <RequiredTextMessage />
        </div>

        {layout.map(item => {
          if (!item.enabled) return null

          let itemEditable = isCustomerEditable && (typeof item.editable == "undefined" || item.editable !== "false")

          switch (item.type) {
            case 'PERSONAL_DETAILS':
              return (<WrappedFormContentBox
                component={PersonalDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onSubmit}
                schema={personalSchema}
                layout={schema.personal.layout}
                boxName={translations('Personal details')}
                formId='personal-details'
                editable={itemEditable}
              />)
            case 'CUSTOMER_PREFERENCES':
              return (customerSchema) && (<WrappedFormContentBox
                component={CustomerDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onCustomerPreferencesSubmit}
                schema={customerSchema}
                layout={schema.customer.layout}
                boxName={translations(item.heading)}
                formId='customer-details'
                editable={isCustomerEditable}
              />)
            case 'NOTES':
              return (<LatestNote customer={initialValues} editable={isCustomerEditable} formId='notes'/>)
            case 'APPOINTMENTS_LISTING':
              return (<AppointmentsList appointments={appointments} noResultsText={translations('No appointments')} editable={itemEditable} formId='appointments-listing'/>)
            case 'LOYALTY':
              return (<Loyalty onSubmit={onCustomerLoyaltySubmit} initialValues={initialValues} editable={itemEditable} formId='loyalty'/>)
            case 'COMMS_OPTIONS':
              return (<CommunicationOptions
                boxName={translations('Communication Options')}
                formId='communicationOptions'
                initialValues={initialValues}
                onSubmit={onCommunicationSubmit}
                editable={isCustomerEditable}
              />)
            case 'MESSAGES':
              return (<LatestMessage customer={initialValues} editable={itemEditable} formId='messages'/>)
            case 'RECOMMENDED_PRODUCTS':
              return (<RecommendedProducts
                boxName={translations(item.heading, { customerFirstName })}
                formId='recommendedProducts'
                initialValues={initialValues}
                onSubmit={onSubmitRecommendedProducts}
                editable={itemEditable}
              />)
            case 'WISHLIST':
              return (<Wishlist
                boxName={translations('Wishlist')}
                formId='wishlist'
                initialValues={initialValues}
                onSubmit={onSubmitWishlist}
                editable={itemEditable}
              />)
            case 'WAITLIST':
              return (<CustomerWaitlist
                boxName={translations('Waitlist')}
                formId='waitlist'
                initialValues={waitlistInitialValues}
                onSubmit={onSubmitWaitlist}
                editable={itemEditable}
              />)
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

ViewCustomerFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default compose(
  FullScreenProgress,
  withStyles(styles)
)(ViewCustomerFormWrapper)
