import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const AutoCompleteField = ({
  options,
  label,
  onChange,
  value,
  passedProps,
  ...rest
}) => {
  const handleOnChange = (event, newValue) => {
    onChange(newValue)
  }
  return (
    <Autocomplete
      value={value}
      inputValue={value}
      id={passedProps.name}
      freeSolo
      options={options}
      onChange={handleOnChange}
      onInputChange={handleOnChange}
      renderInput={(params) => {
        return (
          <TextField {...params} label={passedProps.label} />
        )
      }}
    />
  )
}

export default AutoCompleteField
