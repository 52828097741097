import { connect } from 'react-redux'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import UsersSearchScreen from './UsersSearchScreen'

const mapStateToProps = (state, ownProps) => {
  const hideBottomBar = ownProps.hideBottomBar || !authSelectors.getHasUserManagement(state)
  return {
    hideBottomBar
  }
}

export default connect(mapStateToProps)(UsersSearchScreen)
