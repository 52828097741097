import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'
import Hidden from '@material-ui/core/Hidden'
import Button from '../Button'
import style from './style'
import classNames from 'classnames'

const BackBar = ({ onClick, className, classes }) => (
  <div className={classNames(classes.container, className)} >
    <Button
      type='button'
      onClick={onClick}
      className={classes.button}
    >
      <div className={classes.arrow} />
      <Hidden xsDown>
        <span className={classes.buttonText}>{translations('Back')}</span>
      </Hidden>
    </Button>
  </div>
)

BackBar.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default withStyles(style)(BackBar)
