import React from 'react'
import { translations } from '../../config'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../Button'
import style from './style'
import { getImage } from '../Images'

const FullButtonScreen = props => {
  const { onClose, classes, width, onFullScreen } = props
  return (
    <div className={classes.fullScreenContainer}>
      {/* <Button
        buttonType='secondary'
        className={classes.fullScreenButton}
        onClick={onFullScreen}
        children={useMediaQuery(theme => theme.breakpoints.down('xs')) ? null : translations('Full Screen')}
      /> */}
      <IconButton onClick={onClose}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  )
}

export default withStyles(style)(FullButtonScreen)
