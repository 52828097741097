import { compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import PropTypes from 'prop-types'

import OrderKanban from './OrderKanban'

const EnhancedOrderKanban = compose(
  withPropsOnChange(
    ['orders', 'ordersByStatus'],
    ({ orders, ordersByStatus }) => {
      if (!ordersByStatus) {
        return { ordersByStatus: _.groupBy(orders, 'status') }
      }
    }
  ),
  withPropsOnChange(
    ['ordersByStatus', 'columns'],
    ({ ordersByStatus, columns }) => {
      // if columns prop not provided, generate it from the
      // ordersByStatus prop
      if (!columns) {
        columns = _.keys(ordersByStatus)
          .map(status => {
            return {
              statuses: [status],
              title: status
            }
          })
      }

      // ordersByColumn is a 2D array of order objects.
      // it puts orders into columns, depending on the 'columns'
      // prop.
      const ordersByColumn = columns.map(column => {
        const {
          statuses,
          orderBy = 'orderDate',
          sortOrder,
          groupByStatus
        } = column || {}

        if (groupByStatus) {
          return _.chain(statuses)
            .map(status => {
              return _.orderBy((ordersByStatus[status] || []), orderBy, sortOrder)
            })
            .flatten()
            .value()
        } else {
          return _.chain(statuses)
            .map(status => (ordersByStatus[status] || []))
            .flatten()
            .orderBy(orderBy, sortOrder)
            .value()
        }
      })
      return { ordersByColumn, columns }
    }
  ),
  withPropsOnChange(
    ['columns'],
    ({ columns }) => {
      const selectOptions = columns.map((column, i) => {
        const { title } = column
        return { label: title, value: i }
      })
      return { selectOptions }
    }
  )
)(OrderKanban)

EnhancedOrderKanban.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      statuses: PropTypes.arrayOf(PropTypes.string),
      orderBy: PropTypes.function,
      groupByStatus: PropTypes.bool
    })
  )
}

export default EnhancedOrderKanban
