export default theme => ({
    pageTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      padding: 4,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      },
      '& > div': {
        padding: 11
      }
    },
    searchButtonStyle: {
      alignSelf: 'center',
      fontWeight: 600,
      marginBottom: 10,
  
      [theme.breakpoints.up('sm')]: {
        marginRight: 10,
        marginBottom: 0
      }
    },
    contextBoxTitle: {
      marginRight: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        whiteSpace: 'initial',
        lineHeight: 1.5
      },
      textTransform: 'uppercase'
    },
    selfSend: {
      marginBottom: 10,
      marginTop: 20,
  
      [theme.breakpoints.up('sm')]: {
        marginRight: 10,
        marginBottom: 0
      }
    },
    dropdownFilter: {
        width: '100%'
    },
  })
  