import { connect } from 'react-redux'
import { withHandlers, compose, withState } from 'recompose'
import _ from 'lodash'
import { selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { selectors as appSelectors } from '../../../store/modules/app'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import BasketScreen from './BasketScreen'
const mapStateToProps = state => {
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  const isOpen = appSelectors.getIsCustomerReviewBasketOpen(state)
  const isEmpty = currentOrderSelectors.getCurrentOrderProductTotal(state) === 0
  const res = {
    isOpen,
    isEmpty,
    ..._.pick(order, [
      'groupedProducts',
      'subTotal',
      'numberOfProducts',
      'numberOfPurchaseableProducts'
    ])
  }
  return res
}

export default compose(
  connect(mapStateToProps, null),
  withState('tabValue', 'changeTabValue', 'basket')
)(BasketScreen)
