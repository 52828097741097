/**
 * RetailOS Client Extensi../components/PrivateRoute
 * This folder should not be alter../components/TimedLogoutlaced by retailer specific extensions to the
 * client using the bootstap functionality.
 * If you wish to extend the RetailOS client for a specific retailer, this can be achieved by copying this
 * folder into the retailer bootstap folder and then added your customer components / containers, etc...
 *
 * The index.js of your folder should return an array of either Route or PrivateRoute components that will
 * be added to the root application after all other RetailOS core componements - this means it can also be
 * used to override core routes.
*/

import React from 'react'
import { Route } from 'react-router'

import Example from './containers/Example'

export default [
  <Route key='/ext/example' path='/ext/example' exact component={Example} />
]
