import React from 'react'

import BarGraph from '../../../components/Charts/BarGraph'
import CountChart from '../../../components/Charts/CountChart'
import DonutChart from '../../../components/Charts/DonutChart'
import ListChart from '../../../components/Charts/ListChart'
import withReportData from '../withReportData'

const ReportChart = props => {
  const { reportSchema, chartData, listData, currency, target, seeAllLink, listItem } = props
  const { title, type: chartType, chartOptions, ignoreNoPurchaseItems = false } = reportSchema

  switch (chartType) {
    case 'BAR':
      return <BarGraph
        data={chartData}
        chartTitle={title}
        layout='vertical'
        chartAspect={1.2}
        currency={currency}
        {...chartOptions}
      />
    case 'DONUT':
      return <DonutChart
        data={chartData}
        chartTitle={title}
        currency={currency}
        target={target}
        {...chartOptions}
      />
    case 'PIE':
      return <DonutChart
        data={chartData}
        chartTitle={title}
        currency={currency}
        pie
        {...chartOptions}
      />
    case 'LIST':
      return <ListChart
        data={listData}
        chartTitle={title}
        currency={currency}
        seeAllLink={seeAllLink}
        listItem={listItem}
        ignoreNoPurchaseItems={ignoreNoPurchaseItems}
        {...chartOptions}
      />
    case 'COUNT':
      return <CountChart
        data={chartData}
        chartTitle={title}
        currency={currency}
        {...chartOptions}
      />
    default:
      return null
  }
}

export default withReportData(ReportChart)
