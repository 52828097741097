import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormBody, FormError } from '../../../../components/Form'
import Editable from '../../../../components/Editable'

const WrappedFormContentBox = ({
  component: Component,
  initialValues,
  classes,
  onSubmit,
  schema,
  layout,
  editing,
  toggleEdit,
  formId,
  boxName,
  isSubmitting,
  editable = true
}) => {
  const specificInitialValues = _.pick(
    initialValues,
    [...schema.map(field => field.props.name), 'id']
  )

  return (
    <Component
      enableReinitialize
      initialValues={specificInitialValues}
      givenClass={classes.formContainer}
      onSubmit={onSubmit}
      boxName={boxName}
      formId={formId}
      editable={editable}
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
    >
      <FormError givenClass={classes.submitError} />
      <FormBody
        schema={schema}
        layout={layout}
        globalClasses={{
          col: classes.columnStyle,
          row: classes.rowStyle
        }}
      />
    </Component>
  )
}

WrappedFormContentBox.propTypes = {
  component: PropTypes.elementType,
  initialValues: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    field: PropTypes.string.isRequired,
    props: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    classes: PropTypes.shape({
      formContainer: PropTypes.string,
      submitError: PropTypes.string,
      columnStyle: PropTypes.string,
      rowStyle: PropTypes.string
    })
  })),
  layout: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ])),
    PropTypes.string
  ])),
  editing: PropTypes.bool,
  toggleEdit: PropTypes.func.isRequired,
  formId: PropTypes.string,
  boxName: PropTypes.string,
  isSubmitting: PropTypes.bool,
  editable: PropTypes.bool
}

export default Editable(WrappedFormContentBox)
