import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import H3 from '../../../../components/H3'
import List from '../../../../components/List'
import BackBar from '../../../../components/BackBar'
import MultiSelectBar from '../../common/MultiSelectBar'

import SubCategoryLink from './SubCategoryLink'
import style from './style'

class SubCategories extends Component {
  render () {
    const { classes, category, categories, goBack, push, multiSelect } = this.props
    const { image = 'https://placeimg.com/290/280/any/sepia', name } = category
    const rowProps = { push }
    return (
      <div
        className={classes.container}
      >
        <div className={classes.leftPanel}>
          <div className={classes.leftPanelHeader}>
            <div className={classes.leftPanelHeaderSide}>
              <BackBar onClick={goBack} />
            </div>
            <H3
              value={_.toUpper(name)}
              className={classes.leftPanelHeaderMiddle}
            />
            <div className={classes.leftPanelHeaderSide} />
          </div>
          <List
            ItemComponent={SubCategoryLink}
            items={categories}
            itemProps={rowProps}
            noMargin
          />
        </div>
        <div className={classes.rightPanel}>
          <img className={classes.image} src={image} />
        </div>
        {multiSelect && <MultiSelectBar {...this.props} />}
      </div>
    )
  }
}

export default withStyles(style)(SubCategories)
