import { compose, withState, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { history } from '../../../store'
import { getAppConfigForCurrentRoute, kanban as defaultConfig } from '../../../config'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import StoreroomKanbanScreen from './StoreroomKanbanScreen'

import moment from 'moment'

import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../../../store/modules/auth'

const _fetchOrdersByStatus = (props) => {
  const config = props.config
  const statuses = config.columns.reduce((memo, column) => memo.concat(column.statuses), [])

  props.setIsLoading(true)
  return Promise.all(statuses.map(status => {
    let params = {
      storeId: props.loggedInStoreId
    }
    if (status === 'complete' || status === 'cancelled') {
      const startDate = moment().subtract((config.completeOrdersDaysVisible - 1), 'days').startOf('day').toISOString()
      console.log(`Showing orders from ${startDate} (${config.completeOrdersDaysVisible} days ago)`)
      params = { ...params, startDate }
    }
    return digitalStoreSdk.orders.searchOrders({ status, orderType: 'storeroom', ...params })
      .then(resp => _.get(resp, 'results'))
  }))
    .then(ordersPerStatus => {
      return _.zipObject(statuses, ordersPerStatus)
    }).then(results => {
      props.setOrdersByStatus(results)
    }).finally(() => props.setIsLoading(false))
}

const mapStateToProps = (state) => {
  const selectedColumn = appSelectors.getStoreroomKanbanSelectedColumn(state)
  const loggedInStoreId = authSelectors.getUserSelectedStoreId(state)
  return {
    loggedInStoreId,
    selectedColumn
  }
}

const mapDispatchToProps = (dispatch) => {
  const onChangeColumn = (e) => dispatch(appActions.changeStoreroomKanbanSelectedColumn({
    selectedColumn: e.target.value
  }))
  const goToOrder = (orderNumber) => dispatch(push(`/orders/${orderNumber}?actionsFor=${history.location.pathname}`))

  return {
    onChangeColumn,
    goToOrder
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange([], (props) => {
    const config = { ...defaultConfig, ...getAppConfigForCurrentRoute() }
    return { config }
  }),
  withState('ordersByStatus', 'setOrdersByStatus', {}),
  withState('isLoading', 'setIsLoading', false),
  withState('query', 'setQuery', ''),
  withState('debouncedQuery', 'setDebouncedQuery', ''),
  withPropsOnChange([], (props) => {
    const { setDebouncedQuery } = props
    const searchKanban = _.debounce(setDebouncedQuery, 250)
    return { searchKanban }
  }),
  withPropsOnChange(
    ['ordersByStatus', 'debouncedQuery'],
    (props) => {
      const { ordersByStatus, debouncedQuery } = props
      // this handles order filtering by search query
      if (debouncedQuery) {
        const getIsMatch = (str) => ((str || '').indexOf(debouncedQuery) > -1)
        const newOrdersByStatus = _.mapValues(ordersByStatus, orders => {
          return (orders || []).filter(order => {
            const orderNumberMatch = getIsMatch(_.get(order, 'orderNumber'))
            if (orderNumberMatch) return true
            const productIdMatch = _.reduce(_.get(order, 'products', []), (result, product) => {
              return result || getIsMatch(_.get(product, 'externalProductId'))
            }, false)
            if (productIdMatch) return true
            return false
          })
        })
        const ordersList = _.chain(newOrdersByStatus)
          .values()
          .flatten()
          .value()
        return {
          ordersByStatus: newOrdersByStatus,
          ordersList
        }
      }
    }
  ),
  withHandlers({
    onChangeQuery: ({ searchKanban, setQuery }) => (e) => {
      const query = e.target.value
      searchKanban(query)
      setQuery(query)
    }
  }),
  withPropsOnChange([], (props) => {
    let refreshTimer = props.refreshTimer
    const fetchOrders = () => _fetchOrdersByStatus(props)

    // Inital order load and auto-refresh
    fetchOrders()
    if (props.config.screenRefreshRateSeconds && !refreshTimer) {
      refreshTimer = setInterval(fetchOrders, Math.max(props.config.screenRefreshRateSeconds, 10) * 1000)
    }

    return { fetchOrders, refreshTimer }

  }),
  lifecycle({
    componentWillUnmount () {
      if (this.props.refreshTimer) {
        clearInterval(this.props.refreshTimer)
      }
    }
  })
)(StoreroomKanbanScreen)
