import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ScrollContainer = props => {
  const { children, classes } = props

  return (
    <div className={classes.scrollContainer}>
      {children}
    </div>
  )
}

ScrollContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(ScrollContainer)
