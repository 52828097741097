const styles = {
  container: {
    position: 'relative'
  },
  inner: {
    width: '100%'
  },
  box: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
}

export default theme => styles
