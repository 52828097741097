const style = theme => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonStyle: {
    width: 14,
    height: 27
  }
})

export default theme => style(theme)
