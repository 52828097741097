import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import InspirationsRouter from './InspirationsRouter'
import ViewInspirationScreen from './ViewInspirationScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/inspirations' path='/inspirations' component={TimedLogoutComponent(InspirationsRouter)} />
]
