import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import style from './style'
import ResultRow from '../ResultRow'
import P from '../../../components/P'
import { getImage } from '../../../components/Images'
import InfiniteScroll from 'react-infinite-scroller'
import { translations } from '../../../config'
import Grid from '@material-ui/core/Grid'
import FullScreenLoader from '../../../components/FullScreenLoader'

const filterIcon = getImage('filterIcon')

const _getFilterText = (filterCount) => {
  let filterText = translations('Filter')
  if (filterCount === 1) {
    filterText = `${filterCount} ${translations('Filter Active')}`
  } else if (filterCount > 1) {
    filterText = `${filterCount} ${translations('Filters Active')}`
  }
  return filterText
}

class PollsResultsList extends PureComponent {
  renderTotals = () => {
    const { totalResults, filterCount, classes } = this.props

    return filterCount > 0 && (
      <P className={classes.filterResultsText} value={`${totalResults} ${translations('Results')}`} />
    )
  }

  renderFilters = () => {
    const { classes, onFilterClick, filterCount } = this.props
    const filterText = _getFilterText(filterCount)
    return (
      <Fragment>
        <div className={classes.filterContainer} onClick={onFilterClick}>
          <img className={classes.filterIcon} src={filterIcon} />
          <P className={classes.filterText} value={filterText} />
        </div>
        {this.renderTotals()}
      </Fragment>
    )
  }

  renderList = () => {
    const { classes, results } = this.props
    return _.chain(results)
      .map(result => {
        return (
          <ResultRow key={result.id} result={result} />
        )
      })
      .value()
  }

  render = () => {
    const { classes, hasMore, loadMore } = this.props

    return (
      <div className={classes.container}>
        {this.renderFilters()}
        <InfiniteScroll
          initialLoad={false}
          hasMore={hasMore}
          loadMore={loadMore}
          useWindow={false}
        >
          <div className={classes.listContainer}>
            <Grid container spacing={2}>
              {this.renderList()}
            </Grid>
          </div>
        </InfiniteScroll>
      </div>
    )
  }
}

export default compose(
  withStyles(style),
  FullScreenLoader
)(PollsResultsList)
