import textContentStyle from '../../../../components/Form/TextContent/style'
import formStyles from '../../../../components/Form/style'

const styles = {
  registrationButton: {
    width: '330px',
    height: '50px',
    marginBottom: '15px',
    marginTop: '15px',
    fontWeight: 600
  }
}

export default styles

export const formStyle = theme => ({
  ...formStyles,
  ...textContentStyle,
  registrationButton: styles.registrationButton
})
