const styles = theme => ({
  container: {
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  messageForwardingText: {
    margin: 0
  },
  messageForwardingIcon: {
    margin: '0 10px',
    fontSize: 16,
    width: 16,
    height: 16
  }
})

export default theme => styles(theme)
