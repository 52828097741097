import React, { Component } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { getImage } from '../Images'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import { placeholderImage } from '../../config'
import ImageBox from '../ImageBox'
import style from './style'

class Image extends Component {
  state = {
    status: 'LOADING'
  }

  onLoad = () => {
    this.setState({ status: 'LOADED' })
  }

  onError = () => {
    this.setState({ status: 'ERROR' })
  }

  onNewSrc = () => {
    const image = document.createElement('img')
    image.src = this.props.src
    image.onload = this.onLoad
    image.onerror = this.onError
  }

  componentDidMount() {
    this.onNewSrc()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.onNewSrc()
    }
  }

  render() {
    const { src, box, failedSrc, loadingSrc, noLoadingSpinner, className, containerClassName, style, classes, alt = '', onClick = _.noop, ...rest } = this.props
    const { status } = this.state
    let imgSrc = loadingSrc
    if (status === 'ERROR') imgSrc = failedSrc
    if (status === 'LOADED') imgSrc = src

    return (
      box
        ? <ImageBox {...rest} className={className} src={imgSrc} />
        : <div onClick={onClick} className={classNames(classes.imageContainer, containerClassName)}>
          <img
            src={imgSrc}
            className={classNames(classes.image, className)}
            style={style}
            alt={alt}
          />
          {
            status === 'LOADING' && !noLoadingSpinner
              ? <CircularProgress size={50} className={classes.loadingCircle} />
              : null
          }
        </div>
    )
  }
}

Image.defaultProps = {
  loadingSrc: getImage('blankImg'),
  failedSrc: getImage(placeholderImage)
}

export default withStyles(style)(Image)