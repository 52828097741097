const styles = theme => ({
  timeWrapper: {
    width: '100%',
    display: 'inline-flex',
    marginBottom: '-20px'
  },
  timeFieldWrapper: {
    width: '50%',
    minWidth: 0
  },
  dropdownRoot: {
    width: '100%'
  },
  marginLeft: {
    marginLeft: '20px'
  },
  errorStyle: { color: theme.palette.error.main },
  floatingLabelFocusStyle: { color: theme.palette.accent.main }
})

export default styles
