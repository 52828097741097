import { createSelector } from 'reselect'
import _ from 'lodash'

const getActiveCustomerId = state => _.get(state, 'customerDetails.result.id', [])
export const getActivities = state => _.get(state, 'activities.results', [])
export const getActivitiesStatus = state => state.activities.status

export const getActivitiesByCustomer = createSelector([
  getActivities, getActiveCustomerId
], (activities, activeCustomerId) => {
  return activities.filter(activity => activeCustomerId === activity.customerId)
})
