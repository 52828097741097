const styles = theme => ({
  statusButton: {
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  statusButtonIcon: {
    width: 16,
    height: 16,
    background: 'green',
    borderRadius: '100%',
    marginRight: 10
  }
})

export default theme => styles(theme)
