import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const TableCell = ({ xs, sm, md, lg, children = [], classes, truncate, className, alignItems, justify, direction, headerCell, ...rest }) => {
  const tableCellClass = classNames({
    [classes.hideForExtraSmall]: xs === 0,
    [classes.hideForSmall]: sm === 0,
    [classes.cell]: true,
    [classes.truncate]: true
  })
  return (
    <Grid role={headerCell ? 'columnheader' : 'cell'} className={`${tableCellClass} ${className}`} item xs={xs} sm={sm} md={md} lg={lg} alignItems={alignItems} justify={justify} direction={direction}>
      {children && children.length && children.length > 15 ? (
        <div className={classes.truncate}>
          {children}
        </div>
      ) : (
        <div>
          {children}
        </div>
        )
      }
    </Grid>
  )
}

TableCell.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.object.isRequired,
  truncate: PropTypes.bool,
  className: PropTypes.string,
  alignItems: PropTypes.string,
  justify: PropTypes.string,
  direction: PropTypes.string,
  headerCell: PropTypes.bool
}

export default withStyles(styles)(TableCell)
