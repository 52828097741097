import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  channelId: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANNEL_JOINED:
      return reducers.channelJoined(state, action);
    case constants.SET_CHAT_CHANNEL_ID:
      return reducers.setChatChannelId(state, action)
    case constants.DELETE_CHAT_CHANNEL:
      return reducers.deleteChatChannel(state, action)
    default:
      return state
  }
}
