import React, { PureComponent } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import style from './style'
import BasketItem from '../BasketItem'

class BasketGrid extends PureComponent {
  renderGridItems = () => {
    const {
      editableQuantities,
      editablePrices,
      textType,
      gap,
      products,
      classes,
      goToProduct,
      silent,
      isConsultationReviewScreenOpen
    } = this.props

    const itemProps = {
      editableQuantities,
      editablePrices,
      textType,
      gap,
      products
    }
    return _.chain(products || [])
      .map((product, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index} className={classes.gridItem}>
            <div className={classes.clickable} onClick={goToProduct(product, isConsultationReviewScreenOpen)}>
              <BasketItem
                silent={silent}
                customerFacing
                grid
                {...product}
                {...itemProps}
              />
            </div>
          </Grid>
        )
      }).value()
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Grid container>
          {this.renderGridItems()}
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(BasketGrid)
