import _ from 'lodash'

import { promiseReducer } from '../../util'
import { compactObject } from '../../../helpers'
import { SUCCESS } from '../../middleware/redux-promise'

class UserMessagesReducers {
  getIsFlagged = result => !!result.flagId
  formatResults = results => {
    return results.map(result => {
      return {
        ...result,
        isFlagged: this.getIsFlagged(result)
      }
    })
  }
  searchMessagesFresh = promiseReducer(
    // SUCCESS
    (state, action) => {
      const { result = {} } = action
      const { results, total } = result
      return {
        ...state,
        total,
        results: this.formatResults(results)
      }
    },
    // PENDING
    (state, action) => {
      const { payload = {} } = action
      return {
        ...state,
        ...compactObject(payload)
      }
    }
  )
  searchMessagesNext = promiseReducer(
    (state, action) => {
      const { page, results: currentResults } = state
      const { result = {} } = action
      const { results, total } = result
      const newResults = [...currentResults, ...results]
      return {
        ...state,
        page: ((page || 0) + 1),
        total,
        results: this.formatResults(newResults)
      }
    }
  )
  addFlagToMessageById = (state, action) => {
    if (action.status === SUCCESS) {
      const id = _.get(action, 'payload.messageId')
      const results = _.get(state, 'results', []).map(result => {
        let formattedResult = result
        if (result.id === id) {
          formattedResult.isFlagged = true
        }
        return formattedResult
      })
      return {
        ...state,
        results
      }
    }
    return state
  }
}

export default new UserMessagesReducers()
