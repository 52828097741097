import { connect } from 'react-redux'
import _ from 'lodash'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import AddDiscountModal, { discountTypes, formId } from './AddDiscountModal'
import { compose, withHandlers, withState } from 'recompose'
import { change } from 'redux-form'

const mapStateToProps = state => {
  const currencyCode = authSelectors.getCurrencyCode(state)
  return {
    currencyCode
  }
}
export { discountTypes }

export default compose(
  connect(mapStateToProps),
  withState('discountType', 'setDiscountType', discountTypes.percent),
  withHandlers({
    clearAmount: ({ dispatch }) => () => {
      dispatch(change(formId, 'amount', ''))
    }
  })
)(AddDiscountModal)
