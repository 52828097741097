import _ from 'lodash'

import { sumValues, getItemAggregateValuesFactory } from './helpers'
import { getTransformedData } from './transforms'

const formatValue = (value, type, opts) => {
  if (type === 'seconds') {
    const hours = Math.floor(value / 3600)
    const minutes = Math.floor((value % 3600) / 60)
    const seconds = Math.round(value % 60)
    let formattedValue = ''
    if (hours) formattedValue = formattedValue + hours + 'h '
    if (minutes) formattedValue = formattedValue + minutes + 'm '
    if (seconds) formattedValue = formattedValue + seconds + 's '
    return _.trimEnd(formattedValue)
  } else {
    return value
  }
}

const _getAggregateData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { aggregateFrom, transform } = data

  if (aggregateFrom) {
    const getItemValues = getItemAggregateValuesFactory(props)
    let aggregateData = items.reduce(
      (acc, item) => {
        const base = { ...acc }
        const additional = getItemValues(item)
        if (additional) {
          Object.keys(additional).forEach(key => {
            base[key] = sumValues(additional[key], base[key])
          })
        }
        return base
      },
      {}
    )
    if (transform) {
      aggregateData = getTransformedData({ transform, props, aggregateData })
    }
    _.forEach(aggregateFrom, pathSpec => {
      const { path, label, type, ...opts } = pathSpec || {}
      if (type && aggregateData[label]) {
        aggregateData[label] = formatValue(aggregateData[label], type, opts)
      }
    })
    return aggregateData
  }
}
const getAggregateData = _.memoize(_getAggregateData)

export default getAggregateData
