import passwordPolicyConfig from '../passwordPolicy.config'

/**
 * Use this to store state/config from the deployment repo.
 */

class DeploymentConfig {
  constructor () {
    this.passwordPolicyConfig = passwordPolicyConfig
    this.userPersonalDetailsConfig = null
  }

  getPasswordPolicy () {
    return this.passwordPolicyConfig
  }

  setPasswordPolicy (newPasswordPolicy) {
    this.passwordPolicyConfig = newPasswordPolicy
  }

  getUserPersonalDetailsConfig () {
    return this.userPersonalDetailsConfig
  }

  setUserPersonalDetailsConfig (newUserPersonalDetailsConfig) {
    this.userPersonalDetailsConfig = newUserPersonalDetailsConfig
  }
}

export default new DeploymentConfig()
