import formStyle from '../style'
import textContentStyles from '../../../../../components/Form/TextContent/style'

const styles = theme => ({
  communicationWrapper: {
    flexDirection: 'column',
    display: 'flex',
    margin: '10px 0 20px 0',
    width: '100%'
  },
  noMarginLeft: {
    marginLeft: 0
  },
  checkboxMargin: {
    marginLeft: -16
  },
  storesContainer: {
    width: '100%',
    marginTop: '-20px'
  },
  fullWidth: {
    width: '100%'
  },
  generalMarketing: {
    marginLeft: 0
  }
})

export default theme => ({
  ...formStyle,
  ...styles(theme),
  ...textContentStyles
})
