import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'

const FixedRatioContainer = ({
  children,
  containerClass,
  ratio,
  classes
}) => {
  return (
    <div className={`${classes.container} ${containerClass}`}>
      <div className={classes.inner} style={{paddingTop: `${ratio * 100}%`}}>
        <div className={classes.box}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default withStyles(style)(FixedRatioContainer)
