export default theme => ({
  screen: {
    paddingLeft: '5%',
    paddingRight: '5%',
    margin: '0 auto',
    maxWidth: 1200
  },
  followingList: {
    marginTop: 20,
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up('sm')]: {
      marginTop: 30
    }
  },
  inList: {
    marginTop: 25,
    marginBottom: 25
  },
  withMarginTop: {
    marginTop: 30
  },
  withMarginBottom: {
    marginBottom: 30
  }
})
