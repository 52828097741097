import { translations } from '../config'
import formatDate from './formatDate'

const formatCustomerSearchColumns = (customerSearchColumns, dateFormat) => {
  return customerSearchColumns.map(column => {
    return {
      ...column,
      label: translations(column.label),
      ...(column.id === 'name' ? { format: row => `${row.firstName} ${row.lastName}` } : {}),
      ...(column.id === 'postcode' ? { format: row => row.address && row.address.postCode } : {}),
      ...(column.id === 'latestMessage' ? { 
        format: row => row.latestMessage ? formatDate(row.latestMessage, dateFormat) : '-'
      } : {})
    }
  })
}

export default formatCustomerSearchColumns
