import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import Button from '../../../components/Button'

import Dropdown from '../../../components/Fields/Dropdown'
import FullScreenProgress from '../../../components/FullScreenLoader'
import style from './style'
import { translations } from '../../../config'

const MessageFowardingScreen = ({ onContinue, users, selectedUserId, onUserInputChange, chooseUser, classes }) => (
  <div>
    <h1 className={classes.title}>{translations('Message Forwarding Title')}</h1>
    <p className={classes.description}>{translations('Message Forwarding Description')}</p>
    <Dropdown
      options={users.map(user => {
        return {
          value: user.id,
          label: `${user.firstName} ${user.lastName}`
        }
      })}
      onInputChange={onUserInputChange}
      value={selectedUserId}
      onChange={onUserInputChange}
      onBlur={onUserInputChange}
      hideClear
    />
    <Button className={classes.button} buttonType='white' onClick={() => onContinue()}>{'Skip'}</Button>
    <Button className={classes.button} disabled={!selectedUserId} buttonType='primary' onClick={() => chooseUser()}>{'Choose User'}</Button>
  </div>
)

export default FullScreenProgress(
  withStyles(style)(MessageFowardingScreen)
)
