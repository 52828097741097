import { connect } from 'react-redux'
import { goBack, goForward } from 'connected-react-router'
import HistoryNavigationHeader from './HistoryNavigationHeader'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const wrappedGoBack = () => {
    dispatch(goBack())
  }
  const wrappedGoForward = () => {
    dispatch(goForward())
  }
  const canGoback = window.history.length > 1

  return {
    ...ownProps,
    goBack: wrappedGoBack,
    goForward: wrappedGoForward,
    canGoback
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(HistoryNavigationHeader)
