import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { selectors as notificationsSelectors, actions as notificationsActions } from '../../../store/modules/notifications'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as usersActions, selectors as usersSelectors } from '../../../store/modules/users'
import { actions as userDetailsActions, selectors as userDetailsSelectors } from '../../../store/modules/userDetails'
import * as combinedNotificationsSelectors from '../../../store/modules/combinedSelectors/notificationsUserSelector'
import analyticsService from '../../../services/analyticsService'
import MessageForwardingScreen from './MessageForwardingScreen'
import * as authCombinedSelectors from '../../../store/modules/combinedSelectors/authCombinedSelectors'

const mapStateToProps = state => {
  return {
    isLoading: usersSelectors.getIsLoading(state),
    users: usersSelectors.getResults(state),
    activeStoreId: authSelectors.getUserSelectedStoreId(state)
  }
}

const mapDispatchToProps = dispatch => ({
  searchUsers: params => dispatch(usersActions.searchUsersFresh(params)),
  fetchNotifications: params => dispatch(notificationsActions.fetchUserNotificationsFresh(params))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedUserId', 'setSelectedUserId', null),
  withHandlers({
    onUserInputChange: ({ setSelectedUserId }) => e => {
      setSelectedUserId(e.target.value)
    },
    chooseUser: ({ selectedUserId, onContinue }) => () => {
      onContinue(selectedUserId)
    }
  }),
  lifecycle({
    componentDidMount () {
      const { searchUsers, activeStoreId } = this.props
      searchUsers({ storeId: activeStoreId })
    }
  })
)(MessageForwardingScreen)
