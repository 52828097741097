import { compose, withHandlers } from 'recompose'
import SelectCustomer from './SelectCustomer'
import AnonymousReasonModal from './AnonymousReasonModal'
import modalService from '../../../services/modalService'
import { getAppConfig } from '../../../config'

const basketGuestCheckoutEnabled = getAppConfig('CHECKOUT', 'basketGuestCheckout', false)

const runModal = (onComplete) => {
  modalService.open({
    component: AnonymousReasonModal,
    noRouting: true,
    exit: modalService.close,
    onClick: anonymousReason => {
      modalService.close()
      onComplete({ anonymousReason })
    }
  })
}

export default compose(
  withHandlers({
    onAnonymousCustomerClick: (componentProps) => (...passedProps) => {
      if (basketGuestCheckoutEnabled) {
        componentProps.onAnonymousCustomerClick({ ...passedProps, anonymousReason: 'unassigned' })
      } else {
        runModal(({ anonymousReason }) => {
          componentProps.onAnonymousCustomerClick({ ...passedProps, anonymousReason })
        })
      }
    },
    onUnassignedOrderClick: (props) => () => {
      props.onAnonymousCustomerClick({ anonymousReason: 'unassigned' })
    }
  })
)(SelectCustomer)
