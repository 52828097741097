const styles = {
  displayContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  emailIconPosition: {
    width: '22px'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  textStyle: {
    paddingRight: '9px'
  },
  buttonContainer: {
    border: '1px solid',
    width: '35px',
    height: '35px',
    marginLeft: '5px',
    padding: 0
  }
}

export default theme => styles
