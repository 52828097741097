import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ResourcesActions {
  fetchResources = () => ({
    type: constants.FETCH_RESOURCES,
    promise: () => {
      return digitalStoreSdk.resources
        .fetchResources()
    }
  })
  fetchResource = ({ key }) => {
    return {
      type: constants.FETCH_RESOURCE,
      promise: () => {
        return digitalStoreSdk.resources
          .fetchResource({ key: encodeURI(key) })
          .catch(error => {
            console.log({error})
            throw error
          })
      }
    }
  }
}

export default new ResourcesActions()
