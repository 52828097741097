import logoStyles from '../../../components/MainLogo/style'
import { theme } from '../../../config/theme'

const styles = {
  generalError: {
    height: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.error.main
  },
  field: {
    minWidth: '330px'
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.main,
    padding: '20px'
  },
  checkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0 0 0'
  },
  textContainer: {
    maxWidth: '330px',
    width: '100%',
    paddingTop: '20px'
  },
  forgotLinkWrapper: {
    textAlign: 'center',
    padding: '30px 0 0 0'
  },
  forgotLink: {
    fontSize: 14,
    color: '#000'
  },
  header: {
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '20px 0 0 0'
  }
}

export default theme => ({
  ...styles,
  ...logoStyles
})
