import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { getImage } from '../Images'
import P from '../P'
import AlertBadge from '../../containers/AlertBadge'
import ClickWrapper from '../../components/ClickWrapper'
import Image from '../../components/Image'
import style from './style'
import classNames from 'classnames'
import { translations } from '../../config'

/** To see an example of this component with a badge count, in storybook open the `Redux state` panel, and press `Set notification badge count` */
export class MenuWidget extends PureComponent {
  state = {
    status: 'LOADING'
  }

  onLoad = () => {
    this.setState({ status: 'LOADED' })
  }

  onError = () => {
    this.setState({ status: 'ERROR' })
  }

  onNewSrc = () => {
    const image = document.createElement('img')

    image.src = getImage(this.props.image)
    image.onload = this.onLoad
    image.onerror = this.onError
  }

  componentDidMount() {
    this.onNewSrc()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.onNewSrc()
    }
  }

  render() {
    const { status } = this.state
    const { classes, onClick, badgeType, title, image, hubTileImages } = this.props
    const tileImage = hubTileImages ? (hubTileImages[image] || getImage(this.props.image)) : getImage(this.props.image)
    const smallIcon = getImage(this.props.icon)
    const containerClasses = classNames(
      classes.container,
      {
        [classes.containerLoaded]: status === 'LOADED' || status === 'ERROR'
      }
    )
    const buttonInnerClasses = classNames(
      classes.buttonInner,
      {
        [classes.containerLoaded]: status === 'LOADED' || status === 'ERROR'
      }
    )
    return (
      <div className={containerClasses}>
        <ClickWrapper className={classes.button} onClick={onClick}>
          <div className={buttonInnerClasses}>
            <div className={classes.parentCorner}>
              <AlertBadge type={badgeType}>
                <div className={classes.childCorner}>
                  <img src={smallIcon} className={classes.smallIconImg} />
                </div>
              </AlertBadge>
            </div>
            <div className={classes.title}>
              <P value={translations(title)} type='hubTileText' className={classes.titleInner} />
            </div>
            <Image src={tileImage} noLoadingSpinner className={classes.image} />
          </div>
        </ClickWrapper>
      </div>
    )
  }
}

MenuWidget.propTypes = {
  title: PropTypes.string.isRequired,
  /** name of image as exported from `/components/images/index.js` */
  image: PropTypes.string,
  /** name of icon as exported from `/components/images/index.js` */
  icon: PropTypes.string,
  onClick: PropTypes.func,
  /** setting badgeType will set the badge count using redux selectors for the specified redux module */
  badgeType: PropTypes.oneOf(['notifications', 'basket'])
}

export default withStyles(style)(MenuWidget)
