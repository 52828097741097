export default {
  imagePicker: {
    display: 'none'
  },
  submitButtonLabelContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  submitButtonLabelIcon: {
    height: 15,
    width: 20,
    margin: '0 auto'
  },
  submitButtonLabelText: {
    marginTop: 5,
    fontSize: 12
  },
  header: {
    marginBottom: 5
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 20
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    width: '100%'
  },
  submitButtonHalf: {
    fontWeight: 'bold',
    flex: 1,
    fontSize: 14,
    minHeight: 60,
    textTransform: 'none'
  },
  buttonSpace: {
    margin: '0 10px'
  },
  validationError: {
    color:'red'
  }
}
