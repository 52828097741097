import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { compose } from 'recompose'
import InfiniteScroll from 'react-infinite-scroller'
import cx from 'classnames'

import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import List from '../../../../components/List'
import SubHeader from '../../../../components/SubHeader'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import BackBar from '../../../../components/BackBar'

import FullScreenLoader from '../../../../components/FullScreenLoader'

import style from './style'
import Message from '../../../../components/Message'
import RegistrationButton from '../../../../components/RegistrationButton'

class FullMessageList extends PureComponent {
  renderMessages = () => {
    const { messages, hasMore, fetchMessages, customerId,
      customer, customerFullName, goToMessage, classes } = this.props
    if (customer.anonymised) {
      return <div className={classes.anonContainer}><P value={translations('Latest Message Anonymised')} type='textButton' className={classes.noMessagesText} /></div>
    }
    if (messages && messages.length > 0) {
      return (
        <InfiniteScroll
          pageStart={0}
          className={classes.messagesContainer}
          loadMore={fetchMessages}
          hasMore={hasMore}
          initialLoad={false}
          useWindow={false}
        >
          <List
            items={messages}
            renderItem={message => (
              <Message
                key={message.id}
                message={message}
                fromCustomer={message.fromCustomer}
                customerId={customerId}
                truncate
                customerName={customerFullName}
                goToMessage={goToMessage}
                showButtons={false}
              />
            )}
            noBorder
            noMargin
          />
        </InfiniteScroll>
      )
    } else {
      return <div className={classes.noMessagesContainer}><P type='textButton' value={translations('No Messages')} className={classes.noMessagesText} /></div>
    }
  }

  render () {
    const { classes, customerFullName, pushToMessageScreen, customer, isMessagingAllowedForRole } = this.props

    const containerClasses = cx(
      classes.contentContainer,
      {
        [classes.contentContainerFullHeight]: !isMessagingAllowedForRole,
        [classes.hideOverflow]: customer.anonymised
      }
    )
    return (
      <Fragment>
        <div className={containerClasses}>
          <SubHeader
            leftContent={(
              <BackBar />
            )}
            centerContent={(
              <H2 value={_.toUpper(customerFullName)} />
            )}
          />
          {this.renderMessages()}
        </div>
        {
          (!customer.anonymised && isMessagingAllowedForRole)
            ? (
              <RegistrationButton onClick={pushToMessageScreen}>
                {translations('Create New Message')}
              </RegistrationButton>
            )
            : null
        }
      </Fragment>
    )
  }
}

FullMessageList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  hasMore: PropTypes.bool.isRequired,
  customerFullName: PropTypes.string.isRequired,
  fetchMessage: PropTypes.func.isRequired,
  pushToMessageScreen: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  isMessagingAllowedForRole: PropTypes.bool
}

export default compose(
  withStyles(style),
  FullScreenLoader
)(FullMessageList)
