const boxPadding = '16px 24px'

const style = theme => ({
  customerDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border.main}`,
    borderBottom: 0,
    padding: boxPadding,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  itemContainer: {
    border: `1px solid ${theme.palette.border.main}`,
    padding: boxPadding
  },
  firstContainer: {
    borderBottom: 'none',
    [theme.breakpoints.up('xs')]: {
      borderBottom: `1px solid ${theme.palette.border.main}`,
      borderRight: 'none'
    }
  },
  noPrice: {
    borderRight: `1px solid ${theme.palette.border.main}`
  }
})

export default style
