import React from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import enhanceWithClickOutside from 'react-click-outside'

import AddressList from './AddressList'

class AddressListContainer extends React.Component {
  handleClickOutside = () => {
    const { closeList } = this.props
    closeList()
  }

  handleItemClick = id => {
    const { closeList, searchFullAddress, changeFormField } = this.props
    searchFullAddress(id).then((value) => {
      for (var key in value) {
        changeFormField(`address.${key}`, value[key])
      }
      closeList()
    })
  }

  _generateProps = () => ({
    ...this.props,
    ...this.state,
    handleItemClick: this.handleItemClick,
    handleClickOutside: this.handleClickOutside
  })

  render () {
    const props = this._generateProps()
    return <AddressList {...props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeFormField (field, value) {
      return dispatch(change(ownProps.formName, field, value))
    }
  }
}

const WrappedAddressListContainer = enhanceWithClickOutside(AddressListContainer)

export default connect(null, mapDispatchToProps)(WrappedAddressListContainer)
