export const APPOINTMENT_ANALYTICS_EVENT = 'RA/currentAppointment/APPOINTMENT_ANALYTICS_EVENT'
export const START_APPOINTMENT = 'RA/currentAppointment/START_APPOINTMENT'
export const CLEAR_APPOINTMENT = 'RA/currentAppointment/CLEAR_APPOINTMENT'
export const ADD_CONTENT = 'RA/currentAppointment/ADD_CONTENT'
export const REMOVE_CONTENT = 'RA/currentAppointment/REMOVE_CONTENT'
export const TOGGLE_CONTENT_IS_SHOWING = 'RA/currentAppointment/TOGGLE_CONTENT_IS_SHOWING'
export const TOGGLE_CONTENT_IS_LOVED = 'RA/currentAppointment/TOGGLE_CONTENT_IS_LOVED'
export const CUSTOMER_TOGGLE_CONTENT_IS_LOVED = 'RA/currentAppointment/CUSTOMER_TOGGLE_CONTENT_IS_LOVED'
export const SET_STAGE = 'RA/currentAppointment/SET_STAGE'
export const SET_CUSTOMER = 'RA/currentAppointment/SET_CUSTOMER'
export const SET_CUSTOMER_DETAILS = 'RA/currentAppointment/SET_CUSTOMER_DETAILS'
export const SET_CURRENT_APPOINTMENT = 'RA/currentAppointment/SET_CURRENT_APPOINTMENT'
export const UPDATE_CURRENT_APPOINTMENT = 'RA/currentAppointment/UPDATE_CURRENT_APPOINTMENT'
export const UPDATE_APPOINTMENT_SHARED_VIEW_STATE = 'RA/currentAppointment/UPDATE_APPOINTMENT_SHARED_VIEW_STATE'
export const SET_ORDER_NUMBER = 'RA/currentAppointment/SET_ORDER_NUMBER'
export const SET_CONSULTATION_START_TIME = 'RA/currentAppointment/SET_CONSULTATION_START_TIME'
export const END_APPOINTMENT = 'RA/currentAppointment/END_APPOINTMENT'
export const SET_CUSTOMER_NAME = 'RA/currentAppointment/SET_CUSTOMER_NAME'
