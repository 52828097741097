const styles = theme => ({
  modalConatiner: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  mainImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 175,
    [theme.breakpoints.up('sm')]: {
      height: 351
    }
  },
  mainVideoContainer: {
    width: '80%',
    margin: 'auto'
  },
  mainVideoModal: {
    [theme.breakpoints.up('xl')]: {
      width: 950,
      margin: 'auto'
    }
  },
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  iframeContainer: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%'
  },
  iframeStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  scrollContainer: {
    background: '#fff',
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    marginTop: '2rem',
    padding: '10px 0 20px 0'
  },
  scrollInner: {
    display: 'flex'
  },
  imageContainer: {
    minWidth: 100,
    height: 110,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:first-child': {
      marginLeft: 'auto'
    },
    '&:last-child': {
      marginRight: 'auto'
    },
    '&:focus': {
      border: '2px solid #E0E0E0'
    }
  },
  image: {
    backgroundColor: '#eee',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  videoThumbnailContainer: {
    position: 'relative'
  },
  playIcon: {
    position: 'absolute',
    zIndex: 1
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    zIndex: 1
  },
  pinchImage: {
    maxHeight: '100%',
    width: '100%'
  },
  zoomImageContainer: {
    textAlign: 'center',
    maxWidth: '70%',
    maxHeight: '70%',
    paddingTop: 30,
    margin: 'auto',
    height: '100%',
    '& > div': {
      height: '100%'
    }
  },
  nonCordovaZoomContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  activeImage: {
    border: '2px solid #E0E0E0'
  },
  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 40,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 40,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 40
    }
  },
  mediaContainerInModal: {
    height: '100vh'
  },
  smallImageClass: {
    height: '100% !important',
    width: '100% !important',
    objectFit: 'contain'
  },
  pinchContainer: {
    height: '100%',
    overflow: 'hidden'
  }
})

export default theme => styles(theme)
