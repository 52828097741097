
import React, { useEffect } from 'react'
import { goBack } from 'connected-react-router'

import { BasketCheckout } from '../Checkout'
import modalService from '../../services/modalService'
import { useDispatch } from 'react-redux'

export const BasketCheckoutScreen = () => {
    const dispatch = useDispatch()
    const handleBack = () => {
        dispatch(goBack())
        modalService.close()
    }
    useEffect(() => {
        return modalService.open({
            // RetailOS screen config
            component: BasketCheckout,
            onBack: handleBack,
            // Modal config
            modalIndex: 0,
            fullScreen: true,
            showHeader: true
        }),
        () => {
            //Unmount prevent memory leak
            console.log(`Unmounting BasketCheckoutScreen`)
        }
    }, [])
    return (
        <></>
    )
}