const style = theme => ({
  root: {
    position: 'relative',
    '& select': {
      border: 0,
      color: 'inherit',
      backgroundColor: 'white',
      fontSize: '13px',
      fontFamily: 'inherit',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      padding: '20px 24px',
      display: 'block',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      width: '100%',
      borderRadius: 0,
      '-webkit-appearance': 'none'
    }
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    right: '24px',
    transform: 'translateY(-50%)',
    width: 0,
    height: 0,
    borderTop: '5px solid black',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent'
  }
})

export default style
