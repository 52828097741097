import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ViewGalleriesScreen from './ViewGalleriesScreen'
import { selectors as inspirationDetailsSelectors } from '../../../store/modules/inspirationDetails'
import withFullScreenLoader from '../../../components/FullScreenLoader'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  return {
    galleries: inspirationDetailsSelectors.getSkinToneGalleries(state),
    selectedGalleryIndex: inspirationDetailsSelectors.getSelectedGalleryIndex(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onCloseClick: ({ modalIndex }) => () => {
      modalService.close({ modalIndex })
    }
  }),
  withFullScreenLoader
)(ViewGalleriesScreen)
