import React, { Fragment } from 'react'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import { translations } from '../../../config'
import EventSearchInput from './EventSearchInput'
import TagPicker from './TagPicker'
import EventResults from './EventResults'

const EventsSearchScreen = ({ exit, ...rest }) => {
  // either a function passed in or undefined (will take default inside BackBar)
  const onClick = exit
  return (
    <Fragment>
      <SubHeader
        leftContent={(
          <BackBar onClick={onClick} />
        )}
        centerContent={(
          <H2 value={_.toUpper(translations('Events'))} />
        )}
      />
      <EventSearchInput />
      <TagPicker />
      <EventResults {...rest} />
    </Fragment>
  )
}

export default EventsSearchScreen
