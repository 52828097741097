import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

/** Renders a html `<button>` styled as a link */
export const ButtonLink = ({ onClick, classes, children, className }) => {
  return (
    <button type="button" onClick={onClick} className={`${classes.button} ${className}`}>{children}</button>
  )
}

ButtonLink.defaultProps = {
  className: ''
}

ButtonLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default withStyles(style)(ButtonLink)
