const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  gridContainer: {
    margin: '20px auto 0 auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  lockContainer: {
    width: '50px',
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  nameWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

export default styles
