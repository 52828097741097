export default theme => ({
  container: {
    position: 'relative',
    zIndex: 1,
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  arrow: {
    width: 0,
    height: 0,
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderRight: '5px solid black'
  },
  button: {
    fontWeight: 'bold',
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      padding: '18px'
    }
  },
  buttonText: {
    paddingLeft: 10
  }
})
