import React from 'react'
import Date from './Date'

class DateContainer extends React.Component {
  handleChange = (e, type) => {
    const { value } = e.target
    const currentDates = this.formatValue(this.props.value)
    const { day, month, year } = { ...currentDates, [type]: value || '' }
    const date = `${day}/${month}/${year}`
    // this is needed because '//' is officially a valid date in our validator
    // logic not in validator because it would throw as an invalid date rather than being empty
    // null to avoid empty string being sent through
    return date !== '//' ? date : null
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  formatValue = (value) => {
    if (value) {
      const [day, month, year] = value.split('/')
      return { day, month, year }
    } else {
      return { day: '', month: '', year: '' }
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <Date {...props} />
  }
}

export default DateContainer
