import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import _ from 'lodash'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import P from '../../../components/P'
import H3 from '../../../components/H3'
import { translations, dateFormat, timeFormat } from '../../../config'
import { formatDate } from '../../../helpers'

import styles from './style'
import { getImage } from '../../Images'

const staffBadgeIcon = getImage('staffBadgeIcon')

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

const MessageFrom = ({ classes, message, toName, fromName, fromStoreRoleInfo, toRoleInfo, hasAttachment }) => {
  const channel = translations(message.communicationType)
  const fromCustomer = _.get(message, 'fromCustomer')
  return (
    <div className={classes.contentContainer}>
      <div>
        <div className={cx(classes.iconContainer, classes.paragraphSpacing)}>
          {!fromCustomer ? <img className={classes.imageSpacing} src={staffBadgeIcon} /> : null}
          <H3 value={fromName} />
        </div>
        {fromStoreRoleInfo ? <P value={fromStoreRoleInfo} className={classes.paragraphSpacing} /> : null}
        <P value={formatDate(message.updatedAt, dateAndTimeFormat)} className={classes.paragraphSpacing} />
        <div className={cx(classes.textContainer, classes.lastSpacing)}>
          <P type='textButton' value={`${translations('To')}:`} className={classes.textSpacing} />
          <P value={toName} />
        </div>
        {toRoleInfo ? <P value={toRoleInfo} className={classes.paragraphSpacing} /> : null}
      </div>
      <div className={classes.rightContainer}>
        {hasAttachment ? <AttachFileIcon /> : null}
        <P value={channel} className={cx(classes.paragraphSpacing, classes.channel)} />
      </div>
    </div>
  )
}

MessageFrom.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  message: PropTypes.shape({}).isRequired,
  toName: PropTypes.string.isRequired,
  fromName: PropTypes.string.isRequired,
  fromStoreRoleInfo: PropTypes.string,
  toRoleInfo: PropTypes.string
}

export default withStyles(styles)(MessageFrom)
