import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { getImage } from '../Images'

const chevronIcon = getImage('chevronDownIcon')

class AccordionContent extends Component {
  state = {
    open: false
  }
  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render = () => {
    const { classes, children, title } = this.props

    return (
      <div className={classes.container}>
        <div onClick={this.toggleOpen} className={classes.accordionHeader}>
          <div>{title}</div>
          <img className={this.state.open ? classes.headerIconOpen : classes.headerIcon} src={chevronIcon} />
        </div>
        {this.state.open && (
          <div className={classes.accordionContent}>
            {children}
          </div>
          )
        }
      </div>
    )
  }
}

export default withStyles(style)(AccordionContent)
