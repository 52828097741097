import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import FormControl from '@material-ui/core/FormControl'
import { Dropdown } from '../../../../../components/EditableFields'
import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'
import style from '../../style'

const Stores = ({ selectedRole, storeOptions, editing, isMultiSelector, formattedSelectedStoreNames, classes, defaultRoleName }) => {
  if (_.get(selectedRole, 'isAdmin')) {
    return <div>{translations('All Stores')}</div>
  }
  return (
    <FormControl className={classes.dropDownStandard}>
      <Dropdown
        label={`${translations('Select Store(s)')} *`}
        name={'storeIds'}
        options={storeOptions}
        editing={editing}
        validate={[validators.storeRequired]}
        disabled={!selectedRole}
        multiple={isMultiSelector}
        displayValue={formattedSelectedStoreNames}
        classes={{root: classes.dropDownStandard}}
      />
    </FormControl>
  )
}

export default withStyles(style)(Stores)
