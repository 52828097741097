import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { actions as createMessageActions } from '../../../../store/modules/createMessage'
import analyticsService from '../../../../services/analyticsService'

const mapDispatchToProps = (dispatch) => {
  return {
    message: props => e => {
      e.stopPropagation()
      // props is the customer object
      analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'reportingScreen' })
      dispatch(createMessageActions.updateReceipient(props))
    }
  }
}

const enhance = compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    messageCustomer: ({ message, ...rest }) => message(rest)
  })
)

export default enhance
