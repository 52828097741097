import React, { PureComponent } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import currencyFormatter from '../../../formatters/currencyFormatter'
import {
  translations,
  noPrices,
  emailBasketEnabled,
  storeroomRequestEnabled,
  getAppConfig,
} from '../../../config'
import P from '../../../components/P'
import Button from '../../../components/Button'
import style from './style'

const format = currencyFormatter.format

class BasketButtons extends PureComponent {
  render() {
    const {
      classes,
      subTotal,
      numberOfPurchaseableProducts,
      clearOrder,
      checkout,
      consultation,
      referral,
      editMode,
      storeroomRequest,
      canSendEmailBasket,
      storeroomOrderId,
      saveToProfile,
      isEmpty,
      fullScreen,
      isCheckoutLoading,
      customerFacing,
      exchangeMode,
      customer,
      isConsultationMode,
      guestCheckout,
    } = this.props

    const basketGuestCheckoutEnabled = getAppConfig(
      'CHECKOUT',
      'basketGuestCheckout',
      false
    )
    const basketCustomerCheckoutEnabled = getAppConfig(
      'CHECKOUT',
      'basketCustomerCheckout',
      true
    )

    const _getCheckoutButtonText = () => {
      if (isCheckoutLoading)
        return translations('Basket Menu Checkout Button (Loading Checkout)')
      if (storeroomOrderId)
        return translations('Basket Menu Checkout Button (From Storeroom)')
      if (exchangeMode)
        return translations('Basket Menu Checkout Button (Exchange Mode)')
      if (editMode)
        return translations('Basket Menu Checkout Button (Edit Mode)')
      return translations('Basket Menu Checkout Button')
    }

    const _renderTotals = () => {
      const {
        classes,
        subTotal,
        numberOfPurchaseableProducts,
        customerFacing,
        fullScreen,
      } = this.props
      const totalClasses = {
        totals: customerFacing
          ? classes.totalContainerCustomerFacing
          : fullScreen
          ? classes.totalContainerCustomerFacing
          : classes.totalsContainer,
        singleTotal: classes.totalContainer,
        text: customerFacing
          ? classes.totalContainerTextCustomerFacing
          : fullScreen
          ? classes.totalContainerTextCustomerFacing
          : classes.totalContainerText,
      }

      const addColons = (text) => `${text}:`

      return (
        <div className={totalClasses.totals}>
          <div className={totalClasses.singleTotal}>
            <P
              className={totalClasses.text}
              type="large"
              value={addColons(translations('Items'))}
            />
            <P
              className={totalClasses.text}
              type="large"
              value={numberOfPurchaseableProducts}
            />
          </div>
          {noPrices ? null : (
            <div className={totalClasses.singleTotal}>
              <P
                className={totalClasses.text}
                type="large"
                value={addColons(translations('Sub Total'))}
              />
              <P
                className={totalClasses.text}
                type="large"
                value={format(subTotal)}
              />
            </div>
          )}
        </div>
      )
    }

    if (customerFacing) {
      return _renderTotals()
    }

    return (
      <div className={fullScreen ? classes.footerFullScreen : classes.footer}>
        {_renderTotals()}
        <div
          className={
            fullScreen
              ? classes.buttonsContainerFullScreen
              : classes.buttonsContainer
          }
        >
          <Button
            buttonType="white"
            onClick={clearOrder}
            fullWidth={!fullScreen}
            big={fullScreen}
            className={
              fullScreen ? classes.buttonFullScreen : classes.buttonWithMargin
            }
            disabled={isCheckoutLoading || isEmpty}
          >
            {translations('Clear All')}
          </Button>
          {emailBasketEnabled && !isConsultationMode && (
            <Button
              buttonType="white"
              onClick={referral}
              fullWidth={!fullScreen}
              big={fullScreen}
              className={
                fullScreen ? classes.buttonFullScreen : classes.buttonWithMargin
              }
              disabled={canSendEmailBasket}
            >
              {translations('Email basket')}
            </Button>
          )}
          {storeroomRequestEnabled && !isConsultationMode ? (
            <Button
              buttonType="white"
              onClick={storeroomRequest}
              fullWidth={!fullScreen}
              big={fullScreen}
              className={
                fullScreen ? classes.buttonFullScreen : classes.buttonWithMargin
              }
              disabled={isEmpty}
            >
              {translations('Submit Request')}
            </Button>
          ) : null}
          {!customer && basketGuestCheckoutEnabled ? (
            <Button
              buttonType="primary"
              onClick={guestCheckout}
              fullWidth={!fullScreen}
              big={fullScreen}
              className={
                fullScreen ? classes.buttonFullScreen : classes.buttonWithMargin
              }
              disabled={!!isCheckoutLoading || isEmpty}
            >
              {translations('Guest checkout')}
            </Button>
          ) : null}
          {basketCustomerCheckoutEnabled ? (
            <Button
              buttonType="primary"
              onClick={checkout}
              fullWidth={!fullScreen}
              big={fullScreen}
              className={
                fullScreen ? classes.buttonFullScreen : classes.buttonWithMargin
              }
              disabled={!!isCheckoutLoading || isEmpty}
            >
              {_getCheckoutButtonText()}
            </Button>
          ) : null}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(BasketButtons)
