import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const notificationIcon = getImage('notificationIcon')

const PendingQuestionsNotification = props => {
  const { pendingQuestionCount } = props.meta
  const { createdAt } = props
  const singleText = translations('Pending Question Notification Text')
  const pluralText = translations('Pending Questions Notification Text')
  const text = _.template(pendingQuestionCount > 1 ? pluralText : singleText)({
    pendingQuestionCount
  })
  return (
    <Notification
      {...props}
      iconSource={notificationIcon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

PendingQuestionsNotification.propTypes = {
  meta: PropTypes.shape({
    pendingQuestionCount: PropTypes.number.isRequired
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default PendingQuestionsNotification
