import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { isInvalid } from 'redux-form'

import Form from '../Form'
import FormModal from './FormModal'
import { translations } from '../../config'
import modalService from '../../services/modalService'

const defaultActions = [
  {
    success: true,
    primary: true,
    text: translations('Confirm')
  },
  {
    text: translations('Close'),
    onClick: modalService.close
  }
]

export default compose(
  connect(
    (state, ownProps) => ({
      invalid: isInvalid(ownProps.formId)(state)
    })
  ),
  withPropsOnChange(
    ['formId'],
    ({ formId }) => {
      return { FormComp: Form(formId) }
    }
  ),
  withPropsOnChange(
    ['actions'],
    ({ actions }) => {
      return {
        actions: (
          actions && actions.length
          ? actions
          : defaultActions
        )
      }
    }
  ),
  withPropsOnChange(
    ['actions', 'invalid'],
    ({ actions, invalid }) => {
      return {
        actions: actions.map(action => {
          if (action.success) {
            return { ...action, disabled: invalid }
          } else {
            return action
          }
        })
      }
    }
  )
)(FormModal)
