import { colors } from '../../../config'

const styles = theme => ({
  container: {
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    paddingBottom: 50
  },
  noResultsMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  }
})

export default styles
