import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Editable from '../Editable'
import { PhoneNumber } from '../../../components/Fields'
import { mobileCheck } from '../../../helpers'
import P from '../../P'

import styles from './style'

import PhoneIcons from './PhoneIcons'

const EditablePhoneNumber = Editable({
  input: ({ classes, ...props }) => {
    return <PhoneNumber {...props} />
  },
  display: props => {
    const { label, value, showSmsButton, showPhoneButton, classes } = props
    return (
      <div className={classes.displayContainer}>
        <div className={classes.textContainer}>
          <P key='first' value={label} type='textButton' />
          <P key='second' value={value} className={classes.textStyle} />
        </div>
        { mobileCheck ? <PhoneIcons value={value} showSmsButton={showSmsButton} showPhoneButton={showPhoneButton} /> : null}
      </div>
    )
  }
})

export default withStyles(styles)(EditablePhoneNumber)
