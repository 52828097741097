import React from 'react'
import classNames from 'classnames'

import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

const Option = props => {
  const {
    label,
    value,
    'data-value': dataValue,
    nullOption,
    divider,
    dropdownLabel,
    dropdownProps,
    isFocused, // used in AutocompleteDropdown
    isSelected: isSelectedProp, // used in AutocompleteDropdown
    classes,
    component,
    // rest is where material-ui sets props (eg onClick)
    ...rest
  } = props
  
  const { getIsSelected = () => false } = dropdownProps || {}
  const isSelected = isSelectedProp || getIsSelected(value || dataValue)
  const Component = component || 'li'
  if (dropdownLabel) {
    // LABEL OPTION
    return <option disabled value=''>{label}</option>
  } else if (nullOption) {
    // NULL OPTION
    const className = classNames(classes.option, classes.nullOption)
    return (
      <Component className={className} {...rest}>
        {label}
      </Component>
    )
  } else if (divider) {
    // DIVIDER
    return <Divider className={classes.divider} />
  } else {
    // NORMAL OPTION
    const className = classNames(classes.option, {
      [classes.focused]: isFocused,
      [classes.selected]: isSelected
    })

    return (
      <Component className={className} {...rest}>
        {label}
      </Component>
    )
  }
}

export default withStyles(style)(Option)
