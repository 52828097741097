import _ from 'lodash'
import moment from 'moment'
import { PENDING, INITIAL } from '../../middleware/redux-promise'
import { translations } from '../../../config'

import { defaultState } from './store'

export const getIsInitial = (state) => _.get(state, 'currentAppointment.status') === INITIAL
export const getIsLoading = (state) => _.get(state, 'currentAppointment.status') === PENDING
export const getIsAppointmentFinalised = (state) => getAppointmentStatus(state) === 'FINALISED'
export const getCurrentAppointment = (state) => _.get(state, 'currentAppointment', defaultState)
export const getAppointmentId = (state) => _.get(getCurrentAppointment(state), 'id')
export const getIsAppointmentActive = getAppointmentId
export const getAppointmentStage = (state) => _.get(getCurrentAppointment(state), 'stage')
export const getAppointmentOrderNumber = (state) => _.get(getCurrentAppointment(state), 'orderNumber')
export const getAppointmentType = (state) => _.get(getCurrentAppointment(state), 'type')
export const getAppointmentCustomerId = (state) => _.get(getCurrentAppointment(state), 'customerId')
export const getAppointmentCustomer = (state) => _.get(getCurrentAppointment(state), 'customer')
export const getAppointmentContents = (state) => _.get(getCurrentAppointment(state), 'contents')
export const getAppointmentContentsOfType = (type) => (state) => _.filter(_.get(getCurrentAppointment(state), 'contents'), (content) => content.type === type)
export const getAppointmentProducts = (state) => _.map(getAppointmentContentsOfType('product')(state), (productContent) => ({ ...productContent.details }))
export const getAppointmentOrderId = (state) => _.get(getCurrentAppointment(state), 'orderId')
export const getAppointmentOrder = (state) => _.get(getCurrentAppointment(state), 'order')
export const getAppointmentOrderProducts = (state) => {
  const products = _.get(getAppointmentOrder(state), 'products', [])
  return _.orderBy(products, ['service'], ['desc'])
}
export const getAppointmentStatus = (state) => _.get(getCurrentAppointment(state), 'status')

export const getIsAppointmentExpired = (state) => getAppointmentStatus(state) === 'EXPIRED'
export const getIsAppointmentComplete = (state) => getAppointmentStatus(state) === 'COMPLETE'
export const getIsAppointmentEnded = (state) => _.get(getCurrentAppointment(state), 'ended', false)
export const getAppointmentShowingContent = (state) => {
  return _.find(getAppointmentContents(state), (content) => content.isShowing)
}
export const getAppointmentUser = (state) => _.get(getCurrentAppointment(state), 'user')
export const getIsAppointmentVirtual = (state) => getAppointmentType(state) === 'VIRTUAL_CONSULTATION'

export const getAppointmentInspirations = (state) => {
  return getAppointmentContentsOfType('inspiration')(state)
}

export const getConsultationInspiration = (state) => {
  const inspirationContents = getAppointmentContentsOfType('inspiration')(state)
  const inspirationContent = _.first(inspirationContents)
  const inspiration = _.get(inspirationContent, 'details')

  const selected = !!inspiration
  const defaultLabel = 'Select an Inspiration'
  const selectedLabel = 'Inspiration Selected'
  const detail = selected ? inspiration.title : undefined
  const label = selected ? selectedLabel : defaultLabel
  return { selected, defaultLabel, selectedLabel, detail, label }
}

export const getConsultationRequestItems = (state) => {
  const requestItemContents = getAppointmentContentsOfType('inspiration')(state)
  const requestItems = _.map(requestItemContents, (content) => content.details)

  const selected = !_.isEmpty(requestItems)
  const label = selected ? 'Drink Ordered' : 'Order a Drink'
  const latestItem = _.chain(requestItems).sortBy(['orderDate']).last().value()

  const detail = (selected && latestItem) ? moment(latestItem.orderDate).format('HH:mm') : undefined
  return { selected, label, detail }
}

const _getConsultationBeforeAfterPhoto = (state, photoType) => {
  const photoContents = getAppointmentContentsOfType('photo')(state)
  const beforeAfterPhotoContent = _.findLast(photoContents, (photoContent) => _.get(photoContent, 'details.type') === photoType)
  const beforeAfterPhoto = _.get(beforeAfterPhotoContent, 'details')
  const customerId = getAppointmentCustomerId(state)

  const selected = !!beforeAfterPhoto
  const disabled = !customerId
  const detail = selected && beforeAfterPhoto ? _.get(beforeAfterPhoto, 'file.fileName') : undefined
  const blobUrl = _.get(beforeAfterPhoto, 'file.blobUrl')

  return { selected, disabled, detail, blobUrl }
}

export const getConsultationBeforePhoto = (state) => {
  const consultationBeforePhotoData = _getConsultationBeforeAfterPhoto(state, 'beforePhoto')
  const defaultLabel = 'Take a Before Photo'
  const selectedLabel = 'Before Photo Selected'
  const label = consultationBeforePhotoData.selected ? selectedLabel : defaultLabel
  return {
    ...consultationBeforePhotoData,
    label,
    defaultLabel,
    selectedLabel,
    disabledMessage: 'Customer Required'
  }
}

export const getConsultationAfterPhoto = (state) => {
  const consultationAfterPhotoData = _getConsultationBeforeAfterPhoto(state, 'afterPhoto')
  const defaultLabel = 'Take an After Photo'
  const selectedLabel = 'After Photo Selected'
  const label = consultationAfterPhotoData.selected ? selectedLabel : defaultLabel
  return {
    ...consultationAfterPhotoData,
    label,
    defaultLabel,
    selectedLabel,
    disabledMessage: 'Customer Required'
  }
}

export const getConsultationProducts = (state) => {
  const products = getAppointmentProducts(state)

  const selected = !_.isEmpty(products)
  const totalProducts = _.size(products)
  const defaultLabel = 'Add service & products used'
  const selectedLabel = '(<%= totalProducts %>) Items Added'
  const label = selected ? _.template(selectedLabel)({ totalProducts }) : defaultLabel

  return { selected, defaultLabel, selectedLabel, templateOptions: { totalProducts }, label }
}

export const getConsultationStartTime = (state) => _.get(getCurrentAppointment(state), 'consultationStartTime', new Date().toISOString())

export const getConsultantName = (state) => _.get(getCurrentAppointment(state), 'user.firstName', translations('Chat - consultant name default'))
export const getCustomerName = (state) => _.get(getCurrentAppointment(state), 'customer.firstName', translations('Chat - customer name default'))