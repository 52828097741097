import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'

import H2 from '../H2'
import P from '../P'
import { translations } from '../../config'

import style from './style'
import UploadImage from '../UploadImage'

const ImageUploadModal = ({ classes, image, onPhotoAdded, onPhotoRemoved }) => {
  const title = window.cordova ? translations('Cordova Upload Image Title') : translations('Upload Image Title')
  const text = window.cordova ? translations('Cordova Upload Image Text') : translations('Upload Image Text')
  return (
    <Fragment>
      <H2 className={classes.spacing} value={title} />
      <P className={classes.spacing} value={text} />
      <UploadImage
        buttonSpacing
        onPhotoAdded={onPhotoAdded}
      />
    </Fragment>
  )
}

export default withStyles(style)(ImageUploadModal)
