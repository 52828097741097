import { connect } from 'react-redux'
import ForgotPasswordForm from './ForgotPasswordForm'
import { selectors as appSelectors } from '../../../store/modules/app'
import { actions as authActions } from '../../../store/modules/auth'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { onSubmissionComplete } = ownProps
  const isCustomerMode = appSelectors.getCustomerModeStatus(state)
  const onSubmit = ({ email, username }) => {
    return dispatch(authActions.resetPassword({ email, username }))
      .then(onSubmissionComplete)
      .catch(onSubmissionComplete)
  }

  return {
    ...ownProps,
    onSubmit,
    isCustomerMode
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ForgotPasswordForm)
