import {
  customerReportingSchema,
  staffReportingSchema,
  storeReportingSchema,
} from './configProvider'

export {
  customerReportingSchema,
  staffReportingSchema,
  storeReportingSchema
}
