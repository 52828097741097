import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import { translations } from '../../../config'
import Form, { FormSubmit } from '../../../components/Form'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import { Input } from '../../../components/Fields'
import H2 from '../../../components/H2'
import * as validators from '../../../validators'
import style from './style'

export const formId = 'coupon-code'

const CouponCodeForm = Form(formId)

const CouponCode = props => {
  const { onSubmit, initialValues, formInvalid, classes } = props
  return <div>
    <SubHeader
      leftContent={<BackBar />}
      centerContent={<H2 value={translations('COUPON CODE')} />}
    />
    <CouponCodeForm
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <div className={classes.container}>
        <div>
          <FormControl required>
            <Input
              name='couponCode'
              label={translations('Coupon Code')}
              validate={validators.required}
            />
          </FormControl>
        </div>
        <FormSubmit
          label='continue'
          big
          noContainer
          disabled={formInvalid}
        />
      </div>
    </CouponCodeForm>
  </div>
}

export default withStyles(style)(CouponCode)
