import React from 'react'
import styled from 'styled-components'
import P from '../../../../../components/P'
import List from '../../../../../components/List'
import Button from '../../../../../components/Button'
import AppointmentProductsListItem from './AppointmentProductsListItem'

import { translations } from '../../../../../config'

const AppointmentProductsList = ({
  appointmentId,
  items,
  disabled,
  addItems
}) => {
  const NoProducts = styled(P)`
    margin: 10px 0;
  `

  return (
    <>
      {(items && items.length)
        ? <List
          items={items}
          itemProps={{ appointmentId, disabled }}
          disabled={disabled}
          ItemComponent={AppointmentProductsListItem}
          noMargin
        />
        : <NoProducts value={translations('No products')} />
      }
      <Button
        buttonType='white'
        fullWidth
        disabled={disabled}
        onClick={addItems}
      >
        {translations('Add products')}
      </Button>
    </>
  )
}

export default AppointmentProductsList
