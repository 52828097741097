import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { formatWaitlistEntry } from '../../util/marketingPreferences'

export const getProductWaitlist = state => state.productWaitlist.results ? _.map(state.productWaitlist.results, entry => formatWaitlistEntry(entry)) : []

export const getIsLoading = state => state.productWaitlist.status === PENDING

const formatCustomerWaitlistEntry = entry => {
  return {
    type: 'product',
    ...entry.product
  }
}

export const getProductWaitlistForCustomer = state => state.productWaitlist.results ? { waitlist: _.map(state.productWaitlist.results, entry => formatCustomerWaitlistEntry(entry)) } : {}
