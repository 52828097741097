import { theme } from '../../../../config/theme'
const contentContainerHeight = 123

const style = theme => ({
  buttonContainer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    height: '90px',
    background: theme.palette.lightGrey.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    height: `calc(100vh - ${contentContainerHeight}px)`,
    overflow: 'auto'
  },
  contentContainerFullHeight: {
    height: 'auto'
  },
  createMessageButton: {
    width: '330px',
    height: '50px',
    fontWeight: 600
  },
  createMessageLink: {
    display: 'block',
    margin: '0 auto',
    textDecoration: 'none'
  },
  messagesContainer: {
    margin: '20px 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 60px'
    }
  },
  noMessagesContainer: {
    padding: '20px 50px',
    height: 'calc(100% - 80px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noMessagesText: {
    textTransform: 'uppercase',
    fontSize: 19,
    fontWeight: 'bold'
  },
  anonContainer: {
    height: `calc(100vh - ${theme.totalHeaderHeight}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hideOverflow: {
    overflow: 'hidden'
  }
})

export default theme => style(theme)
