const style = theme => ({
  container: {
    paddingBottom: 30
  },
  image: {
    maxWidth: '100%',
    maxHeight: 350,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'none'
    }
  },
  imageItemContainer: {
    padding: '30px 20px 0 20px'
  },
  textItemContainer: {
    padding: '30px 20px 0 20px',
    width: '100%'
  },
  title: {
    marginBottom: 20
  },
  tagsContainer: {
    marginTop: 30
  },
  button: {
    width: '100%',
    marginTop: 10,
    padding: '15px 0'
  },
  createdByText: {
    marginTop: 10
  },
  errorText: {
    fontSize: 18
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100vh - ${theme.totalHeaderHeight}px)`
  },
  gallerySelectImage: {
    height: 80,
    width: 80
  },
  gallerySelectContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  inspirationContentContainer: {
    flex: 1
  },
  boudoirModeButton: {
    marginRight: '20px'
  }
})

export default style
