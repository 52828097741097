import { tableGutter } from '../Table'

const styles = theme => ({
  lockContainer: {
    width: '50px',
    padding: 0
  },
  tableRow: {
    cursor: 'pointer',
    minHeight: '60px',
    alignItems: 'center',
    fontSize: '13px',
    padding: `0 ${0.5 * tableGutter}px`,
    '&:hover': {
      backgroundColor: theme.palette.tableHoverGrey.main
    },
    [theme.breakpoints.up('xs')]: {
      flexWrap: 'nowrap'
    }
  },
  deactivatedTableRow: {
    color: '#888'
  }
})

export default styles
