const styles = theme => ({
  tabRoot: {
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: 0,
    flex: 1,
    opacity: '1 !important',
    marginRight: 10,
    marginLeft: 10,
    '&:focus': {
      color: theme.palette.secondary.main,
      borderBottomColor: theme.palette.secondary.main
    }
  },
  tabRootBig: {
    height: 56
  },
  tabLabelContainer: {
    padding: 0
  },
  tabLabelBig: {
    ...theme.h2,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  tabLabelMedium: {
    ...theme.h3,
    fontSize: 15,
    textTransform: 'uppercase'
  },
  tabsRoot: {
    flexShrink: 0
  },
  tabsBorder: {
    borderBottom: `1px solid ${theme.palette.chartLegendsGrey.main}`
  },
  tabsBorderLight: {
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  tabsIndicator: {
    height: '3px',
    backgroundColor: theme.palette.accent.main
  },
  tabsIndicatorBig: {
    height: '5px'
  },
  noMaxWidth: {
    maxWidth: 'none'
  },
  displayNone: {}
})

export default theme => styles(theme)
