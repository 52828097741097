import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import compose from 'recompose/compose'

import Carousel from '../../Carousel'
import Product from './Product'
import AddProductButton from './AddProductButton'

import { translations } from '../../../config'

import style from './style'
import Inspiration from './Inspiration'
import File from './File'
import Image from './Image'
import Customer from './Customer'

const renderContent = ({ content, classes, onAddClick, editing, onDelete, width, isAtMax, hidePrice }) => {
  if (content.isAddButton && !isAtMax) {
    return <AddProductButton key={content.id} onAddClick={onAddClick} />
  }
  if (content.type === 'file') {
    return <File {...content} onDelete={onDelete('File')} editing={editing} width={width} />
  }
  if (content.type === 'product') {
    return <Product {...content} onDelete={onDelete('Product')} editing={editing} width={width} hidePrice={hidePrice} />
  }
  if (content.type === 'inspiration') {
    return <Inspiration {...content} onDelete={onDelete('Inspiration')} editing={editing} width={width} />
  }
  if (content.type === 'image') {
    return <Image {...content} onDelete={onDelete('Image')} editing={editing} width={width} />
  }
  if (content.type === 'customer') {
    return <Customer {...content} onDelete={onDelete('Customer')} editing={editing} width={width} />
  }
}

const ProductCarousel = ({
  content,
  onDelete,
  onAddClick,
  editing,
  classes,
  width,
  maxContent = 10,
  hidePrice,
  ...rest
}) => {
  const isAtMax = content.length > maxContent
  
  const isXS = useMediaQuery(theme => theme.breakpoints.down('xs'))
  if (content.length === 0) {
    return <div>{translations('No products')}</div>
  } else if (isXS) {
    return (
      <Grid container spacing={0}>
        {content.map(c => renderContent({ withGrid: true, content: c, classes, onAddClick, editing, onDelete, width: 'xs', isAtMax, hidePrice })
        )}
      </Grid>
    )
  } else {
    return (
      <Carousel
        items={content}
        renderItem={c => renderContent({ withGrid: false, content: c, classes, onAddClick, editing, onDelete, width: 'sm', isAtMax, hidePrice })}
      />
    )
  }
}

ProductCarousel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onProductsDelete: PropTypes.func,
  onAddClick: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  maxContent: PropTypes.number // Max content which can be added
}

export default compose(
  withStyles(style)
)
  (ProductCarousel)
