import { promiseReducer } from '../../util'

class InspirationDetailsReducers {
  fetchInspiration = promiseReducer(
    (state, action) => {
      return {
        ...state,
        result: action.result
      }
    }
  )
  deleteInspiration = promiseReducer(
    (state, action) => {
      return {
        ...state,
        result: {}
      }
    }
  )

  setActiveGalleryIndex = (state, action) => {
    return {
      ...state,
      selectedGalleryIndex: action.payload
    }
  }
}

export default new InspirationDetailsReducers()
