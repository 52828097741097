import _ from 'lodash'
import mergeby from 'mergeby'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class CalendarsReducers {
  fetchCalendars = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchCalendarsPending(state, action)
      case SUCCESS:
        return this.fetchCalendarsSuccess(state, action)
      case FAIL:
        return this.fetchCalendarsFail(state, action)
      default:
        return state
    }
  }

  fetchCalendarsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchCalendarsSuccess = (state, action) => {
    const { total, items } = action.result
    return {
      ...state,
      total,
      results: items,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchCalendarsFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }
}

export default new CalendarsReducers()
