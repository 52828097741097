import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import style from './style'

const FullScreenLoader = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress size={50} />
  </div>
)

FullScreenLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired
}

export default withStyles(style)(FullScreenLoader)
