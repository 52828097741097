import { connect } from 'react-redux'
import { compose } from 'recompose'

import { selectors as categorySelectors } from '../../../store/modules/categories'
import { actions as productsActions } from '../../../store/modules/products'
import withFullScreenLoader from '../../../components/FullScreenLoader'

import CategoryScreen from './CategoryScreen'

const mapStateToProps = (state, props) => {
  const catId = props.catId
  const isLoading = categorySelectors.getIsLoading(state)
  const category = categorySelectors.getCategoryById(state, catId)
  const hasChildCategories = categorySelectors.getHasChildCategoriesById(state, catId)
  return {
    hasChildCategories,
    category,
    isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: params => dispatch(productsActions.searchProductsFresh(params))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFullScreenLoader
)(CategoryScreen)
