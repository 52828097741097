import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InfiniteScroll from 'react-infinite-scroller'

import style from './style'
import Inspiration from './Inspiration'
import P from '../../../../components/P'
import { translations } from '../../../../config'

const InspirationResults = ({ inspirations, classes, hasMore, loadMore, hasSearched, inModal, ...rest }) => {
  if (hasSearched && !inspirations.length) {
    return <P
      className={classes.noResults}
      type='textButton'
      value={translations('No results found')}
    />
  }
  return (
    <InfiniteScroll
      initialLoad={false}
      hasMore={hasMore}
      loadMore={loadMore}
      className={classes.scrollContainer}
      useWindow={false}
    >
      <Grid container spacing={0} className={classes.container}>
        {
          inspirations.map(inspiration => {
            return (
              <Grid item xs={12} sm={4} key={inspiration.id} className={classes.gridItem}>
                <Inspiration inspiration={inspiration} {...rest} />
              </Grid>
            )
          })
        }
      </Grid>
    </InfiniteScroll>

  )
}

export default withStyles(style)(InspirationResults)
