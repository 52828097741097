import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const MobileSelect = props => {
  const { value, onChange, name, options, classes } = props

  return (
    <div className={classes.root}>
      <select
        value={value}
        onChange={onChange}
        name={name}
      >
        {
          options.map((option, i) => {
            if (option.divider) {
              return <option disabled>──────────</option>
            } else if (typeof option === 'string') {
              return <option value={option} key={i}>{option}</option>
            } else {
              return <option
                value={option.value}
                key={i}
              >
                {option.text || option.label || option.value}
              </option>
            }
          })
        }
      </select>
      <div className={classes.arrow} />
    </div>
  )
}

MobileSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(MobileSelect)
