import React from 'react'

import { translations, noPrices as noPricesConfig } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'

import { InChart as Simple } from '../Simple'

let InChart = props => {
  const { total, units, category = {}, noPrices } = props
  const { name } = category
  const value = (
    noPrices || noPricesConfig
    ? `${units} ${translations('units')}`
    : currencyFormatter.format(total)
  )

  return <Simple
    name={name}
    value={value}
  />
}

export { InChart }
