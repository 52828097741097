import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Toast from '../Toast'
import toastService from '../../services/toastService'
import style from './style'

class ToastContainer extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      open: false,
      props: undefined
    }
    this.state = this.initialState
  }

  open = ({ props }) => {
    this.setState({
      open: true,
      props
    })
  }

  close = (event, reason) => {
    if (this.state.props.disableClickAway && reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
  }

  componentDidMount () {
    toastService.connect({ open: this.open, close: this.close })
  }

  componentWillUnmount () {
    toastService.disconnect()
  }

  render () {
    const { open, props = {} } = this.state
    const { classes } = this.props
    return (
      <Toast
        isOpen={open}
        onClose={(...params) => this.close(...params)}
        type='default'
        {...props} />
    )
  }
}

export default withStyles(style)(ToastContainer)
