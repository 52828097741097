import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { customerSearchFunctions, followedCustomerFunctions } from '../dependencies'
import { uiConfig } from './uiConfig'
import { customerSearchColumns, dateFormat } from '../../config'
import { selectors as authSelectors } from '../../store/modules/auth'
import { useSelector } from 'react-redux'
import { formatCustomerSearchColumns } from '../../helpers'

export const CustomerSearch = ({ onBack, onSelect }) => {
  const userId = useSelector(authSelectors.getActiveUserId)
  const implementation = Modules.Abstract.Implementation.provideUseResourceSearch({
    resourceSearchFunctions: customerSearchFunctions,
    columns: formatCustomerSearchColumns(customerSearchColumns, dateFormat)
  })

  const followedCustomerImplementation = Modules.CustomerSearch.Implementation.provideUseFollowedCustomers({
    followedCustomerFunctions,
    userId
  })

  return (
    <Modules.CustomerSearch.Screens.CustomerSearchScreen
      followedCustomerImplementation={followedCustomerImplementation}
      implementation={implementation}
      uiConfig={uiConfig}
      onBack={onBack}
      onSelect={onSelect}
    />
  )
}
