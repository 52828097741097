import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'
import _ from 'lodash'
import appointmentsActions from './actions'
import { history } from '../../../store'
import { FETCH_FULL_CUSTOMER } from '../customerDetails/constants'
import { SUCCESS } from '../../middleware/redux-promise'
import * as selectors from './selectors'

class AppointmentsMiddleware {
  fetchCustomerAppointments = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FETCH_FULL_CUSTOMER && action.status === SUCCESS) {
      const currentPathname = history.location.pathname
      const match = matchPath(currentPathname, { path: '/customers/:id/full' })
      if (match) {
        const customerId = _.get(action, 'result.customer.id')
        dispatch(appointmentsActions.fetchCustomerAppointments({ customerId }))
      }
    }
  }
}

export default new AppointmentsMiddleware()
