import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { formId } from '../CustomerRegistrationForm'
import { selectors as storesSelectors } from '../../../../../store/modules/stores'
import { isMarketingStoresSelected } from '../../../../../store/util/marketingPreferences'

import CommunicationOptions from './CommunicationOptions'

import { languages as languageOptions } from '../../../../../config'

const mapStateToProps = state => {
  const formValues = getFormValues(formId)(state)

  return {
    languageOptions,
    storesOptions: storesSelectors.getAllStoresAsOptions(state),
    isStoreMarketingSelected: isMarketingStoresSelected(formValues)
  }
}

export default connect(mapStateToProps)(CommunicationOptions)
