import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { touch } from 'redux-form'
import Email from './Email'
import OptionsFormatter from '../Wrappers/OptionsFormatter'

import { countryPhoneDictionary } from '../../../config'
import { getUserSelectedRegionId } from '../../../store/modules/auth/selectors'
import { getRegions } from '../../../store/modules/regions/selectors'

class EmailContainer extends React.Component {

  handleChangeMain = emailAddressMain => {
    const domain = (this.props.value || '').split('@')[1]
    if (domain) {
      return `${emailAddressMain}@${domain}`
    }
    return emailAddressMain
  }

  handleChangeDomain = e => {
    const domain = e.target.value 
    const emailAddressMain = (this.props.value || '').split('@')[0]
    if (emailAddressMain) {
      return `${emailAddressMain}${domain}`
    }
    return `${domain}`
  }

  onChange = (e, type) => {
    type === 'domain'
    ? this.props.onChange(this.handleChangeDomain(e))
    : this.props.onChange(this.handleChangeMain(e))
  }

  onBlur = (e, type) => {
    type === 'domain'
    ? this.props.onBlur(this.handleChangeDomain(e))
    : this.props.onBlur(this.handleChangeMain(e))
  }

  formatValue = (value) => {
    const [main, domain] = (value || '').split('@')
    return {
      main,
      domain: domain
        ? '@' + domain
        : null
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <Email {...props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formId, name } = ownProps
  const touchField = () => {
    dispatch(touch(formId, name))
  }
  return {
    touchField
  }
}

const mapStateToProps = state => {
  const regionId = getUserSelectedRegionId(state)
  const regions = getRegions(state)

  const defaultEmailDomainOptions = [
    '@gmail.com',
    '@outlook.com',
    '@hotmail.com'
  ]

  const emailDomainOptions = _.chain(regions)
    .find(region => region.id === regionId)
    .get('details.emailDomainOptions', defaultEmailDomainOptions)
    .map(emailDomain => ({ label: emailDomain, value: emailDomain }))
    .value()

  return {
    emailDomainOptions
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsFormatter(EmailContainer))
