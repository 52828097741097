import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

import { pathsForCheckoutModule } from '../../../helpers'
import { actions as storesActions } from '../stores'
import { actions as checkoutFlowActions } from '../checkoutFlow'

import * as authSelectors from '../auth/selectors'

class StoresMiddleware {
  fetchStoresMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      if (
        matchPath(path, { path: `/:checkoutPrefix/${_.get(pathsForCheckoutModule, 'DELIVERY.0')}`, exact: true })
      ) {
        const basketId = _.get(getState(), 'currentOrderSalesforce.basket.basketId')
        dispatch(checkoutFlowActions.fetchDeliveryOptions(basketId))
      }
      if (
        authSelectors.getIsLoggedIn(getState()) &&
        (
          matchPath(path, { path: '/customers/:id', exact: true }) ||
          matchPath(path, { path: '/customers/:id/full', exact: true }) ||
          matchPath(path, { path: '/users/:id', exact: true }) ||
          matchPath(path, { path: '/orders', exact: true }) ||
          matchPath(path, { path: `/:checkoutPrefix/${_.get(pathsForCheckoutModule, 'DELIVERY.0')}`, exact: true }) ||
          matchPath(path, { path: `/:checkoutPrefix/${_.get(pathsForCheckoutModule, 'SELECT_CUSTOMER.1')}`, exact: true }))
      ) {
        dispatch(storesActions.fetchAllStores())
      }
    }
  }
}

export default new StoresMiddleware()
