import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style'

const SafeAreaSpacer = props => {
  const { inset, classes } = props

  const rootClass = classNames(
    {
      [classes.top]: inset === 'top',
      [classes.bottom]: inset === 'bottom',
      [classes.left]: inset === 'left',
      [classes.right]: inset === 'right'
    }
  )

  if (!inset) return null

  return <div className={rootClass} />
}

SafeAreaSpacer.propTypes = {
  /** string indicating which inset to use */
  inset: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
}

export default withStyles(style)(SafeAreaSpacer)
