
import React from 'react'
import cx from 'classnames'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

const TabsComponent = props => {
  const { tabs, classes, tabValue, handleTabChange, noDivider, bigTabs, mediumTabs, lightDivider, noMaxWidth } = props
  const tabClasses = {
    root: cx(
      classes.tabRoot,
      classes.tabLabelContainer,
      {
        [classes.tabRootBig]: bigTabs,
        [classes.noMaxWidth]: noMaxWidth,
        [classes.tabLabelBig]: bigTabs,
        [classes.tabLabelMedium]: mediumTabs
      }
    )
  }
  const tabsClasses = {
    root: cx(
      classes.tabsRoot,
      {
        [classes.tabsBorder]: !noDivider,
        [classes.tabsBorderLight]: lightDivider
      }
    ),
    indicator: cx({
      [classes.tabsIndicator]: true,
      [classes.tabsIndicatorBig]: bigTabs
    })
  }
  if (tabs && tabs.length) {
    return (
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        fullWidth
        indicatorColor='primary'
        classes={tabsClasses}
      >
        {
          tabs.map(({ label, value }, i) => (
            <Tab
              label={label}
              key={label + i}
              classes={tabClasses}
              value={value}
              disableRipple
            />
          ))
        }
      </Tabs>
    )
  } else {
    return null
  }
}

export default withStyles(style)(TabsComponent)
