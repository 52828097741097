import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'

import * as authSelectors from '../auth/selectors'

import actions from './actions'
import { DELETE_INSPIRATION } from './constants'
import { SUCCESS } from '../../middleware/redux-promise'

class InspirationsMiddleware {
  fetchInspirationMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const result = matchPath(action.payload.location.pathname, { path: '/inspirations/:id', exact: true })
      if (authSelectors.getIsLoggedIn(state) && result) {
        dispatch(actions.fetchInspiration({ id: result.params.id }))
      }
    }
  }
  deleteInspirationSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === DELETE_INSPIRATION && action.status === SUCCESS) {
      dispatch(push('/inspirations'))
    }
    next(action)
  }
}

export default new InspirationsMiddleware()
