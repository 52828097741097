import {
  wizardStepPadding,
  wizardStepHeight,
  wizardStepMarginBottom
} from '../constants'

export default () => ({
  contentBox: {
    paddingBottom: wizardStepPadding * 2,
    paddingLeft: wizardStepPadding * 2,
    paddingRight: wizardStepPadding * 2
  },
  contentBoxContainer: {
    border: 0,
    margin: 0
  }
})
