export default theme => ({
  imageContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingCircle: {
    position: 'absolute'
  },
  image: {
    objectFit: 'contain'
  }
})
