import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import OnlineStock from './OnlineStock'
import { translations, stockLevel as stockLevelConstants } from '../../../../../config'
import { selectors as productDetailSelectors } from '../../../../../store/modules/productDetails'

const getStockProps = (status) => {
  switch (status) {
    case stockLevelConstants.ONLINE_AVAILABLE_STATUS:
      return { label: translations('Available online'), icon: 'greenCheckIcon' }
    case stockLevelConstants.ONLINE_LOW_STOCK_STATUS:
      return { label: translations('Low stock online'), icon: 'orangeCheckIcon' }
    case stockLevelConstants.ONLINE_UNAVAILABLE_STATUS:
      return { label: translations('Unavailable online'), icon: 'redCrossIcon' }
    default:
      return { }
  }
}

const mapStateToProps = state => ({
  onlineAvailabilty: productDetailSelectors.getOnlineAvailabilty(state)
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['onlineAvailabilty', 'overriddenOnlineAvailability'], ({ onlineAvailabilty, overriddenOnlineAvailability }) => {
      const { label, icon } = getStockProps(overriddenOnlineAvailability || onlineAvailabilty)
      return {
        label, icon
      }
    }
  )
)(OnlineStock)
