import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import P from '../P'
import Image from '../Image'

const RelatedProductItem = ({ classes, displayPrice, image, name, goToProduct }) => (
  <a onClick={goToProduct} className={classes.relatedProduct}>
    <Image src={image} className={classes.image} />
    <P value={name} className={classes.name} />
    <P value={displayPrice} type='large' className={classes.price} />
  </a>
)

RelatedProductItem.propTypes = {
  displayPrice: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  goToProduct: PropTypes.func
}

export default withStyles(style)(RelatedProductItem)
