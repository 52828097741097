export default theme => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 5px',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.border.main}`
  },
  keyValuePairContainer: {
    padding: '5px 12px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 10px',
      minWidth: '50%',
      width: '50%'
    }
  },
  key: {
    fontSize: theme.p.p.fontSize,
    opacity: 0.5,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  value: {
    fontSize: theme.p.p.fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'flex-start'
  },
  link: {
    textDecoration: 'underline'
  }
})
