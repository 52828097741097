import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import style from './style'
import Notification from '../Notification'
import { getImage } from '../../Images'
import { translations } from '../../../config'
import P from '../../P'

const icon = getImage('userToUserNotificationIcon')

const getShortenedNameList = (list, index) => {
  const storesFirst = list.slice(0, index)
  const storesRest = list.slice(index)
  return `${storesFirst.join(', ')}${storesRest.length ? ` +${storesRest.length}` : ''}`
}

class UserToUserNotification extends Component {

  renderDeliveredNotificationText = () => {
    const { toUserName, toStores = [], toDepartments = [] } = this.props.meta

    if (toUserName) {
      return translations('User To User Notification Delivered', { toUserName })
    }

    if (toStores.length && toDepartments.length) {
      const stores = getShortenedNameList(toStores, 2)
      const departments = getShortenedNameList(toDepartments, 2)
      return translations('User to Stores and Departments Notification Delivered', { stores, departments })
    }

    if (toStores.length) {
      return translations('User to Stores Notification Delivered', { stores: getShortenedNameList(toStores, 2) })
    }

    if (toDepartments.length) {
      return translations('User to Departments Notification Delivered', { departments: getShortenedNameList(toDepartments, 2) })
    }

    return ''
  }

  renderText = () => {
    const { classes, toSender } = this.props
    const { fromUserName, message } = this.props.meta
    const baseClass = classes.notificationText
    const lastClass = classes.extraPaddingBottom
    const italicClass = classes.italic
    const marginClass = classes.marginLeft
    const notificationText = []
    toSender && notificationText.push(<P className={baseClass} value={this.renderDeliveredNotificationText()} />)
    notificationText.push(<P className={cx(baseClass, italicClass, { [marginClass]: toSender })} value={translations('User To User Notification From', { fromUserName })} />)
    notificationText.push(<P className={cx(baseClass, lastClass, { [marginClass]: toSender })} value={message} />)
    return notificationText
  }

  render () {
    const { createdAt, toSender, hideFlag } = this.props
    return (
      <Notification
        {...this.props}
        iconSource={icon}
        createdAt={createdAt}
        renderText={this.renderText}
        hideFlag={hideFlag}
        isReceipt={!!toSender} // isReceipt if there is a toSender
      />
    )
  }
}

export default withStyles(style)(UserToUserNotification)
