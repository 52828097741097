import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ChatActions {
  channelJoined = ({ channelId }) => ({
    type: constants.CHANNEL_JOINED,
    channelId
  })
  setChatChannelId = ({ channelId }) => ({
    type: constants.SET_CHAT_CHANNEL_ID,
    channelId
  })
  deleteChatChannel = ({ channelId }) => ({
    type: constants.DELETE_CHAT_CHANNEL,
    promise: () => {
      return digitalStoreSdk.chat.deleteChatChannel({ id: channelId, deleteMembers: true })
    }
  })
  fetchChatToken ({ channelId, userIdentity }) {
    return {
      type: constants.FETCH_CHAT_TOKEN,
      promise: () => {
        return digitalStoreSdk.chat.fetchChatToken({
          id: channelId,
          userIdentity
        })
      }
    }
  }
}

export default new ChatActions()
