import React from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

export const paperHeight = 300

const Paper = (props) => {
  const { style, className, classes, fullWidthMobile, ...rest } = props

  return <div
    className={cx(className, classes.paper, {
      [classes.fullWidthMobile]: fullWidthMobile
    })}
    style={{
      ...props.style,
      minHeight: props.height || paperHeight,
      minWidth: `${Math.max(
        (_.get(props, 'style.minWidth') || 0),
        (props.minWidth || 0),
        200
      )}px`
    }}
    {...rest}
  />
}

export default withStyles(style)(Paper)
