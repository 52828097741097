import React, { useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

import P from '../../../../../components/P'
import { getImage } from '../../../../../components/Images'

import { actions as appointmentsActions } from '../../../../../store/modules/appointments'

import { translations } from '../../../../../config'
import { useDispatch } from 'react-redux'

const noImagePlaceholder = getImage('imageNotAvailableImg')
const trashIcon = getImage('trashIcon')

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 5px 0;
`
const ItemLeft = styled.div`
  display: flex;
`

const ItemCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  > p { margin: 0; }
`

const ItemRight = styled.div`
  
`

const ItemImage = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white;
  margin: 0 auto;
`

const AppointmentProductsList = (item) => {
  const dispatch = useDispatch()
  const { appointmentId, imageUrl } = item
  const product = _.omit(item, ['appointmentId', 'disabled'])
  const { name, brand, variant, images, ...rest } = product
  const image = imageUrl || noImagePlaceholder
  const { disabled } = item

  const handleRemoveItem = useCallback(() => {
    dispatch(appointmentsActions.removeContent({ appointmentId, item: product }))
  })

  return (
    <Item>
      <ItemLeft>
        <ItemImage imageSrc={image} />
      </ItemLeft>
      <ItemCenter>
        <P type={'bold'} value={name} />
        <P value={brand} />
        {(variant && variant.name) && <P value={variant.name} />}
      </ItemCenter>
      <ItemRight>
        <IconButton onClick={handleRemoveItem} disabled={disabled}>
          <img
            src={trashIcon}
            alt={translations('Basket - Remove Button')}
          />
        </IconButton>
      </ItemRight>
    </Item>
  )
}

export default AppointmentProductsList
