import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import P from '../../../components/P'
import { FormBody, FormError } from '../../../components/Form'
import FormContentBox from '../../../components/FormContentBox'
import { translations } from '../../../config'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'
import Editable from '../../../components/Editable'
import WrappedRolesAndStoresForm from './RolesAndStores'
import WrappedPasswordForm from './Password'
import UserAvailability from './UserAvailability'

import ActionBar from '../../../components/ActionBar'
import TopRightButton from '../../../components/TopRightButton'
import deploymentConfig from '../../../deploymentApi/deploymentConfig'

const PersonalDetailsForm = Editable(FormContentBox('personal-details'))

const ViewUserFormWrapper = ({ classes, initialValues = {}, onSubmit, storeOptions, isPersonalDetailsEditable,
  isRolesAndStoresEditable, isPasswordEditable, isSelectedUserDeactivated, isSelectedUserLocked,
  isCurrentUserAbleToDeactivateUser, toggleDeactivateAccount, showModal, isCurrentUserSelectedUser,
  onAvailabilityClick, userAvailability, messageForwardingUser, ...props }) => {
  const userFullName = `${initialValues.firstName} ${initialValues.lastName}`

  const rolesAndStoresKeys = ['roleId', 'storeIds', 'departmentIds']
  const rolesAndStoresInitialValues = _.pick(initialValues, _.concat(rolesAndStoresKeys, 'id'))
  const personalDetailsInitialValues = _.omit(initialValues, rolesAndStoresKeys)
  const passwordInitialValues = _.pick(initialValues, 'id')
  const personalDetailsConfig = deploymentConfig.getUserPersonalDetailsConfig() || schema.personal

  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(userFullName)} />
        )}
      />

      {isSelectedUserDeactivated && isCurrentUserAbleToDeactivateUser &&
      <div>
        <ActionBar
          title={translations('This account is de-activated')}
          buttonTitle={translations('RE-ACTIVATE')}
          onClick={() => showModal()}
        />
        <P value={translations('required fields')} className={classes.requiredTextDeactivate} />
      </div>}

      {isSelectedUserLocked && !isSelectedUserDeactivated &&
        <ActionBar
          title={translations('This account is locked')}
          hideButton
        />
      }
      <div className={classes.contentContainer}>
        {!isSelectedUserDeactivated && isCurrentUserAbleToDeactivateUser && (
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
          <TopRightButton
            onClick={showModal}
          >
            {translations('De-Activate')}
          </TopRightButton>
        </div>
        )}

        <UserAvailability
          editable={isCurrentUserSelectedUser}
          userAvailability={userAvailability}
          onAvailabilityClick={onAvailabilityClick}
          messageForwardingUser={messageForwardingUser ? `${messageForwardingUser.firstName} ${messageForwardingUser.lastName}` : null }
        />
        <PersonalDetailsForm
          editable={isPersonalDetailsEditable}
          enableReinitialize
          initialValues={personalDetailsInitialValues}
          givenClass={classes.formContainer}
          onSubmit={onSubmit}
          boxName={translations('Personal Details')}
          formId='personal-details' >

          <FormError givenClass={classes.submitError} />
          <FormBody
            schema={personalDetailsConfig.schema}
            layout={personalDetailsConfig.layout}
            globalClasses={{
              col: classes.columnStyle,
              row: classes.rowStyle
            }}
          />
        </PersonalDetailsForm>
        <WrappedRolesAndStoresForm
          classes={classes}
          storeOptions={storeOptions}
          enableReinitialize
          initialValues={rolesAndStoresInitialValues}
          givenClass={classes.formContainer}
          boxName={translations('Role, Store(s) and Department(s)')}
          formId='roles-and-stores'
          editable={isRolesAndStoresEditable}
          onSubmit={onSubmit}
          />
        <WrappedPasswordForm
          classes={classes}
          editable={isPasswordEditable}
          boxName={translations('Password')}
          formId='password'
          onSubmit={onSubmit}
          initialValues={passwordInitialValues}
        />
      </div>
    </div>
  )
}

export default FullScreenProgress(
  withStyles(styles)(ViewUserFormWrapper)
)
