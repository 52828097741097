import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import Form from '../../../components/Form'

import FormBody from '../../../components/Form/FormBody'
import FormSubmit from '../../../components/Form/FormSubmit'
import { Dropdown, DatePicker } from '../../../components/Fields'
import H2 from '../../../components/H2'
import { translations, colors } from '../../../config'

import style from './style'

export const formId = 'filter-test-purchases'

const FilterForm = Form(formId)

const FilterModal = ({ success, classes, onSubmit, onFieldChange, initialValues, storeList }) => {
  return (
    <div className={classes.container}>
      <H2 value={_.toUpper(translations('Filter'))} />
      <FilterForm onSubmit={onSubmit} initialValues={initialValues}>
        <FormBody>
          <FormControl className={classes.formControl}>
            <Dropdown
              label={translations('Type')}
              name={'type'}
              options={[
                {
                  value: 'daily',
                  label: 'Daily'
                },
                {
                  value: 'weekly',
                  label: 'Weekly'
                },
                {
                  value: 'monthly',
                  label: 'Monthly'
                }
              ]}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <DatePicker
              label={translations('Ending Before')}
              name={'dueBefore'}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <DatePicker
              label={translations('Ending After')}
              name={'dueAfter'}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Dropdown
              label={translations('Store')}
              name={'storeId'}
              options={storeList}
            />
          </FormControl>
        </FormBody>
        <FormSubmit
          label={translations('Update Listings')}
          fullWidth
          buttonClass={classes.buttonClass}
        />
      </FilterForm>
    </div>
  )
}

FilterModal.propTypes = {
  storeList: PropTypes.array,
  initialValues: PropTypes.object,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

export default withStyles(style)(FilterModal)
