const style = {
  contentContainer: {
    padding: '20px 7.5% 0'
  },
  formContainer: {
    marginTop: 20
  }
}

export default style
