const styles = {
  productGrid: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: 15,
    alignItems: 'flex-end'
  },
  listContainer: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  }
}

export default theme => styles
