import _ from 'lodash'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'

import { selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails'
import { manualDiscountsEnabled, noPrices } from '../../../../../config'

import OrderProducts from './OrderProducts'

const mapStateToProps = (state) => {
  const isPartialRefundState = orderDetailsSelectors.getIsPartialRefundState(state)
  const isExchangeState = orderDetailsSelectors.getIsExchangeState(state)
  return { isPartialRefundState, isExchangeState }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['order', 'isPartialRefundState', 'isExchangeState', 'config'],
    (props) => {
      const { order, isPartialRefundState, isExchangeState, config } = props

      const isStoreroom = _.get(order, 'orderType') === 'storeroom'
      const status = _.get(order, 'status')

      // productInfoLayout depicts the columns to be shown and the cell width for each
      // (total cell width must add up to 8 or less)
      let productInfoLayout = []

      if (isStoreroom) {
        // storeroom order
        productInfoLayout = [
          ['variantName', 2],
          ['quantity', 2],
          ['total', 2]
        ]
        if (config.enablePicking && (status === 'awaiting_picking' || status === 'picking_in_progress')) {
          productInfoLayout.push(['picked', 2])
        } else if (config.enableAddToBagFromRequest && status === 'ready_for_collection') {
          productInfoLayout.push(['selectSold', 2])
        } else {
          productInfoLayout.push(['notPurchasedReason', 2])
        }
      } else {
        // standard or referral order
        if (isPartialRefundState || isExchangeState) {
          productInfoLayout = [
            ['variantName', 2],
            ['quantity', 2],
            ['total', 2],
            ['quantityToRefund', 2]
          ]
        } else {
          if (noPrices) {
            productInfoLayout = [
              ['variantName', 3],
              ['quantity', 3]
            ]
          } else {
            if (manualDiscountsEnabled) {
              productInfoLayout = [
                ['variantName', 1],
                ['quantity', 1],
                ['price', 2],
                ['discount', 2],
                ['total', 2]
              ]
            } else {
              productInfoLayout = [
                ['variantName', 2],
                ['quantity', 2],
                ['total', 2]
              ]
            }
          }
        }
      }
      return { productInfoLayout }
    }
  )
)(OrderProducts)
