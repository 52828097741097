import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import pollQuestionsActions from './actions'
import * as pollQuestionsSelectors from './selectors'
import * as authSelectors from '../auth/selectors'
import * as pollResultsConstants from '../pollResults/constants'

class PollQuestionsMiddleware {
  loadPollQuestionsMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    // this is all in the if (...) so that the latter perf expensive predicates
    // are only evaluated if the earlier predicates actually return true
    if (
      // is logged in
      authSelectors.getIsLoggedIn(getState()) &&
      // is routing to a page that needs poll questions
      action.type === LOCATION_CHANGE &&
      (
        matchPath(action.payload.location.pathname, { path: '/polls' })
      )
    ) {
      dispatch(pollQuestionsActions.fetchPollQuestions())
    }
  }

  reloadPollQuestionsOnPollQuestionInstanceDeleteMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (
      authSelectors.getIsLoggedIn(getState()) &&
      action.type === pollResultsConstants.DELETE_POLL_QUESTION_INSTANCE &&
      action.status === 'SUCCESS'
    ) {
      dispatch(pollQuestionsActions.fetchPollQuestions())
    }
    next(action)
  }
}

export default new PollQuestionsMiddleware()
