import { compose } from 'recompose'

import RefundProduct from './RefundProduct'
import { reduxForm } from 'redux-form'

export default compose(
  reduxForm({
    form: 'refundOrder' // a unique identifier for this form
  })
)(RefundProduct)
