import { connect } from 'react-redux'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import EditPriceModal, { editPriceType } from './EditPriceModal'

const mapStateToProps = state => {
  const currencyCode = authSelectors.getCurrencyCode(state)
  return {
    currencyCode
  }
}
export { editPriceType }
export default connect(mapStateToProps)(EditPriceModal)
