import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import _ from 'lodash'

import { timeFormat } from '../../../../../config'
import formatActivityText from '../../formatActivityText'
import style from './style'

const renderIconOrImage = ({ icon: Icon, imageUrl, classes, customer }) => {
  if (imageUrl) {
    return <img src={imageUrl} className={classes.image} />
  } else if (Icon) {
    return <Icon className={classes.image} />
  } else {
    return <div className={classes.initials}>{customer.firstName.charAt(0)}{customer.lastName.charAt(0)}</div>
  }
}

const ViewCustomerActivityListItem = ({ classes, customer, text, icon, date, onActivityClick, imageUrl, activityType }) => {
  const firstName = _.get(customer, 'firstName')
  return (
    <div className={classes.container} onClick={onActivityClick}>
      <div className={classes.iconWrapper}>
        {renderIconOrImage({ icon, imageUrl, classes, customer })}
      </div>
      <div className={classes.copy}>{formatActivityText({ firstName, text, activityType })}</div>
      <div className={classes.rightContent}>{moment(date).format(timeFormat)}</div>
    </div>
  )
}

export default withStyles(style)(ViewCustomerActivityListItem)
