import React from 'react'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import Image from '../../../../components/Image'
import H2 from '../../../../components/H2'

const InspirationProductsScroller = ({classes, products}) => {
  const renderImage = ({ image, title }) => {
    return (
      <div className={classes.productContainer}>
        <Image className={classes.productImage} src={image} />
        <H2 className={classes.productTitle} value={title} />
      </div>
    )
  }

  const renderProductList = () => products.map(product => {
    return renderImage({ image: product.image, title: product.title })
  })

  return (
    <div className={classes.productsContainer}>
      {renderProductList()}
    </div>
  )
}

export default withStyles(style)(InspirationProductsScroller)
