import { LOCATION_CHANGE, goBack } from 'connected-react-router'
import { matchPath } from 'react-router'
import followingActions from './actions'
import * as followingConstants from './constants'
import { FOLLOW_CUSTOMER, UNFOLLOW_CUSTOMER } from '../customerDetails/constants'
import * as authSelectors from '../auth/selectors'

class FollowingMiddleware {
  loadFollowingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToSomewhereThatNeedsFollowing = (
        matchPath(
          action.payload.location.pathname,
          { path: '/customers' }
        ) ||
        matchPath(
          action.payload.location.pathname,
          { path: '/checkout' }
        ) ||
        matchPath(
          action.payload.location.pathname,
          { path: '/referral' }
        ) ||
        matchPath(
          action.payload.location.pathname,
          { path: '/messages/new' }
        ) ||
        matchPath(
          action.payload.location.pathname,
          { path: '/product/' }
        )
      )
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToSomewhereThatNeedsFollowing && isLoggedIn) {
        dispatch(followingActions.fetchFollowing())
      }
    }
  }

  addToFollowingMiddleware = ({ dispatch }) => next => action => {
    if (action.type === FOLLOW_CUSTOMER) {
      dispatch(followingActions.addToFollowing(action))
    }
    next(action)
  }

  removeFromFollowingMiddleware = ({ dispatch }) => next => action => {
    if (action.type === UNFOLLOW_CUSTOMER) {
      dispatch(followingActions.removeFromFollowing(action))
    }
    next(action)
  }
}

export default new FollowingMiddleware()
