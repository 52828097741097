import { change } from 'redux-form'
import { push } from 'connected-react-router'

export default ({ dispatch, customer }) => {
  dispatch(push('/notifications/new#me'))

  // Load customer content
  const customerData = {
    type: 'customer',
    id: customer.id,
    name: `${customer.firstName} ${customer.lastName}`,
    firstName: customer.firstName,
    lastName: customer.lastName
  }

  setTimeout(() => {
    dispatch(change('create-notification', 'content', [customerData]))
    dispatch(change('create-notification', 'type', 'task'))
  }, 500)
}
