import React from 'react'
import PropTypes from 'prop-types'
import StockLevelIndicator from '../../../../../components/StockLevelIndicator'

const OnlineStock = ({label, icon}) => (
  <StockLevelIndicator label={label} icon={icon} />
)

OnlineStock.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string
}

export default (OnlineStock)
