import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import currencyFormatter from '../../../../formatters/currencyFormatter'
import { Input } from '../../../../components/Fields'
import Form from '../../../../components/Form'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'

import style from './style'

export const formId = 'edit-price-modal'
const ThisForm = Form(formId)

export const editPriceType = 'EDIT'

class EditPriceModal extends Component {
  getInitialEditedPriceValue = () => {
    const actualValue = _.get(this.props, 'price.value')
    const num = Number(actualValue) || 0
    const valueWithDecimals = num.toFixed(2)
    return valueWithDecimals
  }

  isNegative = Number(this.getInitialEditedPriceValue()) <= 0
  initialValues = {
    editedPriceValue: this.getInitialEditedPriceValue()
  }

  validateAmount = amount => {
    const { currencyCode } = this.props
    const num = Number(amount)
    const initialValue = this.getInitialEditedPriceValue()

    // Reverse the edit direction on refunds
    if (this.isNegative) {
      return (
        num > initialValue
          ? undefined
          : `Please enter an amount (more than ${currencyFormatter.format({ code: currencyCode, value: initialValue })})`
      )
    }

    return (
      num < initialValue
        ? undefined
        : `Please enter an amount (less than ${currencyFormatter.format({ code: currencyCode, value: initialValue })})`
    )
  }

  render () {
    const { success, actions, currencyCode, classes } = this.props
    return <ThisForm onSubmit={success} initialValues={this.initialValues}>
      <BasicModalOverlay
        title={translations('Edit Price Modal Title')}
        actions={actions}
      >
        <FormControl className={classes.formControl}>
          <Input
            currencyCode={currencyCode}
            name='editedPriceValue'
            type='price'
            negative={this.isNegative}
            validate={this.validateAmount}
          />
        </FormControl>
      </BasicModalOverlay>
    </ThisForm>
  }
}

export default withStyles(style)(EditPriceModal)
