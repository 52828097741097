import React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'

import ProductCarousel from './ProductCarousel'

import { actions as inspirationsActions } from '../../../store/modules/inspirations'

import util from './util'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { ProductRouter } from '../../../retailos/ProductRouter'

class ProductCarouselContainer extends React.Component {
  generateContent = () => {
    const { editing, value } = this.props
    const addButton = { id: 'new', isAddButton: true }
    if (value) {
      return editing ? [addButton].concat(value) : value
    } else {
      return editing ? [].concat(addButton) : []
    }
  }

  generateProps = () => {
    return {
      ...this.props,
      content: this.generateContent()
    }
  }

  render () {
    const props = this.generateProps()
    return <ProductCarousel {...props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const { name, formName } = ownProps
  const currentFormValues = getFormValues(formName)(state)
  const currentContent = _.get(currentFormValues, name, [])
  return {
    currentContent
  }
}

const openProductsScreenModal = ({ dispatch, formName, fieldName, currentContent, featureType }) => {
  const initialItems = currentContent.map(content => ({
    itemId: content.id,
    variationId: _.get(content, 'variants.0.id')
  }))
  modalService.open({
    component: ProductRouter,
    onSelected: (fullProducts) => {
      util.addProductsToField({ products: fullProducts, currentContent, dispatch, formName, fieldName })
    },
    initialItems,
    featureType,
    // Modal config
    fullScreen: true,
    overflowHidden: true,
    // Routing config
    multiSelect: true,
    noRouting: true
  })
}

const openInspirationScreenModal = ({ dispatch, formName, fieldName, currentContent, inspirationsModalComponent }) => {
  dispatch(inspirationsActions.resetStore())
  dispatch(inspirationsActions.fetchInspirationTags())
  dispatch(inspirationsActions.searchInspirationsFresh({ type: 'brand' }))

  util.openInspirationsScreenModal({ dispatch, formName, fieldName, currentContent, inspirationsModalComponent })
}

const openPhotoUploadModal = ({ dispatch, formName, fieldName, currentContent }) => {
  util.openImageUploadModal({ dispatch, formName, fieldName, currentContent })
}

const openFileUploadModal = ({ dispatch, formName, fieldName, currentContent }) => {
  util.openFileUploadModal({ dispatch, formName, fieldName, currentContent })
}

const openCustomerModal = ({ dispatch, formName, fieldName, currentContent, customerModalComponent }) => {
  util.openCustomerModal({ dispatch, formName, fieldName, currentContent, customerModalComponent })
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onAddClick: ({
      dispatch,
      formName,
      name,
      currentContent,
      inspirationsModalComponent,
      customerModalComponent,
      options = [],
      featureType = 'message'
    }) => () => {
      const allowAll = options.length === 0

      return modalService.action({
        title: translations('Select Content Type Title'),
        text: translations('Select Content Type Text'),
        actions: [
          {
            success: true,
            text: translations('label-uploadFile'),
            onClick: () => openFileUploadModal({ dispatch, formName, fieldName: name, currentContent }),
            primary: false,
            enabled: allowAll || options.includes('file')
          },
          {
            success: true,
            text: translations('Product'),
            onClick: () => openProductsScreenModal({ dispatch, formName, fieldName: name, currentContent, featureType }),
            primary: false,
            enabled: allowAll || options.includes('product')
          },
          {
            text: translations('Inspiration'),
            success: true,
            primary: false,
            enabled: allowAll || options.includes('inspiration'),
            onClick: () => openInspirationScreenModal({ dispatch, formName, fieldName: name, currentContent, inspirationsModalComponent })
          },
          {
            text: translations('Photo Upload'),
            success: true,
            primary: false,
            enabled: allowAll || options.includes('upload'),
            onClick: () => openPhotoUploadModal({ dispatch, formName, fieldName: name, currentContent })
          },
          {
            text: translations('Customer'),
            success: true,
            primary: false,
            enabled: allowAll || options.includes('customer'),
            onClick: () => openCustomerModal({ dispatch, formName, fieldName: name, currentContent, customerModalComponent })
          }
        ].filter(e => e.enabled)
      })
    },
    onDelete: ({ dispatch, formName, name, currentContent }) => (itemTypeName) => ({ id, event }) => {
      // stop click bubbling up to the product container click
      event.stopPropagation()
      util.openDeleteModal({ dispatch, formName, fieldName: name, currentContent, id, itemTypeName })
    }
  })
)(ProductCarouselContainer)
