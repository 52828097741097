import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import { Table, TableHeader, TableRow, TableCell } from '../../../../../../components/Table'
import Image from '../../../../../../components/Image'
import ButtonLink from '../../../../../../components/ButtonLink'
import P from '../../../../../../components/P'
import { translations } from '../../../../../../config'
import style from './style'

class OrderProduct extends Component {
  renderDataLine = ([key, content]) => {
    const { classes } = this.props
    const keyText = translations(`Order Product ${_.startCase(key)}`)
    let text = (
      keyText
      ? `${keyText}: ${content}`
      : content
    )
    let lineClass = cx(classes.itemDetail, {
      [classes.notPurchasedReasonTextMobile]: key === 'notPurchasedReason'
    })
    return <P className={lineClass} value={text} />
  }

  renderItemDetails = (linesData = []) => {
    const { product, goToProduct, classes } = this.props
    return <div className={classes.itemDetailsContainer}>
      <Image
        src={_.get(product, 'images.0')}
        className={classes.itemDetailsImage}
        box
        contain
        onClick={goToProduct}
      />
      <div className={classes.itemDetailsText}>
        <ButtonLink
          className={classes.itemName}
          onClick={goToProduct}
        >
          {_.get(product, 'name')}
        </ButtonLink>
        {linesData.map(this.renderDataLine)}
      </div>
    </div>
  }

  _getProductLinesForAllScreenSizes = () => {
    const { product } = this.props

    const variantSku = _.get(product, 'variant.details.sku')
    const externalProductId = _.get(product, 'externalProductId')

    const lines = []
    lines.push(['externalId', externalProductId])
    if (variantSku) lines.push(['variantSku', variantSku])
    return lines
  }

  renderDesktop = () => {
    const { productInfo, productInfoLayout, faded, classes } = this.props

    return <TableRow
      className={faded ? classes.faded : undefined}
    >
      <TableCell sm={4} className={classes.cell}>
        {this.renderItemDetails(this._getProductLinesForAllScreenSizes())}
      </TableCell>
      {productInfoLayout.map(([key, cellWidth]) => {
        return <TableCell
          key={key}
          children={productInfo[key]}
          sm={cellWidth}
          className={classes.cell}
        />
      })}
    </TableRow>
  }

  renderMobile = () => {
    const { classes, productInfoLayout, productInfo, faded } = this.props

    const linesData = [...this._getProductLinesForAllScreenSizes()]

    let rightContent = null

    productInfoLayout.forEach(([key]) => {
      const content = productInfo[key]
      // this is so that stuff like the YesNo picker is pushed to the right
      // but other stuff can just be rendered as lines of text
      if (typeof content === 'string' || typeof content === 'number') {
        linesData.push([key, content])
      } else {
        rightContent = content
      }
    })

    return <div className={classes.containerMobile}>
      <div className={cx(classes.innerMobile, {
        [classes.faded]: faded
      })}>
        {this.renderItemDetails(linesData)}
        {rightContent}
      </div>
    </div>
  }

  render () {
    return <div>
      <Hidden smUp>{this.renderMobile()}</Hidden>
      <Hidden xsDown>{this.renderDesktop()}</Hidden>
    </div>
  }
}

export default withStyles(style)(OrderProduct)
