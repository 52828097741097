import _ from 'lodash'

export const getIsLoading = state => state.calendars.isLoading || state.calendars.status === 'PENDING'
export const getCalendarsState = state => _.get(state, 'calendars')
export const getResults = state => _.get(getCalendarsState(state), 'results')

export const getCalendarOptions = state => getResults(state).map(calendar => ({
  value: calendar.id,
  label: calendar.name
}))
