import { connect } from 'react-redux'
import _ from 'lodash'
import InStoreStock from './InStoreStock'
import { translations, stockLevel as stockConstants } from '../../../../../config'

import { selectors as ProductDetailSelectors } from '../../../../../store/modules/productDetails'

const mapStateToProps = (state) => {
  const selectedVariantId = ProductDetailSelectors.getSelectedVariantId(state)
  const product = ProductDetailSelectors.getProduct(state)
  const variant = _.find(product.variants, (o) => o.id === selectedVariantId)
  const variantStock = _.get(variant, 'stock')
  const overallInStockInstoreForProduct = _.find(product.variants, (o) => o.stock >= stockConstants.inStockLevel)
  const overallLowStockInstoreForProduct = _.find(product.variants, (o) => o.stock <= stockConstants.lowStockHighLevel && o.stock >= stockConstants.lowStockLowLevel)
  const overallNoStockInstoreForProduct = _.find(product.variants, (o) => o.stock === stockConstants.outOfStockLevel)
  const overallNoStockInsertedInstoreForProduct = _.find(product.variants, (o) => o.stock === stockConstants.itemNotStocked)
  const inStockVariantStatus = (variantStock >= stockConstants.inStockLevel)
  const lowStockVariantStatus = (variantStock <= stockConstants.lowStockHighLevel && variantStock >= stockConstants.lowStockLowLevel)
  const noStockVariantStatus = variantStock === stockConstants.outOfStockLevel
  const notStockedVariantStatus = variantStock === stockConstants.itemNotStocked

  let stockProps = {}

  if ((overallInStockInstoreForProduct && variantStock === undefined) || inStockVariantStatus) {
    // checks to see if any object in variant array meets inStockLevel before any variant is selected or once variant is selected it meets inStockLevel
    stockProps = {
      stockStatus: translations('In Stock in here'),
      statusIcon: 'greenCheckIcon'
    }
  } else if ((!variant && overallNoStockInstoreForProduct && !notStockedVariantStatus) || (variantStock !== undefined && noStockVariantStatus)) {
    // checks that no variant is selected, checks the array of variants object for any meeting outOfStockLevel and that the variant exists or if a variant is selected it equals to outOfStockLevel
    stockProps = {
      stockStatus: translations('Out of Stock in here'),
      statusIcon: 'redCrossIcon'
    }
  } else if ((!variant && overallLowStockInstoreForProduct && !notStockedVariantStatus) || (variantStock !== undefined && lowStockVariantStatus)) {
    // checks no variant is selected, checks array of variants object for any meeting the lowStockLevel and that the variant exists or variant selected meets lowStockLevel
    stockProps = {
      stockStatus: translations('Low Stock in here'),
      statusIcon: 'orangeCheckIcon'
    }
  } else if ((overallNoStockInsertedInstoreForProduct && variantStock === undefined) || notStockedVariantStatus) {
    // checks objects in variants array meets itemNotStocked and checks that variants have not been selected or if variant is selected it equals to itemNotStocked
    stockProps = {
      stockStatus: translations('Item not stocked here'),
      statusIcon: 'redCrossIcon'
    }
  }
  return {
    selectedVariantId,
    product,
    variantStock,
    overallInStockInstoreForProduct,
    overallLowStockInstoreForProduct,
    overallNoStockInstoreForProduct,
    overallNoStockInsertedInstoreForProduct,
    inStockVariantStatus,
    lowStockVariantStatus,
    noStockVariantStatus,
    notStockedVariantStatus,
    stockProps
  }
}

export default connect(mapStateToProps, null)(InStoreStock)
