import React, { useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import H3 from '../../../../components/H3'
import Button from '../../../../components/Button'
import { Dropdown, Checkbox, Input } from '../../../../components/Fields'
import { translations, paymentOptions } from '../../../../config'
import { theme } from '../../../../config/theme'

import * as validators from '../../../../validators'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-bottom: 0.5px solid ${theme.palette.border.main};
  padding: 25px 30px 25px;
`

const Inner = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

const Title = styled(H3)`
  margin-bottom: 16px;
  text-align: center;
`

const CustomFormControl = styled(FormControl)`
  flex: 1;
  width: 100%;
`
const ButtonWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
`

const CreditPayment = ({ formValues, isPayingFullAmount }) => {

  const launchIFC = useCallback(() => {
    // TODO: launch IFC
  }, [])

  return <Container>
    <Title value={translations('Interest Free Credit')} />
    <Inner>
      <CustomFormControl required>
        <Dropdown
          name='ifcPaymentMonths'
          label={translations('Payment Term')}
          options={[{ label: '6 month', value: '6' }, { label: '1 Year', value: '12' }]}    
          disabled={isPayingFullAmount}
        />
      </CustomFormControl>
      <ButtonWrapper>
        <Button
          buttonType='white'
          onClick={launchIFC}
          disabled={isPayingFullAmount}>
          {`Launch IFC`}
        </Button>
      </ButtonWrapper>

      <CustomFormControl required={!isPayingFullAmount && _.get(formValues, 'paymentTerm')}>
        <Input
          name='ifcAgreementNumber'
          label={translations('IFC Agreement Number')}
          validate={!isPayingFullAmount && _.get(formValues, 'paymentTerm') ? validators.required : null}
          disabled={isPayingFullAmount}
        />
      </CustomFormControl>
    </Inner>
  </Container>
}

export default CreditPayment