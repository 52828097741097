import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import H2 from '../../../../../components/H2'
import ButtonLink from '../../../../../components/ButtonLink'
import Image from '../../../../../components/Image'
import style from './style'
import Tags from '../../../../../components/Tags'

import ButtonBase from '@material-ui/core/ButtonBase'

const Inspiration = ({ classes, onTagClick, onInspirationClick, defaultGallery = [], inspiration = {} }) => {
  const { title, tags = [], inspirationType } = inspiration
  const src = _.get(defaultGallery[0], 'src')
  return (
    <div className={classes.container} onClick={onInspirationClick}>
      <Image box contain className={classes.image} src={src} />
      <ButtonLink className={classes.title} onClick={onInspirationClick}>{title}</ButtonLink>
      <Tags
        tags={tags}
        inspirationType={inspirationType}
        onTagClick={onTagClick}
      />
    </div>
  )
}

export default withStyles(style)(Inspiration)
