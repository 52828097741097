import { promiseReducer } from '../../util'
import { compactObject } from '../../../helpers'

class OrdersReducers {
  searchOrders = promiseReducer(
    // SUCCESS
    (state, action) => {
      const { result = {} } = action
      const { results, total } = result
      return {
        ...state,
        total,
        results
      }
    },
    // PENDING
    (state, action) => {
      const { payload = {} } = action
      return {
        ...state,
        ...compactObject(payload)
      }
    }
  )

  searchOrdersNext = promiseReducer(
    (state, action) => {
      const { page, results: currentResults } = state
      const { result = {} } = action
      const { results, total } = result
      return {
        ...state,
        page: ((page || 0) + 1),
        total,
        results: [...currentResults, ...results]
      }
    }
  )

  fetchOrderTypes = promiseReducer(
    (state, action) => {
      return {
        ...state,
        orderTypes: action.result
      }
    }
  )
}

export default new OrdersReducers()
