import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { getImage } from '../../Images'

import style from './style'

const IconCheckbox = (props) => {
  const { classes, value, onChange, name } = props
  return <label
    className={classes.container}
    for={name}
  >
    <input
      type='checkbox'
      checked={value}
      onChange={onChange}
      name={name}
      id={name}
      className={classes.input}
    />
    <div className={classes.circle}>
      <img
        className={classes.icon}
        src={getImage('addToBasketIcon')}
      />
    </div>
  </label>
}

export default withStyles(style)(IconCheckbox)
