export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  dropdown: {
    width: '100%',
    marginTop: 30
  },
  button: {
    width: 'fit-content',
    marginLeft: 'auto'
  }
})
