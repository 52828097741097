import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroller'
import Grid from '@material-ui/core/Grid'

import ProductsGridItem from './ProductsGridItem'
import style from './style'

const defaultProductMapper = product => product

class ProductsGrid extends PureComponent {
  renderGridItems = () => {
    const {
      products,
      onProductClick,
      onButtonClick,
      buttonValue,
      productMapper = defaultProductMapper,
      multiSelect,
      modalIndex,
      disablePrice,
      push,
      goBack
    } = this.props

    return (products || [])
      .map((product) => {
        return (
          <ProductsGridItem
            modalIndex={modalIndex}
            component={'li'}
            key={product.id}
            product={product}
            onProductClick={onProductClick}
            onButtonClick={onButtonClick}
            buttonValue={buttonValue}
            multiSelect={multiSelect}
            disablePrice={disablePrice}
            push={push}
            goBack={goBack}
          />
        )
      })
  }

  render () {
    const { handleLoadMore, hasMore, classes } = this.props

    return (
      <InfiniteScroll
        className={classes.container}
        loadMore={handleLoadMore}
        hasMore={hasMore}
        initialLoad={false}
        useWindow={false}
      >
        <Grid className={classes.listContainer} container spacing={0} component={'ul'}>
          {this.renderGridItems()}
        </Grid>
      </InfiniteScroll>
    )
  }
}

export default withStyles(style)(ProductsGrid)
