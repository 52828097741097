const styles = theme => ({
  container: {
    backgroundColor: '#EBF7FF',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    border: '1px solid #84B7D9',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10
  },
  comment: {
    color: '#212121',
    whiteSpace: 'pre-wrap'
  }
})

export default styles
