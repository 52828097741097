import actionBarStyle from '../../../components/ActionBar/style'

const styles = theme => ({
  requiredFieldsText: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '20px',
    margin: '25px 0'
  },
  contentContainer: {
    padding: '0 7.5% 20px'
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  customerModeButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    margin: 0,
    '& >button:not(:last-child)': {
      marginRight: '.5rem'
    }
  },
  actionBar: {
    width: '100%',
    background: 'black',
    color: 'white',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px'
  },
  mobileMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
})

export default theme => ({
  ...actionBarStyle,
  ...styles(theme)
})
