export default (theme) => ({
  itemDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  itemDetailsImage: {
    height: 64,
    width: 64,
    flexShrink: 0,
    marginRight: 5
  },
  itemDetailsText: {
    minWidth: 0,
    flexShrink: 1,
    flex: 1,
    '& > div': {
      overflow: 'visible !important',
      whiteSpace: 'normal !important',
      textOverflow: 'unset !important'
    }
  },
  itemName: {
    fontWeight: 'bold',
    whiteSpace: 'initial'
  },
  itemDetail: {
    fontSize: 11
  },
  containerMobile: {
    border: `1px solid ${theme.palette.border.main}`,
    marginTop: 15,
    padding: '10px 10px 10px 7px'
  },
  innerMobile: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldContainer: {
    [theme.breakpoints.down('xs')]: {
      width: 60,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      flexShrink: 0,
      marginLeft: 'auto'
    }
  },
  cell: {
    alignSelf: 'stretch',
    paddingTop: 14,
    paddingBottom: 14
  },
  notPickedReason: {
    fontSize: 11,
    marginTop: 5
  },
  faded: {
    opacity: 0.3
  },
  notPurchasedReasonTextMobile: {
    textAlign: 'right',
    paddingRight: 3
  }
})
