import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

class SearchBar extends Component {
  state = {
    src: this.props.src
  }

  onSearch = () => {
    const { src } = this.state
    const { onSearch } = this.props
    onSearch(src)
  }

  onTextChange = ({ target: { value: src } }) => {
    this.setState({ src })
  }

  render = () => {
    const { src } = this.state
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <input
          value={src}
          onChange={this.onTextChange}
          type={'text'}
          className={classes.searchInput}
        />
        <div className={classes.searchButtonContainer}>
          <IconButton onClick={this.onSearch}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
    )
  }
}

SearchBar.propTypes = {
  src: PropTypes.string,
  onSearch: PropTypes.func.isRequired
}

export default withStyles(style)(SearchBar)
