import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import StockLevelIndicator from '../../../../../../../components/StockLevelIndicator'

const StoreStockItem = ({
  classes,
  stockProps,
  stockLevelIndicatorProps
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.itemContainer}>
            <StockLevelIndicator {...stockLevelIndicatorProps}/>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default withStyles(style)(StoreStockItem)
