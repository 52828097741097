import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'
import _ from 'lodash'

import { translations } from '../../../../config'

import style from './style'
import { BRAND_INSPIRATIONS } from '../../../../store/modules/app/constants'
import Tabs from '../../../../components/Tabs'
import MobileSelect from '../../../../components/MobileSelect'


const InspirationTabs = ({ classes, tabValue, handleTabChange }) => {
  const tabs = [
    {
      label: _.toUpper(translations('Brand Inspirations')),
      value: BRAND_INSPIRATIONS
    }
  ]
  
  const options = [
    {
      text: _.toUpper(translations('Brand Inspirations')),
      value: BRAND_INSPIRATIONS
    }
  ]

  return (
    <div className={classes.container}>
      <Hidden smUp>
        <MobileSelect
          onChange={handleTabChange}
          value={tabValue}
          options={options}
        />

      </Hidden>
      <Hidden xsDown>
        <Tabs
          tabs={tabs}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          fullWidth
          noDivider
          noMaxWidth
        />
      </Hidden>
    </div>
  )
}

export default withStyles(style)(InspirationTabs)
