import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import P from '../P'
import H2 from '../H2'
import Button from '../Button'
import ButtonsContainer from '../ButtonsContainer'
import FormSubmit from '../Form/FormSubmit'
import CircularProgress from '@material-ui/core/CircularProgress'
import style from './style'

class VideoModalOverlay extends PureComponent {
  render() {
    const {
      classes,
      title,
      src
    } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.loader}><CircularProgress color={'inherit'} /></div>
        <iframe frameBorder='0' scrolling='no' className={classes.iframeStyle} src={src} />
      </div>
    )
  }
}

export default withStyles(style)(VideoModalOverlay)
