import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style'
import _ from 'lodash'
import cx from 'classnames'
import { enabledOrderManagementFilterIds, statusFilters, translations } from '../../../../../../config'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import DatePicker from '../../../../../../components/Fields/DatePicker'
import Dropdown from '../../../../../../components/Fields/Dropdown'

const filters = [
  { id: 'storeId', title: 'Order Management Filter Label - Store', component: Dropdown },
  { id: 'userId', title: 'Order Management Filter Label - User', component: Dropdown },
  { id: 'orderType', title: 'Order Management Filter Label - Type', component: Dropdown },
  { id: 'orderStatus', title: 'Order Management Filter Label - Status', component: Dropdown },
  { id: 'startDate', title: 'Order Management Filter Label - Date from', component: DatePicker },
  { id: 'endDate', title: 'Order Management Filter Label - Date to', component: DatePicker }
]

class Filters extends Component {
  // need local state because search is debounced
  state = {
    userId: null,
    storeId: null,
    orderType: null,
    orderStatus: null,
    startDate: null,
    endDate: null
  }

  searchOrders = _.debounce(
    this.props.searchOrders,
    200
  )

  getOptions = (id) => {
    if (id === 'userId') {
      return this.props.userOptions
    }
    if (id === 'storeId') {
      return this.props.storeOptions
    }
    if (id === 'orderType') {
      return this.props.orderTypeOptions
    }
    if(id === 'orderStatus') {
      return statusFilters.map(item => ({
        label: translations(item.label),
        value: item.value,
      }))
    }
  }

  componentDidUpdate(newProps) {
    const filterTypes = filters.map(e => e.id)
    const filterProps = _.pick(this.props, filterTypes)
    const totalValuesFilled = Object.values(filterProps).filter(e => e).length
    this.props.onFiltersChange && this.props.onFiltersChange(totalValuesFilled)
  }

  updateField = _.memoize(
    (id) => (e) => {
      const val = _.get(e, 'target')
        ? _.get(e.target, 'value')
        : e
      this.setState({ [id]: val }, () => {
        this.searchOrders({ [id]: val })
      })
    }
  )

  getFieldProps = (id) => {
    const { classes, onUserInputChange, onStoreInputChange, isLoadingStores, isLoadingUsers } = this.props
    let props = {
      onChange: this.updateField(id),
      onBlur: this.updateField(id),
      options: this.getOptions(id)
    }
    if (id === 'userId' || id === 'storeId' || id === 'orderType' || id === 'orderStatus') {
      props = {
        ...props,
        autocomplete: true,
        listWidth: 400,
        className: classes.dropdown
      }
    }
    if (id === 'userId') {
      props = {
        ...props,
        onInputChange: onUserInputChange,
        isLoading: isLoadingUsers,
        disableSuggestionFiltering: true
      }
    }
    if (id === 'storeId') {
      props = {
        ...props,
        onInputChange: onStoreInputChange,
        isLoading: isLoadingStores,
        disableSuggestionFiltering: true
      }
    }
    return props
  }

  render() {
    const { classes, displayAsRows } = this.props
    
    return (
      <Grid className={cx(classes.container, { [classes.containerPadding]: !displayAsRows })} container spacing={2}>
        <div className={cx(classes.formWrapper, { [classes.formWrapperRows]: displayAsRows })}>
          {filters
            .filter(({ id }) => enabledOrderManagementFilterIds.includes(id))
            .map(({ id, title, component: FieldComponent }, i) => (
              <FormControl key={i} classes={{ root: classes.formControl }}>
                <FieldComponent
                  key={id}
                  label={translations(title)}
                  value={this.props[id]}
                  {...this.getFieldProps(id)}
                />
              </FormControl>
            ))}
        </div>
      </Grid>
    )
  }
}

Filters.propTypes = {
  total: PropTypes.number,
  query: PropTypes.string,
  category: PropTypes.object,
  isLoading: PropTypes.bool
}

export default withStyles(style)(Filters)
