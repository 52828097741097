import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import PollsListScreen from './PollsListScreen'
import { translations } from '../../../config'
import { POLLS_QUESTIONS, POLLS_RESULTS } from '../../../store/modules/app/constants'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'

const mapStateToProps = state => {
  const pollsTab = appSelectors.getPollsTab(state)
  const hasViewPollResults = authSelectors.getHasViewPollResults(state)
  const options = [
    {
      text: _.capitalize(translations('Questions')),
      value: POLLS_QUESTIONS
    },
    {
      text: _.capitalize(translations('Results')),
      value: POLLS_RESULTS
    }
  ]
  return {
    options,
    pollsTab,
    hasViewPollResults
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    handleTabChange: ({ dispatch }) => (event, value) => {
      // one is a native select so have to use event
      dispatch(appActions.changePollsTab({ pollsTab: value || event.target.value }))
    }
  })
)(PollsListScreen)
