import React from 'react'
import { Modules } from '@redant/retailos-ui'
import qs from 'qs'

import modalService from '../../../services/modalService'
import { GroupCustomerSearch } from '../GroupCustomerSearchScreen'
import { ProductRouter } from '../../ProductRouter'
import InspirationsSearchScreen from '../../../containers/Inspirations/InspirationsSearchScreen'
import { composeMessageFunctions, filePickerFunctions, composeGroupMessageFunctions } from '../../dependencies'
import { messageContentOptions, getLanguage, communicationOptions } from '../../../config'
import { mapCommunicationOptions } from '../../../helpers'

export const ComposeGroupMessageScreen = ({ location }) => {
  const useFilePicker = Modules.File.Implementation.provideUseFilePicker({
    filePickerFunctions,
    acceptedFileTypes: ['image/*']
  })
  
  const useAttachContent = Modules.Messaging.Implementation.provideUseAttachContent({
    composeMessageFunctions,
    modalService,
    ProductModalComponent: ProductRouter,
    InspirationModalComponent: InspirationsSearchScreen, // FIXME: temporary, replace with ui library screen eventually
    contentOptions: messageContentOptions,
    hooks: {
      useFilePicker
    }
  })

  const implementation = Modules.Messaging.Implementation.provideUseComposeGroupMessageScreen({
    composeGroupMessageFunctions,
    modalService,
    initialTemplate: location && qs.parse(location.search, { ignoreQueryPrefix: true }).template,
    SearchModalComponent: GroupCustomerSearch,
    getLanguage,
    hooks: {
      useAttachContent
    },
    communicationOptions: mapCommunicationOptions(communicationOptions.channelOptionKeys),
    locationSearch: location.search
  })

  return (
    <Modules.Messaging.Screens.ComposeGroupMessageScreen
      implementation={implementation}
    />
  )
}
