import { connect } from 'react-redux'
import { compose, withHandlers, withProps, withPropsOnChange } from 'recompose'
import modalService from '../../../../../services/modalService'
import { MessengerEditorRecipients } from '../../../../../components/Messenger'
import UsersSearchScreen from '../../../../Users/UsersSearchScreen'
import BroadcastScreen from '../../BroadcastScreen'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { actions as notificationActions } from '../../../../../store/modules/notifications'
import { translations } from '../../../../../config'

export default compose(
  connect((state, dispatch) => ({
    me: authSelectors.getCurrentUser(state)
  })),
  withProps({
    messageName: 'Notification',
    translatedMessage: 'label-notificationMessage',
    searchTypeName: 'users',
    searchTypeMessage: 'cta-searchUsers',
    showSelfSend: true,
    showBroadcast: true
  }),
  withPropsOnChange(['currentRecipients'], ({ currentRecipients = [], me }) => ({
    isSelectedMe: (me && currentRecipients.length && currentRecipients[0].id === me.id),
    currentRecipients: currentRecipients.map(rec => rec.name)
  })),
  withHandlers({
    showSearchRecipientsModal: ({ onUserSelect, dispatch }) => () => {
      modalService.open({
        component: UsersSearchScreen,
        fullScreen: true,
        inModal: true,
        hideBottomBar: true,
        onUserClick: (user) => {
          dispatch(notificationActions.resetBroadcastGroup())
          onUserSelect(user)
          modalService.close()
        },
        onBackClick: () => modalService.close()
      })
    },
    onSelectMe: ({ me, onUserSelect, dispatch }) => () => {
      dispatch(notificationActions.resetBroadcastGroup())
      onUserSelect(me)
    },
    showBroadcastModal: ({ onUserSelect }) => () => {
      modalService.open({
        component: BroadcastScreen,
        fullScreen: false,
        inModal: true,
        hideBottomBar: true,
        onSubmit: (user) => {
          onUserSelect(user)
          modalService.close()
        }
      })
      
    },
  })
)(MessengerEditorRecipients)
