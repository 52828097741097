import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router'

import { translations } from '../../config'
import ErrorScreen from '../../components/ErrorScreen'

class PrivateRoute extends Component {
  render () {
    const {
      component: RouteComponent,
      isLoggedIn,
      isConnected,
      handleErrorOnClick,
      isApiFail,
      allowOffline,
      ...rest
    } = this.props
    return (
      <Route {...rest} render={props => {
        if (isLoggedIn) {
          if (isApiFail && !allowOffline) {
            if (isConnected) {
              return <ErrorScreen
                title={translations('Generic Error Page Title')}
                buttonText={translations('Generic Error Page Button')}
                onClick={handleErrorOnClick}
              />
            } else {
              return <ErrorScreen
                title={translations('Offline Error Page Title')}
              />
            }
          } else {
            return <RouteComponent {...props} />
          }
        } else {
          return <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
          }} />
        }
      }} />
    )
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  handleErrorOnClick: PropTypes.func.isRequired,
  isApiFail: PropTypes.bool,
  allowOffline: PropTypes.bool,
}

export default PrivateRoute
