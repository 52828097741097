import * as constants from './constants'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_POLL_QUESTIONS:
      return reducers.fetchPollQuestions(state, action)
    case constants.SELECT_ANSWER:
      return reducers.selectAnswer(state, action)
    case constants.UPDATE_COMMENT:
      return reducers.updateComment(state, action)
    case constants.SAVE_ANSWERS:
      return reducers.saveAnswers(state, action)
    case constants.REMOVE_QUESTION:
      return reducers.removeQuestion(state, action)
    default:
      return state
  }
}
