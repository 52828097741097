import _ from 'lodash'
import deploymentConfig from '../deploymentApi/deploymentConfig'

class PasswordPolicy {
  _applyRequireRule = (password) => {
    return password
  }
  _applyMinLengthRule = (password, rule) => {
    return password && password.length >= rule
  }
  _applyMaxLengthRule = (password, rule) => {
    return password && password.length <= rule
  }
  _applyExactLengthRule = (password, rule) => {
    return password && password.length === rule
  }
  _applyUppercaseMinCountRule = (password, rule) => {
    const uppercaseCount = password.match(/([A-Z])/g)
    return uppercaseCount && uppercaseCount.length >= rule
  }
  _applyLowercaseMinCountRule = (password, rule) => {
    const lowercaseCount = password.match(/([a-z])/g)
    return lowercaseCount && lowercaseCount.length >= rule
  }
  _applyNumberMinCountRule = (password, rule) => {
    const numberCount = password.match(/([\d])/g)
    return numberCount && numberCount.length >= rule
  }
  _applySpecialCharMinCountRule = (password, rule) => {
    const specialCharCount = password.match(/([\!\?\-])/g)
    return specialCharCount && specialCharCount.length >= rule
  }
  _applyNonRepeatingRule = (password, rule) => {
    if (!rule || rule <= 2) {
      const repeatingCharacters = password.match(/(.)\1+/g)
      return !repeatingCharacters
    }
    const regex = new RegExp(`(.)${_.join(_.times(rule - 1, () => '\\1'), '')}`)
    return !password.match(regex)
  }

  _applyNonRepeatingInstanceRule = (password, rule) => {
    const regex = new RegExp(`(.)(.*\\1){${rule - 1}}`)
    return !password.match(regex)
  }

  _applyOnlyNumericalCharsRule = (password, rule) => {
    const nonNumericalCharacters = password.match(/\D/)
    return !nonNumericalCharacters
  }

  _applyMinUniqueCharCountRule = (password, rule) => {
    return _.size(_.uniq(password)) >= rule
  }

  /**
   * @description check for consecutive digits eg. 123456 === invalid, 135792 === valid
   * @param {string} password password of digits only to check
   * @param {number} rule number of disallowed consecutive digits in a row
   * @returns {boolean}
   * @memberof PasswordPolicy
   */
  _applyNonConsecutiveRule = (password, rule) => {
    let valid = true
    let counter = 0
    let lastDigit = null
    // check for ascending number
    _.forEach(password, (digit) => {
      if (_.isNull(lastDigit)) {
        lastDigit = _.parseInt(digit)
      } else {
        if ((lastDigit + 1) === _.parseInt(digit)) {
          counter += 1
        } else {
          counter = 0
        }
        lastDigit = _.parseInt(digit)
        if (counter === rule) {
          valid = false
        }
      }
    })
    // check for descending numbers
    _.forEach(password, (digit) => {
      if (_.isNull(lastDigit)) {
        lastDigit = _.parseInt(digit)
      } else {
        if ((lastDigit - 1) === _.parseInt(digit)) {
          counter += 1
        } else {
          counter = 0
        }
        lastDigit = _.parseInt(digit)
        if (counter === rule) {
          valid = false
        }
      }
    })
    return valid
  }

  applyPolicyRule = (options) => {
    switch (options.policyRuleName) {
      case 'REQUIRED':
        return this._applyRequireRule(options.password, options.policyRule.rule)
      case 'ONLY_NUMBERS':
        return this._applyOnlyNumericalCharsRule(options.password, options.policyRule.rule)
      case 'MIN_LENGTH':
        return this._applyMinLengthRule(options.password, options.policyRule.rule)
      case 'MAX_LENGTH':
        return this._applyMaxLengthRule(options.password, options.policyRule.rule)
      case 'EXACT_LENGTH':
        return this._applyExactLengthRule(options.password, options.policyRule.rule)
      case 'UPPERCASE_MIN_COUNT':
        return this._applyUppercaseMinCountRule(options.password, options.policyRule.rule)
      case 'LOWERCASE_MIN_COUNT':
        return this._applyLowercaseMinCountRule(options.password, options.policyRule.rule)
      case 'NUMBER_MIN_COUNT':
        return this._applyNumberMinCountRule(options.password, options.policyRule.rule)
      case 'SPECIAL_CHAR_MIN_COUNT':
        return this._applySpecialCharMinCountRule(options.password, options.policyRule.rule)
      case 'NON_REPEATING':
        return this._applyNonRepeatingRule(options.password, options.policyRule.rule)
      case 'NON_REPEATING_INSTANCE':
        return this._applyNonRepeatingInstanceRule(options.password, options.policyRule.rule)
      case 'MIN_UNIQUE_CHAR_COUNT':
        return this._applyMinUniqueCharCountRule(options.password, options.policyRule.rule)
      case 'NON_CONSECUTIVE':
        return this._applyNonConsecutiveRule(options.password, options.policyRule.rule)
      default:
        return new Error('Unknown policy rule')
    }
  }

  validatePassword = (password) => {
    const passwordPolicyConfig = deploymentConfig.getPasswordPolicy()
    const isValid = _.chain(passwordPolicyConfig)
      .map((policyRule, policyRuleName) => {
        return {
          policyRule: policyRule,
          policyRuleName: policyRuleName
        }
      })
      .reduce((memo, next) => {
        const policyRule = next.policyRule
        const policyRuleName = next.policyRuleName
        if (!_.isError(memo)) {
          const ruleMatches =
            this.applyPolicyRule({
              policyRuleName: policyRuleName,
              policyRule: policyRule,
              password: password
            })
          return ruleMatches || new Error(policyRule.message)
        } else {
          return memo
        }
      }, true)
      .value()
    return isValid
  }

}

export default new PasswordPolicy()
