import { promiseReducer } from '../../util'
import _ from 'lodash'

class PollResultsReducers {
  fetchPollResultsFresh = promiseReducer(
    (state, action) => {
      const results = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')

      return {
        ...state,
        results,
        total,
        page: 1,
        size: 10
      }
    }
  )

  fetchPollResultsNext = promiseReducer(
    (state, action) => {
      const { page, results } = state
      const newResults = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        results: [ ...results, ...newResults ],
        total,
        page: page + 1
      }
    }
  )

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }
}

export default new PollResultsReducers()
