import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import TextBubble from '../../../../components/TextBubble'
import P from '../../../../components/P'
import style from './style'

let InChart = props => {
  const { mainText, bubbleText, rightContent = null, classes } = props
  return (
    <div className={classes.inner}>
      <div className={classes.left}>
        <P type='textButton' value={mainText} className={classes.mainText} />
        <TextBubble noVerticalMargin>{bubbleText}</TextBubble>
      </div>
      {
        rightContent
        ? <div className={classes.right}>
          {rightContent}
        </div>
        : null
      }
    </div>
  )
}

InChart = withStyles(style)(InChart)

export { InChart }
