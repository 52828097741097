import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { compose } from 'recompose'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import { selectors as currentAppointmentSelectors } from '../../store/modules/currentAppointment'
import { getRegionConfig } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'
import Header from './Header'
import { history } from '../../store'
import { Modules } from '@redant/retailos-ui'

const mapStateToProps = state => {

  const isVirtualConsultation = Modules.VirtualConsultation.Slices.sharedStateSlice.selectors.isVirtualConsultation(state)
  const isStoreConsultation = Modules.Consultation.Slices.localStateSlice.selectors.isConsultation(state)
  const isConsultation = isStoreConsultation || isVirtualConsultation

  const configName = getRegionConfig(state)
  const theme = getTheme(configName)

  return {
    customerModeUpdate: appSelectors.getCustomerModeStatus(state),
    isConsultation,
    theme
  }
}

const mapDispatchToProps = {
  customerModeToggle: () => appActions.customerModeToggle({ isOn: true }),
  openMenu: () => appActions.toggleSideMenu({ isOpen: true })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
