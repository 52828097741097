import React, { Component, Fragment } from 'react'

// the use case for this HOC is when you have a component with a fixed
// or absolute position and therefore you need to push down the rest
// of the page content by a distance equal to the height of the component
const withHeightOffset = Comp => (
  class extends Component {
    state = {
      height: 0
    }

    onUpdate = () => {
      if (!this.state.height && this._ref) {
        const height = this._ref.offsetHeight
        this.setState({ height })
      }
    }

    componentDidMount () {
      this.onUpdate()
    }
  
    componentDidUpdate () {
      this.onUpdate()
    }

    setRef = (el) => {
      if (el) this._ref = el
    }

    render () {
      return <Fragment>
        <Comp {...this.props} heightOffsetRef={this.setRef} />
        <div style={{ height: this.state.height, flexShrink: 0 }} />
      </Fragment>
    }
  }
)

export const DivWithHeightOffset = withHeightOffset((props) => {
  const { heightOffsetRef, ...rest } = props
  return <div ref={heightOffsetRef} {...rest} />
})

export default withHeightOffset
