import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import { constants as productDetailsConstants } from '../../../../store/modules/inspirationDetails'
import TabbedContent from '../../../../components/TabbedContent'
import Container from '../../../../components/Container'
import InspirationProductsScroller from '../InspirationProductsScroller'
import ReactMarkdown from 'react-markdown'

import style from './style'

class InspirationBottomTabs extends Component {
  state = {
    tabValue: productDetailsConstants.INSPIRATION_PRODUCTS_TAB
  }
  getDetailsContent = () => {
    const { classes, data } = this.props
    const details = _.get(data, 'details.content', null)

    return (
      <Container inList className={classes.tabContainer}>
        {details ? 
          <ReactMarkdown source={details} /> : 
          <p>{translations('No Details Available')}</p>
        }
      </Container>
    )
  }

  getProductsContent = () => {
    const { classes, data } = this.props
    const products = _.get(data, 'details.products', [])
    return (
      <Container inList className={classes.tabContainer}>
        <InspirationProductsScroller products={products} />
      </Container>
    )
  }

  renderReviews = () => {
    const { product } = this.props
    return <Reviews product={product} />
  }

  handleTabChange = (e, value) => {
    this.setState({
      tabValue: value
    })
  }

  render () {
    const { classes, data } = this.props
    const { tabValue } = this.state
    const tabs = [
      {
        label: translations('Look Products'),
        content: this.getProductsContent(),
        value: productDetailsConstants.INSPIRATION_PRODUCTS_TAB
      },
      {
        label: translations('Details'),
        content: this.getDetailsContent(),
        value: productDetailsConstants.INSPIRATION_DETAILS_TAB
      }
    ]

    return (
      <div className={classes.tabsContainer}>
        <TabbedContent
          tabs={tabs}
          tabValue={tabValue}
          handleTabChange={this.handleTabChange}
          bigTabs
          lightDivider
        />
      </div>
    )
  }
}

InspirationBottomTabs.propTypes = {
  tabs: PropTypes.array,
  handleTabChange: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired
}

export default withStyles(style)(InspirationBottomTabs)
