import * as general from '../../../config'
import _ from 'lodash'

class Selectors {
  getWidgetSource = (state, ownProps) => {
    const { widgetId } = ownProps.params || {}
    const { auth: { user: { username, store: { externalStoreId = '' } = {} } = {} } } = state
    const srcTemplate = _.chain(general)
      .get(`dashboard.widgets[${widgetId}].src`, '')
      .template()
      .value()
    const src = srcTemplate({ username, externalStoreId })
    return src
  }
}

export default new Selectors()
