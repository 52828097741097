import { colors } from '../../../../../config/theme'

export default theme => ({
  listItemsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 15
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10
  },

  latestActivityTitle: {
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: '10px 0'
  },
  activityList: {
    width: '100%'
  },
  button: {
    marginLeft: 'auto'
  }
})
