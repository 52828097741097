import { promiseReducer } from '../../util'

class FollowingReducers {
  fetchFollowing = promiseReducer(
    (state, action) => {
      return {
        ...state,
        following: _.map(action.result.items, (item) => {
          return {
            ...item.customer,
            cusomerUserRelationshipId: item.id
          }
        })
      }
    }
  )
  
  addToFollowing = (state, action) => {
    const id = action.id || action.customer && action.customer.id
    const customer = action.customer || { id: action.id }
    return {
      ...state,
      following: [
        ...((state.following || []).filter(someCustomer => id !== someCustomer.id)),
        customer
      ]
    }
  }

  removeFromFollowing = (state, action) => {
    const id = action.id || action.customer && action.customer.id
    return {
      ...state,
      following: (state.following || []).filter(someCustomer => id !== someCustomer.id)
    }
  }
}

export default new FollowingReducers()