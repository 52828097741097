import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import SearchBar from '../SearchBar'
import HistoryNavigationHeader from '../HistoryNavigationHeader'

class WebviewScreen extends Component {
  state = {
    src: this.props.src,
    editable: this.props.editable
  }
  componentDidMount () {
    const { src } = this.state
    if (window.cordova) {
      const inAppBrowser = window.cordova.InAppBrowser.open(src, '_blank', 'location=no,footer=no,enableViewportScale=yes,transitionstyle=crossdissolve')
      inAppBrowser.addEventListener('exit', () => {
        this.props.goBack && this.props.goBack()
      })
    } else {
      window.open(src, '_blank')
      this.props.goBack && this.props.goBack()
    }
  }
  onSearch = src => {
    this.setState({ src, editable: false })
  }
  refresh = () => {
    this.iframe.src += ''
  }
  render = () => {
    const { src, editable } = this.state
    const { classes } = this.props
    if (window.cordova) return null
    return (
      <div className={classes.container}>
        <HistoryNavigationHeader refresh={this.refresh} />
        {editable && <SearchBar src={src} onSearch={this.onSearch} />}
        <iframe
          ref={iframe => { this.iframe = iframe }}
          className={classes.iframe}
          src={src}
        />
      </div>
    )
  }
}

WebviewScreen.propTypes = {
  src: PropTypes.string,
  editable: PropTypes.bool.isRequired
}

export default withStyles(style)(WebviewScreen)
