import React, { PureComponent } from 'react'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const enhance = compose(
  withPropsOnChange(
    ['item', 'onClick'],
    ({ item, onClick }) => ({
      isClickable: (onClick || (item && item.onClick))
    })
  ),
  withHandlers({
    handleClick: ({ item, onClick, itemProps = {} }) => e => {
      if (item && item.onClick) {
        item.onClick(e)
      }
      if (onClick) {
        const handler = onClick({ itemProps, ...item })
        handler && handler(e)
      }
    }
  }),
  withStyles(style)
)

class ListItem extends PureComponent {
  renderContent () {
    const { renderItem, ItemComponent, item, itemIndex, itemProps, narrow, onClick, editable } = this.props
    if (renderItem) {
      return renderItem({ ...item, ...itemProps, itemIndex, narrow })
    } else if (ItemComponent) {
      return <ItemComponent {...item} {...itemProps} itemIndex={itemIndex} narrow={narrow} onClick={onClick} editable={editable} />
    } else {
      return null
    }
  }

  render () {
    const { classes, narrow, noMargin, handleClick, isClickable, noBorder, boxStyle, gutter } = this.props
    const containerClass = classNames({
      [classes.itemContainer]: true,
      [classes.clickable]: isClickable,
      [classes.gutter]: gutter
    })

    const innerClass = classNames({
      [classes.itemInner]: true,
      [classes.narrow]: narrow,
      [classes.noMargin]: noMargin,
      [classes.noBorder]: noBorder,
      [classes.boxInner]: boxStyle
    })

    return (
      <li
        className={containerClass}
        onClick={handleClick && handleClick}
      >
        <div className={innerClass}>
          {this.renderContent()}
        </div>
      </li>
    )
  }
}

export default enhance(ListItem)
