import * as env from '../config/configFiles/environments/local.json'

const util = require('util')
const _ = require('lodash')

class DigitalStoreClientLogger {
  constructor (context) {
    this.logger = (preface, item) => log(preface, item)
    this.context = context ? ` (${context})` : ''
  }
  trace = (args) => {
    this.logger(`TRACE:${this.context}:`, args)
  }
  info = (args) => {
    this.logger(`INFO:${this.context}:`, args)
  }
  debug = (args) => {
    this.logger(`DEBUG:${this.context}:`, args)
  }
  error = (args) => {
    this.logger(`ERROR:${this.context}`, args)
  }
  clone = (context) => {
    return new DigitalStoreClientLogger(context)
  }
}

// Log any given item w/ date
const log = (preface, item) => {
  if (env.LOGS && !window.hideDigitalStoreLogs) {
    const date = new Date()
    if ((typeof item) === 'object') {
      console.log(`${preface} - ${date}: ${String.prototype.concat(util.inspect(item, false, null))}`)
    } else if (item) {
      console.log(`${preface} - ${date}: ${item}`)
    } else {
      console.log(`${preface} - ${date}: Couldn't find type to log [ERROR]`)
      return _.noop
    }
  }
}

export default new DigitalStoreClientLogger()
