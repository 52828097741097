export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  left: {
    flex: '1 1 auto',
    margin: 0
  },
  right: {
    margin: 0,
    borderLeft: 0,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '& div': {
      fontSize: 15
    }
  },
  fillSpace: {
    flex: 1
  },
  hideOnDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  truncateOnDesktop: {
    [theme.breakpoints.up('sm')]: {
      '& div': {
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  }
})
