import { zIndexMap } from '../../config'

export default () => ({
  container: {
    zIndex: zIndexMap.lockScreen, // Seems to break keyboard when too high - 1301, // Material UI dropdowns are 1300
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    background: '#fff'
  },
  tagline: {
    marginTop: -7,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center'
  },
  taglineText: {
    margin: '0 0 0 5px',
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  lockIcon: {
    height: 20
  },
  logout: {
    marginTop: 10
  }
})
