import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

import { translations } from '../../../config'
import currencyFormatter from '../../../formatters/currencyFormatter'

import style from './style'
import ChartWrapper from '../ChartWrapper'

const format = currencyFormatter.format

const DonutChart = ({
  chartTitle,
  data,
  classes,
  currency,
  paddingAngle,
  target,
  outerRadius,
  // 'totalPrice' prop can be passed in to display a different total
  totalPrice,
  // 'pie' prop distinguishes between pie and donut
  pie
}) => {
  const hasTarget = target !== undefined
  const total = (
    hasTarget
    ? target
    : totalPrice || data.reduce((acc, entry) => acc + entry.value, 0)
  )
  const showChart = total || (hasTarget && target)

  const outerRadiusValue = outerRadius || 70
  const innerRadius = pie ? 0 : 50

  return (
    <ChartWrapper title={chartTitle}>
      {
        showChart
        ? (
          <div className={classes.chartContainer}>
            <div className={classes.pieContainer}>
              <ResponsiveContainer width={'100%'}>
                <PieChart className={classes.rechartsWrapper} dataKey='value' nameKey='name'>
                  <Pie
                    data={data}
                    innerRadius={innerRadius}
                    outerRadius={outerRadiusValue}
                    paddingAngle={0}
                    dataKey='value'
                    isAnimationActive={false}
                  >
                    {
                      data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              {
                  pie
                  ? null
                  : (
                    <div className={classes.pieTotal}>
                      <div>{hasTarget ? translations('target') : translations('total')}</div>
                      <div>
                        {
                          currency
                            ? format({ code: currency, value: total })
                            : total
                        }
                      </div>
                    </div>
                  )
              }
            </div>
            <div className={classes.legendContainer}>
              {
                data.map(({ name, value, color }, i) => (
                  <div className={classes.keyContainer} key={`${name}-${i}`}>
                    <div
                      className={classes.keySquare}
                      style={{backgroundColor: color}}
                    />
                    <div>
                      <div className={classes.keyName}>{name}</div>
                      <div className={classes.keyValue}>
                        {
                          currency
                          ? format({ code: currency, value })
                          : value
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )
        : null
      }
    </ChartWrapper>
  )
}

DonutChart.propTypes = {
  chartTitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string
  })),
  label: PropTypes.func,
  currency: PropTypes.string,
  paddingAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  pie: PropTypes.bool,
  totalPrice: PropTypes.number
}

Cell.propTypes = {
  fill: PropTypes.array.isRequired
}

DonutChart.defaultProps = {
  paddingAngle: 10
}

export default withStyles(style)(DonutChart)
