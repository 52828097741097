import { connect } from 'react-redux'
import _ from 'lodash'
import { isValid, getFormValues } from 'redux-form'

import UserRegistrationForm, { formId } from './UserRegistrationForm'
import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { selectors as roleSelectors } from '../../../../store/modules/roles'

const mapStateToProps = (state, ownProps) => {
  const isFormValid = isValid(formId)(state)
  const { roleId, storeIds } = getFormValues(formId)(state) || {}
  let disabled = false
  const role = roleSelectors.getRoleById(roleId)(state)
  if (role && !role.isAdmin && _.isEmpty(storeIds)) {
    disabled = true
  } else if (role && role.isAdmin) {
    disabled = false
  }
  return {
    isFormValid: isFormValid && disabled
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit (data) {
    const modifiedData = _.omit(data, 'confirmPassword')
    return dispatch(userDetailsActions.createUser(modifiedData))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationForm)
