import React from 'react'
import { Route } from 'react-router'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import AppointmentsDashboardScreen from './AppointmentsDashboardScreen'
import NewStoreConsultation from './Consultation/NewStoreConsultation'

// import StoreConsultation from './Consultation/StoreConsultation'
// import ConsultantScreen from './VirtualConsultation/ConsultantScreen'
// import CustomerScreen from './VirtualConsultation/CustomerScreen'
import {
  ConsultantScreen,
  CustomerScreen,
  InStoreConsultantScreen
} from '../../retailos'

export default [
  // Appointments dashboard
  <PrivateRoute key='/consultations' path='/consultations' exact component={TimedLogoutComponent(AppointmentsDashboardScreen)} allowOffline />,
  // In-Store Sonsultations
  <PrivateRoute key='/consultations/store/new' path='/consultations/store/new' exact component={TimedLogoutComponent(NewStoreConsultation)} allowOffline />,
  <PrivateRoute key='/consultations/store/:id' path='/consultations/store/:id' exact component={TimedLogoutComponent(InStoreConsultantScreen)} allowOffline />,
  // Consultant's Virtual Consultation Screen
  <PrivateRoute key='/consultations/virtual/:id' path='/consultations/virtual/:id' exact component={TimedLogoutComponent(ConsultantScreen)} />,
  // Customer's (Public) Virtual Consultation Screen
  <Route key='/consultations/virtual/:id/customer' path='/consultations/virtual/:id/customer' exact component={CustomerScreen} />
]
