import { Modules } from '@redant/retailos-ui'
import { selectors as authSelectors } from '../auth'
import actions from './actions'

export const getTotalNotificationsNotViewed = ({ getState, dispatch }) => {
  setInterval(() => {
    const state = getState()
    const isLoggedIn = authSelectors.getIsLoggedIn(state)
    const isVirtualConsultation = Modules.VirtualConsultation.Slices.sharedStateSlice.selectors.isVirtualConsultation(state)
    const isStoreConsultation = Modules.Consultation.Slices.localStateSlice.selectors.isConsultation(state)
    const isConsultation = isStoreConsultation || isVirtualConsultation

  /**
   * This isConsultation check is to fix a bug where this subcription causes unnecessary re-renders with undesirable side effects.
   * FIXME: This is a temporary fix and takes advantage of the fact that the menu icons are hidden in consultations. 
   */
    if (isLoggedIn && !isConsultation) {
        dispatch(actions.getTotalNotificationsNotViewed())
    }
  }, 30 * 1000)
}
