import React from 'react'
import PropTypes from 'prop-types'
import ReactImageMagnify from 'react-image-magnify'
import { ReactPinchZoomPan } from 'react-pinch-zoom-pan'
import IconButton from '@material-ui/core/IconButton'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { compose } from 'recompose'

import { getImage } from '../Images'
import Image from '../Image'
import style from './style'

const closeIcon = getImage('closeIconBlack')
const videoIcon = getImage('videoPlayIcon')
const noImagePlaceholder = getImage('imageNotAvailableImg')

const HorizontalGallery = ({
  getMedia,
  activeMediaIndex,
  handleImageClick,
  classes,
  openExpandedModal,
  inModal = false,
  dismiss,
  inCordova,
  hideThumbnails = false
}) => {
  const containerClass = cx({
    [classes.modalConatiner]: inModal
  })
  const videoClass = cx({
    [classes.mainVideoModal]: inModal
  })
  const mediaContainerClass = cx({
    [classes.mediaContainerInModal]: inModal,
    [classes.mediaContainer]: true
  })
  return (
    <div className={containerClass}>
      <div className={classes.iconContainer}>
        {inModal && (
          <IconButton
            onClick={dismiss}
            aria-label='Close'>
            <img src={closeIcon} />
          </IconButton>)}
      </div>
      <div className={mediaContainerClass}>
        {getMedia().map(({ src, type }, index) => {
          const formattedSrc = src || noImagePlaceholder
          if (activeMediaIndex === index) {
            if (type === 'image' && inModal) {
              const zoomContainerClasses = cx(classes.zoomImageContainer, {
                [classes.nonCordovaZoomContainer]: !inCordova
              })
              return (
                <div className={zoomContainerClasses} key={index}>
                  {!inCordova ? (
                    <ReactImageMagnify {...{
                      smallImage: {
                        isFluidWidth: true,
                        src: formattedSrc
                      },
                      largeImage: {
                        src: formattedSrc,
                        width: 900,
                        height: 1350
                      },
                      enlargedImagePosition: 'over',
                      imageClassName: classes.smallImageClass
                    }} />
                  ) : (
                      <ReactPinchZoomPan maxScale={3} containerRatio={150} render={obj => {
                        return (
                          <div className={classes.pinchContainer}>
                            <div style={{
                              transform: `scale(${obj.scale}) translateY(${obj.y}px) translateX(${obj.x}px)`,
                              transition: '.3s ease',
                              height: '100%'
                            }}>
                              <Image src={src} className={classes.pinchImage} />
                            </div>
                          </div>
                        )
                      }} />
                    )}
                </div>
              )
            }
            if (type === 'image') {
              return (
                <div className={classes.mainImageContainer} onClick={openExpandedModal} key={index}>
                  <Image className={classes.mainImage} src={src} />
                </div>
              )
            }
            if (type === 'embeddedVideo') {
              return (
                <div className={classes.mainVideoContainer} key={index}>
                  <div className={videoClass}>
                    <div className={classes.iframeContainer}>
                      <iframe frameborder='0' scrolling='no' className={classes.iframeStyle} src={src} />
                    </div>
                  </div>
                </div>
              )
            }
          }
        })}
        {
          !hideThumbnails
            ? (
              <div className={classes.scrollContainer}>
                <div className={classes.scrollInner}>
                  {getMedia().map(({ src, type, thumbnail }, index) => {
                    const imageContainerClasses = cx({
                      [classes.imageContainer]: true,
                      [classes.activeImage]: activeMediaIndex === index
                    })
                    const videoThumbnailClasses = cx(imageContainerClasses, classes.videoThumbnailContainer)
                    if (type === 'image') {
                      return (
                        <ButtonBase
                          key={src}
                          className={imageContainerClasses}
                          onClick={() => handleImageClick(index)}
                        >
                          <Image className={classes.image} src={src} />
                        </ButtonBase>
                      )
                    } else if (type === 'embeddedVideo') {
                      return (
                        <ButtonBase
                          key={src}
                          className={videoThumbnailClasses}
                          onClick={() => handleImageClick(index)}
                        >
                          <img className={classes.playIcon} src={videoIcon} />
                          {!!thumbnail && <Image src={thumbnail} />}
                        </ButtonBase>
                      )
                    }
                  }
                  )}
                </div>
              </div>
            )
            : null
        }
      </div>
    </div>
  )
}

HorizontalGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  embeddedVideos: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default compose(
  withStyles(style)
)(HorizontalGallery)
