import React from 'react'
import { useSelector } from 'react-redux'
import { Modules, Hooks } from '@redant/retailos-ui'
import { httpClientService, customerFunctions } from '../dependencies'
import modalService from '../../services/modalService'
import { environment, addressLookupCountries, customerSelfRegistrationEnabled } from '../../config'
import { actions as authActions, selectors as authSelectors } from '../../store/modules/auth'
import { getSelectedRegionLocationLookupCode } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import LoginScreen from '../../containers/Login/LoginScreen'
import StorePinEntry from '../../containers/PinEntry/StorePinEntry'


export const CustomerAssistedRegistrationScreen = (props) => {
  const { afterSubmit } = props
  const regionLookupCode = useSelector(getSelectedRegionLocationLookupCode)

  const customerLinkPath = '/register'
  const registrationUrl = `${environment.CUSTOMER_FACING_URL || window.location.origin}${customerLinkPath}`
  // PasswordModalConfig
  const storePin = useSelector(authSelectors.getUserSelectedStorePin)
  const loginMethod = useSelector(authSelectors.getLoginMethod)
  const storePinEnabledMethods = environment.STORE_PIN_ENABLED || []
  const shouldShowPin = storePin && _.some(storePinEnabledMethods, (method) => method === loginMethod)
  const useAddressLookup = Hooks.AddressLookup.provideUseAddressLookup({
    httpClientService,
    pcaKey: environment.PCA_KEY,
    locationLookupCode: regionLookupCode || (addressLookupCountries && addressLookupCountries.join(','))
  })
  const implementationConfig = {
    addressLookupConfig: {
      useAddressSearch: true,
      manualAddressEntry: false
    },
    selfRegistrationConfig: {
      customerSelfRegistrationEnabled
    },
    passwordModalConfig: {
      shouldShowPin,
      appActions,
      authActions,
      LoginScreen,
      StorePinEntry
    }
  }
  const useScanToRegisterModal =  Modules.Customer.Implementation.provideUseCustomerRegistrationToken({
    customerFunctions,
    registrationUrl
    })
  
  const useCustomerAssistedRegistrationScreen = Modules.Customer.Implementation.provideUseCustomerAssistedRegistrationScreen({
    customerFunctions,
    modalService,
    implementationConfig,
    afterSubmit,
    hooks: {
      useScanToRegisterModal,
      useAddressLookup
    }
  })


  const uiConfig = {
    align: 'center'
  }

  return (
    <Modules.Customer.Screens.CustomerAssistedRegistrationScreen
      implementation={useCustomerAssistedRegistrationScreen}
      uiConfig={uiConfig}
    />
  )
}
