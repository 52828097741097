import { connect } from 'react-redux'
import { selectors as rolesSelectors } from '../../../store/modules/roles'

import UserRegistrationScreen from './UserRegistrationScreen'

const mapStateToProps = state => {
  const isLoading = rolesSelectors.getIsLoading(state)
  return {
    isLoading
  }
}

export default connect(mapStateToProps)(UserRegistrationScreen)
