import React from 'react'

import Container from '../../../../components/Container'
import OrdersTable from './OrdersTable'
import TitleBar from './TitleBar'

const Results = props => {
  return <Container withMarginBottom fullWidth>
    <TitleBar {...props} />
    <OrdersTable {...props} />
  </Container>
}

export default Results
