import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import Departments from './Departments'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as departmentSelectors } from '../../../../../store/modules/departments'
import { selectors as rolesSelectors } from '../../../../../store/modules/roles'

const selector = formValueSelector('roles-and-stores')

const mapStateToProps = state => {
  const selectedRoleId = selector(state, 'roleId') || ''
  const selectedRole = rolesSelectors.getRoleById(selectedRoleId)(state)
  const selectedDepartmentIds = selector(state, 'departmentIds') || []
  const formattedSelectedDepartmentNames = departmentSelectors.getDepartmentNamesTruncatedDisplay(selectedDepartmentIds)(state)
  return {
    selectedRole,
    departmentOptions: authSelectors.getUserDepartmentsOptions(state),
    formattedSelectedDepartmentNames
  }
}

export default connect(
  mapStateToProps
)(Departments)
