import { push } from 'connected-react-router'
import _ from 'lodash'
import analyticsService from '../../../../services/analyticsService'

const handleEvents = itemProps => {
  const chartCategory = _.get(itemProps, 'chartCategory')
  switch (chartCategory) {
    case 'customerCountByDate':
      analyticsService.sendCustomEvent({ type: 'customerCountByDateFullListClickThrough' })
      break
    case 'customerCountBySegment':
      analyticsService.sendCustomEvent({ type: 'customerCountBySegmentFullListClickThrough' })
      break
    case 'customerSpendBySegment':
      analyticsService.sendCustomEvent({ type: 'customerSpendBySegmentFullListClickThrough' })
      break
  }
}

export const onClickItem = dispatch => item => {
  const { id, itemProps } = item
  handleEvents(itemProps)
  return () => dispatch(push(`/customers/${id}`))
}
