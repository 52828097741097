import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ViewGalleriesScreen from './ViewGalleriesScreen'
import { selectors as eventDetailsSelectors } from '../../../store/modules/eventDetails'
import withFullScreenLoader from '../../../components/FullScreenLoader'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  return {
    galleries: eventDetailsSelectors.getGalleries(state),
    selectedGalleryIndex: eventDetailsSelectors.getSelectedGalleryIndex(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onCloseClick: () => () => {
      modalService.close()
    }
  }),
  withFullScreenLoader
)(ViewGalleriesScreen)
