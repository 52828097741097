import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import Message from './Message'

export default compose(
  withHandlers({
    goToMessage: ({ goToMessage = _.noop, message }) => () => {
      goToMessage({ id: message.id })
    }
  })
)(Message)
