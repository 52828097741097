import React from 'react'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { SelectProduct } from '../../../../components/EditableFields'
import { translations, recommendedProductsContentOptions } from '../../../../config'
import InspirationsSearchScreen from '../../../Inspirations/InspirationsSearchScreen'

const RecommendedProductsForm = FormContentBox('recommendedProducts')
const editButtonText = RecommendedProductsForm ? translations('Add/Edit') : translations('Edit')

const WrappedRecommendedProductsForm = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, editable }) => {
  return (
    <RecommendedProductsForm
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      editable={editable}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
      saveDisabled={saveDisabled}
      editButtonText={editButtonText}
    >
      <FormError />
      <FormBody>
        <SelectProduct
          options={recommendedProductsContentOptions}
          label='Recommended Products'
          editing={editing}
          name='recommendedProducts'
          inspirationsModalComponent={InspirationsSearchScreen}
        />
      </FormBody>
    </RecommendedProductsForm>
  )
})

export default WrappedRecommendedProductsForm
