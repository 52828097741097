const style = theme => {
  return {
    paragraphSpacing: {
      marginBottom: 5
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    imageSpacing: {
      marginRight: 10
    }
  }
}

export default theme => style(theme)
