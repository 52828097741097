import _ from 'lodash'
import { createSelector } from 'reselect'
import { SUCCESS } from '../../middleware/redux-promise'

export const getQuery = state => _.get(state.inspirations, 'query')
export const getPage = state => _.get(state.inspirations, 'page')
export const getStatus = state => _.get(state.inspirations, 'status')
export const getResults = state => _.get(state.inspirations, 'results', [])
export const getTags = state => _.get(state.inspirations, 'tags', [])
export const getUserId = state => _.get(state.inspirations, 'userId')
export const getTagId = state => _.get(state.inspirations, 'tagId')
export const getType = state => _.get(state.inspirations, 'type')
export const getHasMore = state => state.inspirations.total != null && state.inspirations.results.length < state.inspirations.total
export const getTotal = state => _.get(state.inspirations, 'total')

export const getTagsAsOptions = createSelector(
  [getTags],
  (tags) => {
    return tags.map(t => ({ label: t.title, value: t.id }))
  }
)

export const getHasSearched = createSelector(
  [getQuery, getStatus],
  (query, status) => {
    return !!query && status === SUCCESS
  }
)

export const getHasResults = createSelector(
  [getResults],
  (results) => {
    return results.length > 0
  }
)
