import React, { Component, Fragment } from 'react'
import VisuallyHidden from '@reach/visually-hidden'

import { translations } from '../../../config'

import Heading from '../../../components/Heading'
import Results from './Results'
import SearchBar from './SearchBar'

class ProductsRootScreen extends Component {
  render () {
    return (
      <Fragment>
        <VisuallyHidden>
          <Heading component={'h1'}>{translations('Order Management')}</Heading>
        </VisuallyHidden>
        <SearchBar />
        <Results />
      </Fragment>
    )
  }
}

export default ProductsRootScreen
