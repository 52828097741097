import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import ResourceItem from '../../../ResourceItem'
import style from './style'

class AttachmentItem extends Component {
  viewResource = () => {
    const { url } = this.props
    window.open(url, '_blank')
  }

  render () {
    const { fileName, classes } = this.props
    return <ResourceItem
      Key={fileName}
      viewResource={this.viewResource}
      className={classes.resourceItem}
    />
  }
}

export default withStyles(style)(AttachmentItem)
