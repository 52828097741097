import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class RegionsActions {
  fetchAllRegions = () => ({
    type: constants.FETCH_ALL_REGIONS,
    promise: () => digitalStoreSdk.regions.fetchRegions()
  })
}

export default new RegionsActions()
