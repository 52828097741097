import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import H2 from '../../../../components/H2'
import { getImage } from '../../../../components/Images'
import { translations } from '../../../../config'

class SubmittedOverlay extends PureComponent {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={getImage('checkCircle')} />
        </div>
        <div className={classes.footerContainer}>
          <H2 className={classes.footer} value={translations('Thank you for your response')} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(SubmittedOverlay)
