const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%'
  },
  inputInkbar: {
    color: theme.palette.materialUIFormGrey.main
  }
})

export default styles
