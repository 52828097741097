import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors } from '../pollResults'
import moment from 'moment'
import _ from 'lodash'

const _formatFilterDate = (date) => {
  if (!date) return null
  return moment(date, 'DD/MM/YYYY').format('YYYY/MM/DD')
}

const _formatFilterData = (form) => {
  return _.mapValues(form, (value, key) => {
    if (key === 'dueBefore' || key === 'dueAfter') {
      return _formatFilterDate(value)
    }
    return value
  })
}

class PollResultsActions {
  fetchPollResultsFresh = ({ filters = {} }) => ({
    type: constants.FETCH_POLL_RESULTS_FRESH,
    promise: () => digitalStoreSdk.pollQuestions.fetchPollQuestionInstances({ page: 1, size: 10, ..._formatFilterData(filters) })
  })

  fetchPollResultsNext = ({ filters = {} }) => ({
    type: constants.FETCH_POLL_RESULTS_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const page = selectors.getPage(state)
      const size = selectors.getSize(state)
      return digitalStoreSdk.pollQuestions
        .fetchPollQuestionInstances({ page: page + 1, size, ..._formatFilterData(filters) })
        .then(({ total, results }) => ({ total, results }))
    }
  })

  deletePollQuestionInstanceById = ({ id }) => ({
    type: constants.DELETE_POLL_QUESTION_INSTANCE,
    promise: () => digitalStoreSdk.pollQuestions.deletePollQuestionInstanceById({ id })
  })

  changeFilters = ({ filters }) => ({
    type: constants.CHANGE_POLL_RESULTS_FILTERS,
    filters
  })

  changeFilter = ({ filter }) => ({
    type: constants.CHANGE_POLL_RESULTS_FILTER,
    filter
  })
}

export default new PollResultsActions()
