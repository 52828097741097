import React from 'react'
import Button from '../Button'
import { withStyles } from '@material-ui/core/styles'

import H2 from '../H2'
import style from './style'

const ActionBar = ({ classes, title, buttonTitle, onClick, hideButton }) => {
  return (
    <div className={classes.actionBar}>
      <H2 value={title} className={classes.actionBarHeader} />
      {!hideButton && (
        <Button onClick={onClick} className={classes.reactivateButton} color='primary'>
          {buttonTitle}
        </Button>
      )}
    </div>
  )
}
export default withStyles(style)(ActionBar)
