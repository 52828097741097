import { connect } from 'react-redux'
import { change } from 'redux-form'

import { actions as rolesActions, selectors as rolesSelectors } from '../../../store/modules/roles'
import modalService from '../../../services/modalService'

import UsersSearchScreen from '../../../containers/Users/UsersSearchScreen'

import SelectUser from './SelectUser'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { formName, name } = ownProps
  return {
    ...ownProps,
    onSearchClick: () => {
      const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(state)
      const rolesStoreHasError = rolesSelectors.getHasError(state)
      if (rolesStoreIsNotLoaded || rolesStoreHasError) {
        dispatch(rolesActions.fetchAllRoles())
      }
      modalService.open({
        modalIndex: 2,
        component: UsersSearchScreen,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        onUserClick: user => {
          const { id, firstName, lastName, accountDeactivated } = user
          if (!accountDeactivated) {
            const value = {
              id,
              firstName,
              lastName,
              type: 'user'
            }
            dispatch(change(formName, name, value))
            modalService.close({ modalIndex: 2 })
          }
        },
        onBackClick: () => modalService.close({ modalIndex: 2 })
      })
    },
    onRemoveUserClick: () => dispatch(change(formName, name, ''))
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectUser)
