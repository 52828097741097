import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import UserRegistrationForm from '../../../containers/Users/UserRegistrationScreen/UserRegistrationForm'
import FullScreenProgress from '../../../components/FullScreenLoader'
import style from './style'
import { translations } from '../../../config'

const UserRegistrationScreen = ({classes}) => (
  <div>
    <SubHeader
      leftContent={(
        <BackBar />
      )}
      centerContent={(
        <H2 value={_.toUpper(translations('Add a new user'))} />
      )}
    />
    <div className={classes.contentContainer}>
      <p className={classes.requiredFieldsText}>{translations('required fields')}</p>
      <UserRegistrationForm />
    </div>
  </div>
)

export default FullScreenProgress(
  withStyles(style)(UserRegistrationScreen)
)
