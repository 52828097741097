import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import InfiniteScroll from 'react-infinite-scroller'
import TableView from './TableView'
import GridView from './GridView'
import { translations } from '../../../../../config'
import Container from '../../../../../components/Container'
import style from './style'
import H2 from '../../../../../components/H2'

const OrdersTable = ({
  classes,
  orders,
  goToOrder,
  searchOrdersNext,
  hasMore,
  noResults
}) => {
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.inner}>
          <InfiniteScroll
            pageStart={0}
            loadMore={searchOrdersNext}
            hasMore={hasMore}
            initialLoad={false}
            useWindow
          >
            {useMediaQuery(theme => theme.breakpoints.up('lg')) ? (
              <TableView orders={orders} onOrderClick={goToOrder} />
            ) : (
                <GridView orders={orders} onOrderClick={goToOrder} />
            )}
          </InfiniteScroll>
        </div>
      </div>
      {!!noResults && (
        <Container
          withMarginTop
          className={classes.noResultsMessage}
        >
          <H2 value={translations('No Results')} />
        </Container>
      )}
    </Fragment>
  )
}

export default compose(
  withStyles(style)
)(OrdersTable)
