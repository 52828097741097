import React from 'react'
import { Modules } from '@redant/retailos-ui'
import {
  httpClientService,
  useConsultationSharedState,
  virtualConsultationFunctions
} from '../dependencies'
import modalService from '../../services/modalService'
import { getAppConfig, environment } from '../../config'
import { ProductRouter } from '../ProductRouter'
import { actions as currentOrderActions } from '../../store/modules/currentOrder'
import { actions as appActions } from '../../store/modules/app'
import { VirtualConsultationCheckout } from '../Checkout'
import './uiConfig'
import { getUiConfig } from '../uiConfigStore'

export const ConsultantScreen = ({ match }) => {
  const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields', [])
  const featuresConfig = {
    features: {
      wishlist: getAppConfig('CONSULTATIONS', 'wishlist', true),
      ...getUiConfig('Modules.VirtualConsultation.Screens.ConsultantScreen').features
    }
  }

  const useEndConsultation = Modules.VirtualConsultation.Implementation.provideUseEndConsultation({
    modalService,
    httpClientService,
    currentOrderActions,
    appActions
  })

  const useFinishConsultation = Modules.VirtualConsultation.Implementation.provideUseFinishConsultation({
    modalService,
    httpClientService,
    currentOrderActions,
    appActions
  })

  const useSelectItems = Modules.VirtualConsultation.Implementation.provideUseSelectItems({
    virtualConsultationFunctions,
    modalService,
    ProductRouter
  })

  const useCheckout = Modules.VirtualConsultation.Implementation.provideUseCheckout({
    modalService,
    CheckoutComponent: VirtualConsultationCheckout,
    checkoutType: 'virtualConsultation'
  })

  const useResourceDetails = Modules.Abstract.Implementation.provideUseResourceDetails({
    httpClientService
  })

  const implementation = Modules.VirtualConsultation.Implementation.provideUseConsultantScreen({
    virtualConsultationFunctions,
    httpClientService,
    routeParams: match.params,
    modalService,
    customerFieldsConfig,
    hooks: {
      useEndConsultation,
      useFinishConsultation,
      useSelectItems,
      useSharedState: useConsultationSharedState,
      useResourceDetails,
      useCheckout
    }
  }, 'user')

  return (
    <Modules.VirtualConsultation.Screens.ConsultantScreen
      implementation={implementation}
      uiConfig={{
        ...getUiConfig('Modules.VirtualConsultation.Screens.ConsultantScreen'), 
        ...featuresConfig,
        digitalStorePayUrl: environment.DIGITAL_STORE_PAY_URL
      }}
    />
  )
}
