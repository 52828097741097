const styles = theme => ({
  rightContentContainer: {
    padding: 30
  },
  messageInput: {
    minHeight: 200,
    maxHeight: 428
  }
})

export default styles
