import { useSelector } from 'react-redux'

import {
  getIsLoading,
  getResults,
  getCalendarOptions
} from '../store/modules/calendars/selectors'

/**
 * @description returns boolean
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns calendars
 * @returns
 */
export const useResults = () => {
  return useSelector(getResults)
}

/**
 * @description returns calendars as options
 * @returns
 */
export const useCalendarOptions = () => {
  return useSelector(getCalendarOptions)
}
