export default theme => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 4,
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 200,
      margin: 'auto'
    },
    '& > div': {
      padding: 11
    }
  },
  searchButtonStyle: {
    alignSelf: 'center',
    fontWeight: 600,
    marginBottom: 10,

    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
      marginBottom: 0
    }
  },
  contextBoxTitle: {
    marginRight: 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      whiteSpace: 'initial',
      lineHeight: 1.5
    }
  },
  selfSend: {
    marginBottom: 10,

    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
      marginBottom: 0
    }
  },
  headingWrap: {
    width: '100%',
    margin: 'auto'
  },
  recipientTextStyle: {
    fontSize: 14,
    padding: 5,
    fontWeight: 500
  }
})
