const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10
  },
  containerMargin: {
    marginRight: 30
  },
  bold: {
    fontWeight: 'bold'
  }
})

export default style
