import React, { PureComponent } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import DailyQuestionRow from '../DailyQuestionRow'
import WeeklyQuestionRow from '../WeeklyQuestionRow'
import MonthlyQuestionRow from '../MonthlyQuestionRow'
import style from './style'

const sleep = (ms) => (...args) => new Promise((resolve) => setTimeout(() => resolve(...args), ms))

class QuestionRow extends PureComponent {
  state = {
    showSubmittedOverlay: false
  }
  saveAnswers = () => {
    const { saveAnswers, removeQuestion } = this.props
    return saveAnswers()
      .then(() => {
        this.setState && this.setState({ showSubmittedOverlay: true })
      })
      .then(sleep(1000))
      .then(() => {
        removeQuestion()
      })
  }
  render () {
    const { showSubmittedOverlay } = this.state
    const { classes, question, ...rest } = this.props
    const questionType = _.get(question, 'type')
    switch (questionType) {
      case 'daily':
        return <DailyQuestionRow question={question} {...rest} showSubmittedOverlay={showSubmittedOverlay} saveAnswers={this.saveAnswers} />
      case 'weekly':
        return <WeeklyQuestionRow question={question} {...rest} showSubmittedOverlay={showSubmittedOverlay} saveAnswers={this.saveAnswers} />
      case 'monthly':
        return <MonthlyQuestionRow question={question} {...rest} showSubmittedOverlay={showSubmittedOverlay} saveAnswers={this.saveAnswers} />
      default:
        return null
    }
  }
}

export default withStyles(style)(QuestionRow)
