import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import FullProduct from './FullProduct'
import RefundProduct from './RefundProduct'

import { logger } from '../../../../../helpers'

import style from './style'

const OrderProductContent = props => {
  const { products, classes, refund } = props
  return (
    <div className={classes.orderProductContentContainer}>
      {
        products.map((product, index) => {
          return !refund
            ? (<FullProduct key={`${product.id} ${index}`} product={product} />)
            : (<RefundProduct key={`${product.id} ${index}`} product={product} />)
        })
      }
    </div>
  )
}

OrderProductContent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
  classes: PropTypes.arrayOf(PropTypes.shape({})),
  refund: PropTypes.boolean
}

export default compose(
  withStyles(style)
)
(OrderProductContent)
