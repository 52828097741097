import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InStoreStock from './InStoreStock'
import OnlineStock from './OnlineStock'
import StockInOtherStores from './StockInOtherStores'
import style from './style'

const StockIndicator = ({ classes, overriddenOnlineAvailability, hideInStoreStock }) => {
  return (
    <div className={classes.stockOutter}>
      <div className={classes.stockInner}>
        <div className={classes.indicators}>
          <OnlineStock overriddenOnlineAvailability={overriddenOnlineAvailability} />
          {!hideInStoreStock && <InStoreStock />}
        </div>
        {!hideInStoreStock && <StockInOtherStores />}
      </div>
    </div>
  )
}

export default withStyles(style)(StockIndicator)
