import { push, LOCATION_CHANGE } from 'connected-react-router'
import { actions as customersActions } from '../customers'
import { constants as authConstants } from '../auth'
import { actions as appActions, selectors as appSelectors } from '../app'
import { constants as customerDetailConstants } from '../customerDetails'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { SUCCESS } from '../../middleware/redux-promise'

class CustomerMiddleware {
  createCustomerSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.status === SUCCESS && action.type === customerDetailConstants.CREATE_CUSTOMER) {
      const { firstName, lastName, id } = action.result
      const fullName = `${firstName} ${lastName}`
      if (!action.noPush) {
        dispatch(push(`/customers/${id}`))
      }
      if (!action.silent) {
        modalService.continue({ title: fullName, text: translations('New customer created') })
      }
      next(action)
    } else {
      next(action)
    }
  }

  updateCustomerListOnCustomerUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === customerDetailConstants.UPDATE_CUSTOMER && action.status === SUCCESS) {
      const customer = action.result
      dispatch(customersActions.updateCustomerInList({ customer }))
    }
    next(action)
  }

  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.LOGOUT && action.status === SUCCESS) {
      dispatch(customersActions.resetStore())
    }
    next(action)
  }

  resetCustomerModeOnNavigate = ({ dispatch, getState }) => next => action => {
    const state = getState()
    const customerModeUpdate = appSelectors.getCustomerModeStatus(state)
    if (action.type === LOCATION_CHANGE && customerModeUpdate) {
      /**
       * For when customer mode is exited inadvertently, such as when the app is closed
      */
      dispatch(appActions.customerModeToggle({ isOn: false }))
    }
    next(action)
  }
}

export default new CustomerMiddleware()
