import { colors, zIndexMap, a11y } from '../../../config'

const styles = theme => ({
  container: {
    height: '100%'
  },
  screenContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: `calc(${theme.headerHeight}px + env(safe-area-inset-top))`
  },
  environment: {
    position: 'fixed',
    padding: '10px 12px',
    bottom: 0,
    right: 0,
    background: '#fff',
    zIndex: 1
  },
  noPermissionMessage: {
    marginTop: '10%',
    textAlign: 'center'
  },
  skipToMainContent: {
    position: 'absolute',
    zIndex: zIndexMap.skipToMainContent,
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: a11y.skipToMainContentTextColor,
    '&:focus': {
      width: 'auto',
      height: 'auto',
      whiteSpace: 'nowrap',
      background: a11y.skipToMainContentBackgroundColor,
      padding: 10
    }
  }
})

export default styles
