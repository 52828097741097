import { actions as authActions, selectors as authSelectors } from '../modules/auth'
import digitalStoreSdk from '../../digitalStoreSdk'
import modalService from '../../services/modalService'
import { translations } from '../../config'
import { actions as appActions, selectors as appSelectors } from '../modules/app'

const handleRefreshToken = ({ getState, dispatch }) => {
  // this is the callback for when the bearer token expires
  // and the sdk attempts to get a new one using the refresh token
  const onRefresh = (err, data) => {
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isLoggedIn) {
      if (err) {
        if(_.get(err, 'code') === 'RA-148-02'){
          // this is likely occurred as a user was logged in using offline mode & now has just reconnected
          return dispatch(appActions.lockScreen({ isLocked: true }))
        }

        // if you don't skip API there can be an infinite loop
        return dispatch(authActions.logout({ skipApi: true }))
          .then(() => {
            modalService.continue({
              title: translations(`Token Refresh Failed Modal - Title`),
              text: translations(`Token Refresh Failed Modal - Text`)
            })
          })
      } else {
        return dispatch(authActions.updateUser({ user: data }))
      }
    }
  }

  digitalStoreSdk.setConfig({ onRefresh })
}

export default handleRefreshToken
