import { connect } from 'react-redux'
import OrderCarousel from './OrderCarousel'

import { selectors as customerDetailsSelectors } from '../../store/modules/customerDetails'

const mapStateToProps = state => ({
  orders: customerDetailsSelectors.getCustomerOrders(state)
})

export default connect(mapStateToProps)(OrderCarousel)
