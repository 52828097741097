const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '250px'
    },
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '320px',
    flex: 1
  },
  footer: {
  },
  menuItem: {
    fontSize: 17,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.border.main,
    padding: '15px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 25px'
    },
    fontWeight: 600,
    textTransform: 'uppercase',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  rootMenu: {
    /* Allows content to fill the viewport and go beyond the bottom */
    height: '100%'
  },
  alertBadge: {
    right: 17,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  list: {
    margin: 0,
    padding: 0
  }
})

export default theme => styles(theme)
