import _ from 'lodash'
import { promiseReducer } from '../../util'
import { SUCCESS } from '../../middleware/redux-promise'
import { compactObject } from '../../../helpers'

class InspirationsReducers {
  searchInspirationsFresh = promiseReducer(
    // SUCCESS
    (state, action) => {
      const { total, results } = action.result
      return {
        ...state,
        results,
        total,
        page: 1,
        status: SUCCESS,
        error: undefined
      }
    },
    // PENDING
    (state, action) => {
      const payload = _.get(action, 'payload', {})
      return {
        ...state,
        ...compactObject(payload)
      }
    }
  )
  searchInspirationsNext = promiseReducer(
    (state, action) => {
      const { page, results: currentResults } = state
      const result = _.get(action, 'result', {})
      const { results, total } = result
      return {
        ...state,
        page: ((page || 0) + 1),
        total,
        results: [...currentResults, ...results]
      }
    }
  )
  clearResults = (state, action) => ({
    ...state,
    results: []
  })
  fetchInspirationTags = promiseReducer(
    (state, action) => {
      return {
        ...state,
        tags: action.result
      }
    }
  )
  removeListItem = (state, action) => {
    if (action.status === SUCCESS) {
      const id = _.get(action, 'payload.id')
      const results = state.results.filter(result => result.id !== id)
      return {
        ...state,
        results
      }
    }
    return state
  }
}

export default new InspirationsReducers()
