import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'

import Stores from './Stores'
import Role from './Role'
import Departments from './Departments'
import style from '../style'
import { apps } from '../../../../config'

const RolesAndStoresForm = FormContentBox('roles-and-stores')

const WrappedRolesAndStoresForm = Editable(({ classes, initialValues, editing, toggleEdit,
  boxName, formattedRoleName, editable, isSubmitting, onSubmit, formId }) => {
  return (
    <RolesAndStoresForm
      editable={editable}
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      enableReinitialize
      initialValues={initialValues}
      givenClass={classes.formContainer}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
    >
      <FormError givenClass={classes.submitError} />
      <FormBody>
        <Grid className={classes.flexGridContainer} container spacing={2}>
          <Grid item xs={12} sm={6} >
            <Role
              editing={editing}
              editable={editable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stores
              formattedRoleName={formattedRoleName}
              editing={editing}
              editable={editable}
            />
          </Grid>
          {
            apps.DEPARTMENTS
              ? (
                <Grid item xs={12} sm={6}>
                  <Departments
                    formattedRoleName={formattedRoleName}
                    editing={editing}
                    editable={editable}
                  />
                </Grid>
              )
              : null
          }
        </Grid>
      </FormBody>
    </RolesAndStoresForm>
  )
})

export default withStyles(style)(WrappedRolesAndStoresForm)
