
class CacheService {

  setItem = (key, data) => {
    const wrappedData = {
      value: data
    }
    return localStorage.setItem(key, JSON.stringify(wrappedData))
  }

  getItem = (key) => {
    const storedItem = localStorage.getItem(key)
    return storedItem && JSON.parse(storedItem).value
  }
  
  removeItem = (key) => {
    return localStorage.removeItem(key)
  }

}
export default new CacheService()
