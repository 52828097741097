import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { compose, withHandlers, withProps, withPropsOnChange } from 'recompose'
import { selectors as productDetailSelectors, actions as productDetailsActions } from '../../../../../../store/modules/productDetails'
import { selectors as currentOrderSelectors, actions as currentOrderActions } from '../../../../../../store/modules/currentOrder'
import { selectors as currentAppointmentSelectors, actions as currentAppointmentActions } from '../../../../../../store/modules/currentAppointment'
import { selectors as authSelectors } from '../../../../../../store/modules/auth'

import ProductsGridItem from './ProductsGridItem'

const mapStateToProps = (state) => {
  return {
    selectedStoreId: authSelectors.getUserSelectedStoreId(state),
    selectedProducts: currentAppointmentSelectors.getAppointmentProducts(state),
    currentOrderProducts: currentOrderSelectors.getCurrentOrderProducts(state)
  }
}
export default compose(
  // for dispatch
  connect(mapStateToProps),
  withProps(props => {
    const { product } = props
    const hasPriceRange = productDetailSelectors.getProductHasPriceRange(product)
    return {
      displayPrice: productDetailSelectors.getPriceDisplayForProduct(product),
      saveAmount: hasPriceRange ? 0 : productDetailSelectors.getSaveAmount(product),
      discountValue: hasPriceRange ? 0 : productDetailSelectors.getDiscountValueOfProduct(product),
      originalPrice: productDetailSelectors.getOriginalProductPrice(product)
    }
  }),
  withPropsOnChange(['onProductClick', 'selectedProducts'], ({ product, onProductClick, selectedProducts, currentOrderProducts }) => {
    return {
      hasAddToBasket: !onProductClick,
      selected: !!_.find(selectedProducts, p => p.id === product.id)
    }
  }),
  // this nonsense is because:
  // in normal PLP, clicking picture goes to PDP, but clicking the button
  // adds the product to your basket
  // for PLP in-modal (eg: wishlist), clicking picture OR clicking the
  // button both call the passed `onProductClick` function with the
  // correpsonding product object as the argument
  withHandlers({
    onProductClick: ({ dispatch, product, onProductClick, push }) => () => {
      if (onProductClick) {
        onProductClick(product)
      } else {
        if (push) {
          push(`/product/${product.id}`)
        } else {
          dispatch(push(`/product/${product.id}`))
        }
      }
    },
    fetchProductDetails: ({ dispatch, product, selectedStoreId }) => () => {
      dispatch(productDetailsActions.fetchProduct({
        id: _.get(product, 'id'),
        storeId: selectedStoreId
      }))
    },
    onRequestItemClick: ({ dispatch }) => ({ product, notes }) => {
      dispatch(currentOrderActions.addRequestItem({ product, notes }))
    }
  })
)(ProductsGridItem)
