import React, { useCallback, useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetFormValues } from '../../../../hooks/formHooks'
import { useIsLoading } from '../../../../hooks/productsHooks'
import { submit, change } from 'redux-form'
import CircularProgress from '@material-ui/core/CircularProgress'

import _ from 'lodash'
import styled from 'styled-components'

import { selectors as productsSelectors, actions as productsActions } from '../../../../store/modules/products'
import { selectors as configSelectors } from '../../../../store/modules/config'
import Button from '../../../../components/Button'
import { consultationModules, translations } from '../../../../config'
import Form, { FormBody } from '../../../../components/Form'

export const formId = 'productsFilters'

const prefix = '__'

const SearchFilterForm = Form(formId)

const ButtonContainer = styled.div`
  margin-top: 10px;
`

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.8);
  z-index: 999;
`

const NoFiltersMessage = styled.div`
  font-size: 16px;
  text-align: center;
`

const SearchFilters = ({
  onClose
}) => {
  const dispatch = useDispatch()
  const availableFilters = useSelector(productsSelectors.getAvailableFilters)
  const activeFiltersList = useSelector(productsSelectors.getActiveFilters)
  const category = useSelector(productsSelectors.getCategory)
  const [activeFilters, setActiveFilters] = useState(activeFiltersList)
  useEffect(() => {
    if(activeFilters && typeof activeFilters === 'object') {
      Object.keys(activeFilters).forEach((filterKey) => {
        const value = activeFilters[filterKey]
        if(value && value instanceof Array && value.length > 0) {
          const newKey = `${prefix}${filterKey}`
          activeFilters[newKey] = activeFilters[filterKey]
        }
        delete activeFilters[filterKey]
      })
    }
    setActiveFilters(activeFilters)
  }, [activeFilters])
  
  const numberOfActiveFilters = useSelector(productsSelectors.getNumberOfActiveFilters)
  const remoteConfig = useSelector(configSelectors.getConfig)
  const formValues = useGetFormValues(formId)
  const isLoading = useIsLoading()

  const getNewFilters = (filters) => _
    .chain(filters)
    .mapValues((filterValue, filter) => _.isArray(filterValue) ? filterValue.filter(_.identity) : filterValue)
    .pickBy(filterValue => {
      return _.isArray(filterValue) ? !!filterValue.length : _.identity
    })
    .value()

  const onSubmit = useCallback(() => {
    const newFilters = getNewFilters(formValues)
    Object.keys(newFilters).forEach((filterKey) => {
      const newKey = filterKey.replace(`${prefix}`, '')
      newFilters[newKey] = newFilters[filterKey]
      delete newFilters[filterKey]
    })
    dispatch(productsActions.searchProductsFresh({ filters: newFilters, category }))
  })

  const handleInputChange = useCallback(async (e) => {
    const name = _.get(e, 'target.name')
    
    const value = _.get(e, 'target.value')
    
    await dispatch(change(formId, name, value))
    await dispatch(submit(formId))
  }, [])

  const handlePriceChange = useCallback(async (value) => {
    await dispatch(change(formId, 'price', value))
    await dispatch(submit(formId))
  }, [])

  const clearFilters = useCallback(() => {
    dispatch(productsActions.searchProductsFresh({ filters: {}, category }))
    onClose && onClose()
  })

  const filtersSchema = useMemo(() => {
    const excludes = _.get(remoteConfig, 'filterExcludes', [])

    let formattedFilters = []
    if (_.isArray(availableFilters)) {
      formattedFilters = availableFilters
    } else {
      for (const [key, value] of Object.entries(availableFilters)) {
        if (key.startsWith('price')) {
          const filter = {
            id: key,
            label: 'Price',
            values: value
          }
          formattedFilters.push(filter)
        } else {
          const elseFilter = {
            id: key,
            label: _.startCase(key),
            values: value
          }

          formattedFilters.push(elseFilter)
        }
      }
    }

    const toBeMapped = formattedFilters.filter(filter => {

      return !excludes.find(exclude => exclude === filter.id)
    })
    return _.compact(toBeMapped.map(filter => {
      if (_.size(filter.values) === 0) return // hide empty filters
      if (filter.id === 'price') {
        return
        
        // ADD PRICE FUNCTIONALITY IF REQUIRED
        
        /*
        const range = _.get(filter, 'values')
        
        if (range) {
          const {min, max} = range
          return {
            id: filter.id,
            field: 'Slider',
            props: {
              name: `${prefix}${filter.id}`,
              label: filter.label,
              min: parseInt(min),
              max: parseInt(max),
              onChange: _.debounce(handlePriceChange, 500)
            }
          }
        }
        */

      } else {
        return {
          id: filter.id,
          field: 'Dropdown',
          props: {
            name: `${prefix}${filter.id}`,
            label: filter.label,
            options: filter.values.map(option => ({
              label: option.label || _.startCase(option.value),
              value: option.value
            })),
            multiple: true,
            onChange: handleInputChange
          }
        }
      }
    }))

  }, [availableFilters])
  
  return <>
    <SearchFilterForm
      editing={!isLoading}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={activeFilters}
    >
      {_.size(availableFilters)
        ? <FormBody
          schema={filtersSchema}
          fullWidthFields
        />
        : <NoFiltersMessage>{translations('Products Filters - No available')}</NoFiltersMessage>
      }
    </SearchFilterForm>
    {numberOfActiveFilters > 0 && <ButtonContainer>
      <Button fullWidth onClick={clearFilters}>
        {translations('Clear Filters')}
      </Button>
    </ButtonContainer>}
    <ButtonContainer>
      <Button fullWidth onClick={onClose}>
        {translations('Close')}
      </Button>
    </ButtonContainer>
    {isLoading && (<LoadingOverlay>
      <CircularProgress size={50} />
    </LoadingOverlay>)}
  </>
}


export default SearchFilters
