import React from 'react'
import Time from './Time'

class TimeContainer extends React.Component {
  handleChange = (e, type) => {
    const { value } = e.target
    const currentTimes = this.formatValue(this.props.value)
    const { hours, mins } = { ...currentTimes, [type]: value || '' }
    const time = `${hours}:${mins}`
    return time !== ':' ? time : null
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  formatValue = (value) => {
    if (value) {
      const [hours, mins] = value.split(':')
      return { hours, mins }
    } else {
      return { hours: '', mins: '' }
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <Time {...props} />
  }
}

export default TimeContainer
