import { connect } from 'react-redux'
import FollowingListItem from './FollowingListItem'

import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../store/modules/auth'
import analyticsService from '../../../services/analyticsService'
import { apps } from '../../../config'

const mapStateToProps = (state, ownProps) => {
  const { noMessageButton, anonymised } = ownProps
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const isMessagingEnabled = isMessagingAllowedForRole && !noMessageButton && apps.MESSAGING && !anonymised
  return {
    isMessagingEnabled
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const sendMessage = (e) => {
    e.stopPropagation()
    // ownProps is the followed customer
    dispatch(createMessageActions.updateReceipient(ownProps))
    analyticsService.sendCustomEvent({ type: 'clientBookCustomerSendMessage' })
    analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'clientBookFollowedCustomers' })
  }
  return {
    sendMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(FollowingListItem)
