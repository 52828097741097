import React from 'react'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import RelatedProductItem from '../../../../components/RelatedProductItem'
import P from '../../../../components/P'
import { translations } from '../../../../config'

const RelatedProductsScroller = ({classes, relatedProducts, hasRelatedProducts}) => (
  <div className={classes.scrollContainer}>
    <div className={classes.scrollInner}>
      <div className={classes.productsContainer}>
        { hasRelatedProducts
          ? _.map(relatedProducts, (product, key) => (<RelatedProductItem product={product} key={key} />))
          : <P value={translations('There are no related products at this time')} />
        }
      </div>
    </div>
  </div>
)

export default withStyles(style)(RelatedProductsScroller)
