const style = theme => ({
  listContainerTest: {
    background: theme.palette.lightGrey.main,
    padding: '20px 30px',
    borderTop: `1px solid ${theme.palette.border.main}`,
    height: `calc(100vh - ${theme.headerHeight + theme.subheaderHeight}px)`,
    overflow: 'auto'
  }
})

export default style
