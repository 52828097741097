import React from 'react'

import HybridRouter from '../../../components/HybridRouter'
import BasketScreen from '../BasketScreen'
import ProductDetailScreen from '../../ProductDetail/ProductDetailScreen'

const routes = [
  { path: '/basket', component: BasketScreen, exact: false },
  { path: '/product/:id', component: ProductDetailScreen, exact: true}
]

const BasketRouter = props => {
  const { noRouting, exit, ...passedProps } = props
  // this wrapper div stops unwanted flex behaviour inside modal
  // (material modal has flex & flex-direction: column)
  return <div>
    <HybridRouter
      routes={routes}
      noRouting={noRouting}
      exit={exit}
      passedProps={passedProps}
      initialPath='/basket'
    />
  </div>
}

export default BasketRouter
