import { translations } from '../../../config'
import modalService from '../../../services/modalService'

import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'

const getFullName = customerResult => `${customerResult.firstName} ${customerResult.lastName}`

export default ({ id, firstName, lastName, following, dispatch }) => {
  const fullName = getFullName({ firstName, lastName })

  modalService.action({
    title: translations('Anonymise Customer Title'),
    text: translations('Anonymise Customer Text', { customer: fullName }),
    actions: [
      {
        success: true,
        text: translations('Confirm'),
        onClick: () => dispatch(customerDetailsActions.anonymiseCustomer(id)),
        primary: true
      },
      {
        text: translations('Cancel')
      }
    ]
  })
}
