import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'

import ViewCustomerSnapshotScreen from './ViewCustomerSnapshotScreen'

import { selectors as customerDetailsSelectors } from '../../../../store/modules/customerDetails'
import { selectors as storesSelectors } from '../../../../store/modules/stores'

const mapStateToProps = state => ({
  isCustomerDetailLoading: customerDetailsSelectors.getInitialCustomerDetailIsLoading(state),
  isStoresLoading: storesSelectors.getStoresIsLoading(state)
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['isCustomerDetailLoading', 'isStoresLoading'],
    ({ isCustomerDetailLoading, isStoresLoading }) => ({
      isLoading: isCustomerDetailLoading || isStoresLoading
    })
  )
)(ViewCustomerSnapshotScreen)
