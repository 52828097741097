import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import Dropdown from '../Dropdown'
import Input from '../Input'

import style from './style'


const Email = ({
  label,
  value,
  onChange,
  onBlur,
  emailDomainOptions,
  options,
  passedProps,
  meta: { error, touched },
  touchField,
  classes
}) => {
  return (
    <>
      <InputLabel classes={{ focused: classes.floatingLabelFocusStyle }} htmlFor={label} key='label' shrink>
        {label}
      </InputLabel>
      <div key='inputs' className={classes.fieldContainer}>
        <div className={classes.inputContainer}>
          <Input type='text' onChange={(e) => onChange(e, 'main')} onBlur={(e) => onBlur(e, 'main')} value={value.main} />
        </div>
        <FormControl className={classes.dropdownContainer}>
          <Dropdown
            options={emailDomainOptions}
            name='domain'
            hideClear
            createable
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }
      
              return filtered;
            }}
            value={value.domain}
            onChange={(e) => {
              touchField()
              onChange(e, 'domain')
            }}
            onBlur={(e) => onBlur(e, 'domain')}
            autocomplete
            strict={'true'}
          />
        </FormControl>
      </div>
      <FormHelperText className={classes.errorStyle} key='helper-text'>{touched && error}</FormHelperText>
    </>
  )
}

Email.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  }),
  touchField: PropTypes.func.isRequired
}

export default withStyles(style)(Email)
