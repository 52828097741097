import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { selectors as appSelectors } from '../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../store/modules/auth'
import { actions as currentOrderActions } from '../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import digitalStoreSdk from '../../digitalStoreSdk'
import modalService from '../../services/modalService'
import analyticsService from '../../services/analyticsService'
import { menu, translations } from '../../config'

const mapStateToProps = state => {
  const isOpen = appSelectors.getIsMenuOpen(state)
  const items = menu
  const productsInBasket = currentOrderCombinedSelectors.getCurrentOrderProducts(state)
  const currentOrder = state.currentOrder
  const currentUser = authSelectors.getCurrentUser(state)
  const loggedInStoreId = authSelectors.getUserSelectedStoreId(state)
  const hasSaveBasketPermission = authSelectors.getHasSaveBasket(state)
  return {
    isOpen,
    items,
    productsInBasket,
    currentOrder,
    currentUser,
    loggedInStoreId,
    hasSaveBasketPermission
  }
}

export default () => (Component) => {
  const enhancer = compose(
    connect(mapStateToProps),
    withHandlers({
      handleLogout: ({ dispatch, productsInBasket, currentOrder, loggedInStoreId, hasSaveBasketPermission }) => () => {
        return new Promise((resolve) => {
          if (productsInBasket && productsInBasket.length && hasSaveBasketPermission) {
            modalService.action({
              title: translations('Save Basket Title'),
              actions: [
                {
                  success: true,
                  text: translations('Save Basket Confirm'),
                  onClick: () => {
                    digitalStoreSdk.users.setCurrentOrder({ currentOrder, loggedInStoreId })
                    dispatch(push('/'))
                    dispatch(authActions.logout()).then(resolve)
                  },
                  primary: true
                },
                {
                  text: translations('Save Basket Cancel'),
                  onClick: () => {
                    // the clearOrder middleware handles sdk setCurrentOrder
                    analyticsService.sendCustomEvent({ type: 'clearBasket' })
                    dispatch(currentOrderActions.clearOrder())
                    dispatch(push('/'))
                    dispatch(authActions.logout()).then(resolve)
                  }
                }
              ]
            })
          } else {
            dispatch(push('/'))
            dispatch(authActions.logout()).then(resolve)
          }
        })
      }
    })
  )

  return enhancer(Component)
}
