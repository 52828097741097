export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    boxSizing: 'border-box',

    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  keyValuePair: {
    width: '33%',

    [theme.breakpoints.down('xs')]: {
      width: '50%'
    },

    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  }
})
