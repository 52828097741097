import React from 'react'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

import style from './style'
import SafeAreaSpacer from '../SafeAreaSpacer'
import Button from '../Button'
import { translations } from '../../config'

const TypeIcons = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

const ScanditFeedbackUI = ({
  classes,
  onClose,
  scanStatus,
  statusMessage
}) => {
  const StatusIcon = scanStatus && TypeIcons[scanStatus.type]
  const colorClass = scanStatus && classes[scanStatus.type]
  return (
    <>
      <div className={classNames([classes.feedback, classes.top])}>
        <SafeAreaSpacer inset='top'/>
        { scanStatus && scanStatus.type &&
          <span className={classNames([colorClass, classes.icon])}>
            <StatusIcon/>
            <span className={classes.statusMessage}>{statusMessage}</span>
          </span>
        }
      </div>
      <div className={classNames([classes.feedback, classes.bottom])}>
        <Button
          variant='outlined'
          onClick={onClose}
        >
          {translations('Close')}
        </Button>
        <SafeAreaSpacer inset='bottom'/>
      </div>
    </>
  )
}

export default withStyles(style)(ScanditFeedbackUI)
