import React, { Component } from 'react'
import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

class UsersSearchInput extends Component {
  state = {
    query: this.props.query
  }

  searchUsers = _.debounce(({ query }) => {
    const { searchUsers } = this.props
    searchUsers({ query })
  }, 300)

  updateQuery = (e) => {
    const query = e.target.value
    this.setState({ query })
    this.searchUsers({ query })
  }

  render () {
    const { classes } = this.props
    return (
      <SearchInput
        value={this.state.query}
        placeholder={translations('Look up users')}
        onChange={this.updateQuery}
        autoFocus={false}
      />
    )
  }
}

export default withStyles(styles)(UsersSearchInput)
