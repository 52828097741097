import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined,
  results: [],
  filters: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_POLL_RESULTS_FRESH:
      return reducers.fetchPollResultsFresh(state, action)
    case constants.FETCH_POLL_RESULTS_NEXT:
      return reducers.fetchPollResultsNext(state, action)
    case constants.CHANGE_POLL_RESULTS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_POLL_RESULTS_FILTERS:
      return reducers.changeFilters(state, action)
    default:
      return state
  }
}
