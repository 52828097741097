import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import { translations } from '../../../../config'
import QuestionRow from './QuestionRow'
import { actions as pollQuestionActions, selectors as pollQuestionSelectors } from '../../../../store/modules/pollQuestions'
import QuestionCommentModal from '../../../../components/QuestionCommentModal'
import modalService from '../../../../services/modalService'

export default compose(
  connect((state, ownProps) => {
    const { question } = ownProps
    return {
      submitting: pollQuestionSelectors.getIsSavingAnswersForQuestion(question.id)(state)
    }
  }),
  withHandlers({
    removeQuestion: ({ dispatch, question }) => () => dispatch(pollQuestionActions.removeQuestion({ questionId: question.id })),
    selectAnswer: ({ dispatch, question }) => ({ answer, single }) => dispatch(pollQuestionActions.selectAnswer({ answer, single, questionId: question.id })),
    saveAnswers: ({ dispatch, question }) => () => dispatch(pollQuestionActions.saveAnswers({ questionId: question.id })),
    openCommentModal: ({ dispatch, question }) => () => {
      modalService.open({
        component: QuestionCommentModal,
        updateComment: ({ comment }) => dispatch(pollQuestionActions.updateComment({ comment, answerId: _.get(question, 'pollAnswers[0].id'), questionId: question.id })),
        title: translations('COMMENT'),
        question: _.get(question, 'pollQuestion.question'),
        comment: _.get(question, 'pollAnswers[0].details.comment'),
        fullScreen: true
      })
    }
  })
)(QuestionRow)
