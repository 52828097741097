import { useSelector } from 'react-redux'
import { getAllStores, getError, getIsLoading, getAllStoresAsOptions } from '../store/modules/stores/selectors'

/**
 * @description returns stores
 * @returns
 */
export const useResults = () => {
  return useSelector(getAllStores)
}

/**
 * @description returns stores error
 * @returns
 */
export const useError = () => {
  return useSelector(getError)
}

/**
 * @description returns if the store is currently loading more reports
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns stores options for dropdown
 * @returns
 */
export const useStoresOptions = () => {
  return useSelector(getAllStoresAsOptions)
}
