import React from 'react'
import cx from 'classnames'
import { getImage } from '../../Images'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'

import style from './style'

const yesSrc = getImage('greenTickIcon')
const noSrc = getImage('redCancelIcon')

const YesNo = (props) => {
  const { onClickYes, onClickNo, value, classes, yesLabel, noLabel } = props

  const YesIcon = (
    <IconButton className={classes.button} onClick={onClickYes}>
      <img
        alt={yesLabel || translations('Yes')}
        src={yesSrc}
        className={cx(classes.icon, {
          [classes.selected]: value === true
        })} />
    </IconButton>
  )

  const NoIcon = (
    <IconButton className={classes.button} onClick={onClickNo}>
      <img
        alt={noLabel || translations('No')}
        src={noSrc}
        className={cx(classes.icon, {
          [classes.selected]: value === false
        })} />
    </IconButton>
  )

  return <div className={classes.container}>
    {YesIcon}
    {NoIcon}
  </div>
}

export default withStyles(style)(YesNo)

