import React from 'react'
import { Modules } from '@redant/retailos-ui'
import {
  retailAnalyticsFunctions
} from '../dependencies'
import './uiConfig'
import { environment, retailAnalyticsDefaultDateRange } from '../../config'

export const RetailAnalytics = ({ match }) => {
  const implementation = Modules.RetailAnalytics.Implementation.provideUseRetailAnalyticsScreen({
    retailAnalyticsFunctions: retailAnalyticsFunctions,
    sisenseConfig: {
      url: environment.SISENSE_URL,
      dashboardId: environment.SISENSE_DASHBOARD_ID
    },
    defaultDateRange: retailAnalyticsDefaultDateRange
  })

  return (
    <Modules.RetailAnalytics.Screens.RetailAnalyticsScreen
      implementation={implementation}
    />
  )
}
