import digitalStoreSdk from '../../../digitalStoreSdk'
import { apiUpdateSource } from '../../../config'
import * as constants from './constants'

class StoreDetailsActions {
  fetchStore = ({ id, ...query }) => ({
    type: constants.FETCH_STORE,
    promise: () => digitalStoreSdk.stores.fetchStore({ id, ...query })
  })
  setView = id => {
    return {
      type: constants.SET_VIEW,
      promise: (
        id === 'store'
        ? () => Promise.resolve({ type: 'store' })
        : () => digitalStoreSdk.users.fetchUserDetails({ id, includeReporting: true, includes: 'role,stores,departments' })
          .then(user => ({
            type: 'user',
            user
          }))
      )
    }
  }
  setTimePeriod = timePeriod => ({
    type: constants.SET_TIME_PERIOD,
    timePeriod
  })
  createStore = (fields) => ({
    type: constants.CREATE_STORE,
    promise: () => digitalStoreSdk.stores
      .createStore({ ...fields, updateSource: apiUpdateSource })
  })
  updateStore = ({ id, fields }) => ({
    type: constants.UPDATE_STORE,
    promise: () => digitalStoreSdk.stores
      .patchStore({ id, fields: { ...fields, updateSource: apiUpdateSource } })
  })
  clear = () => ({
    type: constants.CLEAR
  })
  setStoreCalendars = ({ id, calendarIds }) => ({
    type: constants.CREATE_STORE,
    promise: () => digitalStoreSdk.stores
      .setStoreCalendars({ id, calendarIds })
  })
}

export default new StoreDetailsActions()
