import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'

import H2 from '../../../../../components/H2'
import { Dropdown } from '../../../../../components/Fields'
import { translations, apps } from '../../../../../config'
import style from './style'
import * as validators from '../../../../../validators'

class RoleAndStore extends Component {
  renderStoresDropdown = () => {
    const { classes, loggedInUserStores, selectedRole, isMultiSelector } = this.props
    // role is stored as uppercase in store from the database
    if (selectedRole && selectedRole.isAdmin) {
      return <Grid item xs={12} sm={6}>{translations('All Stores')}</Grid>
    }
    return (
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.field}>
          <Dropdown
            options={loggedInUserStores}
            name={'storeIds'}
            validate={validators.required}
            label={`${translations('Select Store(s)')} *`}
            disabled={!selectedRole}
            multiple={isMultiSelector}
          />
        </FormControl>
      </Grid>
    )
  }
  
  renderDepartmentsDropdown = () => {
    if (!apps.DEPARTMENTS) {
      return null
    }
    const { classes, loggedInUserDepartments, selectedRole } = this.props
    // role is stored as uppercase in store from the database
    if (selectedRole && selectedRole.isAdmin) {
      return <Grid item xs={12} sm={6}>{translations('All Departments')}</Grid>
    }
    return (
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.field}>
          <Dropdown
            label={`${translations('Select Department(s)')} *`}
            name={'departmentIds'}
            options={loggedInUserDepartments}
            validate={validators.departmentRequired}
            multiple
          />
        </FormControl>
      </Grid>
    )
  }

  handleRoleChange = e => {
    const { changeFieldValue, touchField } = this.props
    // Changing from a single to multiple dropdown causes crashes
    // Due to the fact that the value needs to be an array for multiple
    changeFieldValue('storeIds', [])
    changeFieldValue('roleId', e.target.value)

    // touch field so validation errors display immediately
    touchField('storeIds')
    touchField('roleId')
  }

  render = () => {
    const { classes, rolesAvailableToUserOptions } = this.props
    if (!rolesAvailableToUserOptions) {
      return null
    }
    return (
      <ContentBox>
        <ContentBoxHeader>
          <TextContent><H2 className={classes.contentBoxHeader} value={translations('Role and Store(s)')} /></TextContent>
        </ContentBoxHeader>
        <ContentBoxBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.field}>
                <Dropdown
                  options={rolesAvailableToUserOptions}
                  name={'roleId'}
                  validate={validators.required}
                  label={`${translations('Select a Role')} *`}
                  onChange={(e) => this.handleRoleChange(e)}
                />
              </FormControl>
            </Grid>
            {this.renderStoresDropdown()}
            {this.renderDepartmentsDropdown()}
          </Grid>
        </ContentBoxBody>
      </ContentBox>
    )
  }
}

export default withStyles(style)(RoleAndStore)
