const styles = theme => {
  return {
    container: {
      display: 'flex',
      width: '100%'
    },
    containerPadding: {
      padding: 20
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    formWrapperRows: {
      flexDirection: 'column'
    },
    formControl: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      flex: 1,
      margin: 5
    },
    dropdown: {
      flex: 1
    }
  }
}

export default theme => styles(theme)
