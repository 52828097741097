const contentContainerHeight = 253

const style = theme => ({
  buttonContainer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    height: '90px',
    background: theme.palette.lightGrey.main,
    display: 'flex',
    alignItems: 'center'
  },
  contentContainer: {
    height: `calc(100vh - ${contentContainerHeight}px)`,
    overflow: 'auto',
    margin: '20px 7.5%'
  },
  createNoteButton: {
    width: '330px',
    height: '50px',
    fontWeight: 600,
    margin: '0 auto'
  },
  textFormControlContainer: {
    width: '100%'
  }
})

export default theme => style(theme)
