export default theme => ({
  desktopFiltersContainer: {
    marginRight: '7.5%',
    marginLeft: '7.5%'
  },
  buttonContainer: {
    padding: 20,
    marginBottom: 10,
    borderTop: `1px solid ${theme.palette.mediumGrey.main}`,
    background: theme.palette.lightGrey.main
  },
  button: {
    margin: 'auto'
  }
})
