import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'
import Tabs from '../Tabs'

// `tabs` prop:
// array of objects of shape
// {
//   label: <string>,
//   content: <Jsx><Stuff /></Jsx>,
//   value: <string> or <number> or anything
// }

class TabbedContent extends Component {
  renderContent () {
    const { tabs, tabValue, classes } = this.props
    return tabs.map(tab => {
      const currentTab = tab.value === tabValue
      const wrapperClass = classNames({
        [classes.displayNone]: !currentTab,
        [classes.contentContainer]: true
      })
      return (
        <div className={wrapperClass} key={tab.value}>
          {tab.content}
        </div>
      )
    })
  }

  render () {
    const { tabs, classes, containerClass } = this.props
    const containerClasses = classNames(
      classes.container,
      {
        [containerClass]: containerClass
      }
    )

    if (tabs && tabs.length) {
      return (
        <div
          className={containerClasses}
        >
          <Tabs
            {...this.props}
          />
          {this.renderContent()}
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(style)(TabbedContent)
