const styles = theme => ({
  fieldContainer: {
    display: 'flex'
  },
  floatingLabelFocusStyle: {
    color: theme.palette.accent.main
  },
  inputContainer: {
    display: 'flex',
    flex: 2,
    marginRight: 10,
    flexDirection: 'column'
  },
  dropdownContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  errorStyle: { color: theme.palette.error.main },
  container: {
    display: 'flex'
  }
})

export default styles
