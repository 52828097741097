import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import CustomerSnapshotActionCarousel from './CustomerSnapshotActionCarousel'

import { selectors as customerDetailSelectors } from '../../../../../store/modules/customerDetails'
import { actions as appActions, selectors as appSelectors } from '../../../../../store/modules/app'

const mapStateToProps = state => {
  const customerDetails = customerDetailSelectors.getCustomer(state)
  return {
    tabValue: appSelectors.getSnapshotCarouselTab(state),
    wishlist: customerDetailSelectors.getCustomerWishlist(state),
    recommendedProducts: customerDetailSelectors.getCustomerRecommendedProducts(state),
    customerFirstName: _.get(customerDetails, 'firstName')
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    handleTabChange: ({ dispatch }) => (event, value) => {
      // one is a native select so have to use event
      dispatch(appActions.changeSnapshotCarouselTab({ snapshotCarouselTab: value || event.target.value }))
    }
  })
)(CustomerSnapshotActionCarousel)
