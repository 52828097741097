import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { getFullName } from '../../../helpers'

import { EmailIcon, SMSIcon } from './Icons'

const MessagingActivityContainer = Activity => ({ activity, onActivityClick }) => {
  const { fromCustomer, messageType } = activity.meta
  const icon = getIcon(messageType)
  const userFirstName = _.get(activity.user, 'firstName', '')
  const userLastName = _.get(activity.user, 'lastName', '')
  const customer = _.get(activity, 'customer', {})
  const modifiedMessageType = getMessageTypeDisplayName(messageType)
  const text = fromCustomer
    ? `Has sent a new ${modifiedMessageType}`
    : `Has been sent a new ${modifiedMessageType} by ${getFullName(userFirstName, userLastName)}`
  return (
    <Activity
      text={text}
      icon={icon}
      customer={customer}
      date={activity.meta.timestamp}
      onActivityClick={onActivityClick}
      activityType={activity.activityType}
    />
  )
}
function getIcon (messageType) {
  switch (messageType) {
    case 'email':
      return EmailIcon
    case 'sms':
      return SMSIcon
    case 'whatsapp':
      return SMSIcon
    default:
      return undefined
  }
}
function getMessageTypeDisplayName (messageType) {
  switch (messageType) {
    case 'email':
      return _.capitalize(messageType)
    case 'sms':
      return _.upperCase(messageType)
    case 'whatsapp':
      return _.upperCase(messageType)
    default:
      return ''
  }
}

MessagingActivityContainer.propTypes = {
  activity: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
    customer: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({}),
    createdAt: PropTypes.string.isRequired
  }).isRequired,
  onActivityClick: PropTypes.func.isRequired
}

export default MessagingActivityContainer
