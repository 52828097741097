import _ from 'lodash';
export const ignoreNoPurchaseItemsFromData = (allData) => {
  const data = [...allData]
  const reportingKeys = ['lifetime', 'thisMonth', 'thisYear']
  return data.map((item) => {
    reportingKeys.forEach((reportingKey) => {
      const productSpend = _.get(item, `reporting.${reportingKey}.profit.productSpend`)
      if(productSpend && Object.keys(productSpend).length > 0) {
        const newProductSpend = {}
        let totalUnits = 0 
        for (const [key, value] of Object.entries(productSpend)) {
          const { total, units } = value || {}
          const { value: totalValue } = total || {}
          if(totalValue > 0) {
            newProductSpend[key] = value
            totalUnits += isNaN(units) ? 0 : units
          }
        }
        _.set(item, `reporting.${reportingKey}.profit.productSpend`, newProductSpend)
        _.set(item, `reporting.${reportingKey}.profit.totalUnits`, totalUnits) 
      }
    });
    return {
      ...item
    }
  })
}