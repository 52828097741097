import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import List from '../../../components/List'
import ResourceItem from '../../../components/ResourceItem'
import { translations } from '../../../config'

import style from './style'

const ResourcesScreen = ({ resources, viewResource, classes }) => {
  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<H2 value={translations('Resources Header')} />}
      />
      <div className={classes.listContainerTest}>
        {resources && resources.map(resource => <ResourceItem viewResource={() => viewResource({ key: resource.Key })} {...resource} />)}
      </div>
    </div>
  )
}

ResourcesScreen.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({})),
  viewResource: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired
}

export default withStyles(style)(ResourcesScreen)
