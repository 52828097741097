import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { selectors as customerDetailSelectors, actions as customerDetailActions } from '../../../../store/modules/customerDetails'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'

import { compose, withHandlers } from 'recompose'

import Note from './Note'

const mapStateToProps = state => ({
  customer: customerDetailSelectors.getCustomer(state),
  customerNotes: customerDetailSelectors.getCustomerNotes(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    pushToEdit: ({ dispatch, customer, note }) => () => dispatch(push(`/customers/${customer.id}/notes/${note.id}/edit`)),
    openDeleteNoteModal: ({ dispatch, customer, customerNotes, note }) => () => {
      const deleteCustomerNote = params => {
        dispatch(customerDetailActions.deleteCustomerNote(params))
      }
      const deleteNote = id => {
        const notes = customerNotes.filter(note => note.id !== id)
        return deleteCustomerNote({
          id: customer.id,
          notes
        })
      }
      modalService.action({
        title: translations('Delete Note Title'),
        text: translations('Delete Note Text'),
        actions: [
          {
            success: true,
            text: translations('Delete'),
            onClick: () => deleteNote(note.id),
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    }
  })
)(Note)
