import _ from 'lodash'
import { createSelector } from 'reselect'

import { parseLatLng, calculateHaversineDistance } from '../../../helpers'
import { storeLocationsEnabled } from '../../../config'
import { selectors as variantsSelectors } from '../variantsStock'
import { selectors as storesSelectors } from '../stores'
import { selectors as authSelectors } from '../auth'

const getLatLngFromStore = (store) => parseLatLng(_.get(store, 'details.latLng'))

export const matchStockWithStore = createSelector([
  variantsSelectors.getVariantStock,
  storesSelectors.getStoresHashmap,
  authSelectors.getUserSelectedStoreId
], (variantStoreStocks, storesHashmap, currentStoreId) => {
  const currentStore = storesHashmap[currentStoreId]

  const currentStoreLatLng = getLatLngFromStore(currentStore)

  const stockByStore = _.chain(variantStoreStocks)
    .reject(vss => vss.storeId === currentStoreId)
    .map(vss => {
      const { storeId, variantId, stock } = vss

      const store = storesHashmap[storeId]

      const _store = { ...store }
      if (storeLocationsEnabled) {
        const storeLatLng = getLatLngFromStore(store)
        _store.distanceFromCurrentStore = calculateHaversineDistance(currentStoreLatLng, storeLatLng)
      }

      return { ...vss, _store }
    })
    .sort((left, right) => {
      const lName = _.get(left, '_store.name')
      const rName = _.get(right, '_store.name')

      if (storeLocationsEnabled) {
        const lDist = _.get(left, '_store.distanceFromCurrentStore')
        const rDist = _.get(right, '_store.distanceFromCurrentStore')
        const lDistExists = _.isNumber(lDist)
        const rDistExists = _.isNumber(rDist)
  
        // sort stores like this...
        //
        // short distance away
        // medium distance away
        // long distance away
        // unknown distance away
        //
        // (and if they're the same distance, alphabetically based on name)
        if (lDistExists && rDistExists) {
          return lDist - rDist
        } else if (lDistExists) {
          return -1
        } else if (rDistExists) {
          return 1
        } else {
          return lName.localeCompare(rName)
        }
      } else {
        return lName.localeCompare(rName)
      }
    })
    .value()

  return stockByStore
})
