import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { translations } from '../../../../config'

const AddProductButton = ({
  onAddClick,
  fullWidth,
  classes
}) => {
  return (
    <div className={classNames(classes.container, {
      [classes.fullWidth]: fullWidth
    })}>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <button aria-label={translations('Add')} className={classes.button} type='button' onClick={onAddClick} />
        </div>
      </div>
    </div>
  )
}

AddProductButton.propTypes = {
  onAddClick: PropTypes.func.isRequired
}

export default withStyles(style)(AddProductButton)
