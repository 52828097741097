import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import { selectors as followingSelectors, actions as followingActions } from '../../../../store/modules/following'

import CustomerSelect from './CustomerSelect'

const mapStateToProps = state => {
  return {
    following: followingSelectors.getFollowingCustomersWithoutAnonymised(state)
  }
}

export default compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount () {
      this.props.dispatch(followingActions.fetchFollowing())
    }
  })
)(CustomerSelect)
