import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { destroy as destroyForm } from 'redux-form'

import { formNames } from '../../config'
import { getDisplayPriceOfProduct } from '../../store/modules/productDetails/selectors'
import RelatedProductItem from './RelatedProductItem'

export default (compose(
  // for dispatch
  connect(),
  withProps(({ product }) => ({
    name: _.get(product, 'name'),
    image: _.get(product, 'images', [])[0],
    displayPrice: getDisplayPriceOfProduct(product)
  })),
  withHandlers({
    goToProduct: ({ dispatch, product }) => (event) => {
      event.preventDefault()
      if (!product.id) return false
      dispatch(push(`/product/${product.id}`))
      dispatch(destroyForm(formNames.productDetails))
    }
  })
))(RelatedProductItem)
