import * as constants from './constants'
import { constants as messageDetailConstants } from '../messageDetails'
import reducers from './reducers'

export const defaultState = {
  size: 20
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_MESSAGES_FOR_USER_FRESH:
      return reducers.searchMessagesFresh(state, action)
    case constants.SEARCH_MESSAGES_FOR_USER_NEXT:
      return reducers.searchMessagesNext(state, action)
    case messageDetailConstants.FLAG_MESSAGE_INAPPROPRIATE:
      return reducers.addFlagToMessageById(state, action)
    default:
      return state
  }
}
