import React, { Component } from 'react'

import { translations } from '../../../../config';
import SearchBar from '../../../../components/SearchBar';

const OrdersSearchBar = props => {
  const { searchOrders, query, isLoading } = props

  return <SearchBar
    search={searchOrders}
    query={query}
    isLoading={isLoading}
    placeholder={translations('Search Placeholder Orders')}
    searchBarPosition='fixed'
  />
}

export default OrdersSearchBar
