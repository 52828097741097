const style = theme => {
  return {
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    paragraphSpacing: {
      marginBottom: 5
    },
    headerSpacing: {
      marginTop: 10
    },
    textContainer: {
      display: 'flex',
      alignItems: 'baseline'
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    imageSpacing: {
      marginRight: 10
    },
    textSpacing: {
      marginRight: 5
    },
    lastSpacing: {
      marginTop: 20
    },
    channel: {
      background: 'black',
      color: 'white',
      padding: '3px 8px',
      fontSize: 11,
      fontWeight: 'bold',
      borderRadius: 20
    },
    rightContainer: {
      display: 'flex'
    }
  }
}

export default theme => style(theme)
