import { connect } from 'react-redux'
import SideMenu from './SideMenu'
import { actions as appActions } from '../../store/modules/app'

import { selectors as authSelectors } from '../../store/modules/auth'
import { selectors as networkSelectors } from '../../store/modules/network'

const mapStateToProps = state => ({
  user: authSelectors.getCurrentUser(state),
  appsAllowedForRole: authSelectors.getAppsAllowedForRole(state),
  networkConnectivity: networkSelectors.isConnected(state)
})

const mapDispatchToProps = dispatch => ({
  onClose () {
    dispatch(appActions.toggleSideMenu({ isOpen: false }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
