import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import SubHeader from '../../../components/SubHeader'
import AccordionContent from '../../../components/AccordionContent'
import { getImage } from '../../../components/Images'
import H2 from '../../../components/H2'
import { translations, pollReporting } from '../../../config'
import IconButton from '@material-ui/core/IconButton'
import CommentBox from '../QuestionRow/CommentBox'

class ViewCommentsModal extends Component {
  renderAnswerComment = (comment) => {
    const { classes } = this.props
    return (
      <div className={classes.commentDetail}>
        <CommentBox value={comment} />
      </div>
    )
  }

  renderAnswer = ({ value, answer }) => {
    const { classes, result: { pollQuestion } } = this.props
    const commentedAnswers = _.filter(value, (v) => v.details && v.details.comment)
    const reportingConfig = pollReporting[pollQuestion.type]

    return !!commentedAnswers.length && (
      <AccordionContent title={`${reportingConfig.reportLabels[answer]} (${commentedAnswers.length} Comments)`}>
        <div className={classes.accordionContent}>
          {_.map(commentedAnswers, (v) => v.details && this.renderAnswerComment(v.details.comment))}
        </div>
      </AccordionContent>
    )
  }

  renderAnswers = () => {
    const { classes, result } = this.props
    const { pollAnswers } = result
    const mappedAnswers = _.groupBy(pollAnswers, (a) => a.answer)

    return _.map(mappedAnswers, (value, key) => {
      return this.renderAnswer({ answer: key, value })
    })
  }

  render = () => {
    const { classes, result, onCloseClick } = this.props
    const { pollQuestion } = result

    return (
      <div className={classes.container}>
        <SubHeader
          className={classes.header}
          centerContent={
            <H2 value={'Comments'} />
          }
          rightContent={
            <IconButton onClick={onCloseClick}>
              <img src={getImage('closeIconBlack')} />
            </IconButton>
          }
        />
        <div className={classes.questionContainer}>
          {pollQuestion.question}
        </div>
        <div className={classes.commentsContainer}>
          {this.renderAnswers()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ViewCommentsModal)
