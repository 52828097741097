import React, { useEffect } from 'react'
import { Modules } from '@redant/retailos-ui'
import qs from 'qs'

import modalService from '../../../services/modalService'
import { CustomerSearch } from '../../CustomerSearch'
import { ProductRouter } from '../../ProductRouter'
import InspirationsSearchScreen from '../../../containers/Inspirations/InspirationsSearchScreen'
import { composeMessageFunctions, filePickerFunctions } from '../../dependencies'
import { messageContentOptions, getLanguage } from '../../../config'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { useDispatch } from 'react-redux'
export const ComposeMessageScreen = ({ location, match }) => {
 const routeParams = match.params
  const dispatch = useDispatch()
useEffect(() => {
  if(routeParams.id){
    dispatch(customerDetailsActions.fetchFullCustomer(routeParams.id))
      .then(({ customer }) => {
        dispatch(createMessageActions.updateReceipient(customer))
      })
  }
   },[routeParams.id])
  const useFilePicker = Modules.File.Implementation.provideUseFilePicker({
    filePickerFunctions,
    acceptedFileTypes: ['image/*']
  })

  const useAttachContent = Modules.Messaging.Implementation.provideUseAttachContent({
    composeMessageFunctions,
    modalService,
    ProductModalComponent: ProductRouter,
    InspirationModalComponent: InspirationsSearchScreen, // FIXME: temporary, replace with ui library screen eventually
    contentOptions: messageContentOptions,
    hooks: {
      useFilePicker
    }
  })
  const implementation = Modules.Messaging.Implementation.provideUseComposeMessageScreen({
    composeMessageFunctions,
    modalService,
    initialTemplate: location && qs.parse(location.search, { ignoreQueryPrefix: true }).template,
    SearchModalComponent: CustomerSearch,
    getLanguage,
    hooks: {
      useAttachContent
    },
    locationSearch: location.search
  })

  return (
    <Modules.Messaging.Screens.ComposeMessageScreen
      implementation={implementation}
    />
  )
}