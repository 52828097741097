import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { getImage } from '../Images'
import { translations } from '../../config'
import Button from '../Button'

import style from './style'
import imageUploadService from '../../services/imageUploadService/imageUploadService'
import modalService from '../../services/modalService'

class UploadImage extends Component {
  state = { image: this.props.image }
  fileSelectedWeb = (e) => {
    const { onPhotoAdded } = this.props
    const file = e.target.files[0]
    const blobUrl = window.URL.createObjectURL(file)
    this.setState && this.setState({ image: blobUrl })
    onPhotoAdded && onPhotoAdded({ blobUrl, fileName: file.name })
  }
  fileSelectedCordova = ({ file, fileName }) => {
    const { onPhotoAdded } = this.props
    this.setState && this.setState({ image: file })
    onPhotoAdded && onPhotoAdded({ blobUrl: file, fileName })
  }

  openImagePickerWeb = () => {
    const { beforeOpen } = this.props
    const { filepicker } = this.refs
    if (beforeOpen) {
      beforeOpen(() => {
        filepicker.click()
      })
    } else {
      filepicker.click()
    }
  }
  _onDeleteClick = () => {
    const { onPhotoRemoved } = this.props
    const { filepicker } = this.refs
    this.setState && this.setState({ image: '' })
    onPhotoRemoved()
    filepicker.value = null
  }
  _renderDeleteFileButton = () => {
    return (
      <Button
        buttonType='primary'
        onClick={this._onDeleteClick}
      >
        {translations('Delete Screenshot')}
      </Button>
    )
  }
  _renderButtonCordova = () => {
    const { children, disabled, classes } = this.props

    return (
      <Button
        wrapper
        disabled={disabled}
        className={classes.button}
        onClick={this._openImagePicker}
      >
        {children}
      </Button>          
    )

  }

  _openModal = () => {
    const { chooseFromLibrary = true, chooseFromCamera = true } = this.props
    let actions = []

    if (chooseFromCamera) {
      actions.push({
        success: true,
        text: translations('Take Photo'),
        onClick: this._openImagePickerCamera,
        primary: true
      })
    }

    if (chooseFromLibrary) {
      actions.push({
        text: translations('Choose Photo'),
        primary: true,
        onClick: this._openImagePickerLibrary
      })
    }

    if (actions.length > 1) {
      modalService.action({
        buttonDirection: 'column',
        actions
      })
    } else if (chooseFromCamera) {
      this._openImagePickerCamera()
    } else if (chooseFromLibrary) {
      this._openImagePickerLibrary()
    } else {
      console.warn('ImagePickerWrapper has all options disabled. Ensure at least chooseFromCamera or chooseFromLibrary are set to true or removed as a property')
    }
  }

  _openImagePicker = () => {
    const { beforeOpen } = this.props
    if (beforeOpen) {
      beforeOpen(() => {
        this._openModal()
      })
    } else {
      this._openModal()
    }
  }
  _openImagePickerLibrary = () => imageUploadService.openImagePickerLibrary((file) => this.fileSelectedCordova({ file, filename: 'Photo from library' }))
  _openImagePickerCamera = () => imageUploadService.openImagePickerCamera((file) => this.fileSelectedCordova({ file, filename: 'Photo from camera' }))
  _renderButtonWeb = () => {
    const { children, classes, disabled } = this.props
    return (
      <Button
        wrapper
        disabled={disabled}
        className={classes.button}
        onClick={this.openImagePickerWeb}
      >
       {children}
      </Button>
    )
  }
  renderButton = () => {
    if (window.cordova) {
      return this._renderButtonCordova()
    } else {
      return this._renderButtonWeb()
    }
  }
  render () {
    const {
      classes,
      className
    } = this.props
    return (
      <div className={className}>
        {this.renderButton()}
        {!window.cordova
          ? <input onChange={this.fileSelectedWeb} className={classes.imagePicker} ref={'filepicker'} type='file' accept='image/*' />
          : null
        }
      </div>
    )
  }
}

export default withStyles(style)(UploadImage)
