import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { compose } from 'recompose'
import { translations } from '../../../../config'
import { getFullName } from '../../../../helpers'
import Button from '../../../Button'
import H2 from '../../../H2'
import style from './style'

const enhancer = compose(
  withStyles(style)
)

const MessageEditorRecipients = props => {
  const { classes, showSearchRecipientsModal, showBroadcastModal, currentRecipients = [], searchTypeName, searchTypeMessage, isSelectedMe, onSelectMe } = props
  const hasRecipients = !!currentRecipients.length
  const customerName = getFullName(currentRecipients[0])
  const searchMessage = translations(searchTypeMessage) || `Search ${searchTypeName}`
  const selectRecipientText = hasRecipients ? translations('cta-changeRecipient') : searchMessage
  const addColon = hasRecipients ? ':' : ''
  const formatRecipient = isSelectedMe ? translations('label-me') : hasRecipients && typeof (currentRecipients[0]) !== 'string' ? customerName : hasRecipients ? currentRecipients[0] : null
  const fullWidth = useMediaQuery(theme => theme.breakpoints.up('sm'))
  return (
    <div>
      <H2 className={classes.contextBoxTitle} value={
        translations(props.translatedMessage) || `${props.messageName || 'Message'} to${addColon}`
      } />
      {formatRecipient && <div className={classes.recipientTextStyle}>{formatRecipient}</div>}
      <div className={classes.buttonsContainer}>
        {props.showSelfSend && (
          <div className={classes.buttonWrapper}>
            <Button
              color='primary'
              className={classes.selfSend}
              onClick={onSelectMe}
              fullWidth={fullWidth}
            >
              {translations('cta-assignToMe')}
            </Button>
          </div>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            onClick={showSearchRecipientsModal}
            className={classes.searchButtonStyle}
            color='primary'
            fullWidth={fullWidth}
          >
            {translations(selectRecipientText)}
          </Button>
        </div>
        {props.showBroadcast && (
          <div className={classes.buttonWrapper}>
            <Button
              onClick={showBroadcastModal}
              color='primary'
              className={classes.selfSend}
              fullWidth={fullWidth}
            >
              {translations('cta-broadcast')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default enhancer(MessageEditorRecipients)
