import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const ClickWrapper = props => {
  const {
    children,
    classes,
    onClick,
    className
  } = props

  const containerClass = classNames(
    classes.buttonReset,
    className
  )

  return <button onClick={onClick} className={containerClass}>{children}</button>
}

ClickWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default withStyles(style)(ClickWrapper)
