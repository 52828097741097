import sectionStyle from '../../common/sectionStyle'

export default theme => ({
  ...sectionStyle,

  basketList: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    marginBottom: 20
  },

  totalsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    gridGap: '8px',
    maxWidth: 320,
    margin: '0 auto 20px',
    '& >:nth-child(2n+1)': {
      fontWeight: 'bold'
    },
    '& >:nth-last-child(-n+2)': {
      marginTop: 8
    }
  },

  question: {
    fontWeight: 'bold'
  },
  answer: {
    fontWeight: 'normal'
  }
})
