import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import PollsResultsList from './PollsResultsList'
import FilterModal from '../FilterModal'
import { selectors as pollResultsSelectors, actions as pollResultsActions } from '../../../store/modules/pollResults'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  const results = pollResultsSelectors.getGroupedPollResults(state)
  const hasMore = pollResultsSelectors.getHasMore(state)
  const filters = pollResultsSelectors.getFilters(state)
  const filterCount = pollResultsSelectors.getFilterCount(state)
  const totalResults = pollResultsSelectors.getTotal(state)
  const isLoading = pollResultsSelectors.getIsLoading(state)

  return {
    results,
    hasMore,
    filters,
    filterCount,
    totalResults,
    isLoading
  }
}
export default compose(
  connect(mapStateToProps),
  withHandlers({
    onFilterClick: ({ dispatch }) => () => {
      modalService.open({
        component: FilterModal
      })
    },
    loadMore: ({ dispatch, filters }) => () => {
      dispatch(pollResultsActions.fetchPollResultsNext({ filters }))
    }
  })
)(PollsResultsList)
