import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import UsersSearchScreen from './UsersSearchScreen'
import UserRegistrationScreen from './UserRegistrationScreen'
import ViewUserScreen from './ViewUserScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/users' path='/users' exact component={TimedLogoutComponent(UsersSearchScreen)} />,
  <PrivateRoute key='/users/new' path='/users/new' exact component={TimedLogoutComponent(UserRegistrationScreen)} />,
  <PrivateRoute key='/users/:id' path='/users/:id' exact component={TimedLogoutComponent(ViewUserScreen)} />
]
