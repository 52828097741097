import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { httpClientService } from '../dependencies'
import { useDispatch } from 'react-redux'

import { uiConfig } from './uiConfig'

export const DocumentScreen = ({ }) => {
  const dispatch = useDispatch()

  const implementation = Modules.Document.Implementation.provideUseDocumentScreen({
    httpClientService,
  })

  return (
    <Modules.Document.Screens.DocumentListScreen
      implementation={implementation}
      uiConfig={uiConfig}
    />
  )
}
