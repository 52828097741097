import { createSelector } from 'reselect'
import _ from 'lodash'
import { FETCH_MESSAGES_FOR_CUSTOMER_FRESH } from './constants'
import { PENDING } from '../../middleware/redux-promise'

export const getIsInitial = state => !state.customerMessages.status
export const getTotal = state => state.customerMessages.total
export const getPage = state => state.customerMessages.page
export const getStatus = state => state.customerMessages.status
export const getAction = state => state.customerMessages.action
export const getHasMore = state => state.customerMessages.total != null && state.customerMessages.results.length < state.customerMessages.total
export const getResults = state => _.get(state, 'customerMessages.results', [])
export const getSize = state => state.customerMessages.size

export const getIsLoadingInitial = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_MESSAGES_FOR_CUSTOMER_FRESH)
))
