import React, { PureComponent } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import QuestionRow from '../QuestionRow'
import H3 from '../../../components/H3'

class PollsQuestionsSection extends PureComponent {
  renderQuestions = () => {
    const { questions } = this.props
    return _.chain(questions)
      .map(question => {
        return (
          <Grid item xs={12} sm={6} md={6} key={question.id}>
            <QuestionRow question={question} />
          </Grid>
        )
      })
      .value()
  }
  render() {
    const { classes, title, questions } = this.props

    return (
      <div className={classes.container}>
        <div>
          <H3 className={classes.header} value={title} />
        </div>
        <Grid container spacing={2} className={questions.length === 1 ? classes.widgetContainerCentered : classes.widgetContainer}>
          {this.renderQuestions()}
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(PollsQuestionsSection)
