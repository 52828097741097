// Connects a basic field component with redux-forms field
// giving that field all passed props plus onChange, onBlur, value etc...
// http://redux-form.com/6.0.0-alpha.4/docs/faq/CustomComponent.md/

import React from 'react'
import { Field, FormName } from 'redux-form'

const FieldWrapper = WrappedField => props => {
  return <WrappedField {...props} {...props.input} />
}

export default (WrappedField) => {
  const MyField = FieldWrapper(WrappedField)

  return class Container extends React.Component {
    state = {
      hasValue: false
    }

    toggle = (e) => {
      this._toggle && this._toggle(e)
    }

    getFieldRef = (e) => {
      // Check if field was auto-filled
      setTimeout(() => this.toggle(e), 100)
      setTimeout(() => this.toggle(e), 1000)
    }

    componentDidMount() {
      // Toggle label if has value
      this._toggle = (e) => this.setState({ hasValue: !!(e && e.value) })
    }

    componentWillUnmount() {
      this._toggle = undefined
    }

    _generateProps = () => {
      const { getFieldRef, validate, ...passedProps } = this.props
      return {
        ...this.state,
        ...passedProps,
        passedProps,
        validate,
        component: MyField,
        ref: this.getFieldRef,
      }
    }

    render() {
      const props = this._generateProps()
      return (
        <FormName children={({ form }) => {
          return (
            <Field formName={form} {...props} />
          )
        }} />
      )
    }
  }
}
