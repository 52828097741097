import {
  local,
  development,
  qa,
  uat,
  production,
  environments
} from './configProvider'

const environmentConfigs = {
  local,
  development,
  qa,
  uat,
  production
}

// Environment variables starting with REACT_APP_ can be modified
// NODE_ENV is always either development, test or production and cannot
// be modified
window.env = window.env || {}

// a mapping for environment constants
export { environments }

// the current env (a string, eg 'qa' or 'development')
export const env = window.env.REACT_APP_CONFIG_ENV || process.env.REACT_APP_CONFIG_ENV || process.env.NODE_ENV

// the current environment config (an object)
export const environment = environmentConfigs[env]
