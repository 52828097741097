import { compose, withHandlers, withPropsOnChange } from 'recompose'
import moment from 'moment'
import _ from 'lodash'

import { months, formatDate } from '../../../helpers'
import { translations, dateFormat } from '../../../config'
import Birthday from './Birthday'

const monthOptions = months.map(
  ({ name }, i) => ({ label: translations(_.startCase(name)), value: i })
)

const parseMomentFromValue = (value) => {
  let m
  if (value) {
    // if value is in the format of config.dateFormat
    // then parse it using config.dateFormat.
    // if it isn't, just let moment guess.
    // (moment can cope with date ISO strings)
    if (moment.utc(value, dateFormat, true).isValid()) {
      m = moment.utc(value, dateFormat)
    } else {
      m = moment.utc(value)
    }
  } else {
    m = moment.utc('1904-01-01')
  }
  // this is a birthday picker where we don't care about a year,
  // but it's still a date, so we set the year to 1904 every time
  // (it's a leap year)
  m.year('1904')
  return m
}

const makeOnChangeDropdown = (type) => ({ onChange, value }) => (e) => {
  const m = parseMomentFromValue(value)
  // update the birthday value according to the dropdown
  m[type] && m[type](e.target.value)
  onChange(formatDate(m))
}

export default compose(
  withPropsOnChange(
    ['value'],
    ({ value }) => {
      // get values for month and day dropdowns from the
      // overall birthday value (a date string)
      const m = parseMomentFromValue(value)
      const monthValue = m.month()
      const dayValue = m.date()
      return { monthValue, dayValue }
    }
  ),
  withPropsOnChange(
    ['monthValue'],
    ({ monthValue }) => {
      // change the options for the day dropdown depending
      // on what months is selected
      const dayOptions = _
        .range(1, _.get(months, [monthValue, 'days'], 31) + 1)
        .map(i => ({ label: i, value: i }))
      return { monthOptions, dayOptions }
    }
  ),
  withHandlers({
    onChangeMonth: makeOnChangeDropdown('month'),
    onChangeDay: makeOnChangeDropdown('date')
  })
)(Birthday)
