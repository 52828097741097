import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

import FormContentBox from '../../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../../components/Form'
import style from '../../../../../components/Form/style'

import OrderCarousel from '../../../../../components/OrderCarousel'
import { Input, Dropdown } from '../../../../../components/EditableFields'
import Editable from '../../../../../components/Editable'

import { translations, loyaltyTiers } from '../../../../../config'

const LoyaltyForm = FormContentBox('loyalty')

const WrappedOrderListForm = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, classes, lifetimeSpend, loyaltyTier, editable }) => {
  return (
    <LoyaltyForm
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={'Loyalty'}
      onSubmit={onSubmit}
      formId={formId}
      editable={editable}
    >
      <FormError />
      <FormBody>
        <OrderCarousel title={translations('Orders Title')} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.field}>
              <Dropdown
                label={translations('Loyalty Tier')}
                name='loyaltyType'
                editing={editing}
                options={loyaltyTiers}
                displayValue={_.capitalize(loyaltyTier)}

              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label={translations('Lifetime Spend')}
              name='lifetimeSpend'
              editable={false}
              displayValue={lifetimeSpend}

            />
          </Grid>

        </Grid>
      </FormBody>
    </LoyaltyForm>
  )
})

WrappedOrderListForm.propTypes = {
  loyaltyTier: PropTypes.string,
  lifetimeSpend: PropTypes.shape({
    value: PropTypes.string,
    code: PropTypes.string
  })
}

export default withStyles(style)(WrappedOrderListForm)
