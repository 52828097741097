import { LOCATION_CHANGE } from 'connected-react-router'
import { selectors as authSelectors } from '../auth'
import usersActions from './actions'
import * as usersSelectors from './selectors'

import * as userDetailsConstants from '../userDetails/constants'
import * as authConstants from '../auth/constants'

class UsersMiddleware {
  loadInitialUsersMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToUsers = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/users'
    const isRoutingToOrders = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/orders'
    const isRoutingToNotifications = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/notifications'
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isRoutingToUsers && isLoggedIn) {
      dispatch(usersActions.searchUsersFresh({}))
    }

    if (isRoutingToOrders || isRoutingToNotifications) {
      dispatch(usersActions.resetStore())
      dispatch(usersActions.searchUsersFresh({ includeDeactivated: false }))
    }
  }

  updateUserListOnUserProfileUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    if ((action.type === userDetailsConstants.UPDATE_USER && action.status === 'SUCCESS') ||
      (action.type === userDetailsConstants.CHANGE_USER_PASSWORD && action.status === 'SUCCESS') ||
      (action.type === userDetailsConstants.CHANGE_CURRENT_USER_PASSWORD && action.status === 'SUCCESS') ||
      (action.type === userDetailsConstants.USER_ACCOUNT_STATUS_TOGGLE && action.status === 'SUCCESS')) {
      const user = action.result
      dispatch(usersActions.updateUserInList({ user }))
    }
    next(action)
  }

  updateUserListOnUserProfileLoadMiddleware = ({ dispatch, getState }) => next => (action) => {
    if ((action.type === userDetailsConstants.FETCH_USER_DETAILS || action.type === userDetailsConstants.FETCH_CURRENT_USER_DETAILS) && action.status === 'SUCCESS') {
      const user = action.result
      dispatch(usersActions.updateUserInList({ user }))
    }
    next(action)
  }

  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.LOGOUT && action.status === 'SUCCESS') {
      dispatch(usersActions.resetStore())
    }
    next(action)
  }
}

export default new UsersMiddleware()
