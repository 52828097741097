export default theme => ({
  communicationOptionsBodyContainer: {
    padding: 0,
    overflow: 'hidden'
  },
  leftGridItem: {
    [theme.breakpoints.down('xs')]: {
      borderBottom: '0.5px solid #A8A8A8'
    },
    [theme.breakpoints.up('sm')]: {
      borderRight: '0.5px solid #A8A8A8'
    }
  },
  leftContentContainer: {
    padding: 30,
    height: '100%'
  },
  buttonsContainer: {
    flexDirection: 'row !important'
  },
  hiddenContainer: {
    padding: 40,
    lineHeight: '24px'
  }
})
