import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import P from '../../../components/P'
import style from './style'
import SelectStoreForm from '../SelectStoreForm'
import MainLogo from '../../../components/MainLogo'

const SelectStoreScreen = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.innerContainer}>
      <div className={classes.formContainer}>
        <MainLogo />
        <div className={classes.forgotLinkWrapper}>
          <P value={translations('Please select your store')} />
          <SelectStoreForm />
        </div>
      </div>
    </div>
  </div>
)

SelectStoreScreen.propTypes = {

}

export default withStyles(style)(SelectStoreScreen)
