const style = theme => ({
  tabContainer: {
    whiteSpace: 'pre-line',
    '& p': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.5
    }
  },
  tabsContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    },
    marginTop: 40
  }
})

export default style
