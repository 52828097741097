import React, { Fragment } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import { translations, dateFormat } from '../../../../../../config'
import Container from '../../../../../../components/Container'
import P from '../../../../../../components/P'
import H3 from '../../../../../../components/H3'

import style from './style'

const Review = props => {
  const { title, text, url, rating, author, published, classes } = props

  const paragraphs = text ? text.split('\n') : []
  const authorText = <Fragment>
    {`${translations('By')} `}
    <strong>{author || translations('Anonymous')}</strong>
  </Fragment>
  const dateText = moment(published).format(dateFormat)

  return (
    <Container inList>
      <div className={classes.top}>
        {title ? <H3 value={title} className={classes.title} /> : null}
        <P value={dateText} className={classes.date} />
      </div>
      {
        paragraphs.map((paragraph, index) => (
          <P key={index} value={paragraph} />
        ))
      }
      <P value={authorText} className={classes.author} />
    </Container>
  )
}

export default withStyles(style)(Review)
