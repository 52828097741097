export const MAX_LOGIN_ATTEMPTS = 5
export const INVALID_PASSWORD_ERROR = 'INVALID_PASSWORD_ERROR'

export const LOGIN = 'digitialStore/auth/LOGIN'
export const RESET_PASSWORD = 'digitialStore/auth/RESET_PASSWORD'
export const LOGOUT = 'digitialStore/auth/LOGOUT'
export const SELECT_STORE = 'digitialStore/auth/SELECT_STORE'
export const UPDATE_AUTH_USER = 'digitialStore/auth/UPDATE_AUTH_USER'
export const SELECT_DEPARTMENT = 'digitialStore/auth/SELECT_DEPARTMENT'
export const REMOVE_RECENT_USER = 'digitalStore/auth/REMOVE_RECENT_USER'
export const SET_AUTH_CONTEXT = 'digitalStore/auth/SET_AUTH_CONTEXT'
export const GET_SSO_AUTH_CONTEXT = 'digitalStore/auth/GET_SSO_AUTH_CONTEXT'
export const REFRESH_AUTH_CONTEXT = 'digitalStore/auth/REFRESH_AUTH_CONTEXT'
export const SET_IS_USING_RECENT_USER = 'digitalStore/auth/SET_IS_USING_RECENT_USER'
export const GET_SELECTED_STORE_DETAILS = 'digitalStore/auth/GET_SELECTED_STORE_DETAILS'
