import React from 'react'
import FullScreenLoader from './FullScreenLoader'

export { FullScreenLoader }

export default (WrappedComponent) => (props) => {
  return props.isLoading
    ? <FullScreenLoader />
    : <WrappedComponent {...props} />
}
