import _ from 'lodash'
import { promiseReducer } from '../../util'

class RegionsReducers {
  fetchWaitlist = promiseReducer(
    (state, action) => {
      const { results, page, size, productId, storeId, customerId } = action.result
      return {
        ...state,
        results,
        page,
        size,
        productId,
        storeId,
        customerId
      }
    }
  )

  fetchWaitlistNext = promiseReducer(
    (state, action) => {
      const { results, page } = action.result
      return {
        ...state,
        page,
        results
      }
    }
  )

  deleteFromWaitlistLocal = (state, action) => {
    return {
      ...state,
      results: state.results
        ? _.filter(state.results, result => result.id !== action.entryId)
        : []
    }
  }

  clearWaitlistResults = (state) => {
    return {
      ...state,
      results: []
    }
  }
}

export default new RegionsReducers()
