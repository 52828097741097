import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import P from '../P'
import { translations } from '../../config'
import style from './style'

const RequiredTextMessage = ({ classes }) => {
  return <P value={translations('required fields')} className={classes.requiredText} />
}

export default withStyles(style)(RequiredTextMessage)
