const style = theme => ({
  totalHeader: {
    display: 'inline',
    visibility: 'visible'
  },
  totalContent: {
    display: 'inline'
  },
  title: {
    visibility: 'hidden',
    height: 0
  },
  data: {
    margin: 0
  },
  statusBubble: {
    width: '70%',
    padding: '5px 10px',
    background: theme.palette.lightGrey.main,
    borderRadius: '100px',
    textAlign: 'center',
    border: `1px solid ${theme.palette.mediumGrey.main}`,
    marginTop: 15
  },
  bold: {
    fontWeight: 'bold'
  },
  listContainer: {
    width: 150
  }
})

export default style
