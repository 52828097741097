export default (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  button: {
    padding: 8
  },
  icon: {
    height: 25,
    width: 25,
    opacity: 0.3
  },
  selected: {
    opacity: 1
  },
  yesIcon: {
    color: 'red'
  }
})
