import { withHandlers, compose } from 'recompose'

import YesNo from './YesNo'

export default compose(
  withHandlers({
    onClickIcon: ({ beforeChange, onChange, value: previousValue }) => ({event, value}) => {
      const newValue = previousValue === value ? null : value
      if (beforeChange) {
        beforeChange(newValue, (updatedValue) => {
          onChange(updatedValue)
        })
      } else {
        onChange(newValue)
      }
    }
  }),
  withHandlers({
    onClickYes: ({ onClickIcon }) => (event) => onClickIcon({event, value: true}),
    onClickNo: ({ onClickIcon }) => (event) => onClickIcon({event, value: false})
  })
)(YesNo)
