import * as constants from './constants'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_DEPARTMENTS:
      return reducers.fetchDepartments(state, action)
    default:
      return state
  }
}
