import * as constants from './constants'
import * as eventsSelectors from './selectors'

import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class EventsActions {
  clearResults = () => ({
    type: constants.CLEAR_RESULTS
  })
  fetchEventTags = () => {
    return {
      type: constants.FETCH_EVENT_TAGS,
      promise: (dispatch, getState) => {
        const state = getState()
        const regionId = authSelectors.getUserSelectedRegionId(state)
        return digitalStoreSdk.events.fetchAllEventTags({ regionId })
      }
    }
  }
  searchEventsFresh = ({ query, page = 1, size = 5, tagId, type } = {}) => {
    return {
      type: constants.SEARCH_EVENTS_FRESH,
      promise: (dispatch, getState) => {
        const state = getState()
        const searchQuery = query === undefined ? eventsSelectors.getQuery(state) : query
        const regionId = authSelectors.getUserSelectedRegionId(state)
        const searchTagId = tagId === undefined ? eventsSelectors.getTagId(state) : tagId
        const storeId = authSelectors.getUserSelectedStoreId(state)

        return digitalStoreSdk.events.searchEvents({
          // can only search for brand events
          query: searchQuery,
          page,
          size,
          regionId,
          storeId,
          tagId: searchTagId
        })
      },
      payload: {
        type,
        query,
        tagId
      }
    }
  }
  searchEventsNext = () => {
    return {
      type: constants.SEARCH_EVENTS_NEXT,
      promise: (dispatch, getState) => {
        const state = getState()
        const query = eventsSelectors.getQuery(state)
        const page = eventsSelectors.getPage(state) + 1
        const size = 5
        const regionId = authSelectors.getUserSelectedRegionId(state)
        const storeId = authSelectors.getUserSelectedStoreId(state)
        const tagId = eventsSelectors.getTagId(state)
        return digitalStoreSdk.events.searchEvents({
          query,
          page,
          size,
          regionId,
          storeId,
          tagId
        })
      }
    }
  }
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new EventsActions()
