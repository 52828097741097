import { compose, withHandlers, branch, renderNothing } from 'recompose'
import { connect } from 'react-redux'
import LockScreen from './LockScreen'
import { actions as appActions, selectors as appSelectors } from '../../store/modules/app'
import { actions as authActions } from '../../store/modules/auth'
import withHandleLogout from '../../components/withHandleLogout'

const enhancer = compose(
  connect(
    (state) => ({
      isLocked: appSelectors.getIsScreenLocked(state)
    }),
    {
      lockScreen: appActions.lockScreen,
      logout: authActions.logout
    }
  ),
  withHandleLogout(),
  withHandlers({
    logout: ({ logout, lockScreen, handleLogout }) => () => {
      // Login and unlock
      return handleLogout().then(() => (
        lockScreen({ isLocked: false })
      ))
    }
  }),
  branch(
    props => !props.isLocked || ['/login', '/forgot-password', '/select-store'].includes(props.location.pathname),
    renderNothing
  )
)

export default enhancer(LockScreen)
