import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { translations } from '../../../config'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import FullScreenProgress from '../../../components/FullScreenLoader'
import InfiniteScroll from 'react-infinite-scroller'
import ProductCard from '../../../components/ProductCard'
import style from './style'

class WaitlistScreen extends PureComponent {
  renderGridItems () {
    const { classes, waitlist, onClickViewWaitlist } = this.props
    return waitlist.map(result => {
      const { product, customerCount } = result

      return (
        <Grid className={classes.waitlistItem} item xs={12} sm={6} md={4} lg={3} component={'li'}>
          <ProductCard
            product={product}
            buttonString={`${translations('View Waitlist')} (${customerCount})`}
            onButtonClick={() => onClickViewWaitlist(product)}
          />
        </Grid>
      )
    })
  }
  render () {
    const { classes, loadMore, hasMore } = this.props
    return (
      <div className={classes.root}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
          centerContent={(
            <Heading uppercase component={'h1'}>{translations('Products With Waitlists')}</Heading>
          )}
        />
        <InfiniteScroll
          className={classes.productGrid}
          loadMore={loadMore}
          hasMore={hasMore}
          initialLoad={false}
        >
          <Grid className={classes.waitlist} container spacing={0} component={'ul'}>
            {this.renderGridItems()}
          </Grid>
        </InfiniteScroll>
      </div>
    )
  }
}

WaitlistScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  getProductWaitlist: PropTypes.func.isRequired,
  loadMore: PropTypes.bool,
  hasMore: PropTypes.bool
}

export default FullScreenProgress(
  withStyles(style)(WaitlistScreen)
)
