import { lighten } from 'polished'

export default theme => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white'
  },
  cover: {
    backgroundSize: 'cover'
  },
  contain: {
    backgroundSize: 'contain'
  },
  round: {
    borderRadius: '100%'
  },
  caption: {
    fontSize: 12
  },
  container: {
    display: 'inline-block',
    position: 'relative',
    '&:hover $selectInner:not($selectInnerActive)': {
      opacity: 0.7
    }
  },
  bordered: {
    borderWidth: 5,
    borderColor: '#FFFFFF',
    borderStyle: 'solid'
  },
  boxShadow: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)'
  },
  selectIcon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: '100%',
    padding: 4
  },
  selectIconShadow: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)'
  },
  selectBoxBorder: {
    border: `1px dotted ${theme.palette.primary.main}`,
    borderRadius: '100%',
    padding: 3,
    height: '100%',
    width: '100%'
  },
  selectInner: {
    height: '100%',
    width: '100%',
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
    transition: 'opacity .3s'
  },
  selectInnerActive: {
    opacity: 1
  }
})
