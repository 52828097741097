import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import cx from 'classnames'

import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import List from '../../../../components/List'
import SubHeader from '../../../../components/SubHeader'
import H2 from '../../../../components/H2'
import P from '../../../../components/P' 
import BackBar from '../../../../components/BackBar'
import Note from '../Note'

import styles from './style'
import { getCustomerFullName } from '../../../../helpers/getFullName'

class ViewAllNotes extends PureComponent {
  renderNotes = () => {
    const { customer, currentUserId, classes } = this.props
    if (customer.anonymised) {
      return <div className={classes.anonContainer}><P value={translations('Latest Note Anonymised')} type='textButton' className={classes.noNotesText} /></div>
    }
    if (customer.notes && customer.notes.length > 0) {
      return (
        <div className={classes.notesContainer}>
          <List
            items={customer.notes}
            renderItem={note => <Note key={note.id} note={note} currentUser={currentUserId === note.user.id} />}
            noBorder
            noMargin
          />
        </div>
      )
    } else {
      return <div className={classes.noNotesContainer}><P type='textButton' value={translations('No notes')} className={classes.noNotesText} /></div>
    }
  }

  render () {
    const { match, customer, classes } = this.props
    const customerFullName = getCustomerFullName(customer)

    const containerClasses = cx(
      classes.contentContainer,
      {
        [classes.hideOverflow]: customer.anonymised
      }
    )
    return (
      <div>
        <div className={containerClasses}>
          <SubHeader
            leftContent={(
              <BackBar />
            )}
            centerContent={(
              <H2 value={_.toUpper(customerFullName)} />
            )}
          />
          {this.renderNotes()}
        </div>
        {
          !customer.anonymised
          ? (
            <div className={classes.buttonContainer}>
              <Link to={`${match.url}/create`} className={classes.createNoteLink}>
                <Button
                  color='primary'
                  className={classes.createNoteButton}
                >
                  {translations('Create New Note')}
                </Button>
              </Link>
            </div>
          )
          : null
        }
      </div>
    )
  }
}

ViewAllNotes.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  currentUserId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired
}

export default withStyles(styles)(ViewAllNotes)
