import React from 'react'

import CreateNote from '../CreateNote'

import { translations } from '../../../../config'

const EditNote = ({ onSubmit, note }) => (
  <CreateNote
    onSubmit={onSubmit}
    title={translations('Update Note')}
    initialValues={note}
  />
)

export default EditNote
