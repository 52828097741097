import customerFormStyle from './style'
import formStyle from '../../../components/Form/style'
import textContentStyle from '../../../components/Form/TextContent/style'

const styles = theme => ({
  addressContainer: {
    display: 'flex'
  },
  hidden: {
    opacity: 0,
    height: 0,
    overflow: 'hidden'
  }
})

export default theme => ({
  ...customerFormStyle,
  ...formStyle,
  ...styles(theme),
  ...textContentStyle
})
