import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { MessageDetailScreen } from './MessageDetailScreen'

const mapDispatchToProps = dispatch => {
  const goToCustomer = (customerId) => {
    dispatch(push(`/customers/${customerId}`))
  }
  return {
    goToCustomer
  }
}

export default connect(null, mapDispatchToProps)(MessageDetailScreen)