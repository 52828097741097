import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class StoresReducers {
  fetchAllStores = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllStoresPending(state, action)
      case SUCCESS:
        return this.fetchAllStoresSuccess(state, action)
      case FAIL:
        return this.fetchAllStoresFail(state, action)
      default:
        return state
    }
  }

  searchStoresFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchStoresFreshPending(state, action)
      case SUCCESS:
        return this.searchStoresFreshSuccess(state, action)
      case FAIL:
        return this.searchStoresFreshFail(state, action)
      default:
        return state
    }
  }

  fetchAllStoresPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchAllStoresSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllStoresFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  searchStoresFreshPending = (state, action) => {
    const { query,  } = action
    const mergeQuery = query === undefined
      ? {}
      : { query }
      
    return {
      ...state,
      ...mergeQuery,
      status: PENDING,
      action: action.type
    }
  }

  searchStoresFreshSuccess = (state, action) => {
    const { total, items } = action.result
    return {
      ...state,
      total,
      page: 1,
      results: items,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  searchStoresFreshFail = (state, action) => ({
    ...state,
    error: action.error.message,
    status: FAIL,
    action: undefined
  })
}

export default new StoresReducers()
