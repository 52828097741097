import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { change, getFormValues } from 'redux-form'
import _ from 'lodash'

import SendFeedbackForm, { formId } from './SendFeedbackForm'
import imageUploadService from '../../../../services/imageUploadService/imageUploadService'
import { actions as feedbackActions } from '../../../../store/modules/feedback'
import { selectors as authSelectors } from '../../../../store/modules/auth'

const mapStateToProps = state => {
  const feedbackFormValues = getFormValues(formId)(state) || {}
  const fileValue = _.get(feedbackFormValues, 'file.fileName')
  return {
    storeId: authSelectors.getUserSelectedStoreId(state),
    fileValue
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, storeId }) => data => {
      let filePromise
      const file = _.get(data, 'file')
      const text = _.get(data, 'text')
      const feedbackType = _.get(data, 'feedbackType')
      if (file) {
        filePromise = imageUploadService.upload(file.blobUrl)
      } else {
        filePromise = Promise.resolve()
      }
      const sendFeedbackPromise = filePromise.then(remoteFileUrl => {
        return dispatch(feedbackActions.sendFeedback({
          text,
          image: remoteFileUrl,
          storeId,
          type: feedbackType
        }))
      })
      return Promise.all([
        filePromise,
        sendFeedbackPromise
      ])
    },
    onPhotoAdded: ({ dispatch }) => ({ blobUrl, fileName }) => {
      dispatch(change(formId, 'file', { blobUrl, fileName }))
    },
    onPhotoRemoved: ({ dispatch }) => () => {
      dispatch(change(formId, 'file', ''))
    }
  })
)(SendFeedbackForm)
