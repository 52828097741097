import React from 'react'
import { ListItem } from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'

import ButtonLink from '../../../../../components/ButtonLink'

import style from './style'

const SubCategoriesTableRow = ({ name, id, classes, onClick }) => {

  const handleClick = e => {
    e.stopPropagation()
    onClick && onClick()
  }

  return (
    <div key={'listItem'} className={classes.container} onClick={handleClick}>
      <div className={classes.headingContainer}>
        <ButtonLink className={classes.heading}>{name}</ButtonLink>
      </div>
    </div>
  )
}

export default withStyles(style)(SubCategoriesTableRow)
