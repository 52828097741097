import React from 'react'

import ReportFullList from '../../../../Reporting/ReportFullList'

const CustomerReportingFullList = props => {
  const { customers, reportSchema } = props
  return <ReportFullList
    items={customers}
    reportSchema={reportSchema}
    itemProps={{chartCategory: reportSchema.chartCategory}}
  />
}

export default CustomerReportingFullList
