export const FETCH_FULL_CUSTOMER = 'digitalStore/customer/FETCH_FULL_CUSTOMER'
export const CREATE_CUSTOMER = 'digitalStore/customer/CREATE_CUSTOMER'
export const UPDATE_CUSTOMER = 'digitalStore/customer/UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_NOTES = 'digitalStore/customer/UPDATE_CUSTOMER_NOTES'
export const DELETE_CUSTOMER_NOTE = 'digitalStore/customer/DELETE_CUSTOMER_NOTE'
export const EDIT_CUSTOMER_NOTE = 'digitalStore/customer/EDIT_CUSTOMER_NOTE'
export const FOLLOW_CUSTOMER = 'digitalStore/customer/FOLLOW'
export const UNFOLLOW_CUSTOMER = 'digitalStore/customer/UNFOLLOW'
export const UPDATE_CUSTOMER_WISHLIST = 'digitalStore/customer/UPDATE_CUSTOMER_WISHLIST'
export const ANONYMISE_CUSTOMER = 'digitalStore/customer/ANONYMISE_CUSTOMER'
