import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING, FAIL } from '../../middleware/redux-promise'
import { FETCH_EVENT } from './constants'

const emptyArray = []

export const getEventDetails = state => _.get(state, 'eventDetails', {})
export const getResult = state => _.get(state.eventDetails, 'result', {})
export const getIsLoading = state => _.get(state.eventDetails, 'status') === PENDING && _.get(state.eventDetails, 'action') === FETCH_EVENT
export const getEventFailedToLoad = state => _.get(state.eventDetails, 'status') === FAIL

export const getGalleries = createSelector([getResult], event => {
  return _.get(event, 'galleries', emptyArray)
})

export const getHasMultipleGalleries = createSelector([getGalleries], galleries => {
  return galleries.length > 1
})

export const getDefaultGalleryIndex = createSelector([getResult], event => {
  return _.findIndex(event.galleries, gallery => gallery.default === true)
})

export const getMessageContent = createSelector([ getResult, getDefaultGalleryIndex ], (event, defaultIndex) => {
  const defaultGallery = _.get(event, `galleries[${defaultIndex}].media`, emptyArray)
  const defaultGalleryImages = defaultGallery.map(gallery => {
    return gallery.src
  })
  let eventContent = {
    ..._.omit(event, ['galleries']),
    type: 'event',
    images: defaultGalleryImages
  }

  return eventContent
})

export const getSelectedGalleryIndex = createSelector(
  [getEventDetails, getDefaultGalleryIndex],
  (eventDetails, defaultIndex) => {
    return _.get(eventDetails, 'selectedGalleryIndex', defaultIndex)
  }
)
