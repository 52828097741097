import React from 'react'

import PrivateRoute from '../../components/PrivateRoute'
import FeedbackScreen from './FeedbackScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/feedback' path='/feedback' exact component={TimedLogoutComponent(FeedbackScreen)} />
]
