const styles = theme => ({
  productPickerContainer: {
    padding: '30px 20px',
    overflowX: 'auto'
  },
  selectProductHeader: {
    marginBottom: 20,
    marginLeft: 14,
    fontSize: 14
  },
  scroller: {
    display: 'inline-block',
    position: 'relative'
  }
})

export default styles
