import { createSelector } from 'reselect'
import _ from 'lodash'

import { FETCH_FOLLOWING } from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { selectors } from '../customerDetails'

export const getFollowing = createSelector([
  state => state.following.following || []
], following => {
  return following.map(selectors.addMarketingValuesToCustomer)
})

export const getFollowingCustomersWithoutAnonymised = createSelector([
  state => state.following.following || []
], following => {
  return _.chain(following)
    .filter(customer => !customer.anonymised)
    .map(selectors.addMarketingValuesToCustomer)
    .value()
})

export const getFetchFollowingIsLoading = state => state.following.status === PENDING && state.following.action === FETCH_FOLLOWING
