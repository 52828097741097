export default theme => ({
  message: {
    textAlign: 'center',
    marginBottom: 20,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 30
    }
  }
})
