import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

import { selectors as authSelectors, constants as authConstants } from '../auth'
import productsActions from './actions'
import * as productsSelectors from './selectors'
import * as productsConstants from './constants'
import { SUCCESS } from '../../middleware/redux-promise'
import analyticsService from '../../../services/analyticsService'

class ProductsMiddleware {
  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.LOGOUT && action.status === SUCCESS) {
      dispatch(productsActions.resetStore())
    }
    next(action)
  }

  resetFiltersOnCategoryChangeMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === productsConstants.SEARCH_PRODUCTS_FRESH) {
      const currentCategory = productsSelectors.getCategory(getState())
      const newCategory = action.category
      if (newCategory && newCategory.id !== _.get(currentCategory, 'id')) {
        dispatch(productsActions.resetStore())
      }
    }
    next(action)
  }
}

export default new ProductsMiddleware()
