import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { FETCH_DEPARTMENTS_FRESH } from './constants'

export const getStatus = state => _.get(state.departments, 'status')
export const getAction = state => _.get(state.departments, 'action')
export const getResults = state => _.get(state.departments, 'results')

export const getDepartmentsIsLoadingInitial = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_DEPARTMENTS_FRESH)
))

export const getDepartmentsAsOptions = createSelector([
  getResults
], results => {
  return results.map(department => ({ value: department.id, label: department.name }))
})

export const getDepartmentNamesTruncatedDisplay = _.memoize(departmentIds =>
createSelector([
  getResults
], (departments) => {
  if (departments) {
    if (departmentIds.length > 2) {
      return departments.length > 2
        ? `${departments[0].name}, ${departments[1].name} (+${departments.length - 2} more)`
        : 'loading...'
    } else {
      return _.chain(departments)
        .filter(department => departmentIds.includes(department.id))
        .map(department => department.name)
        .join(', ')
        .defaultTo('loading...')
        .value()
    }
  }
})
)
