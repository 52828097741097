const style = theme => ({
  containterTitle: {
    fontSize: 20,
    textTransform: 'uppercase',
    fontWeight: 600,
    textAlign: 'center',
    padding: 20
  },
  textContainer: {
    marginTop: 15
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  }
})

export default style
