import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel'

import Dropdown from '../Dropdown'
import { translations } from '../../../config'
import styles from './style'

const Date = ({
  label,
  value,
  onChange,
  onBlur,
  options,
  passedProps,
  meta: { error, touched } = {},
  validate,
  classes
}) => {
  return ([
    <InputLabel
      // FormControlClasses
      classes={{
        focused: classes.floatingLabelFocusStyle
      }}
      htmlFor={label}
      key='label'
      shrink
    >
      {label}
    </InputLabel>,
    <div key='inputs' className={classes.dateWrapper}>
      <div className={classes.dateFieldWrapper}><Dropdown label={translations('Day')} noFloatingLabel givenClasses={{ overridingRootClasses: classes.dropdownRoot }} value={value.day} onChange={(e) => onChange(e, 'day')} onBlur={(e) => onBlur(e, 'day')} options={[]} /></div>
      <div className={`${classes.dateFieldWrapper} ${classes.marginLeft}`}><Dropdown label={translations('Month')} noFloatingLabel givenClasses={{ overridingRootClasses: classes.dropdownRoot }} value={value.month} onChange={(e) => onChange(e, 'month')} onBlur={(e) => onBlur(e, 'month')} options={[]} /></div>
      <div className={`${classes.dateFieldWrapper} ${classes.marginLeft}`}><Dropdown label={translations('Year')} noFloatingLabel givenClasses={{ overridingRootClasses: classes.dropdownRoot }} value={value.year} onChange={(e) => onChange(e, 'year')} onBlur={(e) => onBlur(e, 'year')} options={[]} /></div>
    </div>,
    <FormHelperText className={classes.errorStyle} key='helper-text'>{error}</FormHelperText>
  ])
}

Date.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  })
}

export default withStyles(styles)(Date)
