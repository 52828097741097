import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import * as validators from '../../../../validators'
import { translations } from '../../../../config'
import { Input } from '../../../Fields'
import style from './style'

const CommunicationMessage = ({
  classes,
  title,
  hasRecipients,
  validate = []
}) => {
  const disabled = !hasRecipients

  return (
    <div className={classes.rightContentContainer}>
      <FormControl
        component='fieldset'
        fullWidth
        className={classes.formControl}
        disabled={disabled}
        required
      >
        <Input
          label={translations('Message label')}
          name='text'
          multiline
          placeholder={translations('Message placeholder')}
          rows={5}
          rowsMax={20}
          fullWidth
          classes={{ root: classes.messageInput }}
          validate={[validators.required, ...validate]}
          forceRerender={disabled}
          shrink
        />
      </FormControl>
    </div>
  )
}

export default withStyles(style)(CommunicationMessage)
