import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 30,
  page: 1,
  query: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_STORES:
      return reducers.fetchAllStores(state, action)
    case constants.SEARCH_STORES_FRESH:
      return reducers.searchStoresFresh(state, action)  
    default:
      return state
  }
}
