import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_MESSAGE_DETAILS:
      return reducers.fetchMessageDetails(state, action)
    case constants.FLAG_MESSAGE_INAPPROPRIATE:
      return reducers.flagMessageInappropriate(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
