import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import EventsSearchScreen from './EventsSearchScreen'
import ViewEventScreen from './ViewEventScreen'
import EventWaitlistScreen from './EventWaitlistScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/events' path='/events' exact component={TimedLogoutComponent(EventsSearchScreen)} />,
  <PrivateRoute key='/events/:id' path='/events/:id' exact component={TimedLogoutComponent(ViewEventScreen)} />,
  <PrivateRoute key='/events/:id/waitlist' path='/events/:id/waitlist' exact component={TimedLogoutComponent(EventWaitlistScreen)} />
]
