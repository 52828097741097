import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import { selectors as eventSelectors, actions as eventsActions } from '../../../../store/modules/events'
import TagPicker from './TagPicker'
import { constants as appConstants, selectors as appSelectors } from '../../../../store/modules/app'

const mapStateToProps = state => ({
  tags: eventSelectors.getTagsAsOptions(state),
  tagId: eventSelectors.getTagId(state),
  total: eventSelectors.getTotal(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchEvents: ({ dispatch, type }) => _.debounce(tagId => {
      dispatch(eventsActions.searchEventsFresh({ tagId, type }))
    }, 300)
  })
)(TagPicker)
