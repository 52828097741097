import _ from 'lodash'

class ToastService {
  constructor () {
    this._deferred = this._deferred.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this._open = this._open.bind(this)
    this._close = this._close.bind(this)
    this._toast = this._deferred()
  }

  connect ({ open, close }) {
    this._toast.resolve({ open, close })
  }

  disconnect () {
    this._toast = this._deferred()
  }

  _close () {
    return this._toast.promise
      .then(({ close }) => {
        close()
      })
  }

  _open ({ props }) {
    return this._toast.promise
      .then(({ open }) => {
        open({ props })
      })
  }

  action ({
    type,
    message,
    verticalPosition,
    horizontalPosition,
    disableClickAway,
    autoHideDuration
  }) {
    return this._open({
      props: {
        type,
        message,
        anchorOrigin: (verticalPosition || horizontalPosition) && {
          vertical: verticalPosition || 'bottom',
          horizontal: horizontalPosition || 'left'
        },
        disableClickAway,
        autoHideDuration
      }
    })
    .then(() => {
      return this._deferred().promise
    })
  }

  _deferred () {
    let pResolve
    let pReject
    const promise = new Promise((resolve, reject) => {
      pResolve = resolve
      pReject = reject
    })
    return {
      resolve: pResolve,
      reject: pReject,
      promise: promise
    }
  }
}

export default new ToastService()
