export function getPaymentCardType(paymentMethod = '') {
  return paymentMethod.split(':')[1]
}

export function getPaymentMethod(paymentMethod = '') {
  return paymentMethod.split(':')[0]
}

export function setPaymentMethodAndCardType(method, card) {
  return `${method}:${card}`
}

