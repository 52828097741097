import React, { PureComponent } from 'react'
import NonDailyQuestionRow from '../NonDailyQuestionRow'

class WeeklyQuestionRow extends PureComponent {
  render () {
    return (
      <NonDailyQuestionRow {...this.props} type={'weekly'} />
    )
  }
}

export default WeeklyQuestionRow
