import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import { translations } from '../../../../config'
import Form, { FormSubmit } from '../../../../components/Form'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import Button from '../../../../components/Button'
import { Input } from '../../../../components/Fields'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import * as validators from '../../../../validators'
import style from './style'

export const formId = 'payment-dummy'

const PaymentForm = Form(formId)

const Payment = props => {
  const { onSubmit, initialValues, formInvalid, classes, skipPayment } = props
  return (
    <div>
      <SubHeader leftContent={<BackBar />} centerContent={<H2 value={translations('PAYMENT')} />} />
      <PaymentForm onSubmit={onSubmit} initialValues={initialValues} >
        <div className={classes.container}>
          <div>
            <FormControl required>
              <Input name='paymentToken' label={translations('Payment Code')} validate={validators.required} />
            </FormControl>
          </div>
          <P value={translations('Please enter the payment code')} />
          <FormSubmit label='pay' big noContainer disabled={formInvalid} />
          <Button big onClick={skipPayment} buttonType='primary'>
            {translations('Skip payment')}
          </Button>
        </div>
      </PaymentForm>
    </div>
  )
}

export default withStyles(style)(Payment)
