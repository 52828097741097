import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class PollQuestionsActions {
  fetchPollQuestions = () => ({
    type: constants.FETCH_POLL_QUESTIONS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.pollQuestions
        .fetchActiveQuestionsForUser()
    }
  })
  saveAnswers = ({ questionId }) => ({
    type: constants.SAVE_ANSWERS,
    questionId,
    promise: (dispatch, getState) => {
      const storeId = authSelectors.getUserSelectedStoreId(getState())
      const answers = selectors.getPollQuestionAnswers(questionId)(getState())
      return digitalStoreSdk.pollQuestions
        .savePollQuestionAnswers({ questionId, storeId, answers })
    }
  })
  removeQuestion = ({ questionId }) => {
    return {
      type: constants.REMOVE_QUESTION,
      questionId
    }
  }
  selectAnswer = ({ answer, single, questionId }) => {
    return {
      type: constants.SELECT_ANSWER,
      answer,
      single,
      questionId
    }
  }
  updateComment = ({ comment, answerId, questionId }) => {
    return {
      type: constants.UPDATE_COMMENT,
      comment,
      answerId,
      questionId
    }
  }
}

export default new PollQuestionsActions()
