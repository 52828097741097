const styles = theme => ({
  countChartContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 15px',
    height: 260,
    justifyContent: 'center',
    position: 'relative'
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    borderRight: '1px solid #BCBCBC',
    padding: '1em 0',
    margin: '2px 0',
    width: '33.3%'
  },
  cellTitle: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.chartLegendsGrey.main,
    textAlign: 'center',
    marginBottom: 5,
    padding: '0 0.5em'
  },
  cellValue: {
    fontSize: '4em',
    color: 'black',
    lineHeight: 1.2,
    fontWeight: 300
  },
  titleOuter: {
    width: '100%'
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    '& :nth-child(3n)': {
      borderRight: 'none'
    },
    '& :last-child': {
      borderRight: 'none'
    }
  }
})

export default styles
