const style = theme => ({
  markdownWrapper: {
    padding: '15px 0'
  },
  modalContainer: {
    position: 'relative'
  },
  buttonContainer: {
    position: 'absolute',
    top: '-20px',
    right: '-20px'
  },
  mainMarkdownContainer: {
    display: 'block',
    margin: '1px 3px 0px 0px',
    '& ul': {
      listStyleType: 'none',
      padding: 0
    },
    '& li': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: '13px',
      marginBottom: '3px',
      '&:before': {
        content: ' "" ',
        display: 'inline-block',
        width: '6px',
        height: '6px',
        marginRight: '11px',
        background: theme.palette.primary.main,
        marginTop: '9px'
      }
    },
    '& p': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.5,
      textOverflow: 'ellipsis'
    },
    '& h1': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: theme.h1.fontSize,
      fontWeight: theme.h1.fontWeight
    },
    '& h2': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: theme.h2.fontSize,
      fontWeight: theme.h2.fontWeight
    },
    '& h3': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: theme.h3.fontSize,
      fontWeight: theme.h3.fontWeight
    }
  },
  hyperlinkContainer: {
    '& a': {
      color: theme.palette.text.main,
      fontFamily: theme.base.fontFamily,
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: 1.5
    }
  }
})

export default style
