import _ from 'lodash'
import actions from './actions'
import * as constants from './constants'
import * as currentOrder from '../currentOrder'
import * as checkoutFlow from '../checkoutFlow'
import * as auth from '../auth'
import { LOCATION_CHANGE } from 'connected-react-router'
import { salesforceOCAPI } from '../../../config'
import { PENDING } from '../../middleware/redux-promise'

class CurrentOrderSalesforceMiddleware {
  salesforceBasketMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)

    if (salesforceOCAPI.basketEnabled) {
      this.createBasketFromCustomerChange({ dispatch, getState })(next)(action)
      this.createBasketOnDeliveryScreen({ dispatch, getState })(next)(action)
      this.updateCustomer({ dispatch, getState })(next)(action)
      this.updateDelivery({ dispatch, getState })(next)(action)
      this.updateProductQuantity({ dispatch, getState })(next)(action)
      this.removeProduct({ dispatch, getState })(next)(action)
      this.addProduct({ dispatch, getState })(next)(action)
      this.fetchPaymentOptions({ dispatch, getState })(next)(action)
      this.fetchDeliveryOptions({ dispatch, getState })(next)(action)
      this.clearOrder({ dispatch, getState })(next)(action)
    }
  }

  _createBasket = ({ customer, products, storeId, userId, dispatch, state }) => {
    const basketStatus = _.get(state, 'currentOrderSalesforce.status')

    if (basketStatus !== PENDING) {
      const basket = {
        customerNumber: _.get(customer, 'details.customer_no', _.get(customer, 'customer_no')),
        externalCustomerId: _.get(customer, 'externalCustomerId'),
        billingAddress: {
          // TODO: Make address required on register customer so we have a valid billing address
          title: _.get(customer, 'title', 'unknown'),
          firstName: _.get(customer, 'firstName', 'unknown'),
          lastName: _.get(customer, 'lastName', 'unknown'),
          address1: _.get(customer, 'address1', 'unknown'),
          address2: _.get(customer, 'address2', null),
          city: _.get(customer, 'city', 'unknown'),
          postalCode: _.get(customer, 'postCode', 'unknown'),
        },
        items: _.map(products, product => ({
          externalProductId: product.externalProductId,
          quantity: 1
        })),
        externalStoreId: storeId,
        externalUserId: userId
      }

      dispatch(actions.createCustomerBasket(basket))
    }
  }

  createBasketFromCustomerChange = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.UPDATE_ORDER) {
      const state = getState()
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const newCustomerNumber = _.get(action, 'customer.details.customer_no', null)

      const shouldCreateBasket = !basketId || newCustomerNumber
      if (shouldCreateBasket) {
        const products = currentOrder.selectors.getCurrentOrderProducts(state)
        const storeId = auth.selectors.getUserSelectedExternalStoreId(state)
        const userId = auth.selectors.getActiveUserExternalId(state)
        const customer = _.get(action, 'customer', null)

        this._createBasket({ customer, products, storeId, userId, dispatch, state })
      }
    }
  }

  createBasketOnDeliveryScreen = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const actionPath = _.get(action, 'payload.location.pathname')
      const navigatingToDeliveryScreen = actionPath.includes('delivery')

      if (navigatingToDeliveryScreen) {
        const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)

        if (!basketId) {
          const order = _.get(state, 'currentOrder', {})

          const products = currentOrder.selectors.getCurrentOrderProducts(state)
          const storeId = auth.selectors.getUserSelectedExternalStoreId(state)
          const userId = auth.selectors.getActiveUserExternalId(state)
          const customer = _.get(order, 'customer', null)

          this._createBasket({ customer, products, storeId, userId, dispatch, state })
        }
      }
    }
  }

  updateCustomer = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.UPDATE_ORDER) {
      const state = getState()
      const basketCustomerNumber = _.get(state, 'currentOrderSalesforce.basket.customerNumber', null)
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const newCustomerNumber = _.get(action, 'customer.details.customer_no', null)

      let basket = null

      const customerUpdate = newCustomerNumber && basketCustomerNumber !== newCustomerNumber
      if (customerUpdate) {
        basket = {
          ...basket,
          customerNumber: _.get(action, 'customer.details.customer_no'),
          externalCustomerId: _.get(action, 'customer.externalCustomerId')
        }
      }

      if (basketId && basket) {
        dispatch(actions.updateCustomerBasket(basketId, basket))
      }
    }
  }

  updateDelivery = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.UPDATE_ORDER) {
      const state = getState()
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const shipmentId = _.get(state, 'currentOrderSalesforce.basket.shipments.0.id', undefined)
      const deliveryAddress = _.get(action, 'deliveryAddress', null)
      const deliveryDetails = _.get(action, 'deliveryDetails', null)
      const shippingMethodId = _.get(action, 'deliveryOption.id', null)

      let basket = null

      const deliveryUpdate = deliveryAddress && deliveryDetails
      if (deliveryUpdate) {
        basket = {
          ...basket,
          shipments: [{
            id: shipmentId,
            shippingMethodId: shippingMethodId,
            address: {
              title: deliveryDetails.title,
              firstName: deliveryDetails.firstName,
              lastName: deliveryDetails.lastName,
              address1: deliveryAddress.address1,
              address2: deliveryAddress.address2 || null,
              city: deliveryAddress.city,
              postalCode: deliveryAddress.postCode,
            }
          }]
        }
      }

      if (basketId && basket) {
        dispatch(actions.updateCustomerBasket(basketId, basket))
      }
    }
  }

  updateProductQuantity = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.CHANGE_PRODUCT_QUANTITY_IN_ORDER) {
      const state = getState()
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const currentItems = _.get(state, 'currentOrderSalesforce.basket.items', [])

      const externalProductId = _.get(action, 'product.externalProductId')

      const currentItem = _.chain(currentItems)
        .find(item => item.externalProductId === externalProductId)
        .value()

      if (!currentItem.quantity) {
        return console.warn(`No product found for id `, externalProductId)
      }

      if (currentItem.quantity !== action.quantity) {
        const otherItems = _.filter(currentItems, item => item.externalProductId !== externalProductId)
        const updatedItem = {
          ...currentItem,
          quantity: action.quantity
        }

        const basket = {
          items: [...otherItems, updatedItem]
        }

        if (basketId) {
          dispatch(actions.updateCustomerBasket(basketId, basket))
        }
      }

    }
  }

  removeProduct = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.REMOVE_PRODUCT_FROM_ORDER) {
      const state = getState()
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const currentItems = _.get(state, 'currentOrderSalesforce.basket.items', [])

      const externalProductId = _.get(action, 'product.externalProductId')

      const currentItem = _.chain(currentItems)
        .find(item => item.externalProductId === externalProductId)
        .value()

      if (!currentItem) {
        return console.warn(`No product found for id `, externalProductId)
      }

      const otherItems = _.filter(currentItems, item => item.externalProductId !== externalProductId)

      const basket = {
        items: [...otherItems]
      }

      if (basketId) {
        dispatch(actions.updateCustomerBasket(basketId, basket))
      }
    }
  }

  addProduct = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.ADD_PRODUCT_TO_ORDER) {
      const state = getState()
      const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)
      const currentItems = _.get(state, 'currentOrderSalesforce.basket.items', [])

      const externalProductId = _.get(action, 'product.externalProductId')

      const currentItem = _.chain(currentItems)
        .find(item => item.externalProductId === externalProductId)
        .value()

      const otherItems = _.filter(currentItems, item => item.externalProductId !== externalProductId)

      const basket = {
        items: [...otherItems, { externalProductId, quantity: action.quantity }]
      }

      if (basketId) {
        dispatch(actions.updateCustomerBasket(basketId, basket))
      }
    }
  }

  fetchPaymentOptions = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const isPaymentScreen = path.includes('payment')

      if (isPaymentScreen) {
        const state = getState()
        const basketId = _.get(state, 'currentOrderSalesforce.basket.basketId', null)

        if (basketId) {
          dispatch(actions.fetchBasketPaymentOptions(basketId))
        }
      }
    }
  }

  fetchDeliveryOptions = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
      const currentState = getState()
      const actionPath = _.get(action, 'payload.location.pathname')
      const navigatingToDeliveryScreen = actionPath.includes('delivery')
      if (navigatingToDeliveryScreen) {
        const basketId = _.get(currentState, 'currentOrderSalesforce.basket.basketId', null)

        if (basketId) {
          dispatch(checkoutFlow.actions.fetchDeliveryOptions(basketId))
        }
      }
    } else if (action.type === constants.CREATE_CUSTOMER_BASKET || action.type === constants.UPDATE_CUSTOMER_BASKET) {
      const currentState = getState()
      const currentPath = _.get(currentState, 'router.location.pathname')
      const isDeliveryScreen = currentPath.includes('delivery')
      if (isDeliveryScreen) {
        const basketId = _.get(currentState, 'currentOrderSalesforce.basket.basketId', null)

        if (basketId) {
          dispatch(checkoutFlow.actions.fetchDeliveryOptions(basketId))
        }
      }
    }
  }

  clearOrder = ({ dispatch, getState }) => next => action => {
    if (action.type === currentOrder.constants.CLEAR_ORDER) {
      dispatch(actions.clearBasket())
    }
  }

}

export default new CurrentOrderSalesforceMiddleware()
