import NoteActivityContainer from './NoteActivityContainer'
import ProductCarouselActivityContainer from './ProductCarouselActivityContainer'
import PurchaseActivityContainer from './PurchaseActivityContainer'
import MessagingActivityContainer from './MessagingActivityContainer'

const typeMap = {
  note: NoteActivityContainer,
  product: ProductCarouselActivityContainer,
  inspiration: ProductCarouselActivityContainer,
  photo: ProductCarouselActivityContainer,
  image: ProductCarouselActivityContainer,
  purchase: PurchaseActivityContainer,
  messaging: MessagingActivityContainer,
  event: ProductCarouselActivityContainer
}

export default (type) => {
  return typeMap[type]
}
