const styles = {
  requiredFieldsText: {
    margin: '25px 0'
  },
  contentContainer: {
    padding: '0 7.5%'
  },
  customerModeButtonContainer: {
    margin: 0
  }
}

export default theme => ({
  ...styles
})
