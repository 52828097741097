export default {
  container: {
    
  },
  listContainer: {
    padding: 20,
    maxWidth: 900,
    margin: 'auto'
  },
  moreLoaderWrapper: {
    margin: 30,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  noNotificationsContainer: {
    textAlign: 'center'
  }
}
