import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ResourcesReducers {
  fetchResources = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchResourcesPending(state, action)
      case SUCCESS:
        return this.fetchResourcesSuccess(state, action)
      case FAIL:
        return this.fetchResourcesFail(state, action)
      default:
        return state
    }
  }
  fetchResourcesPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchResourcesSuccess = (state, action) => {
    return {
      ...state,
      list: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchResourcesFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
  fetchResource = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchResourcePending(state, action)
      case SUCCESS:
        return this.fetchResourceSuccess(state, action)
      case FAIL:
        return this.fetchResourceFail(state, action)
      default:
        return state
    }
  }
  fetchResourcePending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchResourceSuccess = (state, action) => {
    return {
      ...state,
      link: action.result.link,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchResourceFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
}

export default new ResourcesReducers()
