const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  individualContainer: {
    paddingTop: 20
  },
  containerClass: {
    width: '100%'
  }
})

export default style
