import { connect } from 'react-redux'

import { selectors as inspirationsSelectors, actions as inspirationsActions } from '../../../../store/modules/inspirations'
import InspirationResults from './InspirationResults'

const mapStateToProps = state => ({
  inspirations: inspirationsSelectors.getResults(state),
  hasMore: inspirationsSelectors.getHasMore(state),
  hasSearched: inspirationsSelectors.getHasSearched(state)
})

const mapDispatchToProps = dispatch => {
  return {
    loadMore: () => dispatch(inspirationsActions.searchInspirationsNext())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspirationResults)
