export default theme => ({
  author: {
    marginTop: 12
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  title: {
    flex: 1,
    lineHeight: '24px'
  },
  date: {
    flexShrink: 0,
    marginLeft: 'auto',
    paddingLeft: 30,
    lineHeight: '24px'
  }
})
