import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import SelectDepartmentForm from './SelectDepartmentForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'

const mapStateToProps = state => ({
  departments: authSelectors.getUserDepartmentsOptions(state),
  departmentId: authSelectors.getUserSelectedDepartmentId(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch }) => ({ departmentId }) => {
      return dispatch(authActions.selectDepartment({ departmentId }))
    }
  })
)(SelectDepartmentForm)
