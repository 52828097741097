import _ from 'lodash'

export const parseLatLng = _.flow(
  // first fn turns an object, array, or string into
  // an object shaped like { lat, lng }
  (latLng) => {
    if (latLng) {
      if (typeof latLng === 'object') {
        if (_.isArray(latLng) && latLng.length === 2) {
          const [lat, lng] = latLng
          return { lat, lng }
        } else if (_.has(latLng, 'lat') && _.has(latLng, 'lng')) {
          return _.pick(latLng, ['lat', 'lng'])
        } else {
          return null
        }
      } else if (typeof latLng === 'string') {
        const [lat, lng] = _.trim(latLng).split(',')
        return { lat, lng }
      } else {
        return null
      }
    } else {
      return null
    }
  },
  // second fn takes the output from the first fn
  // and parses numbers from the lat and lng,
  // returning either a { lat, lng } object with number values,
  // or returns null if either value couldnt be converted to a number
  (latLngObj) => {
    if (!latLngObj) return null
    const latNum = Number(latLngObj.lat)
    const lngNum = Number(latLngObj.lng)
    if (_.isNaN(latNum) || _.isNaN(lngNum)) return null
    return { lat: latNum, lng: lngNum }
  }
)

// convert degrees to radians
export const getRadians = (degrees) => {
  return (degrees * Math.PI) / 180
}

// calculate distance in metres between two latLng coords
// https://www.movable-type.co.uk/scripts/latlong.html
export const calculateHaversineDistance = (latLng1, latLng2) => {
  const parsedLatLng1 = parseLatLng(latLng1)
  const parsedLatLng2 = parseLatLng(latLng2)
  if (!parsedLatLng1 || !parsedLatLng2) return null

  const { lat: lat1, lng: lng1 } = parsedLatLng1
  const { lat: lat2, lng: lng2 } = parsedLatLng2

  const R = 6371e3 // metres
  const φ1 = getRadians(lat1)
  const φ2 = getRadians(lat2)
  const Δφ = getRadians(lat2 - lat1)
  const Δλ = getRadians(lng2 - lng1)

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c

  return d
}
