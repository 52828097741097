import React, { Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import ListSubheader from '@material-ui/core/ListSubheader'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import style from './style'
import Option from '../Option'
import VirtualizedMenuList from '../VirtualizedMenuList'
import { paperHeight } from '../Paper'
import Label from '../Label'
import ErrorText from '../ErrorText'

const getOptions = (options, getIsSelected) => _.map(
  options,
  option => <Option key={option.value} dropdownProps={{ getIsSelected }} {...option} />
)

const getPlainOptions = (options, getIsSelected) => {
  return _
    .chain(options)
    .map((optionsGroup) => {
      return [
        optionsGroup.map((option) => {
          if (option.dropdownLabel) {
            return null
          }
          return <Option key={option.value} dropdownProps={{ getIsSelected }} {...option} />
        })
      ]
    })
    .flatten()
    .value()
}

const getGroupedOptionsWithDivider = (options, getIsSelected) => {
  return _
    .chain(options)
    .map((optionsGroup, i, optionsGroups) => {
      return [
        optionsGroup.map((option, j) => {
          if (option.dropdownLabel) {
            return null
          }
          return <Option key={option.value} dropdownProps={{ getIsSelected }} {...option} />
        }),
        optionsGroups.length > i + 1
          ? <Option
            divider
            key={`divider-${i}`}
          />
          : null
      ]
    })
    .flatten()
    .value()
}

const getGroupedOptions = (groupedOptions, getIsSelected, classes) => {
  const items = []
  _
    .chain(groupedOptions)
    .map((options, groupLabel) => {
      items.push(<ListSubheader classes={{ root: classNames(classes.groupName) }}>{groupLabel}</ListSubheader>)
      items.push(getOptions(options, getIsSelected))
      return items
    })
    .flatten()
    .value()

  return items
}

export const SelectDropdown = (props) => {
  const {
    name,
    value,
    label,
    options,
    passedProps,
    classes,
    givenClasses = {},
    renderLabel,
    className,
    wrappedOnChange,
    listWidth,
    noErrorTextLabel,
    noFloatingLabel,
    meta,
    shrink,
    disabled,
    getIsSelected
  } = props

  const { error, touched } = meta || {}

  const selectClass = classNames(className, givenClasses.field)
  const useVirtualized = _.flatten(options).length > 100

  const PaperProps = {}
  PaperProps.style = { maxHeight: paperHeight }
  if (useVirtualized) {
    PaperProps.minWidth = listWidth
  }

  const MenuListProps = {}
  if (useVirtualized) {
    MenuListProps.component = VirtualizedMenuList
    MenuListProps.dropdownValue = value
  }

  const MenuProps = { PaperProps, MenuListProps }

  const SelectInput = <Input
    id={name}
    classes={{
      root: classNames(classes.selectInput, givenClasses.overridingRootClasses),
      underline: classes.selectInputUnderline
    }}
  />

  const isGroupedOptionsList = _.isObject(options) && !_.isArray(options)
  const isOptionsWithDeviderList = _.isArray(options[1])
  const isOptionsPlainOptionsList = options.length > 0

  return <Fragment>
    <Label
      label={translations(label)}
      noFloatingLabel={noFloatingLabel}
      shrink={shrink || (value && value.length)}
    />
    <Select
      key='input-select'
      MenuProps={MenuProps}
      className={selectClass}
      displayEmpty
      error={touched && error}
      input={SelectInput}
      onChange={wrappedOnChange}
      renderValue={renderLabel}
      value={value || ''}
      disabled={disabled}
      // this should be _.pick instead but idk all the values we need
      {..._.omit(passedProps, ['options', 'givenClasses', 'noFloatingLabel'])}
    >
      {isOptionsPlainOptionsList && getPlainOptions(options, getIsSelected)}
      {isOptionsWithDeviderList && getGroupedOptionsWithDivider(options, getIsSelected)}
      {isGroupedOptionsList && getGroupedOptions(options, getIsSelected, classes)}
    </Select>
    <ErrorText
      meta={meta}
      noErrorTextLabel={noErrorTextLabel}
    />
  </Fragment>
}

SelectDropdown.propTypes = {
  /** Array of arrays (each top level array renders as a group of options, visually seperated by a line) */
  options: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )).isRequired,
  label: PropTypes.string,
  /** Spread as props to [MUI select](https://material-ui.com/api/select/)  */
  passedProps: PropTypes.object,
  wrappedOnChange: PropTypes.func,
  /** Hides the label */
  noErrorTextLabel: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
}

export default withStyles(style)(SelectDropdown)
