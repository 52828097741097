const styles = theme => ({
  container: {
    position: 'absolute',
    backgroundColor: theme.palette.floatingRoundLabelBackground.main,
    borderRadius: '50%',
    width: '66px',
    height: '66px',
    margin: '1rem'
  },
  text: {
    color: theme.palette.whiteText.main,
    margin: 0,
    padding: '0 5px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    lineHeight: 1.25,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'default'
  },
  topLeft: {
    top: 0,
    left: 0
  },
  topRight: {
    top: 0,
    right: 0
  },
  bottomLeft: {
    bottom: 0,
    left: 0
  },
  bottomRight: {
    bottom: 0,
    right: 0
  }
})

export default styles
