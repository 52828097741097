const truncate = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}

export default theme => ({
  messageCell: {
    textAlign: 'right'
  },
  truncate,
  tableRow: {
    alignItems: 'center'
  },
  mobileContainer: {
    fontSize: '13px',
    padding: '14px 0'
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  mobileCell: {
    ...truncate,
    padding: '2px 7px',
    flex: 1,
    width: '50%'
  }
})
