const styles = {
  seeAllButton: {
    marginBottom: -15
  },
  seeAllText: {
    margin: 0,
    '&:after': {
      content: '" "',
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: '5px solid black',
      display: 'inline-block',
      marginLeft: '0.5em'
    }
  },
  leaderBoardTile: {
    width: '100%',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    margin: '0 6px'
  },
  smallItalic: {
    paddingTop: '5px',
    fontStyle: 'italic',
    color: '#686868',
    fontSize: 10
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px'
  },
  leaderBoardListContainer: {
    margin: '0 6px',
    width: '100%'
  },
  chartWrapper: {
    justifyContent: 'space-between',
    marginTop: 8
  },
  chartWrapperWithSeeAll: {
    justifyContent: 'space-between',
    marginTop: 8
  }
}

export default styles
