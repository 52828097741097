import theme from '../../config/theme'

const styles = {
  h2: {
    ...theme.h2,
    color: theme.palette.text.main
  }
}

export default theme => styles
