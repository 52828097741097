import React from 'react'
import { useSelector } from 'react-redux'
import { Modules, useTranslation } from '@redant/retailos-ui'
import { 
  httpClientService, 
  checkoutFunctions, 
  useConsultationSharedState, 
  useConsultationLocalState,
  useResourceDetails, 
  virtualConsultationCheckoutFunctions,
  inStoreConsultationCheckoutFunctions,
} from '../dependencies'
import { environment, addressLookupCountries, countryOptionsForDropdowns, omitStoreSaleDeliveryDetails } from '../../config'
import modalService from '../../services/modalService'
import './uiConfig'
import './schemaConfig'
import { getUiConfig } from '../uiConfigStore'
import { getSchemaConfig } from '../schemaConfigStore'
import {
  actions as currentOrderActions, 
  selectors as currentOrderSelectors
} from '../../store/modules/currentOrder'
import { actions as customerDetailsActions } from '../../store/modules/customerDetails'
import { CustomerSearch } from '../CustomerSearch'

import {
  getSelectedRegionLocationLookupCode,
  getSelectedRegionStripeKey
} from '../../store/modules/combinedSelectors/regionsCombinedSelectors' 

export const VirtualConsultationCheckout = ({ basket, onBack, appointmentId, initialState }) => {

  const stripePublicApiKey = useSelector(getSelectedRegionStripeKey)
  const regionLookupCode = useSelector(getSelectedRegionLocationLookupCode)

  virtualConsultationCheckoutFunctions.useCheckoutScreenMount()

  const useAddressLookup = Modules.Checkout.Implementation.provideUseAddressLookup({
    httpClientService,
    pcaKey: environment.PCA_KEY,
    locationLookupCode: regionLookupCode || (addressLookupCountries && addressLookupCountries.join(',')),
  })

  const useAddressDetailsForm = Modules.Checkout.Implementation.provideUseAddressDetailsForm({
    addressFieldsConfig: {},
    countryOptions: countryOptionsForDropdowns.flat(),
    hooks: {
      useAddressLookup
    }
  })

  const useCheckoutSync = Modules.VirtualConsultation.Implementation.provideUseCheckoutSync({
    httpClientService,
    appointmentId,
    modalService,
    hooks: {
      useSharedState: useConsultationSharedState
    }
  })

  const implementation = Modules.Checkout.Implementation.provideUseRootScreen({
    checkoutFunctions: virtualConsultationCheckoutFunctions,
    basket,
    initialState,
    paymentPublicApiKey: stripePublicApiKey,
    modalService,
    hooks: {
      useAddressDetailsForm,
      useStateSync: useCheckoutSync
    },
    omitStoreSaleDeliveryDetails: omitStoreSaleDeliveryDetails
  })

  return (
    <Modules.Checkout.Screens.RootScreen
      implementation={implementation}
      onBack={onBack}
      uiConfig={{
        ...getUiConfig('Modules.Checkout.Screens.RootScreen'),
        ...getUiConfig('Modules.VirtualConsultation.Screens.Checkout')
      }}
    />
  )
}

export const InStoreConsultationCheckout = ({ basket, onBack, appointmentId, initialState }) => {
  const paymentPublicApiKey = useSelector(getSelectedRegionStripeKey)
  inStoreConsultationCheckoutFunctions.useCheckoutScreenMount()

  const useAddressLookup = Modules.Checkout.Implementation.provideUseAddressLookup({
    httpClientService,
    pcaKey: environment.PCA_KEY,
    locationLookupCode: addressLookupCountries && addressLookupCountries.join(',')
  })

  const useAddressDetailsForm = Modules.Checkout.Implementation.provideUseAddressDetailsForm({
    addressFieldsConfig: getSchemaConfig('Checkout').addressFormSchema,
    countryOptions: countryOptionsForDropdowns.flat(),
    hooks: {
      useAddressLookup
    }
  })

  const useCheckoutSync = Modules.VirtualConsultation.Implementation.provideUseCheckoutSync({
    httpClientService,
    appointmentId,
    modalService,
    hooks: {
      useSharedState: useConsultationLocalState
    }
  })

  const implementation = Modules.Checkout.Implementation.provideUseRootScreen({
    checkoutFunctions: inStoreConsultationCheckoutFunctions,
    basket,
    initialState,
    paymentPublicApiKey: paymentPublicApiKey,
    modalService,
    hooks: {
      useAddressDetailsForm,
      useStateSync: useCheckoutSync
    },
    omitStoreSaleDeliveryDetails: omitStoreSaleDeliveryDetails
  })

  return (
    <Modules.Checkout.Screens.RootScreen
      implementation={implementation}
      onBack={onBack}
      uiConfig={{
        ...getUiConfig('Modules.Checkout.Screens.RootScreen'),
        ...getUiConfig('Modules.InStoreConsultation.Screens.Checkout')
      }}
    />
  )
}

export const BasketCheckout = ({ basket, onBack, initialState }) => {

  const paymentPublicApiKey = useSelector(getSelectedRegionStripeKey)
  checkoutFunctions.useCheckoutScreenMount()

  const { t } = useTranslation('Checkout')

  const useAddressLookup = Modules.Checkout.Implementation.provideUseAddressLookup({
    httpClientService,
    pcaKey: environment.PCA_KEY,
    locationLookupCode: addressLookupCountries && addressLookupCountries.join(',')
  })

  const useAddressDetailsForm = Modules.Checkout.Implementation.provideUseAddressDetailsForm({
    customerDetailsActions,
    currentOrderSelectors,
    addressFieldsConfig: getSchemaConfig('Checkout').addressFormSchema,
    countryOptions: countryOptionsForDropdowns.flat(),
    hooks: {
      useAddressLookup
    }
  })

  const { checkoutSuccessRedirectUrl = '/orders/${orderNumber}' } = getUiConfig('Modules.Checkout.Screens.RootScreen')

  const useBasketCheckout = Modules.Checkout.Implementation.provideUseBasketCheckout({
    currentOrderActions,
    currentOrderSelectors,
    CustomerSearch,
    modalService,
    checkoutType: 'basketCheckout',
    basketCheckoutImplConfig: {
      checkoutSuccessRedirectUrl,
      stripeTapToPayEnabled: true
    },
  })

  const reviewFormSchema = getSchemaConfig('Checkout').checkoutReviewFormSchema
  const implementation = Modules.Checkout.Implementation.provideUseRootScreen({
    checkoutFunctions,
    basket,
    initialState,
    paymentPublicApiKey: paymentPublicApiKey,
    modalService,
    hooks: {
      useAddressDetailsForm,
      useBasketCheckout,
      useResourceDetails
    },
    reviewScreenCustomSchema: reviewFormSchema ? reviewFormSchema(t) : undefined,
    omitStoreSaleDeliveryDetails: omitStoreSaleDeliveryDetails
  })

  return (
    <Modules.Checkout.Screens.RootScreen
      implementation={implementation}
      onBack={onBack}
      uiConfig={{
        ...getUiConfig('Modules.Checkout.Screens.RootScreen')
      }}
    />
  )
}
