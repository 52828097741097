import React from 'react'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

// import ProductsRouter from './ProductsRouter'
import { ProductCatalogueApp } from '../../retailos/ProductRouter/ProductRouter'

export default (
  <PrivateRoute
    key='/products'
    path='/products'
    component={TimedLogoutComponent(ProductCatalogueApp)}
    allowOffline
  />
)
