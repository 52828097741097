import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import Checkbox from '../../../../components/Fields/Checkbox'
import P from '../../../../components/P'
import { translations } from '../../../../config'
import style from './style'

class NotificationsFilters extends PureComponent {
  onToDoChange = () => {
    const { filters, onFiltersChange } = this.props
    const { toDo } = filters
    onFiltersChange({ toDo: !toDo })
  }
  onIsFlaggedChange = () => {
    const { filters, onFiltersChange } = this.props
    const { isFlagged } = filters
    onFiltersChange({ isFlagged: !isFlagged })
  }
  render () {
    const { classes, filters } = this.props
    const { toDo, isFlagged } = filters
    return (
      <div className={classes.container}>
        <P className={classes.text} type='inline' value={`${translations('Show')}:`} />
        <FormControl>
          <Checkbox
            label={translations('To do')}
            value={toDo}
            checked={toDo}
            onChange={this.onToDoChange}
            noErrorTextLabel
          />
        </FormControl>
        <FormControl>
          <Checkbox
            label={translations('Flagged')}
            value={isFlagged}
            checked={isFlagged}
            onChange={this.onIsFlaggedChange}
            noErrorTextLabel
          />
        </FormControl>
      </div>
    )
  }

} 

NotificationsFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    isFlagged: PropTypes.bool.isRequired,
    toDo: PropTypes.bool.isRequired
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired
}

export default withStyles(style)(NotificationsFilters)
