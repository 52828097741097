import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import { DatePicker } from '../../../../components/EditableFields'
import P from '../../../../components/P'

import * as validators from '../../.././../validators'

import style from './style'
import moment from 'moment'
import { dateFormat } from '../../../../config'

const DateSection = ({ editable, orderDate, classes }) => {
  return (
    <div className={classes.section}>
      <div className={classes.dateInner}>
        {
          editable
          ? (
            <FormControl className={classes.formControl}>
              <DatePicker
                label='Order Date'
                name='orderDate'
                editing
                validate={[validators.checkoutDate]}
              />
            </FormControl>
          )
          : (
            <Fragment>
              <P type='textButton' value='Order Date' />
              <P type='textButton' value={orderDate || moment().format(dateFormat)} />
            </Fragment>
          )
        }
      </div>
    </div>
  )
}

DateSection.propTypes = {
  editable: PropTypes.bool.isRequired,
  orderDate: PropTypes.string
}

export default withStyles(style)(DateSection)
