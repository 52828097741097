import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ActivitiesActions {
  fetchLatestCustomerActivities = () => ({
    type: constants.FETCH_LATEST_ACTIVITIES,
    promise: digitalStoreSdk.users.fetchLatestCustomerActivities
  })
  fetchActivities = ({ customerId, page, limit, includes }) => ({
    type: constants.FETCH_ACTIVITIES_BY_CUSTOMER_ID,
    promise: () => digitalStoreSdk.activities.fetchActivities({ customerId, page, limit, includes }),
    payload: { customerId, page, limit }
  })
}

export default new ActivitiesActions()
