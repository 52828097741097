import { general } from './configProvider'

const {
  loginTypes,
  productDetailsTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  productsFilters,
  statusFilters,
  loyaltyTiers,
  clientReporting,
  salesforceOCAPI = {},
  checkoutModules,
  consultationModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  productDetails,
  viewEvent,
  noPrices,
  products,
  addressLookupCountries,
  retailAnalytics,
  customerSnapshot,
  checkoutBackdateLimit,
  basketEnabled = true,
  placeholderImage,
  customerRegionEnabled,
  checkoutAllowUnassignedOrder,
  emailBasketEnabled,
  saveToProfileEnabled,
  deliveryEnabled,
  registerCustomerTermsCheckboxEnabled,
  consultationsEnabled,
  createAppointmentEnabled,
  showInStoreStockInVariantDropdown,
  isStockExact,
  storeroomRequestEnabled,
  storeroomRequestModules,
  forgotPasswordFormEnabled,
  manualDiscountsEnabled,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  storeroomProductNotPickedReasons,
  isStoreroomProductNotPickedReasonRequired,
  editOrderEnabled,
  refundOrderEnabled,
  toggleCustomerFollow,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled,
  addedToBasketModalEnabled,
  scanProductEnabled,
  distanceUnits,
  notificationContentOptions,
  messageContentOptions,
  storeLocationsEnabled,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled,
  customerProfileContentOptions,
  recommendedProductsContentOptions,
  tileDimensions,
  apiUpdateSource,
  groupedProductsEnabled,
  plpAddToBasketEnabled,
  paymentOptions,
  storePinMinLength,
  loginNotes,
  contactEmail,
  searchWithinCategory,
  continuousBasketScanEnabled,
  customerSelfRegistrationEnabled,
  findInOtherLocationsEnabled,
  retailAnalyticsDefaultDateRange,
  enabledOrderManagementFilterIds,
  omitStoreSaleDeliveryDetails,
  maxCustomersForGroupMessaging,
  customAdditionalOrderListingScreenFields,
  customOrderListingScreenFieldHeaders,
  customerSearchColumns
} = general

export {
  loginTypes,
  productDetailsTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  productsFilters,
  statusFilters,
  loyaltyTiers,
  clientReporting,
  salesforceOCAPI,
  checkoutModules,
  consultationModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  productDetails,
  viewEvent,
  noPrices,
  products,
  addressLookupCountries,
  retailAnalytics,
  customerSnapshot,
  checkoutBackdateLimit,
  basketEnabled,
  placeholderImage,
  customerRegionEnabled,
  checkoutAllowUnassignedOrder,
  emailBasketEnabled,
  saveToProfileEnabled,
  deliveryEnabled,
  registerCustomerTermsCheckboxEnabled,
  consultationsEnabled,
  createAppointmentEnabled,
  showInStoreStockInVariantDropdown,
  isStockExact,
  storeroomRequestEnabled,
  storeroomRequestModules,
  forgotPasswordFormEnabled,
  manualDiscountsEnabled,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  storeroomProductNotPickedReasons,
  isStoreroomProductNotPickedReasonRequired,
  editOrderEnabled,
  refundOrderEnabled,
  toggleCustomerFollow,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled,
  addedToBasketModalEnabled,
  scanProductEnabled,
  distanceUnits,
  notificationContentOptions,
  messageContentOptions,
  storeLocationsEnabled,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled,
  customerProfileContentOptions,
  recommendedProductsContentOptions,
  tileDimensions,
  apiUpdateSource,
  groupedProductsEnabled,
  plpAddToBasketEnabled,
  paymentOptions,
  storePinMinLength,
  loginNotes,
  contactEmail,
  searchWithinCategory,
  continuousBasketScanEnabled,
  customerSelfRegistrationEnabled,
  findInOtherLocationsEnabled,
  retailAnalyticsDefaultDateRange,
  enabledOrderManagementFilterIds,
  omitStoreSaleDeliveryDetails,
  customAdditionalOrderListingScreenFields,
  customOrderListingScreenFieldHeaders,
  maxCustomersForGroupMessaging,
  customerSearchColumns
}
