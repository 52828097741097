import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import Checkbox from './Checkbox'

export default compose(
  withHandlers({
    onChange: (props) => e => {
      // on change here was undefined so I assume it never needed the onChange in the actual component
      const { onChange = _.noop, handleCheckboxChange = _.noop } = props
      onChange(e)
      handleCheckboxChange(e)
    }
  })
)(Checkbox)
