import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import P from '../../P'
import { formatDate, getCustomerFullName } from '../../../helpers'
import { dateFormat, timeFormat } from '../../../config'

import style from './style'

const dateAndTimeFormat = `${dateFormat} @ ${timeFormat}`

const renderIconOrImage = ({ icon: Icon, imageUrl, classes }) => {
  if (imageUrl) {
    return <img src={imageUrl} className={classes.image} />
  } else if (Icon) {
    return <Icon className={classes.image} />
  } else {
    return null
  }
}

const Activity = ({ customer = {}, date, text, icon, classes, imageUrl, type, activityClickHandler }) => {
  const imageContainerClasses = cx({
    [classes.circleImageContainer]: icon,
    [classes.imageContainer]: imageUrl
  })
  const { firstName, lastName, anonymised } = customer
  const customerName = getCustomerFullName({ firstName, lastName, anonymised })
  return (
    <div className={classes.container} onClick={activityClickHandler}>
      <div className={classes.leftContainer}>
        <div className={imageContainerClasses}>
          {renderIconOrImage({ icon, imageUrl, classes, type })}
        </div>
      </div>
      <div className={classes.content}>
        <P value={customerName} className={classes.nameText} type='textButton' />
        <P className={classes.bodyText} value={text} />
        <P className={classes.bodyText} value={formatDate(date, dateAndTimeFormat)} />
      </div>
    </div>
  )
}

Activity.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  customer: PropTypes.shape({}).isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string,
  icon: PropTypes.node,
  imageUrl: PropTypes.string,
  activityClickHandler: PropTypes.func.isRequired
}

export default withStyles(style)(Activity)
