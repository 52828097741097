// this file handles:
// - accessing all stores from redux
// - prefilling store as store the app is currently being used in
// - prefilling delivery details from customer object
// - updating redux store when either of the radio options change value
// so that this is immediately reflected in the basket summary on the
// right of the screen
// - including updating redux store and form values for deliveryOption
// when deliveryType changes (hard)
//
// onsubmit...
// transforming form data into the shape the order api requires
// and making sure only data relevant for chosen deliveryType is used

import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'
import moment from 'moment'

import { translations, checkoutBackdateLimit, dateFormat } from '../../../config'
import { selectors as storeSelectors } from '../../../store/modules/stores'
import { selectors as authSelectors } from '../../../store/modules/auth'
import cf from '../../../formatters/currencyFormatter'
import Delivery, { formId } from './Delivery'
import { personalFieldNames } from './PersonalFields'
import withCheckoutFlow from '../withCheckoutFlow'
import modalService from '../../../services/modalService';

const getDeliveryOptionById = ({ deliveryOptions = [], id }) => (
  deliveryOptions.find(option => (
    id === option.id
  ))
)

const mapDeliveryOptions = ({ id, price, name }) => ({
  value: id,
  label: `${translations(name)} - ${_.get(price, 'value') ? cf.format(price) : _.upperCase(translations('free'))}`
})

export default compose(
  withCheckoutFlow,
  connect(state => {
    return {
      allStores: storeSelectors.getAllStores(state),
      storeDropdownOptions: storeSelectors.getAllStoresAsOptions(state),
      userSelectedStoreId: authSelectors.getUserSelectedStoreId(state)
    }
  }),
  withPropsOnChange(
    ['deliveryOptions', 'customer', 'userSelectedStoreId', 'deliveryDetails', 'deliveryAddress'],
    props => {
      const {
        deliveryOptions = [],
        userSelectedStoreId,
        customer,
        deliveryDetails,
        deliveryAddress,
        deliveryType,
        deliveryOption
      } = props

      const homeOptions = deliveryOptions
        .filter(({ deliveryType }) => deliveryType === 'home')
        .map(mapDeliveryOptions)

      const storeOptions = deliveryOptions
        .filter(({ deliveryType }) => deliveryType === 'store')
        .map(mapDeliveryOptions)

      const initialDetails = _.pick(
        { ...customer, ...deliveryDetails },
        personalFieldNames
      )

      const initialAddress = {
        ..._.get(customer, 'address'),
        ...deliveryAddress
      }

      const deliveryOptionHome = (
        (deliveryType === 'home' && _.get(deliveryOption, 'id')) ||
        _.get(homeOptions, `[0].value`)
      )

      const deliveryOptionStore = (
        (deliveryType === 'store' && _.get(deliveryOption, 'id')) ||
        _.get(storeOptions, `[0].value`)
      )

      return {
        initialValues: {
          deliveryType,
          deliveryOption,
          deliveryOptionHome,
          deliveryOptionStore,
          storeId: userSelectedStoreId,
          ...initialDetails,
          address: initialAddress,
          orderDate: moment().format('DD/MM/YYYY')
        },
        homeOptions,
        storeOptions,
        hasAddressPrefilled: !!(_.get(customer, 'address.postCode')),
        customer
      }
    }
  ),
  withHandlers({
    onChangeDeliveryType: ({ updateOrder, deliveryOptions, homeOptions, storeOptions, dispatch }) => e => {
      const deliveryType = e.target.value
      const deliveryTypeIsHome = deliveryType === 'home'
      const deliveryOption = getDeliveryOptionById({
        deliveryOptions,
        id: (
          deliveryTypeIsHome
          ? _.get(homeOptions, `[0].value`)
          : _.get(storeOptions, `[0].value`)
        )
      })
      dispatch(change(
        formId,
        (
          deliveryTypeIsHome
          ? 'deliveryOptionHome'
          : 'deliveryOptionStore'
        ),
        deliveryOption.id
      ))
      updateOrder({
        deliveryType,
        deliveryOption
      })
    },
    onChangeDeliveryOption: ({ updateOrder, deliveryOptions }) => e => {
      updateOrder({
        deliveryOption: getDeliveryOptionById({ deliveryOptions, id: e.target.value })
      })
    },
    onSubmit: ({ updateOrder, deliveryOptions, allStores, next }) => formData => {
      const {
        deliveryType,
        deliveryOptionHome,
        deliveryOptionStore,
        storeId,
        orderDate,
        ...rest
      } = formData

      const store = (
        storeId
        ? allStores.find(({ id }) => id === storeId)
        : undefined
      )

      const updateOrderParams = {
        deliveryType,
        orderDate,
        deliveryOption: getDeliveryOptionById({
          deliveryOptions,
          id: (deliveryType === 'home' ? deliveryOptionHome : deliveryOptionStore)
        }),
        ...(
          deliveryType === 'home'
          ? {
            deliveryAddress: _.get(rest, 'address'),
            deliveryDetails: _.omit(rest, 'address')
          }
          : {
            deliveryDetails: { store: _.omit(store, 'reporting') },
            deliveryAddress: null
          }
        )
      }
      updateOrder(updateOrderParams)
      next()
    }
  })
)(Delivery)
