import axios from 'axios'
import qs from 'qs'
import { environment, addressLookupCountries } from '../../../config'

const getPcaUrl = (pcaService, params = {}) => {
  const queryString = qs.stringify({
    Key: environment.PCA_KEY,
    ...params
  })
  return `https://services.postcodeanywhere.co.uk/Capture/Interactive/${pcaService}/v1.1/json3ex.ws?${queryString}`
}

class CustomerServices {
  checkError (items) {
    if (items.length === 0) {
      throw new Error('Error')
    }

    if (items.length === 1 && items[0].Error) {
      throw new Error(items[0].Description)
    }
  }

  searchPostcode = (Text, Container) => {
    const queryParams = { Text }
    if (Container) queryParams.Container = Container
    if (addressLookupCountries) queryParams.Countries = addressLookupCountries.join(',')

    return axios.get(getPcaUrl('Find', queryParams))
    .then(({ data }) => {
      if (data.Items) {
        // the postcode API does not throw a bad request if there is an error
        // have to check in the response for an error
        // normalise data
        this.checkError(data.Items)
        // normalise data

        if (data.Items[0].Type !== 'Address') {
          return this.searchPostcode(Text, data.Items[0].Id)
        } else {
          return data.Items.map(item => {
            return {
              id: item.Id,
              text: item.Text,
              description: item.Description
            }
          })
        }
      } else {
        throw new Error('No Items')
      }
    })
  }

  searchFullAddress = (Id) => {
    return axios.get(getPcaUrl('Retrieve', { Id }))
    .then(({ data }) => {
      if (data.Items) {
        this.checkError(data.Items)
        // normalise data
        // always an array with a length of one - the full address
        const item = data.Items[0]
        const formattedData = {
          address1: item.Line1,
          address2: item.Line2,
          city: item.City,
          county: item.ProvinceName,
          country: item.CountryName,
          postCode: item.PostalCode
        }
        return formattedData
      }
    })
  }
}

export default new CustomerServices()
