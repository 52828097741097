import React from 'react'
import { Modules } from '@redant/retailos-ui'
import store from '../../store'
import { selectors as authSelectors } from '../../store/modules/auth'
import { httpClientService } from '../dependencies'
import { formatCustomerSearchColumns, mapCommunicationOptions } from '../../helpers'
import { communicationOptions, customerSearchColumns, dateFormat, maxCustomersForGroupMessaging } from '../../config'
import { getUiConfig } from '../uiConfigStore'
import './uiConfig'

export const ProductWaitlistScreen = ({ match, history }) => {
    const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(store.getState())
    const productWaitlistFunctions = new Modules.Waitlist.Implementation.ProductWaitlistFunctions(
      httpClientService,
      history
    )
    const useProductWaitlistScreen = Modules.Waitlist.Implementation.provideUseProductWaitlistScreen(
      {
        productWaitlistFunctions,
        routeParams: match.params,
        isMessagingAllowedForRole,
        communicationOptions: mapCommunicationOptions(communicationOptions.channelOptionKeys)
      }
    )
    return (
      <Modules.Waitlist.Screens.ProductWaitlistScreen
        implementation={useProductWaitlistScreen}
        uiConfig={{
          ...getUiConfig("Modules.Waitlist.Screens.ProductWaitlistScreen"),
        }}
        columns={formatCustomerSearchColumns(customerSearchColumns, dateFormat)}
        maxCustomers={maxCustomersForGroupMessaging}
      />
    )
}
