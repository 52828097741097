import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import Heading from '../Heading'
import MobileSelect from '../MobileSelect'
import { DivWithHeightOffset } from '../withHeightOffset'
import style from './style'

class OrderKanban extends Component {
  componentDidMount () {
    // this fixes the issue of Material UI dialog (overlay)
    // components messing up kanban header styles
    document.body.style.paddingRight = '0 !important'
    document.body.style.overflowY = 'scroll !important'

    this._bodyStyle = document.createElement('style')
    this._bodyStyle.innerHTML = `
      body {
        padding-right: 0 !important;
        overflow-y: auto !important;
      }
    `

    const insertRef = document.querySelector('style')
    insertRef.parentNode.insertBefore(this._bodyStyle, insertRef)
  }

  componentWillUnmount () {
    if (this._bodyStyle) {
      this._bodyStyle.remove()
    }
  }
  
  _defaultRenderOrder = (order) => {
    return <div key={order.id}>{order.id}</div>
  }

  _defaultRenderEmptyColumn = () => {
    return null
  }

  renderOrders = (orders = [], column) => {
    const {
      renderOrder = this._defaultRenderOrder,
      renderEmptyColumn = this._defaultRenderEmptyColumn
    } = this.props
    if (orders.length) {
      return orders.map(renderOrder)
    } else {
      return renderEmptyColumn(column)
    }
  }
  
  renderDesktop = () => {
    const { columns, ordersByColumn, classes, padding, className } = this.props
    // the reason kanban takes padding instead of className is because it has to do some
    // funky stuff to have the fixed headers and the columns that scroll in unison
    // (see the !important styles)

    const containerClass = cx(classes.container, className)

    const columnsClass = cx(classes.columns)
    const columnsStyle = {
      gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
      padding
    }

    return <div className={containerClass}>
      {/* column titles */}
      <DivWithHeightOffset className={cx(columnsClass, classes.columnHeaders)} style={columnsStyle}>
        {columns.map((x, columnIndex) => {
          const column = columns[columnIndex]
          const title = column.title || (column.statuses && column.statuses.join())
          return <div className={classes.columnTitleBox} key={title}>
            <Heading
              component={'h2'}
              className={classes.columnTitle}
            >
              {title}
            </Heading>
          </div>
        })}
      </DivWithHeightOffset>
      {/* column contents */}
      <div className={cx(columnsClass, classes.columnContents)} style={columnsStyle}>
        {columns.map((x, columnIndex) => {
          const orders = ordersByColumn[columnIndex]
          const column = columns[columnIndex]
          return <ul className={classes.columnContainer} key={columnIndex}>
            {this.renderOrders(orders, column)}
          </ul>
        })}
      </div>
    </div>
  }

  renderMobile = () => {
    const {
      selectedColumn = 0,
      selectOptions,
      onChangeColumn,
      ordersByColumn,
      columns,
      paddingMobile,
      padding,
      classes
    } = this.props

    const orders = ordersByColumn[selectedColumn]
    const column = columns[selectedColumn]

    return <div style={{ padding: paddingMobile || padding }}>
      <MobileSelect
        value={selectedColumn}
        options={selectOptions}
        onChange={onChangeColumn}
      />
      <div className={classes.ordersContainerMobile}>
        {this.renderOrders(orders, column)}
      </div>
    </div>
  }


  render () {
    return <Fragment>
      <Hidden xsDown>
        {this.renderDesktop()}
      </Hidden>
      <Hidden smUp>
        {this.renderMobile()}
      </Hidden>
    </Fragment>
  }
}

export default withStyles(style)(OrderKanban)
