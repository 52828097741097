import React  from 'react'
import { translations } from '../../../../../config'
import { withStyles } from '@material-ui/core/styles'
import KeyValueCard from '../../../../../components/KeyValueCard'

import style from './style'
 

const OrderAdditionalDetails = ({ classes, order, configDetailsSchema }) => {
  const orderInfList = []
  configDetailsSchema.forEach(({ label, info }) => {
    const configKeyValue = _.get(order, info)
    orderInfList.push([translations(label), configKeyValue])
  })

  return (
    <>
      <KeyValueCard data={orderInfList} className={classes.noMargin}/>
    </>
  )
}

export default withStyles(style)(React.memo(OrderAdditionalDetails))