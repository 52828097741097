import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import Button from '../../../../components/Button'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import Note from '../Note'

import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'

import { translations } from '../../../../config'

import style from './style'

class LatestNote extends PureComponent {
  renderContentBox = () => {
    const { customer, classes, note } = this.props
    if (customer.anonymised) {
      return <P value={translations('Latest Note Anonymised')} type='textButton' />
    }
    if (customer.notes && customer.notes.length > 0) {
      return (
        <Fragment>
          <P value={translations('Latest Note')} type='textButton' className={classes.latestNoteTitle} />
          <Note truncate note={note} customerId={customer.id} latest />
        </Fragment>
      )
    }
    return <P value={translations('No notes')} type='textButton' />
  }

  render () {
    const { classes, customer, editable, viewNotesHandler } = this.props
    return (
      <ContentBox>
        <ContentBoxHeader>
          <TextContent><H2 className={classes.notesHeader} value='Notes' /></TextContent>
          <RightButtonContent>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.viewButtonGridItem} onClick={viewNotesHandler} >
                { editable 
                    ? (
                      <Link to={`/customers/${customer.id}/notes`} className={classes.notesLink} >
                        <Button
                          id='latest-notes-view'
                          color='primary'
                          className={classes.notesButton}
                        >
                          {translations('View/Create')}
                        </Button>
                      </Link>
                    )
                    : null
                }
              </Grid>
            </Grid>
          </RightButtonContent>
        </ContentBoxHeader>
        <ContentBoxBody>
          {this.renderContentBox()}
        </ContentBoxBody>
      </ContentBox>
    )
  }
}

export default withStyles(style)(LatestNote)
