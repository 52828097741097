import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import InspirationSearchInput from './InspirationSearchInput'
import { actions as inspirationsActions, selectors as inspirationsSelectors } from '../../../../store/modules/inspirations'
import { selectors as appSelectors } from '../../../../store/modules/app'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { BRAND_INSPIRATIONS } from '../../../../store/modules/app/constants'

const mapStateToProps = state => {
  const type = appSelectors.getInspirationsTab(state) === BRAND_INSPIRATIONS ? 'brand' : 'user'
  const userId = type === 'brand' ? undefined : authSelectors.getActiveUserId(state)
  return {
    query: inspirationsSelectors.getQuery(state),
    type,
    userId
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchInspirations: ({ dispatch, type, userId }) => ({ query }) => dispatch(inspirationsActions.searchInspirationsFresh({ query, type, userId }))
  })
)(InspirationSearchInput)
