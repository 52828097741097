import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import BasicModalOverlay from '../../BasicModalOverlay'
import { translations } from '../../../config'
import style from './style'

import AttachmentItem from './AttachmentItem'

import { getImage } from '../../Images'
const closeIcon = getImage('closeIconBlack')

const AttachmentsModal = props => {
  const { attachments = [], success, classes, ...rest } = props

  return (
    <div className={classes.attachmentsContainer}>
      <div className={classes.buttonContainer}>
        <IconButton
          onClick={success}
          aria-label='Close'
          className={classes.buttonStyle}
        >
          <img src={closeIcon} />
        </IconButton>
      </div>
      <div className={classes.overlayContainer}>
        <BasicModalOverlay
          title={translations('Attachments')}
          {...rest}
        >
          {attachments.map((attachment, i) => {
            return <AttachmentItem {...attachment} key={i} />
          })}
        </BasicModalOverlay>
      </div>
    </div>
  )
}

export default withStyles(style)(AttachmentsModal)