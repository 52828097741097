import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import ChecklistCheckbox from './ChecklistCheckbox'

export default compose(
  withHandlers({
    onChange: (props) => e => {
      const { onChange = _.noop, handleCheckboxChange = _.noop } = props
      onChange(e)
      handleCheckboxChange(e)
    }
  })
)(ChecklistCheckbox)
