import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../Editable'
import { ProductCarousel } from '../../Fields'
import P from '../../P'

import style from './style'

const SelectProduct = Editable({
  input: ({ classes, label, ...props }) => {
    return (
      <div>
        <P className={classes.selectProductHeader} value={label} type='textButton' />
        <ProductCarousel
          {...props}
          featureType={label}
          editing
        />
      </div>
    )
  },
  display: ({ label, classes, ...rest }) => {
    return (
      <div>
        <P className={classes.selectProductHeader} value={label} type='textButton' />
        <ProductCarousel
          {...rest}
          featureType={label}
          editing={false}
        />
      </div>
    )
  }
})

export default withStyles(style)(SelectProduct)
