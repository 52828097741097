import React from 'react'
import PropTypes from 'prop-types'
import BasicModalOverlay from '../BasicModalOverlay'
import Form, { FormBody } from '../Form'

import P from '../P'

const FormModal = (props) => {
  const {
    formId,
    actions,
    title,
    text,
    schema,
    layout,
    initialValues,
    onSubmit,
    FormComp
  } = props

  const formProps = { initialValues, onSubmit }
  const basicModalOverlayProps = { actions, title }
  const formBodyProps = { schema, layout, formId, editing: true, fullWidthFields: true }

  return <FormComp {...formProps}>
    <BasicModalOverlay {...basicModalOverlayProps}>
      {text ? <P value={text} /> : null}
      <FormBody {...formBodyProps} />
    </BasicModalOverlay>
  </FormComp>
}

FormModal.propTypes = {
  formId: PropTypes.string,
  actions: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string,
  schema: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    field: PropTypes.string.isRequired,
    props: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    classes: PropTypes.shape({
      fieldWrapper: PropTypes.string,
      field: PropTypes.string
    })
  })),
  layout: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string
    ])),
    PropTypes.string
  ])),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  FormComp: PropTypes.instanceOf(Form)
}

export default FormModal
