const contentContainerHeight = 153

const style = theme => ({
  buttonContainer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    height: '90px',
    background: theme.palette.lightGrey.main,
    display: 'flex',
    alignItems: 'center'
  },
  contentContainer: {
    height: `calc(100vh - ${contentContainerHeight}px)`,
    overflow: 'auto'
  },
  createNoteButton: {
    width: '330px',
    height: '50px',
    fontWeight: 600
  },
  createNoteLink: {
    display: 'block',
    margin: '0 auto',
    textDecoration: 'none'
  },
  notesContainer: {
    margin: '20px 30px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px',
      border: `1px solid ${theme.palette.mediumGrey.main}`,
      margin: '20px 50px'
    }
  },
  noNotesContainer: {
    padding: '20px 50px',
    height: 'calc(100% - 80px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noNotesText: {
    textTransform: 'uppercase',
    fontSize: 19,
    fontWeight: 'bold'
  },
  anonContainer: {
    height: `calc(100vh - ${theme.totalHeaderHeight}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hideOverflow: {
    overflow: 'hidden'
  }
})

export default theme => style(theme)
