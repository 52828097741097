import React from 'react'
import Checklist from './Checklist'
import OptionsFormatter from '../Wrappers/OptionsFormatter'

const markSingle = ({ options, value }) => {
  const singleValue = Array.isArray(value) ? value[0] : value
  return options.map(option => ({
    ...option,
    checked: option.value === singleValue
  }))
}

const markMultiple = ({ options, value }) => {
  return options.map(option => ({
    ...option,
    checked: value.includes(option.value)
  }))
}

const changeMultiple = ({ checkedItems, checkedValue }) => {
  // Add or remove the checked item
  return checkedItems.includes(checkedValue)
    ? checkedItems.filter(v => v !== checkedValue)
    : checkedItems.concat(checkedValue)
}

class ChecklistContainer extends React.Component {
  onChange = (e) => {
    const checkedValue = e.target.value
    const checkedItems = this.props.value || []
    const newValue = this.props.multiple
      ? changeMultiple({ checkedItems, checkedValue })
      : checkedValue === checkedItems ? '' : checkedValue

    this.props.onChange(newValue)
  }

  markCheckedOptions = () => {
    const { options, value } = this.props
    return this.props.multiple
      ? markMultiple({ options, value })
      : markSingle({ options, value })
  }

  _generateProps = () => ({
    ...this.props,
    onChange: this.onChange,
    options: this.markCheckedOptions()
  })

  render () {
    const props = this._generateProps()
    return <Checklist {...props} />
  }
}

export default OptionsFormatter(ChecklistContainer)
