import getGroupedItems from './getGroupedItems'
import getGroupNames from './getGroupNames'

const getGroupedListData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { type: chartType, data, fullList } = reportSchema
  const { groupBy } = data

  if ((fullList || chartType === 'LIST') && groupBy) {
    // make an array: [ { name, items }, ... ]
    const groupedItems = getGroupedItems(props)
    const groupNames = getGroupNames(props)
    const groupedListData = groupNames.map(groupName => {
      return {
        name: groupName,
        items: groupedItems[groupName]
      }
    })
    return groupedListData
  }
}

export default getGroupedListData
