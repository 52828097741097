const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20
  },
  thumb: {
    height: 40,
    width: 40,
    marginLeft: 30,
    marginRight: 30
  },
  thumbImage: {
    height: 40,
    width: 40
  },
  thumbFaded: {
    height: 40,
    width: 40,
    marginLeft: 30,
    marginRight: 30,
    opacity: 0.4
  },
  thumbImageFaded: {
    height: 40,
    width: 40
  },
  thumbEmphasised: {
    height: 55,
    width: 55,
    marginLeft: 30,
    marginRight: 30
  },
  thumbImageEmphasised: {
    height: 55,
    width: 55
  }
})

export default styles
