import React from 'react'

import HybridRouter from '../../../components/HybridRouter'

import ViewInspirationScreen from '../ViewInspirationScreen'
import InspirationsSearchScreen from '../InspirationsSearchScreen'

const routes = [
  { path: '/inspirations/:inspirationId', component: ViewInspirationScreen, exact: false },
  { path: '/inspirations/', component: InspirationsSearchScreen, exact: false }
]

const InspirationsRouter = props => {
  const { noRouting, exit, multiSelect, ...rest } = props
  const passedProps = {
    multiSelect,
    searchBarPosition: noRouting ? 'absolute' : 'fixed',
    ...rest
  }

  return <HybridRouter
      routes={routes}
      noRouting={noRouting}
      exit={exit}
      passedProps={passedProps}
      initialPath='/inspirations'
    />
}

export default InspirationsRouter
