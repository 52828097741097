import EditBasket from './EditBasket'
import { compose, withHandlers } from 'recompose'
import withCheckoutFlow from '../withCheckoutFlow'

export default compose(
  withCheckoutFlow,
  withHandlers({
    onChange: ({ updateOrder }) => ({ orderDate }) => {
      updateOrder({ orderDate })
    }
  })
)(EditBasket)
