export default theme => ({
  container: {
    overflowX: 'auto',
    marginRight: '7.5%',
    marginLeft: '7.5%'
  },
  inner: {
    // minWidth: 1200
  },
  noResultsMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  }
})
