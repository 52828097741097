import React, { Component } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { POLLS_QUESTIONS, POLLS_RESULTS } from '../../../store/modules/app/constants'
import MobileSelect from '../../../components/MobileSelect'
import FullScreenProgress from '../../../components/FullScreenLoader'
import BackBar from '../../../components/BackBar'
import PollsQuestionsList from '../PollsQuestionsList'
import PollsResultsList from '../PollsResultsList'
import style from './style'

class PollsListScreen extends Component {
  renderSelect = () => {
    const { pollsTab, options, handleTabChange, classes, hasViewPollResults } = this.props
    return hasViewPollResults && (
      <div className={classes.selectContainer}>
        <MobileSelect
          value={pollsTab}
          options={options}
          onChange={handleTabChange}
          shadow={false}
          border
          largeText
          uppercase={false}
        />
      </div>
    )
  }
  renderContent = () => {
    const { pollsTab } = this.props
    switch (pollsTab) {
      case POLLS_QUESTIONS:
        return <PollsQuestionsList />
      case POLLS_RESULTS:
        return <PollsResultsList />
      default:
        return null
    }
  }
  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.backButton}>
          <BackBar />
        </div>
        {this.renderSelect()}
        {this.renderContent()}
      </div>
    )
  }
}

export default compose(
  FullScreenProgress,
  withStyles(style)
)(PollsListScreen)
