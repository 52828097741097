import React from 'react'
import { Modules } from '@redant/retailos-ui'
import { contactEmail } from '../../config'

import './uiConfig'
import { getUiConfig } from '../uiConfigStore'

const useQuery = (location) => new URLSearchParams(location.search)

export const PaymentSuccessScreen = ({ match, location }) => {
  const query = useQuery(location)
  const storeName = decodeURIComponent(query.get('storeName') || 'Retailos')
  return (
    <Modules.Checkout.Screens.PaymentSuccessScreen
      orderNumber={match.params.id}
      storeName={storeName}
      contactEmail={contactEmail}
      uiConfig={{
        ...getUiConfig('Modules.VirtualConsultation.Screens.PaymentSuccessScreen')
      }}
    />
  )
}
