import React, { Component, Children } from 'react'
import { List, AutoSizer } from 'react-virtualized'
import styled from 'styled-components'

import { paperHeight } from '../Paper'

const Container = styled.div`
  & > div {
    height: auto !important;
    width: auto !important;
  }
`

class VirtualizedMenuList extends Component {
  rowRenderer = (params) => {
    const { index, style } = params
    const { children, classes } = this.props
    const childrenArr = Children.toArray(children)

    return (
      <div
        key={`react-virtualized_${childrenArr[index]}_${Math.random()}`}
        style={style}
        className={classes.listItem}
      >
        {childrenArr[index]}
      </div>
    )
  }

  render() {
    const { children, className, dropdownValue, height, rowRenderer } = this.props
    const rowCount = Children.toArray(children).length
    return <Container>
      <AutoSizer>
        {({ width }) => (
          <List
            className={className}
            rowRenderer={rowRenderer}
            height={height || paperHeight}
            width={width}
            rowHeight={48}
            rowCount={rowCount}
            dropdownValue={dropdownValue}
          />
        )}
      </AutoSizer>
    </Container>
  }
}

export default VirtualizedMenuList
