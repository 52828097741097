import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'

import * as authSelectors from '../auth/selectors'

import actions from './actions'
import { DELETE_EVENT } from './constants'
import { SUCCESS } from '../../middleware/redux-promise'

class EventsMiddleware {
  fetchEventMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const result = matchPath(action.payload.location.pathname, { path: '/events/:id', exact: true })
      if (authSelectors.getIsLoggedIn(state) && result) {
        dispatch(actions.fetchEvent({ id: result.params.id }))
      }
    }
  }
  deleteEventSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === DELETE_EVENT && action.status === SUCCESS) {
      dispatch(push('/events'))
    }
    next(action)
  }
}

export default new EventsMiddleware()
