const styles = (theme) => ({
  copy: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  bigButton: {
    padding: 14,
    borderRadius: 0
  }
})

export default styles
