import digitalStoreSdk from '../../../digitalStoreSdk'
import couchbaseService from '../../../services/couchbaseService'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'
import { logger } from '../../../helpers'
import { groupedProductsEnabled, searchWithinCategory } from '../../../config'

class ProductsActions {
  searchProductsFresh = ({ query, sort, category, filters, availableSortOptions }) => {
    logger.trace(`searchProductsFresh`)
    logger.debug({ query, sort, category, filters, availableSortOptions })
    return ({
      type: constants.SEARCH_PRODUCTS_FRESH,
      sort,
      query,
      category,
      filters, 
      availableSortOptions,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)
        const region = authSelectors.getUserSelectedRegionId(getState())
        const catalogue = authSelectors.getUserSelectedStoreCatalogue(state)
        const searchProductsSort = sort === undefined ? selectors.getSort(state) : sort
        const searchProductsCategory = category === undefined ? selectors.getCategory(state) : category
        const searchProductsQuery = query === undefined ? selectors.getQuery(state) : query
        const searchProductsFilters = filters === undefined ? selectors.getActiveFilters(state) : filters 
        const size = selectors.getSize(state)

        if (isConnected) {
          const categoryId = (searchProductsCategory && (searchWithinCategory || !searchProductsQuery))
            ? searchProductsCategory.id
            : undefined
          return digitalStoreSdk.products
            .searchProducts({
              region,
              catalogue: catalogue || undefined,
              grouped: groupedProductsEnabled,
              query: searchProductsQuery || null,
              sort: searchProductsSort,
              category: categoryId,
              filters: searchProductsFilters,
              page: 1,
              size
            })
            .then(result => ({
              query: searchProductsQuery,
              sort: searchProductsSort,
              category: searchProductsCategory,
              ...result
            }))
        }

        // offline mode flow
        return couchbaseService.searchProducts({
          region,
          query: searchProductsQuery,
          sort: searchProductsSort,
          category: searchProductsCategory ? searchProductsCategory.id : undefined,
          activeFilters: filters,
          page: 1,
          size
        })
      }
    })
  }

  searchProductsNext = () => ({
    type: constants.SEARCH_PRODUCTS_NEXT,
    promise: (dispatch, getState) => {
      const { products: { page, query, size, sort, category, activeFilters } } = getState()
      const region = authSelectors.getUserSelectedRegionId(getState())
      const catalogue = authSelectors.getUserSelectedStoreCatalogue(getState())
      const searchProductsSort = sort === undefined ? selectors.getSort(getState()) : sort
      const searchProductsFilters = activeFilters === undefined ? selectors.getActiveFilters(getState()) : activeFilters
      const categoryId = (category && (searchWithinCategory || !query))
        ? category.id
        : undefined
      return digitalStoreSdk.products
        .searchProducts({ 
          grouped: groupedProductsEnabled, 
          region, 
          catalogue, 
          query, 
          sort: searchProductsSort, 
          page: page + 1, 
          size, 
          category: categoryId,
          filters: searchProductsFilters
        })
        .then(result => ({
          query,
          sort,
          category,
          ...result
        }))
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ProductsActions()
