import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import cx from 'classnames'

import RemoveButton from '../RemoveButton'
import H2 from '../../../H2'
import Image from '../../../Image'
import style from './style'

const Inspiration = ({
  id,
  title,
  onDelete,
  images,
  onInspirationContainerClick,
  editing,
  classes,
  width
}) => {
  return (
    <div onClick={onInspirationContainerClick} className={cx(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })}>
      {editing && (
        <RemoveButton onClick={event => onDelete({ id, event })} />
      )}
      <Image src={_.get(images, '0')} className={classes.image} containerClassName={classes.imageContainer} />
      <H2 className={classes.text} value={title} />
    </div>
  )
}

export default withStyles(style)(Inspiration)
