const styles = {
  option: {
    color: 'rgba(0, 0, 0, 0.87)',
    height: 24,
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'content-box',
    background: 'none',
    lineHeight: '1.5em',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '12px 16px',
    display: 'block',
    alignItems: 'center',
    outline: 0,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  focused: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    }
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: 'white',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.87)'
    }
  },
  checkbox: {
    pointerEvents: 'none'
  },
  checkboxContainer: {
    marginRight: 0
  },
  nullOption: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  divider: {
    margin: '8px 0'
  }
}

export default theme => styles
