const style = theme => ({
  container: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 10px',
    margin: '10px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 20px'
    }
  },
  resourceKey: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  typeText: {
    marginTop: 5
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '55%'
  },
  icon: {
    minHeight: 35,
    width: 25,
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      minHeight: 50,
      width: 35,
      marginRight: 25
    }
  },
  button: {
    width: 80,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.main
    },
    [theme.breakpoints.up('sm')]: {
      width: 120
    }
  },
  textContainer: {
    width: '100%'
  }
})

export default style
