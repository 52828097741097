import React from 'react'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import PollsListScreen from './PollsListScreen'

export default (
  <PrivateRoute key='/polls' path='/polls' component={TimedLogoutComponent(PollsListScreen)} />
)
