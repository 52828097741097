import React from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../components/P'
import styles from '../style'
import { translations } from '../../../config'

const AddressListError = ({ classes, searchPostcodeError, invalidPostcodeError }) => {
  const errorType = invalidPostcodeError
    ? translations('Invalid PostCode')
    : translations('503 Error')
  return (
    <Paper className={`${classes.addressListErrorPadding}`} >
      <P value={errorType} />
    </Paper>
  )
}
export default withStyles(styles)(AddressListError)
