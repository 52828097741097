import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getRegionConfig } from '../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { getTheme } from '../../config'

const ConfigProvider = ({ children }) => {
  const configName = useSelector(getRegionConfig)
  const theme = useMemo(() =>
    getTheme(configName),
    [configName]
  )

  return (
    <Fragment>
      {children(theme)}
    </Fragment>
  )
}

ConfigProvider.propTypes = {
  children: PropTypes.func.isRequired,
  theme: PropTypes.object
}

export default ConfigProvider