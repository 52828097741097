export default theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 25,
    fontSize: 13
  },
  copy: {
    marginLeft: 30,
    marginTop: 1
  },
  rightContent: {
    marginLeft: 'auto'
  },
  iconWrapper: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  initials: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#000',
    color: '#fff',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    fontWeight: 700
  },
  image: {
    width: '100%'
  }
})
