const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
  },
  face: {
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  faceImage: {
    height: 40,
    width: 40
  },
  faceFaded: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    opacity: 0.4
  },
  faceImageFaded: {
    height: 40,
    width: 40
  },
  faceEmphasised: {
    height: 50,
    width: 50,
    borderRadius: '50%'
  },
  faceImageEmphasised: {
    height: 50,
    width: 50
  }
})

export default styles
