import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import P from '../P'
import classNames from 'classnames'

import style from './style'

const FloatingBadge = ({ label, position, pixelSize, classes, containerClass, textClass }) => {
  const positionClasses = {
    topLeft: classes.topLeft,
    topRight: classes.topRight,
    bottomRight: classes.bottomRight,
    bottomLeft: classes.bottomLeft
  }
  return (
    <div className={classNames(classes.container, containerClass, positionClasses[position])} style={{width: pixelSize, height: pixelSize}}>
      <P className={classNames(classes.text, textClass)} value={label}></P>
    </div>
  )
}

FloatingBadge.propTypes = {
  label: PropTypes.string,
  pixelSize: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  containerClass: PropTypes.string,
  textClass: PropTypes.string,
  position: PropTypes.oneOf([
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ])
}

FloatingBadge.defaultProps = {
  position: 'topLeft'
}

export default withStyles(style)(FloatingBadge)
