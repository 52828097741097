import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import CustomerSearch from '../CustomerSearch'
import FollowingListItem from '../FollowingListItem'
import Button from '../../../components/Button'
import AlphabetList from '../../../components/AlphabetList'
import BottomBar from '../../../components/BottomBar'
import Container from '../../../components/Container'
import H2 from '../../../components/H2'
import ButtonsContainer from '../../../components/ButtonsContainer'
import EditMode from '../../../components/EditMode'

import { translations, checkoutAllowUnassignedOrder, customerSelfRegistrationEnabled } from '../../../config'
import style from './style'
import { ScanToRegisterModal } from '../../../retailos/ScanToRegisterModal'
import modalService from '../../../services/modalService'

const listItemProps = { noMessageButton: true }

const SelectCustomer = ({
  classes,
  onCustomerClick,
  onAnonymousCustomerClick,
  onUnassignedOrderClick,
  following,
  registerCustomer,
  checkoutType,
  editMode,
  orderNumber,
  stopEditingOrder,
  onBackClick
}) => {
  const [qrModalOpen, setQrModalOpen] = useState(false)

  const handleRegisterCustomer = () => {
    if (customerSelfRegistrationEnabled) {
      modalService.action({
        title: translations('Register Customer'),
        text: translations('Register Customer - select option text'),
        actions: [
          {
            text: translations('Register Customer - self register'),
            onClick: () => setQrModalOpen(true),
            primary: true,
            iconImage: 'scanIcon'
          },
          {
            text: translations('Register Customer - continue to registration'),
            onClick: registerCustomer,
            primary: false,
            iconImage: 'customerIconDark'
          }
        ]
      })
    } else {
      registerCustomer()
    }
  }

  const handleCloseQrModal = () => {
    setQrModalOpen(false)
  }

  return (
    <CustomerSearch
      key='checkout-customer-search'
      onCustomerClick={onCustomerClick}
      onBackClick={onBackClick}
    >
      {editMode && <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} />}
      <Container followingList>
        <H2
          className={classes.message}
          value={translations('Choose Customer')}
        />
        <AlphabetList
          items={following}
          onClickItem={item => () => onCustomerClick(item)}
          ItemComponent={FollowingListItem}
          sortKey='firstName'
          itemProps={listItemProps}
        />
      </Container>
      <BottomBar>
        <ButtonsContainer align='center'>
          <Button
            buttonType='primary'
            big
            onClick={handleRegisterCustomer}
          >
            {translations('Register Customer')}
          </Button>
          {checkoutType !== 'referral' &&
            <Button
              buttonType='primary'
              big
              onClick={onAnonymousCustomerClick}
            >
              {translations('ANONYMOUS')}
            </Button>
          }
          {checkoutAllowUnassignedOrder && checkoutType !== 'referral' &&
            <Button
              buttonType='primary'
              big
              onClick={onUnassignedOrderClick}
            >
              {translations('Unassigned Order')}
            </Button>
          }
        </ButtonsContainer>
      </BottomBar>
      <ScanToRegisterModal open={qrModalOpen} onClose={handleCloseQrModal}/>
    </CustomerSearch>
  )
}

export default withStyles(style)(SelectCustomer)
