import React from 'react'
import _ from 'lodash'

import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const icon = getImage('basketIconWhite')

const OrderStatusNotification = props => {
  const { createdAt, meta } = props
  const { orderNumber, status } = meta || {}
  const text = translations('Order Status Notification Text', {
    orderNumber,
    status: _.lowerCase(status)
  })
  return (
    <Notification
      {...props}
      iconSource={icon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

export default OrderStatusNotification
