import {
  FETCH_PRODUCTLIST,
  FETCH_PRODUCTLISTS,
  CREATE_PRODUCTLIST,
  UPDATE_PRODUCTLIST,
  PUT_PRODUCTLIST,
  DELETE_PRODUCTLIST
} from './constants'
import {
  fetchProductList,
  fetchProductLists,
  createProductList,
  updateProductList,
  putProductList,
  deleteProductList
} from './reducers'

export const defaultState = {
  list: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTLIST:
      return fetchProductList(state, action)
    case FETCH_PRODUCTLISTS:
      return fetchProductLists(state, action)
    case CREATE_PRODUCTLIST:
      return createProductList(state, action)
    case UPDATE_PRODUCTLIST:
      return updateProductList(state, action)
    case PUT_PRODUCTLIST:
      return putProductList(state, action)
    case DELETE_PRODUCTLIST:
      return deleteProductList(state, action)
    default:
      return state
  }
}
