import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import List from '../../../../../../components/List'
import StoreStockItem from './StoreStockItem'
import { translations } from '../../../../../../config'
import Button from '../../../../../../components/Button'
import modalService from '../../../../../../services/modalService'

class StoreList extends PureComponent {
  _getVariantsStock = () => {
    const { getVariantStock, selectedVariantId } = this.props
    getVariantStock({ selectedVariantId })
  }

  componentDidMount () {
    this._getVariantsStock()
  }

  _generateProps = () => ({
    ...this.props,
    ...this.state,
    _getVariantsStock: this._getVariantsStock
  })

  render () {
    const props = this._generateProps()
    const { variantsStock, classes, selectedVariantStock, selectedVariantId } = props
    return (
      <div>
        <div className={classes.lineContainer}>
          {selectedVariantStock === undefined && selectedVariantId === null
            ? (
              <div className={classes.containterTitle}>
                {translations('Please select a size')}
                <div className={classes.buttonContainer}>
                  <Button color='primary' fullWidth onClick={modalService.close}>
                    {'close'}
                  </Button>
                </div>
              </div>
                ) : selectedVariantStock === -1
                  ? (
                    <div className={classes.containterTitle}>
                      {translations('Item not available')}
                      <div className={classes.buttonContainer}>
                        <Button color='primary' fullWidth onClick={modalService.close}>
                          {'close'}
                        </Button>
                      </div>
                    </div>
              ) : (
                <div>
                  <div className={classes.containterTitle}>
                    {translations('Find in other stores')}
                  </div>
                  <List
                    items={variantsStock}
                    ItemComponent={StoreStockItem}
                  />
                </div>
              )
            }
        </div>
      </div>
    )
  }
}

export default withStyles(style)(StoreList)
