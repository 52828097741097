import customerFormStyle from './style'
import formStyle from '../../components/Form/style'
import textContentStyle from '../../components/Form/TextContent/style'

const styles = theme => ({
  addressContent: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex'
  },
  buttonSpacing: {
    [theme.breakpoints.up('xs')]: {
      marginLeft: '10px',
      width: '100%'
    }
  },
  addressContainer: {
    display: 'flex',
  },
  paperContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    width: '100%',
    zIndex: 1000,
    maxHeight: '200px',
    overflow: 'auto'
  },
  addressListErrorPadding: {
    padding: '20px',
    textAlign: 'left'
  },
  flexBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right'
  },
  positionRelative: {
    position: 'relative'
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gridGap: '8px'
    }
  },
  buttonsContainer: {
    flexShrink: 0
  },
  inputContainer: {
    flexShrink: 1,
    minWidth: 0,
    flex: 1
  }
})

export default theme => ({
  ...customerFormStyle,
  ...formStyle,
  ...styles(theme),
  ...textContentStyle
})
