import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Notification from '../Notification'

import { getImage } from '../../Images'
import { translations } from '../../../config'

const messageIcon = getImage('newMessageIcon')

const ReceivedMessageNotification = props => {
  const { customerName } = props.meta
  const { createdAt } = props
  const text = _.template(translations('Message Notification Text'))({
    customerName
  })
  return (
    <Notification
      {...props}
      iconSource={messageIcon}
      notificationText={text}
      createdAt={createdAt}
    />
  )
}

ReceivedMessageNotification.propTypes = {
  meta: PropTypes.shape({
    customerName: PropTypes.string.isRequired
  }).isRequired,
  createdAt: PropTypes.string.isRequired
}

export default ReceivedMessageNotification
