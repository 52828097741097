import { push, LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { constants as userDetailsConstants, actions as userDetailActions, selectors as userDetailSelectors } from '../userDetails'
import { selectors as authSelectors } from '../auth'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

class UserDetailsMiddleware {
  createUserSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.status === 'SUCCESS' && action.type === userDetailsConstants.CREATE_USER) {
      const { firstName, lastName, id } = action.result
      const fullName = `${firstName} ${lastName}`
      dispatch(push(`/users/${id}`))
      modalService.continue({ title: fullName, text: translations('New user created') })
      next(action)
    } else {
      next(action)
    }
  }

  loadUserMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/users/:id', exact: true })
      const isRoutingToUserDetailsView = result && result.params.id !== 'new'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToUserDetailsView && isLoggedIn) {
        const userId = result.params.id
        const currentUser = authSelectors.getCurrentUser(getState())
        // if (currentUser.id === userId) {
        //   dispatch(userDetailActions.fetchUserDetails('@me'))
        // } else {
          dispatch(userDetailActions.fetchUserDetails(userId))
        // }
      }
    }
  }

  showChangePasswordModalMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === userDetailsConstants.CHANGE_USER_PASSWORD && action.status === 'SUCCESS') {
      // changing other users password
      const userBefore = userDetailSelectors.getSelectedUser(getState())
      const { firstName, lastName } = action.result
      const fullName = `${firstName} ${lastName}`

      const text = userBefore.accountLocked
        ? `You have successfully unlocked the account for ${fullName}`
        : `You have successfully changed the password for ${fullName}`
      const title = userBefore.accountLocked
        ? translations('Account Unlocked')
        : translations('Password Changed')

      modalService.continue({ title, text })
    } else if (action.type === userDetailsConstants.CHANGE_CURRENT_USER_PASSWORD && action.status === 'SUCCESS') {
      // changing your own password
      const text = `You have successfully changed your password`
      modalService.continue({ title: translations('Password Changed'), text })
    }
    next(action)
  }
}

export default new UserDetailsMiddleware()
