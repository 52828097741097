import React, { useMemo } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { FormBody } from '../../../../components/Form'
import FormContentBox from '../../../../components/FormContentBox'
import { getAppConfig } from '../../../../config'
import * as validators from '../../../../validators'
import { useGetFormValues } from '../../../../hooks/formHooks'
import { useCurrentOrder } from '../../../../hooks/currentOrderHooks'

import style from './style'

const Form = FormContentBox('checkout-extra-details-form', { destroyOnUnmount: false })

const detailsFieldsConfig = getAppConfig('CHECKOUT', 'detailsFields', [])

const testCondition = ({ field, operator, test }, values = {}) => {
  if (operator === 'equal') {
    return values[field] === test
  }

  if (operator === 'not-equal') {
    return values[field] !== test
  }

  if (operator === 'truthy') {
    return !!values[field]
  }

  if (operator === 'falsey') {
    return !values[field]
  }

  return false
}

const sanitzeValues = (detailsFieldsConfig = [], values = {}) => {
  const newValues = {}
  Object.keys(values).map((key, index) => {
    const keyConfig = _.find(detailsFieldsConfig, (config = {}) => {
      return config.id === key
    })

    if (!keyConfig) {
      newValues[key] = values[key]
      return
    }

    if (keyConfig.condition) {
      if (testCondition(keyConfig.condition, values)) {
        newValues[key] = values[key]
      } else {
        newValues[key] = null
      }
    } else {
      newValues[key] = values[key]
    }
  })

  return newValues
}

const ExtraDetailsSection = props => {
  const { classes, remoteConfig, updateOrderDetails, editable } = props

  const formValues = useGetFormValues('checkout-extra-details-form')
  const currentOrder= useCurrentOrder()

  const initialValues = sanitzeValues(detailsFieldsConfig, currentOrder.details)

  const getRemoteOptions = (remoteOptions) => {
    return _.get(remoteConfig, remoteOptions, [])
  }

  const detailsSchema = useMemo(() => {
    return _.compact(detailsFieldsConfig.map(item => {
      const { id, field, validate, options = [], remoteOptions, condition, ...rest } = item

      if (condition && !testCondition(condition, formValues)) {
        return undefined
      }
  
      const dropdownOptions = !_.isUndefined(remoteOptions) ? getRemoteOptions(remoteOptions) : options
      return {
        id,
        field,
        props: {
          name: id,
          validate: validators.generateSchemaValidationFromConfig(validate),
          options: field === 'Dropdown' ? dropdownOptions : undefined,
          ...rest
        }
      }
    }))
  }, [formValues])

  const detailsLayout = detailsSchema.map(item => {
    // Fields should always be full width
    const { id } = item
    return `${id}:12`
  })

  if (detailsSchema.length === 0) return null

  const handleOnChange = (values) => {
    const sanitzedValues = sanitzeValues(detailsFieldsConfig, values)
    updateOrderDetails && updateOrderDetails(sanitzedValues)
  }

  return (
    <div className={classes.section}>
      <Form
        initialValues={initialValues}
        onChange={handleOnChange}
        editable={editable}
        editing={editable}
        isSubmitting={_.noop}
        givenContentBoxClass={classes.extraDetailsContentBox}
        givenContentContainerClass={classes.extraDetailsContentBoxContainer}
        showHeader={false}
      >
        <FormBody
          schema={detailsSchema}
          layout={detailsLayout}
        />
      </Form>
    </div>
  )
}

export default withStyles(style)(ExtraDetailsSection)
