import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import ProductCarouselActivityContainer from './ProductCarouselActivityContainer'

export default (Activity) => compose(
  // for dispatch
  connect(),
  withHandlers({
    onActivityClick: ({ dispatch, activity }) => () => dispatch(push(`/customers/${activity.customer.id}/full`))
  })
)(ProductCarouselActivityContainer(Activity))
