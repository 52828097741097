import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { actions as eventsActions } from '../../../../../store/modules/events'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import Event from './Event'

const mapStateToProps = state => ({
  canSendMessage: authSelectors.getIsMessagingAllowedForRole(state)
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['event'], ({ event }) => {
    const { galleries = [] } = event
    const defaultGallery = _.find(galleries, gallery => gallery.default === true).media || _.get(galleries[0], 'media')
    return {
      defaultGallery,
      messageContent: {
        ..._.omit(event, ['galleries']),
        type: 'event',
        images: defaultGallery.map(image => image.src)
      }
    }
  }),
  withHandlers({
    onTagClick: ({ dispatch }) => tagId => {
      dispatch(eventsActions.searchEventsFresh({ tagId }))
    },
    onEventClick: ({ dispatch, event, onEventClick, messageContent, defaultGallery }) => () => {
      if (onEventClick) {
        onEventClick(messageContent)
      } else {
        dispatch(push(`/events/${event.id}`))
      }
    },
    onEventCTAClick: ({ dispatch, onEventClick, event, messageContent, defaultGallery }) => event => {
      event.stopPropagation()
      if (onEventClick) {
        onEventClick(messageContent)
      } else {
        dispatch(createMessageActions.addContentToMessage({ content: [{ type: 'event', ...event }] }))
      }
    }
  })
)(Event)
