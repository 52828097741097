import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import FixedRatioContainer from '../../../../../components/FixedRatioContainer'
import ButtonLink from '../../../../../components/ButtonLink'
import Image from '../../../../../components/Image'

import style from './style'

export const CategoriesGridItem = props => {
  const { category, classes, onClick, component = 'div' } = props
  const { image = 'https://placeimg.com/290/280/any/sepia', name } = category

  const handleClick = e => {
    e.stopPropagation()
    onClick && onClick(e)
  }

  return (
    <Grid
      className={classes.container}
      item xs={12}
      sm={6}
      md={4}
      lg={3}
      component={component}
      onClick={handleClick}
    >
      <FixedRatioContainer ratio={1} containerClass={classes.ratioContainer}>
        <Image className={classes.image} src={image} />
      </FixedRatioContainer>
      <ButtonLink onClick={handleClick} className={classes.heading}>{name}</ButtonLink>
    </Grid>
  )
}

CategoriesGridItem.defaultProps = {
  component: 'div'
}

CategoriesGridItem.propTypes = {
  category: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /** Render outer container using different tag, e.g 'a'*/
  component: PropTypes.string
}

export default withStyles(style)(CategoriesGridItem)
