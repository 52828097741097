import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../Button'
import { translations } from '../../config'

const FinishedMessage = styled.p`
  text-align: center;
  font-size 14px;
  opacity: 0.8;
`

const LoadMoreButton = ({ hasMore, loadNext, isLoading, buttonText }) => {
  if (!hasMore) return <FinishedMessage>{translations(`Search - Load More Button - Finished`)}</FinishedMessage>

  const handleClick = (e) => {
    loadNext && loadNext(e)
  }

  return (
    <Button disabled={isLoading} onClick={(e) => handleClick(e)} color='primary'>
      {isLoading
        ? translations('Search - Load More Button - Loading')
        : buttonText || translations('Search - Load More Button - Load More')
      }
    </Button>
  )
}

LoadMoreButton.PropTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadNext: PropTypes.func.isRequired,
  buttonText: PropTypes.string
}

export default LoadMoreButton