import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import compose from 'recompose/compose'

import Carousel from '../Carousel'
import Order from './Order'
import P from '../P'

import { translations } from '../../config'

import style from './style'

const OrderCarousel = ({
  orders,
  classes,
  width,
  title,
  hideTitle = false,
  hideStatus = false,
  inCarousel = false,
  ...rest
}) => {
  const isXS = useMediaQuery(theme => theme.breakpoints.down('xs'))
  if (orders.length === 0) {
    return inCarousel
      ? <div>{translations('No orders')}</div>
      : <P className={classes.orderHeader} type='textButton' value={translations('No orders')} />

  } else if (isXS) {
    return (
      <Fragment>
        {!hideTitle ? <P className={classes.orderHeader} type='textButton' value={title} /> : null}
        <Grid container spacing={0} className={classes.container}>
          {orders.map((order, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Order {...order} width={'xs'} hideStatus={hideStatus} />
              </Grid>
            )
          })
          }
        </Grid>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <P className={classes.orderHeader} type='textButton' value={title} />
        <Carousel
          items={orders}
          renderItem={(order) => (
            <Order {...order} width={'sm'} hideStatus={hideStatus} />
          )}
          containerClassName={classes.carouselContainer}
        />
      </Fragment>
    )
  }
}

OrderCarousel.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default compose(
  withStyles(style)
)(OrderCarousel)

