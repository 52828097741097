import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'

import { selectors as productDetailsSelectors } from '../../../../../../../store/modules/productDetails'
import { showInStoreStockInVariantDropdown } from '../../../../../../../config'

import SelectVariant from './SelectVariant'

const mapStateToProps = (state) => {
  if (showInStoreStockInVariantDropdown) {
    const productDetailsVariants = productDetailsSelectors.getVariants(state)
    const productDetails = productDetailsSelectors.getProduct(state)
    return { productDetailsVariants, productDetails }
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['productDetailsVariants', 'productDetails', 'isLoading', 'id', 'variants'],
    ({ productDetailsVariants, productDetails, isLoading, id, variants, product }) => {
      // when you click add to basket, the product details are loaded, because elasticsearch
      // data doesnt contain stock info.
      // if this data has been loaded, use it to get variants options. if it hasn't, use the variants
      // we already have from elasticsearch data.
      if (
        id &&
        productDetails &&
        productDetails.id === id
      ) {
        return { options: productDetailsSelectors.getOptionsFromVariants(productDetailsVariants, false, product) }
      } else {
        return { options: productDetailsSelectors.getOptionsFromVariants(variants, true, product) }
      }
    }
  )
)(SelectVariant)
