import React from 'react'
import {
  pubSubService,
  httpClientService,
  virtualConsultationFunctions
} from '../dependencies'
import { Modules } from '@redant/retailos-ui'

import modalService from '../../services/modalService'
import './uiConfig'
import { getUiConfig } from '../uiConfigStore'

export const CustomerScreen = ({ match }) => {

  const useResourceDetails = Modules.Abstract.Implementation.provideUseResourceDetails({
    httpClientService
  })

  const useSharedState = Modules.VirtualConsultation.Implementation.provideUseSharedState({
    pubSubService,
    virtualConsultationFunctions
  },
    'customer'
  )

  const implementation = Modules.VirtualConsultation.Implementation.provideUseCustomerScreen({
    virtualConsultationFunctions,
    httpClientService,
    routeParams: match.params,
    modalService,
    hooks: {
      useSharedState,
      useResourceDetails,
    }
  }, 'customer')

  return (
    <Modules.VirtualConsultation.Screens.CustomerScreen
      implementation={implementation}
      uiConfig={{...getUiConfig('Modules.VirtualConsultation.Screens.CustomerScreen')}}
    />
  )
}