import _ from 'lodash'
import { sortWithPriority } from '../helpers'

import { allLanguages, prioritized } from './configProvider'

const languagesList = _.values(allLanguages)

const languagesListSorted = sortWithPriority(languagesList, prioritized, 'name')

const getLanguageString = ({ name, nativeName }) => `${name} (${nativeName})`

export const getLanguage = (locale) => {
  if (!locale) return null
  locale = locale.split('_').join('-') // Normalize locale codes
  const localConfig = allLanguages[locale]
  return localConfig ? getLanguageString(localConfig) : locale // Fallback to locale string
}

export const languages = _.map(
  languagesListSorted,
  (language) => ({
    value: language.locale,
    label: getLanguageString(language)
  })
)
