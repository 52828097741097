const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      margin: '15px 32px'
    }
  },
  listContainer: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }
})

export default styles
