const styles = theme => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: 15
  },
  widgetContainerCentered: {
    justifyContent: 'center'
  },
  header: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20
  }
})

export default styles
