import _ from 'lodash'

import { makeGroupDecider } from './helpers'

const _getGroupedItems = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { groupBy } = data

  if (groupBy) {
    let groupedItems = {}
    // make a map: { [name of group]: list of items, ... }
    const decideGroup = makeGroupDecider(groupBy)
    items.forEach(item => {
      const groupName = decideGroup(item, props)
      if (groupName) {
        if (groupedItems[groupName]) {
          groupedItems[groupName].push(item)
        } else {
          groupedItems[groupName] = [item]
        }
      }
    })
    // this is so you can get 0 values in count chart, for example
    decideGroup.groupNames.forEach(groupName => {
      if (!groupedItems[groupName]) {
        groupedItems[groupName] = []
      }
    })
    return groupedItems
  }
}
const getGroupedItems = _.memoize(_getGroupedItems)

export default getGroupedItems
