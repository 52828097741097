import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const TextBubble = props => {
  const { className, classes, children, noMargin, noVerticalMargin } = props
  const wrapperClassName = classNames(
    className,
    classes.bubble,
    {
      [classes.noVerticalMargin]: noVerticalMargin,
      [classes.noMargin]: noMargin,
    }
  )
  
  return (
    <div
      className={wrapperClassName}
    >
      {children}
    </div>
  )
}

export default withStyles(style)(TextBubble)
