import React, { Component, Fragment } from 'react'

import SearchBar from '../common/SearchBar'
import Results from '../common/Results'
import MultiSelectBar from '../common/MultiSelectBar'
import SubCategories from './SubCategories'
import ScrollContainer from '../../../components/ScrollContainer'

import { Helmet } from 'react-helmet'

class CategoryScreen extends Component {
  searchProducts = () => {
    const { searchProducts, category, hasChildCategories, multiSelect } = this.props
    if (!hasChildCategories) {
      searchProducts({ category })
    }
  }

  componentDidMount() {
    this.searchProducts()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.searchProducts()
    }
  }

  render() {
    const { category, hasChildCategories, multiSelect, hideSearchBar } = this.props
    return (
      category
        ? (
          hasChildCategories
            ? <SubCategories {...this.props} />
            : <Fragment>
              <h1 className='visuallyhidden'>{'Products'}</h1>
              <Helmet><title>{'Products'}</title></Helmet>
              <SearchBar {...this.props} />
              <ScrollContainer>
                <Results {...this.props} />
              </ScrollContainer>
              {multiSelect && <MultiSelectBar {...this.props} />}
            </Fragment>
        )
        // if no category don't show anything
        // (should only happen if you manually go to invalid url)
        : <div>Invalid category.</div>
    )
  }
}

export default CategoryScreen
