import * as constants from './constants'
import { constants as messageDetailConstants } from '../messageDetails'
import reducers from './reducers'

const defaultState = {
  size: 10,
  page: 1,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_MESSAGES_FOR_CUSTOMER_FRESH:
      return reducers.fetchMessagesForCustomerFresh(state, action)
    case constants.FETCH_MESSAGES_FOR_CUSTOMER_NEXT:
      return reducers.fetchMessagesForCustomerNext(state, action)
    case messageDetailConstants.FLAG_MESSAGE_INAPPROPRIATE:
      return reducers.addFlagToMessageById(state, action)
    default:
      return state
  }
}
