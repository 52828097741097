import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { formatWaitlistEntry } from '../../util/marketingPreferences'

export const getEventWaitlist = state => state.eventWaitlist.results ? _.map(state.eventWaitlist.results, entry => formatWaitlistEntry(entry)) : []

export const getIsLoading = state => state.eventWaitlist.status === PENDING

const formatCustomerWaitlistEntry = entry => {
  return {
    type: 'event',
    ...entry.event
  }
}

export const getEventWaitlistForCustomer = state => state.eventWaitlist.results ? { waitlist: _.map(state.eventWaitlist.results, entry => formatCustomerWaitlistEntry(entry)) } : {}
