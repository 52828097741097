import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H1 from '../../../components/H1'
import H2 from '../../../components/H2'
import Heading from '../../../components/Heading'
import { translations } from '../../../config'
import HorizontalGallery from '../../../components/HorizontalGallery'
import ImageBox from '../../../components/ImageBox'
import InspirationBottomTabs from './InspirationBottomTabs'

import { Helmet } from 'react-helmet'

import style from './style'
import P from '../../../components/P'
import Tags from '../../../components/Tags'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import { getFullName } from '../../../helpers'
import { matchPath } from 'react-router'
import { history } from '../../../store'


class ViewInspirationScreen extends Component {
  renderImage = () => {
    const { galleries, videoEmbeds, videoThumbs, classes, activeGallery, hasMultipleGalleries, selectedGalleryIndex } = this.props
    const filteredMedia = _.filter(activeGallery, m => !m.galleryPickerImage)
    return (
      <Grid item xs={12} sm={6} className={classes.imageItemContainer}>
        <HorizontalGallery
          images={filteredMedia}
          embeddedVideos={videoEmbeds}
          videoThumbnails={videoThumbs}
        />
      </Grid>
    )
  }

  renderGallerySelect = () => {
    const { classes, gallerySelectImageSrc, onGallerySelectClick } = this.props
    return (
      <div className={classes.gallerySelectContainer}>
        <Button buttonType={'primary'} onClick={onGallerySelectClick}>
          {translations('Set Skin Tone')}
        </Button>
      </div>
    )
  }

  renderContent = () => {
    const { classes, inspiration, hasMultipleGalleries, isMessagingAllowedForRole} = this.props
    const { title, description } = inspiration
    const path = history.location.pathname
    const isActiveConsultation = matchPath(path,{path:'/consultations/virtual/:id'}) || matchPath(path, { path: '/consultations/store/:id' })
    return (
      <Grid item xs={12} sm={6} className={classes.textItemContainer}>
        <div className={classes.inspirationContentContainer}>
          <Heading component={'h2'} variant={'h1'} className={classes.title}>{title}</Heading>
          {this.renderTags()}
          <P value={description} />
          {this.renderCreatedBy()}
        </div>
        <div className={classes.inspirationActions}>
          {hasMultipleGalleries && this.renderGallerySelect()}
          {this.renderSendMessageButton()}
          {
            isActiveConsultation !== null ?
            this.renderAddToConsultationButton()
            :
            null
          }
          
        </div>
      </Grid>
    )
  }
  renderTags = () => {
    const { inspirationType } = this.props.inspiration
    const { classes, onTagClick } = this.props
    const tags = _.get(this.props.inspiration, 'tags', [])
    if (!tags.length) {
      return null
    }
    return (
      <div className={classes.tagsContainer} >
        <Tags
          tags={tags}
          inspirationType={inspirationType}
          onTagClick={onTagClick}
          showAll
          inline
        />
      </div>
    )
  }
  renderSendMessageButton = () => {
    const { classes, onSendMessageClick, canSendMessage, isMessagingAllowedForRole} = this.props
    if (!canSendMessage || !isMessagingAllowedForRole) {
      return null
    }
    return <Button onClick={onSendMessageClick} className={classes.button} buttonType='white' >{translations('Send in message')}</Button>
  }

  renderAddToConsultationButton = () => {
      const { classes, onAddToConsultationClick, selected } = this.props
      return (
        <Button
          onClick={onAddToConsultationClick}
          className={classes.button}
          buttonType='primary'
          disabled={selected}
        >
          {translations('Add to consultation')}
        </Button>
      )
    }
  renderBottomBar = () => {
    const { onDeleteClick, userCanDeleteInspiration, selectMode } = this.props
    if (!userCanDeleteInspiration || selectMode) {
      return null
    }
    return (
      <BottomBar>
        <ButtonsContainer align='center'>
          <Button
            buttonType='primary'
            onClick={onDeleteClick}
            big
          >
            {translations('Delete')}
          </Button>
        </ButtonsContainer>
      </BottomBar>
    )
  }
  renderHeader = () => {
    const { inspiration, inspirationBelongsToUser, onBoudoirClick, classes, goBack } = this.props
    let title
    if (_.get(inspiration, 'inspirationType') === 'brand') {
      title = translations('Brand Inspiration')
    } else if (inspirationBelongsToUser) {
      title = translations('My Inspiration')
    } else {
      title = translations('User Inspiration')
    }
    return (
      <SubHeader
        leftContent={(
          <BackBar onClick={goBack} />
        )}
        centerContent={(
          <Heading uppercase component={'h1'}>{title}</Heading>
        )}
        rightContent={(
          <Button
            buttonType='primary'
            onClick={onBoudoirClick}
            className={classes.boudoirModeButton}
            big>
            {translations('Customer Mode')}
          </Button>
        )}
      />
    )
  }
  renderCreatedBy = () => {
    const { inspiration, classes } = this.props
    if (!inspiration.createdByUser) {
      return null
    }
    return (
      <P
        className={classes.createdByText}
        value={translations('Inspiration Created By', { username: getFullName(inspiration.createdByUser) })}
      />
    )
  }

  render() {
    const { classes, inspirationFailedToLoad, inspiration, tabValue = 0, handleTabChange } = this.props
    return (
      <div className={classes.container}>
        <Helmet><title>{translations('Inspiration')}</title></Helmet>
        {this.renderHeader()}
        {
          inspirationFailedToLoad
            ? (
              <div className={classes.errorContainer}>
                <P className={classes.errorText} type='textButton' value={translations('Failed Inspiration Text')} />
              </div>
            )
            : (
              <Fragment>
                <Grid container spacing={0}>
                  {this.renderImage()}
                  {this.renderContent()}
                </Grid>
                <InspirationBottomTabs data={inspiration} />
                {this.renderBottomBar()}
              </Fragment>
            )
        }
      </div>
    )
  }
}

export default withStyles(style)(ViewInspirationScreen)
