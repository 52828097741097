import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import actions from './actions'
import { selectors as authSelectors } from '../auth'

class UserMessagesMiddleware {
  searchMessagesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToMessages = action.type === LOCATION_CHANGE && matchPath(action.payload.location.pathname, {
      path: '/messages',
      exact: true
    })
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())

    if (isRoutingToMessages && isLoggedIn) {
      dispatch(actions.searchMessagesFresh({}))
    }
  }
}

export default new UserMessagesMiddleware()
