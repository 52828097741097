import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import P from '../P'
import { getImage } from '../Images'

const StockLevelIndicator = ({ classes, icon, label, sublabel }) => (
  <div className={classes.stockContainer}>
    <img src={getImage(icon)} className={classes.stockIcon} />
    <div className={classes.labelsContainer}>
      <P value={label} className={classes.label} />
      {sublabel ? <P value={sublabel} className={classes.sublabel} type='small' /> : null}
    </div>
  </div>
)

StockLevelIndicator.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string
}

export default withStyles(style)(StockLevelIndicator)
