import _ from 'lodash'

import getTargetData from './getTargetData'

const _getTarget = props => {
  const targetData = getTargetData(props) || {}
  const target = targetData.__TARGET
  return target
}
const getTarget = _.memoize(_getTarget)

export default getTarget
