import React, { PureComponent } from 'react'
import NonDailyQuestionRow from '../NonDailyQuestionRow'

class MonthlyQuestionRow extends PureComponent {
  render () {
    return (
      <NonDailyQuestionRow {...this.props} type={'monthly'} />
    )
  }
}

export default MonthlyQuestionRow
