import { connect } from 'react-redux'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import AppDetails from './AppDetails'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const user = authSelectors.getCurrentUser(state)
  const storeName = (authSelectors.getSelectedStore(state) || {}).name
  const departmentName = (authSelectors.getSelectedDepartment(state) || {}).name
  const versionNo = window.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION

  return {
    ...ownProps,
    storeName,
    user,
    versionNo,
    departmentName
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AppDetails)
