import _ from 'lodash'
import { countriesConfig } from './configProvider'
import { sortWithPriority, sortWithTopItems } from '../helpers'

export const { allCountries, prioritized } = countriesConfig

const orderedNumByNameList = sortWithPriority(allCountries, prioritized, 'name')
const orderedNumByCodeList = sortWithPriority(allCountries, prioritized, 'code')
const orderedByNameWithTopItems = sortWithTopItems(allCountries, prioritized, 'name')

export const countryPhoneCodes = _.chain(orderedNumByCodeList)
  .map(country => `${country.code} ${country.dialCode}`)
  .value()
export const countryPhoneDictionary = orderedNumByNameList.reduce((memo, next) => ({ ...memo, [next.dialCode]: next }), {})
export const countries = orderedNumByNameList.map(country => country.name)

export const countryOptionsForDropdowns = orderedByNameWithTopItems.map(group => {
  return group.map(country => ({
    label: country.name,
    value: country.name,
    code: country.code
  }))
})
