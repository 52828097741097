import Role from './Role'
import { connect } from 'react-redux'
import { formValueSelector, change, touch } from 'redux-form'

import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { selectors as roleSelectors } from '../../../../../store/modules/roles'
import { stringManipulation } from '../../../../../helpers'
import { translations } from '../../../../../config'

const selector = formValueSelector('roles-and-stores')

const mapStateToProps = (state, ownProps) => {
  const selectedRoleId = selector(state, 'roleId')

  const selectedRole = roleSelectors.getRoleById(selectedRoleId)(state)

  const formattedRoleName = selectedRole && translations(stringManipulation.formatRoleName(selectedRole.name))

  const availableRoleNamesForUserOptions = authCombinedSelectors.getAvailableRoleNameOptionsForUser(state)
  const availableRoleNamesWithoutSuperAdmin = availableRoleNamesForUserOptions && availableRoleNamesForUserOptions.filter(roleOption => roleOption.label !== 'Super admin')

  return {
    formattedRoleName,
    roleOptions: availableRoleNamesWithoutSuperAdmin
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => dispatch(change('roles-and-stores', field, value)),
  touchField: (field) => {
    dispatch(touch('roles-and-stores', field))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Role)
