import * as customer from './Customer'
import * as generic from './Generic'
import * as product from './Product'
import * as simple from './Simple'
import * as category from './Category'

const ListItems = {
  customer,
  generic,
  product,
  simple,
  category
}

export default ListItems
