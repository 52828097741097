import { connect } from 'react-redux'
import BasketCounter from './BasketCounter'
import { actions } from '../../../store/modules/app'

const mapDispatchToProps = dispatch => ({
  openBasket () {
    dispatch(actions.toggleBasket({ isOpen: true }))
  }
})

export default connect(
  null,
  mapDispatchToProps
)(BasketCounter)
