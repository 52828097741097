import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Tag from '../Tag'
import { translations } from '../../config'
import P from '../P'
import style from './style'

const Tags = ({ inspirationType, tags, onTagClick, classes, showAll = false, inline }) => {
  if (inspirationType === 'user') {
    return null
  }
  const tagsLength = tags.length
  const chosenTags = showAll ? tags : _.take(tags, 3)
  const tagsToRender = chosenTags.map(t => <Tag {...t} inline={inline} onTagClick={onTagClick} />)
  if (!showAll && tagsLength > 3) {
    const extraValue = tagsLength - 3
    tagsToRender.push(<P className={classes.extraValueText} value={translations('Extra Tags', { extraValue })} />)
  }
  return tagsToRender
}

Tags.propTypes = {
  type: PropTypes.string.isRequired,
  onTagClick: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default withStyles(style)(Tags)
