import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'

import H2 from '../../components/H2'
import BackBar from '../../components/BackBar'
import Button from '../../components/Button'
import SubHeader from '../../components/SubHeader'
import LoaderContainer from '../../components/LoaderContainer'
import ResponsiveTable from '../../components/ResponsiveTable'
import BottomBar from '../../components/BottomBar'

import media from '../../config/media'

const ResultsContainer = styled.div`
  margin: 20px auto 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  ${media.greaterThan('md')`
    padding-left: 20px;
    padding-right: 20px;
  `}
},
`

const renderActionButtons = ({ actionButtons }) => {
  return (
    <BottomBar>
      {actionButtons.map(button => {
        return <Button big buttonType='primary' onClick={button.onClick}>{button.label}</Button>
      })}
    </BottomBar>
  )
}

const renderResultsTable = ({ results, columns, onRowClick }) => {
  return (
    <div>
      <ResponsiveTable data={results} columns={columns} onRowClick={onRowClick} />
    </div>
  )
}

const ListPage = ({
  results = [],
  columns = [],
  isLoading,
  onRowClick,
  onBackClick,
  actionButtons = [],
  title
}) => {
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={!!title && <H2 value={title} />}
      />
      <ResultsContainer>
        {!!results.length && renderResultsTable({ results, columns, onRowClick })}
        <LoaderContainer isLoading={isLoading} />
      </ResultsContainer>
      {!!actionButtons.length && renderActionButtons({ actionButtons })}
    </div>
  )
}

ListPage.propTypes = {
  isLoading: PropTypes.bool,
  results: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  onBackClick: PropTypes.func,
  actionButtons: PropTypes.array,
  title: PropTypes.string
}

export default ListPage
