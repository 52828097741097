import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  total: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_CALENDARS_FRESH:
      return reducers.fetchCalendars(state, action)
    default:
      return state
  }
}
