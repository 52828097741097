const style = theme => ({
  container: {
    margin: '20px 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 60px'
    }
  }
})

export default style
