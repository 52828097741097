import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class UserMessagesActions {
  searchMessagesFresh = ({ query }) => ({
    type: constants.SEARCH_MESSAGES_FOR_USER_FRESH,
    promise: (dispatch, getState) => {
      const state = getState()
      const size = selectors.getSize(state)
      const searchQuery = query === undefined ? selectors.getQuery(state) : query
      return digitalStoreSdk
        .messages.searchMessages({
          query: searchQuery || null,
          size,
          page: 1
        })
    },
    payload: {
      query,
      page: 1
    }
  })

  searchMessagesNext = () => ({
    type: constants.SEARCH_MESSAGES_FOR_USER_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const searchQuery = selectors.getQuery(state)
      const page = selectors.getPage(state)
      const size = selectors.getSize(state)
      const nextPage = page + 1
      return digitalStoreSdk
        .messages.searchMessages({
          query: searchQuery || null,
          size,
          page: nextPage
        })
    }
  })
}

export default new UserMessagesActions()
