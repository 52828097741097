import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import marked from 'marked'
import RelatedProductsScroller from '../RelatedProductsScroller'
import { withStyles } from '@material-ui/core/styles'

import { translations, productDetails as productDetailsConfig } from '../../../../config'
import { constants as productDetailsConstants } from '../../../../store/modules/productDetails'
import TabbedContent from '../../../../components/TabbedContent'
import Container from '../../../../components/Container'

import Reviews from './Reviews'
import style from './style'

class ProductDetailBottomTabs extends Component { 
  getProductDetailsContent = () => {
    const { classes, product } = this.props
    const description = _.get(product, 'details.description')
    if (!description) return null
    const productDetailsHTML = marked(description)
    return (
      <Container inList className={classes.tabContainer}>
        <div dangerouslySetInnerHTML={{ __html: productDetailsHTML }} />
      </Container>
    )
  }

  renderReviews = () => {
    const { product } = this.props
    return <Reviews product={product} />
  }

  render () {
    const { classes, tabValue, handleTabChange } = this.props
    const tabs = []
    if (productDetailsConfig.fullProductDescription) {
      tabs.push({
        label: translations('Details'),
        content: this.getProductDetailsContent(),
        value: productDetailsConstants.PRODUCT_DETAILS_EXPANDED
      })
    }
    if (productDetailsConfig.reviews) {
      tabs.push({
        label: translations('Customer Reviews'),
        content: this.renderReviews(),
        value: productDetailsConstants.PRODUCT_CUSTOMER_REVIEWS
      })
    }
    tabs.push({
      label: translations('Related Products'),
      content: <RelatedProductsScroller />,
      value: productDetailsConstants.PRODUCT_RELATED_PRODUCTS
    })
    return (
      <div className={classes.tabsContainer}>
        <TabbedContent
          tabs={tabs}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          bigTabs
          lightDivider
        />
      </div>
    )
  }
}

ProductDetailBottomTabs.propTypes = {
  tabs: PropTypes.array,
  handleTabChange: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired
}

export default withStyles(style)(ProductDetailBottomTabs)
