import React from 'react'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'
import { Cancel as CancelIcon } from '@material-ui/icons'
import { colors } from '../../config/theme'

const chipTypeStyle = {
  success: {
    color: '#ffffff',
    backgroundColor: '#08B205'
  },
  service: {
    color: 'white',
    backgroundColor: '#1B1B1B',
    fontWeight: 'bold'
  },
  normal: {
    color: '#ffffff',
    backgroundColor: '#1B1B1B'
  },
  info: {
    backgroundColor: '#FFECD3',
    borderColor: '#E5B693'
  },
  delete: {
    color: colors.offsetAccent,
    backgroundColor: colors.primary,
    fontWeight: 'bold'
  }
}

/** Basic chip component - spreads props to [MUI Chip](https://material-ui.com/api/chip/) */
export const CustomChip = ({
  label,
  children,
  type,
  variant,
  icon,
  onClick,
  onDelete,
  clickable = true,
  disabled = false,
  size,
  style
}) => {
  const customStyle = chipTypeStyle[type]
  return <Chip
    size={size || `small`}
    label={label || children}
    icon={type === 'delete' 
      ? <CancelIcon style={{ color: colors.offsetAccent, backgroundColor: colors.primary }} />
      : icon}
    clickable={clickable}
    disabled={disabled}
    onDelete={onDelete}
    onClick={onClick}
    variant={variant}
    style={{ ...customStyle, ...style }}
  />
}

CustomChip.defaultProps = {}

CustomChip.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
}

export default CustomChip
