import { connect } from 'react-redux'
import { withHandlers, compose, withState } from 'recompose'
import _ from 'lodash'

import BasketMenu from './BasketMenu'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { actions as currentOrderActions, selectors as currentOrderSelectors, constants as currentOrderConstants } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors, constants as currentAppointmentConstants } from '../../../store/modules/currentAppointment'
import { actions as checkoutFlowActions } from '../../../store/modules/checkoutFlow'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import analyticsService from '../../../services/analyticsService'

const mapStateToProps = state => {
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  const isOpen = appSelectors.getIsBasketOpen(state)
  const canSendEmailBasket = !order.numberOfProducts || currentOrderSelectors.getCurrentOrderEditMode(state)
  const basketType = currentOrderCombinedSelectors.getBasketType(state)
  const editType = currentOrderSelectors.getEditType(state)
  const isEmpty = currentOrderSelectors.getCurrentOrderProductTotal(state) === 0
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const exchangeMode = editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
  const customerNameDetails = currentOrderSelectors.getCurrentOrderCustomerNameDetails(state)
  const groupedProducts = currentOrderCombinedSelectors.getCurrentOrderPurchasedGroupedProducts(state)
  const isConsultationMode = !!currentAppointmentSelectors.getAppointmentCustomerId(state)
  
  return {
    isOpen,
    isEmpty,
    basketType,
    editType,
    canSendEmailBasket,
    currentOrderCustomer,
    exchangeMode,
    customerNameDetails,
    groupedProducts,
    isConsultationMode,
    ..._.pick(order, [
      'editMode',
      'subTotal',
      'numberOfProducts',
      'numberOfPurchaseableProducts',
      'orderNumber',
      'storeroomOrderId',
      'customer'
    ])
  }
}

const mapDispatchToProps = dispatch => ({
  appointmentAnalyticsEvent: (startEvent) => {
    dispatch(currentAppointmentActions.appointmentAnalyticsEvent(startEvent))
  },
  onClose: () => {
    dispatch(appActions.toggleBasket({ isOpen: false }))
  },
  dispatch,
  checkout: () => {
    analyticsService.sendCustomEvent({ type: 'startCheckout' })
    dispatch(appActions.toggleBasket({ isOpen: false }))
    dispatch(checkoutFlowActions.start({ checkoutType: 'checkout' }))
  },
  consultation: () => {
    analyticsService.sendCustomEvent({ type: 'startCheckout' })
    dispatch(appActions.toggleBasket({ isOpen: false }))
    dispatch(checkoutFlowActions.start({ checkoutType: 'consultation' }))
  },
  referral: () => {
    analyticsService.sendCustomEvent({ type: 'startReferral' })
    dispatch(appActions.toggleBasket({ isOpen: false }))
    dispatch(checkoutFlowActions.start({ checkoutType: 'referral' }))
  },
  storeroomRequest: () => {
    analyticsService.sendCustomEvent({ type: 'startStoreroomRequest' })
    dispatch(appActions.toggleBasket({ isOpen: false }))
    dispatch(checkoutFlowActions.start({ checkoutType: 'storeroom' }))
  },
  stopEditingOrder: () => {
    dispatch(currentOrderActions.stopEditingOrder())
  },
  saveToProfile: () => {
    dispatch(currentOrderActions.saveToProfile())
  },
  removeCustomer: () => {
    dispatch(currentOrderActions.updateOrder({ customer: undefined }))
  }
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('tabValue', 'changeTabValue', 'basket'),
  withHandlers({
    clearOrder: ({ dispatch }) => () => {
      modalService.action({
        title: translations('Are you sure?'),
        text: translations('Do you really want to remove all products from your basket?'),
        actions: [
          {
            success: true,
            text: translations('Clear All'),
            onClick: () => {
              analyticsService.sendCustomEvent({ type: 'clearBasket' })
              dispatch(currentOrderActions.clearOrder({ doNotRemoveEditMode: true, showToast: true }))
            },
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    },
    handleTabChange: ({ changeTabValue }) => (event, value) => {
      return changeTabValue(value)
    }
  })
)(BasketMenu)
