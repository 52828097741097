import { connect } from 'react-redux'
import _ from 'lodash'

import ScanditFeedbackUI from './ScanditFeedbackUI'
import { selectors as currentOrderSelectors } from '../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { translations } from '../../config'

export default connect(
  (state) => {
    const scanStatus = currentOrderSelectors.getScanStatus(state)
    const groupedProducts = currentOrderCombinedSelectors.getCurrentOrderPurchasedGroupedProducts(state)
    let statusMessage
    if (scanStatus) {
      const inBasket = groupedProducts.find(gp => _.get(gp, 'variant.ean') === scanStatus.ean)
      statusMessage = scanStatus.type === 'error'
        ? translations('EAN No Results Title', { ean: scanStatus.ean })
        : translations('Scanned Product Added', {
          productName: scanStatus.productName,
          quantity: _.get(inBasket, 'quantity')
        })
    }
    return {
      scanStatus,
      statusMessage
    }
  }
)(ScanditFeedbackUI)
