import _ from 'lodash'

import { promiseReducer } from '../../util'
import { defaultState } from './store'


class CurrentOrderReducers {
  createCustomerBasket = promiseReducer(
    (state, action) => {
      return {
        ...state,
        basket: action.result
      }
    }
  )
  updateCustomerBasket = promiseReducer(
    (state, action) => {
      return {
        ...state,
        basket: action.result
      }
    }
  )
  fetchBasketPaymentOptions = promiseReducer(
    (state, action) => {
      return {
        ...state,
        paymentOptions: action.result
      }
    }
  )
  clearBasket = (state) => {
    return defaultState
  }
}

export default new CurrentOrderReducers()
