import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import Button from '../../../../components/Button'
import H2 from '../../../../components/H2'
import P from '../../../../components/P'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'

import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'

import { translations } from '../../../../config'

import style from './style'
import Message from '../../../../components/Message'

class LatestMessage extends PureComponent {
  renderContentBox = () => {
    const { latestMessage, classes, customer, goToMessage } = this.props
    if (customer.anonymised) {
      return <P value={translations('Latest Message Anonymised')} type='textButton' className={classes.title} />
    }
    if (latestMessage) {
      return (
        <Fragment>
          <P value={translations('Latest Message')} type='textButton' className={classes.title} />
          <Message
            truncate
            message={latestMessage}
            latest
            readMoreLink={`/customers/${customer.id}/messages`}
            goToMessage={goToMessage}
          />
        </Fragment>
      )
    }
    return <P value={translations('No messages')} type='textButton' />
  }

  render () {
    const { classes, customer, editable, onFullMessageClick } = this.props
    return (
      <ContentBox>
        <ContentBoxHeader>
          <TextContent><H2 className={classes.header} value={translations('Messages')} /></TextContent>
          <RightButtonContent>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.viewButtonGridItem} >
                {
                  editable
                    ? (
                      <Link to={`/customers/${customer.id}/messages`} className={classes.link} >
                        <Button
                          id='message-view'
                          color='primary'
                          className={classes.button}
                          onClick={onFullMessageClick}
                        >
                          {translations('View All Messages Button')}
                        </Button>
                      </Link>
                    )
                    : null
                }
                
              </Grid>
            </Grid>
          </RightButtonContent>
        </ContentBoxHeader>
        <ContentBoxBody>
          {this.renderContentBox()}
        </ContentBoxBody>
      </ContentBox>
    )
  }
}

export default withStyles(style)(LatestMessage)
