const style = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0'
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '5px 0',
    flexShrink: 1,
    minWidth: 0
  },
  right: {
    flexShrink: 0
  },
  name: {
    lineHeight: '24px',
    margin: '0 0 0 3px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    color: 'inherit',
    textDecoration: 'none'
  },
  messageIcon: {
    margin: '7px 8px'
  },
  messageIconButton: {
    width: 'auto',
    height: 'auto',
    padding: 0
  }
})

export default style
