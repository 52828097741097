import React from 'react'
import { Route } from 'react-router'
import PrivateRoute from '../../components/PrivateRoute'
import ViewCustomerSnapshotScreen from './ViewCustomerScreen/ViewCustomerSnapshotScreen'
import ViewCustomerScreen from './ViewCustomerScreen'
import ViewAllNotes from './ViewCustomerScreen/ViewAllNotes'
import CreateNote from './ViewCustomerScreen/CreateNote'
import EditNote from './ViewCustomerScreen/EditNote'
import ClientBookScreen from './ClientBookScreen'
import CustomerReportingScreens from './CustomerReporting/CustomerReportingScreens'
import FullMessageList from './ViewCustomerScreen/FullMessageList'
import ViewCustomerActivityScreen from './ViewCustomerScreen/ViewCustomerActivityScreen'
import CustomerRegistrationScreen from './CustomerRegistrationScreen'
import { CustomerSelfRegistrationScreen } from '../../retailos'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/customers' path='/customers' exact component={TimedLogoutComponent(ClientBookScreen)} />,
  <PrivateRoute key='/customers/new' path='/customers/new' exact component={TimedLogoutComponent(CustomerRegistrationScreen)} allowOffline />,
  <PrivateRoute key='/customers/:id' path='/customers/:id' exact component={TimedLogoutComponent(ViewCustomerSnapshotScreen)} />,
  <PrivateRoute key='/customers/:id' path='/customers/:id/full' exact component={TimedLogoutComponent(ViewCustomerScreen)} />,
  <PrivateRoute key='/customers/:id' path='/customers/:id/activity' exact component={TimedLogoutComponent(ViewCustomerActivityScreen)} />,
  <PrivateRoute key='/customers/:id/notes' path='/customers/:id/notes' exact component={TimedLogoutComponent(ViewAllNotes)} />,
  <PrivateRoute key='/customers/:id/notes/create' path='/customers/:id/notes/create' exact component={TimedLogoutComponent(CreateNote)} />,
  <PrivateRoute key='/customers/:id/notes/:noteId/edit' path='/customers/:id/notes/:noteId/edit' exact component={TimedLogoutComponent(EditNote)} />,
  <PrivateRoute key='/customers/:id/messages' path='/customers/:id/messages' exact component={TimedLogoutComponent(FullMessageList)} />,
  ...CustomerReportingScreens,
  // Customer (Public) Registration Screen
  <Route key='/register' path='/register' exact component={CustomerSelfRegistrationScreen} />
]
