const style = {
  image: {
    width: '100%',
    paddingTop: '100%',
    marginBottom: 20
  },
  buttonContainer: {
    paddingTop: 10,
    width: '100%',
    marginTop: 'auto'
  },
  button: {
    width: '100%'
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}

export default style
