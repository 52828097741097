import React from 'react'
import PropTypes from 'prop-types'

import ThreadItem from '../../../../components/ThreadItem'
import NoteFrom from './NoteFrom'

const Note = ({ note, truncate, currentUser,
  latest = false, customer, openDeleteNoteModal, pushToEdit }) => {
  const flip = currentUser ? !currentUser : true
  const showButtons = !flip && !latest
  return (
    <ThreadItem
      latest={latest}
      threadItemContent={note.note}
      link={`/customers/${customer.id}/notes`}
      truncate={truncate}
      itemId={note.id}
      flip={flip}
      pushToEdit={pushToEdit}
      openDeleteModal={openDeleteNoteModal}
      renderFrom={() => <NoteFrom note={note} />}
      showButtons={showButtons}
      buttons={[{ name: 'Edit', onClick: pushToEdit }, { name: 'Delete', onClick: openDeleteNoteModal }]}
    />
  )
}

Note.propTypes = {
  note: PropTypes.shape({}).isRequired,
  customer: PropTypes.shape({}).isRequired,
  truncate: PropTypes.bool,
  currentUser: PropTypes.bool.isRequired,
  latest: PropTypes.bool,
  openDeleteNoteModal: PropTypes.func.isRequired,
  pushToEdit: PropTypes.func.isRequired
}

export default Note
