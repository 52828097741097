import React, { PureComponent } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Dropdown from '../../../../../../../components/Fields/Dropdown'
import BasicModalOverlay from '../../../../../../../components/BasicModalOverlay'

import style from './style'

class SelectVariant extends PureComponent {
  state = {
    value: null
  }
  onChange = e => {
    const value = e.target.value
    this.setState({ value })
    this.props.onChange(value)
  }
  getActions = _.memoize((disabled) => {
    const { actions } = this.props
    if (disabled) {
      return _.chain(actions)
        .map(action => {
          if (action.success) {
            return {
              ...action,
              disabled: true
            }
          } else {
            return action
          }
        })
        .value()
    } else {
      return actions
    }
  })
  render () {
    const { options, classes, actions, ...rest } = this.props
    const value = (
      options.length === 1
      ? (this.state.value || _.get(options, '0.value'))
      : this.state.value
    )
    return (
      <BasicModalOverlay
        {...rest}
        actions={this.getActions(!value)}
      >
        <Dropdown
          givenClasses={{ overridingRootClasses: classes.dropDownStyle }}
          options={options}
          value={value}
          onChange={this.onChange}
          noErrorTextLabel
        />
      </BasicModalOverlay>
    )
  }
}

export default withStyles(style)(SelectVariant)
