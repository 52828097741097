export default theme => ({
  container: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    paddingTop: 0
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  leftContent: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
    position: 'relative',
    paddingRight: 20
  },
  rightContent: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,

    justifyContent: 'center',
    position: 'relative'
  },
  carouselContainer: {
    padding: '20px'
  }
})
