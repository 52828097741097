import moment from 'moment'

import { dateFormat } from '../../../config'

import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  results: [],
  startDate: moment().subtract(1, 'week').format(dateFormat),
  endDate: moment().format(dateFormat),
  orderTypes: [],
  orderStatus: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_ORDERS:
      return reducers.searchOrders(state, action)
    case constants.SEARCH_ORDERS_NEXT:
      return reducers.searchOrdersNext(state, action)
    case constants.FETCH_ORDER_TYPES:
      return reducers.fetchOrderTypes(state, action)
    default:
      return state
  }
}
