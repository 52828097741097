import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ButtonLink from '../ButtonLink'
import style from './style'

const Tag = ({ id, title, classes, onClick, inline }) => {
  return (
    <ButtonLink onClick={(e) => onClick(e, id)} className={inline ? classes.inlineText : classes.text} type='tag'>{title}</ButtonLink>
  )
}

Tag.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    inlineText: PropTypes.string,
    text: PropTypes.string
  }),
  onClick: PropTypes.func,
  inline: PropTypes.bool
}

export default withStyles(style)(Tag)
