import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import Message from '../../../components/Message'
import { translations } from '../../../config'

import style from './style'

const MessageDetailsScreen = ({ message, classes, onFlagClicked, onBackClick }) => {
  return (
    <Fragment>
      <SubHeader
        leftContent={<BackBar onClick={onBackClick} />}
        centerContent={(
          <H2 value={_.toUpper(translations('Message Details Header'))} />
        )}
      />
      <div className={classes.container}>
        <Message
          message={message}
          fromCustomer={message.fromCustomer}
          onFlagClicked={onFlagClicked}
          showButtons
        />
      </div>
    </Fragment>
  )
}

MessageDetailsScreen.propTypes = {
  message: PropTypes.shape({}).isRequired,
  onFlagClicked: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired
}

export default withStyles(style)(MessageDetailsScreen)
