import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import * as constants from './constants'

class VariantsStockReducers {
  fetchVariantStoreStocks = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          stock: action.result,
          status: SUCCESS,
          action: undefined,
          error: undefined
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error.message
        }
      default:
        return state
    }
  }
}

export default new VariantsStockReducers()
