import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import P from '../../../../../components/P'
import { timeFormat, dateFormat } from '../../../../../config'
import { stringManipulation, formatDate } from '../../../../../helpers'

import styles from './style'
import H3 from '../../../../../components/H3'
import { getImage } from '../../../../../components/Images'

const staffBadgeIcon = getImage('staffBadgeIcon')

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

const NoteFrom = ({ classes, note }) => {
  const formattedRoleName = stringManipulation.formatRoleName(note.user.roleName)
  const storeString = `(${formattedRoleName} - ${note.user.storeName})`

  return (
    <Fragment>
      <div className={cx(classes.iconContainer, classes.paragraphSpacing)}>
        {<img className={classes.imageSpacing} src={staffBadgeIcon} /> }
        <H3 value={note.user.name} />
      </div>
      <P value={storeString} className={classes.paragraphSpacing} />
      <P value={formatDate(note.timestamp, dateAndTimeFormat)} />
    </Fragment>
  )
}

NoteFrom.propTypes = {
  classes: PropTypes.shape({}),
  note: PropTypes.shape({})
}

export default withStyles(styles)(NoteFrom)
