import React from 'react'
import { withHandlers, compose } from 'recompose'
import ChangeCustomer from '../../../../Customer/SelectCustomerView'
import { connect } from 'react-redux'
import { selectors as followingSelectors } from '../../../../../store/modules/following'
import { actions as orderDetailsActions } from '../../../../../store/modules/orderDetails'
import modalService from '../../../../../services/modalService'
import CustomerRegistration from '../../../../Customer/CustomerRegistrationScreen'

const mapStateToProps = state => {
  return {
    following: followingSelectors.getFollowing(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onAnonymousCustomerClick: ({ dispatch, next, orderId: id, userId }) => ({ anonymousReason }) => {
      dispatch(orderDetailsActions.reassignCustomer({ id, userId, anonymousReason }))
      modalService.close()
    },
    registerCustomer: ({ dispatch, checkoutType, orderId: id, userId }) => () => {
      modalService.open({
        modalIndex: 1,
        fullScreen: true,
        component: CustomerRegistration,
        disallowCustomerMode: true,
        submitAction: (params) => {
          modalService.close({ modalIndex: 1 })
          return orderDetailsActions.createCustomer(params, id, userId)
        },
        onBackClick: () => modalService.close({ modalIndex: 1 })
      })
    }
  })
)(ChangeCustomer)
