import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../../config'
import Container from '../../../../../components/Container'
import List from '../../../../../components/List'
import P from '../../../../../components/P'

import Review from './Review'
import style from './style'

const Reviews = props => {
  const { product } = props

  const reviews = _.chain(product)
    .get('reporting.reviews', [])
    .uniqBy('text')
    .orderBy('published', 'desc')
    .value()

  if (reviews && reviews.length) {
    return (
      <List
        items={reviews}
        ItemComponent={Review}
        noMargin
      />
    )
  } else {
    return <Container inList>
      <P value={translations('No reviews')} />
    </Container>
  }
}

export default withStyles(style)(Reviews)
