import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING, FAIL, SUCCESS } from '../../middleware/redux-promise'

import { SEARCH_POSTCODE } from './constants'
import { selectors } from '../customerDetails'

export const getStatus = state => state.customers.searchPostcodeStatus
export const getAction = state => state.customers.action
export const getAddressList = state => state.customers.addressList
export const getCustomerListTotal = state => state.customers.customerList.total
export const getCustomerListQuery = state => state.customers.customerList.query
export const getCustomerListPage = state => state.customers.customerList.page
export const getCustomerListSize = state => state.customers.customerList.size
export const getCustomerListStatus = state => state.customers.customerList.status
export const getCustomerListAction = state => state.customers.customerList.action
export const getCustomerListHasMore = state => state.customers.customerList.total != null &&
state.customers.customerList.results.length < state.customers.customerList.total
export const getCustomerHasBeenSearched = state => state.customers.customerList.query &&
state.customers.customerList.status === SUCCESS
export const getCustomerListError = state => state.customers.customerList.error
export const getCustomerListResults = createSelector([
  state => state.customers.customerList.results || []
], results => {
  return results.map(selectors.addMarketingValuesToCustomer)
})
export const getCustomerListIsLoading = state => state.customers.customerList.status === PENDING
export const getInvalidPostcodeError = state => _.chain(state).get('customers.searchPostcodeError.message').value()
export const getIsSearchingPostcode = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === SEARCH_POSTCODE)
))
export const getSearchPostcodeError = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === FAIL && action === SEARCH_POSTCODE)
))
