import _ from 'lodash'
import { getFullName, formatDate } from '../../../../../helpers'
import { getCustomerFullName } from '../../../../../helpers/getFullName'
import currencyFormatter from '../../../../../formatters/currencyFormatter'
import { translations, customAdditionalOrderListingScreenFields, customOrderListingScreenFieldHeaders } from '../../../../../config'

export const getColumns = () => {
  if (customOrderListingScreenFieldHeaders){
    return customOrderListingScreenFieldHeaders.map(field => {
      return {
        key: field.key,
        title: translations(field.titleTranslationKey),
        cellWidth: field.cellWidth
      }
    })
  } else {
    return [
      { key: 'date', title: translations('Orders Table Header Date'), cellWidth: 1 },
      { key: 'orderNumber', title: translations('Orders Table Header Order Number'), cellWidth: 1, clickHandler: true },
      { key: 'items', title: translations('Orders Table Header Items'), cellWidth: 1 },
      { key: 'total', title: translations('Orders Table Header Total'), cellWidth: 1 },
      { key: 'customer', title: translations('Orders Table Header Customer'), cellWidth: 2 },
      { key: 'user', title: translations('Orders Table Header User'), cellWidth: 2 },
      { key: 'store', title: translations('Orders Table Header Store'), cellWidth: 2 },
      { key: 'status', title: translations('Orders Table Header Status'), cellWidth: 2 }
    ]
  }  
}

export const getFormattedOrderRow = (order) => {
  const customListingScreenFieldsObject = (customAdditionalOrderListingScreenFields || []).reduce((acc, field) => {
    acc[field.key] = _.get(order, field.propertyOnOrder);
    return acc;
  }, {});

  const status = _.get(order, 'status')
  const userFullName = getFullName(order.user)

  return {
    date: formatDate(order.orderDate),
    orderNumber: order.orderNumber,
    items: _.get(order, 'products.length'),
    total: currencyFormatter.format(order.total),
    customer: userFullName ? getCustomerFullName(order.customer) : translations('Unassigned'),
    user: userFullName || translations('Unassigned'),
    store: _.get(order, 'store.name'),
    status: translations(`Order Status - ${status}`),
    ...customListingScreenFieldsObject
  }
}
