const styles = theme => ({
  h1: {
    color: theme.palette.text.main,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h1.fontSize,
    fontWeight: theme.h1.fontWeight
  }
})

export default theme => styles
