import _ from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment'

import { FETCH_APPOINTMENTS_FRESH } from './constants'

import { getStoreCalendarOptions } from '../storeDetails/selectors'

export const getIsLoading = state => state.appointments.isLoading || state.appointments.status === 'PENDING'
export const getIsLoadingFresh = state => state.appointments.action === FETCH_APPOINTMENTS_FRESH && state.appointments.status === 'PENDING'
export const getAppointmentsState = state => _.get(state, 'appointments')
export const getAppointmentsFilters = state => _.get(getAppointmentsState(state), 'filters')
export const getAppointments = state => _.get(getAppointmentsState(state), 'results')
// export const getAppointmentById = (id) => (state) => _.get(getAppointmentsState(state), `results.${id}`)
export const getAppointmentById = (id) => (state) => {
  if (!id) {
    return null
  }
  // Make this work for getting by external IDs as well
  const result = _.chain(getAppointmentsState(state))
    .get('results')
    .values()
    .find(appointment => {
      return id === appointment.externalAppointmentId || id === appointment.id
    })
    .value()
  return result
}

// export const getIsAppointmentExpired = (id) => (state) => _.get(getAppointmentsState(state), `results.${id}.status`) === 'EXPIRED'
export const getIsAppointmentExpired = (id) => (state) => _.get(getAppointmentById(id)(state), 'status')
  ? _.get(getAppointmentById(id)(state), 'status') === 'EXPIRED'
  : null
export const getIsVirtualConsultation = (id) => (state) => _.get(getAppointmentById(id)(state), 'status')
  ? _.get(getAppointmentById(id)(state), 'type') === 'VIRTUAL_CONSULTATION'
  : null
export const getAppointmentByExternalId = (id) => (state) => _.find(getAppointments(state), { externalAppointmentId: id })
export const getCurrentAppointmentId = (state) => _.first(_.keys(_.get(getAppointmentsState(state), `results`, {})))
export const getTotal = (state) => _.get(getAppointmentsState(state), 'total')
export const getLimit = (state) => _.get(getAppointmentsState(state), 'limit')
export const getHasMore = createSelector([
  getAppointments,
  getTotal
], (appointments, total) => {
  return total != null && appointments.length < total
})

export const getAppointmentsQuery = createSelector(
  [getAppointmentsFilters, getStoreCalendarOptions],
  (filters, storeCalendarOpts) => {
    const query = {}
    const allCalendarIds = storeCalendarOpts.map(opt => opt.value)
    if (filters.isVirtual && !filters.isInStore) Object.assign(query, { type: 'VIRTUAL_CONSULTATION' })
    if (filters.isInStore && !filters.isVirtual) Object.assign(query, { type: 'CONSULTATION' })
    if (filters.calendarId) {
      Object.assign(query, { calendarId: filters.calendarId })
    } else {
      Object.assign(query, { calendarId: { $in: allCalendarIds.join(',') } })
    }
    if (filters.userId) Object.assign(query, { userId: filters.userId })
    if (filters.startDateTime) {
      const startOfday = moment(filters.startDateTime, 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss')
      const endOfDay = moment(filters.startDateTime, 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD[T]HH:mm:ss')
      Object.assign(query, { startDateTime: { $gte: startOfday, $lte: endOfDay } })
    }
    return query
  }
)
