import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import RelatedProductsScroller from './RelatedProductsScroller'
import * as productDetailSelectors from '../../../../store/modules/productDetails/selectors'

const mapStateToProps = state => ({
    relatedProducts: productDetailSelectors.getRelatedProducts(state)
})

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['relatedProducts'], ({relatedProducts}) => ({
    hasRelatedProducts: relatedProducts.length >= 1
  }))
)(RelatedProductsScroller)
