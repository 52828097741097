import { LOCATION_CHANGE, goBack } from 'connected-react-router'
import { matchPath } from 'react-router'
import customerDetailActions from './actions'
import * as customerDetailsSelectors from './selectors'
import currentOrderActions from '../currentOrder/actions'
import * as customerDetailConstants from './constants'
import * as authSelectors from '../auth/selectors'
import { constants as currentAppointmentConstants, selectors as currentAppointmentSelectors }  from '../currentAppointment'

import { translations } from '../../../config'
import analyticsService from '../../../services/analyticsService'
import toastService from '../../../services/toastService/toastService'
import { SUCCESS } from '../../middleware/redux-promise'
import usersActions from '../users/actions'

const getFullName = customerResult => `${customerResult.firstName} ${customerResult.lastName}`

const matchers = [
  '/customers/:id',
  '/customers/:id/notes',
  '/customers/:id/messages',
  '/customers/:id/full',
  '/customers/:id/activity'
]
const shouldLoadCustomer = (pathname) => {
  for (let path of matchers) {
    const res = matchPath(pathname, { path, exact: true })
    if (res && res.params.id !== 'new') {
      return res.params.id
    }
  }
}

class CustomerDetailsMiddleware {
  loadCustomerMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (!isLoggedIn) {
        return
      }

      const customerId = shouldLoadCustomer(action.payload.location.pathname)
      if (customerId) {
        dispatch(customerDetailActions.fetchFullCustomer(customerId))
      }
    }
  }

  updateCustomerSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === customerDetailConstants.UPDATE_CUSTOMER && action.status === SUCCESS) {
      const fullName = getFullName(action.result)
      toastService.action({
        type: 'success',
        message: translations('Customer Updated'),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
  }

  updateCustomerWishlistMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === customerDetailConstants.UPDATE_CUSTOMER_WISHLIST && action.status === SUCCESS) {
      const fullName = getFullName(action.result)
      toastService.action({
        type: 'success',
        message: translations('Item added wishlist text', { customer: fullName }),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
    next(action)
  }
  updateCustomerNotesMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === customerDetailConstants.UPDATE_CUSTOMER_NOTES && action.status === SUCCESS) {
      const fullName = getFullName(action.result)
      // go back to notes list
      // only way to get into create for now is through the notes list component
      dispatch(goBack())
      toastService.action({
        type: 'success',
        message: translations('Note Created Text', { customer: fullName }),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }
    next(action)
  }

  editCustomerNoteMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === customerDetailConstants.EDIT_CUSTOMER_NOTE && action.status === SUCCESS) {
      const fullName = getFullName(action.result)

      dispatch(goBack())
      toastService.action({
        type: 'success',
        message: translations('Note Edited Text', { customer: fullName }),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
      
    }
    next(action)
  }

  followCustomerMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === customerDetailConstants.FOLLOW_CUSTOMER && action.status === SUCCESS) {
      analyticsService.sendCustomEvent({ type: 'followCustomerAccount' })
    }
    next(action)
  }

  unfollowCustomerMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === customerDetailConstants.UNFOLLOW_CUSTOMER && action.status === SUCCESS) {
      analyticsService.sendCustomEvent({ type: 'unfollowCustomerAccount' })
    }
    next(action)
  }

  loadUsersOnNavigateToFullCustomerProfile = ({ dispatch }) => next => (action) => {
    next(action)
    const isRoutingToFullCustomerProfile = action.type === LOCATION_CHANGE && matchPath(action.payload.location.pathname, {
      path: '/customers/:id/full',
      exact: true
    })

    if (isRoutingToFullCustomerProfile) {
      dispatch(usersActions.searchUsersFresh({ includeDeactivated: false }))
    }
  }

  checkAnonomiseCustomerHasCurrentOrder = ({ dispatch, getState }) => next => (action) => {
    if (action.status === 'SUCCESS' && action.type === customerDetailConstants.ANONYMISE_CUSTOMER) {
      if (_.get(action, 'result.id') === _.get(getState(), 'currentOrder.customer.id')) {
        dispatch(currentOrderActions.anonymousCustomer({ customerWasAnonymised: true }))
      }
    }
    next(action)
  }

  appointmentContentAddToWishlistMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === currentAppointmentConstants.TOGGLE_CONTENT_IS_LOVED) {
      const state = getState()
      const customerId = currentAppointmentSelectors.getAppointmentCustomerId(state)
      if (customerId) {
        const isLoved = _.get(action, 'content.details.isLoved')
        const item = _.get(action, 'content.details')
        const itemWithType = {...item, type: _.get(action, 'content.type')}
        const currentWishlist = customerDetailsSelectors.getCustomerWishlist(state)
        const isAlreadyLoved = !!_.find(currentWishlist, (wishlistItem) => _.get(wishlistItem, 'id') === item.id)
        if (!isLoved) {
          // add to wishlist
          if (!isAlreadyLoved) {
            const newWishlist = [...currentWishlist, itemWithType ]
            dispatch(customerDetailActions.updateCustomerWishlist({ id: customerId, details: {wishlist: newWishlist } }))
          }
        } else {
          // remove from wishlist
          if (isAlreadyLoved) {
            const newWishlist = _.filter(currentWishlist, (wishlistItem) => wishlistItem.id !== item.id)
            dispatch(customerDetailActions.updateCustomerWishlist({ id: customerId, details: {wishlist: newWishlist } }))
          }
        }
      }
      
    }
  }
}

export default new CustomerDetailsMiddleware()
