import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Form from '../../components/Form'
import DonutChart from '../../components/Charts/DonutChart'
import ListChart from '../../components/Charts/ListChart'
import style from './style'

const ExampleForm = Form('Example123')

const ExampleFormComponent = ({ classes }) => {
  return (
    <div>
      <ExampleForm noValidate className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className={classes.chartContainer}>
              <ListChart
                data={[
                  { id: '1234', firstName: 'Billy', lastName: 'Bob', reporting: { totalSpend: { code: 'GBP', value: 1000 } } },
                  { id: '1235', firstName: 'Kelly', lastName: 'Smith', reporting: { totalSpend: { code: 'GBP', value: 2000 } } },
                  { id: '1236', firstName: 'Lucy', lastName: 'Mug', reporting: { totalSpend: { code: 'GBP', value: 3000 } } },
                  { id: '1237', firstName: 'Joe', lastName: 'Schmuck', reporting: { totalSpend: { code: 'GBP', value: 4000 } } }
                ]}
                chartTitle={'Sales Target LeaderBoard'}
                currency={'GBP'}
                limit={3}
                dataType={'LEADERBOARD'}
                isLeaderBoard
                seeAllLink={'/'}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.chartContainerOuter}>
              <div className={classes.chartContainerInner}>
                <DonutChart
                  data={[{ name: 'Devotees', value: 120, color: 'green' }, { name: 'Elites', value: 348, color: 'orange' }, { name: 'Casuals', value: 658, color: 'blue' }]}
                  chartTitle={'Orders by loyalty type'}
                  pie
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </ExampleForm>
    </div>
  )
}

ExampleFormComponent.propTypes = {
  classes: PropTypes.shape({}).isRequired
}

export default withStyles(style)(ExampleFormComponent)
