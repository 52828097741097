
import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { selectors as productsSelectors, actions as productsActions } from '../../../../store/modules/products'
import { useIsLoading } from '../../../../hooks/productsHooks'
import Chip from '../../../../components/Chip'

const ChipsContainer = styled.div`
  text-align: center;
  padding: 0 10px;
  > div { margin-top: 10px; }
  > div + div { margin-left: 5px; }
`

const SelectedFilters = () => {
  const dispatch = useDispatch()
  const activeFilters = useSelector(productsSelectors.getActiveFilters)
  const category = useSelector(productsSelectors.getCategory)
  const isLoading = useIsLoading()

  const activeFiltersArray = _(activeFilters)
    .flatMap()
    .value()

  const handleDeleteFilter = useCallback((value) => {
    const newActiveFilters = activeFilters
    _.forEach(newActiveFilters, (filterGroup) => {
      _.remove(filterGroup, (_value) => _value === value)
      return filterGroup
    })
    if (newActiveFilters && typeof newActiveFilters === 'object') {
      Object.keys(newActiveFilters).forEach((filterKey) => {
        const value = newActiveFilters[filterKey]
        if (value && value instanceof Array && value.length === 0) {
          delete newActiveFilters[filterKey]
        }
      })
    }
    dispatch(productsActions.searchProductsFresh({ filters: newActiveFilters, category }))
  })

  const handleClearAll = useCallback(() => {
    dispatch(productsActions.searchProductsFresh({ filters: {} }))
  })
  
  return (<ChipsContainer isLoading={isLoading}>
    {activeFiltersArray.length > 0 && (
      <>
        {_.map(activeFiltersArray, (value) => {
          return <Chip
            key={value}
            type={'delete'}
            disabled={isLoading}
            onClick={() => handleDeleteFilter(value)}>
            {value}
          </Chip>
        })}
      </>
    )}
  </ChipsContainer>)
}

export default SelectedFilters
