import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import _ from 'lodash'
import activityTypeSelector from '../ActivityContainers/activityTypeSelector'

const ActivityList = ({ activities, render }) => {
  return (
    <React.Fragment>
      {_.chain(activities)
        .sort((a, b) => {
          const aDate = new Date(a.meta.timestamp)
          const bDate = new Date(b.meta.timestamp)
          return bDate - aDate
        })
        .map(activity => {
          const ActivityContainer = activityTypeSelector(activity.activityType)
          return (
            <React.Fragment key={activity.id}>
              {render({ activity, ActivityContainer })}
            </React.Fragment>
          )
        })
        .value()}
    </React.Fragment>
  )
}

ActivityList.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  render: PropTypes.func,
  classes: PropTypes.shape({})
}

export default withStyles(style)(ActivityList)
