import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import style from './style'

class InspirationSearchInput extends Component {
  state = {
    query: this.props.query
  }

  searchUsers = _.debounce(({ query }) => {
    const { searchInspirations } = this.props
    searchInspirations({ query })
  }, 300)

  updateQuery = (e) => {
    const query = e.target.value
    this.setState({ query })
    this.searchUsers({ query })
  }

  render () {
    const { type, classes } = this.props

    if (type === 'user') {
      return null
    }
    return (
      <div className={classes.container}>
        <SearchInput
          value={this.state.query}
          placeholder={translations('Search inspirations')}
          onChange={this.updateQuery}
          autoFocus={false}
        />
      </div>
    )
  }
}

export default withStyles(style)(InspirationSearchInput)
