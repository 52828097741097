import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import fp from 'lodash/fp'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import { actions as customerDetailsActions } from '../../../../store/modules/customerDetails'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as appSelectors } from '../../../../store/modules/app'
import { getRegionConfig } from '../../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import { fieldsForCustomerDetails, isMarketingStoresSelected } from '../../../../store/util/marketingPreferences'

const mapStateToProps = state => ({
  customerModeUpdate: appSelectors.getCustomerModeStatus(state),
  storeId: authSelectors.getUserSelectedStoreId(state),
  userId: authSelectors.getActiveUserId(state),
  configName: getRegionConfig(state)
})

const addressKeys = ['postCode', 'address1', 'address2', 'city', 'county', 'country']

const enhance = withHandlers({
  onSubmit: props => data => {
    const {
      dispatch,
      submitAction = customerDetailsActions.createCustomer,
      storeId,
      userId,
      afterSubmit
    } = props
    const { postCode, marketingStoreIds, preferredLanguage, details } = data
    /**
     * Fields to be removed from the customer object.
     * In some cases, they are appended to the customer details object.
     */
    const omittedFields = [
      ...addressKeys,
      ...fieldsForCustomerDetails,
      'terms',
      'storeIds',
      'addressSearch',
      'marketingStoreIds',
      'noPush',
      'silent',
      'preferredLanguage'
    ]
    const params = _.omit(data, omittedFields)
    if (postCode) {
      const address = _.pick(data, addressKeys)
      params.address = address
    }
    params.details = fp.assign(
      fp.pick(fieldsForCustomerDetails, data),
      details
    )
    if (preferredLanguage) {
      params.details.preferredLanguage = preferredLanguage
    }
    // do this check incase stores were selected but the store marketing was unchecked
    if (fp.size(marketingStoreIds) && isMarketingStoresSelected(data)) {
      params.marketingStoreIds = marketingStoreIds
    }
    params.registeredById = userId
    params.registeredAtId = storeId
    params.registrationSource = 'assisted'
    return dispatch(submitAction(params))
      .then((result) => {
        if (_.isFunction(afterSubmit)) {
          afterSubmit(result)
        }
      })
  }
})

export default compose(
  connect(mapStateToProps),
  enhance
)(CustomerRegistrationForm)
