const styles = theme => ({
  floatingLabelFocusStyle: { color: theme.palette.text.main },
  underlineFocusStyle: { borderColor: theme.palette.text.main },
  errorStyle: { color: theme.palette.error.main },
  errorText: { color: theme.palette.error.main },
  borderFocussed: { borderColor: theme.palette.lightGrey.main },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.text.main
    }
  }
})

export default styles
