import React from 'react'
import DateTime from './DateTime'

class DateTimeContainer extends React.Component {
  handleChange = (value, type) => {
    const currentData = this.formatValue(this.props.value)
    const { date, time } = { ...currentData, [type]: value }
    return `${date} - ${time}`
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  formatValue = (value) => {
    if (value) {
      const [date, time] = value.replace(/ /g, '').split('-')
      return { date, time }
    } else {
      return { date: '', time: '' }
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <DateTime {...props} />
  }
}

export default DateTimeContainer
