import React from 'react'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { SelectProduct } from '../../../../components/EditableFields'
import { translations, customerProfileContentOptions } from '../../../../config'
import InspirationsSearchScreen from '../../../Inspirations/InspirationsSearchScreen'

const CustomerWaitlist = FormContentBox('waitlist')
const editButtonText = CustomerWaitlist ? translations('Add/Edit') : translations('Edit')

const WrappedCustomerWaitlist = Editable(({ initialValues, editing, toggleEdit, isSubmitting, boxName, onSubmit, formId, saveDisabled, editable }) => {
  return (
    <CustomerWaitlist
      enableReinitialize
      initialValues={initialValues}
      editing={editing}
      editable={editable}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
      saveDisabled={saveDisabled}
      editButtonText={editButtonText}
    >
      <FormError />
      <FormBody>
        <SelectProduct
          usePassedValues
          options={['product']}
          label='Waitlist'
          editing={editing}
          name='waitlist'
          inspirationsModalComponent={InspirationsSearchScreen}
          maxContent={100}
        />
      </FormBody>
    </CustomerWaitlist>
  )
})

export default WrappedCustomerWaitlist
