import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'

const getFullName = customerResult => `${customerResult.firstName} ${customerResult.lastName}`

export default ({ id, firstName, lastName, following, customerUserRelationshipId, dispatch }) => {
  const fullName = getFullName({ firstName, lastName })

  if (following) {
    modalService.action({
      title: translations('Unfollow Modal Title'),
      text: translations('Unfollow Modal Text', { customer: fullName }),
      actions: [
        {
          success: true,
          text: translations('Confirm'),
          onClick: () => dispatch(customerDetailsActions.unfollowCustomer({ id: customerUserRelationshipId })),
          primary: true
        },
        {
          text: translations('Cancel')
        }
      ]
    })
  } else {
    modalService.action({
      title: translations('Follow Modal Title'),
      text: translations('Follow Modal Text', { customer: fullName }),
      actions: [
        {
          success: true,
          text: translations('Confirm'),
          onClick: () => dispatch(customerDetailsActions.followCustomer({ id })),
          primary: true
        },
        {
          text: translations('Cancel')
        }
      ]
    })
  }
}
