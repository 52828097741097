import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import ArrowDropDownIcon from '@material-ui/core/internal/svg-icons/ArrowDropDown'

import { translations } from '../../../../config'
import Option from '../Option'
import Label from '../Label'
import ErrorText from '../ErrorText'
import Downshift from '../Downshift'
import VirtualizedMenuList from '../VirtualizedMenuList'
import CustomPaper, { paperHeight } from '../Paper'
import style from './style'

const getSuggestions = ({ inputValue, options: optionGroups }) => {
  let count = 0
  const suggestions = []

  const maxCount = 1000000

  // find up to 5 options with labels that contain inputValue
  for (let i = 0; i < optionGroups.length; i++) {
    // iterate through groups of options
    const options = optionGroups[i]
    const suggestionsForGroup = []

    for (let j = 0; j < options.length && count <= maxCount; j++) {
      // iterate through each option in a group
      const option = options[j]

      // if option has a value, and the label matches the input,
      // OR the input is blank - push it
      if (
        option.value && option.label &&
        (
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
          !inputValue
        )
      ) {
        // iterate count so that max 5 suggestions are returned
        count++
        suggestionsForGroup.push(option)
      }
    }

    // if the group contains any matching items, push the matching suggestions
    // for that group to the array of arrays we will return
    if (suggestionsForGroup.length) {
      suggestions.push(suggestionsForGroup)
    }
  }

  return suggestions
}

export class AutocompleteDropdown extends Component {

  getSelectedItemsFromInputValues = values => {
    const { options } = this.props
    const flatOptions = _.flatten(options)
    if (values) {
      return Array.isArray(values)
        ? _.map(values, (value) => flatOptions.find((option) => option.value === value))
        : flatOptions.find((option) => option.value === values)
    }
    return null
  }

  getInitialSelectedItem = () => {
    const { value, createable } = this.props
    if (createable) {
      if (!value) {
        return null
      }
      return { label: value, value }
    }
    const selectedItem = (
      value
        ? (
          value.value
            ? value
            : this.getSelectedItemsFromInputValues(value)
        )
        : null
    )
    return selectedItem
  }

  handleInput = (e) => {
    const { onInputChange } = this.props
    const inputValue = e.target.value
    this.setState({ inputValue })

    if (onInputChange) onInputChange(inputValue)
  }

  onChange = (item = {}) => {
    const { onChange, formName, value: currentValue, multiple } = this.props
    if (onChange) {
      const newValue = currentValue || []
      if (Array.isArray(currentValue) && multiple) {
        const index = newValue.indexOf(item.value)
        index > -1 ? newValue.splice(index, 1) : newValue.push(item.value)
        onChange(
          // this is to cater for redux-form.
          // if there is props.formName, then we are inside a redux form
          // and redux-form does its own checks for if the onChange arg
          // is an Event :/
          formName
            ? newValue
            : { target: { value: item.value } }
        )
      } else {
        onChange(
          // this is to cater for redux-form.
          // if there is props.formName, then we are inside a redux form
          // and redux-form does its own checks for if the onChange arg
          // is an Event :/
          formName
            ? item.value
            : { target: { value: item.value } }
        )
      }

      this.setState({ selectedItem: this.getSelectedItemsFromInputValues(newValue) })
    }
  }

  itemToString = (item) => {
    if (Array.isArray(item)) {
      return _.map(item, 'label')
    } else {
      const { value, label } = item || {}
      if (value) return label
    }
    return ''
  }

  handleStateChange = (changes) => {
    const { selectedItem } = changes
    if (selectedItem) {
      this.setState({ selectedItem })
    }
  }

  clear = () => {
    const { onChange, multiple } = this.props
    if (onChange) {
      multiple
        ? onChange([])
        : onChange(null)
      this.setState({ selectedItem: null })
    }
  }

  renderClearButton = () => {
    const { classes, label, hideClear = false } = this.props
    const { selectedItem } = this.state
    if (selectedItem && !hideClear) {
      return <div className={classes.clearButtonContainer}>
        <div
          className={classes.clearButton}
          onClick={this.clear}            
        />
      </div>
    } else {
      const arrowClasses = classNames(classes.arrowDropDownIcon, {
        [classes.noLabelArrow]: !label
      })
      return <ArrowDropDownIcon className={arrowClasses} />
    }
  }

  _searchHeight = 60

  renderSearch = inputProps => {
    const { classes, createable } = this.props
    const { ref, ...restOfInputProps } = inputProps

    return <div
      className={classes.searchContainer}
      style={{ height: this._searchHeight }}
    >
      <div className={classes.searchBar}>
        {
          !createable && <SearchIcon className={classes.searchIcon} />
        }
        <Input
          inputRef={ref}
          fullWidth
          classes={{
            root: createable
              ? classes.createableInput
              : classes.searchInput
          }}
          inputProps={restOfInputProps}
          disableUnderline
        />
      </div>
    </div>
  }

  renderSuggestion = params => {
    const { suggestion, index, itemProps, highlightedIndex } = params

    const isHighlighted = highlightedIndex === index

    return <Option
      key={index}
      dropdownProps={this.props}
      isFocused={isHighlighted}
      component={'div'}
      {...suggestion}
      {...itemProps}
    />
  }

  renderNoResultsMessage = () => {
    return <Option
      label={translations('No Results')}
      value={null}
      component={'div'}
      nullOption
    />
  }

  renderLoadingMessage = () => {
    return <Option
      label={translations('Loading...')}
      value={null}
      component={'div'}
      nullOption
    />
  }

  renderDownshift = params => {
    const {
      options,
      name,
      classes,
      className,
      noErrorTextLabel,
      noFloatingLabel,
      meta,
      label,
      createable,
      listWidth,
      isLoading,
      disableSuggestionFiltering,
      maxListHeight,
      hideLabel = false
    } = this.props

    const {
      selectedItem
    } = this.state

    // these are params that downshift passes to its render
    // function. have a gander at the docs to find out more
    const {
      getInputProps,
      getItemProps,
      highlightedIndex,
      inputValue,
      isOpen,
      openMenu
    } = params
    const containerClass = classNames(classes.container, className)

    let suggestionEls = null
    if (isLoading) {
      suggestionEls = [this.renderLoadingMessage()]
    } else {
      const suggestions = (
        disableSuggestionFiltering
          ? options
          : getSuggestions({ inputValue, options })
      )
      if (suggestions.length) {
        let suggestionsCount = -1
        suggestionEls = _.chain(suggestions)
          .map((suggestionsGroup, i, suggestionsGroups) => {
            return suggestionsGroup.map((suggestion, j) => {
              // iterate suggestionsCount inside the inner map
              // for indexing to work.
              suggestionsCount++
              return this.renderSuggestion({
                dropdownProps: this.props,
                highlightedIndex,
                index: suggestionsCount,
                itemProps: getItemProps({ item: suggestion }),
                suggestion
              })
            })
          })
          .flatten()
          .value()
      } else if (createable) {
        const createSuggestion = {
          label: inputValue,
          value: inputValue
        }
        suggestionEls = [
          this.renderSuggestion({
            dropdownProps: this.props,
            highlightedIndex,
            index: 0,
            itemProps: getItemProps({ item: createSuggestion }),
            suggestion: createSuggestion
          })
        ]
      } else {
        suggestionEls = [this.renderNoResultsMessage()]
      }
    }

    const listHeight = Math.min(suggestionEls.length * 48, Math.min((maxListHeight || 10000), paperHeight))
    const suggestionsEl = (
      isOpen
        ? <Paper
          className={classes.paper}
          component={CustomPaper}
          minWidth={listWidth || '100%'}
          fullWidthMobile
          height={listHeight + this._searchHeight}
        >
          {this.renderSearch(getInputProps({
            id: name,
            onChange: this.handleInput,
            onFocus: openMenu
          }))}
          <VirtualizedMenuList height={listHeight}>
            {suggestionEls}
          </VirtualizedMenuList>
        </Paper>
        : null
    )

    const clearButtonEl = this.renderClearButton()

    return <div className={containerClass}>
      <Label
        label={label}
        noFloatingLabel={noFloatingLabel}
        shrink={selectedItem}
        visuallyHidden={hideLabel}
      />
      <div className={classes.inputWrap}>
        <Input
          value={this.itemToString(selectedItem)}
          readOnly
          onFocus={openMenu}
          onClick={openMenu}
          className={classes.selectRoot}
          inputProps={{ className: classes.selectInput }}
        />
        {clearButtonEl}
      </div>
      {suggestionsEl}
      <ErrorText
        meta={meta}
        noErrorTextLabel={noErrorTextLabel}
      />
    </div>
  }

  componentDidUpdate (prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ selectedItem: this.getInitialSelectedItem() })
    }
  }

  state = {
    selectedItem: this.getInitialSelectedItem(),
    inputValue: ''
  }

  render () {
    return <Downshift
      onChange={this.onChange}
      itemToString={this.itemToString}
      selectedItem={this.state.selectedItem}
      inputValue={this.state.inputValue}
      onStateChange={this.handleStateChange}
    >
      {this.renderDownshift}
    </Downshift>
  }
}

AutocompleteDropdown.propTypes = {
  /** Array of arrays (each top level array renders as a group of options, visually seperated by a line) */
  options: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )).isRequired,
  multiple: PropTypes.bool,
  createable: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  /** Hides the label */
  noErrorTextLabel: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  value: PropTypes.shape({
    value: PropTypes.string
  }),
  onInputChange: PropTypes.func,
  formName: PropTypes.string,
  classes: PropTypes.shape({
    clearButtonContainer: PropTypes.string,
    clearButton: PropTypes.string,
    arrowDropDownIcon: PropTypes.string,
    noLabelArrow: PropTypes.string,
    searchContainer: PropTypes.searchBar,
    searchIcon: PropTypes.searchIcon
  }),
  hideClear: PropTypes.bool
}

export default withStyles(style)(AutocompleteDropdown)
