import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import PurchaseActivityContainer from './PurchaseActivityContainer'

export default (Activity) => compose(
  // for dispatch
  connect(),
  withHandlers({
    onActivityClick: ({ dispatch, activity }) => () => {
      const { meta } = activity
      return dispatch(push(`/orders/${meta.orderNumber}`))
    }
  })
)(PurchaseActivityContainer(Activity))
