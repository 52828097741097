import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/RemoveCircle'

import { translations } from '../../../../config'

import style from './style'

const RemoveButton = ({
  onClick,
  classes
}) => (
  <IconButton aria-label={translations('Remove')} disableRipple onClick={onClick} classes={{ root: classes.iconButton }}>
    <RemoveIcon />
  </IconButton>
)

RemoveButton.propTypes = {
  onClick: PropTypes.func
}

export default withStyles(style)(RemoveButton)
