import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class MessagesReducers {
  getIsFlagged = result => !!result.flagId
  formatResults = results => {
    return results.map(result => {
      return {
        ...result,
        isFlagged: this.getIsFlagged(result)
      }
    })
  }
  fetchMessagesForCustomerFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchMessagesForCustomerFreshPending(state, action)
      case SUCCESS:
        return this.fetchMessagesForCustomerFreshSuccess(state, action)
      case FAIL:
        return this.fetchMessagesForCustomerFreshFail(state, action)
      default:
        return state
    }
  }

  fetchMessagesForCustomerFreshPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchMessagesForCustomerFreshSuccess = (state, action) => {
    const { count: total, rows: results } = action.result
    return {
      ...state,
      total,
      page: 1,
      results: this.formatResults(results),
      status: SUCCESS,
      action: undefined
    }
  }

  fetchMessagesForCustomerFreshFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  fetchMessagesForCustomerNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchMessagesForCustomerNextPending(state, action)
      case SUCCESS:
        return this.fetchMessagesForCustomerNextSuccess(state, action)
      case FAIL:
        return this.fetchMessagesForCustomerNextFail(state, action)
      default:
        return state
    }
  }

  fetchMessagesForCustomerNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchMessagesForCustomerNextSuccess = (state, action) => {
    const { count: total, rows: results } = action.result
    const { page, results: currentResults } = state
    const newResults = [...currentResults, ...results]
    return {
      ...state,
      total,
      page: page + 1,
      results: this.formatResults(newResults),
      status: SUCCESS,
      action: undefined
    }
  }

  fetchMessagesForCustomerNextFail = (state, action) => {
    return {
      ...state,
      status: SUCCESS,
      action: undefined
    }
  }
  addFlagToMessageById = (state, action) => {
    if (action.status === SUCCESS) {
      const id = _.get(action, 'payload.messageId')
      const results = _.get(state, 'results', []).map(result => {
        let formattedResult = result
        if (result.id === id) {
          formattedResult.isFlagged = true
        }
        return formattedResult
      })
      return {
        ...state,
        results
      }
    }
    return state
  }
}

export default new MessagesReducers()
