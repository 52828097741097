import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import EventSearchInput from './EventSearchInput'
import { actions as eventsActions, selectors as eventsSelectors } from '../../../../store/modules/events'

const mapStateToProps = state => {
  return {
    query: eventsSelectors.getQuery(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchEvents: ({ dispatch }) => ({ query }) => dispatch(eventsActions.searchEventsFresh({ query }))
  })
)(EventSearchInput)
