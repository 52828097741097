const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  iframe: {
    flex: 1,
    border: 0
  }
}

export default theme => styles
