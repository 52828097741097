import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withProps } from 'recompose'
import { selectors as productDetailSelectors, actions as productDetailsActions } from '../../store/modules/productDetails'
import HorizontalGallery from './HorizontalGallery'
import { getImage } from '../../components/Images'

const noImagePlaceholder = getImage('imageNotAvailableImg')

const mapStateToProps = state => ({
  activeMediaIndex: productDetailSelectors.getActiveMediaIndex(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    getMedia: ({ images, embeddedVideos, videoThumbnails }) => () => {
      const imagesFinal = (images || []).map(image => ({
        ..._.isObject(image) ? image : { src: image },
        type: 'image'
      }))
      const embeddedVideosFinal = (embeddedVideos || []).map((src, index) => ({
        src,
        type: 'embeddedVideo',
        thumbnail: (videoThumbnails) ? videoThumbnails[index] : ''
      }))
      const imageHolder = [{
        src: noImagePlaceholder,
        type: 'image'
      }]
      if (!imagesFinal.length) {
        return imageHolder.concat(embeddedVideosFinal)
      }
      return imagesFinal.concat(embeddedVideosFinal)
    },
    handleImageClick: ({ dispatch }) => (index) => {
      dispatch(productDetailsActions.putActiveMediaIndex({ mediaIndex: index }))
    }
  }),
  withProps({
    inCordova: !!_.get(window, 'cordova', false)
  })
)(HorizontalGallery)
