
const style = theme => ({
  stockOutter: {
    marginBottom: 30
  },
  locationContainer: {
    fontWeight: 600,
    textDecoration: 'underline',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  stockIcon: {
    paddingRight: 10
  },
  storeLink: {
    fontSize: 13,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    padding: 0,
    fontWeight: 600,
    minHeight: 0
  }
})

export default style
