import _ from 'lodash'

import { getPriceValue, getPriceCode } from '../../helpers'
import { currencyCode } from '../../config'
import digitalStoreSdk from '../../digitalStoreSdk'

class PayPalHereService {
  _getPlugin = () => _.get(window, 'cordova.plugins.CDVPayPalHere')

  _wrapPluginFunction = _.memoize((functionName) => {
    return (args) => {
      return new Promise((resolve, reject) => {
        const plugin = this._getPlugin()
        if (plugin) {
          const fn = plugin[functionName]
          if (fn) {
            fn(args, resolve, reject)
          } else {
            reject(new Error(`function CDVPayPalHere.${functionName} not found.`))
          }
        } else {
          reject(new Error('plugin CDVPayPalHere not found.'))
        }
      })
    }
  })

  initializeMerchant = _.once(() => {
    return digitalStoreSdk.auth.fetchPaypalCredentials({})
      .then(credentials => {
        return this._wrapPluginFunction('initializeMerchant')({
          ...credentials,
          referrerCode: 'RedAnt_POS_PPH'
        })
      })
      .catch(err => {
        console.warn(err)
        throw err
      })
  })

  connectToReader = () => {
    return this._wrapPluginFunction('connectToReader')()
  }

  searchAndConnectToReader = () => {
    return this._wrapPluginFunction('searchAndConnectToReader')()
  }

  takePayment = (price) => {
    const args = {
      currencyCode: getPriceCode(price) || currencyCode,
      total: getPriceValue(price)
    }
    return this._wrapPluginFunction('takePayment')(args)
  }
}

export default new PayPalHereService()
