import React, { PureComponent } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import style from './style'
import H2 from '../../../../components/H2'
import Button from '../../../../components/Button'
import AnswerPicker from '../AnswerPicker'
import CommentBox from '../CommentBox'
import SubmittedOverlay from '../SubmittedOverlay'
import { translations } from '../../../../config'

class DailyQuestionRow extends PureComponent {
  render() {
    const { classes, question, selectAnswer, saveAnswers, openCommentModal, submitting, showSubmittedOverlay } = this.props
    const answered = _.get(question, 'pollAnswers.length', 0) > 0
    const comment = _.get(question, 'pollAnswers[0].details.comment')
    return (
      <div className={classNames(classes.container, {
        [classes.boxShadow]: !showSubmittedOverlay,
        [classes.maxheight]: showSubmittedOverlay
      })}>
        <div className={classes.headerContainer}>
          <H2 className={classes.header} value={question.pollQuestion.question} />
        </div>
        <div className={classes.answerContainer}>
          <AnswerPicker
            disabled={submitting}
            question={question}
            selectAnswer={selectAnswer}
            dailyQuestion
          />
        </div>
        {answered && comment &&
          <CommentBox
            value={comment}
          />
        }
        {answered &&
          <div className={classes.buttonContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.buttonCell}>
                <Button
                  buttonType={'white'}
                  fullWidth
                  disabled={submitting}
                  onClick={openCommentModal}
                >
                  {comment ? translations('edit comment') : translations('comment')}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.buttonCell}>
                <Button
                  buttonType={'primary'}
                  fullWidth
                  disabled={submitting}
                  onClick={saveAnswers}
                >
                  {translations('confirm')}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        {submitting && <div className={classes.submittingOverlay} />}
        {showSubmittedOverlay && (
          <div className={classes.submittedOverlay}>
            <SubmittedOverlay />
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(style)(DailyQuestionRow)
