import { visibility } from '../../../../../config/theme'

const styles = theme => {
  const { hideForSmall } = visibility(theme)
  return {
    hideForSmall,
    container: {
      paddingTop: 10
    },
    title: {
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    titleContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        order: 3,
        marginTop: 20
      }
    },
    sortContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    rightContent: {
      display: 'flex',
      flex: '1',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 10
    },
    filterContainer: {
      paddingLeft: '11px',
      marginTop: -13
    }
  }
}

export default theme => styles(theme)
