const styles = theme => {
  return {
    scrollContainer: {
      overflowX: 'auto',
      overflowY: 'hidden',
      paddingTop: '25px',
      paddingBottom: '25px',
      [theme.breakpoints.up('sm')]: {
        margin: '0 7.5%'
      }
    },
    scrollInner: {
        display: 'inline-block'
    },
    productsContainer: {
        display: 'flex'
    }
  }
}

export default theme => styles(theme)