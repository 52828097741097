import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import NotificationsScreen from './NotificationsScreen'
import NewNotificationScreen from './NewNotificationScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
import { sendOtherUsersNotificationsEnabled } from '../../config'

const routes = []

routes.push(
  <PrivateRoute key='notifications' path='/notifications' exact component={TimedLogoutComponent(NotificationsScreen)} />
)

if (sendOtherUsersNotificationsEnabled) {
  routes.push(
    <PrivateRoute key='notifications/new' path='/notifications/new' exact component={TimedLogoutComponent(NewNotificationScreen)} />
  )
}

export default routes
