const style = theme => ({
  orderDetailContentContainer: {
    padding: '30px 7.5%'
  },
  orderNotFound: {
    display: 'flex',
    height: 'calc(100vh - 56px)',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
      height: 'calc(100vh - 64px)'
    }
  },
  errorMessage: {
    margin: '30px 0',
    textAlign: 'center'
  }
})

export default style
