import React from 'react'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import style from './style'
import Button from '../Button'

class ButtonGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0
    }
  }

  onButtonClick = (index, onSelect) => () => {
    this.setState({ selectedIndex: index })
    onSelect && onSelect()
  }

  renderButton = ({ text, onSelect, passedProps = {} }, index) => {
    const { classes, buttons } = this.props

    const onClick = this.onButtonClick(index, onSelect)
    const isSelected = this.state.selectedIndex === index
    const isFirst = index === 0
    const isLast = index === ( buttons.length - 1 )

    const buttonClass = classNames(classes.buttonGroupButton, {
      [classes.selected]: isSelected,
      [classes.first]: isFirst,
      [classes.last]: isLast
    })

    return (
      <Button onClick={onClick} className={buttonClass} {...passedProps}>
        {text}
      </Button>
    )
  }

  render() {
    const { classes, buttons } = this.props

    return (
      <div className={classes.buttonGroupContainer}>
        {buttons.map((config, index) => (
          this.renderButton(config, index)
        ))}
      </div>
    )
  }
}

export default withStyles(style)(ButtonGroup)
