import _ from 'lodash'

const formatValue = (value) => {
  const [mth, yr] = _.split(value, '/', 2)
  const month = _.toNumber(mth)
  const year = _.toNumber(yr)

  if (!Number.isNaN(month) && !Number.isNaN(year)) {
    return `${mth}/${yr}`
  } else if (!Number.isNaN(month)) {
    return mth.length === 2 ? `${mth}/` : mth
  }
  return value.substring(0, value.length - 1)
}

const formatExpirationDate = (date) => formatValue(date)

export default formatExpirationDate
