import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  createdCustomer: undefined,
  addressList: undefined,
  selectedAddress: undefined,
  selectedCustomer: undefined,
  customerList: {
    size: 30,
    page: 1,
    error: undefined,
    query: undefined,
    total: undefined,
    status: undefined,
    customer: undefined
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_POSTCODE:
      return reducers.searchPostcode(state, action)
    case constants.SEARCH_FULL_ADDRESS:
      return reducers.searchFullAddress(state, action)
    case constants.SEARCH_CUSTOMER_FRESH:
      return reducers.searchCustomerFresh(state, action)
    case constants.SEARCH_CUSTOMER_NEXT:
      return reducers.searchCustomerNext(state, action)
    case constants.UPDATE_CUSTOMER_IN_LIST:
      return reducers.updateCustomerInList(state, action)
    default:
      return state
  }
}
