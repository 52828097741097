const style = theme => ({
  icon: {
    height: 160,
    width: '100%'
  },
  fileContainer: {
    height: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200
    }
  },
  fileNameContainer: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontSize: 13,
    width: '100%',
    overflow: 'hidden',
    lineHeight: '1.2em',
    maxHeight: '2.4em',
    fontWeight: 'bold'
  },
  containerMargin: {
    marginRight: 30
  }
})

export default style
