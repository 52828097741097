import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../../config'
import KeyValueCard from '../../../../../components/KeyValueCard'

import style from './style'

class OrderInfo extends Component {
  _translateKey = (key) => {
    return translations(`Order ${_.startCase(key)}`)
  }

  render () {
    const { info = [], classes, emphasisKey, fillSpace } = this.props

    const leftData = info.map(([key, value, keyValuePairProps]) => {
      return [
        this._translateKey(key),
        value,
        {
          className: cx({
            [classes.truncateOnDesktop]: true,
            [classes.hideOnDesktop]: key === emphasisKey,
            [classes.fillSpace]: fillSpace
          }),
          ...keyValuePairProps
        }
      ]
    })

    const _emphasisedInfo = info.find(([key]) => key === emphasisKey)
    const rightData = (
      _emphasisedInfo
      ? [[this._translateKey(_emphasisedInfo[0]), _emphasisedInfo[1]]]
      : []
    )
  
    return <div className={classes.container}>
      <KeyValueCard
        className={classes.left}
        data={leftData}
      />
      <KeyValueCard
        className={classes.right}
        data={rightData}
      />
    </div>
  }
}

export default withStyles(style)(OrderInfo)
