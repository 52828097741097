import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING } from '../../middleware/redux-promise'
import { FETCH_MESSAGE_DETAILS } from './constants'

export const getStatus = state => _.get(state.messageDetails, 'status')
export const getAction = state => _.get(state.messageDetails, 'action')
export const getResult = state => _.get(state.messageDetails, 'result', {})

export const getIsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_MESSAGE_DETAILS)
))
