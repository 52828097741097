import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'

import ButtonLink from '../../../../components/ButtonLink'
import UsersRowIcon from '../UsersRowIcon'
import { getImage } from '../../../../components/Images'
import { Table, TableHeader, TableRow, TableCell } from '../../../../components/Table'
import { translations } from '../../../../config'
import styles from './style'

const deactivatedIcon = getImage('deactivatedIcon')

class UsersSearchTable extends Component {
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>{translations('Sorry, an error has occurred. Please try again.')}</div>
  }

  formatStoreContent = (role, stores) => {
    if (role.isAdmin) {
      return translations('All Stores')
    }
    if (stores.length > 2) {
      return _.template(translations('Multiple stores'))({ store1: stores[0].name, store2: stores[1].name, remaining: stores.length - 2 })
    }
    let storeString = ''
    stores.forEach((store, index) => {
      if (index === stores.length - 1) {
        // last index
        storeString += `${store.name}`
      } else {
        storeString += `${store.name}, `
      }
    })
    return storeString
  }

  renderTableRows = () => {
    const { results, classes, onUserClick, disabledUserId } = this.props
    return _.chain(results)
      .map(user => {
        const { id, firstName, lastName, role, stores, accountDeactivated, accountLocked } = user
        const formattedRole = translations(_.chain(role.name).startCase(role.name).capitalize().value())
        const isUserDisabled = disabledUserId && id === disabledUserId
        return (
          <TableRow key={id} onClick={() => onUserClick(user)} deactivated={accountLocked || accountDeactivated || isUserDisabled}>
            <TableCell xs={4} sm={4}>
              <div
                className={classes.nameContainer}
              >
                <UsersRowIcon
                  accountDeactivated={accountDeactivated}
                  accountLocked={accountLocked}
                  deactivatedIcon={deactivatedIcon}
                />
                <ButtonLink
                  onClick={() => onUserClick(user)} 
                  className={classes.nameWrapper}
                >
                  {`${firstName} ${lastName}`}
                </ButtonLink>
              </div>
            </TableCell>
            <TableCell xs={5} sm={5}>{this.formatStoreContent(role, stores)}</TableCell>
            <TableCell xs={3} sm={3}>{formattedRole}</TableCell>
          </TableRow>
        )
      })
      .value()
  }

  render () {
    const { error, noResults, hasSearched, searchCustomers, hasMore, results, classes } = this.props
    if (error) {
      return this.renderError()
    } else {
      return (
        <div className={classes.gridContainer}>
          <InfiniteScroll
            pageStart={0}
            className={classes.container}
            loadMore={searchCustomers}
            hasMore={hasMore}
            initialLoad={false}
            useWindow={false}
          >
            {
              results &&
              <Table
                emptyMessage={translations('No results found')}
                header={(
                  <TableHeader>
                    <TableCell xs={4} sm={4}>{translations('Users Name')}</TableCell>
                    <TableCell xs={5} sm={5}>{translations('Users Store')}</TableCell>
                    <TableCell xs={3} sm={3}>{translations('Users Role')}</TableCell>
                  </TableHeader>
                )}
                rows={this.renderTableRows()}
              />
            }
          </InfiniteScroll>
        </div>
      )
    }
  }
}

export default withStyles(styles)(UsersSearchTable)
