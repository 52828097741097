import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router'
import _ from 'lodash'
import classNames from 'classnames'
import { subtractPrices } from '@redant/digital-store-prices'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Radio from '@material-ui/core/Radio'

import ImageBox from '../../../components/ImageBox'
import { getImage } from '../../../components/Images'
import P from '../../../components/P'
import Dropdown from '../../../components/Fields/Dropdown'
import FloatingBadge from '../../../components/FloatingBadge'

import { history } from '../../../store'
import { translations, refundOrderEnabled } from '../../../config'
import modalService from '../../../services/modalService'
import currencyFormatter from '../../../formatters/currencyFormatter'

import EditPriceModal, { editPriceType } from './EditPriceModal'
import style from './style'

const noImagePlaceholder = getImage('imageNotAvailableImg')

const format = currencyFormatter.format
const trashIcon = getImage('trashIcon')
const refundIcon = getImage('refundIcon')

const quantityOptions = (quantity = 10) =>
  _.range(Math.max(quantity, 10)).map(num => ({
    value: (num + 1).toFixed(1),
    label: num + 1,
  }))

const _Text = props => {
  const { bold, underline, strikethrough, classes, className, onClick, ...rest } = props
  const pClassName = classNames(
    className,
    classes.text,
    { [classes.bold]: bold },
    { [classes.underline]: underline },
    { [classes.strikethrough]: strikethrough },
    { [classes.pointer]: onClick }
  )

  return <P {...rest} onClick={onClick} className={pClassName} />
}
const Text = withStyles(style)(_Text)

class BasketItem extends Component {
  onClickRefund = e => {
    e.stopPropagation()
    const { id, toggleRefundItem } = this.props
    // Mark all items in basket with this id as refund or not
    toggleRefundItem({ id, refund: !this.props.refund })
  }

  onClickTrash = e => {
    e.stopPropagation()

    const product = this.props
    const path = history.location.pathname
    const isCheckout = matchPath(path, { path: '/checkout' })
    const isReferral = matchPath(path, { path: '/referral' })

    if ((isCheckout || isReferral) && this.props.products.length <= 1) {
      modalService.action({
        title: translations('Removing Last Product From Basket'),
        text: translations('Removing Last Product From Basket Details'),
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => {
              this.props.removeProduct({ product })
              this.props.leaveCheckout()
            },
            primary: true,
          },
          {
            text: translations('Cancel'),
          },
        ],
      })
    } else {
      this.props.removeProduct({ product })
    }
  }

  onChangeQuantity = e => {
    const quantityString = e.target.value
    const quantity = parseInt(quantityString, 10)
    if (quantity || quantity === 0) {
      this.props.changeQuantity({ product: this.props, quantity })
    }
  }

  onClickAddDiscount = e => {
    const { addDiscount } = this.props
    e.stopPropagation()
    addDiscount()
  }

  onClickRemoveDiscount = e => {
    e.stopPropagation()

    const {
      removeManualOrderDiscount,
      removeManualItemDiscount,
      currencyCode,
      discountMode,
      discountType,
      ...product
    } = this.props

    if (_.get(product, 'refund') || discountType !== 'orderDiscount') {
      removeManualItemDiscount({
        product: product,
        applyTo: discountMode ? 1 : undefined,
      })
    } else {
      removeManualOrderDiscount()
    }
  }

  onSuccessEditPrice = ({ editedPriceValue }) => {
    const {
      addManualDiscount,
      nowPrice,
      quantity,
      currencyCode,
      discountType,
      ...product
    } = this.props

    const discount = {
      ...subtractPrices(nowPrice, { value: editedPriceValue }),
      type: editPriceType,
    }

    addManualDiscount({
      discount,
      product,
      applyTo: quantity,
      orderDiscount: discountType === 'orderDiscount',
    })

    modalService.close()
  }

  onClickEditPrice = e => {
    e.stopPropagation()
    const { nowPrice } = this.props

    modalService.open({
      component: EditPriceModal,
      success: this.onSuccessEditPrice,
      price: nowPrice,
      actions: [
        {
          text: translations('Done'),
          success: true,
          primary: true,
        },
      ],
    })
  }

  onClickRemovePriceEdit = this.onClickRemoveDiscount

  renderQuantityPicker = () => {
    const {
      classes,
      quantity,
      textType,
      editableQuantities,
      unsold,
      service,
      consultationMode,
    } = this.props

    if (editableQuantities) {
      if (!service || consultationMode) {
        return (
          <div className={classes.quantityContainer}>
            {!unsold && (
              <div className={classes.innerDropdown}>
                <Text
                  type={textType}
                  value={`${translations('QTY')}${editableQuantities ? '' : `: ${quantity}`}`}
                  className={classes.qtyStyle}
                />
                <Dropdown
                  options={quantityOptions(quantity)}
                  noNullOption
                  noErrorTextLabel
                  value={quantity.toFixed(1)}
                  onChange={this.onChangeQuantity}
                />
              </div>
            )}
          </div>
        )
      } else {
        return (
          <div className={classes.quantityContainer}>
            <div className={classes.innerDropdown}>
              <Text
                type={textType}
                value={`${translations('QTY')}${editableQuantities ? '' : `: ${quantity}`}`}
                className={classes.qtyStyle}
              />
              <Dropdown
                options={quantityOptions(quantity)}
                noNullOption
                noErrorTextLabel
                value={quantity.toFixed(1)}
                onChange={this.onChangeQuantity}
                className={classes.qtyDropdown}
              />
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }

  renderTotalDiscountText = (displaySubtotalValue, displayDiscountValue) => {
    const { discountType, refund } = this.props
    const subtotalValue = format(displaySubtotalValue)
    let str = ''

    if (discountType === 'orderDiscount' && !refund) {
      str = `${subtotalValue} (Transaction Discount)`
    } else if (displayDiscountValue.percent) {
      str = `${subtotalValue}-(${displayDiscountValue.percent}%)`
    } else {
      const discountValue = format(displayDiscountValue)

      str = `${subtotalValue}-(${discountValue})`
    }

    return str.replace('--', '-')
  }

  renderPrice = () => {
    const {
      groupNowPrice,
      groupSubTotal,
      groupManualDiscount,
      textType,
      editablePrices,
      classes,
      service,
      discountMode,
      subTotal,
      discountType,
      manualDiscount,
      nowPrice,
      refund,
      customerFacing,
    } = this.props

    const displaySubtotalValue = groupSubTotal || subTotal
    const displayNowPriceValue = groupNowPrice || nowPrice
    const displayDiscountValue = groupManualDiscount || manualDiscount

    const manualDiscountButtons = [
      <Text
        key='add-discount'
        value={translations('Add Discount')}
        type={textType}
        underline
        onClick={this.onClickAddDiscount}
        className={classNames(classes.editActions)}
      />,
      <Text
        key='edit-price'
        value={translations('Edit Price')}
        type={textType}
        underline
        onClick={this.onClickEditPrice}
        className={classNames(classes.editActions)}
      />,
    ]

    if (customerFacing) {
      return (
        <div className={classNames(classes.unitPriceEdit, classes.priceLine)}>
          <Text value={`${translations('Price')}:`} type={textType} />
          <Text value={`${format(groupNowPrice)}`} type={textType} className={classes.totalPrice} />
        </div>
      )
    }

    return (
      <>
        {editablePrices && !displayDiscountValue ? (
          <div className={classNames(classes.unitPriceEdit, classes.priceLine)}>
            <Text value={`${translations('Total Price')}:`} type={textType} />
            <Text
              value={`${format(displayNowPriceValue)}`}
              type={textType}
              strikethrough={!!displayDiscountValue}
              className={classNames(classes.totalPrice, classes.price)}
            />
          </div>
        ) : (
          !displayDiscountValue && (
            <div className={classes.unitPriceEdit}>
              <Text value={`${translations('Total Price')}:`} type={textType} />
              <Text
                value={`${format(displayNowPriceValue)}`}
                type={textType}
                strikethrough={!!displayDiscountValue}
                className={classNames(classes.totalPrice, classes.price)}
              />
            </div>
          )
        )}
        <div className={classes.priceContainer}>
          {// buttons for adding discount & editing price
          editablePrices && !displayDiscountValue && !discountMode && !service && (
            <>
              <Hidden xsDown>{manualDiscountButtons}</Hidden>
              <Hidden smUp>
                <div className={classes.textContainer}>{manualDiscountButtons}</div>
              </Hidden>
            </>
          )}
        </div>
        {// buttons for removing discounts if they've been added
        displayDiscountValue &&
          (displayDiscountValue.type === editPriceType ? (
            [
              <div key='1' className={classes.priceContainer}>
                <Text value={`${translations('Total Price')}:`} type={textType} />
                <Text
                  value={`${format(displayNowPriceValue)}`}
                  type={textType}
                  strikethrough={!!displayDiscountValue}
                />
                <Text
                  value={`${format(displaySubtotalValue)}`}
                  type={textType}
                  className={classNames(classes.totalPrice, classes.price)}
                />
              </div>,
              editablePrices && (
                <Text
                  key='2'
                  value={translations('Undo Edit')}
                  type={textType}
                  underline
                  onClick={this.onClickRemovePriceEdit}
                />
              ),
            ]
          ) : (
            <div className={classes.priceContainer}>
              <div className={classes.priceContainerInner}>
                <div className={`${classes.priceContainer} ${classes.unitPriceEdit}`}>
                  <Text value={`${translations('Total Price')}:`} type={textType} />
                  <Text
                    value={this.renderTotalDiscountText(displaySubtotalValue, displayDiscountValue)}
                    type={textType}
                    className={classNames(classes.totalPrice, classes.price)}
                  />
                </div>
                {editablePrices && (
                  <Text
                    value={
                      discountType === 'orderDiscount' && !refund
                        ? translations('Remove Transaction Discount')
                        : translations('Remove Discount')
                    }
                    type={textType}
                    underline
                    onClick={this.onClickRemoveDiscount}
                  />
                )}
              </div>
            </div>
          ))}
      </>
    )
  }

  render() {
    const {
      name,
      secondaryName,
      preview,
      variant,
      classes,
      images,
      editableQuantities,
      textType,
      gap,
      price,
      priceToDisplay,
      discount,
      editablePrices,
      groupManualDiscount,
      nowPrice,
      manualDiscount,
      subTotal,
      refund,
      hasRefundPermission,
      discountMode,
      checked,
      toggleChecked,
      service,
      customerFacing,
      hideUnpurchasedItems,
      hideRefund,
      unsold,
    } = this.props

    if (hideUnpurchasedItems && unsold) {
      return null
    }

    const variantName = variant && variant.name
    const image = _.get(images, '0') || noImagePlaceholder
    const trashIconClasses = { root: classes.trashIconButton }

    return (
      <div>
        {refund && <div className={classes.refundContainerTitle}>{translations('refund')}</div>}
        <div
          className={classNames(classes.container, {
            [classes.refundContainer]: refund,
            [classes.discountContainer]: discountMode,
          })}
        >
          {discountMode && !service && (
            <Radio
              className={classes.discountCheckbox}
              checked={checked}
              onClick={toggleChecked}
              disabled={service}
            />
          )}
          <div className={classes.topContent}>
            <div className={classes.leftCol}>
              <ImageBox className={classNames(classes.image)} src={image} contain />
              {preview && (
                <FloatingBadge
                  label={translations('Preview')}
                  containerClass={classes.previewBadge}
                  textClass={classes.previewBadgeText}
                />
              )}
            </div>
            <div className={classes.rightCol}>
              <div className={classes.top}>
                <div className={classNames(classes.topText)}>
                  <Text type={textType} value={name} bold />
                  {secondaryName && <Text type={textType} value={secondaryName} />}
                  {/* keep this div for styling purposes */}
                  <div />
                </div>
                {refundOrderEnabled && !hideRefund && hasRefundPermission && editableQuantities && !discountMode && (
                  <IconButton
                    onClick={this.onClickRefund}
                    classes={{
                      root: classNames(classes.refundIcon, {
                        [classes.refundIconActive]: refund,
                      }),
                    }}
                    aria-pressed={!!refund}
                  >
                    <img
                      src={refundIcon}
                      className={classes.refundIconImg}
                      alt={
                        refund
                          ? translations('Basket - Unmark For Return Button')
                          : translations('Basket - Mark For Return Button')
                      }
                    />
                  </IconButton>
                )}
              </div>
              {gap && <div className={classes.gap} />}
              <div className={classes.bottom}>
                <div className={classNames(classes.bottomInner)}>
                  <Text type={textType} value={variantName} className={classes.variant} />
                  {!customerFacing && editablePrices && !groupManualDiscount ? (
                    <div className={classes.unitPriceEdit}>
                      <Text value={`${translations('Unit Price')}:`} type={textType} />

                      <Text
                        className={classes.price}
                        value={`${discount.value !== 0 ? format(priceToDisplay) : format(price)}`}
                      />
                    </div>
                  ) : (
                    <div className={classes.unitPriceEdit}>
                      <Text
                        value={`${translations('Unit Price')}:`}
                        className={classes.priceLabel}
                        type={textType}
                      />
                      {manualDiscount ? (
                        <>
                          <Text
                            className={classes.price}
                            value={format(nowPrice)}
                            strikethrough={!!groupManualDiscount}
                          />
                          <Text value={format(subTotal)} />
                        </>
                      ) : (
                        <Text
                          value={`${
                            discount.value !== 0 ? `${format(priceToDisplay)}` : `${format(price)}`
                          }`}
                        />
                      )}
                    </div>
                  )}
                  {this.renderPrice()}
                </div>
              </div>
            </div>
            {editableQuantities && !discountMode && (
              <IconButton classes={trashIconClasses} onClick={this.onClickTrash}>
                <img
                  src={trashIcon}
                  className={classes.trashIconImg}
                  alt={translations('Basket - Remove Button')}
                />
              </IconButton>
            )}
          </div>
          <div className={classes.bottomContent}>
            {!discountMode && this.renderQuantityPicker()}
          </div>
        </div>
      </div>
    )
  }
}

BasketItem.propTypes = {
  name: PropTypes.string.isRequired,
  secondaryName: PropTypes.string,
  preview: PropTypes.bool.isRequired,
  variant: PropTypes.object,
  classes: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  editableQuantities: PropTypes.bool.isRequired,
  textType: PropTypes.string.isRequired,
  gap: PropTypes.bool.isRequired,
  price: PropTypes.object.isRequired,
  priceToDisplay: PropTypes.object.isRequired,
  discount: PropTypes.object,
  editablePrices: PropTypes.bool,
  groupManualDiscount: PropTypes.bool,
  nowPrice: PropTypes.object.isRequired,
  manualDiscount: PropTypes.bool,
  subTotal: PropTypes.object.isRequired,
  refund: PropTypes.bool,
  hasRefundPermission: PropTypes.bool,
  discountMode: PropTypes.bool,
  checked: PropTypes.bool,
  toggleChecked: PropTypes.func.isRequired,
  service: PropTypes.bool,
  customerFacing: PropTypes.bool,
  hideUnpurchasedItems: PropTypes.bool,
  unsold: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  consultationMode: PropTypes.bool,
  groupNowPrice: PropTypes.object.isRequired,
  groupSubTotal: PropTypes.object.isRequired,
  discountType: PropTypes.string,
  removeManualOrderDiscount: PropTypes.func.isRequired,
  removeManualItemDiscount: PropTypes.func.isRequired,
  addManualDiscount: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  bold: PropTypes.string,
  underline: PropTypes.string,
  strikethrough: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  toggleRefundItem: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  leaveCheckout: PropTypes.func.isRequired,
  changeQuantity: PropTypes.func.isRequired,
  addDiscount: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

BasketItem.defaultProps = {
  variant: null,
  images: null,
  discount: null,
  editablePrices: false,
  groupManualDiscount: false,
  manualDiscount: false,
  refund: false,
  hasRefundPermission: false,
  discounMode: false,
  checked: false,
  service: false,
  customerFacing: false,
  hideUnpurchasedItems: false,
  unsold: false,
  consultationMode: false,
  discountType: null,
  bold: null,
  underline: null,
  strikethrough: null,
  className: null,
  secondaryName: null,
}

export default withStyles(style)(BasketItem)
