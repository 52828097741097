import { visibility } from '../../../config/theme'
import { tableGutter } from '../Table'

const styles = theme => {
  const { hideForExtraSmall, hideForSmall } = visibility(theme)
  return {
    hideForExtraSmall,
    hideForSmall,
    cell: {
      display: 'flex',
      padding: `0 ${0.5 * tableGutter}px`
    },
    truncate: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& > div': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    }
  }
}

export default theme => styles(theme)
