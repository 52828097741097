import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CategoriesGridItem from './CategoriesGridItem'
import style from './style'

const CategoriesGrid = props => {
  const { classes, categories, push } = props
  return (
    <div className={classes.container}>
      <Grid className={classes.listContainer} container spacing={0} component={'ul'}>
        {
          categories
          .map((category, index) => {
            return <CategoriesGridItem
              key={index}
              category={category}
              push={push}
              component={'li'}
            />
          })
        }
      </Grid>
    </div>
  )
}

export default withStyles(style)(CategoriesGrid)
