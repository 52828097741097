import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import MessagingActivityContainer from './MessagingActivityContainer'

export default (Activity) => compose(
  // for dispatch
  connect(),
  withHandlers({
    onActivityClick: ({ dispatch, activity }) => () => dispatch(push(`/customers/${activity.customer.id}/messages`))
  })
)(MessagingActivityContainer(Activity))
