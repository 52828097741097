import _ from 'lodash'
import fp from 'lodash/fp'
import { communicationOptions } from '../../config/general'
/**
 * The name of the marketing preferences in the config.
 */
const marketingPreferencesConfigKey = 'preferenceOptionKeys'
/**
 * Remove Keys which are false,
 * Leaves an array of keys with true values.
 * This is the format which the checklist requres.
 */
const formatMarketingValues = (result, keys) => {
  return _.chain(result)
    .pick(keys)
    .omitBy(value => !value)
    .keys()
    .value()
}
/**
 * Get marketing preference options from the config as keys.
 */
const getMarketingPreferenceKeys = () => {
  return fp.compose(
    fp.map('value'),
    fp.get(marketingPreferencesConfigKey)
  )(communicationOptions)
}
export const preferenceKeys = getMarketingPreferenceKeys()

export const preferenceOptionKeys = fp.compose(
  fp.map(fp.pick(['value', 'label'])),
  fp.get(marketingPreferencesConfigKey)
)(communicationOptions)
/**
 * Add formatted marketing values to the customer.
 */
export const addMarketingValuesToCustomer = customer => {
  const customerMarketingChannels = formatMarketingValues(customer, communicationOptions.channelKeys)
  const customerMarketingPreferences = formatMarketingValues(customer, preferenceKeys)

  // add formatted properties to the customer object
  const customerWithMarketing = Object.assign(customer, {
    customerMarketingChannels,
    customerMarketingPreferences
  })

  // remove user's unformatted marketing preferences
  return fp.omit(
    [
      ...preferenceKeys
    ]
    , customerWithMarketing)
}

export const formatWaitlistEntry = entry => {
  if (entry.customer) {
    return fp.assign(
      { customer: addMarketingValuesToCustomer(entry.customer) },
      entry
    )
  }
  return entry
}

const getFieldsForCustomerDetails = () => {
  return fp.compose(
    fp.map('value'),
    fp.filter({ storeWithinCustomerDetails: true }),
    fp.get(marketingPreferencesConfigKey)
  )(communicationOptions)
}
/**
 * Custom Marketing fields that are not distinct columns within the database and should instead be stored in the customer details.
 *
 * Allows clients to use bespoke marketing options.
 */
export const fieldsForCustomerDetails = getFieldsForCustomerDetails()
/**
* An array of marketing keys that are related to stores.
* The marketing field should be shown when any of these keys have `true` properties.
*/
export const storeRelatedMarketingFields = fp.compose(
  fp.map('value'),
  fp.filter('showStoresForMarketing'),
  fp.get(marketingPreferencesConfigKey)
)(communicationOptions)
/**
 * Takes form data (or any plain object)
 * and checks if the preference options in the config that are marked with `showStoresForMarketing` are selected.
 *
 * Often used to handle when to show a dropdown for stores to associate with marketing preferences.
 */
export const isMarketingStoresSelected = (data) => {
  /**
  * Checks the {@link storeRelatedMarketingFields fields that relates to stores} and if the user has responded true to any
  *
  * this boolean will be true.
  */
  const isStoreMarketingSelected = fp.compose(
    fp.any(fp.eq(true)),
    fp.pick(storeRelatedMarketingFields)
  )(data)
  return isStoreMarketingSelected
}
