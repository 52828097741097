import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import P from '../../../components/P'
import style from './style'
import SelectDepartmentForm from '../SelectDepartmentForm'
import MainLogo from '../../../components/MainLogo'

const SelectDepartmentScreen = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.innerContainer}>

      <div className={classes.formContainer}>
        <MainLogo />
        <div className={classes.forgotLinkWrapper}>
          <P value={translations('Please select your department')} />
          <SelectDepartmentForm />
        </div>
      </div>
    </div>
  </div>
)

SelectDepartmentScreen.propTypes = {

}

export default withStyles(style)(SelectDepartmentScreen)
