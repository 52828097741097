import React, { PureComponent, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { translations } from '../../../../config'
import P from '../../../../components/P'
import Button from '../../../../components/Button'

import style from './style'

class MultiSelectBar extends PureComponent {
  render () {
    const {
      classes,
      totalSelectedProducts,
      hasSelectedProducts,
      onClickDone
    } = this.props

    const spacerClasses = classNames(
      classes.multiSelectContainerSpacer,
      { [classes.hasSelectedProducts]: hasSelectedProducts }
    )

    return (
      <Fragment>
        <div className={spacerClasses} />
        <div className={classes.multiSelectContainer}>
          {hasSelectedProducts && <div className={classes.totalContainer}>
            <P className={classes.total} role='status' type='large' value={`${totalSelectedProducts} ${translations('PRODUCT SELECTED')}`} />
          </div>}
          <div className={classes.buttonContainer}>
            <Button onClick={onClickDone} big buttonType='primary'>
              {translations('Done')}
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(style)(MultiSelectBar)
