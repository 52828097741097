import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import LatestMessage from './LatestMessage'

import { selectors as customerDetailSelectors } from '../../../../store/modules/customerDetails'
import { actions as messageDetailsActions } from '../../../../store/modules/messageDetails'
import analyticsService from '../../../../services/analyticsService'
import modalService from '../../../../services/modalService'
import MessageDetailsScreen from '../../../Message/MessageDetailsScreen'

const mapStateToProps = state => ({
  latestMessage: customerDetailSelectors.getCustomerLatestMessage(state),
  customerName: customerDetailSelectors.getCustomerFullName(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onFullMessageClick: () => () => {
      analyticsService.sendCustomEvent({ type: 'fullMessageListClick' })
    },
    goToMessage: ({ dispatch }) => ({ id }) => {
      dispatch(messageDetailsActions.resetStore())
      dispatch(messageDetailsActions.fetchMessageDetails({ id }))
      modalService.open({
        component: MessageDetailsScreen,
        fullScreen: true,
        onGoBackClick: () => modalService.close()
      })
    }
  })
)(LatestMessage)
