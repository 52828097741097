import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import style from './style'

export const P = ({ type, value, onClick, className, classes }) => {
  const getStyle = type => {
    const classTypes = {
      large: classes.large,
      hubTileText: classes.hubTileText,
      promoText: classes.promoText,
      textButton: classes.textButton,
      alert: classes.alert,
      inline: classes.inline,
      bold: classes.bold,
      greenText: classes.greenText,
      tag: classes.tag,
      small: classes.small
    }
    return classTypes[type] || classes.p
  }
  const pClass = getStyle(type)
  return (
    <p
      className={classNames(pClass, className)}
      onClick={onClick}
    >
      {value}
    </p>
  )
}

P.propTypes = {
  type: PropTypes.oneOf([
    'large',
    'hubTileText',
    'promoText',
    'textButton',
    'alert',
    'inline',
    'greenText',
    'tag',
    'small'
  ]),
  value: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  onClick: PropTypes.func
}

export default withStyles(style)(P)
