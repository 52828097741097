import * as constants from './constants'
import { constants as detailConstants } from '../eventDetails'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_EVENTS_FRESH:
      return reducers.searchEventsFresh(state, action)
    case constants.SEARCH_EVENTS_NEXT:
      return reducers.searchEventsNext(state, action)
    case constants.CLEAR_RESULTS:
      return reducers.clearResults(state, action)
    case constants.FETCH_EVENT_TAGS:
      return reducers.fetchEventTags(state, action)
    case detailConstants.DELETE_EVENT:
      return reducers.removeListItem(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
