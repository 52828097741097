import React, { PureComponent } from 'react'

import BasketButtons from '../BasketButtons'
import { withStyles } from '@material-ui/core/styles'
import BasketGrid from '../BasketGrid'
import Button from '../../../components/Button'
import style from './style'

import { translations } from '../../../config'
import { Helmet } from 'react-helmet'

class BasketScreen extends PureComponent {
  render () {
    const {
      groupedProducts,
      silent,
      classes,
      onDone,
      isOpen
    } = this.props

    return (
      <div className={classes.container}>
        <h1 className='visuallyhidden'>{translations('basket')}</h1>
        <Helmet><title>{translations('basket')}</title></Helmet>
        <div className={classes.header}>Basket</div>
        <div className={classes.listContainer}>
          <BasketGrid
            products={groupedProducts}
            editableQuantities
            textType='large'
            gap
            silent={silent}
          />
        </div>
        <div className={classes.footer}>
          <BasketButtons customerFacing />
          <div className={classes.buttonContainer}>
            <Button onClick={onDone} big buttonType='primary' className={classes.button}>
              {translations('Done')}
            </Button>
          </div>
        </div>
      </div >
    )
  }
}

export default withStyles(style)(BasketScreen)
