export const FETCH_ORDER = 'digitalOrder/orderDetails/FETCH_ORDER'
export const REASSIGN_USER_FOR_ORDER = 'digitalOrder/orderDetails/REASSIGN_USER_FOR_ORDER'
export const REASSIGN_CUSTOMER_FOR_ORDER = 'digitalOrder/orderDetails/REASSIGN_CUSTOMER_FOR_ORDER'
export const CREATE_CUSTOMER_FOR_ORDER = 'digitalOrder/orderDetails/CREATE_CUSTOMER_FOR_ORDER'
export const REFUND_ORDER = 'digitalOrder/orderDetails/REFUND_ORDER'
export const BEGIN_PARTIAL_REFUND_ORDER = 'digitalOrder/orderDetails/BEGIN_PARTIAL_REFUND_ORDER'
export const CANCEL_PARTIAL_REFUND_ORDER = 'digitalOrder/orderDetails/CANCEL_PARTIAL_REFUND_ORDER'
export const CANCEL_ORDER = 'digitalOrder/orderDetails/CANCEL_ORDER'
export const UPDATE_ORDER = 'digitalStore/orderDetails/UPDATE_ORDER'
export const BEGIN_EXCHANGE_ORDER = 'digitalStore/orderDetails/BEGIN_EXCHANGE_ORDER'
export const CANCEL_EXCHANGE_ORDER = 'digitalStore/orderDetails/CANCEL_EXCHANGE_ORDER'
