import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  lastSeq: null
}

export default (state = defaultState, action) => {
  const newState = {
    ...defaultState,
    ...state
  }

  switch (action.type) {
    case constants.SYNC:
      return reducers.sync(newState, action)
    default:
      return state
  }
}
