import _ from 'lodash'

import { translations } from '../../../config'

import { getItemValue } from './helpers'

const _getTargetData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { vsTarget } = data
  if (!vsTarget) return

  const targetData = items.reduce(
    (acc, item) => {
      const base = { ...acc }
      base.target = (
        getItemValue(item, _.get(vsTarget, 'target.path'), props) +
        (acc.target || 0)
      )
      base.progress = (
        getItemValue(item, _.get(vsTarget, 'progress.path'), props) +
        (acc.progress || 0)
      )
      return base
    },
    {}
  )

  targetData[translations('Achieved')] = targetData.progress
  if (targetData.target > targetData.progress) {
    // if under target
    targetData[translations('To Go')] = targetData.target - targetData.progress
  }
  targetData.__TARGET = targetData.target
  delete targetData.target
  delete targetData.progress

  return targetData
}
const getTargetData = _.memoize(_getTargetData)

export default getTargetData
