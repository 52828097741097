
export const customAddressDetailsSchemaConfig = {
  customProperties: [{}],
  customDefinitions: [{}
  ],
  customDependencies: [{}],
  customUiSchema: [{
    'ui:order': ['firstName', 'lastName', 'addressSearch', 'findAddressWidget', 'address1', 'address2', 'city', 'county', 'country', 'postCode', '*'],
  }]
}
